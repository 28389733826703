import {ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChildren} from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import {BsModalService, BsModalRef, ModalDirective} from 'ngx-bootstrap/modal';
import { DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import { Subject, concat } from 'rxjs';
import { MetabaseService } from '../../../_services/metabase/metabase.service';
import { WasabiApiService } from '../../../_services/wasabiApi/wasabiApi.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import {DatePipe} from '@angular/common';
import StepInterface from '../interfaces/StepInterface';
import fuzzysort from 'fuzzysort';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-super-reports',
  templateUrl: './super-reports.component.html',
  styleUrls: ['./super-reports.component.css']
})
export class SuperReportsComponent implements OnInit {
  modalTemplate: BsModalRef;

  isMobile: false | 'sm' | 'xs' = false;
  isIos = true;

  ticketsMassUpdateStatus = '';
  ticketsMassUpdateStatusLoading = false;
  ticketsMassUpdateSelectAll = false;

  importTemplateInfo = {
    templateName: '',
    template: '',
    step: ''
  };

  isLoadingLeadman: boolean;
  leadmanLoaded: boolean;
  isLoadingTech: boolean;
  techLoaded: boolean;
  index: string;
  newReport = false;
  today = new Date();
  todayLocal = (new Date(this.today.getTime() - (this.today.getTimezoneOffset() * 60000))).toISOString().split('T')[0];
  wtSortDirection: string;
  lmSortDirection: string;
  workdaySortDirection: string;
  addressSortDirection: string;
  subdivisionSortDirection: string;
  statusSortDirection: string;
  dipSortDirection: string;
  disSortDirection: string;
  phaseSortDirection: string;
  techSortDirection: string;
  embedSrc: string;
  wt: string;
  raySignOn: boolean;
  isLoading: boolean;
  loadingStatus: string;
  wtnumber: string;
  lastStatus: string;
  lastComment: string;
  filterPhase: string;
  filterSubdivision: string;
  filterTech: string;
  username: string;
  selectedReport = '';

  imageUploadComplete: boolean;
  imageSearched: boolean;
  isImageLoading: boolean;
  fileNames: string[] = [];
  displayImagesArr: any = {};
  loadedwtnumber: string;
  imageArr: any[] = [];
  readyStatus: string;
  scoutComment: string;
  imgAddress: string;
  imgRetrieved: string;
  isNotReady: boolean;
  scoutNotReadyImageLoaded: boolean;
  showMetabase: boolean;
  showMetabase360: boolean;
  phase: string;
  materialOnScout: boolean;
  ticketLineKey: any;
  admin: boolean;
  techCode: string;
  subvendor: string;
  imagesForViewing: string[] = [];
  displayScoutImages: boolean;
  imgUrl: any = '';
  imgFileTag: string;
  showHouse360Imgs: boolean;

  commentStep: any;

  // Datamodel
  // steps is the actual tickets we load
  steps: StepInterface[] = [];
  // dedupeSteps stops duplicated tickets from making it into the steps obj
  dedupeSteps = false;
  loadtechList: any[] = [];
  VPOList: any[] = [];
  uninstalledItems: any[] = [];

  // IMAGES
  fileLoading: boolean;
  fileArray = [];
  fileList = [];
  imageAdded: boolean;
  chippingImage: boolean;
  fileAddress: string;

  itemList = [
    {
      name: 'S711182000 - vikrell tub',
      itemCode: 'S711182000',
      description: 'vikrell tub',
    },
  ];

  itemCodeSearchText = '';
  addpartlist = [];

  metabaseTkn: string;
  metabaseTknDate: string;

  similarList: any;
  statusCodeConverter: any = [
    { code: 'COM', desc: 'Completed (COM)', value: 'Completed' },
    { code: 'REC', desc: 'Re-Work Crew (REC)', value: 'Re-Work Crew' },
    { code: 'WOB', desc: 'Waiting on Builder (WOB)', value: 'Waiting on Builder' },
    { code: 'WOG', desc: 'Waiting on Gibson (WOG)', value: 'Waiting on Gibson' },
    { code: 'INS', desc: 'Need to Call Inspection (INS)', value: 'Need to Call Inspection' },
    { code: 'LW', desc: 'Left Warehouse (LW)', value: 'Left Warehouse' },
    { code: 'CLD', desc: 'Inspection Called (CLD)', value: 'Inspection Called' },
    { code: 'PTL', desc: 'Partial Inspection Passed (PTL)', value: 'Partial Inspection Passed' },
    { code: 'NEW', desc: 'Scout (NEW)', value: 'Scout' },
    { code: 'NEW', desc: 'New Ticket (NEW)', value: 'New Ticket' },
    { code: 'PUN', desc: 'Future Punch (PUN)', value: 'Future Punch' },
    { code: 'PUN', desc: 'Punch Needed (PUN)', value: 'Punch Needed' },
    { code: 'QAN', desc: 'QA Needed (QAN)', value: 'QA Needed' },
    { code: 'QAR', desc: 'QA Re-Work (QAR)', value: 'QA Re-Work' },
    { code: 'BIN',  desc: 'Builder Inspection Called (BIN)', value: 'Builder Inspection Called'}
  ];

  reportOptions: string[] = ['Workday', 'Punch', 'Pre-Backcharge', 'VPO', 'Tech', 'Complete'];
  report: FormControl = new FormControl('Workday');
  // loadReportStartTime records the time that the report was changed and uses it to block old requests from adding tickets to the state
  loadReportStartTime: Date;

  leadmanOptions: string[] = [];
  leadman: FormControl = new FormControl([]);
  defaultLeadman: FormControl = new FormControl([]);

  statusCodeOptions: string[] = ['Scout', 'QA Needed', 'Punch Needed', 'Called Inspection', 'Re-Work Crew', 'Waiting on Builder', 'Waiting on Gibson', 'QA Re-Work', 'Builder Inspection Called'];
  statusCode: FormControl = new FormControl(['Scout', 'QA Needed', 'Punch Needed', 'Called Inspection', 'Re-Work Crew', 'Waiting on Builder', 'Waiting on Gibson', 'QA Re-Work', 'Builder Inspection Called']);

  citiesOptions: string[] = [];
  cities: FormControl = new FormControl(['']);

  subdivisionsOptions: string[] = [];
  subdivisions: FormControl = new FormControl([]);

  // SEARCH
  // filterSearch represents the text in the search input
  filterSearch = '';
  // filterSearchSubject is used to debounce the input so we only fire a search off after the user is done typing
  filterSearchSubject: Subject<string> = new Subject<string>();
  // filteredSteps records the matching rows and gives back the soNum for filtering the steps
  filteredSteps: Fuzzysort.KeyResults<{searchField: string, soNum: string}>|[] = [];

  isSearchLoading = false;

  originalImages: string[] = [];
  scoutImages: string[] = [];

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private metabaseService: MetabaseService,
    private wasabiApi: WasabiApiService,
    private changeDetectorRef: ChangeDetectorRef,
    breakpointObserver: BreakpointObserver
  ) {
    this.isIos = this.isDeviceIos();
    breakpointObserver.observe([
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.matches) {
        this.isMobile = false;
      }
    });
    breakpointObserver.observe([
      Breakpoints.Small,
    ]).subscribe(result => {
      if (result.matches) {
        this.isMobile = 'sm';
      }
    });
    breakpointObserver.observe([
      Breakpoints.XSmall
    ]).subscribe(result => {
      if (result.matches) {
        this.isMobile = 'xs';
      }
    });
  }

  ngOnInit() {
    this.initalValues();
    this.loadItemList();
    this.loadtech();
    this.loadLeadman();
    this.AddBackorderList();
    this.admin = this.authService.decodedToken.role.toLocaleLowerCase().includes('superadmin');

    const timeout = localStorage.getItem('time');
    const a = this.authService.decodedToken.nameid;
    this.username = a.toLowerCase();
    this.setLeadman();

    if (timeout != null) {
      const date = new Date();
      const hours = ('0' + date.getHours()).slice(-2); // adds the leading zero for value less than 10
      const min = ('0' + date.getMinutes()).slice(-2); // adds the leading zero for value less than 10
      const time = hours + ':' + min;

      if (
        this.username === 'rcasanova' ||
        this.username === 'jmeredith' ||
        this.username === 'bfos' ||
        a.toLocaleUpperCase() === 'BBARTLEY' ||
        a.toLocaleUpperCase() === 'BBANKLER'
      ) {
        this.raySignOn = true;
        this.leadman.setValue([this.leadmanOptions[0]]);
        this.defaultLeadman.setValue([this.leadmanOptions[0]]);
        const reportsParentContainer = document.getElementById('reportsParentContainer');
        if (reportsParentContainer && reportsParentContainer.style){
          reportsParentContainer.style.marginTop = '64px';
        }
      }
    }
    this.startTime();
    if (localStorage.getItem('savedRPT') === 'true') {
      this.loadSavedReport();
    } else {
      this.statusURL();
    }
    this.startListening();
  }

  AddBackorderList() {
    const obj = {
      name: '',
      ItemCode: '',
      ItemDesc: '',
      newQtyOrderedTotal: 0,
      newQtyShippedTotal: 0,
      tickets: []
    };
    this.addpartlist.push(obj);
    this.changeDetectorRef.detectChanges();
  }

  stepItemSelected(
    event: TypeaheadMatch,
    index: number,
  ) {
    if(event.item.kit === "Y"){
      this.addpartlist.splice(index, 1);
      this.alertify.success("Added kit: "+event.item.itemCode)
      this.sageApi.pullReport("HouseEditor/Kit?kit="+event.item.itemCode).subscribe(
        (rows:Array<any>)=>{
          if(Array.isArray(rows)){
            rows.forEach((row) => {
              const item = {
                name: row.ItemCode + " - "+ row.Description,
                ItemCode: row.ItemCode,
                ItemDesc: row.Description,
                newQtyOrderedTotal: row.Quantity
              }
              this.addpartlist.push(item)
            })
          }
        }
      )
    }
    else{
      this.addpartlist[index].ItemCode = event.item.itemCode;
      this.addpartlist[index].ItemDesc = event.item.description;
    }
  }

  RemoveBackorderList(index){
    this.addpartlist.splice(index, 1);
  }

  setLeadman(){
    const a = this.authService.decodedToken.nameid;
    this.username = a.toLowerCase();
    for (const lman of this.leadmanOptions){
      if (
        lman.toLowerCase().includes(this.authService.decodedToken.unique_name.toLowerCase())
        || lman.toLowerCase().includes(this.authService.decodedToken.nameid.slice(1).toLowerCase())
      ){
        this.leadman.setValue([ lman ]);
        if (this.defaultLeadman.value.length == 0){
          this.defaultLeadman.setValue([ lman ]);
        }
      }
    }

    if (this.username === 'rschwausch' || this.username === 'rramirez' || this.username == "krodriguez") {
      this.leadman.setValue(['Ground']);
      if (this.defaultLeadman.value.length == 0){
        this.defaultLeadman.setValue([ 'Ground' ]);
      }
      this.raySignOn = true;
    }

    if (this.leadman.value.length == 0 && this.leadmanOptions.length > 0){
      this.leadman.setValue([this.leadmanOptions[0]]);
      if (this.defaultLeadman.value.length == 0){
        this.defaultLeadman.setValue([this.leadmanOptions[0]]);
      }
    }
  }

  actionClose() {
    this.closeModal();
  }

  setSelection(element, leadman) {
    if (element === null){

    }
    for (let i = 0; i < element.options.length; i++) {
      if (element.options[i].text === leadman) {
        element.options[i].selected = true;

        return;
      }
    }
  }

  startListening() {
    function bindEvent(element, eventName, eventHandler) {
      if (element.addEventListener) {
        element.addEventListener(eventName, eventHandler, false);
      } else if (element.attachEvent) {
        element.attachEvent('on' + eventName, eventHandler);
      }
    }
  }

  printReport() {
    window.print();
  }

  resetReport() {
    this.resetMassUpdateTicketSelectAll();
    this.report.setValue('Workday');
    this.leadman.setValue(this.defaultLeadman.value);
    this.statusCode.setValue(this.statusCodeOptions);
    this.subdivisions.setValue(this.subdivisionsOptions);
    this.filterSearch = '';
    this.callReport('Workday');
  }

  startTime() {
  }

  initalValues(){
    this.index = '0';
    this.wtSortDirection = '';
    this.lmSortDirection = '';
    this.workdaySortDirection = '';
    this.addressSortDirection = '';
    this.subdivisionSortDirection = '';
    this.statusSortDirection = '';
    this.dipSortDirection = '';
    this.disSortDirection = '';
    this.phaseSortDirection = '';
    this.techSortDirection = '';
    this.embedSrc = '';
    this.loadingStatus = '';
    this.wtnumber = '';
    this.lastStatus = '';
    this.lastComment = '';
    this.filterSearch = '';
    this.filterSearchSubject.pipe(debounceTime(300)).subscribe((fs) => {
      this.runSearch(fs);
    });
    this.filterPhase = '';
    this.filterSubdivision = '';
    this.filterTech = '';
    this.username = '';
    this.imageUploadComplete = false;
    this.imageSearched = false;
    this.isImageLoading = false;
    this.loadedwtnumber = '';
    this.readyStatus = '';
    this.scoutComment = '';
    this.imgAddress = '';
    this.imgRetrieved = '';
    this.isNotReady = false;
    this.scoutNotReadyImageLoaded = false;
    this.showMetabase = false;
    this.showMetabase360 = false;
    this.materialOnScout = false;
    this.techCode = '';
    this.subvendor = '';
    this.displayScoutImages = false;
    this.imgFileTag = '';
    this.showHouse360Imgs = true;

    this.statusCode.setValue([...this.statusCodeOptions]);

  }


  openModal(template: TemplateRef<any>, lgModel, step: any = '') {
    this.addpartlist = [];
    this.AddBackorderList();
    this.loadingStatus = '';
    this.importTemplateInfo = {
      templateName: '',
      template: '',
      step: ''
    };

    if (step !== ''){
      this.commentStep = step;
    }
    if ( lgModel === 'Y'){
      this.modalTemplate = this.modalService.show(template, {class: 'modal-xl'});
    }else {
      this.modalTemplate = this.modalService.show(template);
    }

  }

  closeModal() {
    if (!this.imageAdded) {
      this.chippingImage = false;
    }
    this.modalTemplate.hide();
  }

  statusURL() {
    this.newReport = true;
    this.selectedReport = 'SuperReport/Workday';
    this.loadReport('SuperReport/Workday');
  }

  punchURL() {
    this.newReport = true;
    this.selectedReport = 'SuperReport/Punch';
    this.loadReport('SuperReport/Punch');
  }

  techURL() {
    this.newReport = true;
    this.selectedReport = 'SuperReport/Tech';
    this.loadReport('SuperReport/Tech');
  }

  backchargeURL() {
    this.newReport = true;
    this.selectedReport = 'SuperReport/Backcharge';
    this.loadReport('SuperReport/Backcharge');
  }

  vpoURL() {
    this.newReport = true;
    this.selectedReport = 'SuperReport/VPOPunch';
    this.loadReport('SuperReport/VPOPunch');
  }

  completeURL() {
    this.newReport = true;
    this.selectedReport = 'SuperReport/Complete';
    this.loadReport('SuperReport/Complete');
  }

  callReport(report) {
    if (this.leadman.value.length == 0){
      this.setLeadman();
    }
    if (report === 'Workday') {
      this.statusURL();
    } else if (report === 'Punch') {
      this.punchURL();
    } else if (report === 'Pre-Backcharge') {
      this.backchargeURL();
    } else if (report === 'VPO') {
      this.vpoURL();
    } else if (report === 'Tech') {
      this.techURL();
    } else if (report === 'Complete') {
      this.completeURL();
    }
  }

  loadSavedReport() {
    this.steps = [];
    const leadman = JSON.parse(localStorage.getItem('leadmanRPT') || '[]');
    const report = localStorage.getItem('reportRPT');
    const status = JSON.parse(localStorage.getItem('statusRPT') || '[]');
    const search = localStorage.getItem('searchRPT');
    this.leadman = leadman;
    this.selectedReport = report;
    this.filterSearch = search;
    this.statusCode = status;
    this.filteredSteps = JSON.parse(localStorage.getItem('filteredSteps') || '[]');
    this.loadReport(report);
  }

  lookForMatchSO(index){
    const salesOrdNo = this.steps[index].soNum;
    const soWT = this.steps[index].soWT;
    this.similarList = [];
    this.steps.forEach(ticket => {
      if (ticket.soNum === salesOrdNo && ticket.soWT !== soWT){
        this.similarList.push(ticket);
      }
    });
  }

  loadReport(selection: string) {
    this.isLoading = true;
    const reportStart = new Date();
    this.loadReportStartTime = reportStart;
    this.resetMassUpdateTicketSelectAll();
    this.steps = [];
    this.selectedReport = selection;

    const prForLeadman = (leadman?: string) => {
      let report = selection;
      if (leadman && leadman.trim() !== ''){
        report += `?leadman=${leadman}`;
      }
      this.sageApi.pullReport(report, {matchCriteria: [], exp: 60000}).subscribe(
        (wts: any[]) => {
          this.isLoading = false;
          if (wts && Object.keys(wts).length < 1) {
            return;
          }
          if (this.selectedReport == selection && reportStart == this.loadReportStartTime){
            if (Array.isArray(wts)) {
              wts.forEach((w) => {
                const matching = this.steps.find(a => a.soNum == w.SalesOrderNo && a.soWT == w.WTNumber);
                if (!matching || !this.dedupeSteps){
                  // @ts-ignore
                  const obj: StepInterface = {
                    soNum: w.SalesOrderNo,
                    soWT: w.WTNumber,
                    wtNumber: w.SalesOrderNo + ['-'] + w.WTNumber + ['-'] + '000',
                    address: w.ShipToName,
                    city: w.ShipToCity || '',
                    phase: w.HdrParentItemCode.slice(1),
                    subdivision: w.UDF_SUBDIVISION,
                    description: w.ItemCodeDesc,
                    comment: w.UDF_TRACKING_COMMENT !== '' ? 'Comment: ' + w.UDF_TRACKING_COMMENT : '',
                    dip: w.DIP,
                    dis: w.DIS,
                    flagged: w.Flagged,
                    lineKey: w.Expr1,
                    techName: w.techName,
                    techCode: w.UDF_TECHNICIAN_CODE,
                    newTech: '',
                    leadman: w.UDF_LEADMAN,
                    newLeadman: '',
                    // tslint:disable-next-line:max-line-length
                    leadmanInit: (w.UDF_LEADMAN === 'ROBERT (BOBBY) LAQUE' || w.UDF_LEADMAN === 'GEORGE (JORGE) CERVANTES') ? w.UDF_LEADMAN.split(' ')[1][1] + w.UDF_LEADMAN.split(' ')[2][0] : w.UDF_LEADMAN.split(' ')[0][0] + w.UDF_LEADMAN.split(' ')[1][0],
                    statusCode: w.DIP < 0 ?
                      w.HdrParentItemCode === 'ZPUNCH' || w.HdrParentItemCode === 'ZPUNCHB' || w.HdrParentItemCode === 'ZPUNCHT' ?
                        'Future Punch' : 'Scout'
                      : w.StatusCode === 'QA Complete' ? 'Builder Inspection' : w.StatusCode,
                    scheduleDate:
                      w.HdrParentItemCode === 'ZPUNCH' ||
                      w.HdrParentItemCode === 'ZPUNCHB' ||
                      w.HdrParentItemCode === 'ZPUNCHT'
                        ? w.PromiseDate
                        : w.UDF_DATE_SCHEDULED,
                    searchField:
                      w.ShipToName +
                      ['-'] +
                      w.ShipToCity || '' +
                      ['-'] +
                      w.HdrParentItemCode.slice(1) +
                      ['-'] +
                      w.UDF_SUBDIVISION +
                      ['-'] +
                      w.ItemCodeDesc +
                      ['-'] +
                      w.UDF_TRACKING_COMMENT +
                      ['-'] +
                      w.techName +
                      ['-'] +
                      w.StatusCode +
                      ['-'] +
                      // tslint:disable-next-line:max-line-length
                      ((w.UDF_LEADMAN === 'ROBERT (BOBBY) LAQUE' || w.UDF_LEADMAN === 'GEORGE (JORGE) CERVANTES') ? w.UDF_LEADMAN.split(' ')[1][1] + w.UDF_LEADMAN.split(' ')[2][0] : w.UDF_LEADMAN.split(' ')[0][0] + w.UDF_LEADMAN.split(' ')[1][0]),
                    scoutComment: w.UDF_SCOUT_LM_DESCRIPTION,
                    scoutStatus: w.UDF_SCOUT_LM_READY,
                    scoutDate: (w.UDF_SCOUT_LM_SUBMIT_DATE).split('T')[0],
                    uninstalled: w.Unshipped,
                    pipesOut: w.UDF_SCOUT_LM_PIPES_OUT || 0,
                    pipesShading: w.UDF_SCOUT_LM_PIPES_SHADING || 0,
                    pipesDamaged: w.UDF_SCOUT_LM_DMG_PIPES || 0,
                    // tslint:disable-next-line:radix
                    pipesOutExtended: parseInt(w.UDF_SCOUT_LM_DMG_PIPES) || 0,
                    tubBox: w.UDF_SCOUT_LM_TUB_BOX || 0,
                    soQueued: w.soQueued,
                    wtQueued: w.wtQueued,
                    updated: '',
                    womsStatus: w.UDF_WOMS_STATUS || '',
                    womsNewStatus: '',
                    womsType: w.UDF_WOMS_TYPE || '',
                    womsNumber: w.womsNumber || '',
                    assignedTo: w.AssignedLeadman || '',
                    // tslint:disable-next-line:max-line-length
                    reassignedBy: w.UDF_WT_REASSIGNED_BY === `rcasanova` ?
                      `Ray` : w.UDF_WT_REASSIGNED_BY === `jmeredith` ? `John` :
                        w.UDF_WT_REASSIGNED_BY === `bfos` ? `Brandon` :
                          w.UDF_WT_REASSIGNED_BY === `rschwausch` ? `Ryan` :
                            w.UDF_WT_REASSIGNED_BY === `rramirez` ? `Robert` :
                              w.UDF_WT_REASSIGNED_BY === `bbartley` ? `Bret` : w.UDF_WT_REASSIGNED_BY || ``,
                    shipTo: w.shipToName,
                    subvendor: w[`UDF_SUB_VENDOR`] || '  ',
                    alsoApprove: false,
                    newStatCode: w.DIP < 0 ?
                      w.HdrParentItemCode === 'ZPUNCH' || w.HdrParentItemCode === 'ZPUNCHB' || w.HdrParentItemCode === 'ZPUNCHT' ?
                        'Future Punch' : 'Scout'
                      : w.StatusCode === 'QA Complete' ? 'Builder Inspection' : w.StatusCode,
                    massUpdateSelected: false,
                    currentStatus: w.ActualStatusCode,
                    massUpdatePendingStatus: w.PendingStatusChange,
                    TrimDate: w.TrimDate === undefined || w.TrimDate === null ? '' : w.TrimDate,
                    vpos: [],
                    unitprice: w.UnitPrice === undefined ? '0' : w.UnitPrice
                  };
                  this.steps.push(obj);
                }
              });
            }
          }
        },
        (err) => {
          console.log(err);
        },
        () => {
          this.orderArray('dip', 'asc');
          this.orderArray('flagged', 'desc');
          this.loadVPOs();
          this.loadCities();
          this.loadSubdivisions();
        }
      );
    };
    if (this.leadman.value.sort().join(',') == this.leadmanOptions.sort().join(',')){
      prForLeadman();
    }else{
      this.leadman.value.forEach(prForLeadman);
    }
  }

  vpoFilter(step){
    let flag = '';
    if (step.vpos.length > 0){
      step.vpos.forEach(v => {
        if (v.StatusCode === 'COM'){
          flag = 'red';
        }else if (flag !== 'R'){
          flag = 'yellow';
        }
      });
    }
    return flag;
  }

  checkForBIN(step){
    if(step.currentStatus == "BIN" && step.dis > 2){
      return 'red';
    }
  }

  loadtech() {
    this.isLoadingTech = true;
    const nameSearch = '';
    const techNames = this.sageApi.pullReport('SuperReport/QAReports/TechList').subscribe(
      (names: any[]) => {
        this.loadtechList = [];

        if (Array.isArray(names)) {
          names.forEach(name => {
            const obj = {
              tech: name.TechnicianNo,
              firstName: name.FirstName,
              lastName: name.LastName,
              deptno: name.TechnicianDeptNo,
              techCode: name.TechnicianDeptNo + name.TechnicianNo,
              assignedLeadman: name.UDF_ASSIGNED_LEADMAN,
              ScheduleGroup: name.ScheduleGroup,
              internalTech: name.IsInternal
            };
            this.loadtechList.push(obj);
          });
        }
      },
      err => {
        this.isLoading = false;
        this.isLoadingTech = false;
        this.loadingStatus = 'Failed to load Techs';
        console.log(err);
      },
      () => {
        this.techLoaded = true;
        this.isLoadingTech = false;
      }
    );
  }

  loadVPOs(){

    if (this.VPOList.length <= 0){
      this.VPOList = [];
      this.sageApi.pullReport('SuperReport/VPO').subscribe( vp => {
        if (Array.isArray(vp)) {
          // console.log('vpos', vp);
          vp.forEach(v => {
            const obj = {
              SalesOrderNo: v.SalesOrderNo,
              WTNumber: v.WTNumber,
              VPOIC: v.VPOParentIC,
              StatusCode: v.StatusCode,
              tranAmt: v.TranAmt
            };
            this.VPOList.push(obj);
          });
          // console.log('vpos', this.VPOList);
        }
        this.steps.forEach(wt => {
          const vpoWT = [];
          wt.vpos = [];
          this.VPOList.forEach(vo => {
            if (wt.soNum === vo.SalesOrderNo){
              vpoWT.push(vo);
            }
          });
          wt.vpos = vpoWT;
        });
      });
    }else{
      this.steps.forEach(wt => {
        const vpoWT = [];
        wt.vpos = [];
        this.VPOList.forEach(vo => {
          if (wt.soNum === vo.SalesOrderNo){
            vpoWT.push(vo);
          }
        });
        wt.vpos = vpoWT;
      });
    }
  }

  reloadVPOs(){
    this.VPOList = [];
    this.sageApi.pullReport('SuperReport/VPO').subscribe( vp => {
      if (Array.isArray(vp)) {
        vp.forEach(v => {
          const obj = {
            SalesOrderNo: v.SalesOrderNo,
            WTNumber: v.WTNumber,
            VPOIC: v.VPOParentIC,
            StatusCode: v.StatusCode,
          };
          this.VPOList.push(obj);
        });
      }

      this.steps.forEach(wt => {
        const vpoWT = [];
        this.VPOList.forEach(vo => {
          if (wt.soNum === vo.SalesOrderNo){
            vpoWT.push(vo);
          }
        });
        wt.vpos = vpoWT;
      });
    });
  }

  loadLeadman() {
    this.leadmanOptions = [];
    this.sageApi.pullReport('Leadman', {matchCriteria: [], exp: 60000 * 20}).subscribe(lm => {
        if (Array.isArray(lm)) {
          this.leadmanOptions.push('Ground');
          lm.forEach(l => {
            this.leadmanOptions.push(l.UDF_LEADMAN);
          });
        }
      },
      err => {
        this.isLoading = false;
        this.isLoadingLeadman = false;
        this.loadingStatus = 'Failed to load Leadman';
        this.alertify.error(`Could not load "Leadman"! \nCheck with IT if this persists.`);
        console.log(err);
      },
      () => {
        this.leadmanLoaded = true;
        this.isLoadingLeadman = false;
        this.setLeadman();
      });
  }

  resetWorkDirections(doNotResetColumn?: 'wtNumber'|'scheduleDate'|'leadman'|'address'|'phase'|'subdivision'|'statusCode'|'dip'|'dis'|'techName'){
    if (doNotResetColumn != 'wtNumber'){
      this.wtSortDirection = '';
    }
    if (doNotResetColumn != 'scheduleDate'){
      this.workdaySortDirection = '';
    }
    if (doNotResetColumn != 'leadman'){
      this.lmSortDirection = '';
    }
    if (doNotResetColumn != 'address'){
      this.addressSortDirection = '';
    }
    if (doNotResetColumn != 'phase'){
      this.phaseSortDirection = '';
    }
    if (doNotResetColumn != 'subdivision'){
      this.subdivisionSortDirection = '';
    }
    if (doNotResetColumn != 'statusCode'){
      this.statusSortDirection = '';
    }
    if (doNotResetColumn != 'dip'){
      this.dipSortDirection = '';
    }
    if (doNotResetColumn != 'dis'){
      this.disSortDirection = '';
    }
    if (doNotResetColumn != 'techName'){
      this.techSortDirection = '';
    }
  }

  orderArray(column, order) {
    this.resetWorkDirections(column);
    this.steps.sort(compareValues(column, order));

    // tslint:disable-next-line:no-shadowed-variable
    function compareValues(key, order = 'asc') {
      // tslint:disable-next-line:only-arrow-functions
      return function(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }

        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order === 'desc' ? comparison * -1 : comparison;
      };
    }
    if (column === 'wtNumber') {
      if (this.wtSortDirection === 'asc' || this.wtSortDirection === '') {
        this.wtSortDirection = 'desc';
      } else {
        this.wtSortDirection = 'asc';
      }
    }
    if (column === 'leadman') {
      if (this.lmSortDirection === 'asc' || this.lmSortDirection === '') {
        this.lmSortDirection = 'desc';
      } else {
        this.lmSortDirection = 'asc';
      }
    }
    if (column === 'scheduleDate') {
      if (this.workdaySortDirection === 'asc' || this.workdaySortDirection === '') {
        this.workdaySortDirection = 'desc';
      } else {
        this.workdaySortDirection = 'asc';
      }
    }
    if (column === 'address') {
      if (this.addressSortDirection === 'asc' || this.addressSortDirection === '') {
        this.addressSortDirection = 'desc';
      } else {
        this.addressSortDirection = 'asc';
      }
    }
    if (column === 'phase') {
      if (this.phaseSortDirection === 'asc' || this.phaseSortDirection === '') {
        this.phaseSortDirection = 'desc';
      } else {
        this.phaseSortDirection = 'asc';
      }
    }
    if (column === 'subdivision') {
      if (this.subdivisionSortDirection === 'asc' || this.subdivisionSortDirection === '') {
        this.subdivisionSortDirection = 'desc';
      } else {
        this.subdivisionSortDirection = 'asc';
      }
    }
    if (column === 'statusCode') {
      if (this.statusSortDirection === 'asc' || this.statusSortDirection === '') {
        this.statusSortDirection = 'desc';
      } else {
        this.statusSortDirection = 'asc';
      }
    }
    if (column === 'dip') {
      if (this.dipSortDirection === 'asc' || this.dipSortDirection === '') {
        this.dipSortDirection = 'desc';
      } else {
        this.dipSortDirection = 'asc';
      }
    }
    if (column === 'dis') {
      if (this.disSortDirection === 'asc' || this.disSortDirection === '') {
        this.disSortDirection = 'desc';
      } else {
        this.disSortDirection = 'asc';
      }
    }
    if (column === 'techName') {
      if (this.techSortDirection === 'asc' || this.techSortDirection === '') {
        this.techSortDirection = 'desc';
      } else {
        this.techSortDirection = 'asc';
      }
    }
  }

  callMetabase(choice) {
    if (choice === 'show') {
      this.showMetabase = true;
    } else if (choice === 'hide') {
      this.showMetabase = false;
    }
  }

  modalViewerSource() {
    if (this.imgFileTag === 'pdf') {
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://drive.google.com/viewerng/viewer?embedded=true&url=' + this.imgUrl);
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.imgUrl);
    }
  }

  onBlowUpImage(imgUrl: any) {
    this.imgFileTag = imgUrl[0].slice(-3);
    this.imgUrl = '';
    this.imgUrl = imgUrl;
  }

  imageName(imgArrIndex: number) {
    let name = this.imagesForViewing[imgArrIndex].split('%2F')[this.imagesForViewing[imgArrIndex].split('%2F').length - 1];
    if (name !== null){
      name = name.replace(/%20/g, ' ');
    }
    const namesplit = name.split('/');
    return 'Open ' + namesplit[namesplit.length - 1];
  }

  onInnerScoutChoiceClicked(choice: string) {
    const textArea: any = document.getElementById('scout-text-area');
    const container = document.getElementById('choice-container');
    console.dir(`SCOUT PHASE ${this.phase}`);

    if (this.phase === 'TOPOUT') {
      this.readyStatus = choice;
    } else {
      this.readyStatus = choice;
    }

    if (choice === 'n') {
      this.isNotReady = true;
      textArea.placeholder = 'DON\'T FORGET TO UPLOAD IMAGES!';
    } else {
      this.isNotReady = false;
      textArea.placeholder = 'Scout Comments';
    }
  }

 onFileChanged(event) {
    this.onGetTicketImages(this.imgAddress).then(() => {
      this.originalImages = this.fileNames;
      this.fileNames = [];
    });
    this.imageUploadComplete = false;
    this.isImageLoading = true;
    this.imageArr = [];
    this.fileNames = [];
    let size = 0;
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        this.fileNames.push(files[index].name);

        const obj = {
          name: this.loadedwtnumber+files[index].name,
          data: files[index],
        };

        size += files[index].size
        this.imageArr.push(obj);

      }
    }
    if (files.length > 0) {
      if(size > 30000000){
        this.alertify.warning("File size may be too large. Please select fewer photos.");
      }
      this.onFileLoadComplete(this.imgAddress);
    }
  }

  onImgAddressSelected(address: string) {
    const authToken = localStorage.getItem('metabaseToken');
    this.metabaseService.metabaseDashboardUpdate(address, authToken).subscribe(item => {
    }, (error) => {
      console.error('Error updating Metabase House 360 Report: ', error);
    }, () => {
      const houseReportFrame: any = document.getElementById('house360ReportFrame');
      houseReportFrame.src = `https://metabase.gibsonplumbing.com/public/dashboard/14fc7d7b-cfd2-4a71-b215-e96872b737dc?select_address=${address}#hide_parameters=address`;
      this.loadImagesForViewing();
    });
  }

  loadImagesForViewing() {
    this.imageSearched = true;
    this.onGetTicketImages(this.imgAddress).then(() => {
      this.imagesForViewing = this.fileNames;
      this.fileNames = [];
    });
    this.displayScoutImages = true;
  }

  onFileLoadComplete(address: string) {
    this.wasabiApi.uploadImage(address,  this.imageArr).subscribe((res) => {
      this.imageUploadComplete = true;
      this.scoutNotReadyImageLoaded = true;
      console.log(res);
    }, err => {
      console.log(err);
      if (err.status === 200) {
        this.scoutNotReadyImageLoaded = true;
      } else {
        console.log(err.status);
      }
      this.fileNames = [];
      this.isImageLoading = false;
    }, () => {
      this.fileNames = [];
      this.isImageLoading = false;
      this.scoutNotReadyImageLoaded = true;
      this.loadImagesForViewing();
    });
  }

  async onGetTicketImages(address: string) {
    const response = await this.wasabiApi.getImages(address);
    try {
      this.fileNames = response;
    } catch (error) {
      this.alertify.error('There was an error loading the images for this house - please try again');
      console.warn(`Error loading images: ${error.error.Message}`);
    }
  }


  loadFileNames() {
    const route = `QAWorkticket/images?wtNumber=${this.loadedwtnumber}&address=${this.fileAddress}`;
    this.fileList = [];
    this.sageApi.pullReport(route).subscribe((response: any) => {
      const splitFiles = response.split(', /sage/SO_WT_Files/');
      splitFiles.forEach(file => {
        this.fileList.push({ name: file.split(this.fileAddress + '\\')[1]});
      });
    }, err => {
      console.log(err);
      this.alertify.error(err.error.Message);
    }, () => {

    });
  }

  loadImages() {
    const folder = this.imgAddress + '/scout';
    // replace with api
    const route = `QAWorkticket/images?wtNumber=${this.loadedwtnumber}&address=${this.fileAddress}`;
    this.sageApi.pullReport(route).subscribe(
      (res) => {
        this.displayImagesArr = {};
        let lth = 0;
        for (const key in res) {
          this.displayImagesArr[key] = 'data:image/png;base64,' + res[key];
          lth++;
        }
        this.displayImagesArr.length = lth;
        this.isImageLoading = false;
      },
      (err) => {
        console.log(err);
        this.isImageLoading = false;
      },
      () => {
        this.isImageLoading = false;
        this.isNotReady = false;
      }
    );
  }

  updateTrackingComment(){
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const username = this.authService.decodedToken.nameid;
    const timestamp = `${month}/${day}/${year} - ${username}`;

    const wtnumberSplit = this.commentStep.wtnumber.split('-');
    const salesOrderNumber = wtnumberSplit[0];
    const wtNumber = wtnumberSplit[1];
    const wtStep = wtnumberSplit[2];
    const flag = 'Y';
    this.isLoading = true;
    const observables = [];

    const obj = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      salesorderno: salesOrderNumber,
      wtNumber,
      trackingComment: this.commentStep.comment + '-' + this.authService.decodedToken.nameid + ' ' + month + '/' + day + '/' + year,
      onlyComment : 'Y'
    };

    observables.push(this.sageApi.putRequest('SuperReport/QAReports/SaveStatus', obj));

    concat(...observables).subscribe(
      (e) => {
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
        this.loadingStatus = 'Failed - check console for error';
        this.alertify.error(err.error.Message);
      },
      () => {
        this.isLoading = false;
        this.onResetDataClicked();
        this.loadingStatus = 'Loaded Successfully - Changes Committed to SAGE';
        this.alertify.success(this.loadingStatus);
      }
    );
  }

  updateMaterial(Workticket: any) {
    const soNum = Workticket.soNum;
    const wtNum = Workticket.soWT;

    const observables = [];
    const editedLinesArr = [];
    const addedLinesArr = [];
    const endpoint = 'UpdMatUsageSubmit/App';
    const foundError = false;
    const faillist = '';

    this.addpartlist.forEach( item => {

      if (item.ItemCode) {
        const arr = [
          item.ItemCode, // 0
          item.newQtyOrdered, // 1
          0, // 2
          wtNum, // 3
          item.lineKey, // 4
          'Added through QA Report', // 5
          0 // 6
        ];
        addedLinesArr.push(arr);
      }
    });

    const object = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      salesOrderNo: soNum,
      SystemOperation: 'QA Reports',
      Status: 'none',
      wtNum,
      editedLines: [],
      addedLines: addedLinesArr
    };
    if (foundError){
      this.alertify.error(faillist);
      return;
    }

    observables.push(this.sageApi.putRequest(endpoint, object));

    concat(...observables).subscribe((response: any) => {
      this.alertify.success('Success');
    }, err => {
      console.log(err);
      if (err.error !== null){
        this.alertify.error(err.error.text);
      }
    }, () => {
      this.alertify.success('Material Updated on ' + soNum + '-' + wtNum);
      this.addpartlist = [];
    });
  }

  updatePay(Workticket: any) {

    const today = new Date();
    const date = today.getDate();
    const day = today.getDay();
    let friday;
    // saterday = 6 goes to next friday
    if (day === 6) {
      friday = today.setDate(date + 6);
    }else if (day === 0) {
      friday = today.setDate(date + 5);
    }else if (day === 1) {
      friday = today.setDate(date + 4);
    }else {
      friday = today.setDate(date + (12 - day));
    }
    const datePipe = new DatePipe('en-US');
    const fridaydate = datePipe.transform(friday, 'MM-dd-yyyy');

    const soNum = Workticket.soNum;
    const wtNum = Workticket.soWT;

    const observables = [];
    const endpoint = 'Punch/Pay';
    const item = Workticket;
    const tech = item.newTech !== '' ? item.newTech.split(' - ')[1] : item.techCode;
    const techName = item.newTech !== '' ? item.newTech.split(' - ')[0] : item.techName;
    const statobj = this.statusCodeConverter.find(i => i.value.toLocaleLowerCase().includes(Workticket.newStatCode.toLocaleLowerCase()));

    if (statobj === 'COM' && this.selectedReport == 'SuperReport/Punch?leadman=') {
      const object = {
        username: this.authService.decodedToken.nameid,
        password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
        salesorderno: soNum,
        wtnum: wtNum,
        techcode: tech,
        laborcost: '',
      };
      observables.push(this.sageApi.putRequest(endpoint, object));

      concat(...observables).subscribe((response: any) => {

      }, err => {
        console.log(err);
        if (err.error !== null) {
          this.alertify.error(err.error.text);
        }
      }, () => {
        this.alertify.success('Pay Date Applied to ' + techName + ' for ' + fridaydate);
      });
    }
  }

  async loadImagesS3() {
    this.isLoading = true;
    await this.onGetTicketImages(this.imgAddress).then(() => {
      this.imagesForViewing = this.fileNames;
      this.fileNames = [];
    });
    var distinctImages: string[] = [];

    distinctImages = this.imagesForViewing.filter(
      (x) => (this.originalImages.indexOf(x) == -1)
    );
    var stringOfImages = "";
    console.log(distinctImages);
    distinctImages.forEach((element) => {
      if (!element.includes(".pdf")) {
        let edit = element;
        if (element.includes(" ")) {
          edit = element.split(' ').join("%20");
        }
        if (stringOfImages == "") {
          stringOfImages = edit;
        } else {
          stringOfImages += " " + edit;
        }
      }
    });


    this.isLoading = true;
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const username = this.authService.decodedToken.nameid;
    const timestamp = `${month}/${day}/${year} - ${username}`;
    const wtnumberSplit = this.wtnumber.split('-');
    const regExQuotes = /['"]/g;
    const regExAt = /[@]/g;
    const regExAnd = /[&]/g;
    const images = this.sageApi.imageUrlListReports.slice(-4);
    let cleanComment = '';
    if (this.scoutComment !== null || this.scoutComment !== undefined ){
      cleanComment = this.scoutComment
        .replace(regExQuotes, '')
        .replace(regExAt, 'at')
        .replace(regExAnd, 'and');
    }
    cleanComment += ' ' + timestamp;
    let imgComment = '';

    if (Array.isArray(images)) {
      images.forEach(img => {
        imgComment += img + '\n ';
      });
    } else if (images) {
      imgComment = images;
    }

    if (cleanComment === '') {
      cleanComment = '   ';
    }

    const salesOrderNumber = wtnumberSplit[0];
    const wtNumber = wtnumberSplit[1];
    const wtStep = wtnumberSplit[2];

    const observables = [];

    const soScout = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      salesorderno: salesOrderNumber,
      chippingLines: [],
    };

    observables.push(this.sageApi.putRequest('UpdScoutSO', soScout));

    const wtScout = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      salesorderno: salesOrderNumber,
      wtnumber: wtNumber,
      scoutDescription: cleanComment,
      readyStatus: this.readyStatus,
      pipesOutOfTheWall: this.steps[this.index].completePipes || 0,
      pipesShadingTheWall: this.steps[this.index].partialPipes || 0,
      pictureLinks: stringOfImages,
      materialOnScout: this.materialOnScout,
      pipesDamaged: this.steps[this.index].damagedPipes || 0,
      pipesExtendedOutOfTheWall: this.steps[this.index].pipesOutExtended || 0,
      tubBox: this.steps[this.index].tubBox || 0,
    };


    observables.push(this.sageApi.putRequest('UpdScoutWT', wtScout));

    concat(...observables).subscribe((e) => {
        this.isLoading = true;
      },
      (err) => {
        console.log(err);
        setTimeout(() => {
          this.isLoading = false;
          this.loadReport('SuperReport/Workday');
        }, 3000);
        this.loadingStatus = 'Failed - look at console for Error';
        this.alertify.error(err.error.Message);
      },
      () => {
        this.isLoading = false;
        this.onResetDataClicked();
        this.loadingStatus = 'Loaded Successfully - Changes Committed to SAGE';
        this.alertify.success(this.loadingStatus);
        this.loadReport('SuperReport/Workday');
        this.closeModal();
      }
    );
  }

  onScoutSubmit() {

    this.onGetTicketImages(this.imgAddress).then(() => {
      this.imagesForViewing = this.fileNames;
      this.fileNames = [];
    });
    var distinctImages: string[] = [];

    distinctImages = this.imagesForViewing.filter(
      (x) => (this.originalImages.indexOf(x) == -1)
    );
    var stringOfImages = "";
    console.log(distinctImages);
    distinctImages.forEach((element) => {
      if (!element.includes(".pdf")) {
        let edit = element;
        if (element.includes(" ")) {
          edit = element.split(' ').join("%20");
        }
        if (stringOfImages == "") {
          stringOfImages = edit;
        } else {
          stringOfImages += " " + edit;
        }
      }
    });

    this.isLoading = true;
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const username = this.authService.decodedToken.nameid;
    const timestamp = `${month}/${day}/${year} - ${username}`;
    const wtnumberSplit = this.wtnumber.split('-');
    const regExQuotes = /['"]/g;
    const regExAt = /[@]/g;
    const regExAnd = /[&]/g;
    const images = this.sageApi.imageUrlListReports.slice(-4);
    this.originalImages = [];
    let cleanComment = '';
    if (this.scoutComment !== null || this.scoutComment !== undefined ){
      cleanComment = this.scoutComment
        .replace(regExQuotes, '')
        .replace(regExAt, 'at')
        .replace(regExAnd, 'and');
    }
    cleanComment += ' ' + timestamp;
    let imgComment = '';

    if (Array.isArray(images)) {
      images.forEach(img => {
        imgComment += img + '\n ';
      });
    } else if (images) {
      imgComment = images;
    }

    if (cleanComment === '') {
      cleanComment = '   ';
    }

    const salesOrderNumber = wtnumberSplit[0];
    const wtNumber = wtnumberSplit[1];
    const wtStep = wtnumberSplit[2];

    const observables = [];

    const soScout = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      salesorderno: salesOrderNumber,
      chippingLines: [],
    };

    observables.push(this.sageApi.putRequest('UpdScoutSO', soScout));

    const wtScout = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      salesorderno: salesOrderNumber,
      wtnumber: wtNumber,
      scoutDescription: cleanComment,
      readyStatus: this.readyStatus,
      pipesOutOfTheWall: this.steps[this.index].completePipes || 0,
      pipesShadingTheWall: this.steps[this.index].partialPipes || 0,
      pictureLinks: stringOfImages,
      materialOnScout: this.materialOnScout,
      pipesDamaged: this.steps[this.index].damagedPipes || 0,
      pipesExtendedOutOfTheWall: this.steps[this.index].pipesOutExtended || 0,
      tubBox: this.steps[this.index].tubBox || 0,
    };


    observables.push(this.sageApi.putRequest('UpdScoutWT', wtScout));

    concat(...observables).subscribe((e) => {
        this.isLoading = true;
      },
      (err) => {
        console.log(err);
        setTimeout(() => {
          this.isLoading = false;
          this.loadReport('SuperReport/Workday');
        }, 3000);
        this.loadingStatus = 'Failed - look at console for Error';
        this.alertify.error(err.error.Message);
      },
      () => {
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
        this.onResetDataClicked();
        this.loadingStatus = 'Loaded Successfully - Changes Committed to SAGE';
        this.alertify.success(this.loadingStatus);
        this.loadReport('SuperReport/Workday');
      }
    );

  }

  onResetDataClicked() {
    this.wtnumber = '';
    this.loadingStatus = '';
    this.lastComment = '';
    this.lastStatus = '';
    this.scoutComment = '';
    this.readyStatus = '';
    this.isNotReady = true;
    this.scoutNotReadyImageLoaded = false;
    this.materialOnScout = false;
    this.ticketLineKey = '';
    this.originalImages = [];
  }

  getIncludedSteps(): StepInterface[] {
    const isteps: StepInterface[] = [];
    for (const step of this.steps){
      if (this.isStepIncluded(step)){
        isteps.push(step);
      }
    }
    return isteps;
  }

  isStepIncluded(step: StepInterface){
    const filterIncluded = this.isIncludedInStatus(step);
    const subdivionIncluded = this.isIncludedInSubdivisions(step);
    const searchIncluded = this.isIncludedInSearch(step);
    if (
      filterIncluded
      && subdivionIncluded
      && searchIncluded
    ){
      return true;
    }
    return false;
  }

  isIncludedInSearch(isContainedStep: StepInterface){
    if (this.filterSearch.trim() == ''){
      return true;
    }

    // If this is complaining, please resave the file and it will fix itself.
    const matchingFilteredStep = this.filteredSteps.find(fs => (fs as any).obj.soNum == isContainedStep.soNum); // painful but necessary any.
    if (matchingFilteredStep){
      return true;
    }
    return false;
  }

  isIncludedInStatus(isIncludedStep: StepInterface){
    if (this.statusCode.value.sort().join(',') == this.statusCodeOptions.sort().join(',')){
      return true;
    }
    if (this.statusCode.value.includes(isIncludedStep.newStatCode)){
      return true;
    }
    return false;
  }

  isIncludedInSubdivisions(isIncludedStep: StepInterface){
    const stepSubdiv = (isIncludedStep.subdivision == undefined ? '' : isIncludedStep.subdivision).trim().toLowerCase();
    if (this.subdivisions.value.sort().join(',') == this.subdivisionsOptions.sort().join(',')){
      return true;
    }
    if (this.subdivisions.value.includes(stepSubdiv)){
      return true;
    }
    return false;
  }

  /*
    Adds a trimmed down version of filterSearch to the filterSearchSubject
  */
  onSearch() {
    if (this.filterSearch.trim() != ''){
      this.isSearchLoading = true;
      this.filterSearchSubject.next(this.filterSearch.trim());
    }
  }

  /*
    Runs a filter on the existing steps and returns an array with the soNum for filtering out tickets without a matching soNum
  */
  runSearch(filterSearch: string){
    this.isSearchLoading = false;
    const toSort = this.steps.map(a => ({searchField: a.searchField, soNum: a.soNum}));
    const s = fuzzysort.go<StepInterface>(filterSearch, toSort as StepInterface[], {key: 'searchField', threshold: -1000});
    this.filteredSteps = s;
  }

  loadUninstalledMaterial(uninstalledMaterial: string) {
    this.uninstalledItems = [];
    this.isLoading = true;
    const uninstalledUrlAddon = `Unshipped/${uninstalledMaterial}`;
    this.sageApi.pullReport(uninstalledUrlAddon).subscribe(
      (wts: any[]) => {
        if (wts && Object.keys(wts).length < 1) {
          return;
        }
        if (Array.isArray(wts)) {
          wts.forEach(w => {
            const obj = {
              soNum: w.SalesOrderNo,
              soWT: w.JT158_WTNumber,
              wtNumber: w.SalesOrderNo + ['-'] + w.JT158_WTNumber,
              lineKey: w.LineKey,
              itemCode: w.ItemCode,
              itemNum: w.ItemType,
              itemDesc: w.ItemCodeDesc,
              comment: w.CommentText,
              location: w.UDF_LOCATION,
              ordered: w.QuantityOrdered,
              shipped: w.QuantityShipped
            };
            this.uninstalledItems.push(obj);
          });
        }
      },
      err => {
        console.log(err);
        this.isLoading = false;
      },
      () => {
        this.orderArray('dip', 'asc');
        this.orderArray('flagged', 'desc');
        this.isLoading = false;
      }
    );
  }

  onTechChange(tech: any) {
    const techIndex = tech.split(' - ')[2];
    // if (this.loadtechList[techIndex].assignedLeadman === null || this.loadtechList[techIndex].assignedLeadman === undefined){
    //   this.alertify.error('Assign Tech Failure: No Assigned Leadman Found...');
    //   return;
    // }
    if (this.loadtechList[techIndex].assignedLeadman === null || this.loadtechList[techIndex].assignedLeadman === undefined){
      const observables = [];
      const endpoint = "SuperReport/BadLeadman";
      const object = {
        username: this.authService.decodedToken.nameid,
        password:
          this.authService.decodedToken[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication"
            ],
        tech: this.loadtechList[techIndex].tech,
        firstName: this.loadtechList[techIndex].firstName,
        lastName: this.loadtechList[techIndex].lastName,
        deptno: this.loadtechList[techIndex].deptno,
        techCode: this.loadtechList[techIndex].techCode,
        assignedLeadman: this.loadtechList[techIndex].assignedLeadman,
        ScheduleGroup: this.loadtechList[techIndex].ScheduleGroup,
        internalTech: this.loadtechList[techIndex].internalTech
      };

      observables.push(this.sageApi.putRequest(endpoint, object));

      concat(...observables).subscribe(
        (response: any) => {
          console.log(response);
        },
        (err) => {
          // this.alertify.error("Error ");
        },
        () => {
          // console.log("Sent to API Log");
        }
      );
    }
    if (this.loadtechList[techIndex].internalTech === 'Y'){
      this.steps[this.index].womsNewStatus = '';
    }else if (this.loadtechList[techIndex].internalTech === 'N' && ['LOSS', 'LOSSD'].includes(this.steps[this.index].womsStatus)) {
      this.steps[this.index].womsNewStatus = 'MGTRV';
    }else{
      this.steps[this.index].womsNewStatus = this.steps[this.index].womsStatus;
    }
  }

  loadData(wt: string) {
    this.wtnumber = wt;
    this.isLoading = true;
    const wtnumberSplit = this.wtnumber.split('-');

    const salesOrderNumber = wtnumberSplit[0];
    const  wtNumber = wtnumberSplit[1];
    const  wtStep = wtnumberSplit[2];

    this.loadingStatus = 'Loading work ticket details';
    const loadWorkTicket = this.sageApi.pullReport('/SuperReport/QAReports/StatAndCom?SalesOrderNo=' + salesOrderNumber + '&wtNum=' + wtNumber + '&wtStep=' + wtStep).subscribe(
      (workticket: any) => {
        this.lastStatus = workticket[0].StatusCode;
        this.lastComment = workticket[0].UDF_TRACKING_COMMENT;

        this.onContinueClicked();
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
        this.loadingStatus = 'Failed - Status ' + ' Reason: look at console';
      }
    );
  }

  onContinueClicked() {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const username = this.authService.decodedToken.nameid;
    const timestamp = `${month}/${day}/${year} - ${username}`;

    const wtnumberSplit = this.wtnumber.split('-');
    const salesOrderNumber = wtnumberSplit[0];
    const wtNumber = wtnumberSplit[1];
    const wtStep = wtnumberSplit[2];
    const flag = 'Y';
    this.isLoading = true;
    const observables = [];

    // WT status need to fix
    const obj = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      salesorderno: salesOrderNumber,
      wtNumber,
      flag,
      lastStatus: this.lastStatus,
      lastComment: this.lastComment
    };

    observables.push(this.sageApi.putRequest('SuperReport/QAReports/SaveStatus', obj));

    concat(...observables).subscribe(
      (e) => {

      },
      (err) => {
        console.log(err);
        this.isLoading = false;
        this.loadingStatus = 'Failed - check console for error';
      },
      () => {
        this.isLoading = false;
        this.onResetDataClicked();
        this.loadingStatus = 'Loaded Successfully - Changes Committed to SAGE';
      }
    );
  }

  applyStat(){
    if (this.similarList.length <= 0){
      return;
    }
    this.similarList.forEach(tick => {
      if (tick.alsoApprove){
        tick.newStatCode = this.steps[this.index].newStatCode;
      }
    });
  }

  statusUpdate(step: any){
    if (step.newStatCode === 'REP'){
      this.alertify.error('ERROR: Status cannot be set as REP');
      return;
    }
    this.isLoading = true;
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const observables = [];
    const fixComment = step.comment !== undefined ?
      // tslint:disable-next-line:max-line-length
      step.comment.substring(0, 7).toLocaleLowerCase().includes('comment:') ? step.comment.substring(8) : step.comment
      : '';
    const statgroup = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      trackingComment: fixComment === '' ? '' : fixComment + '-' + this.authService.decodedToken.nameid + ' ' + month + '/' + day + '/' + year,
      updateTickets: []
    };
    const statobj = this.statusCodeConverter.find(i => i.value.toLocaleLowerCase().includes(step.newStatCode.toLocaleLowerCase()));

    if (statobj.code === 'STC'){
      this.alertify.error('ERROR: Cannot close a Service ticket on this page. Use the QA Workticket page to change the Status of the ticket.');
      return;
    }

    const body = [
      /*salesOrderNo:*/ step.soNum,
      /*wtNumber:*/step.soWT,
      /*status:*/ statobj.code
    ];
    statgroup.updateTickets.push(body);

    if (this.similarList.length > 0){
      const updateAdd = [];
      this.similarList.forEach(tick => {
        if (tick.alsoApprove){
          updateAdd.push(tick);
        }
      });

      if (updateAdd.length > 0){
        updateAdd.forEach(tick => {
          // tslint:disable-next-line:max-line-length
          const statobjB = this.statusCodeConverter.find(i => i.value.toLocaleLowerCase().includes(tick.newStatCode.toLocaleLowerCase()));
          const bodyB = [
            /*salesOrderNo:*/ tick.soNum,
            /*wtNumber:*/ tick.soWT,
            /*status:*/ statobjB.code
          ];
          statgroup.updateTickets.push(bodyB);
        });
      }
    }

    observables.push(this.sageApi.putRequest('SuperReport/QAReports/UpdateStatus', statgroup));
    this.alertify.warning(`Updating SO-${step.soNum}`);
    concat(...observables).subscribe(response => {

    }, error => {
      console.log(error);
      this.isLoading = false;
      this.alertify.error(`Error - ` + error.error.Message);
    }, () => {
      this.updatePay(step);
      this.isLoading = false;
      this.alertify.success(`Success!`);
    });

  }

  saveChanges(index: string) {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    this.isLoading = true;
    const submitObj = [];
    const observables = [];

    if (this.getIncludedSteps()[index].newLeadman !== '') {
      this.getIncludedSteps()[index].reassignedBy = this.authService.decodedToken.nameid;
    }

    const item = this.getIncludedSteps()[index];
    const tech = item.newTech !== '' ? item.newTech.split(' - ')[1] : item.techCode;
    const leadman = item.newLeadman !== '' ? item.newLeadman : item.leadman;
    const womsStatus = item.womsNewStatus !== '' ? item.womsNewStatus : item.womsStatus;
    const womsType = item.newWomsType !== '' ? item.newWomsType : item.womsType;
    // tslint:disable-next-line:max-line-length
    const fixComment = item.comment !== undefined ?
      item.comment.substring(0, 7).toLocaleLowerCase().includes('comment:') ? item.comment.substring(8) : item.comment
      : '';

    const obj = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      salesorderno: item.soNum,
      wtNumber: item.soWT,
      techCode: tech,
      leadman,
      womsStatus,
      womsType,
      trackingComment: fixComment === '' ? '' : fixComment + '-' + this.authService.decodedToken.nameid + ' ' + month + '/' + day + '/' + year,
      PONum: item.PONum
    };
    submitObj.push(obj);

    if (this.similarList.length > 0){
      this.similarList.forEach( tick => {
        const secObj = {
          username: this.authService.decodedToken.nameid,
          password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
          salesorderno: tick.soNum,
          wtNumber: tick.soWT,
          techCode: item.newTech !== '' ? item.newTech.split(' - ')[1] : tick.techCode,
          leadman: item.newLeadman !== '' ? item.newLeadman : tick.leadman,
          womsStatus: item.womsNewStatus !== '' ? item.womsNewStatus : tick.womsStatus,
          trackingComment: fixComment === '' ? '' : fixComment + '-' + this.authService.decodedToken.nameid + ' ' + month + '/' + day + '/' + year
        };
      });
    }

    observables.push(this.sageApi.putRequest('SuperReport/QAReports/EditTicket', obj));

    concat(...observables).subscribe(
      e => {

      },
      err => {
        console.log(err);
        this.isLoading = false;
        this.alertify.error(err.error.Message);
        this.loadingStatus =
          'Failed - ' + 'check console for error:' + ' PLEASE SAVE NOTES AND TRY AGAIN LATER';
      },
      () => {
        this.isLoading = false;
        this.loadingStatus = 'Loaded Successfully - Changes Committed to SAGE';
        this.alertify.success(this.loadingStatus);
        // hide modal

        this.updatePay(this.getIncludedSteps()[index]);
        this.updateMaterial(this.getIncludedSteps()[index]);
        this.closeModal();
        this.getIncludedSteps()[index].updated = 'updated';
      }
    );
  }

  checkMassUpdateTicketsSelected() {
    return this.steps.some(x => x.massUpdateSelected === true);
  }

  countMassUpdateTicketsSelected() {
    return this.steps.filter(x => x.massUpdateSelected === true).length;
  }

  onMassUpdateTicketSelected(index: number) {
    this.steps[index].massUpdateSelected = !this.steps[index].massUpdateSelected;
  }

  onMassUpdateTicketSelectAll() {
    this.ticketsMassUpdateSelectAll = !this.ticketsMassUpdateSelectAll;
    this.steps.forEach(ticket => {
      if (this.statusCode.value.includes('') || this.statusCode.value.includes(ticket.statusCode)) {
        if (this.isIncludedInSearch(ticket)) {
          ticket.massUpdateSelected = this.ticketsMassUpdateSelectAll;
        } else {
          ticket.massUpdateSelected = false;
        }
      } else {
        ticket.massUpdateSelected = false;
      }

    });
  }

  onSuccessMassUpdateTicket(selectedStatus: string) {
    this.ticketsMassUpdateSelectAll = false;
    this.ticketsMassUpdateStatus = '';
    this.steps.forEach(ticket => {
      if (ticket.massUpdateSelected) {
        ticket.massUpdatePendingStatus = selectedStatus;
      }
      ticket.massUpdateSelected = false;
    });

  }

  resetMassUpdateTicketSelectAll() {
    this.ticketsMassUpdateSelectAll = false;
    this.steps.forEach(ticket => {
      ticket.massUpdateSelected = false;
    });
  }

  onMassUpdateTickets() {
    this.ticketsMassUpdateStatusLoading = true;
    const selectedStatus = this.ticketsMassUpdateStatus;
    const endpoint = 'SuperReport/QAReports/MassUpdateStatus';
    const observables = [];

    const body: MassUpdateStatus = {
      Username: this.authService.decodedToken.nameid,
      Lines: []
    };

    this.steps.forEach(ticket => {
      if (ticket.massUpdateSelected) {
        const obj: MassUpdateStatusLines = {
          SalesOrderNo: ticket.soNum,
          WTNumber: ticket.soWT,
          CurrentStatus: ticket.currentStatus,
          NewStatus: selectedStatus
        };

        body.Lines.push(obj);
      }
    });

    observables.push(this.sageApi.putRequest(endpoint, body));

    concat(...observables).subscribe(response => {

      },
      err => {
        this.ticketsMassUpdateStatusLoading = false;
        console.log(err);
        this.alertify.error(err.error.Message);
      },
      () => {
        this.ticketsMassUpdateStatusLoading = false;
        this.onSuccessMassUpdateTicket(this.ticketsMassUpdateStatus);
        this.alertify.success('The tickets have been queued to update');
      });
  }

  onScoutModalShow(object, index) {
    this.originalImages = [];
    this.index = index;
    this.imagesForViewing = [];
    this.displayScoutImages = false;
    const commentArea: any = document.getElementById('scout-text-area');
    const container = document.getElementById('choice-container');
    this.materialOnScout = false;

    this.readyStatus = object.scoutStatus;
    this.isNotReady = true;
    this.scoutNotReadyImageLoaded = false;
    this.phase = object.phase;

    this.subvendor = object.subvendor;
    this.techCode = object.techCode;
    this.ticketLineKey = object.lineKey;
    if (this.wtnumber !== object.wtNumber) {
      this.scoutComment = object.scoutComment;
    }
    this.wtnumber = object.wtNumber;
    this.loadedwtnumber = object.wtNumber;
    this.imgAddress = object.address;


  }

  onWTClick(wt, line) {
    localStorage.setItem('savedWT', wt);
    localStorage.setItem('savedLine', line);
    localStorage.setItem('fromReportOrDash', 'R');
    const routeBody = wt;
    this.router.navigate(['/supers/Workticket'], { queryParams: {workticket : routeBody}});
  }

  actionEdit(index: string) {
    this.index = index;
    this.lookForMatchSO(index);
  }

  loadItemList() {
    this.itemList = [];
    this.sageApi.pullReport('SuperReport/Item').subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const item = {
              name: row.ItemCode + ' - ' + row.ItemCodeDesc,
              itemCode: row.ItemCode,
              description: row.ItemCodeDesc,
              kit: row.Kit,
            };

            this.itemList.push(item);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
        console.log(err);
      }
    );
  }

  loadCities(){
    const newCities: string[] = [];
    for (const step of this.steps){
      if (!newCities.includes(step.city.trim().toLowerCase())){
        newCities.push(step.city.trim().toLowerCase());
      }
    }
    this.citiesOptions = newCities;
    this.cities.setValue(newCities);
  }

  loadSubdivisions(){
    const newSubdivs: string[] = [];
    for (const step of this.steps){
      const stepSubdiv = (step.subdivision == undefined ? '' : step.subdivision).trim().toLowerCase();
      if (!newSubdivs.includes(stepSubdiv)){
        newSubdivs.push(stepSubdiv);
      }
    }
    if (newSubdivs.sort().join(',') != this.subdivisionsOptions.sort().join(',')){
      this.subdivisionsOptions = newSubdivs;
      this.subdivisions.setValue(newSubdivs);
    }
  }

  isDeviceIos() {
    const platform = (navigator as any)?.userAgentData?.platform || navigator?.platform || 'unknown';
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

}

interface MassUpdateStatusLines {
  SalesOrderNo: string;
  WTNumber: string;
  CurrentStatus: string;
  NewStatus: string;
}

interface MassUpdateStatus {
  Username: string;
  Lines: MassUpdateStatusLines[];
}
