<div class="container pb-5">
  <div class="card my-5 shadow-lg text-center">
    <h1>Clear Date</h1>
    <!-- <div>
      <mat-form-field appearance="outline" class="startDate">
        <mat-label>Start Date</mat-label>
        <input
        [matDatepicker]="datePicker3"
        matInput
        class="startDate"
        (dateChange)="addStartDate($event)"
        />
        <mat-datepicker-toggle matSuffix [for]="datePicker3"></mat-datepicker-toggle>
        <mat-datepicker #datePicker3 ></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="endDate">
        <mat-label>End Date</mat-label>
        <input
        [matDatepicker]="datePicker4"
        matInput
        class="endDate"
        (dateChange)="addEndDate($event)"
        />
        <mat-datepicker-toggle matSuffix [for]="datePicker4"></mat-datepicker-toggle>
        <mat-datepicker #datePicker4 ></mat-datepicker>
      </mat-form-field>
      <button (click)="DateRanges()">Search</button>
    </div> -->

<table >
  <tr>
    <th>Sales Order</th>
    <th>Address</th>
    <th>Builder</th>
    <th>Clear Date</th>
    <th>Recall Date</th>
    <th class="actionButton"></th>
  </tr>
  <tr *ngFor="let row of dataToSubmit"  >
<td>{{row.SalesOrderNo}}</td>
<td>{{row.address}}</td>
<td>{{row.builder}}</td>
<td>
  <mat-form-field appearance="outline" class="clearDate">
    <mat-label>Clear Date</mat-label>
    <input
      [matDatepicker]="datePickerinfo"
      matInput
      [(ngModel)]="row.clearDate"
      class="clearDate"
      (dateChange)="addClearDateRow($event, row)"
      />
    <mat-datepicker-toggle matSuffix [for]="datePickerinfo"></mat-datepicker-toggle>
    <mat-datepicker #datePickerinfo ></mat-datepicker>
  </mat-form-field>
  </td>
  <td>
    <mat-form-field appearance="outline" class="clearDate">
      <mat-label>Recall Date</mat-label>
      <input
        [matDatepicker]="datePickerRecall"
        matInput
        [(ngModel)]="row.recallDate"
        class="clearDate"
        (dateChange)="addRecallDateRow($event, row)"
        />
      <mat-datepicker-toggle matSuffix [for]="datePickerRecall"></mat-datepicker-toggle>
      <mat-datepicker #datePickerRecall ></mat-datepicker>
    </mat-form-field>
    </td>
    <td>
      <button class="sm" mat-raised-button color="danger" (click)="removeFromArray(row)"  [disabled]="loading">
        <i class="fa fa-trash"></i></button>
    </td>
    </tr>
    <tr>
    <td>
      <mat-form-field appearance="outline">
        <input matInput type="text" appearance="outline"
         name="salesOrderNo" placeholder="salesOrderNo"
        [(ngModel)]="salesOrderNo"
        [typeahead]="allSalesOrders" typeaheadOptionField="addressInfo"
        autocomplete="off" (typeaheadOnSelect)="addressSelected($event)"
        autofocus id="salesOrder"
        >
        </mat-form-field>
    </td>
    <td>{{address}}</td>
    <td>{{builder}}</td>
    <td>
      <mat-form-field appearance="outline" class="clearDate">
        <mat-label>Clear Date</mat-label>
        <input
          [matDatepicker]="datePicker"
          matInput
          class="clearDate"
          [(ngModel)]="clearDate"
          (dateChange)="addClearDate($event)"
          (keyup.enter)="addrow()"
          />
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker ></mat-datepicker>
      </mat-form-field>
      <td>
        <mat-form-field appearance="outline" class="recallDate">
          <mat-label>Recall Date</mat-label>
          <input
            [matDatepicker]="datePicker2"
            matInput
            [(ngModel)]="recallDate"
            (keyup.enter)="addrow()"
            class="recallDate"
            (dateChange)="addRecallDate($event)"
            />
          <mat-datepicker-toggle matSuffix [for]="datePicker2"></mat-datepicker-toggle>
          <mat-datepicker #datePicker2 ></mat-datepicker>
        </mat-form-field>
      </td>
      <td class="actionButton">
        <button mat-raised-button color="danger" [disabled]="clearDate == undefined || salesOrderNo == undefined" (click)="addrow()"><i class="fas fa-plus"></i></button></td>
  </tr>
</table>
<div class="submitButton">
  <button mat-raised-button color="danger"(click)="putRequest()" [disabled]="loading">
    <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i></span><span *ngIf="!loading">Send Info</span></button>

</div>
</div>
