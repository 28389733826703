<div [class.suprep-mobile]="isMobile!=false" [class.suprep-mobile-xs]="isMobile=='xs'" class="suprep">

  <mat-card class="suprep-card">
    <mat-card-title class="suprep-card-title">
      Super Report
      <div *ngIf="isLoading" class="suprep-card-title-spinner">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
    </mat-card-title>
    <mat-card-content class="suprep-card-content">
      <div class="suprep-card-content-pad">
        <div
          class="suprep-card-content-controls suprep-card-content-controls-data"
        >
          <mat-form-field appearance="outline" class="suprep-card-content-controls-ff">
            <mat-label>Report</mat-label>
            <mat-select [formControl]="report" placeholder="Report" (selectionChange)="report.setValue($event.value)">
              <mat-option *ngFor="let op of reportOptions" [value]="op" [class.empty]="op==''">{{op!=''?op:'Empty'}}</mat-option>
            </mat-select>

          </mat-form-field>
          <app-searchable-msel
            [selectedOptions]="leadman"
            (onSelectChange)="leadman.setValue($event.value)"
            placeholder="Leadman"
            [options]="leadmanOptions"
            [disabled]="isLoading"
            class="suprep-card-content-controls-ff"
          ></app-searchable-msel>
          <div class="suprep-card-content-controls-ff suprep-card-content-controls-search">
            <button
              (click)="callReport(report.value)"
              mat-raised-button
              color="accent"
              class="suprep-card-content-controls-search-btn mat-elevation-z0"
            >
              Search
            </button>
          </div>
        </div>
        <h6 *ngIf="isMobile!='xs'">Filters</h6>
        <div
          *ngIf="isMobile!='xs'"
          class="suprep-card-content-controls suprep-card-content-controls-filters"
        >
          <!-- <app-searchable-msel [disabled]="isLoading" (onSelectChange)="cities.setValue($event.value)"
            [options]="citiesOptions" [defaultSelected]="citiesOptions"
            placeholder="City" class="suprep-card-content-controls-ff"
          ></app-searchable-msel> -->

          <app-searchable-msel
            [selectedOptions]="statusCode"
            (onSelectChange)="statusCode.setValue($event.value)"
            placeholder="StatusCode"
            [options]="statusCodeOptions"
            [disabled]="isLoading"
            class="suprep-card-content-controls-ff"
          ></app-searchable-msel>
          <app-searchable-msel
            [selectedOptions]="subdivisions"
            (onSelectChange)="subdivisions.setValue($event.value)"
            placeholder="Subdivisions"
            [options]="subdivisionsOptions"
            [disabled]="isLoading"
            class="suprep-card-content-controls-ff"
          ></app-searchable-msel>
          <mat-form-field appearance="outline" class="suprep-card-content-controls-ff">
            <mat-label>Search</mat-label>
            <input
              [(ngModel)]="filterSearch"
              (input)="onSearch()"
              placeholder="Search"
              matInput
              type="text"
              [disabled]="isLoading"
            />
            <span matSuffix class="suprep-card-content-controls-ff suprep-card-content-controls-searchin-suffix">
              <mat-spinner *ngIf="isSearchLoading" diameter="20"></mat-spinner>
            </span>
          </mat-form-field>

        </div>
        <mat-expansion-panel *ngIf="isMobile=='xs'" class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Filters
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div
            class="suprep-card-content-controls suprep-card-content-controls-filters"
          >
            <!-- <app-searchable-msel [disabled]="isLoading" (onSelectChange)="cities.setValue($event.value)"
              [options]="citiesOptions" [defaultSelected]="citiesOptions"
              placeholder="City" class="suprep-card-content-controls-ff"
            ></app-searchable-msel> -->

            <app-searchable-msel
              [selectedOptions]="statusCode"
              (onSelectChange)="statusCode.setValue($event.value)"
              placeholder="StatusCode"
              [options]="statusCodeOptions"
              [disabled]="isLoading"
              class="suprep-card-content-controls-ff"
            ></app-searchable-msel>
            <app-searchable-msel
              [selectedOptions]="subdivisions"
              (onSelectChange)="subdivisions.setValue($event.value)"
              placeholder="Subdivisions"
              [options]="subdivisionsOptions"
              [disabled]="isLoading"
              class="suprep-card-content-controls-ff"
            ></app-searchable-msel>
            <mat-form-field appearance="outline" class="suprep-card-content-controls-ff">
              <mat-label>Search</mat-label>
              <input
                [(ngModel)]="filterSearch"
                (input)="onSearch()"
                placeholder="Search"
                matInput
                type="text"
                [disabled]="isLoading"
              />
              <span matSuffix class="suprep-card-content-controls-ff suprep-card-content-controls-searchin-suffix">
                <mat-spinner *ngIf="isSearchLoading" diameter="20"></mat-spinner>
              </span>
            </mat-form-field>

          </div>
        </mat-expansion-panel>
        <div
          class="suprep-card-content-icontrols"
        >
          <!-- <button class="btn btn-info mx-1" (click)="loadReport(selectedReport)">
            <img src="/assets/refreshIcon.svg" style="height: 20px;" alt="refresh button" />
          </button> -->
          <button class="btn mx-1" (click)="printReport()">
            <img src="/assets/printIcon.svg" style="height: 20px;" alt="print button" />
          </button>
          <button class="btn btn-warning mx-1" (click)="resetReport()">
            <img src="/assets/resetIcon.svg" style="height: 20px;" alt="reset button" />
          </button>
          <a routerLink="/supers/miscpay" class="btn btn-info mx-1" >
            <img src="/assets/dollar_sign.svg" style="height: 20px;" alt="metabase button" />
          </a>
          <a routerLink="/supers/house360" class="btn btn-info mx-1">
            <img src="/assets/houseTop.png" style="height: 20px;" alt="metabase360 button" />
          </a>
          <a routerLink="/supers/address-files" class="btn btn-info mx-1 suprep-adrfil">
            <img src="/assets/imageIcon.svg" style="height: 20px;" alt="metabase360 button" />
          </a>
        </div>
      </div>
      <div
        class="suprep-table"
      >
        <table>
          <tr class="p-0 m-0">
            <th class="suprep-table-header" (click)="onMassUpdateTicketSelectAll()">
              Select
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" [(ngModel)]="ticketsMassUpdateSelectAll"
                       style="pointer-events:none">
                <label class="custom-control-label"></label>
              </div>
            </th>
            <th class="suprep-table-header" (click)="orderArray('wtNumber', wtSortDirection)">
              <div class="px-1 py-2 suprep-table-header-content">
                Work Ticket
                <span class="suprep-table-header-content-caret">
                  {{
                  wtSortDirection == "asc"
                    ? "&#9650;"
                    : wtSortDirection == "desc"
                    ? "&#9660;"
                    : ""
                  }}
                </span>
              </div>
            </th>
            <th class="suprep-table-header" (click)="orderArray('leadman', lmSortDirection)">
              <div class="px-3 py-2 suprep-table-header-content">
                LM
                <span class="suprep-table-header-content-caret">
                  {{
                  lmSortDirection == "asc"
                    ? "&#9650;"
                    : lmSortDirection == "desc"
                    ? "&#9660;"
                    : ""
                  }}
                </span>
              </div>
            </th>
            <th class="suprep-table-header" (click)="orderArray('scheduleDate', workdaySortDirection)">
              <div class="px-1 py-2 suprep-table-header-content">
                Workday
                <span class="suprep-table-header-content-caret">
                  {{
                  workdaySortDirection == "asc"
                    ? "&#9650;"
                    : workdaySortDirection == "desc"
                    ? "&#9660;"
                    : ""
                  }}
                </span>
              </div>
            </th>
            <th class="suprep-table-header" (click)="orderArray('address', addressSortDirection)">
              <div class="px-1 py-2 suprep-table-header-content">
                Address
                <span class="suprep-table-header-content-caret">
                  {{
                  addressSortDirection == "asc"
                    ? "&#9650;"
                    : addressSortDirection == "desc"
                    ? "&#9660;"
                    : ""
                  }}
                </span>
              </div>
            </th>
            <th class="suprep-table-header" (click)="orderArray('phase', phaseSortDirection)">
              <div class="px-1 py-2 suprep-table-header-content">
                Phase
                <span class="suprep-table-header-content-caret">
                  {{
                  phaseSortDirection == "asc"
                    ? "&#9650;"
                    : phaseSortDirection == "desc"
                    ? "&#9660;"
                    : ""
                  }}
                </span>
              </div>
            </th>
            <th class="suprep-table-header" (click)="orderArray('subdivision', subdivisionSortDirection)">
              <div class="px-1 py-2 suprep-table-header-content">
                Subdivision
                <span class="suprep-table-header-content-caret">
                  {{
                  subdivisionSortDirection == "asc"
                    ? "&#9650;"
                    : subdivisionSortDirection == "desc"
                    ? "&#9660;"
                    : ""
                  }}
                </span>
              </div>
            </th>
            <th class="suprep-table-header" (click)="orderArray('statusCode', statusSortDirection)">
              <div class="px-1 py-2 suprep-table-header-content">
                Status
                <span class="suprep-table-header-content-caret">
                  {{
                  statusSortDirection == "asc"
                    ? "&#9650;"
                    : statusSortDirection == "desc"
                    ? "&#9660;"
                    : ""
                  }}
                </span>
              </div>
            </th>
            <th class="suprep-table-header" (click)="orderArray('dip', dipSortDirection)">
              <div class="px-1 py-2 suprep-table-header-content">
                DIP
                <span class="suprep-table-header-content-caret">
                  {{
                  dipSortDirection == "asc"
                    ? "&#9650;"
                    : dipSortDirection == "desc"
                    ? "&#9660;"
                    : ""
                  }}
                </span>
              </div>
            </th>
            <th class="suprep-table-header" (click)="orderArray('dis', disSortDirection)">
              <div class="px-1 py-2 suprep-table-header-content">
                DIS
                <span class="suprep-table-header-content-caret">
                  {{
                  disSortDirection == "asc"
                    ? "&#9650;"
                    : disSortDirection == "desc"
                    ? "&#9660;"
                    : ""
                  }}
                </span>
              </div>
            </th>
            <th class="suprep-table-header" (click)="orderArray('techName', techSortDirection)">
              <div class="px-1 py-2 suprep-table-header-content">
                Assigned Tech
                <span class="suprep-table-header-content-caret">
                  {{
                  techSortDirection == "asc"
                    ? "&#9650;"
                    : techSortDirection == "desc"
                    ? "&#9660;"
                    : ""
                  }}
                </span>
              </div>
            </th>
            <th class="suprep-table-header">
              <div class="px-1 py-2 suprep-table-header-content">Action</div>
            </th>
          </tr>
          <ng-container *ngFor="let step of getIncludedSteps(); let i= index">
            <ng-container>
              <tr class="table-row" [ngStyle]="{ 'background-color': vpoFilter(step)}" [ngStyle]="{'background-color': checkForBIN(step)}">
                <td (click)="step.massUpdateSelected = !step.massUpdateSelected">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" [(ngModel)]="step.massUpdateSelected"
                           style="pointer-events:none">
                    <label class="custom-control-label"></label>
                  </div>
                </td>
                <td class="py-1">
                  <button class="btn" [disabled]="selectedReport == 'SuperReport/Backcharge'" [ngStyle]="step.statusCode == 'Completed'
      ? { 'background-color': 'Yellow', color: 'Black' }
      : step.womsType.toLowerCase() == 'builder' &&
      (step.womsNumber == '5' ||
      step.womsStatus.toLowerCase() == 'epor')
      ? { 'background-color': 'Orange', color: 'Black' }
      : (step.statusCode !== 'Builder Inspection' &&
      step.statusCode !== 'Waiting on Builder' &&
      step.statusCode !== 'Inspection Needed' &&
      step.statusCode !== 'Called Inspection' &&
      step.dip> 2 &&
      (step.phase == 'TOPOUT' ||
      step.phase == 'GAS' ||
      step.phase == 'PUNCH')) ||
      (step.statusCode !== 'Builder Inspection' &&
      step.statusCode !== 'Waiting on Builder' &&
      step.statusCode !== 'Inspection Needed' &&
      step.statusCode !== 'Called Inspection' &&
      step.dip > 1 &&
      (step.phase == 'ROUGHIN' ||
      step.phase == 'WATER' ||
      step.phase == 'SEWER' ||
      step.phase == 'VANGUARD' ||
      step.phase == 'TRIM' ||
      step.phase == 'DROPIN'))
      ? { 'background-color': 'red', color: 'white' }
      : step.dip <0
        ? (step.scoutStatus.toLowerCase() == 'y' ||
        step.scoutStatus.toLowerCase() == 'n') &&
        step.scoutDate == todayLocal
        ? { 'background-color': 'blue', color: 'white' }
        : { 'background-color': 'green', color: 'white' }
        : { 'background-color': 'purple', color: 'white' }" (click)="step.dip <0 && step.statusCode == 'Scout' ?
        onScoutModalShow(step, i): onWTClick(step.wtNumber, step.lineKey)"
                          (click)="step.dip <0 && step.statusCode == 'Scout' ? openModal(staticModal, 'N') : ''">
                    {{
                    step.flagged == 1
                      ? "Flagged"
                      : step.soQueued == "Y"
                      ? "In Queue"
                      : step.wtQueued == "Y"
                        ? "In Queue"
                        : step.womsType.toLowerCase() == "builder" &&
                        (step.womsStatus.toLowerCase() == "prcrv" ||
                          step.womsStatus.toLowerCase() == "mgtrv")
                          ? step.wtNumber.substring(0, 11) + " VPO Pending"
                          : step.womsType.toLowerCase() == "builder" &&
                          (step.womsStatus.toLowerCase() == "accrv" ||
                            step.womsStatus.toLowerCase() == "epoo" ||
                            step.womsStatus.toLowerCase() == "epod")
                            ? step.wtNumber.substring(0, 11) + " VPO Sent"
                            : step.womsType.toLowerCase() == "builder" &&
                            (step.womsNumber == "5" ||
                              step.womsStatus.toLowerCase() == "epor")
                              ? step.wtNumber.substring(0, 11) + " VPO Ready"
                              : step.wtNumber.substring(0, 11)
                    }}
                  </button>
                  <button class="" id="uninstalled-mat-btn"
                          (click)="loadUninstalledMaterial(step.soNum + step.soWT)"
                          (click)="openModal(uninstalledMaterialModal, 'N')"
                          *ngIf="step.uninstalled == 'Uninstalled' && step.dip> 0" data-toggle="tooltip"
                          title="Uninstalled Material">
                    <img src="/assets/infoIcon.svg" alt="info icon" style="width: 100%;" />
                  </button>
                </td>
                <td id="Leadman" class="col-xs-1">
                  {{ step?.leadmanInit ? step.leadmanInit : '' }}
                </td>
                <td id="WorkDay" class="col-xs-1">
                  {{ step.scheduleDate | date: "shortDate" }} <br>
                  <span *ngIf="selectedReport === 'SuperReport/Backcharge'">Trim COM: {{ step.TrimDate | date: "shortDate" }}</span></td>
                <td id="JT_WORKTICKET|UDF_ADDRESS|" class="col-xs-2">
                  <div class="col-xs-10">{{ step.address }}{{ (step.city||'').trim()!=''? ', ' + (step.city||'') : '' }}</div>
                  <button *ngIf="step.leadman !== step.assignedTo && step.assignedTo !== ''" type="button"
                          class="reassigned-to-button col-xs-2" data-toggle="tooltip" data-html="true"
                          data-placement="top"
                          title="This ticket was reassigned to {{step.assignedTo}} by {{ step.reassignedBy }}">
                    <img src="/assets/feedback-icon.svg" alt="feedback" />
                  </button>
                  <div colspan="2">{{ step.comment }}</div>
                  <ng-container *ngFor="let vpo of step.vpos">
                    <div colspan="2">
                      VPO: {{vpo.SalesOrderNo}}-{{vpo.WTNumber}} {{vpo.VPOIC}} - ${{vpo.tranAmt}}
                    </div>
                  </ng-container>
                  <!--                  <div colspan="2">-->
                  <!--                    <button class="btn-sm" (click)="openModal(commentModel, 'N', step)">update comment</button>-->
                  <!--                  </div>-->
                </td>
                <td id="JT_WORKTICKET|HdrParentItemCode|" class="col-xs-1">
                  {{ step.phase }}</td>
                <td id="Subdivision" class="col-xs-2">
                  {{ step.subdivision }}<br />
                  {{ step.description }}</td>
                <td id="JT_WORKTICKET|statusCode|" class="col-xs-1">
                  {{ step.statusCode }}<br>
                  <span *ngIf="step.massUpdatePendingStatus" class="font-weight-bold">Pending Change to {{
                    step.massUpdatePendingStatus }}</span>
                </td>
                <td id="DIP" class="col-xs-1">
                  {{ step.dip }}<br />
                </td>
                <td id="DIS" class="col-xs-1">
                  {{ step.dis }}<br />
                </td>
                <td id="JT_WORKTICKET|techName|" class="col-xs-2">
                  {{
                  step.newTech !== "" && step.updated === "updated"
                    ? step.newTech.split(" - ")[0]
                    : step.techName
                  }}</td>
                <td id="actionEditButton" class="col-xs-1"
                    style="padding-left: 0; padding-right: 0; max-width: 4.16666%;">
                  <button class="{{
        step.updated == ''
        ? 'btn btn-primary btn-sm'
        : 'btn btn-success btn-sm'
        }}" (click)="actionEdit(i)" (click)="openModal(editInterface, 'N')" style="margin-top: 3px;">
                    {{ step.updated == "" ? "Edit" : "Updated" }}
                  </button>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </table>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- *comment Modal -->
  <ng-template #commentModel class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
               aria-hidden="true">
    <div class="modal-dialog m-0">
      <div class="modal-content" style="width: 100%;">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Update Tracking Comment</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="this.closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <div id="comment-container row" ref="open">
            <div class="col-12 text-danger">Warning: Special Characters (such as: " $ ! &#64; # ' to name a few) will
              cause
              the submission to fail and break the WT.</div>
            <div class="col-12">
              <textarea [(ngModel)]="commentStep.comment" maxlength="500" rows="5" class="form-control"
                        id="old-comment-text-area" type="text" placeholder="" name="scout-text-area" display="none"></textarea>
            </div>
            <hr />
            <div class="col-12">
              <button [disabled]="isLoading || (isNotReady && !scoutNotReadyImageLoaded)"
                      class="scout-modal-buttons btn btn-primary scout-img-button" (click)="updateTrackingComment()"
                      type="submit" value="submit">
                <img *ngIf="isLoading" src="/assets/ring-alt.svg" />
                <span *ngIf="!isLoading">Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- comment Modal end -->

  <!-- *Scout Modal -->
  <ng-template #staticModal class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
               aria-hidden="true">
    <div class="modal-dialog m-0">
      <div class="modal-content" style="width: 100%;">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Scout Status</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="this.closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <form action="submit">
            <!-- <br /> -->
            <p>Is the scout for</p>
            <p>
              WT#
              <strong><u>{{ wtnumber }}</u></strong>
            </p>
            <p>
              &#64; <strong>{{ imgAddress }}</strong> ready?
            </p>
            <button (click)="onInnerScoutChoiceClicked($event.target.value)" class="scout-choice-buttons" value="y"
                    [ngStyle]="readyStatus == 'y' ? { 'background-color': 'green' } : ''">
              Yes
            </button>
            <button (click)="onInnerScoutChoiceClicked($event.target.value)" class="scout-choice-buttons" value="n"
                    [ngStyle]="readyStatus === 'n' ? { 'background-color': 'red' } : ''">
              No
            </button>

            <div style="align-items: center; padding-top: 10px;">
              <div style="width: 50%; display: inline-block;">
                <label>Is there material needed for this scout?</label>
                <select class="form-control" name="materialOnScout" [(ngModel)]="materialOnScout">
                  <option [value]="false"> No</option>
                  <option [value]="true"> Yes</option>
                </select>
              </div>
            </div>

            <div id="choice-container" ref="open">
              <div>
                <textarea maxlength="500" rows="5" class="form-control" id="scout-text-area" type="text"
                          [(ngModel)]="scoutComment" placeholder="" name="scout-text-area" display="none"></textarea>
              </div>
              <hr />
              <div class="container-fluid">
                <div class="row scout-button-container">
                  <div>
                    <label for="load-existing-images" class="btn btn-primary scout-img-button"
                           (click)="loadImagesForViewing()">Load Images</label>
                  </div>
                  <div>
                    <button type="button" class="btn btn-primary scout-img-button"
                            *ngIf="!displayScoutImages && imagesForViewing.length> 0"
                            (click)="displayScoutImages= !displayScoutImages">&#11205;
                    </button>
                    <button type="button" class="btn btn-primary scout-img-button"
                            (click)="displayScoutImages= !displayScoutImages" *ngIf="displayScoutImages">&#9660;
                    </button>


                  </div>
                  <div>
                    <label [class.disabled]="isLoading || isImageLoading"
                           class="scout-modal-buttons btn btn-primary scout-img-button">
                      {{ isImageLoading ? "Uploading" : "Upload Images" }}
                      <input [disabled]="isLoading ? 'disabled' : ''" style="display: none;" accept="image/*"
                             multiple="true" id="uploadBtn" type="file" (change)="onFileChanged($event)" />
                    </label>
                    <div>
                      <small *ngIf="fileNames.length !== 0">{{
                        fileNames.join(" ")
                        }}</small>
                    </div>
                    {{ imageUploadComplete ? "Selected Image Uploaded" : "" }}
                  </div>
                  <div>
                    <button [disabled]="isLoading || (isNotReady && !scoutNotReadyImageLoaded)"
                            class="scout-modal-buttons btn btn-primary scout-img-button" (click)="loadImagesS3()"
                            type="submit" value="submit">
                      <img *ngIf="isLoading" src="/assets/ring-alt.svg" />
                      <span *ngIf="!isLoading">Submit</span>
                    </button>
                  </div>
                </div>
                <div *ngIf="displayScoutImages" class="row scout-img-container" style="padding-top: 1rem;">
                  <ng-container *ngFor="let img of imagesForViewing; let i= index">
                    <div *ngIf="img.toLowerCase().slice(-3) === 'pdf'">
                      <div class="row">
                        <div class="col-sm-12">
                          <a [href]="imagesForViewing[i]" target="_blank" class="" [innerHtml]="imageName(i)"></a>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <hr>
                  <ng-container *ngFor="let img of imagesForViewing; let i= index">
                    <div *ngIf="img.toLowerCase().slice(-3) !== 'pdf' && img.slice(-1) !== '/'">
                      <img width="100%" height="100%" src="{{ img }}" />
                    </div>
                    <!--                    <div *ngIf="img.slice(-3) === 'pdf'">-->
                    <!--                      <div class="row">-->
                    <!--                        <div class="col-sm-12">-->
                    <!--                          <a [href]="imageArr[i]" target="_blank" class="" [innerHtml]="imageName(i)"></a>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </ng-container>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- */Scout Modal -->


  <!-- *Uninstalled Material Modal -->
  <ng-template #uninstalledMaterialModal class="modal fade" tabindex="-1" role="dialog"
               aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog my-0" id="uninstalled-material-modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">
            Uninstalled Material
            <img *ngIf="isLoading" src="/assets/ring-alt-colored.svg" />
          </h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="this.closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div id="no-uninstalled-material" *ngIf="!uninstalledItems.length && isLoading == false">
          No uninstalled material
        </div>
        <table id="uninstalled-material-table">
          <th>
            Location
          </th>
          <th>Description</th>
          <th>Comment</th>
          <tr *ngFor="let item of uninstalledItems; let i= index">
            <td>{{ item.location }}</td>
            <td>{{ item.itemDesc }}</td>
            <td>{{ item.comment }}</td>
          </tr>
        </table>
      </div>
    </div>
  </ng-template>

  <!-- *Uninstalled Material Modal -->

  <!-- * Action Edit modal-->
  <ng-template #editInterface class="modal fade" id="editInterface" tabindex="-1" role="dialog"
               aria-labelledby="editor">
    <div class="modal-dialog m-0" role="document">
      <div class="modal-content" *ngIf="steps.length> 0">
        <div class="modal-header">

          <h3 class="modal-title" id="editor">
            <img *ngIf="isLoading" src="/assets/ring-alt-colored.svg" />Editing
            {{ getIncludedSteps()[index].address }}
          </h3>
          <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="text-center">
          </div>
        </div>
        <div class="container">
          <h5 class="text-success">{{ loadingStatus }}</h5>
          <h4>Subdivision: {{ getIncludedSteps()[index].subdivision }}</h4>
          <h4>Leadman: {{ getIncludedSteps()[index].leadman }}</h4>
          <h5>Woms Status: {{ getIncludedSteps()[index].womsStatus }}</h5>
          <h5>Woms Type: {{ getIncludedSteps()[index].womsType }}</h5>
          <h5>Trim COM date: {{ getIncludedSteps()[index].TrimDate | date: "shortDate" }}</h5>
        </div>
        <div class="modal-body m-0 p-0">
          <label class="ml-3" for="typeNotes">Description</label>
          <blockquote>
            <textarea type="text" class="lead ml-3" id="typeNotes" name="notes" [(ngModel)]="getIncludedSteps()[index].description"
                      style="width: 90%;" rows="3"></textarea>
          </blockquote>
          <label class="ml-3" for="track-comment-text-area">Tracking Comment</label>
          <div class="col-12 text-danger">Warning: Special Characters &#40;such as: " $ ! &#64; # ' to name a few&#41;
            will
            cause
            the submission to fail and break the WT.</div>
          <blockquote>
            <textarea [(ngModel)]="getIncludedSteps()[index].comment" maxlength="500" rows="5" class="lead ml-3"
                      id="track-comment-text-area" type="text" placeholder="" style="width: 90%;" name="scout-text-area"
                      display="none"></textarea>
          </blockquote>
          <form class="form-inline container-fluid">
            <div class="form-group row mb-2">
              <div class="col-12 m-0" *ngIf="selectedReport == 'SuperReport/Punch' || selectedReport == 'SuperReport/VPOPunch' || selectedReport == 'SuperReport/Tech'">
                <div class="row mb-2">
                  <div class="form-group margin-bottom-10">
                    <label class="" style="min-width: 120px;" for="currentType">Change Tech</label>
                    <input type="text" class="form-control" id="currentType" disabled
                           value="{{ getIncludedSteps()[index].techName }}" />
                  </div>
                  <div class="form-group margin-bottom-10">
                    <label class="sr-only" for="newTech">Change Tech</label>
                    <input type="text" class="form-control" id="newTech" placeholder="New Tech" list="techNames"
                           (change)="onTechChange($event.target.value)" [ngModelOptions]="{ standalone: true }"
                           [(ngModel)]="getIncludedSteps()[index].newTech" autocomplete="off" />
                  </div>
                </div>
              </div>
              <div class="clearfix" style="margin-top: 10px;"></div>
              <div class="col-12 m-0"
                   *ngIf="(selectedReport == 'SuperReport/Punch' || selectedReport == 'SuperReport/VPOPunch' || selectedReport == 'SuperReport/Tech') && getIncludedSteps()[index].newTech != '' && getIncludedSteps()[index].womsNewStatus != ''">
                <div class="row mb-2">
                  <div class="form-group">
                    <label for="womsStatus" style="min-width: 120px;"> Woms Status </label>
                    <input type="text" class="form-control" id="womsStatus" disabled
                           value="{{ getIncludedSteps()[index].womsStatus }}" />
                  </div>
                  <div class="form-group">
                    <label class="sr-only" for="womsNewStatus"> Woms Status </label>
                    <select name="womsNewStatus" id="womsNewStatus" class="form-control"
                            [(ngModel)]="getIncludedSteps()[index].womsNewStatus">
                      <option disabled>--Select--</option>
                      <option value="MGTRV"> Management Review Queue</option>
                      <option value="{{ getIncludedSteps()[index].womsStatus }}"> Keep Current Status</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="clearfix" style="margin-top: 20px;"></div>
              <div class="container ml-0 pl-0">
                <ng-container *ngIf="selectedReport != 'SuperReport/Backcharge'">
                  <div class="row my-2">
                    <div class="form-group margin-bottom-10 ml-0 pl-0">
                      <label class="" style="min-width: 120px;" for="newPO">PO#</label>
                      <input type="text" class="form-control margin-bottom-10" id="newPO" placeholder="PO#"
                             [ngModelOptions]="{ standalone: true }" [(ngModel)]="getIncludedSteps()[index].PONum"
                             [disabled]="getIncludedSteps()[index].POExist" />
                    </div>
                  </div>
                </ng-container>
                <div class="row" *ngIf="selectedReport == 'SuperReport/Workday' && (raySignOn || admin)">
                  <div class="form-group margin-bottom-10">
                    <label class="" style="min-width: 120px;" for="currentLeadman">Change Leadman</label>
                    <input type="text" class="form-control margin-bottom-10" id="currentLeadman" disabled
                           value="{{ getIncludedSteps()[index].leadman }}" />
                  </div>
                  <div class="form-group margin-bottom-10 ml-0 pl-0">
                    <label class="sr-only" for="newLeadman">Change Leadman</label>
                    <input type="text" class="form-control margin-bottom-10" id="newLeadman" placeholder="New Leadman"
                           list="leadmanNames" [ngModelOptions]="{ standalone: true }"
                           [(ngModel)]="getIncludedSteps()[index].newLeadman" />
                  </div>
                </div>
                <div class="clearfix" style="margin-top: 20px;"></div>
                <!--                <div class="row" *ngIf="raySignOn || admin">-->
                <!--                  <div class="form-group margin-bottom-10">-->
                <!--                    <label class="" style="min-width: 120px;" for="flagItem">Flag Item</label>-->
                <!--                    <button-->
                <!--                      class="btn btn-warning"-->
                <!--                      *ngIf="getIncludedSteps()[index].flagged !== 1"-->
                <!--                      id="flagItem"-->
                <!--                      (click)="loadData(getIncludedSteps()[index].wtNumber)">-->
                <!--                      Flag Ticket-->
                <!--                    </button>-->
                <!--                    <label-->
                <!--                      class="sr-only"-->
                <!--                      style="min-width: 120px;"-->
                <!--                      for="flaggedItem">Flagged Item</label>-->
                <!--                    <button-->
                <!--                      class="btn btn-danger"-->
                <!--                      *ngIf="getIncludedSteps()[index].flagged == 1"-->
                <!--                      id="flaggedItem">-->
                <!--                      Flagged-->
                <!--                    </button>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
          </form>
          <div class="clearfix" style="margin-top: 20px;"></div>

          <datalist id="techNames">
            <select class="form-control" id="Techname">
              <option disabled>--Select--</option>
              <option *ngFor="let name of loadtechList; let techIndex= index;" value="{{ name.firstName }} {{ name.lastName }} - {{
              name.techCode
              }} - {{ techIndex }}">
                {{ name.firstName }} {{ name.lastName }}
              </option>
            </select>
          </datalist>
          <datalist id="leadmanNames">
            <select class="form-control" id="leadmanNames">
              <option disabled>--Select--</option>
              <option *ngFor="let name of leadmanOptions">
                {{ name }}
              </option>
            </select>
          </datalist>
          <ng-container *ngIf="(selectedReport == 'SuperReport/Punch'  || selectedReport == 'SuperReport/VPOPunch') && getIncludedSteps()[index].POExist">
            <hr>
            <div class="my-2 ml-2">
              <label><b>**Punch ticket has saved PO#. No new parts can be added to this ticket**</b></label>
            </div>
          </ng-container>
          <ng-container *ngIf="(selectedReport == 'SuperReport/Punch' || selectedReport == 'SuperReport/VPOPunch') && !getIncludedSteps()[index].POExist">
            <hr>
            <div class="my-2 ml-2">
              <label><b>Add Materials</b></label>
              <div class="row mb-2">
                <table style="width: 100%">
                  <tr>
                    <th style="width: 70%">Item</th>
                    <th colspan="2" style="width: 30%">Qty</th>
                  </tr>
                  <ng-container *ngFor="let newItem of addpartlist; let addedItemIndex = index;">
                    <tr>
                      <td>
                        <input class="form-control input" [(ngModel)]="newItem.name" typeaheadOptionField="ItemCode"
                               [typeaheadMinLength]="0" [typeahead]="itemList" typeaheadOptionField="name"
                               typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                               (typeaheadOnSelect)="stepItemSelected($event, addedItemIndex)"
                               [typeaheadOptionsInScrollableView]="5" placeholder="Item" autocomplete="off"
                               placeholder="ItemCode" autocomplete="off">
                      </td>
                      <td>
                        <input class="form-control form-control-sm" id="countLine" style="width: 50px;" type="number"
                               [(ngModel)]="newItem.newQtyOrderedTotal" (click)="$event.target.select()" />
                      </td>
                      <td>
                        <button class="btn btn-sm btn-primary" (click)="RemoveBackorderList(addedItemIndex)">X</button>
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
              <div class="row mb-2 justify-content-center">
                <button class="btn btn-sm btn-primary" (click)="AddBackorderList()">+</button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedReport == 'SuperReport/Backcharge'">
<!--            <div class="row m-2 justify-content-center">-->
<!--              <div class="col-12 justify-content-center">-->
<!--                <label class="" for="currentType"><b>Type of Charge</b></label>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row mb-2">-->
<!--              <div class="col-6">-->
<!--                <div class="">-->
<!--                  <input type="text" class="form-control" id="currentType" disabled-->
<!--                         value="{{ getIncludedSteps()[index].womsType }}"/>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-6">-->
<!--                <div class="">-->
<!--                  <label class="sr-only" for="newType">Change Type</label>-->
<!--                  <input type="text" class="form-control" id="newType" placeholder="New Type" list="types"-->
<!--                         [ngModelOptions]="{ standalone: true }" [(ngModel)]="getIncludedSteps()[index].newWomsType" [disabled]="getIncludedSteps()[index].womsStatus === 'REVBC'"/>-->
<!--                  <datalist id="types">-->
<!--                    <option value="BUILDER"></option>-->
<!--                    <option value="CREW"></option>-->
<!--                    <option value="GIBSON"></option>-->
<!--                    <option value="CANCEL"></option>-->
<!--                  </datalist>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div class="row m-2 justify-content-center">
              <div class="col-12 justify-content-center">
                <h5>Backcharge Amount: $ {{getIncludedSteps()[index].unitprice}}</h5>
              </div>
            </div>
            <div class="row m-2 justify-content-center">
              <div class="col-12 justify-content-center">
                <label class="" for="currentType"><b>WOMs Status</b></label>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6">
                <div class="">
                  <input type="text" class="form-control" id="currentType" disabled
                         value="{{ getIncludedSteps()[index].womsStatus }}"/>
                </div>
              </div>
              <div class="col-6">
                <div class="">
                  <label class="sr-only" for="newType">Change Type</label>
                  <input type="text" class="form-control" id="newType" placeholder="New Status" list="womsStatus"
                         [ngModelOptions]="{ standalone: true }" [(ngModel)]="getIncludedSteps()[index].womsNewStatus" [disabled]="getIncludedSteps()[index].womsStatus === 'REVBC'"/>
                  <datalist id="womsStatus">
<!--                    <option value="PBCK">Pending Backcharge (Actual)</option>-->
                    <option value="PDISC">Pre-Dispute Builder Charge</option>
                    <option value="CANCEL">Cancelled For Various Reasons</option>
                  </datalist>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedReport != 'SuperReport/Backcharge'">
            <hr>
            <div class="my-2 ml-2" >
              <label><b>Quick Status Change</b> (tracking comment will Also be updated with Status)</label>
              <div class="row mb-2">
                <div class="col-6">
                  <label for="quickStat">WT {{getIncludedSteps()[index].soNum}}-{{getIncludedSteps()[index].soWT}} Status:</label>
                  <select class="form-control" id="quickStat" [(ngModel)]="getIncludedSteps()[index].newStatCode">
                    <ng-container *ngFor="let sCC of statusCodeConverter">
                      <ng-container *ngIf="sCC.code !== 'REP'">
                        <option selected value="{{sCC.value}}">{{sCC.desc}}</option>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>
                <div *ngIf="similarList.length <= 0" class="col-4"><button class="btn btn-primary mt-4"
                                                                           (click)="statusUpdate(getIncludedSteps()[index])" [disabled]="isLoading">Update Status</button></div>
                <div *ngIf="similarList.length > 0" class="col-4"><button class="btn btn-primary mt-4"
                                                                          (click)="applyStat()">Apply to Selected</button></div>
              </div>
              <!--            <div class="my-2">-->
              <!--              <Label class="fa-bold">The following are WT with Same SO number.</Label>-->
              <!--            </div>-->
              <table *ngIf="similarList.length > 0">
                <th>Phase</th>
                <th>leadman</th>
                <th>Status</th>
                <th>Add</th>
                <th>Assigned Tech</th>
                <!--              <th>Flag</th>-->
                <tr *ngFor="let Swt of similarList">
                  <td>{{Swt.phase}} ({{Swt.soWT}})</td>
                  <td>{{Swt.leadmanInit}}</td>
                  <td><select class="form-control" [(ngModel)]="Swt.newStatCode">
                    <ng-container *ngFor="let sCC of statusCodeConverter">
                      <option selected value="{{sCC.value}}">{{sCC.desc}}</option>
                    </ng-container>
                  </select></td>
                  <td><input type="checkbox" [(ngModel)]="Swt.alsoApprove"></td>
                  <td>{{
                    Swt.newTech !== "" && Swt.updated === "updated"
                      ? Swt.newTech.split(" - ")[0]
                      : Swt.techName
                    }}</td>

                  <!--                <td><button-->
                  <!--                  class="btn btn-warning"-->
                  <!--                  *ngIf="Swt.flagged !== 1"-->
                  <!--                  id="flagItemSwt"-->
                  <!--                  (click)="loadData(Swt.soNum)">-->
                  <!--                  Flag-->
                  <!--                </button></td>-->
                </tr>
              </table>
              <div class="row" *ngIf="similarList.length > 0">
                <div class="col-4"><button class="btn btn-primary mt-4" (click)="statusUpdate(getIncludedSteps()[index])"
                                           [disabled]="isLoading">Update Status</button></div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="actionClose()">
            Close
          </button>
          <button type="button" class="btn btn-primary" (click)="saveChanges(index)">
            <img *ngIf="isLoading" src="/assets/ring-alt.svg" /><span *ngIf="!isLoading">{{
            selectedReport == 'SuperReport/Backcharge' ? "Submit" : getIncludedSteps()[index].newTech !== ""
              ? "Update & Approve"
              : "Approve"
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- * Action Edit modal END-->

</div>

<!-- # Mass Update Card -->
<div class="card" style="position: fixed; bottom: 10px; right: 10rem;" *ngIf="checkMassUpdateTicketsSelected()">
  <div class="card-body py-1">
    <h5 class="card-title font-weight-bold">Mass Update Ticket Statuses</h5>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="w-100">
          <mat-label>New Status</mat-label>
          <mat-select [(value)]="ticketsMassUpdateStatus">
            <mat-option value="">-- None --</mat-option>
            <ng-container *ngFor="let status of statusCodeConverter">
              <mat-option [value]="status.code">{{ status.desc }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <button mat-raised-button color="success" (click)="onMassUpdateTickets()"
                [disabled]="ticketsMassUpdateStatusLoading || ticketsMassUpdateStatus ===''">
          <span *ngIf="ticketsMassUpdateStatusLoading"><i class="fas fa-cog fa-spin"></i></span>
          <span *ngIf="!ticketsMassUpdateStatusLoading">Update {{ countMassUpdateTicketsSelected() }} Ticket<span
            *ngIf="countMassUpdateTicketsSelected() > 1">s</span></span>
        </button>
      </div>
    </div>
  </div>
</div>
