import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import KitRegions from '../KitRegions';
import KitPhases from '../KitPartPhases';
import QuoteStatuses from '../QuoteStatuses';
import DateValidators from 'src/app/Validators/DateValidators';
import QuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteDtoInterface';
import InputDecimalSanitizer from 'src/app/utils/InputDecimalSanitizer';

@Component({
  selector: 'app-quote-sidepanel',
  templateUrl: './QuoteSidepanelComponent.html',
  styleUrls: ['./QuoteSidepanelComponent.css', '../QuotingTheme.scss'],
})
export class QuoteSidepanelComponent implements OnInit, OnDestroy, OnChanges {

  @Input() open: boolean = false;
  @Input() saving: boolean = false;

  @Input() quote: QuoteDtoInterface;

  @Input() itemTotal = 0;
  @Input() costTotal = 0;
  @Input() rawCost = 0;
  @Input() partCost = 0;
  @Input() partTotal = 0;
  @Input() laborCost = 0;
  @Input() laborTotal = 0;

  isSidebarOpen = false;
  
  @ViewChild('titleInput', {static: true}) public titleInput: ElementRef;
  
  nameControl = new FormControl('', [Validators.required]);
  descControl = new FormControl('', [Validators.required, Validators.maxLength(255)]);
  statusControl = new FormControl('', [Validators.required]);
  builderControl = new FormControl('', [Validators.required]);
  squareFootageControl = new FormControl('', [Validators.required, Validators.min(1), Validators.max(999999.99)]);
  minExpiration = (new Date()).toISOString().slice(0,10);
  expirationControl = new FormControl(new Date(), [Validators.required]);
  materialMarginControl = new FormControl('', [Validators.required, Validators.min(0), Validators.max(9999.9999)]);
  laborMarginControl = new FormControl('', [Validators.required, Validators.min(0), Validators.max(9999.9999)]);
  gibsonMarginControl = new FormControl('', [Validators.required, Validators.min(0), Validators.max(9999.9999)]);
  taxMarginControl = new FormControl('', [Validators.required, Validators.min(0), Validators.max(9999.9999)]);

  regionControl = new FormControl('', [Validators.required]);

  regions = KitRegions;

  heightOffset = 0;

  quoteStatuses = QuoteStatuses;

  @Output() onChange = new EventEmitter<QuoteDtoInterface>();

  @Output() onUpdateParts = new EventEmitter();

  @Output() openChange = new EventEmitter<boolean>();
  
  constructor(public screenSize: ScreenSizeService) {}
  
  ngOnInit() {
    this.resetControls();
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    this.resetControls();
  }

  resetControls(){

    let expDate = new Date(this.quote.Quote_Expiration);
    // Add one day to the expDate because the date picker will default to the day before the date
    // BTW, copilot just knew this was the issue and autogenerated this comment. How cool is that?
    expDate.setDate(expDate.getDate() + 1);

    this.nameControl = new FormControl(this.quote.Quote_Name, [Validators.required]);
    this.statusControl = new FormControl(this.quote.Quote_Status, [Validators.required]);
    this.builderControl = new FormControl(this.quote.Quote_Builder, [Validators.required]);
    this.squareFootageControl = new FormControl(this.quote.Quote_SquareFootage, [Validators.required, Validators.min(1), Validators.max(999999.99)]);
    this.expirationControl = new FormControl(expDate, [Validators.required, DateValidators.dateAfterValidator( this.minExpiration, this.quote.Quote_Expiration, {} )]);
    this.descControl = new FormControl(this.quote.Quote_Desc, [Validators.maxLength(255)]);

    this.materialMarginControl = new FormControl(this.convertToPercent(this.quote.Quote_MaterialMargin), [Validators.min(0), Validators.max(9999.9999)]);
    this.laborMarginControl = new FormControl(this.convertToPercent(this.quote.Quote_LaborMargin), [Validators.min(0), Validators.max(9999.9999)]);
    this.gibsonMarginControl = new FormControl(this.convertToPercent(this.quote.Quote_GibsonMargin), [Validators.min(0), Validators.max(9999.9999)]);
    this.taxMarginControl = new FormControl(this.convertToPercent(this.quote.Quote_TaxMargin), [Validators.min(0), Validators.max(9999.9999)]);
    this.regionControl = new FormControl(this.quote.Quote_Region, [Validators.required]);
  }

  convertToPercent(value: number){
    let val = (value*1000000)-1000000; // Avoid floating point errors by multiplying by 1000000
    // See how older ARM chips without an FPU do division with floating point numbers
    // *hint, it's just multiplication of integers and then a shift
    return val/10000;
  }

  convertFromPercent(value: number){
    let val = (value)/100;
    return val+1;
  }

  titleBlur(event){
    if(this.nameControl.value.trim() == ''){
      this.nameControl.setValue('Untitled');
    }else{
      this.nameControl.setValue(this.nameControl.value.trim());
    }
  }

  canSave(){
    let expDate = new Date(this.quote.Quote_Expiration);
    // Add one day to the expDate because the date picker will default to the day before the date
    expDate.setDate(expDate.getDate() + 1);

    const isValid = this.nameControl.valid 
      && this.statusControl.valid 
      && this.builderControl.valid 
      && this.squareFootageControl.valid
      && this.expirationControl.valid 
      && this.materialMarginControl.valid 
      && this.laborMarginControl.valid 
      && this.gibsonMarginControl.valid 
      && this.taxMarginControl.valid
      && this.regionControl.valid;
    const hasChanges = this.nameControl.value != this.quote.Quote_Name
      || this.descControl.value != this.quote.Quote_Desc
      || this.statusControl.value != this.quote.Quote_Status
      || this.builderControl.value != this.quote.Quote_Builder
      || this.squareFootageControl.value != this.quote.Quote_SquareFootage
      || (new Date(this.expirationControl.value)).toISOString() != (expDate).toISOString()
      || this.convertFromPercent(this.materialMarginControl.value) != this.quote.Quote_MaterialMargin
      || this.convertFromPercent(this.laborMarginControl.value) != this.quote.Quote_LaborMargin
      || this.convertFromPercent(this.gibsonMarginControl.value) != this.quote.Quote_GibsonMargin
      || this.convertFromPercent(this.taxMarginControl.value) != this.quote.Quote_TaxMargin
      || this.regionControl.value != this.quote.Quote_Region;
    return isValid && hasChanges;
  }

  showActions(){
    let expDate = new Date(this.quote.Quote_Expiration);
    // Add one day to the expDate because the date picker will default to the day before the date
    expDate.setDate(expDate.getDate() + 1);

    const hasChanges = this.nameControl.value != this.quote.Quote_Name
      || this.descControl.value != this.quote.Quote_Desc
      || this.statusControl.value != this.quote.Quote_Status
      || this.builderControl.value != this.quote.Quote_Builder
      || this.squareFootageControl.value != this.quote.Quote_SquareFootage
      || (new Date(this.expirationControl.value)).toISOString() != (expDate).toISOString()
      || this.convertFromPercent(this.materialMarginControl.value) != this.quote.Quote_MaterialMargin
      || this.convertFromPercent(this.laborMarginControl.value) != this.quote.Quote_LaborMargin
      || this.convertFromPercent(this.gibsonMarginControl.value) != this.quote.Quote_GibsonMargin
      || this.convertFromPercent(this.taxMarginControl.value) != this.quote.Quote_TaxMargin
      || this.regionControl.value != this.quote.Quote_Region;
    return hasChanges;
  }

  saveChanges(){
    this.quote.Quote_Name = this.nameControl.value;
    this.quote.Quote_Status = this.statusControl.value;
    this.quote.Quote_Builder = this.builderControl.value;
    this.quote.Quote_SquareFootage = this.squareFootageControl.value;
    this.quote.Quote_Expiration = (new Date(this.expirationControl.value)).toISOString().slice(0,10);
    this.quote.Quote_Desc = this.descControl.value;
    this.quote.Quote_MaterialMargin = this.convertFromPercent(this.materialMarginControl.value);
    this.quote.Quote_LaborMargin = this.convertFromPercent(this.laborMarginControl.value);
    this.quote.Quote_GibsonMargin = this.convertFromPercent(this.gibsonMarginControl.value);
    this.quote.Quote_TaxMargin = this.convertFromPercent(this.taxMarginControl.value);
    this.quote.Quote_Region = this.regionControl.value;

    this.onChange.emit({
      ...this.quote
    });
  }

  cancelChanges(){
    this.nameControl.setValue(this.quote.Quote_Name);
    this.descControl.setValue(this.quote.Quote_Desc);
    this.statusControl.setValue(this.quote.Quote_Status);
    this.builderControl.setValue(this.quote.Quote_Builder);
    this.squareFootageControl.setValue(this.quote.Quote_SquareFootage);
    this.expirationControl.setValue(this.quote.Quote_Expiration);
    this.materialMarginControl.setValue(this.quote.Quote_MaterialMargin);
    this.laborMarginControl.setValue(this.quote.Quote_LaborMargin);
    this.gibsonMarginControl.setValue(this.quote.Quote_GibsonMargin);
    this.taxMarginControl.setValue(this.quote.Quote_TaxMargin);
    this.regionControl.setValue(this.quote.Quote_Region);
  }

  updatePrices(){
    this.onUpdateParts.emit();
  }

  openChangeHandler(newOpen: boolean){
    this.openChange.emit(newOpen);
  }

  getExpirationControlValue(){
    if(this.expirationControl.value instanceof Date){
      return (new Date(this.expirationControl?.value))?.toISOString()?.slice(0,10);
    }
    return this.expirationControl.value;
  }

  materialInput(e){
    this.materialMarginControl.setValue(InputDecimalSanitizer(e, 3));
  }

  laborInput(e){
    this.laborMarginControl.setValue(InputDecimalSanitizer(e, 3));
  }

  gibsonInput(e){
    this.gibsonMarginControl.setValue(InputDecimalSanitizer(e, 3));
  }

  taxInput(e){
    this.taxMarginControl.setValue(InputDecimalSanitizer(e, 3));
  }

  squareFootageInput(e){
    this.squareFootageControl.setValue(InputDecimalSanitizer(e, 6, 2));
  }

}