<div [class]="screenSize.getClasses() + ' quoting-theme prevqpdf'">
  <div *ngIf="error" class="prevqpdf-error">
    <h1 class="prevqpdf-error-txt">Error loading quote</h1>
  </div>
  <div *ngIf="loading"  class="prevqpdf-loading">
    <h1 class="prevqpdf-loading-title">Loading Quote</h1>
    <mat-spinner *ngIf="loading" [diameter]="30" class="prevqpdf-loading-title-spinner"></mat-spinner>
  </div>
  <iframe 
    *ngIf="content != undefined"
    [src]="content"
    class="prevqpdf-if"
  ></iframe>
</div>
