<div [class.smpct-mobile]="isMobile" class="smpct">
  <mat-card class="smpct-card">
    <mat-card-title class="smpct-card-title">
      Misc Punch Created
      <div *ngIf="miscPunchCreatedLoading" class="smpct-card-title-spinner">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
    </mat-card-title>
    <mat-card-content>
      <div class="smpct-card-controls">
        
        <mat-form-field appearance="outline" class="smpct-card-controls-ff">
          <mat-select  multiple [formControl]="miscPunchCreatedTechControl" placeholder="Technician(s)" [disabled]="miscPunchCreatedLoading" (selectionChange)="setMiscPunchCreatedDateSearchable()" class="smpct-card-controls-item smpct-card-controls-techsel">
            <input matInput (keyup)="onMiscPunchCreatedTechSearch($event.target.value, miscPunchCreated)" (keydown)="$event.stopPropagation()" placeholder="Techname" class="smpct-card-controls-techsel-in">
            <mat-divider></mat-divider>
            <button mat-button (click)="onMiscPunchCreatedTechSelectAll()" value="all" class="smpct-card-controls-techsel-all">
              {{this.miscPunchCreatedTechControl.value.sort().join() == this.miscPunchCreatedTechs.sort().join()? "Deselect" : "All Techs"}}
            </button>
            <mat-divider></mat-divider>
            <mat-option *ngFor="let tech of miscPunchCreatedTechs" [value]="tech">{{tech}}</mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="outline" class="smpct-card-controls-ff">
          <mat-label>Address</mat-label>
          <input matInput type="text" [(ngModel)]="miscPunchCreatedAddressControl" [disabled]="miscPunchCreatedLoading" (input)="setMiscPunchCreatedDateSearchable()" class="smpct-card-controls-item smpct-card-controls-address"/>
        </mat-form-field>
        
        <mat-form-field appearance="outline" class="smpct-card-controls-ff">
          <mat-label>Punch Status Date</mat-label>
          <mat-date-range-input [formGroup]="miscPunchCreatedDateContrl" [rangePicker]="miscPunchCreatedPicker" [min]="miscPunchCreatedDateContrlMin" [max]="miscPunchCreatedDateContrlMax" [disabled]="miscPunchCreatedLoading">
            <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="onMiscPunchCreateDate($event)">
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onMiscPunchCreateDate($event)">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="miscPunchCreatedPicker"></mat-datepicker-toggle>
          <mat-date-range-picker #miscPunchCreatedPicker [disabled]="miscPunchCreatedLoading"></mat-date-range-picker>
        </mat-form-field>
  
        <button (click)="onMiscPunchCreatedFilterSearch()" mat-raised-button color="accent" extended [disabled]="!miscPunchCreatedSearchContrl" class="smpct-card-controls-item smpct-card-controls-search">Search</button>
  
      </div>
  
      <div class="smpct-card-data">
  
        <div class="smpct-card-data-ctn">
          <table mat-table [dataSource]="miscPunchCreatedData" matSort class="smpct-card-data-ctn-table">
  
            <ng-container matColumnDef="SalesOrderNo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> SO# </th>
              <td mat-cell *matCellDef="let element" class="smpct-card-data-ctn-table-td sm">
                <span class="smpct-card-data-ctn-table-td-con">
                  {{element.SalesOrderNo}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="JT158_WTNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> WT# </th>
              <td mat-cell *matCellDef="let element" class="smpct-card-data-ctn-table-td sm">
                <span class="smpct-card-data-ctn-table-td-con">
                  {{element.JT158_WTNumber}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="NumberOfPayLines">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> NoPayLines </th>
              <td mat-cell *matCellDef="let element" class="smpct-card-data-ctn-table-td sm">
                <span class="smpct-card-data-ctn-table-td-con">
                  {{element.NumberOfPayLines}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="PunchDesc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> PunchDescription </th>
              <td mat-cell *matCellDef="let element" class="smpct-card-data-ctn-table-td xl">
                <span class="smpct-card-data-ctn-table-td-con">
                  {{element.PunchDesc}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="ShipToName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
              <td mat-cell *matCellDef="let element" class="smpct-card-data-ctn-table-td xl">
                <span class="smpct-card-data-ctn-table-td-con">
                  {{element.ShipToName}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="TechName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> TechName </th>
              <td mat-cell *matCellDef="let element" class="smpct-card-data-ctn-table-td lg">
                <span class="smpct-card-data-ctn-table-td-con">
                  {{element.TechName}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="UDF_WOMS_STATUS">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let element" class="smpct-card-data-ctn-table-td lg">
                <span class="smpct-card-data-ctn-table-td-con">
                  {{element.UDF_WOMS_STATUS}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="UDF_WOMS_STATUS_DATE">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> StatusDate </th>
              <td mat-cell *matCellDef="let element" class="smpct-card-data-ctn-table-td md">
                <span class="smpct-card-data-ctn-table-td-con">
                  {{element.UDF_WOMS_STATUS_DATE}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="UDF_WOMS_TYPE">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ChargeType </th>
              <td mat-cell *matCellDef="let element" class="smpct-card-data-ctn-table-td md">
                <span class="smpct-card-data-ctn-table-td-con">
                  {{element.UDF_WOMS_TYPE}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="UDF_WOMS_TYPE_COMMENT">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ChargeComments </th>
              <td mat-cell *matCellDef="let element" class="smpct-card-data-ctn-table-td xl">
                <span class="smpct-card-data-ctn-table-td-con">
                  {{element.UDF_WOMS_TYPE_COMMENT}}
                </span>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="['SalesOrderNo', 'JT158_WTNumber', 'NumberOfPayLines', 'PunchDesc', 'ShipToName', 'TechName', 'UDF_WOMS_STATUS', 'UDF_WOMS_STATUS_DATE', 'UDF_WOMS_TYPE', 'UDF_WOMS_TYPE_COMMENT']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['SalesOrderNo', 'JT158_WTNumber', 'NumberOfPayLines', 'PunchDesc', 'ShipToName', 'TechName', 'UDF_WOMS_STATUS', 'UDF_WOMS_STATUS_DATE', 'UDF_WOMS_TYPE', 'UDF_WOMS_TYPE_COMMENT'];"></tr>
          </table>
        </div>
      
        <div class="smpct-card-data-table-controls">
          <mat-paginator
            #miscPunchCreatedDataPaginator
            [pageSizeOptions]="[10, 20]"
            showFirstLastButtons
            aria-label="Select page of periodic elements"
          >
          </mat-paginator>
        </div>
  
      </div>
  
    </mat-card-content>
  </mat-card>
</div>