import { Component, OnInit } from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { concat } from 'rxjs';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  isLoading: boolean;
  resetPasswordForm = new FormGroup({
    CurrentPassword: new FormControl('', [
      Validators.required
    ]),
    NewPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      this.passwordPatternValidator()
    ]),
    RetypeNewPassword: new FormControl('', [
      Validators.required,
      this.passwordMatchValidator()
    ])
  });

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    public authService: AuthService,
  ) { }

  ngOnInit() {

  }

  resetPassword() {
    this.isLoading = true;

    const endpoint = 'users/activedirectory/password';
    const observables = [];
    const body = {
      Username: this.authService.decodedToken.nameid,
      CurrentPassword: this.resetPasswordForm.get('CurrentPassword').value,
      NewPassword: this.resetPasswordForm.get('RetypeNewPassword').value
    };

    observables.push(this.sageApi.putRequest(endpoint, body));

    concat(...observables).subscribe(response => {
      console.log(response);
    }, err => {
      this.isLoading = false;
      console.log(err);
      this.alertify.error('Could Not Update Password: ' + err.error);
    }, () => {
      this.isLoading = false;
      this.resetPasswordForm.reset();
      this.alertify.success('Password Successfully Changed');
    });
  }

  cancelReset() {
    this.resetPasswordForm.reset();
  }

  passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value || '';
      const valid = value === control.parent?.value.NewPassword ? true : false;
      return valid ? null : !control.value ? null : {match: {description: 'Passwords Do Not Match'}};

    };
  }

  passwordPatternValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value || '';
      const lowerCasePattern = value.match(/[a-z]/);
      const upperCasePattern = value.match(/[A-Z]/);
      const numberPattern = value.match(/\d/);
      const specialCharacterPattern = value.match(/[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/);

      return !lowerCasePattern ? { patterns: { description: 'Must contain a minimum of 1 lower case letter [a-z]'}} :
              !upperCasePattern ? { patterns: { description: 'Must contain a minimum of 1 upper case letter [A-Z]'}} :
              !numberPattern ? { patterns: { description: 'Must contain a minimum of 1 numeric character [0-9]'}} :
              // tslint:disable-next-line:max-line-length
              !specialCharacterPattern ? { patterns: { description: `Must contain a minimum of 1 special character: !"#$%&'()*+,\-./:; <=>?@[\\\]^_\`{|}~`}}
              : null;
    };
  }

  getCurrentPasswordErrorMessage() {
    const field = this.resetPasswordForm.get('CurrentPassword');
    return field.hasError('required') ?
      'Current Password is required' : '';
  }

  getNewPasswordErrorMessage() {
    const field = this.resetPasswordForm.get('NewPassword');
    return field.hasError('required') ? 'New Password is required' :
      field.hasError('minlength') ? 'New Password must be greater than 12 charaters long' :
      field.hasError('patterns') ? field.getError('patterns').description : '';
  }

  getRetypeNewPasswordErrorMessage() {
    const field = this.resetPasswordForm.get('RetypeNewPassword');
    return field.hasError('required') ? 'Retype New Password Required' :
      field.hasError('match') ? 'Passwords Do Not Match' : '';
  }

}
