import { ExcelService } from './../../../_services/excel/excel.service';
import { Component, OnInit, TemplateRef } from "@angular/core";
import { AlertifyService } from "../../../_services/alertify/alertify.service";
import { SageApiService } from "../../../_services/sageApi/sageApi.service";
import { AuthService } from "../../../_services/auth/auth.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { concat } from "rxjs";

import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: "app-material-scheduled",
  templateUrl: "./material-scheduled.component.html",
  styleUrls: ["./material-scheduled.component.css"],
})
export class MaterialScheduledComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig>;
  history = [];
  itemsToTransfer = [];
  DateScheduled: Date;
  FromDate: Date;
  ToDate: Date;
  pdfTodayDate: string;
  pdfTime: string;
  print = false;
  showMaterialsScheduled = false;
  materialsOnSchedule = [];
  MatLoading = false;
  sending: boolean;
  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private router: Router,
    private excelService: ExcelService
  ) {}

  ngOnInit(): void {
    const today = new Date();
    this.pdfTodayDate = this.datePipe.transform(today, "MM/dd/yyyy");
    this.pdfTime = this.formatAMPM(today);
  }
  GetMaterial(FromDate: Date, ToDate: Date) {
    if (FromDate !== undefined && ToDate !== undefined) {
      this.MatLoading = true;
      const endpoint = "PullSheet/ScheduledDate";
      const observables = [];
      const body = {
        FromDate: FromDate,
        ToDate: ToDate,
      };
      observables.push(this.sageApi.putRequest(endpoint, body));
      concat(...observables).subscribe(
        (arrays: Array<any>) => {
          if (Array.isArray(arrays)) {
            arrays.forEach((array) => {
              this.materialsOnSchedule.push(array);
            });
          }
          console.log(this.materialsOnSchedule);
        },
        (err) => {
          console.log(err);
          this.alertify.error("Error - " + err.message);
          this.MatLoading = false;
        },
        () => {
          this.MatLoading = false;
        }
      );
      this.showMaterialsScheduled = true;
    } else {
      this.alertify.warning("You must enter a date range.");
    }
  }

  saveExcel(FromDate, ToDate){
    var FromDateFormatted = this.datePipe.transform(FromDate, "MM.dd.yyyy");
    var ToDateFormatted = this.datePipe.transform(ToDate, "MM.dd.yyyy");
    this.excelService.exportAsExcelFile(this.materialsOnSchedule,  `MaterialOnSchedule ${FromDateFormatted}-${ToDateFormatted}`);
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  }

  historyOfPullSheets() {
    this.sageApi
      .pullReport("PullSheet/History")
      .subscribe((arrays: Array<any>) => {
        if (Array.isArray(arrays)) {
          arrays.forEach((array) => {
            this.history.push(array);
          });
        }
      });
  }
}
