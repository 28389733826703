import { Component, OnInit, TemplateRef } from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';

@Component({
  selector: 'app-receipt-of-good-approval',
  templateUrl: './receipt-of-goods-approval.component.html',
  styleUrls: ['./receipt-of-goods-approval.component.css']
})
export class ReceiptOfGoodsApprovalComponent implements OnInit {
  modalTemplate: BsModalRef;

  fileLoading: boolean;
  fileArray = [];
  fileList = [];

  receiptOfGoodsPending = [];
  receiptOfGoodsLoading = false;
  receiptOfGoodsUpdating = false;

  receiptSelectedIndex: number;
  receiptSelected: boolean;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.getPendingReceiptOfGoods();
  }

  onReceiptSelected(index: number) {
    this.receiptSelectedIndex = index;
    this.receiptSelected = true;
  }

  onReceiptDeselected() {
    this.receiptSelectedIndex = 0;
    this.receiptSelected = false;
  }

  numberOfErrors(index: number) {
    let errorCount = 0;
    this.receiptOfGoodsPending[index].Lines.forEach(item => {
      if (item.ItemsMatch === 0) {
        if (item.ItemExistsInRog === 0) {
          errorCount++;
        }
        if (item.ItemExistsInHistory === 0) {
          errorCount++;
        }
      }

      if (item.QuantitiesMatch === 0 && item.ItemExistsInRog === 1 && item.ItemExistsInHistory === 1) {
        errorCount++;
      }

      if (item.ItemsMatchPO === 0) {
        if (item.ItemExistsInPO === 0) {
          errorCount++;
        }
      }

      if (item.ItemsQuantityValid === 0 && item.ItemsMatchPO === 1) {
        errorCount++;
      }
    });

    return errorCount;
  }

  itemDiscrepancy(lineIndex: number, receiptIndex: number) {
    return  this.receiptOfGoodsPending[receiptIndex].Lines[lineIndex].ItemsMatch === 0 ||
            this.receiptOfGoodsPending[receiptIndex].Lines[lineIndex].QuantitiesMatch === 0 ||
            this.receiptOfGoodsPending[receiptIndex].Lines[lineIndex].ItemExistsInRog === 0 ||
            this.receiptOfGoodsPending[receiptIndex].Lines[lineIndex].ItemExistsInHistory === 0 ||
            this.receiptOfGoodsPending[receiptIndex].Lines[lineIndex].ItemsMatchPO === 0 ||
            this.receiptOfGoodsPending[receiptIndex].Lines[lineIndex].ItemExistsInPO === 0 ||
            this.receiptOfGoodsPending[receiptIndex].Lines[lineIndex].ItemsQuantityValid === 0;
  }

  getPendingReceiptOfGoods() {
    this.receiptOfGoodsLoading = true;
    this.receiptOfGoodsPending = [];
    this.sageApi.pullReport('ReceiptApproval/ReceiptOfGoods/Combined').subscribe((goods: []) => {
      if (Array.isArray(goods)) {
        this.receiptOfGoodsPending = goods;
      }
    }, err => {
      console.log(err);
      this.receiptOfGoodsLoading = false;
    }, () => {
      this.receiptOfGoodsLoading = false;
      console.log('Goods', this.receiptOfGoodsPending);
    });
  }

  onApproveReceiptOfGoods(receipt: any, index: number) {
    this.receiptOfGoodsUpdating = true;
    const observables = [];
    const body = {
      Username: this.authService.decodedToken.nameid,
      ReceiptNo: receipt.ReceiptNo,
      ReceiptType: receipt.ReceiptType,
      PurchaseOrderNo: receipt.PurchaseOrderNo,
      VendorNo: receipt.VendorNo,
      InvoiceNo: receipt.InvoiceNo,
      InvoiceDate: receipt.InvoiceDate,
      BatchNo: receipt.BatchNo,
      ApprovedWithError: 'Y',
      ResolutionNotes: receipt.ResolutionNotes,
      Lines: []
    };

    this.receiptOfGoodsPending[index].Lines.forEach(item => {
      if (item.ItemsMatch === 0) {
        if (item.ItemExistsInRog === 0) {
          const obj = {
            ErrorType: 'History',
            ErrorSubType: 'ItemMissingFromRog',
            HistoryItemCode: Boolean(item.ItemCodeActual) ? item.ItemCodeActual : '',
            HistoryItemDesc: Boolean(item.ItemDescription) ? item.ItemDescription : '',
            HistoryQuantityReceived: Boolean(item.QtyReceived) ? item.QtyReceived.toString() : '0',
            HistoryQuantityInvoiced: '0',
            HistoryExtendedAmt: '0',
            RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
            RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
            RogQuantityReceived:  Boolean(item.RogQuantity) ? item.RogQuantity.toString() : '0',
            RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
            RoiItemCode: '',
            RoiItemDesc: '',
            RoiQuantityInvoiced:  '0',
            RoiUnitCost: '0',
            RoiExtendedAmt: '0',
            PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
            PoItemDesc: Boolean(item.POItemCodeDesc) ? item.POItemCodeDesc : '',
            PoQuantityOrdered:  Boolean(item.POQuantityOrdered) ? item.POQuantityOrdered.toString()  : '0',
            PoUnitCost: '0',
          };
          body.Lines.push(obj);
        }
        if (item.ItemExistsInHistory === 0) {
          const obj = {
            ErrorType: 'History',
            ErrorSubType: 'ItemMissingFromHistory',
            HistoryItemCode: Boolean(item.ItemCodeActual) ? item.ItemCodeActual : '',
            HistoryItemDesc: Boolean(item.ItemDescription) ? item.ItemDescription : '',
            HistoryQuantityReceived: Boolean(item.QtyReceived) ? item.QtyReceived.toString() : '0',
            HistoryQuantityInvoiced: '0',
            HistoryExtendedAmt: '0',
            RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
            RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
            RogQuantityReceived:  Boolean(item.RogQuantity) ? item.RogQuantity.toString() : '0',
            RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
            RoiItemCode: '',
            RoiItemDesc: '',
            RoiQuantityInvoiced:  '0',
            RoiUnitCost: '0',
            RoiExtendedAmt: '0',
            PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
            PoItemDesc: Boolean(item.POItemCodeDesc) ? item.POItemCodeDesc : '',
            PoQuantityOrdered:  Boolean(item.POQuantityOrdered) ? item.POQuantityOrdered.toString()  : '0',
            PoUnitCost: '0',
          };
          body.Lines.push(obj);
        }
      }

      if (item.QuantitiesMatch === 0 && item.ItemExistsInRog === 1 && item.ItemExistsInHistory === 1) {
        const obj = {
          ErrorType: 'History',
          ErrorSubType: 'ItemQuantitiesDoNotMatch',
          HistoryItemCode: Boolean(item.ItemCodeActual) ? item.ItemCodeActual : '',
          HistoryItemDesc: Boolean(item.ItemDescription) ? item.ItemDescription : '',
          HistoryQuantityReceived: Boolean(item.QtyReceived) ? item.QtyReceived.toString() : '0',
          HistoryQuantityInvoiced: '0',
          HistoryExtendedAmt: '0',
          RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
          RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
          RogQuantityReceived:  Boolean(item.RogQuantity) ? item.RogQuantity.toString() : '0',
          RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
          RoiItemCode: '',
          RoiItemDesc: '',
          RoiQuantityInvoiced:  '0',
          RoiUnitCost: '0',
          RoiExtendedAmt: '0',
          PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
          PoItemDesc: Boolean(item.POItemCodeDesc) ? item.POItemCodeDesc : '',
          PoQuantityOrdered:  Boolean(item.POQuantityOrdered) ? item.POQuantityOrdered.toString()  : '0',
          PoUnitCost: '0',
        };
        body.Lines.push(obj);
      }

      if (item.ItemsMatchPO === 0) {
        if (item.ItemExistsInPO === 0) {
          const obj = {
            ErrorType: 'Po',
            ErrorSubType: 'ItemMissingFromPo',
            HistoryItemCode: Boolean(item.ItemCodeActual) ? item.ItemCodeActual : '',
            HistoryItemDesc: Boolean(item.ItemDescription) ? item.ItemDescription : '',
            HistoryQuantityReceived: Boolean(item.QtyReceived) ? item.QtyReceived.toString() : '0',
            HistoryQuantityInvoiced: '0',
            HistoryExtendedAmt: '0',
            RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
            RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
            RogQuantityReceived:  Boolean(item.RogQuantity) ? item.RogQuantity.toString() : '0',
            RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
            RoiItemCode: '',
            RoiItemDesc: '',
            RoiQuantityInvoiced:  '0',
            RoiUnitCost: '0',
            RoiExtendedAmt: '0',
            PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
            PoItemDesc: Boolean(item.POItemCodeDesc) ? item.POItemCodeDesc : '',
            PoQuantityOrdered:  Boolean(item.POQuantityOrdered) ? item.POQuantityOrdered.toString()  : '0',
            PoUnitCost: '0',
          };
          body.Lines.push(obj);
        }
      }

      if (item.ItemsQuantityValid === 0 && item.ItemsMatchPO === 1) {
        const obj = {
          ErrorType: 'Po',
          ErrorSubType: 'ItemQuantityInvalid',
          HistoryItemCode: Boolean(item.ItemCodeActual) ? item.ItemCodeActual : '',
          HistoryItemDesc: Boolean(item.ItemDescription) ? item.ItemDescription : '',
          HistoryQuantityReceived: Boolean(item.QtyReceived) ? item.QtyReceived.toString() : '0',
          HistoryQuantityInvoiced: '0',
          HistoryExtendedAmt: '0',
          RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
          RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
          RogQuantityReceived:  Boolean(item.RogQuantity) ? item.RogQuantity.toString() : '0',
          RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
          RoiItemCode: '',
          RoiItemDesc: '',
          RoiQuantityInvoiced:  '0',
          RoiUnitCost: '0',
          RoiExtendedAmt: '0',
          PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
          PoItemDesc: Boolean(item.POItemCodeDesc) ? item.POItemCodeDesc : '',
          PoQuantityOrdered:  Boolean(item.POQuantityOrdered) ? item.POQuantityOrdered.toString()  : '0',
          PoUnitCost: '0',
        };
        body.Lines.push(obj);
      }
    });

    console.log(body);

    observables.push(this.sageApi.postRequest('ReceiptApproval', body));

    concat(...observables).subscribe(response => {
      console.log('post request', response);
    }, err => {
      this.receiptOfGoodsUpdating = false;
      console.log(err);
      this.alertify.error('Error - ' + err.message);
    }, () => {
      this.closeModal();
      this.receiptOfGoodsPending.splice(index, 1);
      this.onReceiptDeselected();
      this.receiptOfGoodsUpdating = false;
      this.alertify.success('Success');
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalTemplate = this.modalService.show(template);
  }

  closeModal() {
    this.modalTemplate.hide();
  }

  fileNames(receipt: any) {
    const route = `ReceiptOfGoods/file?purchaseOrderNo=${receipt.PurchaseOrderNo}`;
    this.fileList = [];
    this.sageApi.pullReport(route).subscribe((response: any) => {
      console.log(response, response.split(', \\\\gp-nas2\\SO_WT_Files'));
      const splitFiles = response.split(', \\\\gp-nas2\\SO_WT_Files');
      splitFiles.forEach(file => {
        console.log(file);
        if (file) {
          this.fileList.push({ name: file.split(receipt.PurchaseOrderNo + '\\')[1]});
        }
      });
    }, err => {
      console.log(err);
      this.alertify.error(err.message);
    }, () => {
      console.log(this.fileList);
    });
  }

  fileDownload(receipt: any, fileName: string) {
    // tslint:disable-next-line:max-line-length
    const route = `ReceiptOfGoods/file/download?purchaseOrderNo=${receipt.PurchaseOrderNo}&fileName=${fileName}`;

    this.sageApi.getFile(route).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      if (fileName) {
        downloadLink.setAttribute('download', fileName);
      }
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.parentNode.removeChild(downloadLink);
    }, err => {
      console.log(err);
      this.alertify.error(err.message);
    });
  }

  fileChosen(event) {
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        const obj = {
          name: files[index].name,
          data: files[index]
        };

        this.fileArray = [obj];
      }
    }
  }

  fileUpload(receipt: any) {
    this.fileLoading = true;
    // tslint:disable-next-line:max-line-length
    const route = `ReceiptOfGoods/file/upload?purchaseOrderNo=${receipt.PurchaseOrderNo}&invoiceNo=${receipt.InvoiceNo}`;
    const sentFiles = [];

    this.fileArray.forEach( file => {
      sentFiles.push(file.data);
    });

    console.log(sentFiles);

    this.sageApi.uploadFile(route, sentFiles).subscribe((res) => {
      console.log(res);
    }, err => {
      console.log(err);
      this.fileLoading = false;
    }, () => {
      this.resetUpload();
      this.alertify.success('File Loaded');
    });
  }

  resetUpload() {
    this.fileArray = [];
    this.fileLoading = false;
  }

}
