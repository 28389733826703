import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.css']
})
export class MediaGalleryComponent implements OnInit, OnChanges {
    
    isMobile: boolean = false;

    @Input() slides;

    currentSlide = 0;

    @ViewChild('medgalPickerContent') medgalPickerContent: ElementRef<HTMLDivElement>;
    @ViewChildren('medgalPickerContentSlide') medgalPickerContentSlides: QueryList<ElementRef<HTMLDivElement>>;
    
    constructor(
        breakpointObserver: BreakpointObserver
    ){
        breakpointObserver.observe([
            Breakpoints.Small,
            Breakpoints.XSmall
        ]).subscribe(result => {
            if (result.matches) {
                this.isMobile = true;
            }else{
                this.isMobile = false;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if( 
            Array.isArray(changes['slides'].currentValue) 
            && Array.isArray(changes['slides'].currentValue) 
            && changes['slides'].currentValue
            && changes['slides'].previousValue 
            && changes['slides'].currentValue.sort().join() != changes['slides'].previousValue.sort().join()
        ){
            this.currentSlide = 0;
            this.medgalPickerContent.nativeElement.scroll({
                left: 0, 
                behavior: 'smooth'
            });
        }
    }
    
    ngOnInit(): void {
        this.currentSlide = 0;
    }

    onPreviousClick() {
        const previous = this.currentSlide - 1;
        this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
        this.medgalPickerContentSlides.get(this.currentSlide).nativeElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
    }

    onNextClick() {
        const next = this.currentSlide + 1;
        this.currentSlide = next == this.slides.length ? 0 : next;
        this.medgalPickerContentSlides.get(this.currentSlide).nativeElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
    }

    scrollPicker(scrollAmount: number){
        this.medgalPickerContent.nativeElement.scroll({
            left: this.medgalPickerContent.nativeElement.scrollLeft+scrollAmount, 
            behavior: 'smooth'
        });
    }

    isDisplayableMedia(doc){
        const acceptableMediaExtentions = [
            'png',
            'jpg',
            'jpeg',
            'svg',
            'gif',
            'webm'
          ];
      
        const split = doc.split('.');
        const last = split[split.length-1];
        if(acceptableMediaExtentions.includes(last.toLowerCase())){
            return true;
        }
        return false;
    }

    getFileExtension(doc){
        const split = doc.split('.');
        return split[split.length-1];
    }

}