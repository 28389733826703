import { AfterViewInit, Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { concat } from 'rxjs';

@Component({
  selector: 'app-plan-setup',
  templateUrl: './plan-setup.component.html',
  styleUrls: ['./plan-setup.component.css']
})
export class PlanSetupComponent implements OnInit, AfterViewInit {
  isPlanLoading: boolean;
  planList: PlanList[] = [];



  tableDisplayedColumns: string[] = [
    'name',
    'description',
    'action'
  ];
  dataSource: MatTableDataSource<PlanList> | null;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loadPlanList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openPlanForm() {
    const dialogRef = this.dialog.open(PlanSetupCreateDialogComponent, {
      data: {},
      minHeight: '20vh',
      minWidth: '30vw'
      // width: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result?.status === 'success') {
        const plan: PlanList = {
          name: result.plan,
          description: result.description,
          origDescription: result.description,
          updating: false,
          updated: false
        };
        this.planList.push(plan);

        this.table.renderRows();
      }
    });
  }

  savePlan(element: PlanList) {
    element.updating = true;

    const endpoint = 'Plan/Update';
    const observables = [];
    const body: any = {
      Username: this.authService.decodedToken?.nameid.toLocaleLowerCase(),
      Plan: element.name,
      Description: element.description
    };

    observables.push(this.sageApi.putRequest(endpoint, body));

    concat(...observables).subscribe((response: any) => {

    }, err => {
      element.updating = false;
      this.alertify.error(err.error.text);
    }, () => {
      element.origDescription = element.description;
      element.updating = false;
      element.updated = true;
      this.alertify.success('Successfully updated ' + element.name);
    });
  }

  loadPlanList() {
    const endpoint = 'Plan';
    this.isPlanLoading = true;

    this.sageApi.pullReport(endpoint).subscribe((plans: ApiPlanList[]) => {
      console.log(plans);
      if (Array.isArray(plans)) {
        plans.forEach(apiPlan => {
          const plan: PlanList = {
            name: apiPlan.UDF_PLAN_CODE,
            description: apiPlan.UDF_DESCRIPTION,
            origDescription: apiPlan.UDF_DESCRIPTION,
            updating: false,
            updated: false
          };

          this.planList.push(plan);
        });
      }
    }, err => {
      this.isPlanLoading = false;
      this.alertify.error(err.Message);
    }, () => {
      this.isPlanLoading = false;
      this.dataSource = new MatTableDataSource(this.planList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}

@Component({
  selector: 'app-plan-setup-create',
  templateUrl: './plan-setup-create.component.html',
})
export class PlanSetupCreateDialogComponent {
  isAddPlanLoading: boolean;

  planForm = new FormGroup({
    Name: new FormControl('', [
      Validators.required
    ]),
    Description: new FormControl('', [
      Validators.required
    ])
  });

  constructor(
    public dialogRef: MatDialogRef<PlanSetupCreateDialogComponent>,
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {}
  ) {}

  addPlan($event) {
    this.isAddPlanLoading = true;
    const planNumber = this.planForm.get('Name').value;
    const planDescription = this.planForm.get('Description').value;

    const endpoint = 'Plan/Create';
    const body: any = {
      Username: this.authService.decodedToken?.nameid.toLocaleLowerCase(),
      Plan: planNumber,
      Description: planDescription
    };

    this.sageApi.postRequest(endpoint, body).subscribe(response => {
      console.log(response);
    }, err => {
      console.log(err);
      if (err.status === 409) {
        this.alertify.error('Plan Already Exists');
      } else {
        this.alertify.error('Could not create: ' + err.error?.message);
      }
      this.isAddPlanLoading = false;
    }, () => {
      this.isAddPlanLoading = false;
      this.alertify.success('Plan ' + planNumber + ' created successfully');
      this.dialogRef.close({
        plan: planNumber,
        description: planDescription,
        status : 'success'
      });
    });
  }
}

interface PlanList {
  name: string;
  description: string;
  origDescription: string;
  updating: boolean;
  updated: boolean;
}

interface ApiPlanList {
  UDF_PLAN_CODE: string;
  UDF_DESCRIPTION: string;
}
