import { Component, OnInit, TemplateRef } from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';

@Component({
  selector: 'app-receipt-of-invoice-approval',
  templateUrl: './receipt-of-invoice-approval.component.html',
  styleUrls: ['./receipt-of-invoice-approval.component.css']
})
export class ReceiptOfInvoiceApprovalComponent implements OnInit {
  modalTemplate: BsModalRef;

  fileLoading: boolean;
  fileArray = [];
  fileList = [];

  receiptOfInvoicesPending = [];
  receiptOfInvoicesLoading = false;
  receiptOfInvoiceUpdating = false;

  receiptSelectedIndex: number;
  receiptSelected: boolean;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.getPendingReceiptOfInvoicess();
  }

  openModal(template: TemplateRef<any>) {
    this.modalTemplate = this.modalService.show(template);
  }

  closeModal() {
    this.modalTemplate.hide();
  }

  onReceiptSelected(index: number) {
    this.sortItems(index);
    this.receiptSelectedIndex = index;
    this.receiptSelected = true;
  }

  onReceiptDeselected() {
    this.receiptSelectedIndex = 0;
    this.receiptSelected = false;
  }

  numberOfErrors(index: number) {
    let errorCount = 0;
    this.receiptOfInvoicesPending[index].Lines.forEach(item => {
      if (item.ItemsMatch === 0) {
        if (item.ItemExistsRoi === 0) {
          errorCount++;
        }
        if (item.ItemExistsHistory === 0) {
          errorCount++;
        }
      }

      if (item.QuantitiesMatch === 0 &&
           item.ItemExistsHistory === 1 &&
            item.ItemExistsRoi === 1) {
        errorCount++;
      }

      if (item.ExtAmtMatch === 0 &&
            item.QuantitiesMatch === 1 &&
              item.ItemExistsHistory === 1 &&
                item.ItemExistsRoi === 1) {
        errorCount++;
      }

      if (item.ItemMatchPO === 0) {
        if (item.ItemExistsInPO === 0) {
          errorCount++;
        }
      }

      if (item.CostsMatch === 0 &&
            item.ItemExistsInPO === 1) {
        errorCount++;
      }

      if (item.ItemMatchRog === 0) {
        if (item.ItemExistsInRog === 0) {
          errorCount++;
        }
        if (item.ItemExistsInRoi === 0) {
          errorCount++;
        }
      }

      if (item.QuantitiesMatchRog === 0 &&
            item.ItemExistsInRoi === 1 &&
              item.ItemExistsInRog === 1) {
        errorCount++;
      }
    });

    return  errorCount;
  }

  numberOfErrorsIndividualLine(item: any) {
    let individualErrorCount = 0;
    if (item.ItemsMatch === 0) {
      if (item.ItemExistsRoi === 0) {
        individualErrorCount++;
      }
      if (item.ItemExistsHistory === 0) {
        individualErrorCount++;
      }
    }

    if (item.QuantitiesMatch === 0 &&
         item.ItemExistsHistory === 1 &&
          item.ItemExistsRoi === 1) {
      individualErrorCount++;
    }

    if (item.ExtAmtMatch === 0 &&
          item.QuantitiesMatch === 1 &&
            item.ItemExistsHistory === 1 &&
              item.ItemExistsRoi === 1) {
      individualErrorCount++;
    }

    if (item.ItemMatchPO === 0) {
      if (item.ItemExistsInPO === 0) {
        individualErrorCount++;
      }
    }

    if (item.CostsMatch === 0 &&
          item.ItemExistsInPO === 1) {
      individualErrorCount++;
    }

    if (item.ItemMatchRog === 0) {
      if (item.ItemExistsInRog === 0) {
        individualErrorCount++;
      }
      if (item.ItemExistsInRoi === 0) {
        individualErrorCount++;
      }
    }

    if (item.QuantitiesMatchRog === 0 &&
          item.ItemExistsInRoi === 1 &&
            item.ItemExistsInRog === 1) {
      individualErrorCount++;
    }

    return individualErrorCount;
  }

  itemDiscrepancy(lineIndex: number, receiptIndex: number) {
    return  this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex].ItemsMatch === 0 ||
            this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex].ItemExistsRoi === 0 ||
            this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex].ItemExistsHistory === 0 ||
            this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex].QuantitiesMatch === 0 ||
            this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex].ExtAmtMatch === 0 ||
            this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex].CostsMatch === 0 ||
            this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex].ItemExistsInPO === 0 ||
            this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex].ItemMatchPO === 0 ||
            this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex].ItemMatchRog === 0 ||
            this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex].ItemExistsInRoi === 0 ||
            this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex].ItemExistsInRog === 0 ||
            this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex].QuantitiesMatchRog === 0;
  }

  getPendingReceiptOfInvoicess() {
    this.receiptOfInvoicesLoading = true;
    this.receiptOfInvoicesPending = [];
    this.sageApi.pullReport('ReceiptApproval/ReceiptOfInvoice/Combined').subscribe((invoices: []) => {
      if (Array.isArray(invoices)) {
        this.receiptOfInvoicesPending = invoices;
      }
    }, err => {
      console.log(err);
      this.receiptOfInvoicesLoading = false;
    }, () => {
      this.receiptOfInvoicesLoading = false;
      console.log('Invoices', this.receiptOfInvoicesPending);
    });
  }

  sortItems(index: number) {
    const arr = this.receiptOfInvoicesPending[index].Lines;
    arr.sort((a, b) => {
      const ascDirection = false; // sort numbers in descending
      const aErrorCount = this.numberOfErrorsIndividualLine(a);
      const bErrorCount = this.numberOfErrorsIndividualLine(b);

      if (aErrorCount < bErrorCount) {
        return ascDirection ? -1 : 1;
      }
      if (aErrorCount > bErrorCount) {
        return ascDirection ? 1 : -1;
      }
      // items must be equal
      return 0;
    });
  }

  onApproveReceiptOfInvoice(receipt: any, index: number) {
    this.receiptOfInvoiceUpdating = true;
    const observables = [];
    const body = {
      Username: this.authService.decodedToken.nameid,
      ReceiptNo: receipt.ReceiptNo,
      ReceiptType: receipt.ReceiptType,
      PurchaseOrderNo: receipt.PurchaseOrderNo,
      VendorNo: receipt.VendorNo,
      InvoiceNo: receipt.InvoiceNo,
      InvoiceDate: receipt.InvoiceDate,
      BatchNo: receipt.BatchNo,
      ApprovedWithError: 'Y',
      ResolutionNotes: receipt.ResolutionNotes,
      Lines: []
    };

    if (receipt.ShippingMatch === 0) {
      const object = {
        ErrorType: 'History',
        ErrorSubType: 'ShippingNotEqual',
        HistoryItemCode: '',
        HistoryItemDesc: '',
        HistoryQuantityReceived: '0',
        HistoryQuantityInvoiced: '0',
        HistoryExtendedAmt: '0',
        HistoryHeaderShipping : Boolean(receipt.HajocaShipping) ? receipt.HajocaShipping : 0,
        HistoryHeaderSalesTax : Boolean(receipt.HajocaSalesTax) ? receipt.HajocaSalesTax : 0,
        HistoryHeaderTotalDue : Boolean(receipt.HajocaTotalDue) ? receipt.HajocaTotalDue : 0,
        RogItemCode: '',
        RogItemDesc: '',
        RogQuantityReceived: '0',
        RogReceiptNo: '',
        RoiItemCode: '',
        RoiItemDesc: '',
        RoiQuantityInvoiced: '0',
        RoiUnitCost: '0',
        RoiExtendedAmt: '0',
        RoiHeaderShipping : Boolean(receipt.InvoiceShipping) ? receipt.InvoiceShipping : 0,
        RoiHeaderSalesTax : Boolean(receipt.InvoiceSalesTax) ? receipt.InvoiceSalesTax : 0,
        RoiHeaderTotalDue : Boolean(receipt.InvoiceTotalDue) ? receipt.InvoiceTotalDue : 0,
        PoItemCode: '',
        PoItemDesc: '',
        PoQuantityOrdered: '0',
        PoUnitCost: '0'
      };
      body.Lines.push(object);
    }

    if (receipt.SalesTaxMatch === 0) {
      const object = {
        ErrorType: 'History',
        ErrorSubType: 'SalesTaxNotEqual',
        HistoryItemCode: '',
        HistoryItemDesc: '',
        HistoryQuantityReceived: '0',
        HistoryQuantityInvoiced: '0',
        HistoryExtendedAmt: '0',
        HistoryHeaderShipping : Boolean(receipt.HajocaShipping) ? receipt.HajocaShipping : 0,
        HistoryHeaderSalesTax : Boolean(receipt.HajocaSalesTax) ? receipt.HajocaSalesTax : 0,
        HistoryHeaderTotalDue : Boolean(receipt.HajocaTotalDue) ? receipt.HajocaTotalDue : 0,
        RogItemCode: '',
        RogItemDesc: '',
        RogQuantityReceived: '0',
        RogReceiptNo: '',
        RoiItemCode: '',
        RoiItemDesc: '',
        RoiQuantityInvoiced: '0',
        RoiUnitCost: '0',
        RoiExtendedAmt: '0',
        RoiHeaderShipping : Boolean(receipt.InvoiceShipping) ? receipt.InvoiceShipping : 0,
        RoiHeaderSalesTax : Boolean(receipt.InvoiceSalesTax) ? receipt.InvoiceSalesTax : 0,
        RoiHeaderTotalDue : Boolean(receipt.InvoiceTotalDue) ? receipt.InvoiceTotalDue : 0,
        PoItemCode: '',
        PoItemDesc: '',
        PoQuantityOrdered: '0',
        PoUnitCost: '0'
      };
      body.Lines.push(object);
    }

    if (receipt.TotalDueMatch === 0) {
      const object = {
        ErrorType: 'History',
        ErrorSubType: 'TotalDueNotEqual',
        HistoryItemCode: '',
        HistoryItemDesc: '',
        HistoryQuantityReceived: '0',
        HistoryQuantityInvoiced: '0',
        HistoryExtendedAmt: '0',
        HistoryHeaderShipping : Boolean(receipt.HajocaShipping) ? receipt.HajocaShipping : 0,
        HistoryHeaderSalesTax : Boolean(receipt.HajocaSalesTax) ? receipt.HajocaSalesTax : 0,
        HistoryHeaderTotalDue : Boolean(receipt.HajocaTotalDue) ? receipt.HajocaTotalDue : 0,
        RogItemCode: '',
        RogItemDesc: '',
        RogQuantityReceived: '0',
        RogReceiptNo: '',
        RoiItemCode: '',
        RoiItemDesc: '',
        RoiQuantityInvoiced: '0',
        RoiUnitCost: '0',
        RoiExtendedAmt: '0',
        RoiHeaderShipping : Boolean(receipt.InvoiceShipping) ? receipt.InvoiceShipping : 0,
        RoiHeaderSalesTax : Boolean(receipt.InvoiceSalesTax) ? receipt.InvoiceSalesTax : 0,
        RoiHeaderTotalDue : Boolean(receipt.InvoiceTotalDue) ? receipt.InvoiceTotalDue : 0,
        PoItemCode: '',
        PoItemDesc: '',
        PoQuantityOrdered: '0',
        PoUnitCost: '0'
      };
      body.Lines.push(object);
    }

    this.receiptOfInvoicesPending[index].Lines.forEach(item => {
      if (item.ItemsMatch === 0) {
        if (item.ItemExistsRoi === 0) {
          const obj = {
            ErrorType: 'History',
            ErrorSubType: 'ItemMissingFromRoi',
            HistoryItemCode: Boolean(item.ItemCodeHistory) ? item.ItemCodeHistory : '',
            HistoryItemDesc: Boolean(item.ItemCodeDescHistory) ? item.ItemCodeDescHistory : '',
            HistoryQuantityReceived: '0',
            HistoryQuantityInvoiced: Boolean(item.QuantityInvoicedHistory) ? item.QuantityInvoicedHistory.toString() : '0',
            HistoryExtendedAmt: Boolean(item.ExtendedAmtHistory) ? item.ExtendedAmtHistory.toString() : '0',
            RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
            RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
            RogQuantityReceived:  Boolean(item.RogQtyReceived) ? item.RogQtyReceived.toString() : '0',
            RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
            RoiItemCode: Boolean(item.ItemCodeRoi) ? item.ItemCodeRoi : '',
            RoiItemDesc: Boolean(item.ItemCodeDescRoi) ? item.ItemCodeDescRoi : '',
            RoiQuantityInvoiced:  Boolean(item.QuantityInvoicedRoi) ? item.QuantityInvoicedRoi.toString() : '0',
            RoiUnitCost: Boolean(item.UnitCostRoi) ? item.UnitCostRoi.toString() : '0',
            RoiExtendedAmt: Boolean(item.ExtendedAmtRoi) ? item.ExtendedAmtRoi.toString() : '0',
            PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
            PoItemDesc: Boolean(item.PODescription) ? item.PODescription : '',
            PoQuantityOrdered:  Boolean(item.POQtyOrdered) ? item.POQtyOrdered.toString()  : '0',
            PoUnitCost: Boolean(item.POUnitCost) ? item.POUnitCost.toString() : '0'
          };
          body.Lines.push(obj);
        }
        if (item.ItemExistsHistory === 0) {
          const obj = {
            ErrorType: 'History',
            ErrorSubType: 'ItemMissingFromHistory',
            HistoryItemCode: Boolean(item.ItemCodeHistory) ? item.ItemCodeHistory : '',
            HistoryItemDesc: Boolean(item.ItemCodeDescHistory) ? item.ItemCodeDescHistory : '',
            HistoryQuantityReceived: '0',
            HistoryQuantityInvoiced: Boolean(item.QuantityInvoicedHistory) ? item.QuantityInvoicedHistory.toString() : '0',
            HistoryExtendedAmt: Boolean(item.ExtendedAmtHistory) ? item.ExtendedAmtHistory.toString() : '0',
            RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
            RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
            RogQuantityReceived:  Boolean(item.RogQtyReceived) ? item.RogQtyReceived.toString() : '0',
            RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
            RoiItemCode: Boolean(item.ItemCodeRoi) ? item.ItemCodeRoi : '',
            RoiItemDesc: Boolean(item.ItemCodeDescRoi) ? item.ItemCodeDescRoi : '',
            RoiQuantityInvoiced:  Boolean(item.QuantityInvoicedRoi) ? item.QuantityInvoicedRoi.toString() : '0',
            RoiUnitCost: Boolean(item.UnitCostRoi) ? item.UnitCostRoi.toString() : '0',
            RoiExtendedAmt: Boolean(item.ExtendedAmtRoi) ? item.ExtendedAmtRoi.toString() : '0',
            PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
            PoItemDesc: Boolean(item.PODescription) ? item.PODescription : '',
            PoQuantityOrdered:  Boolean(item.POQtyOrdered) ? item.POQtyOrdered.toString()  : '0',
            PoUnitCost: Boolean(item.POUnitCost) ? item.POUnitCost.toString() : '0'
          };
          body.Lines.push(obj);
        }
      }

      if (item.QuantitiesMatch === 0 &&
           item.ItemExistsHistory === 1 &&
            item.ItemExistsRoi === 1) {
        const obj = {
          ErrorType: 'History',
          ErrorSubType: 'InvoicedQuantitiesDoNotMatch',
          HistoryItemCode: Boolean(item.ItemCodeHistory) ? item.ItemCodeHistory : '',
          HistoryItemDesc: Boolean(item.ItemCodeDescHistory) ? item.ItemCodeDescHistory : '',
          HistoryQuantityReceived: '0',
          HistoryQuantityInvoiced: Boolean(item.QuantityInvoicedHistory) ? item.QuantityInvoicedHistory.toString() : '0',
          HistoryExtendedAmt: Boolean(item.ExtendedAmtHistory) ? item.ExtendedAmtHistory.toString() : '0',
          RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
          RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
          RogQuantityReceived:  Boolean(item.RogQtyReceived) ? item.RogQtyReceived.toString() : '0',
          RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
          RoiItemCode: Boolean(item.ItemCodeRoi) ? item.ItemCodeRoi : '',
          RoiItemDesc: Boolean(item.ItemCodeDescRoi) ? item.ItemCodeDescRoi : '',
          RoiQuantityInvoiced:  Boolean(item.QuantityInvoicedRoi) ? item.QuantityInvoicedRoi.toString() : '0',
          RoiUnitCost: Boolean(item.UnitCostRoi) ? item.UnitCostRoi.toString() : '0',
          RoiExtendedAmt: Boolean(item.ExtendedAmtRoi) ? item.ExtendedAmtRoi.toString() : '0',
          PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
          PoItemDesc: Boolean(item.PODescription) ? item.PODescription : '',
          PoQuantityOrdered:  Boolean(item.POQtyOrdered) ? item.POQtyOrdered.toString()  : '0',
          PoUnitCost: Boolean(item.POUnitCost) ? item.POUnitCost.toString() : '0'
        };
        body.Lines.push(obj);
      }

      if (item.ExtAmtMatch === 0 &&
            item.QuantitiesMatch === 1 &&
              item.ItemExistsHistory === 1 &&
                item.ItemExistsRoi === 1) {
        const obj = {
          ErrorType: 'History',
          ErrorSubType: 'InvoicedExtAmtDoNotMatch',
          HistoryItemCode: Boolean(item.ItemCodeHistory) ? item.ItemCodeHistory : '',
          HistoryItemDesc: Boolean(item.ItemCodeDescHistory) ? item.ItemCodeDescHistory : '',
          HistoryQuantityReceived: '0',
          HistoryQuantityInvoiced: Boolean(item.QuantityInvoicedHistory) ? item.QuantityInvoicedHistory.toString() : '0',
          HistoryExtendedAmt: Boolean(item.ExtendedAmtHistory) ? item.ExtendedAmtHistory.toString() : '0',
          RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
          RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
          RogQuantityReceived:  Boolean(item.RogQtyReceived) ? item.RogQtyReceived.toString() : '0',
          RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
          RoiItemCode: Boolean(item.ItemCodeRoi) ? item.ItemCodeRoi : '',
          RoiItemDesc: Boolean(item.ItemCodeDescRoi) ? item.ItemCodeDescRoi : '',
          RoiQuantityInvoiced:  Boolean(item.QuantityInvoicedRoi) ? item.QuantityInvoicedRoi.toString() : '0',
          RoiUnitCost: Boolean(item.UnitCostRoi) ? item.UnitCostRoi.toString() : '0',
          RoiExtendedAmt: Boolean(item.ExtendedAmtRoi) ? item.ExtendedAmtRoi.toString() : '0',
          PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
          PoItemDesc: Boolean(item.PODescription) ? item.PODescription : '',
          PoQuantityOrdered:  Boolean(item.POQtyOrdered) ? item.POQtyOrdered.toString()  : '0',
          PoUnitCost: Boolean(item.POUnitCost) ? item.POUnitCost.toString() : '0'
        };
        body.Lines.push(obj);
      }

      if (item.ItemMatchPO === 0) {
        if (item.ItemExistsInPO === 0) {
          const obj = {
            ErrorType: 'PO',
            ErrorSubType: 'ItemMissingFromPO',
            HistoryItemCode: Boolean(item.ItemCodeHistory) ? item.ItemCodeHistory : '',
            HistoryItemDesc: Boolean(item.ItemCodeDescHistory) ? item.ItemCodeDescHistory : '',
            HistoryQuantityReceived: '0',
            HistoryQuantityInvoiced: Boolean(item.QuantityInvoicedHistory) ? item.QuantityInvoicedHistory.toString() : '0',
            HistoryExtendedAmt: Boolean(item.ExtendedAmtHistory) ? item.ExtendedAmtHistory.toString() : '0',
            RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
            RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
            RogQuantityReceived:  Boolean(item.RogQtyReceived) ? item.RogQtyReceived.toString() : '0',
            RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
            RoiItemCode: Boolean(item.ItemCodeRoi) ? item.ItemCodeRoi : '',
            RoiItemDesc: Boolean(item.ItemCodeDescRoi) ? item.ItemCodeDescRoi : '',
            RoiQuantityInvoiced:  Boolean(item.QuantityInvoicedRoi) ? item.QuantityInvoicedRoi.toString() : '0',
            RoiUnitCost: Boolean(item.UnitCostRoi) ? item.UnitCostRoi.toString() : '0',
            RoiExtendedAmt: Boolean(item.ExtendedAmtRoi) ? item.ExtendedAmtRoi.toString() : '0',
            PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
            PoItemDesc: Boolean(item.PODescription) ? item.PODescription : '',
            PoQuantityOrdered:  Boolean(item.POQtyOrdered) ? item.POQtyOrdered.toString()  : '0',
            PoUnitCost: Boolean(item.POUnitCost) ? item.POUnitCost.toString() : '0'
          };
          body.Lines.push(obj);
        }
      }

      if (item.CostsMatch === 0 &&
            item.ItemExistsInPO === 1) {
        const obj = {
          ErrorType: 'PO',
          ErrorSubType: 'UnitCostsDoNotMatch',
          HistoryItemCode: Boolean(item.ItemCodeHistory) ? item.ItemCodeHistory : '',
          HistoryItemDesc: Boolean(item.ItemCodeDescHistory) ? item.ItemCodeDescHistory : '',
          HistoryQuantityReceived: '0',
          HistoryQuantityInvoiced: Boolean(item.QuantityInvoicedHistory) ? item.QuantityInvoicedHistory.toString() : '0',
          HistoryExtendedAmt: Boolean(item.ExtendedAmtHistory) ? item.ExtendedAmtHistory.toString() : '0',
          RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
          RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
          RogQuantityReceived:  Boolean(item.RogQtyReceived) ? item.RogQtyReceived.toString() : '0',
          RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
          RoiItemCode: Boolean(item.ItemCodeRoi) ? item.ItemCodeRoi : '',
          RoiItemDesc: Boolean(item.ItemCodeDescRoi) ? item.ItemCodeDescRoi : '',
          RoiQuantityInvoiced:  Boolean(item.QuantityInvoicedRoi) ? item.QuantityInvoicedRoi.toString() : '0',
          RoiUnitCost: Boolean(item.UnitCostRoi) ? item.UnitCostRoi.toString() : '0',
          RoiExtendedAmt: Boolean(item.ExtendedAmtRoi) ? item.ExtendedAmtRoi.toString() : '0',
          PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
          PoItemDesc: Boolean(item.PODescription) ? item.PODescription : '',
          PoQuantityOrdered:  Boolean(item.POQtyOrdered) ? item.POQtyOrdered.toString()  : '0',
          PoUnitCost: Boolean(item.POUnitCost) ? item.POUnitCost.toString() : '0'
        };
        body.Lines.push(obj);
      }

      if (item.ItemMatchRog === 0) {
        if (item.ItemExistsInRog === 0) {
          const obj = {
            ErrorType: 'Goods',
            ErrorSubType: 'ItemMissingFromRog',
            HistoryItemCode: Boolean(item.ItemCodeHistory) ? item.ItemCodeHistory : '',
            HistoryItemDesc: Boolean(item.ItemCodeDescHistory) ? item.ItemCodeDescHistory : '',
            HistoryQuantityReceived: '0',
            HistoryQuantityInvoiced: Boolean(item.QuantityInvoicedHistory) ? item.QuantityInvoicedHistory.toString() : '0',
            HistoryExtendedAmt: Boolean(item.ExtendedAmtHistory) ? item.ExtendedAmtHistory.toString() : '0',
            RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
            RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
            RogQuantityReceived:  Boolean(item.RogQtyReceived) ? item.RogQtyReceived.toString() : '0',
            RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
            RoiItemCode: Boolean(item.ItemCodeRoi) ? item.ItemCodeRoi : '',
            RoiItemDesc: Boolean(item.ItemCodeDescRoi) ? item.ItemCodeDescRoi : '',
            RoiQuantityInvoiced:  Boolean(item.QuantityInvoicedRoi) ? item.QuantityInvoicedRoi.toString() : '0',
            RoiUnitCost: Boolean(item.UnitCostRoi) ? item.UnitCostRoi.toString() : '0',
            RoiExtendedAmt: Boolean(item.ExtendedAmtRoi) ? item.ExtendedAmtRoi.toString() : '0',
            PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
            PoItemDesc: Boolean(item.PODescription) ? item.PODescription : '',
            PoQuantityOrdered:  Boolean(item.POQtyOrdered) ? item.POQtyOrdered.toString()  : '0',
            PoUnitCost: Boolean(item.POUnitCost) ? item.POUnitCost.toString() : '0'
          };
          body.Lines.push(obj);
        }
        if (item.ItemExistsInRoi === 0) {
          const obj = {
            ErrorType: 'Goods',
            ErrorSubType: 'ItemMissingFromRoi',
            HistoryItemCode: Boolean(item.ItemCodeHistory) ? item.ItemCodeHistory : '',
            HistoryItemDesc: Boolean(item.ItemCodeDescHistory) ? item.ItemCodeDescHistory : '',
            HistoryQuantityReceived: '0',
            HistoryQuantityInvoiced: Boolean(item.QuantityInvoicedHistory) ? item.QuantityInvoicedHistory.toString() : '0',
            HistoryExtendedAmt: Boolean(item.ExtendedAmtHistory) ? item.ExtendedAmtHistory.toString() : '0',
            RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
            RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
            RogQuantityReceived:  Boolean(item.RogQtyReceived) ? item.RogQtyReceived.toString() : '0',
            RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
            RoiItemCode: Boolean(item.ItemCodeRoi) ? item.ItemCodeRoi : '',
            RoiItemDesc: Boolean(item.ItemCodeDescRoi) ? item.ItemCodeDescRoi : '',
            RoiQuantityInvoiced:  Boolean(item.QuantityInvoicedRoi) ? item.QuantityInvoicedRoi.toString() : '0',
            RoiUnitCost: Boolean(item.UnitCostRoi) ? item.UnitCostRoi.toString() : '0',
            RoiExtendedAmt: Boolean(item.ExtendedAmtRoi) ? item.ExtendedAmtRoi.toString() : '0',
            PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
            PoItemDesc: Boolean(item.PODescription) ? item.PODescription : '',
            PoQuantityOrdered:  Boolean(item.POQtyOrdered) ? item.POQtyOrdered.toString()  : '0',
            PoUnitCost: Boolean(item.POUnitCost) ? item.POUnitCost.toString() : '0'
          };
          body.Lines.push(obj);
        }
      }

      if (item.QuantitiesMatchRog === 0 &&
            item.ItemExistsInRoi === 1 &&
              item.ItemExistsInRog === 1) {
        const obj = {
          ErrorType: 'Goods',
          ErrorSubType: 'ReceivedInvoicedQuantitiesDoNotMatch',
          HistoryItemCode: Boolean(item.ItemCodeHistory) ? item.ItemCodeHistory : '',
          HistoryItemDesc: Boolean(item.ItemCodeDescHistory) ? item.ItemCodeDescHistory : '',
          HistoryQuantityReceived: '0',
          HistoryQuantityInvoiced: Boolean(item.QuantityInvoicedHistory) ? item.QuantityInvoicedHistory.toString() : '0',
          HistoryExtendedAmt: Boolean(item.ExtendedAmtHistory) ? item.ExtendedAmtHistory.toString() : '0',
          RogItemCode: Boolean(item.RogItemCode) ? item.RogItemCode : '',
          RogItemDesc: Boolean(item.RogItemCodeDesc) ? item.RogItemCodeDesc : '',
          RogQuantityReceived:  Boolean(item.RogQtyReceived) ? item.RogQtyReceived.toString() : '0',
          RogReceiptNo: Boolean(item.RogReceiptNo) ? item.RogReceiptNo : '',
          RoiItemCode: Boolean(item.ItemCodeRoi) ? item.ItemCodeRoi : '',
          RoiItemDesc: Boolean(item.ItemCodeDescRoi) ? item.ItemCodeDescRoi : '',
          RoiQuantityInvoiced:  Boolean(item.QuantityInvoicedRoi) ? item.QuantityInvoicedRoi.toString() : '0',
          RoiUnitCost: Boolean(item.UnitCostRoi) ? item.UnitCostRoi.toString() : '0',
          RoiExtendedAmt: Boolean(item.ExtendedAmtRoi) ? item.ExtendedAmtRoi.toString() : '0',
          PoItemCode: Boolean(item.POItemCode) ? item.POItemCode : '',
          PoItemDesc: Boolean(item.PODescription) ? item.PODescription : '',
          PoQuantityOrdered:  Boolean(item.POQtyOrdered) ? item.POQtyOrdered.toString()  : '0',
          PoUnitCost: Boolean(item.POUnitCost) ? item.POUnitCost.toString() : '0'
        };
        body.Lines.push(obj);
      }
    });
    // receipt.Errors.Goods.ItemCodesDoNotMatch.forEach(err => {
    //   const obj = {
    //     ErrorType: 'Goods',
    //     ErrorSubType: 'ItemCodesDoNotMatch',
    //     HistoryItemCode: '',
    //     HistoryItemDesc: '',
    //     HistoryQuantityReceived: '0',
    //     HistoryQuantityInvoiced: '0',
    //     HistoryExtendedAmt: '0',
    //     RogItemCode: Boolean(err.ItemCodeRog) ? err.ItemCodeRog : '',
    //     RogItemDesc: Boolean(err.ItemCodeDescRog) ? err.ItemCodeDescRog : '',
    //     RogQuantityReceived:  '0',
    //     RogReceiptNo:  Boolean(err.ReceiptNoRog) ? err.ReceiptNoRog : '',
    //     RoiItemCode:  Boolean(err.ItemCodeRoi) ? err.ItemCodeRoi : '',
    //     RoiItemDesc:  Boolean(err.ItemCodeDescRoi) ? err.ItemCodeDescRoi : '',
    //     RoiQuantityInvoiced:  '0',
    //     RoiUnitCost: '0',
    //     RoiExtendedAmt: '0',
    //     PoItemCode: '',
    //     PoItemDesc: '',
    //     PoQuantityOrdered:  '0',
    //     PoUnitCost: '0',
    //   };

    //   body.Lines.push(obj);
    // });

    // receipt.Errors.Goods.ItemMissingFromRog.forEach(err => {
    //   const obj = {
    //     ErrorType: 'Goods',
    //     ErrorSubType: 'ItemCodeMissingFromRog',
    //     HistoryItemCode: '',
    //     HistoryItemDesc: '',
    //     HistoryQuantityReceived: '0',
    //     HistoryQuantityInvoiced: '0',
    //     HistoryExtendedAmt: '0',
    //     RogItemCode: '',
    //     RogItemDesc: '',
    //     RogQuantityReceived:  '0',
    //     RogReceiptNo:  '',
    //     RoiItemCode:  Boolean(err.ItemCode) ? err.ItemCode : '',
    //     RoiItemDesc:  Boolean(err.ItemCodeDesc) ? err.ItemCodeDesc : '',
    //     RoiQuantityInvoiced:  '0',
    //     RoiUnitCost: '0',
    //     RoiExtendedAmt: '0',
    //     PoItemCode: '',
    //     PoItemDesc: '',
    //     PoQuantityOrdered: '0',
    //     PoUnitCost: '0',
    //   };

    //   body.Lines.push(obj);
    // });

    // receipt.Errors.Goods.ItemMissingFromRoi.forEach(err => {
    //   const obj = {
    //     ErrorType: 'Goods',
    //     ErrorSubType: 'ItemCodeMissingFromRoi',
    //     HistoryItemCode: '',
    //     HistoryItemDesc: '',
    //     HistoryQuantityReceived: '0',
    //     HistoryQuantityInvoiced: '0',
    //     HistoryExtendedAmt: '0',
    //     RogItemCode: Boolean(err.ItemCode) ? err.ItemCode : '',
    //     RogItemDesc: Boolean(err.ItemCodeDesc) ? err.ItemCodeDesc : '',
    //     RogQuantityReceived:  '0',
    //     RogReceiptNo:  '',
    //     RoiItemCode:  '',
    //     RoiItemDesc:  '',
    //     RoiQuantityInvoiced:  '0',
    //     RoiUnitCost: '0',
    //     RoiExtendedAmt: '0',
    //     PoItemCode: '',
    //     PoItemDesc: '',
    //     PoQuantityOrdered:  '0',
    //     PoUnitCost: '0',
    //   };

    //   body.Lines.push(obj);
    // });

    // receipt.Errors.Goods.ItemQuantitiesDoNotMatch.forEach(err => {
    //   const obj = {
    //     ErrorType: 'Goods',
    //     ErrorSubType: 'ItemQuantitiesDoNotMatch',
    //     HistoryItemCode: '',
    //     HistoryItemDesc: '',
    //     HistoryQuantityReceived: '0',
    //     HistoryQuantityInvoiced: '0',
    //     HistoryExtendedAmt: '0',
    //     RogItemCode: Boolean(err.ItemCodeRog) ? err.ItemCodeRog : '',
    //     RogItemDesc: Boolean(err.ItemCodeDescRog) ? err.ItemCodeDescRog : '',
    //     RogQuantityReceived:  Boolean(err.QuantityReceived) ? err.QuantityReceived : '0',
    //     RogReceiptNo:  Boolean(err.ReceiptNoRog) ? err.ReceiptNoRog : '',
    //     RoiItemCode:  Boolean(err.ItemCodeRoi) ? err.ItemCodeRoi : '',
    //     RoiItemDesc:  Boolean(err.ItemCodeDescRoi) ? err.ItemCodeDescRoi : '',
    //     RoiQuantityInvoiced:  Boolean(err.QuantityInvoiced) ? err.QuantityInvoiced : '0',
    //     RoiUnitCost: '0',
    //     RoiExtendedAmt: '0',
    //     PoItemCode: '',
    //     PoItemDesc: '',
    //     PoQuantityOrdered:  '0',
    //     PoUnitCost: '0',
    //   };

    //   body.Lines.push(obj);
    // });

    // receipt.Errors.History.ItemCodesDoNotMatch.forEach(err => {
    //   const obj = {
    //     ErrorType: 'History',
    //     ErrorSubType: 'ItemCodesDoNotMatch',
    //     HistoryItemCode: Boolean(err.ItemCodeHistory) ? err.ItemCodeHistory : '',
    //     HistoryItemDesc: Boolean(err.ItemCodeDescHistory) ? err.ItemCodeDescHistory : '',
    //     HistoryQuantityReceived: '0',
    //     HistoryQuantityInvoiced: '0',
    //     HistoryExtendedAmt: '0',
    //     RogItemCode: '',
    //     RogItemDesc: '',
    //     RogQuantityReceived: '0',
    //     RogReceiptNo: '',
    //     RoiItemCode: Boolean(err.ItemCodeRoi) ? err.ItemCodeRoi : '',
    //     RoiItemDesc: Boolean(err.ItemCodeDescRoi) ? err.ItemCodeDescRoi : '',
    //     RoiQuantityInvoiced: '0',
    //     RoiUnitCost: '0',
    //     RoiExtendedAmt: '0',
    //     PoItemCode: '',
    //     PoItemDesc: '',
    //     PoQuantityOrdered: '0',
    //     PoUnitCost: '0',
    //   };

    //   body.Lines.push(obj);
    // });

    // receipt.Errors.History.ItemMissingFromRoi.forEach(err => {
    //   const obj = {
    //     ErrorType: 'History',
    //     ErrorSubType: 'ItemCodeMissingFromRoi',
    //     HistoryItemCode: Boolean(err.ItemCodeHistory) ? err.ItemCodeHistory : '',
    //     HistoryItemDesc: Boolean(err.ItemCodeDescHistory) ? err.ItemCodeDescHistory : '',
    //     HistoryQuantityReceived: '0',
    //     HistoryQuantityInvoiced: '0',
    //     HistoryExtendedAmt: '0',
    //     RogItemCode: '',
    //     RogItemDesc: '',
    //     RogQuantityReceived: '0',
    //     RogReceiptNo: '',
    //     RoiItemCode: '',
    //     RoiItemDesc: '',
    //     RoiQuantityInvoiced: '0',
    //     RoiUnitCost: '0',
    //     RoiExtendedAmt: '0',
    //     PoItemCode: '',
    //     PoItemDesc: '',
    //     PoQuantityOrdered: '0',
    //     PoUnitCost: '0',
    //   };

    //   body.Lines.push(obj);
    // });

    // receipt.Errors.History.ItemMissingFromHistory.forEach(err => {
    //   const obj = {
    //     ErrorType: 'History',
    //     ErrorSubType: 'ItemCodeMissingFromHistory',
    //     HistoryItemCode: '',
    //     HistoryItemDesc: '',
    //     HistoryQuantityReceived: '0',
    //     HistoryQuantityInvoiced: '0',
    //     HistoryExtendedAmt: '0',
    //     RogItemCode: '',
    //     RogItemDesc: '',
    //     RogQuantityReceived: '0',
    //     RogReceiptNo: '',
    //     RoiItemCode: Boolean(err.ItemCodeRoi) ? err.ItemCodeRoi : '',
    //     RoiItemDesc: Boolean(err.ItemCodeDescRoi) ? err.ItemCodeDescRoi : '',
    //     RoiQuantityInvoiced: '0',
    //     RoiUnitCost: '0',
    //     RoiExtendedAmt: '0',
    //     PoItemCode: '',
    //     PoItemDesc: '',
    //     PoQuantityOrdered: '0',
    //     PoUnitCost: '0',
    //   };

    //   body.Lines.push(obj);
    // });

    // receipt.Errors.History.ItemQuantitiesDoNotMatch.forEach(err => {
    //   const obj = {
    //     ErrorType: 'History',
    //     ErrorSubType: 'ItemQuantitiesDoNotMatch',
    //     HistoryItemCode: Boolean(err.ItemCodeHistory) ? err.ItemCodeHistory : '',
    //     HistoryItemDesc: Boolean(err.ItemCodeDescHistory) ? err.ItemCodeDescHistory : '',
    //     HistoryQuantityReceived: '0',
    //     HistoryQuantityInvoiced: Boolean(err.QuantityInvoicedHistory) ? err.QuantityInvoicedHistory : '0',
    //     HistoryExtendedAmt: '0',
    //     RogItemCode: '',
    //     RogItemDesc: '',
    //     RogQuantityReceived: '0',
    //     RogReceiptNo: '',
    //     RoiItemCode: Boolean(err.ItemCodeRoi) ? err.ItemCodeRoi : '',
    //     RoiItemDesc: Boolean(err.ItemCodeDescRoi) ? err.ItemCodeDescRoi : '',
    //     RoiQuantityInvoiced: Boolean(err.QuantityInvoicedRoi) ? err.QuantityInvoicedRoi : '0',
    //     RoiUnitCost: '0',
    //     RoiExtendedAmt: '0',
    //     PoItemCode: '',
    //     PoItemDesc: '',
    //     PoQuantityOrdered: '0',
    //     PoUnitCost: '0',
    //   };

    //   body.Lines.push(obj);
    // });
    // receipt.Errors.History.ItemExtendedAmtDoNotMatch.forEach(err => {
    //   const obj = {
    //     ErrorType: 'History',
    //     ErrorSubType: 'ItemQuantitiesDoNotMatch',
    //     HistoryItemCode: Boolean(err.ItemCodeHistory) ? err.ItemCodeHistory : '',
    //     HistoryItemDesc: Boolean(err.ItemCodeDescHistory) ? err.ItemCodeDescHistory : '',
    //     HistoryQuantityReceived: '0',
    //     HistoryQuantityInvoiced: Boolean(err.QuantityInvoicedHistory) ? err.QuantityInvoicedHistory : '0',
    //     HistoryExtendedAmt: Boolean(err.ExtendedAmtHistory) ? err.ExtendedAmtHistory : '0',
    //     RogItemCode: '',
    //     RogItemDesc: '',
    //     RogQuantityReceived: '0',
    //     RogReceiptNo: '',
    //     RoiItemCode: Boolean(err.ItemCodeRoi) ? err.ItemCodeRoi : '',
    //     RoiItemDesc: Boolean(err.ItemCodeDescRoi) ? err.ItemCodeDescRoi : '',
    //     RoiQuantityInvoiced: Boolean(err.QuantityInvoicedRoi) ? err.QuantityInvoicedRoi : '0',
    //     RoiUnitCost: '0',
    //     RoiExtendedAmt: Boolean(err.ExtendedAmtRoi) ? err.ExtendedAmtRoi : '0',
    //     PoItemCode: '',
    //     PoItemDesc: '',
    //     PoQuantityOrdered: '0',
    //     PoUnitCost: '0',
    //   };

    //   body.Lines.push(obj);
    // });

    // receipt.Errors.Po.ItemCodesDoNotMatch.forEach(err => {
    //   const obj = {
    //     ErrorType: 'Po',
    //     ErrorSubType: 'ItemCodesDoNotMatch',
    //     HistoryItemCode: '',
    //     HistoryItemDesc: '',
    //     HistoryQuantityReceived: '0',
    //     HistoryQuantityInvoiced: '0',
    //     HistoryExtendedAmt: '0',
    //     RogItemCode: '',
    //     RogItemDesc: '',
    //     RogQuantityReceived: '0',
    //     RogReceiptNo: '',
    //     RoiItemCode: Boolean(err.ItemCodeRoi) ? err.ItemCodeRoi : '',
    //     RoiItemDesc: Boolean(err.ItemCodeDescRoi) ? err.ItemCodeDescRoi : '',
    //     RoiQuantityInvoiced:  '0',
    //     RoiUnitCost: '0',
    //     RoiExtendedAmt: '0',
    //     PoItemCode: Boolean(err.ItemCodePo) ? err.ItemCodePo : '',
    //     PoItemDesc: Boolean(err.ItemCodeDescPo) ? err.ItemCodeDescPo : '',
    //     PoQuantityOrdered: '0',
    //     PoUnitCost: '0',
    //   };

    //   body.Lines.push(obj);
    // });

    // receipt.Errors.Po.ItemCostsDoNotMatch.forEach(err => {
    //   const obj = {
    //     ErrorType: 'Po',
    //     ErrorSubType: 'ItemCostsDoNotMatch',
    //     HistoryItemCode: '',
    //     HistoryItemDesc: '',
    //     HistoryQuantityReceived: '0',
    //     HistoryQuantityInvoiced: '0',
    //     HistoryExtendedAmt: '0',
    //     RogItemCode: '',
    //     RogItemDesc: '',
    //     RogQuantityReceived:  '0',
    //     RogReceiptNo: '',
    //     RoiItemCode: Boolean(err.ItemCodeRoi) ? err.ItemCodeRoi : '',
    //     RoiItemDesc: Boolean(err.ItemCodeDescRoi) ? err.ItemCodeDescRoi : '',
    //     RoiQuantityInvoiced: '0',
    //     RoiUnitCost: Boolean(err.UnitCostRoi) ? err.UnitCostRoi : '0',
    //     RoiExtendedAmt: '0',
    //     PoItemCode: Boolean(err.ItemCodePo) ? err.ItemCodePo : '',
    //     PoItemDesc: Boolean(err.ItemCodeDescPo) ? err.ItemCodeDescPo : '',
    //     PoQuantityOrdered:  '0',
    //     PoUnitCost: Boolean(err.UnitPo) ? err.UnitPo : '0',
    //   };

    //   body.Lines.push(obj);
    // });

    // receipt.Errors.Po.ItemMissingFromPo.forEach(err => {
    //   const obj = {
    //     ErrorType: 'Po',
    //     ErrorSubType: 'ItemMissingFromPo',
    //     HistoryItemCode: '',
    //     HistoryItemDesc: '',
    //     HistoryQuantityReceived: '0',
    //     HistoryQuantityInvoiced: '0',
    //     HistoryExtendedAmt: '0',
    //     RogItemCode: '',
    //     RogItemDesc: '',
    //     RogQuantityReceived: '0',
    //     RogReceiptNo: '',
    //     RoiItemCode:  Boolean(err.ItemCode) ? err.ItemCode : '',
    //     RoiItemDesc:  Boolean(err.ItemCodeDesc) ? err.ItemCodeDesc : '',
    //     RoiQuantityInvoiced: '0',
    //     RoiUnitCost: '0',
    //     RoiExtendedAmt: '0',
    //     PoItemCode: '',
    //     PoItemDesc: '',
    //     PoQuantityOrdered: '0',
    //     PoUnitCost: '0',
    //   };

    //   body.Lines.push(obj);
    // });

    console.log(body);

    observables.push(this.sageApi.postRequest('ReceiptApproval', body));

    concat(...observables).subscribe(response => {
      console.log('post request', response);
    }, err => {
      this.receiptOfInvoiceUpdating = false;
      console.log(err);
      this.alertify.error('Error - ' + err.message);
    }, () => {
      this.closeModal();
      this.receiptOfInvoicesPending.splice(index, 1);
      this.onReceiptDeselected();
      this.receiptOfInvoiceUpdating = false;
      this.alertify.success('Success');
    });
  }

  fileNames(receipt: any) {
    const route = `ReceiptOfGoods/file?purchaseOrderNo=${receipt.PurchaseOrderNo}`;
    this.fileList = [];
    this.sageApi.pullReport(route).subscribe((response: any) => {
      console.log(response, response.split(', \\\\gp-nas2\\SO_WT_Files'));
      const splitFiles = response.split(', \\\\gp-nas2\\SO_WT_Files');
      splitFiles.forEach(file => {
        console.log(file);
        if (file) {
          this.fileList.push({ name: file.split(receipt.PurchaseOrderNo + '\\')[1]});
        }
      });
    }, err => {
      console.log(err);
      this.alertify.error(err.message);
    }, () => {
      console.log(this.fileList);
    });
  }

  fileDownload(receipt: any, fileName: string) {
    // tslint:disable-next-line:max-line-length
    const route = `ReceiptOfGoods/file/download?purchaseOrderNo=${receipt.PurchaseOrderNo}&fileName=${fileName}`;

    this.sageApi.getFile(route).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      if (fileName) {
        downloadLink.setAttribute('download', fileName);
      }
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.parentNode.removeChild(downloadLink);
    }, err => {
      console.log(err);
      this.alertify.error(err.message);
    });
  }

  fileChosen(event) {
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        const obj = {
          name: files[index].name,
          data: files[index]
        };

        this.fileArray = [obj];
      }
    }
  }

  fileUpload(receipt: any) {
    this.fileLoading = true;
    // tslint:disable-next-line:max-line-length
    const route = `ReceiptOfGoods/file/upload?purchaseOrderNo=${receipt.PurchaseOrderNo}&invoiceNo=${receipt.InvoiceNo}`;
    const sentFiles = [];

    this.fileArray.forEach( file => {
      sentFiles.push(file.data);
    });

    console.log(sentFiles);

    this.sageApi.uploadFile(route, sentFiles).subscribe((res) => {
      console.log(res);
    }, err => {
      console.log(err);
      this.fileLoading = false;
    }, () => {
      this.resetUpload();
      this.alertify.success('File Loaded');
    });
  }

  resetUpload() {
    this.fileArray = [];
    this.fileLoading = false;
  }
}
