import { Component, OnInit, TemplateRef } from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';

@Component({
  selector: 'app-schedule-confirmation-queue',
  templateUrl: './schedule-confirmation-queue.component.html',
  styleUrls: ['./schedule-confirmation-queue.component.css']
})
export class ScheduleConfirmationQueueComponent implements OnInit {
  editLeadTime: BsModalRef;
  scheduleConfirmations = {
    tickets: []
  };

  phaseList = [];
  phasesSelected = [];

  statusList = [];
  statusesSelected = [];

  phaseLeadTimes = [];

  addressSearch = '';

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.loadScheduleConfirmations();
    this.loadLeadTimes();
  }

  openModal(template: TemplateRef<any>) {
    this.phaseLeadTimes.forEach(phaseLeadTime => {
      phaseLeadTime.leadTimeChange = '';
    });

    this.editLeadTime = this.modalService.show(template);
  }

  closeModal() {
    this.editLeadTime.hide();
  }

  onStatusSelected(status, statusListIndex) {
    if (this.statusesSelected.includes(status)) {
      this.statusesSelected.splice(this.statusesSelected.indexOf(status), 1);
      this.statusList[statusListIndex].selected = false;
    } else if (status === '') {
       this.statusesSelected.length = 0;
       this.statusList.forEach(statuses => {
        statuses.selected = false;
       });
    } else {
       this.statusesSelected.push(status);
       this.statusList[statusListIndex].selected = true;
    }
  }

  onPhaseSelected(phase, phaseListIndex) {
    if (this.phasesSelected.includes(phase)) {
      this.phasesSelected.splice(this.phasesSelected.indexOf(phase), 1);
      this.phaseList[phaseListIndex].selected = false;
    } else if (phase === '') {
       this.phasesSelected.length = 0;
       this.phaseList.forEach(phases => {
        phases.selected = false;
       });
    } else {
       this.phasesSelected.push(phase);
       this.phaseList[phaseListIndex].selected = true;
    }
  }

  displayTicket(ticketListIndex: number) {
    let displayTicket = false;
    const status = this.scheduleConfirmations.tickets[ticketListIndex].status;
    const phase = this.scheduleConfirmations.tickets[ticketListIndex].phase;
    const address = this.scheduleConfirmations.tickets[ticketListIndex].address;

    if ((this.statusesSelected.includes(status) || this.statusesSelected.length === 0)
          && (this.phasesSelected.includes(phase) || this.phasesSelected.length === 0)
          && (address.includes(this.addressSearch) || this.addressSearch.length === 0)) {
            this.scheduleConfirmations.tickets[ticketListIndex].display = true;
            displayTicket = true;
    } else {
      this.scheduleConfirmations.tickets[ticketListIndex].display = false;
    }
    return displayTicket;
  }

  showTicketNotes(ticketIndex: number) {
    this.scheduleConfirmations.tickets[ticketIndex].showNotes = !this.scheduleConfirmations.tickets[ticketIndex].showNotes;
  }

  sortTickets(keyToSortBy: any) {
    const arr = this.scheduleConfirmations.tickets;
    arr.sort((a, b) => {
      let ascDirection = false; // sort numbers in descending
      let ItemOne = a[keyToSortBy];
      let ItemTwo = b[keyToSortBy];

      if (typeof a[keyToSortBy] === 'string') {
        ItemOne = a[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ItemTwo = b[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ascDirection = true;
      }

      if (ItemOne < ItemTwo) {
        return ascDirection ? -1 : 1;
      }
      if (ItemOne > ItemTwo) {
        return ascDirection ? 1 : -1;
      }
      // items must be equal
      return 0;
    });
  }

  loadScheduleConfirmations() {
    this.sageApi.pullReport('ScheduleConfirmation').subscribe((tickets: Array<any>) => {
      if (Array.isArray(tickets)) {
        tickets.forEach((ticket) => {
          const obj = {
            phase: ticket.Phase,
            scheduleDate: ticket.ScheduleDate,
            rescheduleDate: ticket.RescheduleDate,
            status: ticket.Status,
            statusDate: ticket.StatusDate,
            statusTime: ticket.StatusTime,
            address: ticket.Address,
            salesorderno: ticket.SalesOrderNo,
            wtnumber: ticket.WTNumber,
            wtstep: ticket.WTStep,
            notes: ticket.Notes || '',
            showNotes: false,
            display: true
          };
          this.scheduleConfirmations.tickets.push(obj);

          if (!this.statusList.some(status => status.name === obj.status)) {
            this.statusList.push({name: obj.status, selected: false});
          }
          if (!this.phaseList.some(status => status.name === obj.phase)) {
            this.phaseList.push({name: obj.phase, selected: false});
          }
        });
      } else {

      }
      console.log(this.scheduleConfirmations.tickets);
    }, err => {
      console.log('error', err);
    }, () => {
      this.sortTickets('phase');
    });
  }

  loadLeadTimes() {
    this.sageApi.pullReport('ScheduleConfirmation/LeadTime').subscribe((leadTimes: Array<any>) => {
      if (Array.isArray(leadTimes)) {
        leadTimes.forEach(leadTime => {
          const obj = {
            id: leadTime.id,
            name: leadTime.PhaseName,
            leadTime: leadTime.LeadTime,
            leadTimeChange: ''
          };

          this.phaseLeadTimes.push(obj);
        });
      }
    }, err => {
      console.log(err);
      this.alertify.error(err.error.message);
    }, () => {
      console.log(this.phaseLeadTimes);
    });
  }

  editLeadTimeSubmittal() {
    console.log(this.phaseLeadTimes);
    const observables = [];
    this.phaseLeadTimes.forEach(phase => {
      const body = {
        id: phase.id,
        leadTime: phase.leadTimeChange,
        user: this.authService.decodedToken?.nameid
      };

      if (phase.leadTimeChange !== '') {
        observables.push(this.sageApi.putRequest('ScheduleConfirmation/LeadTime', body));
      }
    });


    console.log(observables);
    concat(...observables).subscribe(response => {
      console.log(response);
    }, err => {
      console.log(err);
      this.alertify.error('Error - ' + err.error.message);
    }, () => {
      this.phaseLeadTimes.forEach(phase => {
        if (phase.leadTimeChange !== '') {
          // tslint:disable-next-line:radix
          phase.leadTime = parseInt(phase.leadTimeChange);
          phase.leadTimeChange = '';
        }
      });
      this.editLeadTime.hide();
      this.alertify.success('Lead Times Updated');
    });
  }
}
