<div class="container">
  <div class="card border-1 m-3">
    <!--    Header-->
    <div class="card-header text-center">
      <div class="row mb-1 justify-content-center">
        <h2><b>Over The Counter (QR Scanner)</b></h2>
      </div>
      <div class="row mb-1" id="addressInfo">
<!--        <ng-container *ngIf="!restrictUser">-->
<!--        <div class="col-12 pt-2 text-center">-->
<!--          <label for="address">Address or WT#</label>-->
<!--&lt;!&ndash;          <label for="address">Dirección o WT#</label>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="col-12">-->
<!--          <input class="form-control" [disabled]="isLoading || restrictUser" [(ngModel)]="addressName"-->
<!--                 (typeaheadOnSelect)="addressSelected($event)" [typeahead]="addressList"-->
<!--                 typeaheadOptionField="name" placeholder="Address" autocomplete="off">-->
<!--        </div>-->
<!--        <div class="col-8">-->
<!--          <input class="form-control my-1" [disabled]="isLoading || restrictUser" [(ngModel)]="selectedPhaseName"-->
<!--                 (typeaheadOnSelect)="phaseSelected($event)" [typeahead]="phases"-->
<!--                 typeaheadOptionField="phaseName" placeholder="Phase" autocomplete="off">-->
<!--        </div>-->

<!--        <div class="col-12 pt-2 text-center">-->
<!--          <label for="address">OR</label>-->
<!--&lt;!&ndash;          <label for="address">Dirección o WT#</label>&ndash;&gt;-->
<!--        </div>-->
<!--        </ng-container>-->
          <div class="col-12 pt-2 text-center">
          <label for="address">Scan WT Code</label>
<!--          <label for="address">Dirección o WT#</label>-->
        </div>
        <div class="col-12">
          <input class="form-control input" #input [(ngModel)]="WTScanner" (keyup.enter)="pullMaterial()"
                  placeholder="Scan QR or WT BarCode" autocomplete="off">
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-12">
          <ng-container *ngIf="!restrictUser">
            <button class="btn btn-primary mx-1 my-1" (click)="pullMaterial()">load</button>
          </ng-container>
          <ng-container *ngIf="restrictUser">
            <div>
              <span><b class="text-danger">**Note</b>: Please use the Scanner to load the House to Proceed.
                / Utilice el escáner para cargar la casa para continuar.<b class="text-danger">**</b></span>
            </div>
            <button class="btn btn-primary mx-1 my-1" (click)="pullMaterial()">Scan</button>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="card-body border-0 justify-content-center">
      <div class="row justify-content-center" id="wtsList">
        <ng-container *ngIf="workticket !== ''">
          <div class="text-center mt-3">
          <h5><b>WT #: {{soNum}}-{{wtNum}}</b></h5>
        </div>
        </ng-container>
        <div class="justify-content-center mt-1">
          <ng-container *ngFor="let item of itemList; let index = index">
            <div class="col-12 m-0 p-0">
              <div class="row justify-content-center m-0 p-0">
                <div class="col">
                  <input class="form-control input" #input [(ngModel)]="item.name"
                         (keyup.enter)="itemSelectedNew(item.name, index, workticket)"
                          typeaheadOptionField="ItemCode"
                         placeholder="ItemCode" autocomplete="off">
                  <!--[disabled]="restrictUser && index !== itemList.length-1"-->
                  <!--[typeahead]="filtereditems" (typeaheadOnSelect)="itemSelectedNew($event.item.ItemCode, index, workticket)"-->
                </div>
              </div>
            </div>
            <div class="col-12 m-0 p-0">
              <div class="row justify-content-center">
                <div class="d-flex justify-content-center mt-1">
                  <div class="p-2 text-center">
                    <button class="btn-primary btn" (click)=" item.newQtyShippedTotal = item.newQtyShippedTotal > 0 ? item.newQtyShippedTotal - 1 : 0">
                      <i class="fas fa-minus"></i></button>
                  </div>
                  <div class="p-2 mt-1 text-center">
                    <input class="form-control form-control-sm" id="countLine" style="width: 50px;" type="number"
                           [(ngModel)]="item.newQtyShippedTotal" (click)="$event.target.select()"/>
                  </div>
                  <div class="py-2 px-0 mt-2 text-center">
                    <b>QTY</b>
                  </div>
                  <div class="p-2 text-center">
                    <button class="btn-primary btn" (click)="item.newQtyShippedTotal = item.newQtyShippedTotal >= 0 ? item.newQtyShippedTotal + 1 : 0">
                      <i class="fas fa-plus"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="phaseLoaded">
<!--        <div class="col-12 m-0 p-0">-->
<!--          <div class="row justify-content-center">-->
<!--            <button class="btn btn-primary" [disabled]="isSubmitting || isLoading"-->
<!--                    (click)="AddBackorderList()"><i class="fas fa-plus"></i></button>-->
<!--          </div>-->
<!--        </div>-->
        <div class="col-12 m-0 p-0">
          <div class="row mt-2 justify-content-center">
            <button class="btn btn-primary" [disabled]="isLoading || isSubmitting" (click)="updateMaterial(workticket,true)">
<!--            <button class="btn btn-primary" [disabled]="isLoading || isSubmitting" (click)="AddAliases()">-->
              <span *ngIf="!isSubmitting">Submit</span>
              <span *ngIf="isSubmitting"><i class="fas fa-sync fa-spin"></i></span>
            </button>
          </div>
        </div>
        <ng-container *ngIf="itemListSubmitted.length > 0">
          <div class="col-12 m-0 p-0">
          <div class="row mt-2 justify-content-center">
            <h5><b>Submitted Items</b></h5>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="text-center">ItemCode</th>
                  <th class="text-center">QTY</th>
                </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let item of itemListSubmitted">
                <tr>
                  <td>{{item.name}}</td>
                  <td>{{item.newQtyShippedTotal}}</td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>


<ng-template #unknownCode>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-9 offset-1 text-center">
        <h2>Add Alias?</h2>
        <h5>Not matching itemCode or alias found. add Alias?</h5>
        <h3><b>Alias: {{ScannedUnknown}}</b></h3>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal(true)">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <div class="row p-2">
      <label><b>ItemCode:</b></label>
      <input type="text" class="form-control" id="unkownIC" [(ngModel)]="wantedICode" (keyup.enter)="createTempAlias()" placeholder="ItemCode" #unkownIC>
<!--      <input>-->
<!--      <input class="form-control input" #input [(ngModel)]="name"-->
<!--             (keyup.enter)="itemSelectedNew(item.name, index, workticket)"-->
<!--             typeaheadOptionField="ItemCode"-->
<!--             placeholder="ItemCode" autocomplete="off">-->
    </div>

    <div class="row p-2 justify-content-end">
      <button class="btn btn-primary mx-2" (click)="createTempAlias()">Save</button>
      <button class="btn btn-primary" (click)="closeModal(true)">Close</button>
    </div>


  </div>
</ng-template>
