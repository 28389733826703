<div  id="pageInfo">
    <div id="side-navbar" class="col-2 py-2">
      <div class="list-group list-group-flush">
          <a class="list-group-item list-group-item-action py-1 border rounded-top" (click)="settingPageSelected('')" [ngClass]="{'font-weight-bold bg-info text-light': homePageSelected}"> Settings </a>

        <div class="border-bottom text-center bg-dark text-light rounded-top" *ngIf="navShow('am') || navShow('am')"> Punch </div>
          <a
            *ngIf="navShow('am')"
            class="list-group-item list-group-item-action py-1 border"
            (click)="settingPageSelected('PunchAssignableTechs')"
            [ngClass]="{'font-weight-bold bg-info text-light': activePage('PunchAssignableTechs')}">
            Assignable Techs
          </a>

        <div class="border-bottom text-center bg-dark text-light rounded-top" *ngIf="navShow('acct_mgt') || navShow('acct')"> Sales Order </div>
          <a
            *ngIf="navShow('acct_mgt')"
            class="list-group-item list-group-item-action py-1 border"
            (click)="settingPageSelected('SubdivisionSetup')"
            [ngClass]="{'font-weight-bold bg-info text-light': activePage('SubdivisionSetup')}">
            Subdivision Edit
          </a>
          <a
            *ngIf="navShow('acct_mgt')"
            class="list-group-item list-group-item-action py-1 border"
            (click)="settingPageSelected('PlanSetup')"
            [ngClass]="{'font-weight-bold bg-info text-light': activePage('PlanSetup')}">
            Plan Setup
          </a>

      </div>
    </div>
    <div  id="main-content" class="col-10 py-2">
      <ng-container *ngIf="homePageSelected">
        <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
          <div class="col-md-5 p-lg-5 mx-auto my-5">
            <h1 class="display-4 font-weight-normal">Settings</h1>
            <p class="lead font-weight-normal">Click on a tab in the left column to select a settings page.</p>
          </div>
          <div class="product-device box-shadow d-none d-md-block"></div>
          <div class="product-device product-device-2 box-shadow d-none d-md-block"></div>
        </div>
      </ng-container>
      <ng-template appSettings></ng-template>
    </div>
  </div>
