<h1 mat-dialog-title class="text-center">
    <mat-icon class="text-warning" style="font-size: 100px; width: 100px; height: 100px;">save</mat-icon> <br>
    Save progress?
</h1>
<div mat-dialog-content class="text-center">
    Do you want to save 
    Invoice {{ data.invoice.invoiceNo }} for PO {{ data.invoice.purchaseOrderNo }}?
</div>
<div mat-dialog-actions>
    <div class="text-center w-100">
        <button mat-stroked-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" (click)="confirmSave()">Save</button>
    </div>
</div>
