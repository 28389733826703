<div class="container">
  <div class="card shadow-lg mt-3 pt-2 pb-2">
    <div class="text-center">
      <h2 class="pt-2 pb-2"> Upload Form </h2>
      <h5 class="mt-1"> Please Select a File to Upload </h5>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="form-group">
        <div class="col-sm-12 mb-3">
          <label for="address"> Address </label>
            <input class="form-control" type="text" [(ngModel)]="addressName" (click)="$event.target.select()" list="addressList" name="address" [disabled]="addressLoading" placeholder="Please Select an Address"/>
            <!-- * item List  -->
            <datalist id="addressList">
              <option *ngFor="let address of addressList" value="{{ address.name }}"></option>
            </datalist>
            <!-- * item List End  -->
        </div>
        <div class="col-sm-12">
          <label for="requestAttachments"> Attachments </label>
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="requestAttachments" (change)="fileChosen($event)">
              <label class="custom-file-label text-left" for="requestAttachments">Choose file</label>
            </div>
            <div class="col" *ngFor="let file of fileArray">
              <small> {{ file.name }}</small>
            </div>
        </div>
      </div>
    </div>
    <div class="pl-3 pr-3 mt-3" id="upload-footer">
      <div class="float-left">
        <!-- <button class="btn btn-secondary btn-lg pr-5 pl-5" (click)="slidePrevious()" *ngIf="!sliderOne"> Previous </button> -->
      </div>
      <div class="float-right">
        <button class="btn btn-primary btn-lg pr-5 pl-5 ml-2" (click)="fileUpload()" [disabled]="fileArray.length === 0 || addressName === ''"><span *ngIf="!fileLoading">Upload</span><span *ngIf="fileLoading"><i class="fas fa-sync fa-spin"></i></span></button>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
