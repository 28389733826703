import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import KitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import KitRegions from '../KitRegions';
import KitUtil from 'src/app/utils/KitUtil';
import KitPhases from '../KitPartPhases';
import QuoteStatuses from '../QuoteStatuses';
import QuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteDtoInterface';
import DateValidators from 'src/app/Validators/DateValidators';
import { QuoteQuestionRowAnswerChangeInterface } from '../QuoteQuestionRowComponent/QuoteQuestionRowComponent';
import ContextQuoteQuestionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteQuestionDtoInterface';
import { MatSnackBar } from '@angular/material/snack-bar';
import InputDecimalSanitizer from 'src/app/utils/InputDecimalSanitizer';
import ContextQuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteDtoInterface';

@Component({
  selector: 'app-quotesview',
  templateUrl: './QuotesViewComponent.html',
  styleUrls: ['./QuotesViewComponent.css', '../QuotingTheme.scss']
})
export class QuotesViewComponent extends KitUtil implements OnInit {

  loading: boolean = false;

  quoteControl = new FormControl('');
  regionControl = new FormControl('All');
  statusControl = new FormControl('All');
  builderControl = new FormControl('');
  quotePage = 0;
  quotePageSize = 5;
  quotePageHasMore = false;
  hasSearched = false;

  quotes: QuoteDtoInterface[] = [];

  newQuoteOpen = false;
  newQuoteLoading = false;
  newQuoteErrorMsg = null;

  newQuoteNameControl = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(30)]);
  newQuoteBuilderControl = new FormControl('', [Validators.required, Validators.maxLength(30)]);
  newQuoteSquareFootageControl = new FormControl(1000, [Validators.required, Validators.min(1), Validators.max(999999.99)]);
  newQuoteExpMin = (new Date()).toISOString();
  newQuoteExpControl = new FormControl('', [Validators.required, DateValidators.dateAfterValidator( (new Date()).toISOString(), this.newQuoteExpMin, {error: true} ) ]);
  newQuoteRegionControl = new FormControl('', [Validators.required, Validators.maxLength(30)]);
  newQuoteStatusControl = new FormControl('', [Validators.required, Validators.maxLength(30)]);
  newQuoteDescControl = new FormControl('', [Validators.maxLength(255)]);

  quoteStatuses = QuoteStatuses;
  regions = KitRegions;
  quoteBuilders: string[] = [];

  quoteQuestions: ContextQuoteQuestionDtoInterface[] = [];
  quoteQuestionsAnswers: (QuoteQuestionRowAnswerChangeInterface|null)[] = [];

  constructor(
    public api: SageApiService,
    private router: Router,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ){
    super();
  }

  ngOnInit() {
    this.api.pullReport(
      'quote-questions', 
      {
        matchCriteria: ['quote-questions'],
        exp: 60000
      },
      ).subscribe((data: ContextQuoteQuestionDtoInterface[])=>{
      this.quoteQuestions = data;
      for(let q of this.quoteQuestions){
        this.quoteQuestionsAnswers.push(null);
      }
    });
  }

  disableSearchControls(){
    this.quoteControl.disable();
    this.regionControl.disable();
    this.statusControl.disable();
    this.builderControl.disable();
  }

  enableSearchControls(){
    this.quoteControl.enable();
    this.regionControl.enable();
    this.statusControl.enable();
    this.builderControl.enable();
  }

  searchQuotes(page?: number) {
    this.hasSearched = true;
    if(page != undefined){
      this.quotePage = page;
    }else{
      this.quotePage = 0;
      this.quotePageHasMore = false;
    }

    if(!this.loading){
      this.loading = true;
      this.disableSearchControls();
      let qurl = `quotes-overview?size=${this.quotePageSize}&offset=${this.quotePage!=0?this.quotes.length:0}&Quote_Name=${this.quoteControl.value}`;
      if(this.regionControl.value != 'All'){
        qurl += `&Quote_Region=${this.regionControl.value}`;
      }
      if(this.statusControl.value != 'All'){
        qurl += `&Quote_Status=${this.statusControl.value}`;
      }
      if(this.builderControl.value != ''){
        qurl += `&Quote_Builder=${this.builderControl.value}`;
      }
      this.api.pullReport(qurl)
      .subscribe((data: QuoteDtoInterface[])=>{
        this.quotes = page != undefined? this.quotes.concat(data) : data;
        this.loading = false;
        this.enableSearchControls();
        this.quotePageHasMore = data.length == this.quotePageSize;
      },
      (err)=>{
        this.loading = false;
        this.enableSearchControls();
        console.log(err);
        this.snackBar.open('There was an error loading the quotes.', 'Dismiss', {
          duration: Infinity,
        });
      });
    }
  }

  deleteQuote(quote: QuoteDtoInterface, quoteIndex: number) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {title: `Really delete ${quote.Quote_Name}?`, message: `Are you sure you want to delete ${quote.Quote_Name}?`}
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result){
        this.api.deleteRequest(`quote/${quote.Quote_guid}`).subscribe(()=>{
          this.quotes.splice(quoteIndex, 1);
        }, 
        (err)=>{
          console.log(err);
        });
      }
    });
  }

  createQuote(){
    this.newQuoteLoading = true;
    this.api.postRequest('quote', {
      Quote_Name: this.newQuoteNameControl.value,
      Quote_Expiration: this.newQuoteExpControl.value.toISOString().slice(0, 10),
      Quote_SquareFootage: this.newQuoteSquareFootageControl.value,
      Quote_Builder: this.newQuoteBuilderControl.value,
      Quote_Region: this.newQuoteRegionControl.value,
      Quote_Status: this.newQuoteStatusControl.value,
      Quote_Desc: this.newQuoteDescControl.value,
    }).subscribe((dataRaw)=>{
      const data: QuoteDtoInterface = dataRaw as unknown as QuoteDtoInterface;

      // Now go through all the quoteQuestionsAnswers and add the kit or part to the quote
      const kitsToAdd: {Kit_guid: string; quantity: 1}[] = [];
      const partsToAdd: {Part_guid: string; quantity: 1, phase: string; cost: number;}[] = [];
      for(let qIndex = 0; qIndex < this.quoteQuestionsAnswers.length; qIndex++){
        const a: QuoteQuestionRowAnswerChangeInterface = this.quoteQuestionsAnswers[qIndex];
        if(a){
          if('Kit' in a.kitOrPart){
            kitsToAdd.push({Kit_guid: a.kitOrPart.Kit.Kit_guid, quantity: 1});
          }
          if('Part' in a.kitOrPart){
            partsToAdd.push({
              Part_guid: a.kitOrPart.Part.Part.Part_guid, 
              quantity: 1, 
              phase: a.kitOrPart.QuoteQuestionAnswer.QuoteQuestionAnswer_Phase, 
              cost: a.kitOrPart.Part.Part.Part_Cost
            });
          }
        }
      }

      if([...kitsToAdd, ...partsToAdd].length > 0){
        // Make a request to add-to-quote using kitsToAdd and partsToAdd
        this.api.postRequest(`add-to-quote/${data.Quote_guid}`, {
          kits: kitsToAdd,
          parts: partsToAdd,
          customKits: [],
        }).subscribe(()=>{
          // this.quotes.push(data);
          this.newQuoteOpen = false;
          this.router.navigate([`/quoting/quote/${data.Quote_guid}`]);
          this.newQuoteLoading = false;
        },
        (err)=>{
          // Show an error message
          const continueDespiteQuestionError = this.snackBar.open('Created the quote, but there was a problem processing your answers to the questions.', 'Go to quote anyway', {
            duration: Infinity,
          });
          continueDespiteQuestionError.onAction().subscribe(()=>{
            this.router.navigate([`/quoting/quote/${data.Quote_guid}`]);
          });
          console.log(err);
        });
      }else{
        this.newQuoteOpen = false;
        this.router.navigate([`/quoting/quote/${data.Quote_guid}`]);
        this.newQuoteLoading = false;
      }
    }, 
    (err)=>{
      console.log(err);
      if(err.error){
        this.newQuoteErrorMsg = err.error;
        if(err.error.includes("Quote_Name")){
          this.newQuoteNameControl.setErrors({unique: true});
        }
        if(err.error.includes("Quote_Expiration")){
          this.newQuoteExpControl.setErrors({unique: true});
        }
        if(err.error.includes("Quote_Desc")){
          this.newQuoteDescControl.setErrors({unique: true});
        }
        if(err.error.includes('Quote_SquareFootage')){
          this.newQuoteSquareFootageControl.setErrors({unique: true});
        }
        if(err.error.includes("Quote_Builder")){
          this.newQuoteBuilderControl.setErrors({unique: true});
        }
        if(err.error.includes("Quote_Region")){
          this.newQuoteRegionControl.setErrors({unique: true});
        }
        if(err.error.includes("Quote_Status")){
          this.newQuoteStatusControl.setErrors({unique: true});
        }
      }
      this.newQuoteLoading = false;
    });
  }

  openNewQuoteSheet() {
    this.newQuoteNameControl = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(30)]);
    this.newQuoteExpControl = new FormControl('', [Validators.required, DateValidators.dateAfterValidator( '', ((new Date())).toISOString(), {error: true} ) ]);
    this.newQuoteRegionControl = new FormControl('', [Validators.required, Validators.maxLength(30)]);
    this.newQuoteStatusControl = new FormControl('', [Validators.required, Validators.maxLength(30)]);
    this.newQuoteSquareFootageControl = new FormControl(1000, [Validators.required, Validators.min(1), Validators.max(999999.99)]);
    this.newQuoteBuilderControl = new FormControl('', [Validators.required, Validators.maxLength(30)]);
    this.newQuoteDescControl = new FormControl('', [Validators.maxLength(255)]);
    this.newQuoteErrorMsg = null;
    this.newQuoteOpen = true;
  }

  newQuoteValid() {
    // If any of the quoteQuestionsAnswers are null, return false;
    // for(let q of this.quoteQuestionsAnswers){
    //   if(q == null){
    //     return false;
    //   }
    // }
    return this.newQuoteNameControl.valid 
      && this.newQuoteExpControl.valid 
      && this.newQuoteDescControl.valid 
      && this.newQuoteStatusControl.valid 
      && this.newQuoteSquareFootageControl.valid
      && this.newQuoteBuilderControl.valid 
      && this.newQuoteLoading == false;
  }

  newQuoteAnswerCheckChanged(change: QuoteQuestionRowAnswerChangeInterface|null, qIndex: number){
    this.quoteQuestionsAnswers[qIndex] = change;
  }

  newQuoteSquareFootageInput(e: InputEvent){
    const val = InputDecimalSanitizer(e, 6, 2);
    this.newQuoteSquareFootageControl.setValue(val);
  }

  copyQuote(quote){
    const copyingSnackbar = this.snackBar.open('Copying quote...', '', {duration: Infinity});

    this.api.postRequest(`copy-quote/${quote.Quote_guid}`, {}).subscribe((data: ContextQuoteDtoInterface)=>{
      copyingSnackbar.dismiss();
      this.router.navigate([`/quoting/quote/${data.Quote.Quote_guid}`]);
    }, 
    (err)=>{
      console.log(err);
      copyingSnackbar.dismiss();
      this.snackBar.open('There was an error copying the quote.', 'Dismiss', {duration: Infinity});
    });
  }

}