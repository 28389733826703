<app-data-table 
  [rawData]="allParts" 
  [tableColumns]="[
    {col: 'PartCode', value: 'PartCode', title: 'PartCode', size: 'md'},
    {col: 'Quantity', value: 'Quantity', title: 'Quantity', size: 'sm'},
    {col: 'Cost', value: normalizeMoney, title: 'Cost', size: 'sm'},
    {col: 'TotalCost', value: normalizeMoney, title: 'TotalCost', size: 'sm'},
    {col: 'Phase', value: 'Phase', title: 'Phase', size: 'md'},
    {col: 'Kit', value: 'Kit', title: 'Kit', size: 'lg'},
    {col: 'KitParents', value: 'KitParents', title: 'KitParents', size: 'xl', includeColumnByDefault: false},
    {col: 'Desc', value: 'Desc', title: 'Desc', size: 'lg', includeColumnByDefault: false},
    {col: 'Tags', value: 'Tags', title: 'Tags', size: 'md'},
    {col: 'Location', value: 'Location', title: 'Location', size: 'md'},
  ]" 
  [inColumns]="
  [
    {col: 'PartCode',  title: 'PartCode'},
    {col: 'Kit', title: 'Kit'},
    {col: 'Location', title: 'Location'},
  ]"
  [mselColumns]="
  [
    {col: 'Phase', title: 'Phase'},
    {col: 'Tags', title: 'Tags'},
  ]
  "
  [includeColumnSelector]="true"
  [searchOnChange]="true"
  [accordianControls]="true"
  [includePdfDownload]="true"
  accordianScreenSize="smd"
  title="Slicer" 
></app-data-table>