import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import KitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitPartDtoInterface';
import PartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/PartDtoInterface';
import ContextKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitDtoInterface';
import ContextKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitPartDtoInterface';

import KitUtil, { getKitCost } from 'src/app/utils/KitUtil';
import { FormControl, Validators } from '@angular/forms';
import KitPartPhases from '../KitPartPhases';
import ContextPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextPartDtoInterface';
import KitOverviewDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitOverviewDtoInterface';

export interface KitPartToAddRowKitPartItem {
  item: ContextKitDtoInterface|PartDtoInterface;
  selected: boolean;
  quantity: number;
}

@Component({
  selector: 'app-kptarow',
  templateUrl: './KitPartToAddRowComponent.html',
  styleUrls: ['./KitPartToAddRowComponent.css', '../QuotingTheme.scss']
})
export class KitPartToAddRowComponent extends KitUtil implements OnInit, OnChanges {

  @Input() kitPart: KitOverviewDtoInterface | ContextPartDtoInterface;
  @Input() selected: boolean = false;
  @Input() quantity: number = 1;
  
  @Input() editable: boolean = false;

  kit: KitOverviewDtoInterface|null = null;
  part: ContextPartDtoInterface|null = null;

  quantityControl: FormControl = new FormControl(this.quantity, [Validators.min(1), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]);
  phaseControl: FormControl = new FormControl(KitPartPhases[0].valueOf(), [Validators.required]);
  cost = 0;

  kitPartPhases = KitPartPhases;
  
  @Output() selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() quantityChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() phaseChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public api: SageApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ){
    super();
  }

  ngOnInit() {
    this.setPartOrKit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setPartOrKit();
    this.generateCost();
    this.resetQuantityControl();
  }

  setPartOrKit(){
    if('Part' in this.kitPart){
      this.part = this.kitPart;
    }else{
      this.kit = this.kitPart;
    }
  }

  resetQuantityControl(){
    this.quantityControl = new FormControl(this.quantity, [Validators.min(1), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]);
  }

  generateCost() {
    const q = isNaN(this.quantityControl.value) || this.quantityControl.value==''? 
      0 
      : parseInt(this.quantityControl.value);
    if(this.part){
      this.cost = this.part.Part.Part_Cost * q;
    }
  }

  selectedChanged(newSelected: boolean) {
    this.selectedChange.emit(newSelected);
  }

  quantityControlInput(e: InputEvent){
    // Test if e.value is a number
    if(!/^[0-9]*$/.test((e.target as HTMLInputElement).value)){
      const newVal = (e.target as HTMLInputElement).value.replace(/\D/g, '').slice(0, 4);
      // First, get the cursor position in the input
      const cursorPosStart = (e.target as HTMLInputElement).selectionStart-1;
      const cursorPosEnd = (e.target as HTMLInputElement).selectionEnd-1;

      (e.target as HTMLInputElement).value = newVal;
      this.quantityControl.setValue(newVal);
      
      // Now, set the cursor position back to cursorPos
      (e.target as HTMLInputElement).setSelectionRange(cursorPosStart, cursorPosEnd);
      setTimeout(()=>{
        // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
        (e.target as HTMLInputElement).setSelectionRange(cursorPosStart, cursorPosEnd);
      }, 0);
    }else{
      this.quantityControl.setValue(this.quantityControl.value.slice(0, 4));
    }
    this.generateCost();
  }

  quantityControlBlur(e: FocusEvent){
    if(typeof this.quantityControl.value == 'string' && this.quantityControl.value.trim() == ''){
      this.quantityControl.setValue(1);
    }
    this.generateCost();
    this.quantityChange.emit(parseInt(this.quantityControl.value));
  }

  phaseControlChange(e){
    this.phaseChange.emit(e);
  }

}