import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ScreenSizeService } from "src/app/_services/ScreenSizeService/ScreenSizeService";
import ContextQuoteDtoInterface from "src/app/_services/sageApi/interfaces/pullReport/ContextQuoteDtoInterface";
import QuoteDtoInterface from "src/app/_services/sageApi/interfaces/pullReport/QuoteDtoInterface";
import { SageApiService } from "src/app/_services/sageApi/sageApi.service";
import { AddItemsOutputInterface } from "../KitOrPartPickerBotSheetComponent/KitOrPartPickerBotSheetComponent";
import ContextQuoteKitPartDtoInterface from "src/app/_services/sageApi/interfaces/pullReport/ContextQuoteKitPartDtoInterface";
import { Observable, Observer, Subject } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import QuoteDataEditableInterface from "../../../_services/QuoteEditableService/interfaces/QuoteDataEditableInterface";
import QuoteKitPartEditableInterface, {
  QuoteKitPartEditableDataInterface,
} from "../../../_services/QuoteEditableService/interfaces/QuoteKitPartEditableInterface";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModalComponent } from "../../Platform/confirm-modal/confirm-modal.component";
import ContextQuotePartDtoInterface from "src/app/_services/sageApi/interfaces/pullReport/ContextQuotePartInterface";
import QuotePartDtoInterface from "src/app/_services/sageApi/interfaces/pullReport/QuotePartDtoInterface";
import CustomQuoteKitToAddInterface from "src/app/_services/sageApi/interfaces/pullReport/CustomQuoteKitToAddInterface";
import ContextPartDtoInterface from "src/app/_services/sageApi/interfaces/pullReport/ContextPartDtoInterface";
import { QuoteKitPartRowOnSaveType } from "../QuoteKitPartRowComponent/QuoteKitPartRowComponent";
import QuoteExclusionEditableInterface from "../../../_services/QuoteEditableService/interfaces/QuoteExclusionEditableInterface";
import QuoteExclusionDtoInterface from "src/app/_services/sageApi/interfaces/pullReport/QuoteExclusionDtoInterface";
import QuoteNoteEditableInterface from "../../../_services/QuoteEditableService/interfaces/QuoteNoteEditableInterface";
import QuoteNoteDtoInterface from "src/app/_services/sageApi/interfaces/pullReport/QuoteNoteDtoInterface";
import QuoteBillingTermEditableInterface from "../../../_services/QuoteEditableService/interfaces/QuoteBillingTermEditableInterface";
import QuoteBillingTermDtoInterface from "src/app/_services/sageApi/interfaces/pullReport/QuoteBillingTermDtoInterface";
import QuoteInclusionEditableInterface from "../../../_services/QuoteEditableService/interfaces/QuoteInclusionEditableInterface";
import QuoteInclusionDtoInterface from "src/app/_services/sageApi/interfaces/pullReport/QuoteInclusionDtoInterface";
import QuoteOptionDtoInterface from "src/app/_services/sageApi/interfaces/pullReport/QuoteOptionDtoInterface";
import QuoteOptionEditableInterface, {
  QuoteOptionKitPartEditableInterface,
} from "../../../_services/QuoteEditableService/interfaces/QuoteOptionEditableInterface";
import ContextQuoteOptionDtoInterface from "src/app/_services/sageApi/interfaces/pullReport/ContextQuoteOptionDtoInterface";
import ContextQuoteOptionKitPartDtoInterface from "src/app/_services/sageApi/interfaces/pullReport/ContextQuoteOptionKitPartDtoInterface";
import { QuoteEditableService } from "src/app/_services/QuoteEditableService/QuoteEditableService";
import { share } from "rxjs/operators";

interface PatchQuoteInterface {
  Quote_Name?: string;
  Quote_Desc?: string;
  Quote_Status?: string;
  Quote_Builder?: string;
  Quote_Region?: string;
  Quote_Expiration?: string;
  Quote_MaterialMargin?: number;
  Quote_LaborMargin?: number;
  Quote_GibsonMargin?: number;
  Quote_TaxMargin?: number;
}

interface PartBreakdownItemGroupInterface {
  name: string;
  partCost: number;
  totalCost: number;
  quantity: number;
  items: PartBreakdownItemInterface[];
  desc: string;
}

interface PartBreakdownItemInterface {
  data: QuoteKitPartEditableDataInterface;
  cost: number;
  quantity: number;
  parents: string[];
}

@Component({
  selector: "app-quoteview",
  templateUrl: "./QuoteViewComponent.html",
  styleUrls: ["./QuoteViewComponent.css", "../QuotingTheme.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteViewComponent implements OnInit {
  quoteGuid = this.activatedRoute.snapshot.params.quoteGuid;
  appbarSaving = false;

  loadingPDF: boolean = false;

  loading: string[] = [];

  breadcrumb: number = 0; // Kind of like the pages of the breadcrumb

  sidepanelOpen = false;

  addSheetOpen: boolean = false;
  addBotSheetTarget: {
    parentName: string;
    parentGuid?: string;
    data: QuoteKitPartEditableInterface | QuoteDataEditableInterface | null;
  } | null = null;

  customKitSheetOpen: boolean = false;
  customKitTarget:
    | QuoteKitPartEditableInterface
    | QuoteDataEditableInterface
    | null = null;

  customPartSheetOpen: boolean = false;
  customPartTarget:
    | QuoteKitPartEditableInterface
    | QuoteDataEditableInterface
    | null = null;

  editPartBotSheetOpen: boolean = false;
  isDeleteModalOpen: boolean = false;

  constructor(
    public qe: QuoteEditableService,
    public api: SageApiService,
    public screenSize: ScreenSizeService,
    public activatedRoute: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.activatedRoute.snapshot.queryParams;

    // Add keydown listener to document that listens for the delete key to delete selected QuoteKitParts, or escape to unselect all
    document.addEventListener("keydown", (e) => {
      if (this.breadcrumb == 0) {
        if (
          e.key == "Delete" &&
          this.editPartBotSheetOpen == false &&
          this.isDeleteModalOpen == false &&
          this.customKitSheetOpen == false &&
          this.customPartSheetOpen == false &&
          this.addSheetOpen == false
        ) {
          if (this.qe.getSelectedChildren().length > 0) {
            this.loadingUntilComplete(this.qe.destroySelectedChildren());
          }
        }
        if (
          e.key == "Escape" &&
          this.editPartBotSheetOpen == false &&
          this.isDeleteModalOpen == false &&
          this.customKitSheetOpen == false &&
          this.customPartSheetOpen == false &&
          this.addSheetOpen == false
        ) {
          this.qe.deselectChildren();
        }
        if (
          e.key.toLowerCase() == "e" &&
          this.editPartBotSheetOpen == false &&
          this.isDeleteModalOpen == false &&
          this.customKitSheetOpen == false &&
          this.customPartSheetOpen == false &&
          this.addSheetOpen == false &&
          this.qe.getSelectedChildren().length == 1
        ) {
          this.openEditPartBotSheet();
          this.cdr.detectChanges();
        }
      }
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((map) => {
      this.breadcrumb = parseInt(map.tab);
      return map;
    });

    this.qe.initQuote({ quoteGuid: this.quoteGuid });

    this.rerenderOnObservable(this.qe.updated);
  }

  rerenderOnObservable(observable: Observable<any>) {
    observable.subscribe(() => {
      this.cdr.detectChanges();
    });
    return observable;
  }

  tm() {
    return new Date().toISOString();
  }

  loadingUntilComplete(observable: Observable<any>) {
    const guid = `${Math.random()
      .toString(36)
      .substring(7)}-${new Date().getTime()}`;
    observable.subscribe({
      next: (rt) => {
        if (rt == "loading") {
          this.loading.push(guid);
          this.cdr.detectChanges();
        }
      },
      error: () => {},
      complete: () => {
        const loadingIndex = this.loading.indexOf(guid);
        if (loadingIndex > -1) {
          this.loading.splice(loadingIndex, 1);
        }
        this.cdr.detectChanges();
      },
    });
    return observable;
  }

  isLoading() {
    return this.loading.length > 0;
  }

  appbarSavingUntilComplete(observable: Observable<any>) {
    this.appbarSaving = true;
    observable.subscribe(
      () => {
        this.appbarSaving = false;
        this.cdr.detectChanges();
      },
      () => {
        this.appbarSaving = false;
        this.cdr.detectChanges();
      }
    );
    return observable;
  }

  rowSaved(saved: QuoteKitPartRowOnSaveType) {
    if ("QuotePart_Cost" in saved) {
      // Go through all the QuoteParts in data.children and their children, and change the QuotePart_Cost to saved.QuotePart_Cost
      const changeCost = (children: QuoteKitPartEditableInterface[]) => {
        for (let qkp of children) {
          if (qkp.data.QuotePart) {
            if (
              qkp.data.QuotePart.QuotePart.QuotePart_guid ==
              saved.QuotePart_guid
            ) {
              qkp.data.QuotePart.QuotePart = saved;
              qkp.costControl.setValue(saved.QuotePart_Cost);
            }
          }
          if (qkp.data.QuoteKit) {
            changeCost(qkp.children);
          }
        }
      };
      changeCost(this.qe.quote.children);
    }
    // this.shouldUpdate.next();
  }

  openAddPartsSheet(target?: QuoteKitPartEditableInterface) {
    if (target) {
      this.addBotSheetTarget = {
        parentGuid: target.data.QuoteKitPart.QuoteKitPart_guid,
        parentName:
          target.data?.QuoteKit?.QuoteKit?.QuoteKit_Name ||
          target.data?.QuotePart?.QuotePart?.QuotePart_Code,
        data: target,
      };
    } else {
      this.addBotSheetTarget = {
        parentName: this.qe.quote.data.Quote.Quote_Name,
        data: this.qe.quote,
      };
    }
    this.addSheetOpen = true;
  }

  closeAddPartsSheet() {
    this.addSheetOpen = false;
  }

  saveQuoteKitPartChanges() {}

  openEditPartBotSheet() {
    this.editPartBotSheetOpen = true;
  }

  openAddCustomKitSheet(
    newTarget: QuoteKitPartEditableInterface | QuoteDataEditableInterface
  ) {
    this.customKitSheetOpen = true;
    this.customKitTarget = newTarget;
  }

  closeCustomKitSheet() {
    this.customKitSheetOpen = false;
    this.customKitTarget = null;
  }

  openAddCustomPartSheet(
    newTarget: QuoteKitPartEditableInterface | QuoteDataEditableInterface
  ) {
    this.customPartSheetOpen = true;
    this.customPartTarget = newTarget;
  }

  closeCustomPartSheet() {
    this.customPartSheetOpen = false;
    this.customPartTarget = null;
  }

  navigateToPDF() {
    this.loadingPDF = true;

    const quotePreviewPage = '/quoting/preview-quote-pdf/' + this.qe.quote.data.Quote.Quote_guid;
   // console.log(this.loadingPDF)
    let obj = { QuoteGuid: this.quoteGuid, finalized: false };
    console.log(obj);
    this.api.postBlob("preview-quote-pdf", obj).subscribe(
      (response) => {
        var blob = new Blob([response], { type: "application/pdf" });
        var file = window.URL.createObjectURL(blob);
        this.loadingPDF = false;
        window.open(file);
      },
      (e) => {
        console.log(e.error);
        this.snackBar.open("Error opening quote PDF.", "Close");
        this.loadingPDF = false;
        this.cdr.detectChanges();

        throw Error(e);
      },
      () => {
        this.loadingPDF = false;
        console.log(this.loadingPDF)
        this.cdr.detectChanges();
      }
    );
  }

  changeTab(newTab: number) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: newTab },
      queryParamsHandling: "merge",
    });
  }

  trackByKitPartGuid(index: number, item: QuotePartDtoInterface) {
    return item.QuotePart_guid;
  }

  trackByQuoteNoteGuid(index: number, item: QuoteNoteDtoInterface) {
    return item.QuoteNote_guid;
  }

  trackByQuoteBillingTermGuid(
    index: number,
    item: QuoteBillingTermDtoInterface
  ) {
    return item.QuoteBillingTerm_guid;
  }

  trackByField<ObjInterface>(field: keyof ObjInterface) {
    return (index: number, item: ObjInterface) => item[field];
  }
}
