<div class="container pb-5">
  <div class="card my-5 shadow-lg text-center" id="noprint">
    <h1>Pull Ticket</h1>
    <ng-container>
      <div class="col-12" id="noprint">
        <mat-form-field class="col-7">
          <mat-label>
            <span *ngIf="loadjobs"><i class="fas fa-sync fa-spin"></i></span>
            <span *ngIf="!loadjobs">
              Job
            </span>
          </mat-label>
          <input matInput [(ngModel)]="job.jobInformation" type="text" [typeahead]="jobCollection"
            typeaheadOptionField="jobInformation" [typeaheadMinLength]="0" [typeaheadOptionsInScrollableView]="15"
            autocomplete="on" name="jobInformation" [disabled]="loadjobs || jobSelected"
            (typeaheadOnSelect)="setJobNumber(job)">
        </mat-form-field>
        <mat-form-field class="col-5">
          <mat-label>Technician</mat-label>
          <mat-select>
            <mat-option *ngFor="let tech of technicians" [value]="tech.name" (click)="setTech(tech)">
              {{ tech.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container id="noprint" *ngIf="techName != '' && jobNumber != ''" class="col-12">
      <div id="noprint" *ngIf="itemList.length == 0">
        <i class="fas fa-sync fa-spin"></i>
      </div>
      <div class="justify-content-center col-12" id="noprint" *ngIf="itemList.length >0">
        <table class="table table-striped col-12">
          <thead>
            <th>Item Code</th>
            <th>Item Name</th>
            <th>On Hand</th>
            <th>Amount Requested</th>
            <th></th>
          </thead>
          <tr *ngFor="let itemToTransfer of itemsToTransfer">
            <td>{{ itemToTransfer.ItemCode }}</td>
            <td>{{ itemToTransfer.ItemCodeDesc }}</td>
            <td>{{ itemToTransfer.QuantityOnHand }}</td>
            <td>{{ itemToTransfer.ItemQuantity }}</td>
            <td>
              <button mat-raised-button color="danger" (click)="DeleteItem(itemToTransfer)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </table>
      </div>
      <ng-container *ngIf="itemList.length >0">
        <div class="align-content-center" id="noprint">
          <ul class="list-group list-group-horizontal col-12">
            <li class="list-group-item col-6 border-0 px-1 pb-0 pt-2 text-sm font-weight-bold">
              <input class="form-control" [(ngModel)]="item.ItemName" type="text" placeholder="Item" aria-label="Item"
                [typeaheadMinLength]="0" [typeahead]="itemList" typeaheadOptionField="name" typeaheadOptionsLimit="150"
                [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5" autocomplete="off" name="ItemName"
                (ngModelChange)="amountOnHand(item)" />
            </li>
            <li class="list-group-item col-2 p-6 border-0 text-sm font-weight-bold text-align-center">
              {{ onHand }}
            </li>

            <li class="list-group-item col-2 border-0 px-1 pb-0 pt-2 text-sm font-weight-bold">
              <input class="form-control" type="number" placeholder="Quantity" aria-label="Quantity"
                [(ngModel)]="item.ItemQuantity" name="ItemQuantity" required />
            </li>
            <li class="list-group-item col-2 border-0 px-1 pb-0 pt-2">
              <button type="submit" mat-raised-button color="danger" [disabled]="!itemReady()" (click)="pushItem(item)">
                Add
              </button>
            </li>
          </ul>
        </div>
      </ng-container>
      <br />

      <div id="buttonRequests" class="p-2">
        <button mat-raised-button color="danger" [disabled]="!printReady() || sending" (click)="printPage()">
          Request and Print
        </button>
        <button mat-raised-button color="danger" [disabled]="!printReady() || sending == true"
        (click)="sendInformation()">
        <span *ngIf="sending"><i class="fas fa-sync fa-spin"></i></span>
        <span *ngIf="!sending">Request</span>
      </button>
    </div>
    </ng-container>
  </div>

  <div id="print-view-page" class="m-0 p-0">
    <div class="container-fluid">
      <div class="row justify-content-center" [id]="'pdfData'">
        <div class="row justify-content-center" [ngStyle]="{ 'min-width': '100%', 'max-width': '100%' }">
          <h4>If needing to add additional parts: from the home screen of the scanner please scan the QR code provided
            to go the
            correct website.
            <br>
            If already in the web app: go to Service | Warranty -> Material OTC then scan barcode to populate the job
            ticket.
          </h4>
          <br>
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>
                  <img [ngStyle]="{ height: '100px' }" src="assets/gibsonLogo.PNG" alt="image" />
                </td>
                <td>
                  <div class="row justify-content-center mt-1 p-0">
                    <qr-code value="{{baseUrl}}/Service/MaterialOTC?jobnumber={{ jobNumber }}"></qr-code>
                  </div>
                  <div class="row justify-content-center mt-1 p-0">
                    <p>
                      <b>Job #: {{ jobNumber }}</b>
                    </p>
                  </div>
                </td>
                <td>
                  <div class="row justify-content-center m-0 p-0">
                    <h4><b>{{businessType}} Pull Sheet</b></h4>
                  </div>
                  <ng-container *ngIf="itemsToTransfer !== undefined">
                    <div class="row justify-content-center mt-1 p-0">
                      <h5>
                        <b>{{ address }}</b>
                      </h5>
                    </div>
                    <div class="row justify-content-center mt-1 p-0">
                      <p>
                        <b>Technician:</b>
                        {{ techName }}
                      </p>
                    </div>
                    <div class="row justify-content-center mt-1 p-0">
                      <p>
                        <b>Print Date:</b>{{ pdfTodayDate }}
                        <br>
                        <b>Scheduled Date:</b> {{ scheduledDate}}
                      </p>
                    </div>
                  </ng-container>
                </td>
                <td>
                  <div class="row justify-content-center m-1 p-0" [ngStyle]="{'width': '80%'}">
                    <h3>Job #: {{jobNumber}}</h3>
                    <br>
                    <ngx-barcode6 [bc-value]="jobNumber" [bc-display-value]="false" [bc-format]="'CODE128'"
                      [bc-width]="1" [bc-height]="40"></ngx-barcode6>
                  </div>
                </td>
              </tr>
            </thead>
            <tr class="table-primary">
              <th>Item</th>
              <th>Barcode</th>
              <th>Description</th>
              <th>Quantity</th>
            </tr>
            <tr *ngFor="let itemgroup of itemsToTransfer; let i = index">
              <ng-container>
                <th style="width: 20%">{{ itemgroup.ItemCode }}</th>
                <td style="width: 20%">
                  <ngx-barcode6 [bc-value]="itemgroup.ItemCode" [bc-display-value]="false" [bc-format]="'CODE128'"
                    [bc-width]="1" [bc-height]="40"></ngx-barcode6>
                </td>
                <td style="width: 50%">
                  {{ itemgroup.ItemCodeDesc }}
                </td>
                <td style="width: 20%">{{ itemgroup.ItemQuantity }}</td>
              </ng-container>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
