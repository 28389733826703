import {
  Component,
  Input,
  OnInit,
  HostListener,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import {concat, Subscription} from 'rxjs';
import {AlertifyService} from '../../../_services/alertify/alertify.service';
import {SageApiService} from '../../../_services/sageApi/sageApi.service';
import {AuthService} from '../../../_services/auth/auth.service';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead/typeahead-match.class';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {DatePipe} from '@angular/common';
import {tick} from '@angular/core/testing';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {any} from 'codelyzer/util/function';
import {tryCatch} from 'rxjs/internal-compatibility';
import ScheduleTicketInterface from '../interfaces/ScheduleTicketInterface';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-assign-schedule-ticket-item',
  templateUrl: './assign-schedule-ticket-item.component.html',
  styleUrls: ['./assign-schedule-ticket-item.component.css']
})
export class AssignScheduleTicketItemComponent implements OnInit, AfterViewInit {
  
  @Input() tickets: ScheduleTicketInterface;
  @Input() filterCounterTickets: boolean;
  @Input() ticketColor: string;
  @Input() techName: string;
  @Input() phaseByCrew: string;
  @Output() onReqModalOpen: EventEmitter<void> = new EventEmitter();

  // tslint:disable-next-line:max-line-length
  constructor(private sageApi: SageApiService, private authService: AuthService, private alertify: AlertifyService,
              private modalService: BsModalService, private cdr: ChangeDetectorRef, private datepipe: DatePipe) {
  }

  requestModalOpenHandler(): void {
    this.onReqModalOpen.emit();
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    // this.cdr.detectChanges();
  }
  
}
