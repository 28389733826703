import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import KitPartPhases from '../KitPartPhases';
import QuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuotePartDtoInterface';
import GetColorFromString from 'src/app/utils/GetColorFromString';
import { QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';
import QuoteOptionEditableInterface, { QuoteOptionKitPartEditableInterface } from 'src/app/_services/QuoteEditableService/interfaces/QuoteOptionEditableInterface';
import QuoteOptionKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOptionKitDtoInterface';
import ContextQuoteOptionKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteOptionKitDtoInterface';
import QuoteOptionKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOptionKitPartDtoInterface';
import { BaseModalService } from 'src/app/_services/BaseModalService/BaseModalService';
import { FormArray, FormControl } from '@angular/forms';
import ContextQuotePartTagDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuotePartTagDtoInterface';

export type QuoteOptionKitPartRowOnSaveType = 
  QuoteOptionKitPartDtoInterface
  | QuotePartDtoInterface
  | ContextQuoteOptionKitDtoInterface
  | QuoteOptionKitDtoInterface;

@Component({
  selector: 'app-qoptionkprow',
  templateUrl: './QuoteOptionKitPartRowComponent.html',
  styleUrls: ['./QuoteOptionKitPartRowComponent.css', '../QuotingTheme.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteOptionKitPartRowComponent implements OnInit, OnChanges {

  @Input() qokp: QuoteOptionKitPartEditableInterface;
  @Input() qop: QuoteOptionEditableInterface;
  
  cost = 0;
  costControlVisible: boolean = false;

  totalParts = 0;

  kitPartPhases = KitPartPhases;

  isHovered = false;
  isCostHovered = false;

  isLocationPickerOpen = false;
  tagPickerOpen = false;
  
  @Output() onSave: EventEmitter<QuoteOptionKitPartRowOnSaveType> = new EventEmitter<QuoteOptionKitPartRowOnSaveType>();
  @Output() onOpenAddSheet: EventEmitter<QuoteOptionKitPartEditableInterface> = new EventEmitter<QuoteOptionKitPartEditableInterface>();
  @Output() onCustomKitSheet: EventEmitter<QuoteOptionKitPartEditableInterface> = new EventEmitter<QuoteOptionKitPartEditableInterface>();
  @Output() onCustomPartSheet: EventEmitter<QuoteOptionKitPartEditableInterface> = new EventEmitter<QuoteOptionKitPartEditableInterface>();
  
  constructor(
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    public api: SageApiService,
    public qe: QuoteEditableService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private modals: BaseModalService,
  ){}

  ngOnInit() {
    this.qe.updated.subscribe(()=>{
      this.generateCost();
      this.generateTotalParts();
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetControls();
    this.generateCost();
    this.generateTotalParts();
    this.cdr.detectChanges();
  }

  resetControls(){
    this.qokp.quantityControl.setValue(this.qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity);
    this.qokp.buildLocationControl.setValue(this.qokp.data.BuildLocation?.BuildLocation_Code);
    
    if(this.qokp?.data?.QuoteOptionPart?.QuotePart != null){
      this.qokp.costControl.setValue(this.qokp.data.QuoteOptionPart?.QuotePart?.QuotePart_Cost);
      this.qokp.phaseControl.setValue(this.qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Phase);
      this.qokp.tagsControl = new FormArray(this.qokp.data.QuoteOptionPart.QuotePartTags.map(tag=>new FormControl(tag.Tag.Tag_Name)));
    }else if(this.qokp?.data?.QuoteOptionKit != null){
      this.qokp.nameControl.setValue(this.qokp.data.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Name);
      this.qokp.descControl.setValue(this.qokp.data.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Desc);
    }
    this.cdr.detectChanges();
  }

  generateCost() {
    if(this.qokp.data.QuoteOptionKit){
      this.cost = this.qe.getRawOptionCost({quoteOption: this.qop, quoteOptionKitPart: this.qokp}) * this.qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
    }else{
      this.cost = this.qokp.data.QuoteOptionPart.QuotePart.QuotePart_Cost * this.qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
    }
  }

  generateTotalParts() {
    if(this.qokp.data.QuoteOptionKit){
      this.totalParts = this.qe.getOptionTotalParts({quoteOption: this.qop, quoteOptionKitPart: this.qokp});
    }else{
      this.totalParts = this.qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
    }
  }

  getTotalParts(children: QuoteOptionKitPartEditableInterface[]){
    // Loop through data.children, and their children, adding all the quantities of the QuoteParts
    let totalParts = 0;
    for(let qokp of children){
      if(qokp.data.QuoteOptionPart){
        totalParts += qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
      }
      if(qokp.data.QuoteOptionKit){
        totalParts += this.getTotalParts(qokp.children) * qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
      }
    }
    return totalParts;
  }

  getSubkits(){
    return this.qokp.children.filter((child)=>child.data.QuoteOptionKit != null);
  }

  getParts(){
    return this.qokp.children.filter((child)=>child.data.QuoteOptionPart != null);
  }

  selectedChanged(newSelected: boolean) {
    this.qokp.selected.setValue(newSelected);
  }

  quantityControlInput(e: InputEvent){
    // Test if e.value is a number
    if(!/^[0-9]*$/.test((e.target as HTMLInputElement).value)){
      const newValue = (e.target as HTMLInputElement).value.replace(/\D/g, '').slice(0, 4);
      // First, get the cursor position in the input
      const cursorPosStart = (e.target as HTMLInputElement).selectionStart-1;
      const cursorPosEnd = (e.target as HTMLInputElement).selectionEnd-1;

      (e.target as HTMLInputElement).value = newValue;
      this.qokp.quantityControl.setValue(newValue);
      // Now, set the cursor position back to cursorPos
      (e.target as HTMLInputElement).setSelectionRange(cursorPosStart, cursorPosEnd);
      setTimeout(()=>{
        // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
        (e.target as HTMLInputElement).setSelectionRange(cursorPosStart, cursorPosEnd);
      }, 0);
    }else{
      this.qokp.quantityControl.setValue(this.qokp.quantityControl.value.slice(0, 4));
    }
  }

  quoteKitNameInput(e: InputEvent){
    this.qokp.nameControl.setValue((e.target as HTMLInputElement).value);
  }

  costControlInput(e: InputEvent){
    const wholeValueMax = 8;
    const decimalValueMax = 4;

    // test is e.value has more than one period. If it does, remove all but the first one
    if((e.target as HTMLInputElement).value.split('.').length > 2){
      const splitByPeriod = (e.target as HTMLInputElement).value.split('.');
      const firstTwo = splitByPeriod.slice(0, 2).join('.');
      const newValue = [firstTwo, ...splitByPeriod.slice(2)].join('');

      (e.target as HTMLInputElement).value = newValue;
      this.qokp.costControl.setValue(newValue);

      const cursorPosStart = newValue.split('.')[0].length+1;
      const cursorPosEnd = newValue.split('.')[0].length+1;
      (e.target as HTMLInputElement).setSelectionRange(cursorPosStart, cursorPosEnd);
      setTimeout(()=>{
        // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
        (e.target as HTMLInputElement).setSelectionRange(cursorPosStart, cursorPosEnd);
      }, 0);
    }

    // Test if e.value is a number
    if(!/^\d*\.?\d*$/.test((e.target as HTMLInputElement).value)){
      let arrVal = (e.target as HTMLInputElement).value.replace(/\D/g, '').split('.');
      arrVal[0] = arrVal[0].slice(0, wholeValueMax);
      if(arrVal.length >= 2){
        arrVal[1] = arrVal[1].slice(0, decimalValueMax);
      }
      const newValue = arrVal.join('.');
      // First, get the cursor position in the input
      const cursorPosStart = (e.target as HTMLInputElement).selectionStart-1;
      const cursorPosEnd = (e.target as HTMLInputElement).selectionEnd-1;

      (e.target as HTMLInputElement).value = newValue;
      this.qokp.costControl.setValue(newValue);
      // Now, set the cursor position back to cursorPos
      (e.target as HTMLInputElement).setSelectionRange(cursorPosStart, cursorPosEnd);
      setTimeout(()=>{
        // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
        (e.target as HTMLInputElement).setSelectionRange(cursorPosStart, cursorPosEnd);
      }, 0);
    }else{
      let arrVal = this.qokp.costControl.value.split('.');
      arrVal[0] = arrVal[0].slice(0, wholeValueMax);
      if(arrVal.length >= 2){
        arrVal[1] = arrVal[1].slice(0, decimalValueMax);
      }
      const newValue = arrVal.join('.');
      this.qokp.costControl.setValue(newValue);
    }
  }
  
  costControlBlur(e){
    // If the costControl is empty, set it back to QuotePart_QuotePart_Cost
    if(`${this.qokp.costControl.value}`.trim() == ''){
      this.generateCost();
      this.generateTotalParts();
      this.qokp.costControl.setValue(this.qokp.data.QuoteOptionPart.QuotePart.QuotePart_Cost);
    }

    // Set the costControl value to the parseFloat of the value
    this.qokp.costControl.setValue(parseFloat(this.qokp.costControl.value));

    this.costControlVisible = false;
  }

  saveChanges() {

    // if the cost control was changed, make a patch request for that too
    if(this.qokp.costControl.value != this.qokp.data?.QuoteOptionPart?.QuotePart?.QuotePart_Cost && this.qokp.data?.QuoteOptionPart != null){

      this.qe.changeQuotePart({
        quotePart: this.qokp
      }).subscribe((data: QuotePartDtoInterface)=>{
        this.qokp.data.QuoteOptionPart.QuotePart = data;
        this.resetControls();
        this.generateCost();
        this.generateTotalParts();
        this.onSave.emit(data);
        this.cdr.detectChanges();
      }, (error)=>{
        console.log(error);
        this.cdr.detectChanges();
        this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
      });
    }

    // If the tagsControl was changed, make a put request for that
    if(this.tagsControlsChanged()){
      this.qe.putQuotePartTags({
        quoteKitPart: this.qokp
      }).subscribe((data: ContextQuotePartTagDtoInterface[])=>{
        this.resetControls();
        this.cdr.detectChanges();
      }, (error)=>{
        console.log(error);
        this.cdr.detectChanges();
        this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
      })
    }

    // if the nameControl was changed, make a patch request for that too
    if(this.qokp.data?.QuoteOptionKit != null && this.qokp.data?.QuoteOptionKit?.Kit?.Kit_guid != null &&  this.qokp.nameControl.value != this.qokp.data?.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Name){
      this.qe.changeQuoteOptionKit({
        quoteOptionKitPart: this.qokp
      }).subscribe((data)=>{
        this.resetControls();
        this.generateCost();
        this.generateTotalParts();
        this.onSave.emit(data);
        this.cdr.detectChanges();
      }, (error)=>{
        console.log(error);
        this.cdr.detectChanges();
        this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
      });
    }

    // if the quantityControl or buildLocationControl was changed, make a patch request for that too
    if(
      this.qokp.data?.QuoteOptionKitPart != null 
      && this.qokp.data?.QuoteOptionKitPart?.QuoteOptionKitPart_guid != null 
      && (
        this.qokp.quantityControl.value != this.qokp.data?.QuoteOptionKitPart?.QuoteOptionKitPart_Quantity
        || this.qokp.buildLocationControl.value != this.qokp.data?.BuildLocation?.BuildLocation_Code
      )
    ){
      this.qe.changeQuoteOptionKitPart({
        quoteOptionKitPart: this.qokp
      }).subscribe((data)=>{
        this.resetControls();
        this.generateCost();
        this.generateTotalParts();
        this.onSave.emit(data);
        this.cdr.detectChanges();
      }, (error)=>{
        console.log(error);
        this.cdr.detectChanges();
        this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
      });
    }

    this.generateCost();
    this.generateTotalParts();
  }

  cancelChanges(){
    this.resetControls();
  }

  mOver(e: MouseEvent){
    // Needed so we dont tell the parent to add the hover class to it
    e.preventDefault();
    e.stopPropagation();
    this.isHovered = true;
  }

  mOut(e: MouseEvent){
    // Needed so we dont tell the parent to add the hover class to it
    e.preventDefault();
    e.stopPropagation();
    this.isHovered = false;
  }

  getTagColor(tagName: string){
    if(tagName){
      return GetColorFromString(tagName);
    }
    return '#fff';
  }

  getBuildLocations(){
    return this.qe.getBuildLocations().map(bl=>bl.BuildLocation_Code);
  }

  buildLocationControlChange(e){
    this.qokp?.buildLocationControl.setValue(e.value); 
    this.isLocationPickerOpen=false; 
    this.cdr.detectChanges();
  }

  tagPickerControlChange(e){
    this.qokp.tagsControl.push(new FormControl(e.value));
    this.tagPickerOpen=false;
    this.cdr.detectChanges();
  }

  getAvailableTags(){
    const currTags = this.qokp.tagsControl.controls.map(t=>t.value);
    return this.qe.getTags().filter(tag=>currTags.findIndex(ctag=>ctag==tag.Tag_Name) == -1).map(t=>t.Tag_Name);
  }

  tagsControlsChanged(){
    return this.qokp.tagsControl.controls.map(t=>t.value).join(',') != this.qokp.data.QuoteOptionPart.QuotePartTags.map(t=>t.Tag.Tag_Name).join(',');
  }

  removeTag(tag: string){
    this.modals.confirm(`Remove ${tag}?`, `Really Remove ${tag} from ${this.qokp.data.QuoteOptionPart.QuotePart.QuotePart_Code}?`).subscribe((result: boolean) => {
      if(result){
        const index = this.qokp.tagsControl.controls.findIndex(t=>t.value==tag);
        this.qokp.tagsControl.removeAt(index);
        this.cdr.detectChanges();
      }
    });
  }
}