<div class="container pb-5">
  <div class="card border-0 my-5 shadow-lg">
    <ng-container *ngIf="!receiptSelected">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">{{ receiptOfInvoicesLoading ? 'Receipt Of Invoices Loading...' : 'Receipt Of Invoices with Discrepancies' }}</h5>
        </div>
        <div class="card-body text-center" *ngIf="receiptOfInvoicesPending.length === 0 && !receiptOfInvoicesLoading">
          <h5 class="card-title">There Are No Receipt Of Invoices With Discrepancies!</h5>
        </div>
        <div class="card-body">
          <div class="row mx-0">
            <ng-container *ngFor="let invoice of receiptOfInvoicesPending; let invoiceIndex = index;">
              <div class="col-md-6 col-xl-4 my-3">
                <div class="card shadow-lg">
                  <div class="card-body">
                    <h5 class="card-title">ReceiptNo: {{ invoice.ReceiptNo }}<span class="badge badge-pill badge-dark float-right"> {{ numberOfErrors(invoiceIndex) }} Discrepancies </span></h5>
                    <h5>Receipt Date: {{ invoice.ReceiptDate | date }}</h5>
                    <h5>Invoice No: {{ invoice.InvoiceNo }}</h5>
                    <h5>PurchaseOrder: {{ invoice.PurchaseOrderNo }}</h5>
                    <h5>Vendor: {{ invoice.VendorNo }}</h5>
                    <h5>Batch: {{ invoice.BatchNo }}</h5>
                    <button class="btn btn-primary btn-block" (click)="onReceiptSelected(invoiceIndex)">Explore</button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="receiptSelected">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col text-right">
              <span class="badge badge-pill badge-dark"> Discrepancies Count {{ numberOfErrors(receiptSelectedIndex) }} </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-sm-5"> ReceiptNo </div>
                <div class="col-sm-7">{{ receiptOfInvoicesPending[receiptSelectedIndex].ReceiptNo }}</div>
              </div>
              <div class="row">
                <div class="col-sm-5"> Purchase Order </div>
                <div class="col-sm-7">{{ receiptOfInvoicesPending[receiptSelectedIndex].PurchaseOrderNo }}</div>
              </div>
              <div class="row">
                <div class="col-sm-5"> BatchNo </div>
                <div class="col-sm-7">{{ receiptOfInvoicesPending[receiptSelectedIndex].BatchNo }}</div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-5"> Shipping Roi </div>
                <div class="col-sm-7">{{ receiptOfInvoicesPending[receiptSelectedIndex].InvoiceShipping | number : '1.2-2'  }}</div>
              </div>
              <div class="row">
                <div class="col-sm-5"> Sales Tax Roi </div>
                <div class="col-sm-7">{{ receiptOfInvoicesPending[receiptSelectedIndex].InvoiceSalesTax | number : '1.2-2' }}</div>
              </div>
              <div class="row">
                <div class="col-sm-5"> Total Due Roi </div>
                <div class="col-sm-7">{{ receiptOfInvoicesPending[receiptSelectedIndex].InvoiceTotalDue | number : '1.2-2' }}</div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-sm-5"> InvoiceNo </div>
                <div class="col-sm-7">{{ receiptOfInvoicesPending[receiptSelectedIndex].InvoiceNo }}</div>
              </div>
              <div class="row">
                <div class="col-sm-5"> Invoice Date </div>
                <div class="col-sm-7">{{ receiptOfInvoicesPending[receiptSelectedIndex].InvoiceDate | date }}</div>
              </div>
              <div class="row">
                <div class="col-sm-5"> VendorNo </div>
                <div class="col-sm-7">{{ receiptOfInvoicesPending[receiptSelectedIndex].VendorNo }}</div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-5"> Shipping Csv </div>
                <div class="col-sm-7">{{ receiptOfInvoicesPending[receiptSelectedIndex].HajocaShipping | number : '1.2-2' }}</div>
              </div>
              <div class="row">
                <div class="col-sm-5"> Sales Tax Csv </div>
                <div class="col-sm-7">{{ receiptOfInvoicesPending[receiptSelectedIndex].HajocaSalesTax | number : '1.2-2' }}</div>
              </div>
              <div class="row">
                <div class="col-sm-5"> Total Due Csv </div>
                <div class="col-sm-7">{{ receiptOfInvoicesPending[receiptSelectedIndex].HajocaTotalDue | number : '1.2-2' }}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <span class="badge badge-pill badge-primary mx-2" *ngIf="receiptOfInvoicesPending[receiptSelectedIndex].ShippingMatch === 0"> Csv Shipping Cost <> Roi Freight </span>
              <span class="badge badge-pill badge-primary mx-2" *ngIf="receiptOfInvoicesPending[receiptSelectedIndex].SalesTaxMatch === 0"> Csv SalesTax <> Roi SalesTax </span>
              <span class="badge badge-pill badge-primary mx-2" *ngIf="receiptOfInvoicesPending[receiptSelectedIndex].TotalDueMatch === 0"> Csv Total Due <> Roi Total Due </span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row mb-3 shadow">
            <div class="col pr-0">
              <ng-container *ngFor="let item of receiptOfInvoicesPending[receiptSelectedIndex].Lines; let itemIndex = index; let isFirst = first;">
                <div class="row">
                  <ul class="list-group list-group-flush list-group-horizontal col-12" *ngIf="isFirst">
                    <li class="list-group-item list-group-item-primary border-top-0 rounded-left col-sm-3 py-1"> Info <span class="badge badge-pill badge-dark float-right"> </span></li>
                    <li class="list-group-item list-group-item-primary border-top-0 rounded-0 col py-1 text-center"> On CSV </li>
                    <li class="list-group-item list-group-item-primary border-top-0 rounded-0 col py-1 text-center"> On Receipt </li>
                    <li class="list-group-item list-group-item-primary border-top-0 rounded-0 col py-1 text-center"> On Invoice </li>
                    <li class="list-group-item list-group-item-primary border-top-0 rounded-right col py-1 text-center"> On PO </li>
                  </ul>
                  <ng-container *ngIf="!itemDiscrepancy(itemIndex, receiptSelectedIndex)">
                    <ul class="list-group list-group-horizontal col-12">
                      <li class="list-group-item py-1 rounded-0 rounded-top col-sm-3" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> 
                        No Discrepancy Item
                      </li>
                      <li class="list-group-item py-1 rounded-0 rounded-top col text-center" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}">
                        <span class="badge badge-pill badge-success"> Invoiced: {{ item.ItemCodeRoi }} Qty: {{ item.QuantityInvoicedRoi | number : '1.2-2' }} </span>
                      </li>
                    </ul>
                  </ng-container>

                  <ng-container *ngIf="itemDiscrepancy(itemIndex, receiptSelectedIndex)">
                    <ul class="list-group list-group-horizontal col-12">
                      <li class="list-group-item py-1 rounded-0 rounded-top col-sm-3" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Item Code </li>
                      <li class="list-group-item py-1 rounded-0 rounded-top col" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> 
                        {{ item.ItemCodeHistory }} 
                      </li>
                      <li class="list-group-item py-1 rounded-0 rounded-top col" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> 
                        {{ item.RogItemCode }} 
                      </li>
                      <li class="list-group-item py-1 rounded-0 rounded-top col" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> 
                        {{ item.ItemCodeRoi}} 
                      </li>
                      <li class="list-group-item py-1 rounded-0 rounded-top col" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> 
                        {{ item.POItem }}
                      </li>
                    </ul>
                    <ul class="list-group list-group-horizontal col-12">
                      <li class="list-group-item py-1 col-sm-3 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Description </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescHistory }} </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.RogItemCodeDesc }} </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescRoi }} </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.PODescription }} </li>
                    </ul>
                    <ul class="list-group list-group-horizontal col-12">
                      <li class="list-group-item py-1 col-sm-3 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Quantity </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeHistory ? item.QuantityInvoicedHistory/ 1 : '' | number : '1.2-2' }} </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.RogItemCode ? item.RogQtyReceived / 1 : '' | number : '1.2-2' }} </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRoi ? item.QuantityInvoicedRoi : '' | number : '1.2-2' }} </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.POItem ? item.POQtyOrdered : '' | number : '1.2-2' }} </li>
                    </ul>
                    <ul class="list-group list-group-horizontal col-12">
                      <li class="list-group-item py-1 col-sm-3 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> UnitCost </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeHistory ? item.UnitCostHistory / 1 : '' | number : '1.2-2' }} </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"></li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRoi ? item.UnitCostRoi : '' | number : '1.2-2' }} </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.POItem ? item.POUnitCost : '' | number : '1.2-2' }} </li>
                    </ul>
                    <ul class="list-group list-group-horizontal col-12">
                      <li class="list-group-item py-1 col-sm-3 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> ExtendedAmt </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeHistory ? item.ExtendedAmtHistory / 1 : '' | number : '1.2-2' }} </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"></li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRoi ? item.ExtendedAmtRoi : '' | number : '1.2-2' }} </li>
                      <li class="list-group-item py-1 col border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"></li>
                    </ul>
                    <ul class="list-group list-group-horizontal col-12">
                      <li class="list-group-item py-1 col-sm-3 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Discrepancies </li>
                      <li class="list-group-item py-1 col rounded-0 rounded-top text-center" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> 
                        <span class="badge badge-pill badge-primary mx-2" *ngIf="item.ItemExistsHistory === 1 && item.ItemExistsRoi === 1 && item.QuantitiesMatch === 0"> Csv & Roi Quantities </span>
                        <span class="badge badge-pill badge-primary mx-2" *ngIf="item.ItemsMatch === 0 && item.ItemExistsRoi === 0"> Missing From R.O.I. </span>
                        <span class="badge badge-pill badge-primary mx-2" *ngIf="item.ItemsMatch === 0 && item.ItemExistsHistory === 0"> Missing From CSV </span>
                        <span class="badge badge-pill badge-primary mx-2" *ngIf="item.ExtAmtMatch === 0 && item.QuantitiesMatch === 1 && item.ItemExistsHistory === 1 && item.ItemExistsRoi === 1"> Roi & Csv Ext Costs </span>
                        <span class="badge badge-pill badge-primary mx-2" *ngIf="item.ItemsMatchPO === 0 && item.ItemExistsInPO === 0"> Missing From P.O. </span>
                        <span class="badge badge-pill badge-primary mx-2" *ngIf="item.CostsMatch === 0 && item.ItemsMatchPO === 1"> Roi & PO Costs </span>
                        <span class="badge badge-pill badge-primary mx-2" *ngIf="item.ItemsMatchRog === 0 && item.ItemExistsInRog === 0"> Missing From R.O.G. </span>
                        <span class="badge badge-pill badge-primary mx-2" *ngIf="item.ItemsMatchRog === 0 && item.ItemExistsInRoi === 0"> Missing From R.O.I. </span>
                        <span class="badge badge-pill badge-primary mx-2" *ngIf="item.QuantitiesMatchRog === 0 && item.ItemMatchRog === 1 && item.ItemExistsInRoi === 1 && item.ItemExistsInRog === 1"> Qty Invoiced <> Qty Received  </span>
                      </li>
                    </ul>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
          <ng-container>

              <!-- <div class="row mb-3 shadow">
                <div class="col pr-0">
                  <ng-container *ngFor="let item of receiptOfInvoicesPending[receiptSelectedIndex].Errors.Goods.ItemCodesDoNotMatch; let itemIndex = index; let isFirst = first;">
                    <div class="row">
                      <ul class="list-group list-group-flush list-group-horizontal col-12" *ngIf="isFirst">
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-left  col-sm-4 py-1"> Items Do Not Match <span class="badge badge-pill badge-dark float-right"> R.O.I. vs. R.O.G. </span></li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-0     col-sm-4 py-1 text-center"> Invoiced </li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-right col-sm-4 py-1 text-center"> Received </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Item Code </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRoi }} </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRog }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Description </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescRoi }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescRog }} </li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row mb-3 shadow">
                <div class="col pr-0">
                  <ng-container *ngFor="let item of receiptOfInvoicesPending[receiptSelectedIndex].Errors.Goods.ItemMissingFromRog; let itemIndex = index; let isFirst = first;">
                    <div class="row">
                      <ul class="list-group list-group-flush list-group-horizontal col-12" *ngIf="isFirst">
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-left  col-sm-4 py-1"> Item Invoiced But Not Received <span class="badge badge-pill badge-dark float-right"> R.O.I. vs. R.O.G. </span></li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-0     col-sm-4 py-1 text-center"> Invoiced </li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-right col-sm-4 py-1 text-center"> Received </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Item Code </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCode }} </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"></li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Description </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDesc }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"></li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row mb-3 shadow">
                <div class="col pr-0">
                  <ng-container *ngFor="let item of receiptOfInvoicesPending[receiptSelectedIndex].Errors.Goods.ItemMissingFromRoi; let itemIndex = index; let isFirst = first;">
                    <div class="row">
                      <ul class="list-group list-group-flush list-group-horizontal col-12" *ngIf="isFirst">
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-left  col-sm-4 py-1"> Item On Receipt Of Goods Not On Invoice <span class="badge badge-pill badge-dark float-right"> R.O.I. vs. R.O.G. </span></li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-0     col-sm-4 py-1 text-center"> Invoiced </li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-right col-sm-4 py-1 text-center"> Received </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Item Code </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"></li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCode }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Description </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"></li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDesc }} </li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row mb-3 shadow">
                <div class="col pr-0">
                  <ng-container *ngFor="let item of receiptOfInvoicesPending[receiptSelectedIndex].Errors.Goods.ItemQuantitiesDoNotMatch; let itemIndex = index; let isFirst = first;">
                    <div class="row">
                      <ul class="list-group list-group-flush list-group-horizontal col-12" *ngIf="isFirst">
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-left  col-sm-4 py-1"> Items Quantities Do Not Match <span class="badge badge-pill badge-dark float-right"> R.O.I. vs. R.O.G. </span></li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-0     col-sm-4 py-1 text-center"> Invoiced </li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-right col-sm-4 py-1 text-center"> Received </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Item Code </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRoi }} </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRog }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Description </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescRoi }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescRog }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Quantity </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.QuantityInvoiced | number : '1.2-2' }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.QuantityReceived | number : '1.2-2' }} </li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row mb-3 shadow">
                <div class="col pr-0">
                  <ng-container *ngFor="let item of receiptOfInvoicesPending[receiptSelectedIndex].Errors.History.ItemQuantitiesDoNotMatch; let itemIndex = index; let isFirst = first;">
                    <div class="row">
                      <ul class="list-group list-group-flush list-group-horizontal col-12" *ngIf="isFirst">
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-left  col-sm-4 py-1"> Items Quantities Do Not Match <span class="badge badge-pill badge-dark float-right"> R.O.I. vs. Sent </span></li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-0     col-sm-4 py-1 text-center"> Invoiced </li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-right col-sm-4 py-1 text-center"> Sent </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Item Code </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRoi }} </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeHistory }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Description </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescRoi }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescHistory }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Quantity </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.QuantityInvoicedRoi | number : '1.2-2' }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.QuantityInvoicedHistory | number : '1.2-2' }} </li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row mb-3 shadow">
                <div class="col pr-0">
                  <ng-container *ngFor="let item of receiptOfInvoicesPending[receiptSelectedIndex].Errors.History.ItemMissingFromHistory; let itemIndex = index; let isFirst = first;">
                    <div class="row">
                      <ul class="list-group list-group-flush list-group-horizontal col-12" *ngIf="isFirst">
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-left  col-sm-4 py-1"> Item On Invoice Not Sent <span class="badge badge-pill badge-dark float-right"> R.O.I. vs. Sent </span></li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-0     col-sm-4 py-1 text-center"> Invoiced </li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-right col-sm-4 py-1 text-center"> Sent </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Item Code </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRoi }} </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"></li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Description </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescRoi }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"></li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row mb-3 shadow">
                <div class="col pr-0">
                  <ng-container *ngFor="let item of receiptOfInvoicesPending[receiptSelectedIndex].Errors.History.ItemMissingFromRoi; let itemIndex = index; let isFirst = first;">
                    <div class="row">
                      <ul class="list-group list-group-flush list-group-horizontal col-12" *ngIf="isFirst">
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-left  col-sm-4 py-1"> Item Sent Not On Receipt <span class="badge badge-pill badge-dark float-right"> R.O.I. vs. Sent </span></li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-0     col-sm-4 py-1 text-center"> Invoiced </li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-right col-sm-4 py-1 text-center"> Sent </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Item Code </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"></li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeHistory }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Description </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"></li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescHistory }} </li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row mb-3 shadow">
                <div class="col pr-0">
                  <ng-container *ngFor="let item of receiptOfInvoicesPending[receiptSelectedIndex].Errors.History.ItemCodesDoNotMatch; let itemIndex = index; let isFirst = first;">
                    <div class="row">
                      <ul class="list-group list-group-flush list-group-horizontal col-12" *ngIf="isFirst">
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-left  col-sm-4 py-1"> Items Do Not Match <span class="badge badge-pill badge-dark float-right"> R.O.I. vs. Sent </span></li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-0     col-sm-4 py-1 text-center"> Invoiced </li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-right col-sm-4 py-1 text-center"> Sent </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Item Code </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRoi }} </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeHistory }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Description </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescRoi }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescHistory }} </li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row mb-3 shadow">
                <div class="col pr-0">
                  <ng-container *ngFor="let item of receiptOfInvoicesPending[receiptSelectedIndex].Errors.History.ItemExtendedAmtDoNotMatch; let itemIndex = index; let isFirst = first;">
                    <div class="row">
                      <ul class="list-group list-group-flush list-group-horizontal col-12" *ngIf="isFirst">
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-left  col-sm-4 py-1"> Item Extended Amounts Do Not Match <span class="badge badge-pill badge-dark float-right"> R.O.I. vs. Sent </span></li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-0     col-sm-4 py-1 text-center"> Invoiced </li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-right col-sm-4 py-1 text-center"> Sent </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Item Code </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRoi }} </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeHistory }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Description </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescRoi }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescHistory }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Quantity </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.QuantityInvoicedRoi | number : '1.2-2' }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.QuantityInvoicedHistory | number : '1.2-2' }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Quantity </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ExtendedAmtRoi | number : '1.2-2' }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ExtendedAmtHistory | number : '1.2-2' }} </li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row mb-3 shadow">
                <div class="col pr-0">
                  <ng-container *ngFor="let item of receiptOfInvoicesPending[receiptSelectedIndex].Errors.Po.ItemCodesDoNotMatch; let itemIndex = index; let isFirst = first;">
                    <div class="row">
                      <ul class="list-group list-group-flush list-group-horizontal col-12" *ngIf="isFirst">
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-left  col-sm-4 py-1"> Items Do Not Match <span class="badge badge-pill badge-dark float-right"> R.O.I. vs. P.O. </span></li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-0     col-sm-4 py-1 text-center"> Invoiced </li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-right col-sm-4 py-1 text-center"> Ordered </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Item Code </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRoi }} </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRog }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Description </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescRoi }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescRog }} </li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row mb-3 shadow">
                <div class="col pr-0">
                  <ng-container *ngFor="let item of receiptOfInvoicesPending[receiptSelectedIndex].Errors.Po.ItemMissingFromPo; let itemIndex = index; let isFirst = first;">
                    <div class="row">
                      <ul class="list-group list-group-flush list-group-horizontal col-12" *ngIf="isFirst">
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-left  col-sm-4 py-1"> Item On Invoice Not On Purchase Order <span class="badge badge-pill badge-dark float-right"> R.O.I. vs. P.O. </span></li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-0     col-sm-4 py-1 text-center"> Invoiced </li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-right col-sm-4 py-1 text-center"> Ordered </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Item Code </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCode }} </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"></li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Description </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDesc }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"></li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row mb-3 shadow">
                <div class="col pr-0">
                  <ng-container *ngFor="let item of receiptOfInvoicesPending[receiptSelectedIndex].Errors.Po.ItemCostsDoNotMatch; let itemIndex = index; let isFirst = first;">
                    <div class="row">
                      <ul class="list-group list-group-flush list-group-horizontal col-12" *ngIf="isFirst">
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-left  col-sm-4 py-1"> Item Costs Do Not Match <span class="badge badge-pill badge-dark float-right"> R.O.I. vs. P.O. </span></li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-0     col-sm-4 py-1 text-center"> Invoiced </li>
                        <li class="list-group-item list-group-item-primary border-top-0 rounded-right col-sm-4 py-1 text-center"> Ordered </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Item Code </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeRoi }} </li>
                        <li class="list-group-item py-2 rounded-0 rounded-top col-sm-4" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodePo }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Description </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescRoi }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.ItemCodeDescPo }} </li>
                      </ul>
                      <ul class="list-group list-group-horizontal col-12">
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> Cost </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.UnitCostRoi }} </li>
                        <li class="list-group-item py-2 col-sm-4 border-top-0 rounded-0 rounded-bottom" [ngClass]="{'list-group-item-dark': itemIndex % 2 !== 0}"> {{ item.UnitPo }} </li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div> -->

          </ng-container>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-4">
              <div class="float-left">
                <button class="btn btn-secondary pr-5 pl-5" (click)="onReceiptDeselected()"> Back </button>
              </div>
            </div>
            <div class="col-4 text-center">
              <a class="btn p-0" (click)="openModal(fileExplorer)" (click)="fileNames(receiptOfInvoicesPending[receiptSelectedIndex])"><i class="far fa-folder-open fa-3x text-dark"></i></a>
            </div>
            <div class="col-4">
              <div class="float-right">
                <button class="btn btn-primary pr-5 pl-5" (click)="openModal(approvalNotes)" [disabled]=" receiptOfInvoiceUpdating"><span *ngIf="!receiptOfInvoiceUpdating">Approve Roi</span><span *ngIf="receiptOfInvoiceUpdating"><i class="fas fa-sync fa-spin"></i></span></button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </ng-container>
  </div>
</div>

<ng-template #fileExplorer>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>File Contents for PO: {{ receiptOfInvoicesPending[receiptSelectedIndex].PurchaseOrderNo | titlecase}}</h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <div class="row mb-1" id="files">
      <div class="col-3 pt-2">
        <div class="card text-center">
          <label class="pt-2 mb-0">
            <i class="fas fa-file-upload fa-4x text-info"></i>
            <input type="file" (change)="fileChosen($event)" style="display: none;">
          </label>
          <small>Upload</small>
          <ng-container *ngFor="let file of fileArray">
            <br> <small> {{ file.name }}</small>
          </ng-container>
        </div>
      </div>
      <div class="col-3 pt-2" *ngFor="let file of fileList">
        <div class="card text-center">
          <a class="btn p-0 pt-2" (click)="fileDownload(receiptOfInvoicesPending[receiptSelectedIndex], file.name)"><i class="fas fa-file-alt fa-4x"></i></a>
          <small>{{ file.name }}</small>
        </div>
      </div>
    </div>

    <form>
      <div class="form-row pt-2">
        <div class="col">
          <button class="btn btn-primary float-right" (click)="closeModal()">Close</button>
          <button class="btn btn-info float-right mr-2" (click)="fileUpload(receiptOfInvoicesPending[receiptSelectedIndex])" *ngIf="fileArray.length > 0"><span *ngIf="!fileLoading">Upload</span><span *ngIf="fileLoading"><i class="fas fa-sync fa-spin"></i></span></button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #approvalNotes>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>Approval Notes For Receipt: {{ receiptOfInvoicesPending[receiptSelectedIndex].ReceiptNo | titlecase}}</h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <div class="row mx-3">
      <div class="col-12 mb-2">
        <textarea class="form-control" name="roiNotes" rows="5" [(ngModel)]="receiptOfInvoicesPending[receiptSelectedIndex].ResolutionNotes" placeholder="Enter Notes about Discrepancy Resolution"></textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center mt-3 mb-3">
        <div class="float-right">
          <button class="btn btn-primary pr-5 pl-5" (click)="onApproveReceiptOfInvoice(receiptOfInvoicesPending[receiptSelectedIndex], receiptSelectedIndex)" [disabled]=" receiptOfInvoiceUpdating || receiptOfInvoicesPending[receiptSelectedIndex].ResolutionNotes === ''"><span *ngIf="!receiptOfInvoiceUpdating">Approve Roi</span><span *ngIf="receiptOfInvoiceUpdating"><i class="fas fa-sync fa-spin"></i></span></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
