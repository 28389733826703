<div class="container pb-5">
  <div class="card my-5 shadow-lg">
    <div class="d-flex justify-content-center p-3">
      <button class="btn btn-success text-light" routerLink="Create"><h1>Create New Transfer</h1></button>
    </div>
    <div>
      <div class="row m-0 p-3" id="historyLog">
        <div class="col-6">
          <h2> Warehouse Transfer History </h2>
        </div>
        <div class="col-6 text-right">
          <button class="btn btn-primary" (click)="getTransferHistory()">Refresh history</button>
        </div>
        <div class="col-3">
          <label for="IS-select"> <b> Status </b> </label>
          <select [(ngModel)]="IntStatus" class="form-control" name="IS-select" id="IS-select"
            [disabled]="loadingHistory">
            <option value="All">All</option>
            <option value="Submitted">Submitted</option>
            <option value="New">New</option>
            <option value="Batched">Batched</option>
            <option value="Check">Check</option>
            <option value="Error">Error</option>
          </select>
        </div>
        <div class="col-3">
          <label for="SentDate"> <b>Earliest Sent Date</b> </label>
          <input type="text" class="form-control" id="SentDate" name="SentDate" bsDatepicker [bsConfig]="bsConfig"
            [(bsValue)]="LastSentDate" [disabled]="loadingHistory">
        </div>
        <div class="col-2">
          <label for="PONo"> <b>Created By #</b> </label>
          <input class="form-control" id="CreatedByFilter" name="CreatedByFilter" [(ngModel)]="CreatedByFilter"
            autocomplete="off" [disabled]="loadingHistory">
        </div>
        <div class="col-2">
          <label for="BatchNo"> <b>Batch #</b> </label>
          <input class="form-control" id="BatchNo" name="BatchNo" [(ngModel)]="BatchNumFilter" autocomplete="off"
            [disabled]="loadingHistory">
        </div>
        <div class="col-2">
          <label for="ItemCode"> <b>Item Code</b> </label>
          <input class="form-control" id="ItemCode" name="ItemCode" [(ngModel)]="ItemCodeFilter" autocomplete="off"
            [disabled]="loadingHistory">
        </div>
      </div>
      <table class="table table-stripped table-hover">
        <thead>
          <tr>
            <th>Batch Number</th>
            <th>Item Code</th>
            <th>Quantity</th>
            <th>Warehouse From</th>
            <th>Warehouse To</th>
            <th>Created By</th>
            <th>Date Created</th>
            <th (click)="sortReceiptHistByError()">Status</th>
          </tr>
        </thead>
        <ng-container *ngFor="let row of sortedTransferHistory; let i = index">
          <tr [ngStyle]="{'color': changeTextColorIfError(row)}" *ngIf="historyFilters(row)">
            <td>{{row.BatchNo}}</td>
            <td>{{row.ItemCode}}</td>
            <td>{{row.Quantity}}</td>
            <td>{{row.WarehouseFrom}}</td>
            <td>{{row.WarehouseTo}}</td>
            <td>{{row.CreatedBy}}</td>
            <td>{{row.DateCreated | date : 'MM/dd/yyyy'}}</td>
            <td>{{row.Status}}</td>
          </tr>
        </ng-container>
      </table>
    </div>

    <!-- <app-data-table
[rawData]="transferHistory"
[tableColumns]="[
  {col: 'BatchNo', value: 'BatchNo', title: 'Batch Number', size: 'md'},
  {col: 'ItemCode', value: 'ItemCode', title: 'Item Code', size: 'md'},
  {col: 'WarehouseFrom', value: 'WarehouseFrom', title: 'Warehouse From', size: 'md'},
  {col: 'WarehouseTo', value: 'WarehouseTo', title: 'Warehouse To', size: 'md'},
  {col: 'Status', value: 'Status', title: 'Status', size: 'md'},
  {col: 'CreatedBy', value: 'CreatedBy', title: 'Created By', size: 'md'},
  {col: 'DateCreated', value: 'DateCreated', title: 'Date Created', size: 'md'},
]"
[inColumns]="
[
  {col: 'CreatedBy',  title: 'Created By'},
  {col: 'ItemCode',  title: 'Item Code' },
  {col: 'WarehouseFrom', title: 'Warehouse From'},
  {col: 'WarehouseTo',  title: 'Warehouse To'},
  {col: 'Status',title: 'Status' }
]"
[dpickerColumns]="[
        ]"
[isLoading]="loadingHistory"
[includeColumnSelector]="true"
[searchOnChange]="true"
accordianControls="true"
accordianScreenSize="smd"
title="Warehouse Transfer History"
>
<ng-container table-content>
  <div class="h360-table-tabcon-ctrls">
  </div>
</ng-container>
</app-data-table> -->
  </div>
</div>
