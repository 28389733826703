import { Component, ViewChild, OnInit, TemplateRef } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import * as Rx from "rxjs";
// import { AuthService } from '../../services/auth/auth.service';
// import { LoginService } from '../../services/login/login.service';
import { SageApiService } from "../../../_services/sageApi/sageApi.service";
import { concat } from "rxjs";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AuthService } from "../../../_services/auth/auth.service";
import { AlertifyService } from "../../../_services/alertify/alertify.service";
import { FormControl } from "@angular/forms";
// import { DataModel } from '../../model/data-model';
// import { WomsService } from 'app/services/woms/woms.service';
// import { andObservables } from '@angular/router/src/utils/collection';
// import { ExcelService } from '../../services/excel/excel.service';

@Component({
  selector: "app-woms-acct",
  templateUrl: "./woms-acct.component.html",
  styleUrls: ["./woms-acct.component.css"],
})
export class WomsAcctComponent implements OnInit {
  dataModel = {
    customer: "",
    address: "",
    workPerformed: "",
    assignedTo: "",
    statusCode: "",
    steps: [],
    wts: [],
    subdivisions: [],
    orders: [],
    ordersLM: [],
    tracking: "",
    loaded: [],
    loadtech: [],
    loadstatuses: [],
    salesorder: "",
    statusCodes: "",
    techid: "",
    statuscomment: "",
    qaServiceCodes: "",
    newtracking: "",
    finaltracking: "",
    leadmanReport: [],
    redFlag: "",
    miscTech: [],
    uninstalledItems: ["loading"],
    materialChanges: [],
    kpiQA: [],
    kpiPunch: [],
    kpiOpenItems: [],
    serviceCodes: [],
    womsMaterial: [],
    ListLeadman: [],
    listWomsStatus: [],
    womsHistory: [],
    qaItemsForPunch: [],
  };
  index: any = "0";
  isLoading: boolean;
  loadingStatus: string;
  addVPOComments: string;
  vpoNotesUpdated = false;
  failed: boolean;
  wtSortDirection = "";
  addressSortDirection = "";
  subdivisionSortDirection = "";
  statusSortDirection = "";
  companySortDirection = "";
  amountSortDirection = "";
  dateSortDirection = "";
  showMaterial = false;
  allTicketTotalCost = 0;
  errorMsg = "";

  reportOptions: string[] = [
    "Accounting Review",
    "Pre-Dispute Builder Charge",
    "EPO Received",
    "EPO Outstanding",
    "EPO Disputing",
    "EPO Invoiced",
    "Canceled Backcharges"
  ];
  report: FormControl = new FormControl([
    "Accounting Review",
    "Pre-Dispute Builder Charge",
    "EPO Received",
    "EPO Outstanding",
    "EPO Disputing",
    "EPO Invoiced",
    "Canceled Backcharges"
  ]);

  companiesOptions: string[] = [];
  companies: FormControl = new FormControl([]);

  addressSearch = '';
  salesOrderSearch= '';

  modalTemplate: BsModalRef;
  importTemplateInfo = {
    templateName: "",
    template: "",
    step: "",
  };
  FromDate: Date;
  ToDate: Date;

  sortByHistory = false;
  urlParams: URLSearchParams;

  @ViewChild("editInterface") editInterface;
  @ViewChild("vpoNotesModal") vpoNotesModal;

  constructor(
    private router: Router,
    private sageApi: SageApiService,
    private modalService: BsModalService,
    private authService: AuthService,
    private alertify: AlertifyService // private womsApi: WomsService, // private authService: AuthService, // private loginService: LoginService,
  ) {}

  ngOnInit() {
    this.loadStatus();
    this.loadHistory();
    // .then(res => this.loadHistory2());
    // this.loadHistory2();
    this.checkForParams();
   // this.loadHistory("Accounting Review");
    console.log(this.dataModel.steps);
  }

  // calculateTicketsTotal(): any {
  //   return this.dataModel.steps.filter((item) => {
  //     if (Math.sign(item.amount) === 1) {
  //       this.allTicketTotalCost += item.amount;
  //     }
  //   });
  // }
  checkForParams() {
    console.log("checking");
    this.urlParams = new URLSearchParams(window.location.search);
    console.log(this.urlParams);
    if (this.urlParams.has("salesorder")) {
      const answer = this.urlParams.get("salesorder");
      console.log(answer)
      this.salesOrderSearch = answer;
      this.getIncludedSteps();
    }
  }

  returnToUnsorted(column) {
    if (column == "wtNumber") {
      this.addressSortDirection = "";
      this.subdivisionSortDirection = "";
      this.statusSortDirection = "";
      this.companySortDirection = "";
      this.amountSortDirection = "";
      this.dateSortDirection = "";
    }
    if (column === "address") {
      this.wtSortDirection = "";
      this.subdivisionSortDirection = "";
      this.statusSortDirection = "";
      this.companySortDirection = "";
      this.amountSortDirection = "";
      this.dateSortDirection = "";
    }
    if (column === "subdivision") {
      this.wtSortDirection = "";
      this.addressSortDirection = "";
      this.statusSortDirection = "";
      this.companySortDirection = "";
      this.amountSortDirection = "";
      this.dateSortDirection = "";
    }
    // if (column === "status") {
    //   this.wtSortDirection = "";
    //   this.addressSortDirection = "";
    //   this.subdivisionSortDirection = "";
    //   this.companySortDirection = "";
    //   this.amountSortDirection = "";
    //   this.dateSortDirection = "";
    // }
    if (column === "builderCompany") {
      this.wtSortDirection = "";
      this.addressSortDirection = "";
      this.subdivisionSortDirection = "";
      this.statusSortDirection = "";
      this.amountSortDirection = "";
      this.dateSortDirection = "";
    }
    if (column === "amount") {
      this.wtSortDirection = "";
      this.addressSortDirection = "";
      this.subdivisionSortDirection = "";
      this.statusSortDirection = "";
      this.companySortDirection = "";
      this.dateSortDirection = "";
    }
    if (column === "currentStatusDate") {
      this.wtSortDirection = "";
      this.addressSortDirection = "";
      this.subdivisionSortDirection = "";
      this.statusSortDirection = "";
      this.companySortDirection = "";
      this.amountSortDirection = "";
    }
  }
  onColumnSort(column, order) {
    this.dataModel.steps.sort(compareValues(column, order));
    function compareValues(key, order2 = "asc") {
      // tslint:disable-next-line:only-arrow-functions
      return function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          console.log(a, b, "not in array");
          return 0;
        }

        const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order2 === "desc" ? comparison * -1 : comparison;
        //  if(order === 'desc')
      };
    }
    if (column === "wtNumber") {
      if (this.wtSortDirection === "asc" || this.wtSortDirection === "") {
        this.wtSortDirection = "desc";
      } else {
        this.wtSortDirection = "asc";
      }
    }
    if (column === "address") {
      if (
        this.addressSortDirection === "asc" ||
        this.addressSortDirection === ""
      ) {
        this.addressSortDirection = "desc";
      } else {
        this.addressSortDirection = "asc";
      }
    }
    if (column === "subdivision") {
      if (
        this.subdivisionSortDirection === "asc" ||
        this.subdivisionSortDirection === ""
      ) {
        this.subdivisionSortDirection = "desc";
      } else {
        this.subdivisionSortDirection = "asc";
      }
    }
    // if (column === "status") {
    //   if (
    //     this.statusSortDirection === "asc" ||
    //     this.statusSortDirection === ""
    //   ) {
    //     this.statusSortDirection = "desc";
    //     console.log("desc");
    //   } else {
    //     this.statusSortDirection = "asc";
    //   }
    // }

    if (column === "builderCompany") {
      if (
        this.companySortDirection === "asc" ||
        this.companySortDirection === ""
      ) {
        this.companySortDirection = "desc";
      } else {
        this.companySortDirection = "asc";
      }
    }
    if (column === "amount") {
      if (
        this.amountSortDirection === "asc" ||
        this.amountSortDirection === ""
      ) {
        this.amountSortDirection = "desc";
      } else {
        this.amountSortDirection = "asc";
      }
    }
    if (column === "currentStatusDate") {
      if (this.dateSortDirection === "asc" || this.dateSortDirection === "") {
        this.dateSortDirection = "desc";
      } else {
        this.dateSortDirection = "asc";
      }
    }
    this.returnToUnsorted(column);
  }

  openModal(template: TemplateRef<any>) {
    // console.log(this.houseInfo, this.houseLocationList);
    // console.log(template);
    this.loadingStatus = "";
    this.importTemplateInfo = {
      templateName: "",
      template: "",
      step: "",
    };

    this.modalTemplate = this.modalService.show(template);
    // this.modalTemplate = this.modalService.show(template, {class: 'modal-lg'});
  }

  closeModal() {
    this.modalTemplate.hide();
  }

  actionEdit(index) {
    this.index = this.dataModel.steps.indexOf(index);
    // this.index = index.toString();
    // this.editInterface.show();
    this.loadingStatus = "";
    this.errorMsg = "";
  }

  actionClose() {
    // this.editInterface.hide();
    this.closeModal();
  }

  onShowVPONotes(index) {
    this.index = this.dataModel.steps.indexOf(index);
    //this.index = index.toString();
    // this.vpoNotesModal.show();
    this.loadingStatus = "";
  }

  onVPONotesClose() {
    // this.vpoNotesModal.hide();
    this.closeModal();
  }

  onAddVPOComments(index: string) {
    this.isLoading = true;
    const observables = [];
    const vpoObj = {
      username: this.authService.decodedToken.nameid,
      password:
        this.authService.decodedToken[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication"
        ],
      salesorderno: this.dataModel.steps[index].soNum,
      wtnumber: this.dataModel.steps[index].soWT,
      lineKey: this.dataModel.steps[index].lineKey,
      vpoNotes: this.dataModel.steps[index].vpoNotes,
    };

    // observables.push(this.womsApi.updateVPONotes(this.dataModel.steps[index]));

    observables.push(this.sageApi.putRequest("WomsAcct/Vpo", vpoObj));

    concat(...observables).subscribe(
      (e) => {
        console.log(`inside RX ${e}`);
      },
      (error) => {
        console.error(error);
        this.alertify.error("Error submitting WT: " + error.error.Message);
        this.failed = true;
        // const doc = this.sageApi
        //   ._parseXML(error._body)
        //   .getElementsByTagName('sdata:message')[0].innerHTML;
        this.isLoading = false;
        // this.loadingStatus = `❌ Failed to commit -  ${doc}`;
      },
      () => {
        this.loadingStatus =
          "✔️Loaded Successfully - Changes Committed to SAGE";
        this.alertify.success(this.loadingStatus);
        this.failed = false;
        this.isLoading = false;
        this.vpoNotesUpdated = true;
        // setTimeout(() => this.vpoNotesModal.hide(), 4000);
        setTimeout(() => this.closeModal(), 4000);
      }
    );
    console.log(this.dataModel.steps[index]);
  }

  saveChanges(index: string) {
    this.isLoading = true;
    const observables = [];
    const submitObj = {
      username: this.authService.decodedToken.nameid,
      password:
        this.authService.decodedToken[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication"
        ],
      salesorderno: this.dataModel.steps[index].soNum,
      wtnumber: this.dataModel.steps[index].soWT,
      lineKey: this.dataModel.steps[index].lineKey,
      newACCStatus: this.dataModel.steps[index].newACCStatus,
      poNumber: this.dataModel.steps[index].poNumber,
      histObj: [],
    };

    const newStatus =
      this.dataModel.steps[index].newType === "BUILDER"
        ? "PRCRV"
        : this.dataModel.steps[index].newType === "GIBSON"
        ? "LOSS"
        : this.dataModel.steps[index].newType === "DISPUTE"
        ? "DISBC"
        : this.dataModel.steps[index].newType === "CREW"
        ? "CREWBC"
        : this.dataModel.steps[index].newType === "CANCEL"
        ? "CANCEL"
        : this.dataModel.steps[index].newACCStatus || "  ";

    const datePipe = new DatePipe("en-US");
    const todaydate = datePipe.transform(new Date(), "yyyyMMddHHmmss");
    const tmpKey = this.dataModel.steps[index].soNum + todaydate + "H";
    console.log("tmpkey", tmpKey);

    const histObj = [
      /*webkey: 0*/ tmpKey,
      /*address: 1*/ this.dataModel.steps[index].address,
      /*descriptionOfWork: 2*/ this.dataModel.steps[index].descriptionOfWork ||
        " ",
      /*technician: 3*/ this.dataModel.steps[index].technician || " ",
      /*newTime: 4*/ this.dataModel.steps[index].newTime || " ",
      /*workTime: 5*/ this.dataModel.steps[index].workTime || " ",
      /*dateScheduled: 6*/ this.dataModel.steps[index].dateScheduled || " ",
      /*matNeededDate: 7*/ this.dataModel.steps[index].matNeededDate || " ",
      /*matDescription: 8*/ this.dataModel.steps[index].matDescription || " ",
      /*notes: 9*/ this.dataModel.steps[index].notes || " ",
      /*type: 10*/ this.dataModel.steps[index].type || " ",
      /*builderName: 11*/ this.dataModel.steps[index].builderName || " ",
      /*builderPhone: 12*/ this.dataModel.steps[index].builderPhone || " ",
      /*currentStatus: 13*/ this.dataModel.steps[index].currentStatus || " ",
      /*currentStatusDate: 14*/ this.dataModel.steps[index].currentStatusDate ||
        " ",
      /*matNeededCheck: 15*/ this.dataModel.steps[index].matNeededCheck || " ",
      /*newStatus: 16*/ newStatus || " ",
      /*newType: 17*/ this.dataModel.steps[index].newType || " ",
      /*serviceCode: 18*/ this.dataModel.steps[index].serviceCode || " ",
      /*builderCompany: 19*/ this.dataModel.steps[index].builderCompany || " ",
    ];
    submitObj.histObj = histObj;

    // observables.push(this.womsApi.updateACC(this.dataModel.steps[index]));
    // observables.push(this.womsApi.updateACCSO(this.dataModel.steps[index]));
    // observables.push(this.womsApi.storeHistoryWorkticket(this.dataModel.steps[index]));
    observables.push(this.sageApi.putRequest("WomsAcct", submitObj));
    console.log("store data observables", observables);

    concat(...observables).subscribe(
      (e) => {
        console.log("put observable response", e);
      },
      (err) => {
        console.log(err);
        // this.errorMsg = this.sageApi
        //   ._parseXML(err._body)
        //   .getElementsByTagName('sdata:message')[0].innerHTML;
        this.alertify.error(err.error.Message);
        this.isLoading = false;
        this.loadingStatus = `Failed to submit: ${this.errorMsg} Please try again.`;
      },
      () => {
        this.isLoading = false;
        this.loadingStatus = "Loaded Successfully - Changes Committed to SAGE";
        this.alertify.success(this.loadingStatus);
        // this.editInterface.hide();
        this.closeModal();
        this.dataModel.steps[index].updated = "updated";
      }
    );

    console.log(this.dataModel.steps[index]);
  }

  loadStatus() {
    this.failed = false;
    this.loadingStatus = "";
    this.sageApi.pullReport("WomsStatuses").subscribe(
      (statuses: Array<any>) => {
        if (Array.isArray(statuses)) {
          statuses.forEach((status) => {
            if(status.status_code != 'CREWBC' && status.status_code != 'LMRV' && status.status_code != 'MAN_DEFECT' && status.status_code != 'NO TYPE'
            && status.status_code != 'WARC'){

              const obj = {
                statusCode: status.status_code,
                statusDesc: status.status_code_desc,
              };
              this.dataModel.loadstatuses.push(obj);
            }
          });
        }
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }

  loadHistory() {
    this.isLoading = true;
    console.log("Loading " );

    let reportstring = "womsHistory/initialLoad";
    // if (report === "Accounting Review") {
    //   reportstring = "womsHistory/ACCRV";
    // } else if (report === "Pre-Dispute Builder Charge") {
    //   reportstring = "womsHistory/PDISC";
    // } else if (report === "EPO Received") {
    //   reportstring = "womsHistory/EPOR";
    // } else if (report === "EPO Outstanding") {
    //   reportstring = "womsHistory/EPOO";
    // } else if (report === "EPO Disputing") {
    //   reportstring = "womsHistory/EPOD";
    // } else if (report === "EPO Invoiced") {
    //   reportstring = "womsHistory/EPOI";
    // } else {
    //   this.isLoading = false;
    //   this.alertify.error("ERROR")
    //   return;
    // }

    this.index = "0";
    this.dataModel.steps = [];
    this.sageApi.pullReport(reportstring).subscribe(
      (wts: Array<any>) => {
        if (wts && Object.keys(wts).length < 1) {
          return;
        }
        if (Array.isArray(wts)) {
          wts.forEach((w) => {
            var dateFormatted: Date;
            if (w.UDF_WOMS_STATUS_DATE != null) {
              if (!w.UDF_WOMS_STATUS_DATE.includes("-")) {
                var year = w.UDF_WOMS_STATUS_DATE.substring(0, 4);
                var month = w.UDF_WOMS_STATUS_DATE.substring(4, 6);
                var day = w.UDF_WOMS_STATUS_DATE.substring(6, 8);
                dateFormatted = new Date(year + "-" + month + "-" + day);
              } else {
                dateFormatted = new Date(w.UDF_WOMS_STATUS_DATE);
              }
            }

            const obj = {
              soNum: w.SalesOrderNo,
              soWT: w.WTNumber,
              wtNumber: w.SalesOrderNo + ["-"] + w.WTNumber,
              lineKey: w.LineKey,
              address: w.Address,
              technician: w.Technician,
              descriptionOfWork: w.DescriptionOfWork,
              commentText: w.CommentText,
              workTime: w.WorkTime,
              builderName: w.BuilderName,
              builderPhone: w.BuilderPhone,
              builderCompany: w.BuilderCompany,
              subdivision: w.Subdivision,
              currentStatus: w.Status,
              newACCStatus: "",
              statusDescription: w.StatusDesc,
              poNumber: w.PoNumber,
              // phase: w['UDF_PHASE'],
              type: w.Type,
              newType: "",
              notes: w.TypeComment,
              amount: w.ParentPrice || 0,
              newAmount: "",
              crew1: "",
              crew2: "",
              crew3: "",
              crewPercent1: "",
              crewPercent2: "",
              crewPercent3: "",
              updated: "",
              vpoNotes: w.VPO_NOTES,
              currentStatusDate: dateFormatted,
              matNeededCheck: w.UDF_MAT_NEEDED_CHECK,
              matNeededDate: w.UDF_MAT_NEEDED_DATE,
              matDescription: w.UDF_MAT_NEEDED_DESC,
              serviceCode: w.UDF_SERVICE_CODE,
              dateScheduled: w.PromiseDate,
              womsHist: []
            };
            this.dataModel.steps.push(obj);
          });
        }
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.allTicketTotalCost = 0;
        this.loadCompanies();
        // this.loadHistory2();
        // return new Promise((resolve, reject) => {
        //   console.log('resolve');
        //   resolve();
        // });
      }
    );

  }

  loadHistory2() {
    // this.index = this.dataModel.steps.indexOf(index);
    this.sageApi.pullReport("WOMs/History").subscribe(
      (wts: Array<any>) => {
        if (wts && Object.keys(wts).length < 1) {
          return;
        }
        if (Array.isArray(wts)) {
          wts.forEach((w) => {
            const obj = {
              WEBKEY: w.WEB_KEY,
              SO_NUM: w.SO_NUM,
              WT_NUM: w.WT_NUM,
              ADDRESS: w.ADDRESS,
              ASSIGNED_TECH: w.ASSIGNED_TECH,
              BACKCHARGE_NOTE: w.BACKCHARGE_NOTE,
              BLDR_CONTACT: w.BLDR_CONTACT,
              BLD_CONTACT_NUM: w.BLD_CONTACT_NUM,
              CUR_STATUS_CODE: w.CUR_STATUS_CODE,
              CUR_STATUS_DATE: w.CUR_STATUS_DATE,
              NEW_STATUS: w.NEW_STATUS,
              NEW_STATUS_DATE: w.NEW_STATUS_DATE,
              DATE_SCHEDULED: w.DATE_SCHEDULED,
              DESCRIPTION: w.DESCRIPTION,
              MATERIAL_CHECK: w.MATERIAL_CHECK,
              MAT_NEEDED_DATE: w.MAT_NEEDED_DATE,
              MAT_NEEDED_DESC: w.MAT_NEEDED_DESC,
              PHASE: w.PHASE,
              STATUS_CODE: w.STATUS_CODE,
              TIME_TO_COM: w.TIME_TO_COM,
              WHICH_BUILDER: w.WHICH_BUILDER,
              WHO_IS_PAYING: w.WHO_IS_PAYING,
              SERVICE_CODE: w.SERVICE_CODE,
              NEW_CHARGE_TYPE: w.NEW_CHARGE_TYPE,
              NEW_TIME_TO_COM: w.NEW_TIME_TO_COM,
              NEW_PO: w.NEW_PO,
              USERNAME: w.USERNAME,
              WOMS_APP: w.WOMS_APP,
            };
            // console.log(this.dataModel.steps);
            // console.log(obj.SO_NUM, obj.WT_NUM);
            const findind = this.dataModel.steps.findIndex(x => x.soNum === obj.SO_NUM && x.soWT === obj.WT_NUM);
            console.log(findind);
            if (findind != -1){
              this.dataModel.steps[findind].womsHist.push(obj);
            }
            console.log(this.dataModel.steps[findind].womsHist);
          });
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        this.allTicketTotalCost = 0;
      }
    );
  }

  loadHistory3(step) {
    this.index = this.dataModel.steps.indexOf(step);
    // this.index = index.toString();
    // this.editInterface.show();
    this.loadingStatus = "";
    this.errorMsg = "";
    console.log(this.index);
    if (this.index !== -1){
      this.dataModel.steps[this.index].womsHist = [];
      console.log("success");
    }
    this.sageApi.pullReport("WOMs/History/WT?SalesOrderNo=" + step.soNum + "&WtNum=" + step.soWT).subscribe(
      (wts: Array<any>) => {
        if (wts && Object.keys(wts).length < 1) {
          return;
        }
        if (Array.isArray(wts)) {
          wts.forEach((w) => {
            const obj = {
              WEBKEY: w.WEB_KEY,
              SO_NUM: w.SO_NUM,
              WT_NUM: w.WT_NUM,
              ADDRESS: w.ADDRESS,
              ASSIGNED_TECH: w.ASSIGNED_TECH,
              BACKCHARGE_NOTE: w.BACKCHARGE_NOTE,
              BLDR_CONTACT: w.BLDR_CONTACT,
              BLD_CONTACT_NUM: w.BLD_CONTACT_NUM,
              CUR_STATUS_CODE: w.CUR_STATUS_CODE,
              CUR_STATUS_DATE: w.CUR_STATUS_DATE,
              NEW_STATUS: w.NEW_STATUS,
              NEW_STATUS_DATE: w.NEW_STATUS_DATE,
              DATE_SCHEDULED: w.DATE_SCHEDULED,
              DESCRIPTION: w.DESCRIPTION,
              MATERIAL_CHECK: w.MATERIAL_CHECK,
              MAT_NEEDED_DATE: w.MAT_NEEDED_DATE,
              MAT_NEEDED_DESC: w.MAT_NEEDED_DESC,
              PHASE: w.PHASE,
              STATUS_CODE: w.STATUS_CODE,
              TIME_TO_COM: w.TIME_TO_COM,
              WHICH_BUILDER: w.WHICH_BUILDER,
              WHO_IS_PAYING: w.WHO_IS_PAYING,
              SERVICE_CODE: w.SERVICE_CODE,
              NEW_CHARGE_TYPE: w.NEW_CHARGE_TYPE,
              NEW_TIME_TO_COM: w.NEW_TIME_TO_COM,
              NEW_PO: w.NEW_PO,
              USERNAME: w.USERNAME,
              WOMS_APP: w.WOMS_APP,
            };
            // const findind = this.dataModel.steps.findIndex(x => x.soNum === obj.SO_NUM && x.soWT === obj.WT_NUM);
            // console.log(findind);
            if (this.dataModel.steps[this.index].soNum === obj.SO_NUM && this.dataModel.steps[this.index].soWT === obj.WT_NUM){
            this.dataModel.steps[this.index].womsHist.push(obj);
            }
            console.log(this.dataModel.steps[this.index].womsHist);
          });
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        this.allTicketTotalCost = 0;
      }
    );
  }

  confirmDateRange() {
    if (this.FromDate != undefined && this.ToDate != undefined) {
      if (this.FromDate <= this.ToDate) {
        this.sortByHistory = true;
      } else {
        this.sortByHistory = false;
      }
    } else {
      this.sortByHistory = false;
    }
  }
  getIncludedSteps() {
    this.allTicketTotalCost = 0;
    const isteps = [];
    for (const step of this.dataModel.steps){
      if (this.isStepIncluded(step)){
        isteps.push(step);
        this.allTicketTotalCost += step.amount;
      }
    }
    return isteps;
  }
  isStepIncluded(step){
     const addressIncluded = this.isIncludedInAddress(step);
     const salesOrderIncluded = this.isIncludedInSalesOrderNo(step);
     const phaseIncluded = this.isIncludedInStatus(step);
     const companyIncluded = this.isIncludedInCompanies(step);

    if (
      companyIncluded &&
      phaseIncluded &&
      addressIncluded &&
      salesOrderIncluded
    ){
      return true;
    }
    return false;
  }

isIncludedInAddress(item){
if(item.address.toLowerCase().includes(this.addressSearch.trim().toLowerCase()) ){
  return true;
}
else{
  return false;
}
}
isIncludedInSalesOrderNo(item){
  if(item.wtNumber.toLowerCase().includes(this.salesOrderSearch.trim().toLowerCase()) ){
    return true;
  }
  else{
    return false;
  }
  }

  isIncludedInStatus(isIncludedStep){
    // console.log(this.report.value);
    // console.log(this.reportOptions);
    // console.log(isIncludedStep.statusDescription);
    // console.log(this.report.value.includes(isIncludedStep.statusDescription));
    if (this.report.value.sort().join(',') == this.reportOptions.sort().join(',')){
      return true;
    }
    if (this.report.value.includes(isIncludedStep.statusDescription) ||
      (this.report.value.includes("Canceled Backcharges") && isIncludedStep.statusDescription == "Cancelled For Various Reasons")){
      return true;
    }
    return false;
  }

  isIncludedInCompanies(isIncluded){
    if (this.companies.value.sort().join(',') == this.companiesOptions.sort().join(',')){
      return true;
    }
    if (this.companies.value.includes(isIncluded.builderCompany)){
      return true;
    }
    return false;
  }

  loadCompanies(){
    const newCompanies: string[] = [];
    for(const company of this.dataModel.steps){
      const comp = (company.builderCompany == undefined ? '' : company.builderCompany).trim();
      if(!newCompanies.includes(comp)){
        newCompanies.push(comp);
      }
    }if(newCompanies.sort().join(',') != this.companiesOptions.sort().join(',')){
      this.companiesOptions = newCompanies;
      this.companies.setValue(newCompanies);
    }
    console.log(this.companiesOptions)
  }
}
