<div class="container-fluid py-4" style="height: 85vh">
    <mat-drawer-container class="h-100">
        <mat-drawer mode="side" opened class="p-2 border">

              <ng-container *ngFor="let manager of managers; let managersIndex = index; let isFirst = first;">
                    <div class="border-bottom text-center bg-dark text-light rounded-top" [ngClass]="{ 'mt-2': !isFirst }"> {{ manager.name | titlecase }} </div>

                    <ng-container *ngFor="let group of manager.ticketGroups; let ticketGroupIndex = index; let isLast = last;">
                        <div class="list-group list-group-flush">
                            <button
                                mat-stroked-button
                                class="list-group-item list-group-item-action py-1 border"
                                (click)="ticketGroupSelected(managersIndex, ticketGroupIndex)"
                                [ngClass]="{'bg-primary text-white': group == selectedGroup}"
                            >
                                {{ group.name | titlecase }}
                            </button>
                        </div>
                    </ng-container>
              </ng-container>

        </mat-drawer>
        <mat-drawer-content>
            <mat-card style="min-height: 100%;">
                <mat-card-header>
                    <mat-card-title>
                        {{ selectedGroup?.name | titlecase }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-form-field class="w-50">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Crew" #input>
                    </mat-form-field>
                    <mat-form-field class="ml-2 float-right">
                        <mat-label>Status</mat-label>
                        <mat-select (selectionChange)="applyColumnFilter($event.value)" multiple>
                        <mat-option value="All Statuses">All Statuses</mat-option>
                        <ng-container *ngFor="let status of statusList">
                            <mat-option value="{{ status }}">{{ status }}</mat-option>
                        </ng-container>
                        <mat-divider></mat-divider>
                        </mat-select>
                    </mat-form-field>
                        <div class="mat-elevation-z8">
                            <table mat-table [dataSource]="dataSource" class="w-100" matSort>
    
                                <!-- Position Column -->
                                <ng-container matColumnDef="wtNumber">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
                                    <td mat-cell *matCellDef="let element"> {{ element.wtNumber }} </td>
                                </ng-container>
                            
                                <!-- Name Column -->
                                <ng-container matColumnDef="address">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
                                    <td mat-cell *matCellDef="let element"> {{ element.address }} </td>
                                </ng-container>
                            
                                <!-- Weight Column -->
                                <ng-container matColumnDef="type">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                                    <td mat-cell *matCellDef="let element"> {{ element.type }} </td>
                                </ng-container>
                            
                                <!-- Symbol Column -->
                                <ng-container matColumnDef="assigned tech">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Assigned Tech </th>
                                    <td mat-cell *matCellDef="let element"> {{ element.assignedTech }} </td>
                                </ng-container>
    
                                <!-- Status Column -->
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                    <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
                                </ng-container>
    
                                <!-- Action Column -->
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                                    <td mat-cell *matCellDef="let element"> 
                                        <button mat-icon-button (click)="ticketSelected(element)" >
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                            
                                <tr mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: true;"></tr>
                                <tr mat-row *matRowDef="let row; columns: tableDisplayedColumns;"></tr>
    
                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td *ngIf="filteredValues.status === ''" class="mat-cell" colspan="5">No data matching the filter "{{ input.value }}"</td>
                                    <td *ngIf="filteredValues.status !== ''" class="mat-cell" colspan="5">No data matching the filter "{{ input.value }}" and has status of "{{ filteredValues.status | titlecase }}"</td>
                                </tr>
                            </table>   
                            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                        </div>
                </mat-card-content>
            </mat-card>
        </mat-drawer-content>
      </mat-drawer-container>
</div>
