<ng-container *ngIf="selectedOption==null">
  <div class="qops-add">
    <button (click)="addOption()" mat-fab class="qops-add-btn">
      <i class="fas fa-plus"></i>
    </button>
  </div>
  
  <div class="qops-ops">
    <ng-container *ngFor="let op of options">
      <mat-card class="qops-ops-card">
        <h3 class="qops-ops-card-name">
          {{op.data.QuoteOption_Name}}
        </h3>
        <button mat-mini-fab color="primary" (click)="selectedOption=op" class="qops-ops-card-edit"><i class="fas fa-cog"></i></button>
      </mat-card>
    </ng-container>
  </div>
  
</ng-container>

<ng-container *ngIf="selectedOption!=null">
  <button (click)="selectedOption=null" mat-raised-button color="primary" class="qops-back">Back</button>
  <app-qoption (onDelete)="selectedOption=null" [option]="selectedOption"></app-qoption>
</ng-container>