<h1 mat-dialog-title class="text-center">
    <mat-icon class="text-success" style="font-size: 100px; width: 100px; height: 100px;">verified</mat-icon> <br>
    All good?
</h1>
<div mat-dialog-content class="text-center">
    <div>    
        Do you want to approve
        Invoice {{ data.invoice.invoiceNo }} for PO {{ data.invoice.purchaseOrderNo }}?
    </div>
    <div class="overflow-auto" style="height: 150px;">
        <mat-list dense *ngIf="data.differences.length > 0">
            <div mat-subheader>
                <span [matBadge]="data.differences.length" matBadgeSize="small" matBadgeOverlap="false">Approved Item Differences</span>
            </div>
            <mat-list-item *ngFor="let line of data.differences; let differenceIndex = index;">
                <div mat-line>
                    <mat-icon mat-list-icon> inventory </mat-icon>
                    {{ line.itemCode }} - Invoiced: {{ line.newQuantityInvoiced }} Cost: {{ line.newUnitCost }}
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</div>
<div mat-dialog-actions>
    <div class="text-center w-100">
        <button mat-stroked-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="success" (click)="confirmApprove()">Approve</button>
    </div>
</div>