import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { concat } from 'rxjs';

interface UnshippedItemInterface {
  lineKey: string;
  quantityShipped: number;
}

interface UnshippedItemsInterface {
  salesOrderNo: string;
  lines: UnshippedItemInterface[];
}

interface UnshippedItemsListInterface {
  itemCode: string;
  warehouseCode: string;
  quantityNeeded: number;
  quantityOnHand: number;
  quantityDifference: number;
  updated: boolean;
  updating: boolean;
  orders: UnshippedItemsInterface[];
}

@Component({
  selector: 'app-unshipped-items',
  templateUrl: './unshipped-items.component.html',
  styleUrls: ['./unshipped-items.component.css']
})
export class UnshippedItemsComponent implements OnInit, AfterViewInit {
  unshippedItemsLoading: boolean;
  unshippedItemsList: UnshippedItemsListInterface[] = [];
  warehouseCode = '001';
  completedTickets = true;

  warehouseList = [
    {
      WarehouseCode: '001',
      WarehouseDesc: 'Main Warehouse'
    },
    {
      WarehouseCode: '002',
      WarehouseDesc: 'Buda Warehouse'
    },
    {
      WarehouseCode: '003',
      WarehouseDesc: 'Multi-Family - Direct Ship'
    },
    {
      WarehouseCode: '004',
      WarehouseDesc: 'Multi-Family - Ship to SA Whse'
    },
    {
      WarehouseCode: '005',
      WarehouseDesc: 'Dallas Residential'
    }
  ];

  tableDisplayedColumns: string[] = ['itemCode', 'quantityNeeded', 'quantityOnHand', 'quantityDifference', 'action'];
  dataSource: MatTableDataSource<UnshippedItemsListInterface> | null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.loadUnshippedItemsList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  refreshUnshippedItemsList() {
    this.loadUnshippedItemsList();
  }

  refreshUnshippedItem(element: any) {
    const endpoint = this.completedTickets ?
                          'UnshippedItems?WarehouseCode=' + this.warehouseCode + '&ItemCode=' + element.itemCode :
                          'UnshippedItems/Open?WarehouseCode=' + this.warehouseCode + '&ItemCode=' + element.itemCode;

    this.sageApi.pullReport(endpoint).subscribe((items: Array<any>) => {
      console.log(items);
      element.orders = [];

      items.forEach(item => {
        element.quantityNeeded = item.QuantityNeeded;
        element.quantityOnHand = item.TotalQuantityOnHand;
        element.quantityDifference = item.TotalQuantityOnHand - item.QuantityNeeded;

        const ordersIndex = element.orders.findIndex(x => x.salesOrderNo === item.SalesOrderNo);

        if (ordersIndex >= 0) {
          // add item to order
          const linesObj: UnshippedItemInterface = {
            lineKey: item.LineKey,
            quantityShipped: item.QuantityOrdered
          };

          element.orders[ordersIndex].lines.push(linesObj);
        } else {
          // add order and item
          const ordersObj: UnshippedItemsInterface = {
            salesOrderNo: item.SalesOrderNo,
            lines: []
          };

          const linesObj: UnshippedItemInterface = {
            lineKey: item.LineKey,
            quantityShipped: item.QuantityOrdered
          };

          ordersObj.lines.push(linesObj);
          element.orders.push(ordersObj);
        }
      });

      if (items.length === 0) {
        element.quantityNeeded = 0;
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  shipItem(element: any) {
    console.log(element);
    element.updating = true;
    const endpoint = 'UnshippedItems/Update';
    const observables = [];
    const body = {
      Username: this.authService.decodedToken?.nameid.toLocaleLowerCase(),
      Password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      Orders: element.orders
    };

    observables.push(this.sageApi.putRequest(endpoint, body));

    concat(...observables).subscribe((response: any) => {
      console.log('response', response);
      if (response.Response.ReasonPhrase !== '') {
        this.alertify.message('Successful with the following message ' + response.Response.ReasonPhrase, 0);
      } else {
        this.alertify.success('Success');
      }
    }, err => {
      console.log(err);
      element.updating = false;
      this.alertify.error(err.error.text);
    }, () => {
      this.refreshUnshippedItem(element);
      element.updating = false;
      element.updated = true;
    });

  }

  loadUnshippedItemsList() {
    this.unshippedItemsLoading = true;
    this.unshippedItemsList = [];
    const endpoint = this.completedTickets ?
                          'UnshippedItems?WarehouseCode=' + this.warehouseCode :
                          'UnshippedItems/Open?WarehouseCode=' + this.warehouseCode;

    this.sageApi.pullReport(endpoint).subscribe((items: Array<any>) => {
      if (Array.isArray(items)) {
        items.forEach(item => {
          if (!this.unshippedItemsList.some(x => x.itemCode === item.ItemCode)) {
            const itemCodeObj: UnshippedItemsListInterface = {
              itemCode: item.ItemCode,
              warehouseCode: item.WarehouseCode,
              quantityNeeded: item.QuantityNeeded,
              quantityOnHand: item.TotalQuantityOnHand,
              quantityDifference: item.TotalQuantityOnHand - item.QuantityNeeded,
              updated: false,
              updating: false,
              orders: []
            };

            const ordersObj: UnshippedItemsInterface = {
              salesOrderNo: item.SalesOrderNo,
              lines: []
            };

            const linesObj: UnshippedItemInterface = {
              lineKey: item.LineKey,
              quantityShipped: item.QuantityOrdered
            };

            ordersObj.lines.push(linesObj);

            itemCodeObj.orders.push(ordersObj);

            this.unshippedItemsList.push(itemCodeObj);
          } else {
            const unshippedItemsListIndex = this.unshippedItemsList.findIndex(x => x.itemCode === item.ItemCode);
            const ordersIndex = this.unshippedItemsList[unshippedItemsListIndex].orders.findIndex(x => x.salesOrderNo === item.SalesOrderNo);

            if (ordersIndex >= 0) {
              // add item to order
              const linesObj: UnshippedItemInterface = {
                lineKey: item.LineKey,
                quantityShipped: item.QuantityOrdered
              };

              this.unshippedItemsList[unshippedItemsListIndex].orders[ordersIndex].lines.push(linesObj);
            } else {
              // add order and item
              const ordersObj: UnshippedItemsInterface = {
                salesOrderNo: item.SalesOrderNo,
                lines: []
              };

              const linesObj: UnshippedItemInterface = {
                lineKey: item.LineKey,
                quantityShipped: item.QuantityOrdered
              };

              ordersObj.lines.push(linesObj);
              this.unshippedItemsList[unshippedItemsListIndex].orders.push(ordersObj);
            }
          }

        });
      }
    }, err => {
      console.log(err);
      this.unshippedItemsLoading = false;
      this.alertify.error(err.message);
    }, () => {
      console.log(this.unshippedItemsList);
      this.unshippedItemsLoading = false;
      this.dataSource = new MatTableDataSource(this.unshippedItemsList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
