<app-bottom-sheet
    [open]="open && quoteQuestion != null"
    (sheetClosed)="sheetClosed.emit($event)"
    [sheetWidth]="screenSize.getClassesArray().includes('smd')? '100vw' : '75vw'"
    [class]="screenSize.getClasses() + ' createqqa'"
  >
    <h1>
      Create Answer
      <mat-spinner
        *ngIf="loading"
        [diameter]="30"
        class="createqqa-spinner"
      ></mat-spinner>
    </h1>
    <div class="createqqa-controls">
      <mat-form-field
        appearance="outline"
        class="createqqa-ff createqqa-name"
      >
        <mat-label>Name</mat-label>
        <input
          [formControl]="nameControl"
          [disabled]="loading"
          matInput
          type="text"
          class="createqqa-ff-in"
        />
      </mat-form-field>
      
      <app-korp-picker
        [control]="kitOrPartSelectedControl"
        [typeControl]="typeControl"
      >
      </app-korp-picker>
      
      <mat-form-field
        appearance="outline"
        class="createqqa-ff createqqa-desc"
      >
        <mat-label>Description</mat-label>
        <textarea
          [formControl]="descControl"
          [disabled]="loading"
          matInput
          type="text"
          class="createqqa-ff-in"
        ></textarea>
      </mat-form-field>
    </div>
    
    <span *ngIf="errorMsg != null" class="createqqa-error"
      >*{{errorMsg}}</span
    >

    <div class="createqqa-actions">
      <button
        (click)="createQuoteQuestionAnswer()"
        [disabled]="!valid()"
        color="primary"
        mat-flat-button
        class="createqqa-actions-submit"
      >
        Create Answer
      </button>
    </div>
  </app-bottom-sheet>