import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import KitPhases from '../KitPartPhases';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import ContextQuoteQuestionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteQuestionDtoInterface';
import QuoteQuestionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteQuestionDtoInterface';
import QuoteQuestionTypes from '../QuoteQuestionTypes';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import ContextQuoteQuestionAnswerDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteQuestionAnswerDtoInterface';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

interface QuoteQuestionsInterface {
  data: ContextQuoteQuestionDtoInterface;
  questionSaving: boolean;
  questionNameControl: FormControl;
  questionDescControl: FormControl;
  questionTypeControl: FormControl;
  answersSaving: boolean[];
  answersNameControl: FormControl[];
  answersDescControl: FormControl[];
  answersKitOrPartControl: FormControl[]; // FormControl<KitOverviewDtoInterface|PartDtoInterface>[]
  answersPartPhaseControl: FormControl[]; // FormControl<string|null>[]
}

@Component({
  selector: 'app-qqeditorview',
  templateUrl: './QuoteQuestionsEditorViewComponent.html',
  styleUrls: ['./QuoteQuestionsEditorViewComponent.css', '../QuotingTheme.scss'],
})
export class QuoteQuestionsEditorViewComponent implements OnInit, OnDestroy {
  
  loading = false;

  quoteQuestions: QuoteQuestionsInterface[] = [];

  qqTypes = QuoteQuestionTypes;

  newQuoteQuestionOpen = false;
  newQuoteQuestionLoading = false;
  newQuoteQuestionErrorMsg: string|null = null;
  newQuoteQuestionNameControl = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]);
  newQuoteQuestionDescControl = new FormControl('', [Validators.maxLength(255)]);
  newQuoteQuestionTypeControl = new FormControl('', [Validators.required]);

  quoteQuestionTypes = QuoteQuestionTypes;
  kitPartPhases = KitPhases;

  newQuoteQuestionAnswerIndex: number = -1;

  newQuoteAnswerOpen = false;

  constructor(
    public screenSize: ScreenSizeService,
    public api: SageApiService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}
  
  ngOnInit(): void {
    this.getQuoteQuestions();
  }

  ngOnDestroy(): void {}

  getQuoteQuestions(){
    this.loading = true;
    this.api.pullReport('quote-questions').subscribe((data: ContextQuoteQuestionDtoInterface[])=>{
      this.loading = false;
      this.quoteQuestions = [];
      for(let q of data){
        const answersSaving: boolean[] = [];
        const answerNameControls: FormControl[] = [];
        const answerDescControls: FormControl[] = [];
        const answersKitOrPartControl: FormControl[] = [];
        const answersPartPhaseControl: FormControl[] = [];

        for(let a of q.QuoteQuestionAnswers){
          answersSaving.push(false);
          answerNameControls.push(new FormControl(a.QuoteQuestionAnswer.QuoteQuestionAnswer_Name, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]));
          answerDescControls.push(new FormControl(a.QuoteQuestionAnswer.QuoteQuestionAnswer_Desc, [Validators.maxLength(255)]));
          
          if('Kit' in a && a.Kit != null){
            answersKitOrPartControl.push(new FormControl(a.Kit, [Validators.required]));
            answersPartPhaseControl.push(new FormControl(null, []));
          } else if('Part' in a && a.Part != null) {
            answersKitOrPartControl.push(new FormControl(a.Part, [Validators.required]));
            answersPartPhaseControl.push(new FormControl(a.QuoteQuestionAnswer.QuoteQuestionAnswer_Phase, [Validators.required]));
          }else{
            answersKitOrPartControl.push(new FormControl('', [Validators.required])); // Weird shit has to happen to get here
            answersPartPhaseControl.push(new FormControl(null, []));
          }
        }

        this.quoteQuestions.push({
          data: q,
          questionSaving: false,
          questionNameControl: new FormControl(q.QuoteQuestion.QuoteQuestion_Name, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]),
          questionDescControl: new FormControl(q.QuoteQuestion.QuoteQuestion_Desc, [Validators.maxLength(255)]),
          questionTypeControl: new FormControl(q.QuoteQuestion.QuoteQuestion_Type, [Validators.required]),
          answersSaving: answersSaving,
          answersNameControl: answerNameControls,
          answersDescControl: answerDescControls,
          answersKitOrPartControl: answersKitOrPartControl,
          answersPartPhaseControl: answersPartPhaseControl
        });
      }
    },
    (err)=>{
      this.loading = false;
      console.log(err);
      this.snackBar.open('Failed to load quote questions. Ask IT if this persists', 'Close', {duration: Infinity});
    }
    );
  }

  createQuoteQuestion(){
    const newQuestion = {
      QuoteQuestion_Name: this.newQuoteQuestionNameControl.value,
      QuoteQuestion_Desc: this.newQuoteQuestionDescControl.value,
      QuoteQuestion_Type: this.newQuoteQuestionTypeControl.value,
    }
    this.api.postRequest('quote-question', newQuestion).subscribe((data: QuoteQuestionDtoInterface)=>{
      // Create a new QuoteQuestionsInterface from the data and add it to the quoteQuestions array
      const newQuestion: QuoteQuestionsInterface = {
        data: {
          QuoteQuestion: data,
          QuoteQuestionAnswers: []
        },
        questionSaving: false,
        questionNameControl: new FormControl(data.QuoteQuestion_Name, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]),
        questionDescControl: new FormControl(data.QuoteQuestion_Desc, [Validators.maxLength(255)]),
        questionTypeControl: new FormControl(data.QuoteQuestion_Type, [Validators.required]),
        answersSaving: [],
        answersNameControl: [],
        answersDescControl: [],
        answersKitOrPartControl: [],
        answersPartPhaseControl: []
      };
      this.quoteQuestions.push(newQuestion);

      this.newQuoteQuestionOpen = false;
    }, (err)=>{
      this.newQuoteQuestionLoading = false;
      this.newQuoteQuestionErrorMsg = err.error;
    });
  }

  questionNameControlInput(inputEvent, nameIndex){
    const newName = inputEvent.target.value;
    this.quoteQuestions[nameIndex].questionNameControl.setValue(newName);
  }

  questionNameControlBlur(blurEvent, nameIndex){
    const newName = blurEvent.target.value.trim();
    if(newName.length === 0){
      this.quoteQuestions[nameIndex].questionNameControl.setValue('Untitled');
    }else{
      this.quoteQuestions[nameIndex].questionNameControl.setValue(newName);
    }
  }

  isQuestionSaveButtonDisabled(questionIndex){
    const question = this.quoteQuestions[questionIndex];
    // Should be disabled if the controls match their data
    const isDataMatching = question.questionNameControl.value === question.data.QuoteQuestion.QuoteQuestion_Name &&
      question.questionDescControl.value === question.data.QuoteQuestion.QuoteQuestion_Desc &&
      question.questionTypeControl.value === question.data.QuoteQuestion.QuoteQuestion_Type;

    const isInvalid = question.questionNameControl.invalid || question.questionDescControl.invalid || question.questionTypeControl.invalid;
    
    if(isDataMatching || isInvalid || question.questionSaving){
      return true;
    }
    return false;
  }

  isQuestionCancelButtonDisabled(questionIndex){
    const question = this.quoteQuestions[questionIndex];
    const isDataMatching = question.questionNameControl.value === question.data.QuoteQuestion.QuoteQuestion_Name &&
      question.questionDescControl.value === question.data.QuoteQuestion.QuoteQuestion_Desc &&
      question.questionTypeControl.value === question.data.QuoteQuestion.QuoteQuestion_Type;
    return isDataMatching && !question.questionSaving;
  }

  resetQuestionControls(questionIndex){
    const question = this.quoteQuestions[questionIndex];
    question.questionNameControl.setValue(question.data.QuoteQuestion.QuoteQuestion_Name);
    question.questionDescControl.setValue(question.data.QuoteQuestion.QuoteQuestion_Desc);
    question.questionTypeControl.setValue(question.data.QuoteQuestion.QuoteQuestion_Type);
  }

  saveQuestionChanges(questionIndex){
    const question = this.quoteQuestions[questionIndex];
    question.questionSaving = true;
    this.api.patchRequest(`quote-question/${question.data.QuoteQuestion.QuoteQuestion_guid}`, {
      QuoteQuestion_Name: question.questionNameControl.value,
      QuoteQuestion_Desc: question.questionDescControl.value,
      QuoteQuestion_Type: question.questionTypeControl.value,
    }).subscribe((data: QuoteQuestionDtoInterface)=>{
      question.data.QuoteQuestion = data;
      question.questionSaving = false;
      this.resetQuestionControls(questionIndex);
    },
    (err)=>{
      console.log(err);
      this.snackBar.open('Can\'t save changes to the answer. It may have been delete?', 'Close', {duration: Infinity});
    }
    );
  }

  destroyQuestionChanges(questionIndex){
    const question = this.quoteQuestions[questionIndex];
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {title: `Really delete ${question.data.QuoteQuestion.QuoteQuestion_Name}?`, message: `Are you sure you want to delete ${question.data.QuoteQuestion.QuoteQuestion_Name}?`}
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result){
        this.api.deleteRequest(`quote-question/${question.data.QuoteQuestion.QuoteQuestion_guid}`).subscribe(()=>{
          this.quoteQuestions.splice(questionIndex, 1);
        },
        (err)=>{
          console.log(err);
          this.snackBar.open('Can\'t delete this question. It may have already been delete?', 'Close', {duration: Infinity});
        }
        );
      }
    });
  }

  answerNameControlInput(inputEvent, questionindex, answerIndex){
    const newName = inputEvent.target.value;
    this.quoteQuestions[questionindex].answersNameControl[answerIndex].setValue(newName);
  }

  answerNameControlBlur(blurEvent, questionIndex, answerIndex){
    const newName = blurEvent.target.value.trim();
    if(newName.length === 0){
      this.quoteQuestions[questionIndex].answersNameControl[answerIndex].setValue('Untitled');
    }else{
      this.quoteQuestions[questionIndex].answersNameControl[answerIndex].setValue(newName);
    }
  }

  isAnswerCancelButtonDisabled(questionIndex, answerIndex){
    const question = this.quoteQuestions[questionIndex];
    const answer = question.data.QuoteQuestionAnswers[answerIndex];
    const name = question.answersNameControl[answerIndex];
    const desc = question.answersDescControl[answerIndex];
    const kitOrPart = question.answersKitOrPartControl[answerIndex];
    const partPhase = question.answersPartPhaseControl[answerIndex];

    // if the part is null and the phase is equal to the answer's phase, then the phaseInvalid should be true
    let phaseInvalid = false;
    if(
      typeof kitOrPart.value == 'object' 
      && 'Part_guid' in kitOrPart.value 
      && partPhase.value == answer.QuoteQuestionAnswer.QuoteQuestionAnswer_Phase
    ){
      phaseInvalid = true;
    }
    // If the kitOrPart is a kit, then the phaseInvalid should be true because we don't care about the phase
    if(
      typeof kitOrPart.value == 'object' 
      && 'Kit_guid' in kitOrPart.value 
    ){
      phaseInvalid = true;
    }

    const isDataMatching = 
      name.value === answer.QuoteQuestionAnswer.QuoteQuestionAnswer_Name 
      && desc.value === answer.QuoteQuestionAnswer.QuoteQuestionAnswer_Desc 
      && (kitOrPart.value == answer.Kit || kitOrPart.value == answer.Part)
      // && phaseInvalid;
    return isDataMatching && !question.answersSaving[answerIndex];
  }

  isAnswerSaveButtonDisabled(questionIndex, answerIndex){
    const question = this.quoteQuestions[questionIndex];
    const answer = question.data.QuoteQuestionAnswers[answerIndex];

    const name = question.answersNameControl[answerIndex];
    const desc = question.answersDescControl[answerIndex];
    const kitOrPart = question.answersKitOrPartControl[answerIndex];
    const partPhase = question.answersPartPhaseControl[answerIndex];
    const isDataMatching = 
      // Check that the name matches
      name.value === answer.QuoteQuestionAnswer.QuoteQuestionAnswer_Name 
      // Check that the description matches
      && desc.value === answer.QuoteQuestionAnswer.QuoteQuestionAnswer_Desc 
      // Check that either Part or Kit are set
      && (kitOrPart.value == answer.Kit || kitOrPart.value == answer.Part)
      // Check the partPhase
      // && (partPhase?.value != null && kitOrPart.value?.Part != null); // If part is set, the phase needs to be set too;
    const isInvalid = name.invalid;
    if(isDataMatching || isInvalid || question.answersSaving[answerIndex]){
      return true;
    }
    return false;
  }

  resetAnswerControls(questionIndex, answerIndex){
    const question = this.quoteQuestions[questionIndex];
    const answer = question.data.QuoteQuestionAnswers[answerIndex];
    this.quoteQuestions[questionIndex].answersNameControl[answerIndex].setValue(answer.QuoteQuestionAnswer.QuoteQuestionAnswer_Name);
    this.quoteQuestions[questionIndex].answersDescControl[answerIndex].setValue(answer.QuoteQuestionAnswer.QuoteQuestionAnswer_Desc);
    this.quoteQuestions[questionIndex].answersKitOrPartControl[answerIndex].setValue(answer.Kit || answer.Part);
    this.quoteQuestions[questionIndex].answersPartPhaseControl[answerIndex].setValue(null);
    if(answer.Part != null){
      const phase = answer.QuoteQuestionAnswer.QuoteQuestionAnswer_Phase;
      this.quoteQuestions[questionIndex].answersPartPhaseControl[answerIndex].setValue(phase);
    }
  }

  saveAnswerChanges(questionIndex, answerIndex){
    const question = this.quoteQuestions[questionIndex];
    const answer = question.answersNameControl[answerIndex];
    question.answersSaving[answerIndex] = true;

    const patchData = {
      QuoteQuestionAnswer_Name: answer.value,
      QuoteQuestionAnswer_Desc: question.answersDescControl[answerIndex].value
    };
    
    if('Kit_guid' in question.answersKitOrPartControl[answerIndex].value){
      patchData['kit'] = question.answersKitOrPartControl[answerIndex].value.Kit_guid;
    }
    if(question.answersKitOrPartControl[answerIndex].value?.Part?.Part_guid != null){
      patchData['part'] = question.answersKitOrPartControl[answerIndex].value.Part.Part_guid;
    }

    if(question?.answersPartPhaseControl[answerIndex]?.value != null){
      patchData['QuoteQuestionAnswer_Phase'] = question.answersPartPhaseControl[answerIndex].value;
    }
    this.api.patchRequest(`quote-question-answer/${question.data.QuoteQuestionAnswers[answerIndex].QuoteQuestionAnswer.QuoteQuestionAnswer_guid}`, 
      patchData
    ).subscribe((data: ContextQuoteQuestionAnswerDtoInterface)=>{
      question.data.QuoteQuestionAnswers[answerIndex] = data;
      question.answersSaving[answerIndex] = false;
      this.resetAnswerControls(questionIndex, answerIndex);
    },
    (err)=>{
      console.log(err);
      this.snackBar.open('Can\'t save changes to the answer. It may have been delete?', 'Close', {duration: Infinity});
    }
    );
  }

  destroyAnswer(questionIndex, answerIndex){
    const question = this.quoteQuestions[questionIndex];
    const answer = question.data.QuoteQuestionAnswers[answerIndex];
    
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {title: `Really delete ${answer.QuoteQuestionAnswer.QuoteQuestionAnswer_Name}?`, message: `Are you sure you want to delete ${answer.QuoteQuestionAnswer.QuoteQuestionAnswer_Name}?`}
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result){
        this.api.deleteRequest(`quote-question-answer/${answer.QuoteQuestionAnswer.QuoteQuestionAnswer_guid}`).subscribe((data)=>{
          question.data.QuoteQuestionAnswers.splice(answerIndex, 1);
          question.answersNameControl.splice(answerIndex, 1);
          question.answersDescControl.splice(answerIndex, 1);
          question.answersKitOrPartControl.splice(answerIndex, 1);
          question.answersPartPhaseControl.splice(answerIndex, 1);
          question.answersSaving.splice(answerIndex, 1);
        },
        (err)=>{
          console.log(err);
          this.snackBar.open('Can\'t delete the answer. It may have already been delete?', 'Close', {duration: Infinity});
        });
      }
    });
  }

  resetNewQuoteQuestionControls(){
    this.newQuoteQuestionErrorMsg = null;
    this.newQuoteQuestionNameControl = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]);
    this.newQuoteQuestionDescControl = new FormControl('', [Validators.maxLength(255)]);
    this.newQuoteQuestionTypeControl = new FormControl('', [Validators.required]);
  }

  openNewQuoteQuestionSheet(){
    
    this.resetNewQuoteQuestionControls();
    this.newQuoteQuestionOpen = true;
  }

  newQuoteQuestionValid(){
    return this.newQuoteQuestionNameControl.valid && this.newQuoteQuestionDescControl.valid && this.newQuoteQuestionTypeControl.valid && this.newQuoteQuestionLoading == false;
  }

  answerCreated(newAnswer: ContextQuoteQuestionAnswerDtoInterface){
    // Create the controls for the new answer and add them to the array
    const newaNameControl = new FormControl(newAnswer.QuoteQuestionAnswer.QuoteQuestionAnswer_Name, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]);
    this.quoteQuestions[this.newQuoteQuestionAnswerIndex].answersNameControl.push(newaNameControl);
    const newaDescControl = new FormControl(newAnswer.QuoteQuestionAnswer.QuoteQuestionAnswer_Desc, [Validators.maxLength(255)]);
    this.quoteQuestions[this.newQuoteQuestionAnswerIndex].answersDescControl.push(newaDescControl);
    let newaKitOrPartControl: FormControl;
    if('Kit' in newAnswer && newAnswer.Kit != null){
      newaKitOrPartControl = new FormControl(newAnswer.Kit, [Validators.required]);
    } else if('Part' in newAnswer && newAnswer.Part != null) {
      newaKitOrPartControl = new FormControl(newAnswer.Part, [Validators.required]);
    }
    this.quoteQuestions[this.newQuoteQuestionAnswerIndex].answersKitOrPartControl.push(newaKitOrPartControl);

    let newaPartPhaseControl: FormControl;
    if('Part' in newAnswer && newAnswer.Part != null){
      newaPartPhaseControl = new FormControl(newAnswer.QuoteQuestionAnswer.QuoteQuestionAnswer_Phase, [Validators.required]);
    }
    this.quoteQuestions[this.newQuoteQuestionAnswerIndex].answersPartPhaseControl.push(newaPartPhaseControl);

    this.quoteQuestions[this.newQuoteQuestionAnswerIndex].answersSaving.push(false);

    this.quoteQuestions[this.newQuoteQuestionAnswerIndex].data.QuoteQuestionAnswers.push(newAnswer);

    this.newQuoteAnswerOpen = false;
  }

}