<h1 mat-dialog-title>Edit: {{techInfo.FirstName}} {{techInfo.LastName}}</h1>
<div mat-dialog-content class="form">
  <mat-checkbox [value]="techInfo.assignablePunch" color="primary" [(ngModel)]="techInfo.assignablePunch">Active</mat-checkbox>

  <!-- <mat-form-field>
    <mat-label>First Name</mat-label>
    <input matInput [(ngModel)]="techInfo.FirstName">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Last Name</mat-label>
    <input matInput [(ngModel)]="techInfo.LastName">
  </mat-form-field> -->
  <mat-form-field>
    <mat-label>SubVendor</mat-label>
    <input matInput [(ngModel)]="techInfo.SubVendor" (typeaheadOnBlur)="selectVendor($event)" (typeaheadOnSelect)="selectVendor($event)" [typeahead]="vendorInformation" typeaheadOptionField="Name">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Schedule Group</mat-label>
    <input matInput [(ngModel)]="techInfo.ScheduleGroup" [typeahead]="scheduleGroup">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Email</mat-label>
    <input matInput [(ngModel)]="techInfo.email">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Default Warehouse</mat-label>
    <input matInput [(ngModel)]="techInfo.defaultWarehouse"  [typeahead]="defaultWarehouse">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Current Status</mat-label>
    <input matInput [(ngModel)]="techInfo.currentStatus" [typeahead]="statusList" [typeaheadMinLength]="0" >
  </mat-form-field>
  <mat-checkbox [value]="techInfo.PRT" color="primary"
  [(ngModel)]="techInfo.PRT">PRT</mat-checkbox>

  <div *ngIf="techInfo.PRT == true" class="PRT">
    <div>
      <mat-form-field>
        <mat-label>PRT Crew Lead</mat-label>
        <input matInput [(ngModel)]="techInfo.PRTCrewLead" typeaheadOptionsLimit="5" [dropup]="true"
        (typeaheadOnBlur)="selectSplit($event,  'PRTCrewLead')" (typeaheadOnSelect)="selectSplit($event,  'PRTCrewLead')"
        [typeahead]="techList" typeaheadOptionField="Name">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Split</mat-label>
        <input matInput [(ngModel)]="techInfo.PRTCrewLeadSplit" type="number">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>PRT Crew Emp1</mat-label>
        <input matInput [(ngModel)]="techInfo.PRTCrew1" [dropup]="true" typeaheadOptionsLimit="5"
        (typeaheadOnBlur)="selectSplit($event,  'PRTCrew1')" (typeaheadOnSelect)="selectSplit($event,  'PRTCrew1')"
        [typeahead]="techList" typeaheadOptionField="Name">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Split</mat-label>
        <input matInput [(ngModel)]="techInfo.PRTCrew1Split"  type="number">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>PRT Crew Emp2</mat-label>
        <input matInput [(ngModel)]="techInfo.PRTCrew2" typeaheadOptionsLimit="5"
        (typeaheadOnBlur)="selectSplit($event,  'PRTCrew2')" [dropup]="true" (typeaheadOnSelect)="selectSplit($event,  'PRTCrew2')"
        [typeahead]="techList" typeaheadOptionField="Name">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Split</mat-label>
        <input matInput [(ngModel)]="techInfo.PRTCrew2Split"  type="number">
      </mat-form-field>
    </div>
  </div>
  <!-- <mat-form-field>
    <mat-label>Super</mat-label>
    <mat-select [(ngModel)]="Super">
      <ng-container *ngFor="let super of techList">
        <mat-option [value]="super.UDF_LEADMAN">{{ super.Leadman | titlecase }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field> -->

  <!-- <mat-checkbox [value]="tnrcc" color="primary" [(ngModel)]="tnrcc">TNRCC Required</mat-checkbox> -->

</div>
<div mat-dialog-actions>
  <button mat-button color="warn" mat-flat-button (click)="onNoClick()">Exit</button>

  <button mat-button color="success" mat-flat-button [disabled]="validators() || sending"
  matTooltip='{{toolTip}}' (click)="checkData()"><mat-icon *ngIf="sending">sync</mat-icon>Submit</button>
</div>
