<form [formGroup]="planForm" (ngSubmit)="addPlan($event)">
    <div class="bg-dark text-light rounded px-2">
        <h1 mat-dialog-title>Create New Plan</h1>
    </div>
    
    <mat-dialog-content>
        <div>
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Enter Plan Name</mat-label>
                <input matInput placeholder="Plan Name" formControlName="Name" required>
                <mat-error *ngIf="planForm.get('Name').invalid">Name Required</mat-error>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Enter Description</mat-label>
                <input matInput placeholder="New Plan" formControlName="Description" required>
                <mat-error *ngIf="planForm.get('Description').invalid">Description Required</mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    
    <mat-divider></mat-divider>
    
    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close>Cancel</button>
        <button type="submit" mat-raised-button color="success" [disabled]="isAddPlanLoading">
            {{ isAddPlanLoading ? 'Creating...' : 'Create' }}
        </button>
    </mat-dialog-actions>
</form>
