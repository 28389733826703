import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import ContextKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitDtoInterface';
import { FormControl, Validators } from '@angular/forms';
import KitPartPhases from '../KitPartPhases';
import QuoteOptionKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOptionKitPartDtoInterface';
import QuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuotePartDtoInterface';
import QuoteOptionKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOptionKitDtoInterface';
import QuoteOptionEditableInterface, { QuoteOptionKitPartEditableInterface } from '../../../_services/QuoteEditableService/interfaces/QuoteOptionEditableInterface';
import QuoteOptionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOptionDtoInterface';
import { PostAddToQuoteOptionInterface, QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';
import { AddItemsOutputInterface } from '../KitOrPartPickerBotSheetComponent/KitOrPartPickerBotSheetComponent';

@Component({
  selector: 'app-qoptions',
  templateUrl: './QuoteOptionsComponent.html',
  styleUrls: ['./QuoteOptionsComponent.css', '../QuotingTheme.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteOptionsComponent implements OnInit, OnChanges {

  @Input() options: QuoteOptionEditableInterface[];

  selectedOption: QuoteOptionEditableInterface|null = null;
  
  constructor(
    public api: SageApiService,
    public qe: QuoteEditableService,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ){}

  ngOnInit() {
    this.qe.updated.subscribe(()=>{
      this.cdr.detectChanges();
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    
  }

  addOption(){
    this.qe.addQuoteOption({newOption: {
      QuoteOption_Name: 'Untitled Option',
      QuoteOption_Desc: '',
    }});
  }
}
