<div class="card">
  <div class="container">
  <div class="tables">
    <div>
      <table class="table table-hover table-striped" [style]="{'width':' 100%'}">
        <thead>
          <tr>
            <th>Type</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Cash</th>
            <td>${{paymentAmount.cash}}</td>
          </tr>
          <tr>
            <th>Check</th>
            <td>${{paymentAmount.check}}</td>
          </tr>
          <tr>
            <th>CC</th>
            <td>${{paymentAmount.CC}}</td>
          </tr>
          <tr>
            <th>Refund</th>
            <td>${{paymentAmount.refund}}</td>
          </tr>
          <tr>
            <th>Builder</th>
            <td>${{paymentAmount.Builders}}</td>
          </tr>
          <tr>
            <th>Total</th>
            <td>${{paymentAmount.AggregateOfService}}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <button class="btn btn-primary" [disabled]="sendingPayments || paymentLoading || paymentAmount.AggregateOfService == 0" (click)="openModal(SubmitPayments)">Submit Payments To Sage</button>
      </div>
    </div>
    <div>
      <table class="table table-hover table-striped" [style]="{'width':' 100%'}">
        <thead>

          <tr>
            <th>ItemCode</th>
            <th>Quantity</th>
            <th>Truck</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items">
            <td>{{item.itemcode}}</td>
            <td>{{item.quantity}}</td>
            <td>{{item.inventoryLocation}}</td>
          </tr>
          <tr *ngIf="items.length == 0">
            <td align="center" colspan="3">No items to send</td>
          </tr>
        </tbody>
      </table>
      <div>
        <button class="btn btn-primary" [disabled]="sendingItems || itemLoading || items.length == 0" (click)="openModal(SubmitItems)">Submit Items To Sage</button>
      </div>
    </div>
  </div>
  <h1>Payment Breakdown</h1>
  <div class="loadingSpinner">
    <mat-spinner *ngIf="paymentLoading" ></mat-spinner>
  </div>

  <table [hidden]="paymentLoading" mat-table [dataSource]="dataSource" style="width: 100%" class="mat-elevation-z8"
    matSort>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
      <td mat-cell *matCellDef="let element"> ${{element.amount}} </td>
    </ng-container>

    <ng-container matColumnDef="paymentType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Payment Type </th>
      <td mat-cell *matCellDef="let element"> {{element.paymentType}} </td>
    </ng-container>

    <ng-container matColumnDef="jobType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Type </th>
      <td mat-cell *matCellDef="let element"> {{element.jobType}} </td>
    </ng-container>

    <ng-container matColumnDef="batchName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Batch Name </th>
      <td mat-cell *matCellDef="let element"> {{element.batchName}} </td>
    </ng-container>

    <ng-container matColumnDef="builderPo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> PO # </th>
      <td mat-cell *matCellDef="let element"> {{element.builderPo}} </td>
    </ng-container>

    <ng-container matColumnDef="builderBillable">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Builder Billable </th>
      <td mat-cell *matCellDef="let element"> {{element.builderBillable}} </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
      <td mat-cell *matCellDef="let element"> {{element.address}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div [hidden]="paymentLoading">
    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>
</div>

<ng-template #SubmitPayments>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2> Submit Payments to Sage? </h2>
        <h5></h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
      <table class="table table-hover table-striped" [style]="{'width':' 100%'}">
        <thead>
          <tr>
            <th>Type</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Service Total</th>
            <td>${{serviceTotal}}</td>
          </tr>
          <tr>
            <th>Builder</th>
            <td>${{paymentAmount.Builders}}</td>
          </tr>
          <tr>
            <th>Total</th>
            <td>${{paymentAmount.AggregateOfService}}</td>
          </tr>
        </tbody>
      </table>
      <h3>If the above amounts match the batches from today, please submit to Sage.</h3>
    </div>
    <div class="row mb-1">
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" [disabled]="sendingPayments || paymentLoading" (click)="submitPaymentToSage()">Yes</button>
      </div>
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" [disabled]="sendingPayments || paymentLoading" (click)="closeModal()">No</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #SubmitItems>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2> Submit Items To Sage </h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" [disabled]="sendingItems" (click)="submitItemsToSage()">Yes</button>
      </div>
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" [disabled]="sendingItems" (click)="closeModal()">No</button>
      </div>
    </div>
  </div>
</ng-template>
