<app-sidebar
  [open]="open"
  (sidebarClosed)="openChangeHandler($event)"
  sidebarDirection="right"
>
  <div class="kitside">
    <h2 class="kitside-title">{{ title }}</h2>
    <h4 class="kitside-parts">Total Parts: <span class="kitside-parts-val">{{ partTotal }}</span></h4>
    <h4 class="kitside-cost">
      Total Cost:  <span class="kitside-parts-val"><span class="kitside-cost-val-icon">$</span> {{ costTotal.toFixed(2) }}</span>
    </h4>
    <span class="kitside-cost-disclaimer">
      Total Cost calculated to the nearest penny, but the true cost of parts often contain a percentage of a penny which is reflected here.
    </span>

    <div class="kitside-update">
      <button 
        (click)="updatePrices()"
        mat-mini-fab 
        color="primary" 
        class="kitside-update-btn"
      >
        <i class="fa fa-redo kitside-update-btn-icon"></i>
      </button>
      <h5 class="kitside-update-title">Update Part Prices</h5>
    </div>

    <app-searchable-sel 
      placeholder="Build Location" 
      [options]="getBuildLocationOptions()"
      [required]="true"
      [defaultSelected]="buildLocationControl.value"
      (onSelectChange)="buildLocationControl.setValue($event.value)"
    ></app-searchable-sel>

    <mat-form-field
      appearance="outline"
      class="kitside-region-ff kitside-ff"
    >
      <mat-label>Region</mat-label>
      <mat-select
        [disabled]="saving"
        [formControl]="regionControl"
        class="kitside-region-ff-sel kitside-sel"
      >
        <mat-option *ngFor="let region of kitRegions" [value]="region.valueOf()">
          {{ region }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="kitside-desc-ff kitside-ff"
    >
      <mat-label>Description</mat-label>
      <textarea
        [disabled]="saving"
        [formControl]="descriptionControl"
        matInput
        type="text"
        class="kitside-desc-ff-in"
      ></textarea>
    </mat-form-field>

    <div 
      *ngIf="
        !(
          titleControl.value.trim()=='' 
          || titleControl.value == title 
        )
        || descriptionControl.value.trim()!=description.trim()
        || regionControl.value!=region
        || buildLocationControl.value!=buildLocation
      "
      class="kitside-actions"
    >
      <button
        (click)="resetControls()"
        color="warn"
        mat-flat-button
        class="kitside-actions-cancel"
      >
        Cancel
      </button>
      <button
        [disabled]="!regionControl.valid || !descriptionControl.valid || !titleControl.valid"
        (click)="saveChanges()"
        color="primary"
        mat-flat-button
        class="kitside-actions-save"
      >
        Save
      </button>
    </div>
  </div>
</app-sidebar>
