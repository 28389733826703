import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import QuoteQuestionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteQuestionDtoInterface';
import ContextQuoteQuestionAnswerDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteQuestionAnswerDtoInterface';
import { MatDialog } from '@angular/material/dialog';
import KitOverviewDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitOverviewDtoInterface';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import ContextPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextPartDtoInterface';
@Component({
  selector: 'app-create-qqanswer',
  templateUrl: './CreateQuoteQuestionAnswerComponent.html',
  styleUrls: ['./CreateQuoteQuestionAnswerComponent.css', '../QuotingTheme.scss'],
})
export class CreateQuoteQuestionAnswerComponent implements OnInit, OnDestroy, OnChanges {

  @Input() quoteQuestion: QuoteQuestionDtoInterface | null = null;

  @Input() open: boolean = false;
  loading = false;
  errorMsg: string|null = null;
  nameControl = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]);
  descControl = new FormControl('', [Validators.maxLength(255)]);
  typeControl = new FormControl('Part', [Validators.required]);
  kitOrPartControl = new FormControl('', [Validators.required]);
  kitOrPartSelectedControl = new FormControl('', [Validators.required]);
  kitOrPartSelectedOptions: KitOverviewDtoInterface[]|ContextPartDtoInterface[] = [];
  kitOrPartSelectedInputControl:  FormControl = new FormControl('');
  kitOrPartSelectedFilteredOptionsSubject: Subject<string> = new Subject<string>();

  @Output() sheetClosed = new EventEmitter<boolean>();
  @Output() answerCreated = new EventEmitter<ContextQuoteQuestionAnswerDtoInterface>();

  constructor(
    public screenSize: ScreenSizeService,
    public api: SageApiService,
    public dialog: MatDialog
  ) {
    this.kitOrPartSelectedFilteredOptionsSubject.pipe(debounceTime(2000)).subscribe((searchVal) => {
      this.loadNewQuoteAnswerKitOrPartOptions(searchVal);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.open){
      this.resetControls();
    }
  }
  
  ngOnInit(): void {}

  ngOnDestroy(): void {}

  createQuoteQuestionAnswer(){
    const questionGuid = this.quoteQuestion.QuoteQuestion_guid;
    let newAnswer: any = {
      QuoteQuestionAnswer_Name: this.nameControl.value,
      QuoteQuestionAnswer_Desc: this.descControl.value,
    };
    // If the part is selected, add it to the newAnswer
    if(this.typeControl.value == 'Part'){
      newAnswer.part = this.kitOrPartSelectedControl.value.Part.Part_guid;
      newAnswer.QuoteQuestionAnswer_Phase = 'NA';
    }
    // If the kit is selected, add it to the newAnswer
    if(this.typeControl.value == 'Kit'){
      newAnswer.kit = this.kitOrPartSelectedControl.value.Kit_guid;
    }
    this.api.postRequest(`add-to-quote-question/${questionGuid}`, newAnswer).subscribe((data: ContextQuoteQuestionAnswerDtoInterface)=>{
      this.answerCreated.emit(data);
    });
  }

  openNewQuoteQuestionSheet(){
    
    this.resetControls();
    this.open = true;
  }

  resetControls(){
    this.errorMsg = null;
    this.nameControl = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]);
    this.descControl = new FormControl('', [Validators.maxLength(255)]);
    this.typeControl = new FormControl('Part', [Validators.required]);
    this.kitOrPartControl = new FormControl('', [Validators.required]);
    this.kitOrPartSelectedControl = new FormControl('', [Validators.required]);
    this.kitOrPartSelectedOptions = [];
    this.kitOrPartSelectedInputControl.setValue('');
  }

  valid(){
    return this.nameControl.valid 
      && this.descControl.valid 
      && this.typeControl.valid
      && this.loading == false 
      && this.kitOrPartSelectedControl.valid;
  }

  loadNewQuoteAnswerKitOrPartOptions(searchVal){
    const type = this.typeControl.value;
    if(type === 'Part'){
      this.api.pullReport(`parts?Part_Code=${searchVal}`).subscribe((data: ContextPartDtoInterface[])=>{
        this.kitOrPartSelectedOptions = data;
      });
    }else if(type === 'Kit'){
      this.api.pullReport(`kits-overview?Kit_Name=${searchVal}`).subscribe((data: KitOverviewDtoInterface[])=>{
        this.kitOrPartSelectedOptions = data;
      });
    }
  }

  kitOrPartControlChange(){
    this.kitOrPartControl.setValue(''); 
    this.kitOrPartSelectedOptions = []; 
    this.kitOrPartSelectedControl.setValue(''); 
    this.kitOrPartSelectedInputControl.setValue('');
  }

}