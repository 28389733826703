<div class="container-fluid py-4" style="height: 85vh">
    <mat-card style="min-height: 100%;">
        <mat-card-header>
            <mat-card-title>
                {{ unshippedItemsLoading ? 'Loading' : '' }} Unshipped Items in Warehouse {{ warehouseCode }} <small>{{ completedTickets ? '(Completed Tickets)' : '(Open Tickets)' }}</small>
                <mat-slide-toggle *ngIf="!unshippedItemsLoading" class=" float-right mr-0" color="primary" [(ngModel)]="completedTickets" (change)="refreshUnshippedItemsList()">
                    {{ completedTickets ? 'Completed Tickets' : 'Open Tickets' }}
                </mat-slide-toggle>
            </mat-card-title>
        </mat-card-header>
        <div class="w-100">
            <mat-progress-bar class="w-100" mode="indeterminate" *ngIf="unshippedItemsLoading"></mat-progress-bar>
        </div>
        <mat-card-content [hidden]="unshippedItemsLoading">
            <mat-form-field class="w-50">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. VW34" #input>
            </mat-form-field>
            <mat-form-field class="ml-2 float-right">
                <mat-label>Warehouse</mat-label>
                <mat-select (selectionChange)="refreshUnshippedItemsList()" [(value)]="warehouseCode">
                <ng-container *ngFor="let warehouse of warehouseList">
                    <mat-option value="{{ warehouse.WarehouseCode }}">{{ warehouse.WarehouseCode }} - {{ warehouse.WarehouseDesc }}</mat-option>
                </ng-container>
                <mat-divider></mat-divider>
                </mat-select>
            </mat-form-field>
                <div class="mat-elevation-z8 mat-table-responsive">
                    <table mat-table [dataSource]="dataSource" class="w-100" matSort>

                        <!-- Position Column -->
                        <ng-container matColumnDef="itemCode">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> ItemCode </th>
                            <td mat-cell *matCellDef="let element"> {{ element.itemCode }} </td>
                        </ng-container>
                    
                        <!-- Name Column -->
                        <ng-container matColumnDef="quantityNeeded">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity Needed </th>
                            <td mat-cell *matCellDef="let element"> {{ element.quantityNeeded }} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="quantityOnHand">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity On Hand </th>
                            <td mat-cell *matCellDef="let element"> {{ element.quantityOnHand }} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="quantityDifference">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity Difference </th>
                            <td mat-cell *matCellDef="let element"> {{ element.quantityDifference }} </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let element"> 
                                <ng-container *ngIf="!element.updating">
                                    <button mat-raised-button (click)="shipItem(element)">
                                        {{ element.updated ? 'Shipped' : 'Ship' }} 
                                        <mat-icon class="text-success">local_shipping</mat-icon>
                                    </button>
                                </ng-container>

                                <ng-container *ngIf="element.updating">
                                    <mat-icon class="text-success">local_shipping</mat-icon>
                                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                </ng-container>
                            </td>
                        </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: tableDisplayedColumns;"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="5">No data matching the filter "{{ input.value }}"</td>
                        </tr>
                    </table>   
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
        </mat-card-content>
    </mat-card>
</div>
