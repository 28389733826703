<div class="container-fluid">
  <div class="row mx-3">
    <div class="table-responsive">
      <div class="row my-2">
        <div class="col-2">
          <button class="btn btn-info btn-sm pull-right" (click)="loadHistory()">
            Refresh
          </button>
        </div>
        <div class="col-6">
          <img src="../../../../assets/flag-16.png" alt="a red flag" /> = Material not
          added to ticket
          <img
            src="../../../../assets/notification.svg"
            alt="a bell shaped notification icon"
          />= Non Stock material selected
        </div>
      </div>
      <div class="row m-0 p-0">
        <table class="table table-striped table-hover w-auto">
          <thead>
          <tr>
            <td>Flag</td>
            <td>WT#</td>
            <td>Address</td>
            <td>Subdivision</td>
            <td>Company</td>
            <td>Description of Work</td>
            <td>Assigned Tech</td>
            <td>LM</td>
            <td>WorkTime</td>
            <td>Total Cost</td>
            <td
              (click)="onColumnSort('currentStatusDate', 'statusDateSortDirection')">
              Current StatusDate
              {{statusDateSortDirection == "asc"
              ? "&#9650;"
              : statusDateSortDirection == "desc"
                ? "&#9660;"
                : ""}}
            </td>
            <td>Action</td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let step of dataModel.steps; let i = index" id="table-info">
            <img
              *ngIf="step.matNotShown"
              src="../../../../assets/flag-16.png"
              alt="a red flag"/>
            <img
              *ngIf="step.nonStockCheck"
              src="../../../../assets/notification.svg"
              alt="a bell shaped notification icon"/>
            <span></span>
            <td>
              {{ step.wtNumber }}
            </td>
            <td>
              {{ step.address }}
            </td>
            <td>
              {{ step.subdivision }}
            </td>
            <td>
              {{ step.builderCompany }}
            </td>
            <td>
              {{ step.descriptionOfWork }}
            </td>
            <td>
              {{ step.technician }}
            </td>
            <td>{{ step.leadman }}</td>
            <td>{{ step.workTime }} mins</td>
            <td>${{ step.laborCost + step.totalMaterialCost.toFixed(3) * 1 }}</td>
            <td>
              {{ step.currentStatusDate }}
            </td>
            <td>
              <button
                class="{{
              step.updated == ''
                ? 'btn btn-primary btn-sm'
                : 'btn btn-success btn-sm'}}"
                (click)="actionEdit(i.toString())"
                (click)="openModal(editInterface)">
                {{ step.updated == "" ? "Edit" : "Updated" }}
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>



<!--*Edit Modal -->
<ng-template #editInterface
             class="modal fade"
             tabindex="-1"
             role="dialog"
             aria-labelledby="mySmallModalLabel"
             aria-hidden="true">
<!--  <div-->
<!--    class="modal fade"-->
<!--    id="editInterface"-->
<!--    bsModal-->
<!--    #editInterface="bs-modal"-->
<!--    tabindex="-1"-->
<!--    role="dialog"-->
<!--    aria-labelledby="editor"-->
<!--  >-->
    <div class="modal-dialog" role="document">
      <div class="modal-content" *ngIf="dataModel.steps.length > 0">
        <div class="modal-header">
          <ng-container *ngIf="errorMsg !== ''">
            <div class="row">
              <div class="div">
                <h5 *ngIf="errorMsg !== ''" style="color: red;">
                  <img
                    src="../../../assets/importantYellow.png"
                    alt="a black exclamation point on a yellow background"
                    width="20"
                  />
                  Failed to submit: {{ errorMsg }} Please try again later.
                </h5>
              </div>
            </div>
          </ng-container>
          <div class="row">
            <div class="col">
              <h3 class="modal-title" id="editor">
                Editing {{ dataModel.steps[index].address }}
              </h3>
              <ng-container *ngIf="errorMsg !== ''">
                <h5  style="color: red;">
                  <img
                    src="../../../../assets/importantYellow.png"
                    alt="a black exclamation point on a yellow background"
                    width="20"
                  />
                  Failed to submit: {{ errorMsg }} Please try again later.
                </h5>
              </ng-container>
              <ng-container *ngIf="dataModel.steps[index].matNotShown">
                <div
                  style="background-color: darkslateblue; border-radius: 5px; color: white; font-size: 1.5rem">
                  <p style="padding: 5px;">
                    <img src="../../../../assets/flag-16.png" alt="a red flag" />
                    Material marked as needed but it has NOT been added to ticket!
                  </p>
                </div>
              </ng-container>
              <ng-container *ngIf="dataModel.steps[index].nonStockCheck">
                <div
                  style="background-color: rgb(240, 156, 1); border-radius: 5px; color: white; font-size: 1.5rem">
                  <p style="padding: 3px 0">
                    <img
                      src="../../../../assets/notification.svg"
                      alt="a bell shaped notification icon"
                    />
                    Non Stock material selected
                  </p>
                </div>
              </ng-container>
            </div>
          </div>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="text-center">
          </div>
        </div>
        <div class="modal-body">
          <div class="row mb-2">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-sm"
                (click)="cancelTicket(dataModel.steps[index], index)"
                (click)="displayReasonToCancel === true
              ? (displayReasonToCancel = false)
              : (displayReasonToCancel = true)"
                [ngStyle]="displayReasonToCancel === true
              ? { 'background-color': 'orange', color: 'white' }
              : { 'background-color': 'red', color: 'white' }">
                {{ displayReasonToCancel ? "Close" : "Cancel Ticket" }}
              </button>
            </div>
            <ng-container *ngIf="displayReasonToCancel">
              <div class="col-12 mt-1">
                <label for="reasonToCancel"><b>Reason To Cancel</b></label>
                <textarea
                  [(ngModel)]="reasonToCancel"
                  class="form-control"
                  name="reasonToCancel"
                  id="reasonToCancel"
                  rows="2"
                ></textarea>
              </div>
            </ng-container>
          </div>
          <div class="row mb-2">
            <div class="col-12 mb-1">
              <label for="typeNotes"><b>Charge Reason</b></label>
            </div>
            <div class="col-12">
              <blockquote>
                <p class="lead" id="typeNotes">{{ dataModel.steps[index].notes }}</p>
              </blockquote>
            </div>

          </div>

            <form class="">
              <div class="row mb-2">
                <div class="col-4 mt-2">
                  <label for=""><b>Builder Name</b></label>
                </div>
                <div class="col-7">
                  <input
                    type="text"
                    class="form-control"
                    name="BuilderName"
                    id="builderName"
                    aria-describedby="builderName"
                    disabled
                    value="{{ dataModel.steps[index].builderName }}"/>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4 mt-2">
                  <label for=""><b>Builder Email</b></label>
                </div>
                <div class="col-7">
                  <input
                    type="text"
                    class="form-control"
                    name="BuilderEmail"
                    id="builderEmail"
                    aria-describedby="builderEmail"
                    disabled
                    value="{{ dataModel.steps[index].builderPhone }}"
                  />
                </div>
              </div>
            </form>

<!--          <div class="row mb-2">-->
            <form class="">
              <div class="row">
                <div class="col-12 mb-1">
                  <label class="" for="AdditionalInfo" style="min-width: 105px;"><b>Additional Info</b></label>
                </div>
                <div class="col-6">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      id="AdditionalInfo"
                      disabled
                      value="{{ dataModel.steps[index].builderCompany }}"
                      style="width: 21rem"
                    />
                  </div>
                </div>
                <div class="col-6">
<!--                  <label-->
<!--                    class="sr-only"-->
<!--                    for="builderSubdivision"-->
<!--                    style="min-width: 120px;"-->
<!--                  >Builder Subdivision</label-->
<!--                  >-->
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      id="BuilderSubdivision"
                      disabled
                      value="{{ dataModel.steps[index].builderSubdivision }}"
                    />
                  </div>
                </div>
              </div>

              <div class="clearfix" style="margin-top:20px;"></div>
              <div class="row mb-2">
                <div class="col-4 mt-2">
                  <label class="" for="workTime" style="min-width: 120px;"><b>Work Time</b></label>
                </div>
                <div class="col-6">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      id="workTime"
                      disabled
                      value="{{ dataModel.steps[index].workTime }}"/>

                  </div>
                </div>
                <div class="col-1 mt-2">Minutes</div>
              </div>
              <div class="row mb-2">
                <div class="col-4 mt-2">
                  <label class="" for="labor-cost" style="min-width: 120px;"><b>Labor Cost</b></label>
                </div>
                <div class="col-7">
                  <input
                    type="text"
                    class="form-control"
                    id="labor-cost"
                    disabled
                    value="${{ dataModel.steps[index].laborCost.toFixed(2) * 1 }}"
                  />
<!--                  <div class="input-group-addon">dollars</div>-->
                </div>
              </div>
              <ng-container *ngIf="dataModel.steps[index].material.length > 0">
                <div class="row mb-2">
                  <div class="col-4 mt-2">
                    <label for="material-cost"><b>Material Cost</b></label>
                  </div>
                  <div class="col-7">
                    <input
                      type="text"
                      name="material-cost"
                      id="material-cost"
                      class="form-control"
                      placeholder=""
                      aria-describedby="material-cost"
                      disabled
                      value="${{dataModel.steps[index].totalMaterialCost.toFixed(3) * 1}}"
                    />
                  </div>
                </div>
              </ng-container>
              <div class="row mb-2">
                <div class="col-4 mt-2">
                  <label class="" for="newAmount"><b>Charge Amount</b></label>
                </div>
                <div class="col-7">
<!--                  <div class="mt-2"><b>$</b></div>-->
                  <input
                    type="number"
                    class="form-control"
                    id="newAmount"
                    placeholder="new amount"
                    name="newAmount"
                    [(ngModel)]="dataModel.steps[index].newAmount"
                  />
                </div>
              </div>
<!--              <div class="row mb-2">-->
<!--                <div class="col">-->
<!--                  <label class="control-label col-xs-4 marginTopAndBot"><b>Images</b></label>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row mb-2">-->
<!--                <div class="col-8">-->
<!--                  <label-->
<!--                    class="btn btn-primary"-->
<!--                    [class.disabled-button]="-->
<!--                  isLoading || isImageLoading"-->
<!--                    (click)="loadImagesS3()">-->
<!--                    Load Images-->
<!--                  </label>-->
<!--                </div>-->
<!--                <div *ngIf="!!imgLoaded" class="col-12">-->
<!--                  <div>-->
<!--                    {{ imgArr.length > 0 ? "" : "**No Photos To Load**" }}-->
<!--                    &lt;!&ndash;                <img&ndash;&gt;-->
<!--                    &lt;!&ndash;                  *ngFor="let img of imgArr; let i = index"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  class="navbar-buttons"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  width="25%"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  height="25%"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  src="{{ img }}"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  (click)="onViewFullImg([img])"&ndash;&gt;-->
<!--                    &lt;!&ndash;                />&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <ng-container *ngIf="dataModel.steps[index].material.length > 0">
                <div class="row mb-2">
                  <div class="table-responsive" *ngIf="showMaterial">
                    <table class="table table-striped table-hover">
                      <thead>
                      <tr>
                        <th>Desc</th>
                        <th>Number</th>
                        <th>Quantity</th>
                        <th>Item Cost</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let mat of dataModel.steps[index].material">
                        <td scope="row">{{ mat.itemDesc }}</td>
                        <td scope="row">{{ mat.itemCode }}</td>
                        <td scope="row">{{ mat.quantity }}</td>
                        <td scope="row">${{ mat.itemCost }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-container>
            </form>
<!--          </div>-->

        </div>
        <!-- *Material Footer -->
        <ng-container *ngIf="dataModel.steps[index].material.length > 0">
          <div class="modal-footer row">
            <div class="col-6 mt-2">
              <!--            <div class="col-12">-->
              <h4>
                Labor Total: ${{ dataModel.steps[index].laborCost.toFixed(2) * 1 }}
              </h4>
              <!--            </div>-->
            </div>
            <div class="col-5 text-right">
              <!--            <div class="col-12">-->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="showMaterial = !showMaterial">
                {{ showMaterial ? "Hide Material" : "Show Material" }}
              </button>
              <!--            </div>-->
            </div>
            <div class="col-12 text-center">
              <!--            <div class="col-12">-->
              <h4>
                <strong>Total Cost: ${{
                  dataModel.steps[index].totalMaterialCost.toFixed(3) * 1 +
                  dataModel.steps[index].laborCost * 1}}</strong>
              </h4>
              <!--            </div>-->
            </div>
            <div class="col-12 align-content-center">
              <div class="row">
                <div class="col-6 text-left">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="actionClose()">Close</button>
                </div>
                <div class="col-6 text-right">
                  <button
                    type="button"
                    class="btn btn-primary"
                    [ngClass]="dataModel.steps[index].newAmount === ''
                  ? 'disabledButton' : 'enabledButton'"
                    [disabled]="!dataModel.steps[index].newAmount || isLoading"
                    (click)="saveChanges(index)">
                    <img *ngIf="isLoading" src="assets/ring-alt.svg" /><span
                    *ngIf="!isLoading">{{dataModel.steps[index].newAmount !== ""
                    ? "Update & Accept" : "Accept"}}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- *No Material Footer -->
        <ng-container *ngIf="dataModel.steps[index].material.length <= 0">
          <div
            class="modal-footer row justify-content-center mx-2">
<!--            <div class="row justify-content-center">-->
            <div class="col-12 text-center">
              <h4>
                <strong>
                  Labor Total: ${{ dataModel.steps[index].laborCost }}
                </strong>
              </h4>
            </div>
            <div class="col-5 text-left">
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="actionClose()">
                  Close
                </button>
              </div>
            <div class="col-5 text-right">
                <button
                  type="button"
                  class="btn btn-primary"
                  [disabled]="dataModel.steps[index].newAmount === '' || isLoading"
                  (click)="saveChanges(index)"
                  [ngClass]="
                dataModel.steps[index].newAmount === ''
                  ? 'disabledButton'
                  : 'enabledButton'">
                  <img *ngIf="isLoading" src="assets/ring-alt.svg" /><span
                  *ngIf="!isLoading">{{
                  dataModel.steps[index].newAmount !== ""
                    ? "Update & Approve"
                    : "Approve"
                  }}</span>
                </button>
              </div>
<!--            </div>-->
          </div>
        </ng-container>
      </div>
    </div>
<!--  </div>-->
</ng-template>


<!-- *Image Modal -->
<ng-template #imgModal
             class="modal fade"
             tabindex="-1"
             role="dialog"
             aria-labelledby="mySmallModalLabel"
             aria-hidden="true">
  <div
    bsModal
    #imgModal="bs-modal"
    class="modal fade"
    id="modelId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 *ngIf="this.imgLoaded" class="modal-title">
            Images for {{ dataModel.steps[index].address }}
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="imgModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <img src="{{ modalImgUrl }}" alt="" width="100%" />
        </div>
      </div>
    </div>
  </div>
</ng-template>

