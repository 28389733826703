import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import ContextQuoteQuestionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteQuestionDtoInterface';
import KitOverviewDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitOverviewDtoInterface';
import PartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/PartDtoInterface';
import { FormControl } from '@angular/forms';
import QuoteQuestionAnswerDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteQuestionAnswerDtoInterface';
import ContextPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextPartDtoInterface';

interface QuoteQuestionKitInterface {
  Kit: KitOverviewDtoInterface;
  QuoteQuestionAnswer: QuoteQuestionAnswerDtoInterface;
}

interface QuoteQuestionPartInterface {
  Part: ContextPartDtoInterface;
  QuoteQuestionAnswer: QuoteQuestionAnswerDtoInterface;
}

export interface QuoteQuestionRowAnswerChangeInterface {
  kitOrPart: QuoteQuestionKitInterface|QuoteQuestionPartInterface;
  index: number;
};

@Component({
  selector: 'app-qqrow',
  templateUrl: './QuoteQuestionRowComponent.html',
  styleUrls: ['./QuoteQuestionRowComponent.css', '../QuotingTheme.scss'],
})
export class QuoteQuestionRowComponent implements OnInit, OnDestroy, OnChanges {

  @Input() quoteQuestion: ContextQuoteQuestionDtoInterface | null = null;
  @Input() disabled: boolean = false;

  // Just use form controls. Using bools and setting ngModel or [checked] leads to spooky ghosts changing state in ways that are fucked!
  // Seriously, just spent 3.5 hours wondering why state is getting changed even though I am not changing it, only to try using FCs and it now works. IDEK WHY!
  // Actually, Angular is the one fucked up for doing weird ass shit like that. Man, like what the fuck!
  // Pretty much HTMX and React handle this way better. I am sure Vue is better too except that it's chinesium so you can't really trust it!
  answersChecked: FormControl[] = []; 

  @Output() answerChange = new EventEmitter<QuoteQuestionRowAnswerChangeInterface|null>();

  constructor(
    public screenSize: ScreenSizeService,
    public api: SageApiService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    for(let a of this.quoteQuestion.QuoteQuestionAnswers){
      this.answersChecked.push(new FormControl(false));
    }
  }
  
  ngOnInit(): void {}

  ngOnDestroy(): void {}

  checkChanged(val: boolean, answerIndex: number){
    if(this.quoteQuestion.QuoteQuestion.QuoteQuestion_Type == 'radio'){
      for(let ai = 0; ai < this.answersChecked.length; ai++){
        this.answersChecked[ai].setValue(false);
      }
    }

    this.answersChecked[answerIndex].setValue(val);
    
    if(val && this.quoteQuestion.QuoteQuestionAnswers[answerIndex].Kit){
      const kitAnswer: QuoteQuestionKitInterface = {
        Kit: this.quoteQuestion.QuoteQuestionAnswers[answerIndex].Kit,
        QuoteQuestionAnswer: this.quoteQuestion.QuoteQuestionAnswers[answerIndex].QuoteQuestionAnswer
      };
      this.answerChange.emit({kitOrPart: kitAnswer, index: answerIndex});
    }else if(val && this.quoteQuestion.QuoteQuestionAnswers[answerIndex].Part){
      const partAnswer: QuoteQuestionPartInterface = {
        Part: this.quoteQuestion.QuoteQuestionAnswers[answerIndex].Part,
        QuoteQuestionAnswer: this.quoteQuestion.QuoteQuestionAnswers[answerIndex].QuoteQuestionAnswer
      };
      this.answerChange.emit({kitOrPart: partAnswer, index: answerIndex});
    }else{
      this.answerChange.emit(null);
    }
  }

}