import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import ContextQuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteDtoInterface';
import QuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteDtoInterface';
import QuoteKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteKitDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import QuoteUtil from 'src/app/utils/QuoteUtil';
import { AddItemsOutputInterface } from '../KitOrPartPickerBotSheetComponent/KitOrPartPickerBotSheetComponent';
import ContextQuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteKitPartDtoInterface';
import { Context } from 'vm';
import ContextQuoteKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteKitDtoInterface';
import kitPartPhases from '../KitPartPhases';
import QuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteKitPartDtoInterface';
import QuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuotePartDtoInterface';
import { Observable, from as fromObsToProm } from 'rxjs';
import QuoteKitPartQuotePartSavedInterface from '../../../_services/QuoteEditableService/interfaces/QuoteKitPartQuotePartSavedInterface';
import QuoteKitPartEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteKitPartEditableInterface';

@Component({
  selector: 'app-editqpartbs',
  templateUrl: './EditQuotePartBotSheetComponent.html',
  styleUrls: ['./EditQuotePartBotSheetComponent.css', '../QuotingTheme.scss']
})
export class EditQuotePartBotSheetComponent extends QuoteUtil implements OnInit, OnChanges{
  @Input() open: boolean = false;
  @Input() qkp: QuoteKitPartEditableInterface|null = null;

  kitPartPhases = kitPartPhases;

  loading: boolean = false;
  quantityControl = new FormControl(1, [Validators.required, Validators.min(1), Validators.max(9999)]);
  phaseControl = new FormControl(kitPartPhases[0].valueOf(), [Validators.required]);
  costControl = new FormControl(1, [Validators.required, Validators.min(0.0001), Validators.max(99999999.9999)]);
  nameControl = new FormControl('', [Validators.required]);
  descControl = new FormControl('', [Validators.required]);

  @Output() sheetClosed = new EventEmitter<void>();
  @Output() partSaved = new EventEmitter<QuoteKitPartQuotePartSavedInterface>();

  constructor(
    public api: SageApiService,
    public screenSize: ScreenSizeService, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ){
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetControls();
  }

  ngOnInit(): void {
    this.resetControls();
  }

  resetControls(){
    if(this.qkp.data.QuotePart != null){
      this.quantityControl = new FormControl(this.qkp.data.QuoteKitPart.QuoteKitPart_Quantity, [Validators.required, Validators.min(1), Validators.max(9999)]);
      this.phaseControl = new FormControl(this.qkp.data.QuoteKitPart.QuoteKitPart_Phase, [Validators.required]);
      this.costControl = new FormControl(this.qkp.data.QuotePart.QuotePart.QuotePart_Cost, [Validators.required, Validators.min(0.0001), Validators.max(99999999.9999)]);
    }else if(this.qkp.data.QuoteKit != null){
      this.quantityControl = new FormControl(this.qkp.data.QuoteKitPart.QuoteKitPart_Quantity, [Validators.required, Validators.min(1), Validators.max(9999)]);
      this.phaseControl = new FormControl(kitPartPhases[0].valueOf(), [Validators.required]);
      this.costControl = new FormControl(1, [Validators.required, Validators.min(0.0001), Validators.max(99999999.9999)]);
      this.nameControl = new FormControl(
        this.qkp.data?.QuoteKit.QuoteKit.QuoteKit_Name,
        [Validators.required]
      );
      this.descControl = new FormControl(
        this.qkp.data?.QuoteKit.QuoteKit.QuoteKit_Desc,
        [Validators.required]
      );
    }
  }

  isSaveDisabled(){
    if(this.qkp.data?.QuotePart != null){
      return !(
        this.quantityControl.valid 
        && this.phaseControl.valid 
        && this.costControl.valid
        && (
          this.quantityControl.value != this.qkp.data.QuoteKitPart.QuoteKitPart_Quantity
          || this.phaseControl.value != this.qkp.data.QuoteKitPart.QuoteKitPart_Phase
          || this.costControl.value != this.qkp.data.QuotePart.QuotePart.QuotePart_Cost
        )
      );
    }else if(this.qkp.data.QuoteKit != null){
      return !(
        this.quantityControl.valid 
        && this.phaseControl.valid 
        && this.costControl.valid
        && this.nameControl.valid
        && this.descControl.valid
        && (
          this.quantityControl.value != 0
          || this.phaseControl.value != kitPartPhases[0].valueOf()
          || this.costControl.value != 0
          || this.nameControl.value != this.qkp.data.QuoteKit.QuoteKit.QuoteKit_Name
          || this.descControl.value != this.qkp.data.QuoteKit.QuoteKit.QuoteKit_Desc
        )
      );
    }
    return false;
  }

  saveChanges(){
    let proms: [
      Promise<QuoteKitPartDtoInterface|null>, 
      Promise<QuotePartDtoInterface | null>,
      Promise<QuoteKitDtoInterface | null>
    ] = [Promise.resolve(null), Promise.resolve(null), Promise.resolve(null)];

    this.loading = true;
    if(
      (
        this.quantityControl.valid 
        && this.quantityControl.value != this.qkp.data.QuoteKitPart.QuoteKitPart_Quantity
      )
      || 
      (
        this.phaseControl.valid 
        && this.phaseControl.value != this.qkp.data.QuoteKitPart.QuoteKitPart_Phase
      )
    ){
      proms[0] = 
        this.api.patchRequest(`quotekitpart/${this.qkp.data?.QuoteKitPart.QuoteKitPart_guid}`, {
          QuoteKitPart_Quantity: this.quantityControl.value,
          QuoteKitPart_Phase: this.phaseControl.value,
        }).toPromise<any>(); // Annoying AF any because rxjs nonsense
    }

    if(
      this.costControl.valid 
      && this.qkp.data?.QuotePart?.QuotePart?.QuotePart_guid != null
      && this.costControl.value != this.qkp.data?.QuotePart?.QuotePart?.QuotePart_Cost
    ){
      proms[1] = 
        this.api.patchRequest(`quotepart/${this.qkp.data?.QuotePart.QuotePart.QuotePart_guid}`, {
          QuotePart_Cost: this.costControl.value,
        }).toPromise<any>(); // Annoying AF any because rxjs nonsense
    }

    if(
      this.nameControl.valid 
      && (
        this.nameControl.value != this.qkp.data.QuoteKit.QuoteKit.QuoteKit_Name
        || this.descControl.value != this.qkp.data.QuoteKit.QuoteKit.QuoteKit_Desc
      )
    ){
      proms[2] = 
        this.api.patchRequest(`quotekit/${this.qkp.data?.QuoteKit.QuoteKit.QuoteKit_guid}`, {
          QuoteKit_Name: this.nameControl.value,
          QuoteKit_Desc: this.descControl.value,
          QuoteKit_Region: this.qkp.data.QuoteKit.QuoteKit.QuoteKit_Region,
        }).toPromise<any>(); // Annoying AF any because rxjs nonsense
    }

    Promise.all(proms).then((res)=>{
      this.loading = false;
      const partSavedObj: QuoteKitPartQuotePartSavedInterface = {
        QuoteKitPart: res[0],
        QuotePart: res[1],
        QuoteKit: res[2],
      };
      if(res[0]){
        this.qkp.data.QuoteKitPart = res[0];
        this.qkp.quantityControl.setValue(res[0].QuoteKitPart_Quantity);
        this.qkp.phaseControl.setValue(res[0].QuoteKitPart_Phase);
      }
      if(res[1]){
        this.qkp.data.QuotePart.QuotePart = res[1];
        this.qkp.costControl.setValue(res[1].QuotePart_Cost);
      }
      if(res[2]){
        this.qkp.data.QuoteKit.QuoteKit = res[2];
        this.qkp.nameControl.setValue(res[2].QuoteKit_Name);
        this.qkp.descControl.setValue(res[2].QuoteKit_Desc);
      }
      this.partSaved.emit(partSavedObj);
      this.sheetClosed.emit();
    });
  }

}