import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertifyService } from '../../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../../_services/auth/auth.service';

interface PendingInvoice {
  id: number;
  invoiceNo: string;
  invoiceDate: string;
  receiptDate: string;
  purchaseOrderNo: string;
  newReceiptDate: string;
  newInvoiceNo: string;
  newInvoiceDate: string;
  shippingAmountDue: number;
  taxAmountDue: number;
  totalAmountDue: number;
  lineErrors: number;
  sageTaxDue: number;
  sageTotalDue: number;
}

interface ApiInvoice {
  Id: number;
  InvoiceDate: string;
  InvoiceNo: string;
  Items: any;
  PurchaseOrderNo: string;
  ReceiptDate: string;
  ShippingAmountDue: number;
  TaxAmountDue: number;
  TotalAmountDue: number;
  LineErrors: number;
  SageTaxDue: number;
  SageTotalDue: number;
}

@Component({
  templateUrl: './roi-pending.component.html',
  styleUrls: ['./roi-pending.component.css']
})
export class RoiPendingComponent implements OnInit {
  pendingInvoices: PendingInvoice[] = [];
  filteredInvoices: PendingInvoice[] = [];
  pendingInvoicesLoading: boolean;

  constructor(
    private router: Router,
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    // for (let i = 0; i < 15; i++) {
    //   const newInvoice: PendingInvoice = {
    //     id: 0,
    //     invoiceNo: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 20),
    //     invoiceDate: (new Date(+(new Date()) - Math.floor(Math.random() * 10000000000))).toDateString(),
    //     receiptDate: (new Date(+(new Date()) - Math.floor(Math.random() * 10000000000))).toDateString(),
    //     purchaseOrderNo: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
    //     newReceiptDate: null,
    //     newInvoiceNo: null,
    //     newInvoiceDate: null,
    //     shippingAmountDue: Math.random() * 100,
    //     taxAmountDue: Math.random() * 100,
    //     totalAmountDue: Math.random() * 100
    //   };

    //   this.pendingInvoices.push(newInvoice);
    // }

    this.getInvoices();
  }

  openIndividualInvoice(invoiceId: number) {
    this.router.navigate(['/Accounting/Receipt/Invoice', invoiceId]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value || '';
    const loweredFilterValue = filterValue.toLocaleLowerCase();
    this.filteredInvoices = this.pendingInvoices.filter(x =>
      x.invoiceNo.toLocaleLowerCase().includes(loweredFilterValue) ||
      x.purchaseOrderNo.toLocaleLowerCase().includes(loweredFilterValue) ||
      loweredFilterValue === '');
  }

  calculateDaysInProgress(invoiceIndex: number) {
    const invoiceDate = new Date(this.pendingInvoices[invoiceIndex].invoiceDate);
    const today = new Date();
    const diff = Math.abs(invoiceDate.getTime() - today.getTime() - (1000 * 3600 * 24));
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

    const firstDayOfTheYear =  new Date(today.getFullYear(), 0, 1);
    const firstDay = firstDayOfTheYear.getDay();
    // add days in time to make first day of the year be a sunday

    const timeThisYear = Math.abs(firstDayOfTheYear.getTime() - today.getTime());
    const daysThisYear = Math.ceil(timeThisYear / (1000 * 3600 * 24));
    const currentWeekNumber = Math.floor((daysThisYear + firstDay) / 7);

    const timeFromInvoice = invoiceDate.getTime() - firstDayOfTheYear.getTime();
    const daysFromInvoice = Math.ceil(timeFromInvoice / (1000 * 3600 * 24));
    const invoiceWeekNumber = Math.floor((daysFromInvoice) / 7);

    const numberOfWeekends = Math.abs(currentWeekNumber - invoiceWeekNumber);
    const numberOfWeekendDays = numberOfWeekends * 2;

    const diffDaysWithoutWeekends = diffDays - numberOfWeekendDays;
    return diffDaysWithoutWeekends;
  }

  getInvoices() {
    this.pendingInvoicesLoading = true;
    this.pendingInvoices = [];

    this.sageApi.pullReport('ReceiptOfInvoice/Pending').subscribe((rows: Array<ApiInvoice>) => {
      console.log(rows);
      if (Array.isArray(rows)) {
        rows.forEach(row => {
          const invoice: PendingInvoice = {
            id: row.Id,
            invoiceDate: row.InvoiceDate,
            invoiceNo: row.InvoiceNo,
            purchaseOrderNo: row.PurchaseOrderNo,
            receiptDate: row.ReceiptDate,
            shippingAmountDue: row.ShippingAmountDue,
            taxAmountDue: row.TaxAmountDue,
            totalAmountDue: row.TotalAmountDue,
            lineErrors: row.LineErrors,
            sageTaxDue: row.SageTaxDue,
            sageTotalDue: row.SageTotalDue,
            newReceiptDate: '',
            newInvoiceDate: '',
            newInvoiceNo: ''
          };

          this.pendingInvoices.push(invoice);
        });
      }
    }, err => {

    }, () => {
      this.filteredInvoices = this.pendingInvoices;
    });
  }

}
