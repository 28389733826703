import { ChangeDetectionStrategy, EventEmitter, Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { catchError, map, share, shareReplay, tap, throwIfEmpty } from 'rxjs/operators';
import ContextQuoteDtoInterface from '../sageApi/interfaces/pullReport/ContextQuoteDtoInterface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SageApiService } from '../sageApi/sageApi.service';
import QuoteDataEditableInterface from './interfaces/QuoteDataEditableInterface';
import { FormArray, FormControl, Validators } from '@angular/forms';
import QuoteOptionEditableInterface, { QuoteOptionKitPartEditableDataInterface, QuoteOptionKitPartEditableInterface, QuoteOptionReplaceEditableInterface } from './interfaces/QuoteOptionEditableInterface';
import ContextQuoteOptionKitPartDtoInterface from '../sageApi/interfaces/pullReport/ContextQuoteOptionKitPartDtoInterface';
import ContextQuoteKitPartDtoInterface from '../sageApi/interfaces/pullReport/ContextQuoteKitPartDtoInterface';
import ContextQuoteOptionDtoInterface from '../sageApi/interfaces/pullReport/ContextQuoteOptionDtoInterface';
import QuoteBillingTermDtoInterface from '../sageApi/interfaces/pullReport/QuoteBillingTermDtoInterface';
import QuoteExclusionDtoInterface from '../sageApi/interfaces/pullReport/QuoteExclusionDtoInterface';
import QuoteInclusionDtoInterface from '../sageApi/interfaces/pullReport/QuoteInclusionDtoInterface';
import QuoteNoteDtoInterface from '../sageApi/interfaces/pullReport/QuoteNoteDtoInterface';
import QuoteBillingTermEditableInterface from './interfaces/QuoteBillingTermEditableInterface';
import QuoteExclusionEditableInterface from './interfaces/QuoteExclusionEditableInterface';
import QuoteInclusionEditableInterface from './interfaces/QuoteInclusionEditableInterface';
import QuoteKitPartEditableInterface, { QuoteKitPartEditableDataInterface } from './interfaces/QuoteKitPartEditableInterface';
import QuoteNoteEditableInterface from './interfaces/QuoteNoteEditableInterface';
import QuoteDtoInterface from '../sageApi/interfaces/pullReport/QuoteDtoInterface';
import CustomQuoteKitToAddInterface from '../sageApi/interfaces/pullReport/CustomQuoteKitToAddInterface';
import QuoteKitPartDtoInterface from '../sageApi/interfaces/pullReport/QuoteKitPartDtoInterface';
import ContextPartDtoInterface from '../sageApi/interfaces/pullReport/ContextPartDtoInterface';
import ContextQuotePartDtoInterface from '../sageApi/interfaces/pullReport/ContextQuotePartInterface';
import QuotePartDtoInterface from '../sageApi/interfaces/pullReport/QuotePartDtoInterface';
import { ConfirmModalComponent } from 'src/app/Components/Platform/confirm-modal/confirm-modal.component';
import { BaseModalService } from '../BaseModalService/BaseModalService';
import QuoteKitDtoInterface from '../sageApi/interfaces/pullReport/QuoteKitDtoInterface';
import ContextQuoteOptionReplaceDtoInterface from '../sageApi/interfaces/pullReport/ContextQuoteOptionReplaceDtoInterface';
import QuoteOptionKitDtoInterface from '../sageApi/interfaces/pullReport/QuoteOptionKitDtoInterface';
import QuoteOptionKitPartDtoInterface from '../sageApi/interfaces/pullReport/QuoteOptionKitPartDtoInterface';
import { any } from 'codelyzer/util/function';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import QuoteOptionDtoInterface from '../sageApi/interfaces/pullReport/QuoteOptionDtoInterface';
import BuildLocationDtoInterface from '../sageApi/interfaces/pullReport/BuildLocationDtoInterface';
import TagDtoInterface from '../sageApi/interfaces/pullReport/TagDtoInterface';
import QuotePartTagDtoInterface from '../sageApi/interfaces/pullReport/QuotePartTagDtoInterface';
import ContextQuotePartTagDtoInterface from '../sageApi/interfaces/pullReport/ContextQuotePartTagDtoInterface';

/*
  All these interfaces will eventually live in their own files, but for now they are here because I am a lazy fuck!
*/

export interface PatchQuoteInterface {
  Quote_Name?: string;
  Quote_Desc?: string;
  Quote_Status?: string;
  Quote_Builder?: string;
  Quote_Region?: string;
  Quote_SquareFootage?: number;
  Quote_Expiration?: string;
  Quote_MaterialMargin?: number;
  Quote_LaborMargin?: number;
  Quote_GibsonMargin?: number;
  Quote_TaxMargin?: number;
}

export interface PostAddToQuotePartInterface  {
  Part_guid: string;
  quantity: number;
  phase: string;
  cost: number;
}

export interface PostAddToQuoteKitInterface  {
  Kit_guid: string;
  quantity: number;
}

export interface PostAddToQuoteCustomKitInterface {
  quantity: number;
  name: string;
  desc: string;
  region: string;
}

export interface PostAddToQuoteCustomPartInterface {
  quantity: number;
  name: string;
  desc: string;
  region: string;
}

export interface PostAddToQuoteInterface {
  parts: PostAddToQuotePartInterface[],
  kits: PostAddToQuoteKitInterface[]
  customKits: PostAddToQuoteCustomKitInterface[],
  customParts: PostAddToQuoteCustomPartInterface[]
}

export interface PatchQuoteKitPartInterface {
  QuoteKitPart_Quantity?: number;
  QuoteKitPart_Phase?: string;
  BuildLocation_guid?: string;
}

export interface PatchQuotePartInterface {
  QuotePart_Cost?: number;
  tags?: string[];
}

export interface putQuotePartTagsInterface {
  Tag_guid: string;
}

export interface PatchQuoteKitInterface {
  QuoteKit_Name?: string;
  QuoteKit_Desc?: string;
  QuoteKit_Region?: string;
}

export interface ContextKitsAndPartsInterface {
  parts: ContextQuoteKitPartDtoInterface[],
  kits: ContextQuoteKitPartDtoInterface[]
}

export interface PartBreakdownItemGroupInterface {
  name: string;
  partCost: number;
  totalCost: number;
  quantity: number;
  items: PartBreakdownItemInterface[];
  desc: string;
}

export interface PartBreakdownItemInterface {
  data: QuoteKitPartEditableDataInterface;
  cost: number;
  quantity: number;
  parents: string[];
  location: BuildLocationDtoInterface;
}

export interface OptionPartBreakdownItemGroupInterface {
  name: string;
  partCost: number;
  totalCost: number;
  quantity: number;
  items: OptionPartBreakdownItemInterface[];
  desc: string;
}

export interface OptionPartBreakdownItemInterface {
  data: QuoteOptionKitPartEditableDataInterface;
  cost: number;
  quantity: number;
  parents: string[];
}

export interface PostQuoteInclusionInterface {
  QuoteInclusion_Name: string; 
  QuoteInclusion_Desc: string;
}

export interface PatchQuoteInclusionInterface {
  QuoteInclusion_Name?: string; 
  QuoteInclusion_Desc?: string;
}

export interface PostQuoteExclusionInterface {
  QuoteExclusion_Name: string; 
  QuoteExclusion_Desc: string;
}

export interface PatchQuoteExclusionInterface {
  QuoteExclusion_Name?: string; 
  QuoteExclusion_Desc?: string;
}

export interface PostQuoteNoteInterface {
  QuoteNote_Name: string; 
  QuoteNote_Desc: string;
}

export interface PatchQuoteNoteInterface {
  QuoteNote_Name?: string; 
  QuoteNote_Desc?: string;
}

export interface PostQuoteBillingTermInterface {
  QuoteBillingTerm_Name: string; 
  QuoteBillingTerm_Desc: string;
}

export interface PatchQuoteBillingTermInterface {
  QuoteBillingTerm_Name?: string; 
  QuoteBillingTerm_Desc?: string;
}

export interface PostAddToQuoteOptionInterface {
  parts: {
    Part_guid: string;
    QuoteOptionKitPart_Phase: string;
    QuoteOptionKitPart_Quantity?: number;
    QuotePart_Cost?: number;
  }[];
  kits: {
    Kit_guid: string;
    QuoteOptionKitPart_Quantity: number;
  }[];
  customParts: {
    QuotePart_Code: string;
    QuotePart_Desc: string;
    QuotePart_Cost: number;
    QuoteOptionKitPart_Quantity: number;
    QuoteOptionKitPart_Phase: string;
  }[];
  customKits: {
    QuoteOptionKitPart_Quantity: number;
    QuoteOptionKit_Name: string;
    QuoteOptionKit_Desc: string;
    QuoteOptionKit_Region: string;
  }[];
}

export interface PatchQuoteOptionInterface {
  QuoteOption_Name?: string;
  QuoteOption_Desc?: string;
}

export interface ContextOptionKitsAndPartsInterface {
  parts: ContextQuoteOptionKitPartDtoInterface[],
  kits: ContextQuoteOptionKitPartDtoInterface[]
}

export interface PatchQuoteOptionKitInterface {
  QuoteOptionKit_Name?: string;
  QuoteOptionKit_Desc?: string;
  QuoteOptionKit_Region?: string;
}

export interface PatchQuoteOptionKitPartInterface {
  QuoteOptionKitPart_Quantity?: number;
  QuoteOptionKitPart_Phase?: string;
  BuildLocation_guid?: string;
  Tags?: string[];
}

export interface PostQuoteOptionInterface {
  QuoteOption_Name: string,
  QuoteOption_Desc: string;
}

export type QuoteEditableResponseType = 'loading'|'confirming'|'complete'|'canceled';

@Injectable({
  providedIn: 'root',
})
export class QuoteEditableService {

  public quote: QuoteDataEditableInterface|null = null;

  private buildLocations: BuildLocationDtoInterface[] = [];
  private tags: TagDtoInterface[] = [];

  public updated = new Subject<void>(); // Filthy hack => Forgive me whoever has to maintain this

  public loading: string[] = []; // Shamelessly stolen from my project, multi-is-loading.

  constructor(
    public api: SageApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private modals: BaseModalService,
  ) { }

  /* 
    Loading state stuff 
  */

  /**
   * 
   * @returns {boolean} Whether or not the service is currently loading data
   */
  isLoading(){
    return this.loading.length > 0;
  }

  /**
   * 
   * @returns {string} A unique loading id
   */
  getLoadingId(){
    return `${Math.random().toString(36).substring(7)}-${(new Date()).getTime()}`;
  }

  /**
   * 
   * @param {string} id The loading id to remove
   */
  removeLoadingId(id: string){
    this.loading = this.loading.filter((lid)=>lid != id);
  }

  /**
   * Creates a lid and add it to the loading state.
   * @returns {string} The newly-created loading id
   */
  startLoading(){
    const id = this.getLoadingId();
    this.loading.push(id);
    return id;
  }

  /**
   * Wrapper for Observables that handles loading states.
   * @param {Observable<any>} observable The observable to watch for loading states
   * @returns {Observable<any>} The observable that will emit the same values as the input observable, but will also emit 'loading' and 'complete' values
   */
  loadingUntilComplete(observable: Observable<any>){
    let id = 'NO_ID'
    observable.subscribe({
      next: (rt)=>{
        if(rt == 'loading'){
          id = this.startLoading();
          this.updated.next();
        }
        if(rt == 'complete'){
          this.removeLoadingId(id);
        }
      },
      error: ()=>{
        this.removeLoadingId(id);
      },
      complete: ()=>{
        this.removeLoadingId(id);
      }
    });
    return observable.pipe(shareReplay());
  }

  /* 
    API Quote stuff
  */

  /**
   * Gets the quote from the Quote_guid
   * @param options.quoteGuid The guid of the quote to get
   * @returns {Observable<ContextQuoteDtoInterface>} An observable that will emit the quote data
   */
  getQuoteReq(options: {
    quoteGuid: string, 
  }): Observable<ContextQuoteDtoInterface> {
    const obs = new Observable<ContextQuoteDtoInterface>((subscriber)=>{
      this.api.pullReport(`quote/${options.quoteGuid}`).subscribe((data: ContextQuoteDtoInterface)=>{
        subscriber.next(data);
        subscriber.complete();
      },
      (err)=>{
        console.log(err);
        subscriber.error(err);
        subscriber.complete();
      });
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * Patches the quote with the given changes
   * @param options.quoteGuid The guid of the quote to patch
   * @param options.quoteChanges The changes to apply to the quote
   * @returns {Observable<QuoteDtoInterface>} An observable that will emit the updated quote data
   */
  patchQuoteReq(options: {
    quoteGuid: string,
    quoteChanges: PatchQuoteInterface
  }): Observable<QuoteDtoInterface> {
    const obs = new Observable<QuoteDtoInterface>((subscriber)=>{
      const quotePatchReq = this.api.patchRequest(`quote/${options.quoteGuid}`, options.quoteChanges);
      quotePatchReq.subscribe((data: QuoteDtoInterface)=>{
        subscriber.next(data);
        subscriber.complete();
      }, 
      (err)=>{
        console.log(err);
        subscriber.error(err);
        subscriber.complete();
      });
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for adding parts, custom parts, kits and custom kits to a quote.
   * @param options The options for the request 
   * @returns {Observable<ContextKitsAndPartsInterface>} An observable that will emit the updated quote data
   */
  postAddToQuoteReq(options: {
    quoteGuid: string,
    parentQuoteKitPartGuid?: string,
    quoteKitPartsToAdd: PostAddToQuoteInterface,
    showErrors?: boolean
  }): Observable<ContextKitsAndPartsInterface> {
    const obs = new Observable<ContextKitsAndPartsInterface>((subscriber)=>{
      let url = `add-to-quote/${options.quoteGuid}`;
      if(options.parentQuoteKitPartGuid != null){
        url += `/${options.parentQuoteKitPartGuid}`;
      }
      this.api.postRequest(
        url, 
        options.quoteKitPartsToAdd
      ).subscribe((data: ContextKitsAndPartsInterface)=>{
        subscriber.next(data);
        subscriber.complete();
      }, 
      (err)=>{
        console.log(err);
        subscriber.error(err);
        subscriber.complete();
      },
      ()=>{
        
      });
    });const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for patching a QuoteKitPart.
   * @param options The options for the request 
   * @returns {Observable<QuoteKitPartDtoInterface>} An observable that will emit the updated quote data
   */
  patchQuoteKitPartReq(options: {
    quoteKitPartGuid: string;
    quoteKitPart: PatchQuoteKitPartInterface;
  }): Observable<QuoteKitPartDtoInterface> {
    const obs = new Observable<QuoteKitPartDtoInterface>((subscriber)=>{
      this.api.patchRequest(`quotekitpart/${options.quoteKitPartGuid}`, options.quoteKitPart).subscribe(
        (data: QuoteKitPartDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for patching a QuotePart.
   * @param options The options for the request 
   * @returns {Observable<QuotePartDtoInterface>} An observable that will emit the updated quote data
   */
  patchQuotePartReq(options: {
    quotePartGuid: string;
    quotePart: PatchQuotePartInterface;
  }): Observable<QuotePartDtoInterface> {
    const obs = new Observable<QuotePartDtoInterface>((subscriber)=>{
      this.api.patchRequest(`quotepart/${options.quotePartGuid}`, options.quotePart).subscribe(
        (data: QuotePartDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for changing the QuotePartTags on a QuotePart.
   * @param options The options for the request 
   * @returns {Observable<QuotePartTagDtoInterface[]>} An observable that will emit the updated quote data
   */
  putQuotePartTagsReq(options: {
    quotePartGuid: string;
    quotePartTags: putQuotePartTagsInterface[];
  }): Observable<ContextQuotePartTagDtoInterface[]> {
    const obs = new Observable<ContextQuotePartTagDtoInterface[]>((subscriber)=>{
      this.api.putRequest(`quote-part-tags/${options.quotePartGuid}`, options.quotePartTags).subscribe(
        (data: ContextQuotePartTagDtoInterface[])=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for patching a QuoteKit.
   * @param options The options for the request 
   * @returns {Observable<QuoteKitDtoInterface>} An observable that will emit the updated quote data
   */
  patchQuoteKitReq(options: {
    quoteKitGuid: string;
    quoteKit: PatchQuoteKitInterface;
  }): Observable<QuoteKitDtoInterface> {
    const obs = new Observable<QuoteKitDtoInterface>((subscriber)=>{
      this.api.patchRequest(`quotekit/${options.quoteKitGuid}`, options.quoteKit).subscribe(
        (data: QuoteKitDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for adding a QuoteInclusion.
   * @param options The options for the request
   * @returns {Observable<QuoteInclusionDtoInterface>} An observable that will emit the updated quote data
   */
  postQuoteInclusionReq(options: {
    quoteGuid: string;
    quoteInclusion: PostQuoteInclusionInterface;
  }){
    const obs = new Observable<QuoteInclusionDtoInterface>((subscriber)=>{
      this.api.postRequest(`quote-inclusion/${options.quoteGuid}`, options.quoteInclusion).subscribe(
        (data: QuoteInclusionDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for patching a QuoteInclusion.
   * @param options The options for the request
   * @returns {Observable<QuoteInclusionDtoInterface>} An observable that will emit the updated quote data
   */
  patchQuoteInclusionReq(options: {
    quoteInclusionGuid: string;
    quoteInclusion: PatchQuoteInclusionInterface;
  }): Observable<QuoteInclusionDtoInterface> {
    const obs = new Observable<QuoteInclusionDtoInterface>((subscriber)=>{
      this.api.patchRequest(`quote-inclusion/${options.quoteInclusionGuid}`, options.quoteInclusion).subscribe(
        (data: QuoteInclusionDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for deleting a QuoteInclusion.
   * @param options The options for the request
   * @returns {Observable<void>} An observable that emits nothing when completed, and an error if there was an issue
  */
  deleteQuoteInclusionReq(options: {
    quoteInclusionGuid: string;
  }): Observable<void> {
    const obs = new Observable<void>((subscriber)=>{
      this.api.deleteRequest(`quote-inclusion/${options.quoteInclusionGuid}`).subscribe(
        (data)=>{
          subscriber.next();
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for adding a QuoteExclusion.
   * @param options The options for the request
   * @returns {Observable<QuoteExclusionDtoInterface>} An observable that will emit the updated quote data
   */
  postQuoteExclusionReq(options: {
    quoteGuid: string,
    quoteExclusion: PostQuoteExclusionInterface
  }): Observable<QuoteExclusionDtoInterface> {
    const obs = new Observable<QuoteExclusionDtoInterface>((subscriber)=>{
      this.api.postRequest(
        `quote-exclusion/${options.quoteGuid}`, 
        options.quoteExclusion
      ).subscribe((data: QuoteExclusionDtoInterface)=>{
        subscriber.next(data);
        subscriber.complete();
      },
      (err)=>{
        console.log(err);
        subscriber.error(err);
        subscriber.complete();
      });
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for patching a QuoteExclusion.
   * @param options The options for the request
   * @returns {Observable<QuoteExclusionDtoInterface>} An observable that will emit the updated quote data
   */
  patchQuoteExclusionReq(options: {
    quoteExclusionGuid: string;
    quoteExclusion: PatchQuoteExclusionInterface;
  }): Observable<QuoteExclusionDtoInterface> {
    const obs = new Observable<QuoteExclusionDtoInterface>((subscriber)=>{
      this.api.patchRequest(`quote-exclusion/${options.quoteExclusionGuid}`, options.quoteExclusion).subscribe(
        (data: QuoteExclusionDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for deleting a QuoteExclusion.
   * @param options The options for the request
   * @returns {Observable<void>} An observable that emits nothing when completed, and an error if there was an issue
   */
  deleteQuoteExclusionReq(options: {
    quoteExclusionGuid: string;
  }): Observable<void> {
    const obs = new Observable<void>((subscriber)=>{
      this.api.deleteRequest(`quote-exclusion/${options.quoteExclusionGuid}`).subscribe(
        (data)=>{
          subscriber.next();
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for adding a QuoteNote.
   * @param options The options for the request
   * @returns {Observable<QuoteNoteDtoInterface>} An observable that will emit the updated quote data
   */
  postQuoteNoteReq(options: {
    quoteNote: PostQuoteNoteInterface
  }){
    const obs = new Observable<QuoteNoteDtoInterface>((subscriber)=>{
      this.api.postRequest(`quote-note/${this.quote.data.Quote.Quote_guid}`, options.quoteNote).subscribe(
        (data: QuoteNoteDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for patching a QuoteNote.
   * @param options The options for the request
   * @returns {Observable<QuoteNoteDtoInterface>} An observable that will emit the updated quote data
   */
  patchQuoteNoteReq(options: {
    quoteNoteGuid: string;
    quoteNote: PatchQuoteNoteInterface;
  }){
    const obs = new Observable<QuoteNoteDtoInterface>((subscriber)=>{
      this.api.patchRequest(`quote-note/${options.quoteNoteGuid}`, options.quoteNote).subscribe(
        (data: QuoteNoteDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for deleting a QuoteNote.
   * @param options The options for the request
   * @returns {Observable<void>} An observable that emits nothing when completed, and an error if there was an issue
   */
  deleteQuoteNoteReq(options: {
    quoteNoteGuid: string;
  }): Observable<void> {
    const obs = new Observable<void>((subscriber)=>{
      this.api.deleteRequest(`quote-note/${options.quoteNoteGuid}`).subscribe(
        (data)=>{
          subscriber.next();
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for adding a QuoteBillingTerm.
   * @param options The options for the request
   * @returns {Observable<QuoteBillingTermDtoInterface>} An observable that will emit the updated quote data
   */
  postQuoteBillingTermReq(options: {
    quoteGuid: string,
    quoteBillingTerm: PostQuoteBillingTermInterface,
  }){
    const obs = new Observable<QuoteBillingTermDtoInterface>((subscriber)=>{
      this.api.postRequest(`quote-billing-term/${this.quote.data.Quote.Quote_guid}`, options.quoteBillingTerm).subscribe(
        (data: QuoteBillingTermDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for patching a QuoteBillingTerm.
   * @param options The options for the request
   * @returns {Observable<QuoteBillingTermDtoInterface>} An observable that will emit the updated quote data
   */
  patchQuoteBillingTermReq(options: {
    quoteBillingTermGuid: string,
    quoteBillingTerm: PatchQuoteBillingTermInterface
  }){
    const obs = new Observable<QuoteBillingTermDtoInterface>((subscriber)=>{
      this.api.patchRequest(`quote-billing-term/${options.quoteBillingTermGuid}`, options.quoteBillingTerm).subscribe(
        (data: QuoteBillingTermDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for deleting a QuoteBillingTerm.
   * @param options The options for the request
   * @returns {Observable<void>} An observable that emits nothing when completed, and an error if there was an issue
  */
  deleteQuoteBillingTermReq(options: {
    quoteBillingTermGuid: string
  }){
    const obs = new Observable<void>((subscriber)=>{
      this.api.deleteRequest(`quote-billing-term/${options.quoteBillingTermGuid}`).subscribe(
        (data)=>{
          subscriber.next();
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * Creates a QuoteOption and returns it to the user.
   * @param options 
   * @returns {Observable<ContextQuoteOptionDtoInterface>}
   */
  postQuoteOptionReq(options: {
    quoteGuid: string,
    quoteOption: PostQuoteOptionInterface,
  }): Observable<QuoteOptionDtoInterface> {
    const obs = new Observable<QuoteOptionDtoInterface>((subscriber)=>{
      this.api.postRequest(`quote-option/${options.quoteGuid}`, options.quoteOption).subscribe(
        (data: QuoteOptionDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * Destroys the provided QuoteOption.
   * @param {{quoteOptionGuid: string}} options 
   * @returns {void}
   */
  deleteQuoteOptionReq(options: {
    quoteOptionGuid: string,
  }): Observable<QuoteOptionDtoInterface> {
    const obs = new Observable<QuoteOptionDtoInterface>((subscriber)=>{
      this.api.deleteRequest(`quote-option/${options.quoteOptionGuid}`).subscribe(
        ()=>{
          subscriber.next();
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for changing all the credited parts on an option.
   * @param options The options for the request
   * @returns {Observable<ContextQuoteOptionDtoInterface>} An observable that will emit all of the credited parts
   */
  putQuoteOptionReplaceReq(options: {
    quoteOptionGuid: string,
    quoteKitParts: string[],
  }){
    const obs = new Observable<ContextQuoteOptionReplaceDtoInterface[]>((subscriber)=>{
      this.api.putRequest(`quote-option-replace/${options.quoteOptionGuid}`, options.quoteKitParts).subscribe(
        (data: ContextQuoteOptionReplaceDtoInterface[])=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for adding a QuoteOptionKitPart.
   * @param options The options for the request
   * @returns {Observable<ContextQuoteOptionDtoInterface>} An observable that will emit the updated QuoteOptionKitPart data
   */
  postAddToQuoteOptionReq(options: {
    quoteOptionGuid: string,
    quoteOptionParts: PostAddToQuoteOptionInterface,
    parentQuoteOptionKitPartGuid?: string,
  }){
    const obs = new Observable<ContextOptionKitsAndPartsInterface>((subscriber)=>{
      let url = `add-to-quote-option/${options.quoteOptionGuid}`;
      if(options.parentQuoteOptionKitPartGuid != null){
        url += `/${options.parentQuoteOptionKitPartGuid}`;
      }
      this.api.postRequest(url, options.quoteOptionParts).subscribe(
        (data: ContextOptionKitsAndPartsInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * Changes the QuoteOption according to the provided changes.
   * @param options 
   * @returns {QuoteOptionDtoInterface}
   */
  patchQuoteOptionReq(options: {
    quoteOptionGuid: string,
    quoteOption: PatchQuoteOptionInterface,
  }): Observable<QuoteOptionDtoInterface> {
    const obs = new Observable<QuoteOptionDtoInterface>((subscriber)=>{
      this.api.patchRequest(`quote-option/${options.quoteOptionGuid}`, options.quoteOption).subscribe(
        (data: QuoteOptionDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for patching a QuoteOptionKit.
   * @param options The options for the request
   * @returns {Observable<QuoteOptionKitDtoInterface>} An observable that will emit the updated QuoteOptionKit data
   */
  patchQuoteOptionKitReq(options: {
    quoteOptionKitGuid: string;
    quoteOptionKit: PatchQuoteOptionKitInterface;
  }): Observable<QuoteOptionKitDtoInterface> {
    const obs = new Observable<QuoteOptionKitDtoInterface>((subscriber)=>{
      this.api.patchRequest(`quote-option-kit/${options.quoteOptionKitGuid}`, options.quoteOptionKit).subscribe(
        (data: QuoteOptionKitDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for patching a QuoteOptionKitPart.
   * @param options The options for the request
   * @returns {Observable<QuoteOptionKitPartDtoInterface>} An observable that will emit the updated QuoteOptionKitPart data
   */
  patchQuoteOptionKitPartReq(options: {
    quoteOptionKitPartGuid: string;
    quoteOptionKitPart: PatchQuoteOptionKitPartInterface;
  }): Observable<QuoteOptionKitPartDtoInterface> {
    const obs = new Observable<QuoteOptionKitPartDtoInterface>((subscriber)=>{
      this.api.patchRequest(`quote-option-kitpart/${options.quoteOptionKitPartGuid}`, options.quoteOptionKitPart).subscribe(
        (data: QuoteOptionKitPartDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * The API request for deleting a QuoteOptionKitPart.
   * @param options The options for the request
   * @returns {Observable<void>} An observable that emits nothing when completed, and an error if there was an issue
   */
  deleteQuoteOptionKitPartsReq(options: {
    quoteOptionKitPartGuids: string[];
  }): Observable<void> {
    const obs = new Observable<void>((subscriber)=>{
      this.api.deleteRequest(`quote-option-kitparts?QuoteOptionKitPart_guid=${options.quoteOptionKitPartGuids.join('&QuoteOptionKitPart_guid=')}`).subscribe(
        (data)=>{
          subscriber.next();
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * Gets all the build locations in the db
   * @returns {Observable<BuildLocationDtoInterface[]>} An observable that will emit all the build locations
   */
  getBuildLocationsReq(): Observable<BuildLocationDtoInterface[]> {
    const obs = new Observable<BuildLocationDtoInterface[]>((subscriber)=>{
      this.api.pullReport('buildlocations').subscribe(
        (data: BuildLocationDtoInterface[])=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * Gets all the tags in the db
   * @returns {Observable<TagDtoInterface[]>} An observable that will emit all the tags
   */
  getTagsReq(): Observable<TagDtoInterface[]> {
    const obs = new Observable<TagDtoInterface[]>((subscriber)=>{
      this.api.pullReport('tags').subscribe(
        (data: TagDtoInterface[])=>{
          subscriber.next(data);
          subscriber.complete();
        },
        (err)=>{
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /* 
    Creation
  */

  /**
   * This will open a quote and create all the editable fields for it.
   * @param options 
   * @returns 
   */
  initQuote(options: {quoteGuid: string; showErrors?: boolean}): Observable<void> {
    this.setBuildLocations();
    this.setTags();
    const obs = new Observable<void>((subscriber)=>{
      this.getQuoteReq({quoteGuid: options.quoteGuid}).subscribe(
        (data)=>{
          this.setQuote({quote: data});
          subscriber.next();
          subscriber.complete();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to open quote. Has it been deleted?', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This will add a part to the quote.
   * @param {ContextQuoteDtoInterface} options.quote
   * @returns void
   */
  setQuote(options: {quote: ContextQuoteDtoInterface}){
    this.quote = {
      // Data
      data: {
        Quote: options.quote.Quote
      },
      // Context -> Just a fancy way of saying related tables which gives you "context" about how this data might be used or what it is in relation to
      children: [],
      exclusions: [],
      inclusions: [],
      notes: [],
      billingTerms: [],
      options: [],
    };
    this.quote.children = options.quote.QuoteKitParts.map((qkp)=>this.getQuoteKitPartEditable(qkp));
    this.quote.exclusions = options.quote.QuoteExclusions.map((excl)=>this.getQuoteExclusionEditable(excl));
    this.quote.inclusions = options.quote.QuoteInclusions.map((incl)=>this.getQuoteInclusionEditable(incl));
    this.quote.notes = options.quote.QuoteNotes.map((note)=>this.getQuoteNoteEditable(note));
    this.quote.billingTerms = options.quote.QuoteBillingTerms.map((bt)=>this.getQuoteBillingTermEditable(bt));
    this.quote.options = options.quote.QuoteOptions.map((opt)=>this.getQuoteOptionEditable(opt));
    this.updated.next();
  }

  /**
   * Takes a ContextQuoteKitPartDtoInterface and returns it's editable form.
   * @param {ContextQuoteKitPartDtoInterface} qkp
   * @returns {QuoteKitPartEditableInterface}
   */
  getQuoteKitPartEditable(qkp: ContextQuoteKitPartDtoInterface): QuoteKitPartEditableInterface {
    if(qkp.QuotePart){
      const editable: QuoteKitPartEditableInterface = {
        data: {
          QuoteKitPart: qkp.QuoteKitPart,
          QuotePart: qkp.QuotePart,
          BuildLocation: qkp.BuildLocation,
        },
        editable: true,
        expanded: false,
        selected: new FormControl(false),
        nameControl: new FormControl(qkp.QuotePart?.QuotePart.QuotePart_Code, [Validators.required]),
        descControl: new FormControl(qkp.QuotePart?.QuotePart.QuotePart_Desc, [Validators.required]),
        costControl: new FormControl(qkp.QuotePart?.QuotePart.QuotePart_Cost, [Validators.required, Validators.min(0.0001), Validators.max(99999999.9999)]),
        quantityControl: new FormControl(qkp.QuoteKitPart.QuoteKitPart_Quantity, [Validators.required, Validators.min(1), Validators.max(9999)]),
        phaseControl: new FormControl(qkp.QuoteKitPart.QuoteKitPart_Phase, [Validators.required]),
        buildLocationControl: new FormControl(qkp?.BuildLocation?.BuildLocation_Code||'', []),
        tagsControl:  new FormArray(qkp.QuotePart.QuotePartTags.map(tag=>new FormControl(tag.Tag.Tag_Name)), []),
      };
      return editable;
    }else if(qkp.QuoteKit){
      const editable: QuoteKitPartEditableInterface = {
        data: {
          QuoteKitPart: qkp.QuoteKitPart,
          QuoteKit: qkp.QuoteKit,
          BuildLocation: qkp.BuildLocation,
        },
        editable: true,
        expanded: false,
        selected: new FormControl(false),
        nameControl: new FormControl(qkp.QuoteKit.QuoteKit.QuoteKit_Name, [Validators.required]),
        descControl: new FormControl(qkp.QuoteKit.QuoteKit.QuoteKit_Desc, [Validators.required]),
        costControl: new FormControl(1, [Validators.required, Validators.min(0.0001), Validators.max(99999999.9999)]),
        quantityControl: new FormControl(qkp.QuoteKitPart.QuoteKitPart_Quantity, [Validators.required, Validators.min(1), Validators.max(9999)]),
        phaseControl: new FormControl(qkp.QuoteKitPart.QuoteKitPart_Phase, [Validators.required]),
        buildLocationControl: new FormControl(qkp?.BuildLocation?.BuildLocation_Code||'', []),
        tagsControl:  new FormArray([], []),
      };
      editable.children = qkp.QuoteKit.QuoteKitParts.map((qkp)=>{
        return this.getQuoteKitPartEditable(qkp);
      });
      return editable;
    }
    throw new Error('QuoteKitPartEditableInterface could not be created - no QuotePart or QuoteKit found in ContextQuoteKitPartDtoInterface');
  }

  /**
   * Takes a QuoteExclusionDtoInterface and returns it's editable form.
   * @param {QuoteExclusionDtoInterface} exclusion
   * @returns {QuoteExclusionEditableInterface}
   */
  getQuoteExclusionEditable(exclusion: QuoteExclusionDtoInterface): QuoteExclusionEditableInterface {
    const newExclusion: QuoteExclusionEditableInterface = {
      data: exclusion,
      nameControl: new FormControl(exclusion.QuoteExclusion_Name, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      descControl: new FormControl(exclusion.QuoteExclusion_Desc, [Validators.maxLength(1024)]),
    };
    return newExclusion;
  }

  /**
   * Takes a QuoteInclusionDtoInterface and returns it's editable form.
   * @param {QuoteInclusionDtoInterface} inclusion
   * @returns {QuoteInclusionEditableInterface}
   */
  getQuoteInclusionEditable(inclusion: QuoteInclusionDtoInterface): QuoteInclusionEditableInterface {
    const newInclusion: QuoteInclusionEditableInterface = {
      data: inclusion,
      nameControl: new FormControl(inclusion.QuoteInclusion_Name, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      descControl: new FormControl(inclusion.QuoteInclusion_Desc, [Validators.maxLength(1024)]),
    };
    return newInclusion;
  }

  /**
   * Takes a QuoteNoteDtoInterface and returns it's editable form.
   * @param {QuoteNoteDtoInterface} note 
   * @returns {QuoteNoteEditableInterface}
   */
  getQuoteNoteEditable(note: QuoteNoteDtoInterface): QuoteNoteEditableInterface {
    const newNote: QuoteNoteEditableInterface = {
      data: note,
      nameControl: new FormControl(note.QuoteNote_Name, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      descControl: new FormControl(note.QuoteNote_Desc, [Validators.maxLength(1024)]),
    };
    return newNote;
  }

  /**
   * Takes a QuoteBillingTermDtoInterface and returns it's editable form.
   * @param {QuoteBillingTermDtoInterface} billingTerm
   * @returns {QuoteBillingTermEditableInterface}
   */
  getQuoteBillingTermEditable(billingTerm: QuoteBillingTermDtoInterface): QuoteBillingTermEditableInterface {
    const newBillingTerm: QuoteBillingTermEditableInterface = {
      data: billingTerm,
      nameControl: new FormControl(billingTerm.QuoteBillingTerm_Name, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      descControl: new FormControl(billingTerm.QuoteBillingTerm_Desc, [Validators.maxLength(1024)]),
    };
    return newBillingTerm;
  }

  /**
   * Takes a ContextQuoteOptionDtoInterface and returns it's editable form.
   * @param {ContextQuoteOptionDtoInterface} opt
   * @returns {QuoteOptionEditableInterface}
   */
  getQuoteOptionEditable(opt: ContextQuoteOptionDtoInterface): QuoteOptionEditableInterface {
    return {
      data: opt.QuoteOption,
      children: opt.QuoteOptionKitParts.map(qokp => this.getQuoteOptionKitPartEditable(qokp)),
      creditedQuoteKitParts: opt.QuoteOptionReplaces.map(replace => this.getQuoteOptionReplaceEditable(replace)),
      creditedQuoteKitPartsControl: opt.QuoteOptionReplaces.map(replace => this.getQuoteOptionRelaceControl(replace)),
      nameControl: new FormControl(opt.QuoteOption.QuoteOption_Name, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      descControl: new FormControl(opt.QuoteOption.QuoteOption_Desc, [Validators.maxLength(255)]),
    };
  }

  /**
   * Takes a ContextQuoteOptionKitPartDtoInterface and returns it's editable form.
   * @param {ContextQuoteOptionKitPartDtoInterface} qokp
   * @returns {QuoteOptionKitPartEditableInterface}
   */
  getQuoteOptionKitPartEditable(qokp: ContextQuoteOptionKitPartDtoInterface): QuoteOptionKitPartEditableInterface {
    if(qokp.QuoteOptionPart){
      const editable: QuoteOptionKitPartEditableInterface = {
        data: {
          QuoteOptionPart: qokp.QuoteOptionPart,
          QuoteOptionKitPart: qokp.QuoteOptionKitPart,
          BuildLocation: qokp.BuildLocation,
        },
        editable: true,
        expanded: false,
        selected: new FormControl(false),
        nameControl: new FormControl(qokp.QuoteOptionPart?.QuotePart.QuotePart_Code, [Validators.required]),
        descControl: new FormControl(qokp.QuoteOptionPart?.QuotePart.QuotePart_Desc, [Validators.required]),
        costControl: new FormControl(qokp.QuoteOptionPart?.QuotePart.QuotePart_Cost, [Validators.required, Validators.min(0.0001), Validators.max(99999999.9999)]),
        quantityControl: new FormControl(qokp.QuoteOptionKitPart.QuoteOptionKitPart_Quantity, [Validators.required, Validators.min(1), Validators.max(9999)]),
        phaseControl: new FormControl(qokp.QuoteOptionKitPart.QuoteOptionKitPart_Phase, [Validators.required]),
        buildLocationControl: new FormControl(qokp?.BuildLocation?.BuildLocation_Code||'', []),
        tagsControl: new FormArray(qokp.QuoteOptionPart.QuotePartTags.map(tag=>new FormControl(tag.Tag.Tag_Name)), []),
      };
      return editable;
    }else if(qokp.QuoteOptionKit){
      const editable: QuoteOptionKitPartEditableInterface = {
        data: {
          QuoteOptionKitPart: qokp.QuoteOptionKitPart,
          QuoteOptionKit: qokp.QuoteOptionKit,
          BuildLocation: qokp.BuildLocation,
        },
        editable: true,
        expanded: false,
        selected: new FormControl(false),
        nameControl: new FormControl(qokp.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Name, [Validators.required]),
        descControl: new FormControl(qokp.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Desc, [Validators.required]),
        costControl: new FormControl(1, [Validators.required, Validators.min(0.0001), Validators.max(99999999.9999)]),
        quantityControl: new FormControl(qokp.QuoteOptionKitPart.QuoteOptionKitPart_Quantity, [Validators.required, Validators.min(1), Validators.max(9999)]),
        phaseControl: new FormControl(qokp.QuoteOptionKitPart.QuoteOptionKitPart_Phase, [Validators.required]),
        buildLocationControl: new FormControl(qokp?.BuildLocation?.BuildLocation_Code||'', []),
        tagsControl: new FormArray([], []),
      };
      editable.children = qokp.QuoteOptionKit.QuoteOptionKitParts.map((qkp)=>{
        return this.getQuoteOptionKitPartEditable(qkp);
      });
      return editable;
    }
  }

  /**
   * Takes a ContextQuoteOptionReplaceDtoInterface and returns it's editable form.
   * @param {ContextQuoteOptionReplaceDtoInterface} replace 
   * @returns {QuoteOptionReplaceEditableInterface}
   */
  getQuoteOptionReplaceEditable(replace: ContextQuoteOptionReplaceDtoInterface): QuoteOptionReplaceEditableInterface{
    return {
      data: replace
    };
  }

  /**
   * Returns the QuoteKitPart_guid
   * @param {ContextQuoteOptionReplaceDtoInterface} replace 
   * @returns string
   */
  getQuoteOptionRelaceControl(replace: ContextQuoteOptionReplaceDtoInterface){
    return replace.QuoteKitPart.QuoteKitPart_guid;
  }

  /**
   * Sets the service's buildLocations variable and returns the BuildLocations.
   * @param {{showErrors?: boolean}} options 
   * @returns {Observable<QuoteEditableResponseType>}
   */
  setBuildLocations(options?: {
    showErrors?: boolean
  }): Observable<QuoteEditableResponseType> {
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      subscriber.next('loading');
      this.getBuildLocationsReq().subscribe(
        (data)=>{
          this.buildLocations = data;
          subscriber.next('complete');
          subscriber.complete();
        },
        (err)=>{
          if(options?.showErrors === undefined || options?.showErrors == true){
            this.snackBar.open('Failed to load build locations. Please try again.', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * Sets the service's tags variable and returns the tags.
   * @param options 
   * @returns {Observable<QuoteEditableResponseType>}
   */
  setTags(options?: {
    showErrors?: boolean
  }): Observable<QuoteEditableResponseType> {
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      subscriber.next('loading');
      this.getTagsReq().subscribe(
        (data)=>{
          this.tags = data;
          subscriber.next('complete');
          subscriber.complete();
        },
        (err)=>{
          if(options?.showErrors === undefined || options?.showErrors == true){
            this.snackBar.open('Failed to load tags. Please try again.', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }
  
  /**
   * This will save the changes to the quote object.
   * @param options 
   * @returns {Observable<void>} Emits void when the changes have been saved and an error if there was an issue
   */
  changeQuote(options: {
    quoteChanges: PatchQuoteInterface,
    showErrors?: boolean
  }): Observable<QuoteEditableResponseType> {
    if(this.quote == null){
      return new Observable<QuoteEditableResponseType>((subscriber)=>{
        subscriber.error('No quote loaded');
      });
    }
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      this.patchQuoteReq({quoteGuid: this.quote?.data.Quote.Quote_guid, quoteChanges: options.quoteChanges}).subscribe((data)=>{
        this.quote.data.Quote = data;
        subscriber.next('complete');
        subscriber.complete();
        this.updated.next();
      },
      (err)=>{
        console.log(err);
        if(options.showErrors === undefined || options.showErrors == true){
          this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
        }
        subscriber.error(err);
        subscriber.complete();
        this.updated.next();
      });
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This adds parts, custom parts, kits and custom kits to the quote
   * @param options
   * @returns {Observable<QuoteEditableResponseType>} Emits 'complete' when the changes have been saved and an error if there was an issue
  */
  addToQuote(options: {
    kitsAndPartsToAdd: PostAddToQuoteInterface,
    parentQuoteKitPart?: QuoteKitPartEditableInterface|QuoteDataEditableInterface,
    showErrors?: boolean
  }): Observable<QuoteEditableResponseType> {
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      if(options.parentQuoteKitPart && 'QuoteKitPart' in options.parentQuoteKitPart.data){
        subscriber.next('loading');
        this.postAddToQuoteReq({
          quoteGuid: this.quote.data.Quote.Quote_guid,
          parentQuoteKitPartGuid: options.parentQuoteKitPart?.data?.QuoteKitPart?.QuoteKitPart_guid,
          quoteKitPartsToAdd: options.kitsAndPartsToAdd
        }).subscribe(
          (data: ContextKitsAndPartsInterface)=>{
            // One of the few times I edit things by reference instead of by value because the tree is too damn deep
            const kitToAddTo = options.parentQuoteKitPart;
            // First, go through the QuoteKitParts and check if there is a matching data.parts
            for(let part of data.parts){
              const partIndex = kitToAddTo.children.findIndex((qkp)=>{
                // return qkp.data.QuotePart?.QuotePart.QuotePart_guid == part.QuotePart?.QuotePart.QuotePart_guid && qkp.data?.QuoteKitPart?.QuoteKitPart_Phase == part?.QuoteKitPart?.QuoteKitPart_Phase;
                return qkp.data.QuotePart?.QuotePart.QuotePart_guid == part.QuotePart?.QuotePart.QuotePart_guid;
              });
              if(partIndex > -1){
                // Construct a QuoteKitPartEditableInterface from part
                const partEditable = this.getQuoteKitPartEditable(part);
                kitToAddTo.children[partIndex] = partEditable;
              }else{
                const partEditable = this.getQuoteKitPartEditable(part);
                kitToAddTo.children.push(partEditable);
              }
            }
            // Construct a QuoteKitPartEditableInterface from the data.kits
            const kits = data.kits.map((kit)=>{
              return this.getQuoteKitPartEditable(kit);
            });
            kitToAddTo.children.push(...kits);
            subscriber.next('complete');
            subscriber.complete();
            this.updated.next();
          }, 
          (err)=>{
            if(options.showErrors === undefined || options.showErrors == true){
              this.snackBar.open('Failed to add to quote', 'Close', {duration: Infinity});
            }
            subscriber.error(err);
            subscriber.complete();
            this.updated.next();
          }
        );
      }else{
        subscriber.next('loading');
        this.postAddToQuoteReq({
          quoteGuid: this.quote.data.Quote.Quote_guid,
          quoteKitPartsToAdd: options.kitsAndPartsToAdd
        }).subscribe((data: ContextKitsAndPartsInterface)=>{
          // First, generate QuoteKitPartEditableInterface from the data.parts and data.kits
          const parts = data.parts.map((part)=>{
            return this.getQuoteKitPartEditable(part);
          });
          const kits = data.kits.map((kit)=>{
            return this.getQuoteKitPartEditable(kit);
          });
          // See if the QuoteKitPart already exists. If it does, replace it with the new one
          for(let part of parts){
            const partIndex = this.quote.children.findIndex((qkp)=>{
              return qkp?.data?.QuoteKitPart?.QuoteKitPart_guid == part?.data?.QuoteKitPart?.QuoteKitPart_guid;
            });
  
            if(partIndex > -1){
              // Construct a QuoteKitPartEditableInterface from part
              const partEditable = part;
              this.quote.children[partIndex] = partEditable;
            }else{
              const partEditable = part;
              this.quote.children.push(partEditable);
            }
          }
          this.quote.children.push(...kits);
          this.deselectChildren();
          subscriber.next('complete');
          subscriber.complete();
          this.updated.next();
        }, 
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to add to quote', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        });
      }
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This will save the changes to cost of all matching parts.
   * @param options 
   * @returns {void}
   */
  updateAllQuotePartsCost(options: {
    quotePartGuid: string;
    quotePartCost: number;
  }){
    
    const updateQuoteKitParts = (quoteKitParts: QuoteKitPartEditableInterface[])=>{
      // Go over all the children. If they are a part, see if they match the quotePart guid. If they do, update them. If it's a kit, go into the kit and do the same
      for(let child of quoteKitParts){
        if(options.quotePartGuid == child.data?.QuotePart?.QuotePart?.QuotePart_guid){
          child.data.QuotePart.QuotePart.QuotePart_Cost = options.quotePartCost;
          child.costControl.setValue(options.quotePartCost);
        }else if(child?.children != null && child.children.length > 0){
          updateQuoteKitParts(child.children);
        }
      }
    }
    updateQuoteKitParts(this.quote.children);

    const updateQuoteOption = (quoteOption: QuoteOptionEditableInterface)=>{
      const updateQuoteOptionKitParts = (quoteOptionKitParts: QuoteOptionKitPartEditableInterface[])=>{
        for(let child of quoteOptionKitParts){
          if(options.quotePartGuid == child.data?.QuoteOptionPart?.QuotePart?.QuotePart_guid){
            child.data.QuoteOptionPart.QuotePart.QuotePart_Cost = options.quotePartCost;
            child.costControl.setValue(options.quotePartCost);
          }else if(child?.children != null && child.children.length > 0){
            updateQuoteOptionKitParts(child.children);
          }
        }
      }
      updateQuoteOptionKitParts(quoteOption.children);
    }
    for(let option of this.quote.options){
      updateQuoteOption(option);
    }
  }

  /**
   * This will save the changes to the tags of all matching parts.
   * @param options 
   */
  updateAllQuotePartsTags(options: {
    quotePartGuid: string;
    quotePartTags: ContextQuotePartTagDtoInterface[]
  }){
    const updateQuoteKitParts = (quoteKitParts: QuoteKitPartEditableInterface[])=>{
      // Go over all the children. If they are a part, see if they match the quotePart guid. If they do, update them. If it's a kit, go into the kit and do the same
      for(let child of quoteKitParts){
        if(options.quotePartGuid == child.data?.QuotePart?.QuotePart?.QuotePart_guid){
          child.data.QuotePart.QuotePartTags = options.quotePartTags;
          child.tagsControl.clear();
          for(let tag of options.quotePartTags){
            child.tagsControl.push(new FormControl(tag.Tag.Tag_Name));
          }
        }else if(child?.children != null && child.children.length > 0){
          updateQuoteKitParts(child.children);
        }
      }
    }
    updateQuoteKitParts(this.quote.children);

    const updateQuoteOption = (quoteOption: QuoteOptionEditableInterface)=>{
      const updateQuoteOptionKitParts = (quoteOptionKitParts: QuoteOptionKitPartEditableInterface[])=>{
        for(let child of quoteOptionKitParts){
          if(options.quotePartGuid == child.data?.QuoteOptionPart?.QuotePart?.QuotePart_guid){
            child.data.QuoteOptionPart.QuotePartTags = options.quotePartTags;
            child.tagsControl.clear();
            for(let tag of options.quotePartTags){
              child.tagsControl.push(new FormControl(tag.Tag.Tag_Name));
            }
          }else if(child?.children != null && child.children.length > 0){
            updateQuoteOptionKitParts(child.children);
          }
        }
      }
      updateQuoteOptionKitParts(quoteOption.children);
    }
    for(let option of this.quote.options){
      updateQuoteOption(option);
    }
  }

  /**
   * Patches the QuoteKitPart with the provided changes.
   * @param options 
   * @returns 
   */
  patchQuoteKitPart(options: {
    quoteKitPart: QuoteKitPartEditableInterface,
    showErrors?: boolean
  }): Observable<QuoteKitPartDtoInterface> {
    const newBl = this.getBuildLocation({buildLocationCode: options.quoteKitPart.buildLocationControl.value});
    const obs = new Observable<QuoteKitPartDtoInterface>((subscriber)=>{
      this.patchQuoteKitPartReq({
        quoteKitPartGuid: options.quoteKitPart.data.QuoteKitPart.QuoteKitPart_guid,
        quoteKitPart: {
          QuoteKitPart_Quantity: options.quoteKitPart.quantityControl.value,
          QuoteKitPart_Phase: options.quoteKitPart.phaseControl.value,
          BuildLocation_guid: newBl?.BuildLocation_guid||undefined
        }
      }).subscribe(
        (data: QuoteKitPartDtoInterface)=>{
          // I am not sending back the BuildLocation, so I need to set it to the one that was sent
          // What should happen, is that we send back the QuoteKitPart, BuildLocation, and QuotePartTags
          options.quoteKitPart.data.BuildLocation = newBl;
          subscriber.next(data);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This will save the changes to the quote part.
   * @param options 
   * @returns {Observable<QuotePartDtoInterface>}
   */
  changeQuotePart(options: {
    quotePart: QuoteKitPartEditableInterface|QuoteOptionKitPartEditableInterface,
    showErrors?: boolean
  }): Observable<QuotePartDtoInterface> {
    // set qp equal to the actual QuotePartDtoInterface object
    let qp: QuotePartDtoInterface;
    if('QuotePart' in options.quotePart.data){
      qp = options.quotePart.data.QuotePart.QuotePart;
    }else if('QuoteOptionPart' in options.quotePart.data){
      qp = options.quotePart.data.QuoteOptionPart.QuotePart;
    }
    const obs = new Observable<QuotePartDtoInterface>((subscriber)=>{
      this.patchQuotePartReq({
        quotePartGuid: qp.QuotePart_guid,
        quotePart: {
          QuotePart_Cost: options.quotePart.costControl.value
        }
      }).subscribe(
        (data: QuotePartDtoInterface)=>{
          // Go over all the parts in the quote, and if any use this part, update them
          this.updateAllQuotePartsCost({
            quotePartGuid: data.QuotePart_guid,
            quotePartCost: data.QuotePart_Cost,
          });
          subscriber.next(data);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This will save the changes to the QuotePartTags.
   * @param options 
   * @returns {Observable<ContextQuotePartTagDtoInterface[]>}
   */
  putQuotePartTags(options: {
    quoteKitPart: QuoteKitPartEditableInterface|QuoteOptionKitPartEditableInterface,
    showErrors?: boolean
  }): Observable<ContextQuotePartTagDtoInterface[]> {
    // set qp equal to the actual QuotePartDtoInterface object
    let qp: QuotePartDtoInterface;
    if('QuotePart' in options.quoteKitPart.data){
      qp = options.quoteKitPart.data.QuotePart.QuotePart;
    }else if('QuoteOptionPart' in options.quoteKitPart.data){
      qp = options.quoteKitPart.data.QuoteOptionPart.QuotePart;
    }
    const obs = new Observable<ContextQuotePartTagDtoInterface[]>((subscriber)=>{
      const tagGuids: putQuotePartTagsInterface[] = options.quoteKitPart.tagsControl.controls.map((control)=>{
        return {
          Tag_guid: this.tags.find((tag)=>tag.Tag_Name == control.value)?.Tag_guid
        };
      });
      this.putQuotePartTagsReq({
        quotePartGuid: qp.QuotePart_guid,
        quotePartTags: tagGuids
      }).subscribe(
        (data: ContextQuotePartTagDtoInterface[])=>{
          // Go over all the parts in the quote, and if any use this part, update their QuotePartTags
          this.updateAllQuotePartsTags({
            quotePartGuid: qp.QuotePart_guid,
            quotePartTags: data,
          });
          subscriber.next(data);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This will save the changes to the QuoteKit.
   * @param options 
   * @returns 
   */
  patchQuoteKit(options: {
    quoteKit: QuoteKitDtoInterface,
    showErrors?: boolean;
  }){
    const obs = new Observable<QuoteKitDtoInterface>((subscriber)=>{
      this.patchQuoteKitReq({
        quoteKitGuid: options.quoteKit.QuoteKit_guid,
        quoteKit: {
          QuoteKit_Name: options.quoteKit.QuoteKit_Name,
          QuoteKit_Desc: options.quoteKit.QuoteKit_Desc,
          QuoteKit_Region: options.quoteKit.QuoteKit_Region,
        }
      }).subscribe(
        (data: QuoteKitDtoInterface)=>{
          subscriber.next(data);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This adds a QuoteExclusion to the quote
   * @param options 
   * @returns {Observable<QuotePartDtoInterface>}
   */
  addQuoteExclusion(options: {
    quoteExclusion: PostQuoteExclusionInterface,
    showErrors?: boolean
  }): Observable<QuoteExclusionDtoInterface> {
    const obs = new Observable<QuoteExclusionDtoInterface>((subscriber)=>{
      this.postQuoteExclusionReq({quoteGuid: this.quote.data.Quote.Quote_guid, quoteExclusion: options.quoteExclusion})
      .subscribe(
        (data: QuoteExclusionDtoInterface)=>{
          this.quote.exclusions.push(this.getQuoteExclusionEditable(data));
          subscriber.next(data);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to add exclusion', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This will save the changes to the QuoteExclusion.
   * @param options
   * @returns {Observable<QuoteExclusionDtoInterface>}
   */
  changeQuoteExclusion(options: {
    quoteExclusion: QuoteExclusionEditableInterface,
    showErrors?: boolean
  }): Observable<QuoteExclusionDtoInterface> {
    const obs = new Observable<QuoteExclusionDtoInterface>((subscriber)=>{
      this.patchQuoteExclusionReq({
        quoteExclusionGuid: options.quoteExclusion.data.QuoteExclusion_guid,
        quoteExclusion: {
          QuoteExclusion_Name: options.quoteExclusion.nameControl.value,
          QuoteExclusion_Desc: options.quoteExclusion.descControl.value
        }
      }).subscribe(
        (data: QuoteExclusionDtoInterface)=>{
          const exclusion = this.quote.exclusions.find((excl)=>excl.data.QuoteExclusion_guid == options.quoteExclusion.data.QuoteExclusion_guid);
          if(exclusion?.data != null){
            exclusion.data = data;
          }
          subscriber.next(data);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This will destroy the QuoteExclusion.
   * @param options 
   * @returns {Observable<QuoteEditableResponseType>}
   */
  destroyQuoteExclusion(options: {
    quoteExclusion: QuoteExclusionEditableInterface;
    showConfirm?: boolean;
    showErrors?: boolean;
  }): Observable<QuoteEditableResponseType> {
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      const del = ()=>{
        subscriber.next('loading');
        this.deleteQuoteExclusionReq({
          quoteExclusionGuid: options.quoteExclusion.data.QuoteExclusion_guid
        }).subscribe(
          (data)=>{
            // Loop over the exclusions and remove the one with the matching guid
            for(let exclIndex = 0; exclIndex < this.quote.exclusions.length; exclIndex++){
              if(this.quote.exclusions[exclIndex].data.QuoteExclusion_guid == options.quoteExclusion.data.QuoteExclusion_guid){
                this.quote.exclusions.splice(exclIndex, 1);
                break;
              }
            }
            subscriber.next('complete');
            subscriber.complete();
            this.updated.next();
          },
          (err)=>{
            if(options.showErrors === undefined || options.showErrors == true){
              this.snackBar.open('Error deleting exclusion', 'Close', {duration: Infinity});
            }
            subscriber.error(err);
            subscriber.complete();
            this.updated.next();
          }
        );
      }

      if(options?.showConfirm === false){
        del();
      }else{
        subscriber.next('confirming');
        this.modals.confirm(`Delete ${options.quoteExclusion.data.QuoteExclusion_Name}?`, `Really delete ${options.quoteExclusion.data.QuoteExclusion_Name}?`).subscribe((result: boolean) => {
          if(result){
            del();
          }else{
            subscriber.next('canceled');
            subscriber.complete();
            this.updated.next();
          }
        });
      }
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * Adds a QuoteInclusion to the Quote.
   * @param options 
   * @returns {Observable<QuoteInclusionDtoInterface>}
   */
  addQuoteInclusion(options: {
    quoteInclusion: PostQuoteInclusionInterface; 
    showErrors?: boolean;
  }): Observable<QuoteInclusionDtoInterface> {
    const obs = new Observable<QuoteInclusionDtoInterface>((subscriber)=>{
      this.postQuoteInclusionReq({
        quoteGuid: this.quote.data.Quote.Quote_guid,
        quoteInclusion: options.quoteInclusion
      }).subscribe(
        (data: QuoteInclusionDtoInterface)=>{
          this.quote.inclusions.push(this.getQuoteInclusionEditable(data));
          subscriber.next(data);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to add inclusion', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        },
        ()=>{
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This saves changes to the QuoteInclusion.
   * @param options 
   * @returns {Observable<QuoteInclusionDtoInterface>}
   */
  changeQuoteInclusion(options: {
    quoteInclusion: QuoteInclusionEditableInterface,
    showErrors?: boolean
  }): Observable<QuoteInclusionDtoInterface> {
    const obs = new Observable<QuoteInclusionDtoInterface>((subscriber)=>{
      this.patchQuoteInclusionReq({
        quoteInclusionGuid: options.quoteInclusion.data.QuoteInclusion_guid,
        quoteInclusion: {
          QuoteInclusion_Name: options.quoteInclusion.nameControl.value,
          QuoteInclusion_Desc: options.quoteInclusion.descControl.value
        }
      }).subscribe(
        (data: QuoteInclusionDtoInterface)=>{
          const inclusion = this.quote.inclusions.find((incl)=>incl.data.QuoteInclusion_guid == options.quoteInclusion.data.QuoteInclusion_guid);
          if(inclusion?.data != null){
            inclusion.data = data;
          }
          subscriber.next(data);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This will destroy the QuoteInclusion.
   * @param options 
   * @returns {Observable<QuoteEditableResponseType>}
   */
  destroyQuoteInclusion(options: {
    quoteInclusion: QuoteInclusionEditableInterface;
    showConfirm?: boolean;
    showErrors?: boolean;
  }): Observable<QuoteEditableResponseType> {
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      const del = ()=>{
        subscriber.next('loading');
        this.deleteQuoteInclusionReq({
          quoteInclusionGuid: options.quoteInclusion.data.QuoteInclusion_guid
        }).subscribe(
          (data)=>{
            // Loop over the inclusions and remove the one with the matching guid
            for(let inclIndex = 0; inclIndex < this.quote.inclusions.length; inclIndex++){
              if(this.quote.inclusions[inclIndex].data.QuoteInclusion_guid == options.quoteInclusion.data.QuoteInclusion_guid){
                this.quote.inclusions.splice(inclIndex, 1);
                break;
              }
            }
            subscriber.next('complete');
            subscriber.complete();
            this.updated.next();
          },
          (err)=>{
            if(options.showErrors === undefined || options.showErrors == true){
              this.snackBar.open('Error deleting inclusion', 'Close', {duration: Infinity});
            }
            subscriber.error(err);
            subscriber.complete();
            this.updated.next();
          }
        );
      }

      if(options?.showConfirm === false){
        del();
      }else{
        subscriber.next('confirming');
        this.modals.confirm(`Delete ${options.quoteInclusion.data.QuoteInclusion_Name}?`, `Really delete ${options.quoteInclusion.data.QuoteInclusion_Name}?`).subscribe((result: boolean) => {
          if(result){
            del();
          }else{
            subscriber.next('canceled');
            subscriber.complete();
            this.updated.next();
          }
        });
      }
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * Adds a QuoteNote (aka spec) to the Quote.
   * @param options 
   * @returns {Observable<QuoteNoteDtoInterface>}
   */
  addQuoteNote(options: {
    newNote: PostQuoteNoteInterface;
    showErrors?: boolean;
  }): Observable<QuoteNoteDtoInterface> {
    const obs = new Observable<QuoteNoteDtoInterface>((subscriber)=>{
      this.postQuoteNoteReq({
        quoteNote: options.newNote
      }).subscribe(
        (data: QuoteNoteDtoInterface)=>{
          this.quote.notes.push(this.getQuoteNoteEditable(data));
          subscriber.next(data);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to add note', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This saves changes to the QuoteNote.
   * @param options 
   * @returns {Observable<QuoteNoteDtoInterface>}
   */
  patchQuoteNote(options: {
    quoteNote: QuoteNoteEditableInterface;
    showErrors?: boolean;
  }): Observable<QuoteNoteDtoInterface> {
    const obs = new Observable<QuoteNoteDtoInterface>((subscriber)=>{
      this.patchQuoteNoteReq({
        quoteNoteGuid: options.quoteNote.data.QuoteNote_guid,
        quoteNote: {
          QuoteNote_Name: options.quoteNote.nameControl.value,
          QuoteNote_Desc: options.quoteNote.descControl.value
        }
      }).subscribe(
        (data: QuoteNoteDtoInterface)=>{
          const note = this.quote.notes.find((note)=>note.data.QuoteNote_guid == options.quoteNote.data.QuoteNote_guid);
          if(note?.data != null){
            note.data = data;
          }
          subscriber.next(data);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This will destroy the QuoteNote.
   * @param options 
   * @returns {Observable<QuoteEditableResponseType>}
   */
  destroyQuoteNote(options: {
    quoteNote: QuoteNoteEditableInterface;
    showConfirm?: boolean;
    showErrors?: boolean;
  }): Observable<QuoteEditableResponseType> {
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      const del = ()=>{
        subscriber.next('loading');
        this.deleteQuoteNoteReq({
          quoteNoteGuid: options.quoteNote.data.QuoteNote_guid
        }).subscribe(
          (data)=>{
            // Loop over the exclusions and remove the one with the matching guid
            for(let noteIndex = 0; noteIndex < this.quote.notes.length; noteIndex++){
              if(this.quote.notes[noteIndex].data.QuoteNote_guid == options.quoteNote.data.QuoteNote_guid){
                this.quote.notes.splice(noteIndex, 1);
                break;
              }
            }
            subscriber.next('complete');
            subscriber.complete();
            this.updated.next();
          },
          (err)=>{
            if(options.showErrors === undefined || options.showErrors == true){
              this.snackBar.open('Error deleting note', 'Close', {duration: Infinity});
            }
            subscriber.error(err);
            subscriber.complete();
            this.updated.next();
          }
        );
      }

      if(options?.showConfirm === false){
        del();
      }else{
        subscriber.next('confirming');
        this.modals.confirm(`Delete ${options.quoteNote.data.QuoteNote_Name}?`, `Really delete ${options.quoteNote.data.QuoteNote_Name}?`).subscribe((result: boolean) => {
          if(result){
            del();
          }else{
            subscriber.next('canceled');
            subscriber.complete();
            this.updated.next();
          }
        });
      }
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * Adds a QuoteBillingTerm to the Quote.
   * @param options 
   * @returns {Observable<QuoteBillingTermDtoInterface>}
   */
  addQuoteBillingTerm(options: {
    quoteBillingTerm: PostQuoteBillingTermInterface;
    showErrors?: boolean;
  }): Observable<QuoteBillingTermDtoInterface> {
    const obs = new Observable<QuoteBillingTermDtoInterface>((subscriber)=>{
      this.postQuoteBillingTermReq({
        quoteGuid: this.quote.data.Quote.Quote_guid,
        quoteBillingTerm: options.quoteBillingTerm,
      }).subscribe(
        (data: QuoteBillingTermDtoInterface)=>{
          this.quote.billingTerms.push(this.getQuoteBillingTermEditable(data));
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to add billing term', 'Close', {duration: Infinity});
          }
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This saves changes to the QuoteBillingTerm.
   * @param options 
   * @returns {Observable<QuoteBillingTermDtoInterface>}
   */
  changeQuoteBillingTerm(options: {
    quoteBillingTerm: QuoteBillingTermEditableInterface;
    showErrors?: boolean;
  }): Observable<QuoteBillingTermDtoInterface> {
    const obs = new Observable<QuoteBillingTermDtoInterface>((subscriber)=>{
      this.patchQuoteBillingTermReq({
        quoteBillingTermGuid: options.quoteBillingTerm.data.QuoteBillingTerm_guid,
        quoteBillingTerm: {
          QuoteBillingTerm_Name: options.quoteBillingTerm.nameControl.value,
          QuoteBillingTerm_Desc: options.quoteBillingTerm.descControl.value
        }
      }).subscribe(
        (data: QuoteBillingTermDtoInterface)=>{
          const billingTerm = this.quote.billingTerms.find((bt)=>bt.data.QuoteBillingTerm_guid == options.quoteBillingTerm.data.QuoteBillingTerm_guid);
          if(billingTerm?.data != null){
            billingTerm.data = data;
          }
          subscriber.next(data);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This will destroy the QuoteBillingTerm.
   * @param options 
   * @returns {Observable<QuoteEditableResponseType>}
   */
  destroyQuoteBillingTerm(options: {
    quoteBillingTerm: QuoteBillingTermEditableInterface;
    showConfirm?: boolean;
    showErrors?: boolean;
  }): Observable<QuoteEditableResponseType> {
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      const del = ()=>{
        subscriber.next('loading');
        this.deleteQuoteBillingTermReq({
          quoteBillingTermGuid: options.quoteBillingTerm.data.QuoteBillingTerm_guid
        }).subscribe(
          (data)=>{
            // Loop over the billing terms and remove the one with the matching guid
            for(let btIndex = 0; btIndex < this.quote.billingTerms.length; btIndex++){
              if(this.quote.billingTerms[btIndex].data.QuoteBillingTerm_guid == options.quoteBillingTerm.data.QuoteBillingTerm_guid){
                this.quote.billingTerms.splice(btIndex, 1);
                break;
              }
            }
            subscriber.next('complete');
            subscriber.complete();
            this.updated.next();
          },
          (err)=>{
            if(options.showErrors === undefined || options.showErrors == true){
              this.snackBar.open('Error deleting billing term', 'Close', {duration: Infinity});
            }
            subscriber.error(err);
            subscriber.complete();
            this.updated.next();
          }
        );
      }

      if(options?.showConfirm === false){
        del();
      }else{
        subscriber.next('confirming');
        this.modals.confirm(`Delete ${options.quoteBillingTerm.data.QuoteBillingTerm_Name}?`, `Really delete ${options.quoteBillingTerm.data.QuoteBillingTerm_Name}?`).subscribe((result: boolean) => {
          if(result){
            del();
          }else{
            subscriber.next('canceled');
            subscriber.complete();
            this.updated.next();
          }
        });
      }
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This destroys the provided QuoteKitParts from the quote.
   * @param options 
   * @returns {Observable<QuoteEditableResponseType>}
   */
  destroyQuoteKitParts(options: {
    quoteKitParts: QuoteKitPartEditableInterface[];
    showConfirm?: boolean;
    showErrors?: boolean;
  }): Observable<QuoteEditableResponseType> {
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      const del = ()=>{
        subscriber.next('loading');
        const qkpGuids = options.quoteKitParts.map(qkpe=>qkpe.data.QuoteKitPart.QuoteKitPart_guid);
        this.api.deleteRequest(`quotekitparts?QuoteKitPart_guid=${qkpGuids.join('&QuoteKitPart_guid=')}`).subscribe(
          (data)=>{
            const searchAndDestroy = (children: QuoteKitPartEditableInterface[])=>{
              for(let i = 0; i < children.length; i++){
                if(qkpGuids.includes(children[i].data.QuoteKitPart?.QuoteKitPart_guid)){
                  children.splice(i, 1);
                  i--;
                }else if(Array.isArray(children[i]?.children)){
                  searchAndDestroy(children[i].children);
                }
              }
            }
            searchAndDestroy(this.quote.children);
            subscriber.next('complete');
            subscriber.complete();
            this.updated.next();
          },
          (err)=>{
            if(options.showErrors === undefined || options.showErrors == true){
              this.snackBar.open(`Error deleting quote kit part${qkpGuids.length > 1? 's' : ''}`, 'Close', {duration: Infinity});
            }
            subscriber.error(err);
            subscriber.complete();
            this.updated.next();
          }
        );
      }

      if(options?.showConfirm === false){
        del();
      }else{
        subscriber.next('confirming');
        const confTitle = options.quoteKitParts.length == 1 ?
          `Delete ${options.quoteKitParts[0].data.QuotePart?.QuotePart.QuotePart_Code||options.quoteKitParts[0].data.QuoteKit?.QuoteKit.QuoteKit_Name}?`
          : `Delete ${options.quoteKitParts.length} items?`;
        const confMsg = options.quoteKitParts.length == 1 ?
          `Are you sure you want to delete ${options.quoteKitParts[0].data.QuotePart?.QuotePart.QuotePart_Code||options.quoteKitParts[0].data.QuoteKit?.QuoteKit.QuoteKit_Name}?`
          : `Are you sure you want to delete ${options.quoteKitParts.length} items?`;
        this.modals.confirm(confTitle, confMsg).subscribe((result: boolean) => {
          if(result){
            del();
          }else{
            subscriber.next('canceled');
            subscriber.complete();
            this.updated.next();
          }
        });
      }
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This will destroy the selected QuoteKitParts.
   * @param options 
   * @returns {Observable<QuoteEditableResponseType>}
   */
  destroySelectedChildren(options?: {
    showConfirm?: boolean;
    showErrors?: boolean;
  }): Observable<QuoteEditableResponseType> {
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      const selectedChildren = this.getSelectedChildren();
      const del = ()=>{
        subscriber.next('loading');
        if(selectedChildren.length == 0){
          subscriber.next('complete');
          subscriber.complete();
          this.updated.next();
        }else{
          const destroyedChildren: Observable<QuoteEditableResponseType>[] = [];
          this.destroyQuoteKitParts({quoteKitParts: selectedChildren, showConfirm: false}).subscribe(
            (data)=>{
              subscriber.next('complete');
              subscriber.complete();
              this.updated.next();
            },
            (err)=>{
              if(options.showErrors === undefined || options.showErrors == true){
                this.snackBar.open('Error deleting some or all of the selected children', 'Close', {duration: Infinity});
              }
              subscriber.error(err);
              subscriber.complete();
              this.updated.next();
            },
            ()=>{
              this.updated.next();
            }
          );
        }
      }
      
      if(options?.showConfirm === false){
        del();
      }else{
        subscriber.next('confirming');
        const confTitle = selectedChildren.length == 1 ?
        `Really delete ${selectedChildren[0]?.data?.QuoteKit?.QuoteKit?.QuoteKit_Name||selectedChildren[0]?.data?.QuotePart?.QuotePart?.QuotePart_Code}?`
        : `Really delete ${selectedChildren.length} items?`;
        const confMsg = selectedChildren.length == 1 ?
          `Are you sure you want to delete ${selectedChildren[0]?.data?.QuoteKit?.QuoteKit?.QuoteKit_Name||selectedChildren[0]?.data?.QuotePart?.QuotePart?.QuotePart_Code}?`
          : `Are you sure you want to delete ${selectedChildren.length} items?`;

        this.modals.confirm(confTitle, confMsg).subscribe((result: boolean) => {
          if(result){
            del();
          }else{
            subscriber.next('canceled');
            subscriber.complete();
            this.updated.next();
          }
        });
      }

    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This will save the changes to the QuoteOptionReplace (credited parts). All previous credited parts will be replaced with the new ones.
   * @param options 
   * @returns {Observable<QuoteOptionDtoInterface>}
   */
  saveQuoteOptionCreditedPartsControl(options: {
    quoteOption: QuoteOptionEditableInterface,
    showErrors?: boolean
  }): Observable<QuoteEditableResponseType> {
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption});
    const creditedPartsGuids = creditedParts.map((cp)=>cp.data.QuoteKitPart.QuoteKitPart_guid);
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      this.putQuoteOptionReplaceReq({
        quoteOptionGuid: options.quoteOption.data.QuoteOption_guid,
        quoteKitParts: creditedPartsGuids
      }).subscribe(
        (data: ContextQuoteOptionReplaceDtoInterface[])=>{
          options.quoteOption.creditedQuoteKitParts = data.map(qore=>this.getQuoteOptionReplaceEditable(qore));
          options.quoteOption.creditedQuoteKitPartsControl = data.map(qore=>this.getQuoteOptionRelaceControl(qore));
          subscriber.next('complete');
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * Adds a new QuoteOption to the quote
   * @param options 
   * @returns {Observable<ContextQuoteOptionDtoInterface>}
   */
  addQuoteOption(options: {
    newOption: PostQuoteOptionInterface,
    showErrors?: boolean
  }): Observable<ContextQuoteOptionDtoInterface> {
    const obs = new Observable<ContextQuoteOptionDtoInterface>((subscriber)=>{
      this.postQuoteOptionReq({
        quoteGuid: this.quote.data.Quote.Quote_guid,
        quoteOption: options.newOption
      }).subscribe(
        (data: QuoteOptionDtoInterface)=>{
          const ctxQO: ContextQuoteOptionDtoInterface = {
            QuoteOption: data,
            QuoteOptionKitParts: [],
            QuoteOptionReplaces: []
          };
          this.quote.options.push(this.getQuoteOptionEditable(ctxQO));
          subscriber.next(ctxQO);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to add option', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * Adds a new QuoteOption to the quote
   * @param options 
   * @returns {Observable<ContextQuoteOptionDtoInterface>}
   */
  destroyQuoteOption(options: {
    quoteOption: QuoteOptionEditableInterface;
    showErrors?: boolean;
    showConfirm?: boolean;
  }): Observable<QuoteEditableResponseType> {
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      const del = ()=>{
        subscriber.next('loading');
        this.deleteQuoteOptionReq({
          quoteOptionGuid: options.quoteOption.data.QuoteOption_guid,
        }).subscribe(
          ()=>{
            // Find the index of the quoteOption and remove it
            const index = this.quote.options.findIndex((qo)=>qo.data.QuoteOption_guid == options.quoteOption.data.QuoteOption_guid);
            if(index > -1){
              this.quote.options.splice(index, 1);
            }
            subscriber.next('complete');
            subscriber.complete();
            this.updated.next();
          },
          (err)=>{
            if(options.showErrors === undefined || options.showErrors == true){
              this.snackBar.open('Failed to destroy option', 'Close', {duration: Infinity});
            }
            subscriber.error(err);
            subscriber.complete();
            this.updated.next();
          }
        );
      }
      
      if(options?.showConfirm === false){
        del();
      }else{
        subscriber.next('confirming');
        const confTitle = `Really delete ${options.quoteOption.data.QuoteOption_Name}?`
        const confMsg = `Are you sure you want to delete ${options.quoteOption.data.QuoteOption_Name}?`;

        this.modals.confirm(confTitle, confMsg).subscribe((result: boolean) => {
          if(result){
            del();
          }else{
            subscriber.next('canceled');
            subscriber.complete();
            this.updated.next();
          }
        });
      }
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * Adds a new QuoteOption to the quote
   * @param options 
   * @returns {Observable<QuoteEditableResponseType>}
   */
  changeQuoteOption(options: {
    quoteOption: QuoteOptionEditableInterface;
    showErrors?: boolean;
    showConfirm?: boolean;
  }): Observable<QuoteEditableResponseType> {
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      subscriber.next('loading');
      this.patchQuoteOptionReq({
        quoteOptionGuid: options.quoteOption.data.QuoteOption_guid,
        quoteOption: {
          QuoteOption_Name: options.quoteOption.nameControl.value,
          QuoteOption_Desc: options.quoteOption.descControl.value,
        }
      }).subscribe(
        (data)=>{
          // Find the index of the quoteOption and remove it
          const index = this.quote.options.findIndex((qo)=>qo.data.QuoteOption_guid == options.quoteOption.data.QuoteOption_guid);
          if(index > -1){
            this.quote.options[index].data = data;
          }
          subscriber.next('complete');
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to update option', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * This will save the changes to the QuoteOptionReplace (credited parts) and add the new parts to the quote.
   * @param options 
   * @returns {Observable<QuoteEditableResponseType>}
   */
  addToQuoteOption(options: {
    kitsAndPartsToAdd: PostAddToQuoteOptionInterface,
    quoteOption: QuoteOptionEditableInterface,
    parentQuoteOptionKitPart?: QuoteOptionKitPartEditableInterface,
    showErrors?: boolean
  }): Observable<QuoteEditableResponseType> {
    const lid = this.startLoading(); // I have no idea why I need this when I can use loadingUntilComplete, but I do need it here :/
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      this.postAddToQuoteOptionReq({
        quoteOptionGuid: options.quoteOption.data.QuoteOption_guid,
        quoteOptionParts: options.kitsAndPartsToAdd,
        parentQuoteOptionKitPartGuid: options.parentQuoteOptionKitPart?.data?.QuoteOptionKitPart.QuoteOptionKitPart_guid
      }).subscribe((data: ContextOptionKitsAndPartsInterface)=>{
        subscriber.next('loading');
        this.updated.next();
        const children = options.parentQuoteOptionKitPart?.children || options.quoteOption.children;
        // First, go through the QuoteKitParts and check if there is a matching data.parts
        for(let part of data.parts){
          const partIndex = children.findIndex((qkp)=>{
            return qkp?.data?.QuoteOptionPart?.QuotePart?.QuotePart_guid == part.QuoteOptionPart.QuotePart?.QuotePart_guid;
          });
          if(partIndex > -1){
            // Construct a QuoteKitPartEditableInterface from part
            const partEditable = this.getQuoteOptionKitPartEditable(part);
            children[partIndex] = partEditable;
          }else{
            const partEditable = this.getQuoteOptionKitPartEditable(part);
            children.push(partEditable);
          }
        }
        // Construct a QuoteKitPartEditableInterface from the data.kits
        const kits = data.kits.map((kit)=>{
          return this.getQuoteOptionKitPartEditable(kit);
        });
        children.push(...kits);
        subscriber.next('complete');
        subscriber.complete();
        this.updated.next();
      }, 
      (err)=>{
        if(options.showErrors === undefined || options.showErrors == true){
          this.snackBar.open('Failed to add to quote', 'Close', {duration: Infinity});
        }
        subscriber.error(err);
        subscriber.complete();
        this.updated.next();
      });
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs).subscribe({complete: ()=>{this.removeLoadingId(lid)}});
    return sobs;
  }

  /**
   * This will save the changes to the QuoteOptionKit.
   * @param options 
   * @returns {Observable<QuoteOptionKitDtoInterface>}
   */
  changeQuoteOptionKit(options: {
    quoteOptionKitPart: QuoteOptionKitPartEditableInterface,
    showErrors?: boolean
  }): Observable<QuoteOptionKitDtoInterface> {
    if(options.quoteOptionKitPart?.data?.QuoteOptionKit == null){
      return new Observable<QuoteOptionKitDtoInterface>((subscriber)=>{
        subscriber.error('The QuoteOptionKit does not exist on QuoteKitPartEditableInterface'); // Let them know they fucked up somewhere
        subscriber.complete();
      }); // Return an empty observable
    }
    const obs = new Observable<QuoteOptionKitDtoInterface>((subscriber)=>{
      this.patchQuoteOptionKitReq({
        quoteOptionKitGuid: options.quoteOptionKitPart.data.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_guid,
        quoteOptionKit: {
          QuoteOptionKit_Name: options.quoteOptionKitPart.nameControl.value,
          QuoteOptionKit_Desc: options.quoteOptionKitPart.descControl.value
        }
      }).subscribe(
        (data: QuoteOptionKitDtoInterface)=>{
          const kit = options.quoteOptionKitPart
          if(kit?.data?.QuoteOptionKit != null){
            kit.data.QuoteOptionKit.QuoteOptionKit = data;
          }
          subscriber.next(data);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * Saves changes to the QuoteOptionKitPart.
   * @param options 
   * @returns {Observable<QuoteOptionKitPartDtoInterface>}
   */
  changeQuoteOptionKitPart(options: {
    quoteOptionKitPart: QuoteOptionKitPartEditableInterface,
    showErrors?: boolean
  }): Observable<QuoteOptionKitPartDtoInterface> {
    
    const obs = new Observable<QuoteOptionKitPartDtoInterface>((subscriber)=>{
      const newBuildLocation = this.getBuildLocation({buildLocationCode: options.quoteOptionKitPart.buildLocationControl.value});
      const newTags = this.getTags({tagNames: options.quoteOptionKitPart.tagsControl.value});
      this.patchQuoteOptionKitPartReq({
        quoteOptionKitPartGuid: options.quoteOptionKitPart.data.QuoteOptionKitPart.QuoteOptionKitPart_guid,
        quoteOptionKitPart: {
          QuoteOptionKitPart_Quantity: options.quoteOptionKitPart.quantityControl.value,
          QuoteOptionKitPart_Phase: options.quoteOptionKitPart.data.QuoteOptionKit != null ? 
            options.quoteOptionKitPart.phaseControl.value 
            : undefined,
          BuildLocation_guid: newBuildLocation.BuildLocation_guid,
          Tags: newTags.map((tag)=>tag.Tag_guid)
        },
      }).subscribe(
        (data: QuoteOptionKitPartDtoInterface)=>{
          const part = options.quoteOptionKitPart;
          if(part?.data?.QuoteOptionKitPart != null){
            part.data.QuoteOptionKitPart = data;
          }
          
          if(newBuildLocation != null){
            part.data.BuildLocation = newBuildLocation;
          }

          subscriber.next(data);
          subscriber.complete();
          this.updated.next();
        },
        (err)=>{
          if(options.showErrors === undefined || options.showErrors == true){
            this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
          }
          subscriber.error(err);
          subscriber.complete();
          this.updated.next();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * Destroys the provided QuoteOptionKitParts.
   * @param options 
   * @returns {Observable<QuoteEditableResponseType>}
   */
  destroyQuoteOptionKitParts(options: {
    quoteOption: QuoteOptionEditableInterface,
    quoteOptionKitParts?: QuoteOptionKitPartEditableInterface[],
    showConfirm?: boolean,
    showErrors?: boolean
  }): Observable<QuoteEditableResponseType> {
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      const del = ()=>{
        subscriber.next('loading');
        const qokpGuids = options.quoteOptionKitParts.map(qokpe=>qokpe.data.QuoteOptionKitPart.QuoteOptionKitPart_guid);
        this.deleteQuoteOptionKitPartsReq({quoteOptionKitPartGuids: qokpGuids}).subscribe(
          (data)=>{
            const searchAndDestroy = (children: QuoteOptionKitPartEditableInterface[])=>{
              for(let i = 0; i < children.length; i++){
                if(qokpGuids.includes(children[i].data.QuoteOptionKitPart?.QuoteOptionKitPart_guid)){
                  children.splice(i, 1);
                  i--;
                }else if(Array.isArray(children[i]?.children)){
                  searchAndDestroy(children[i].children);
                }
              }
            }
            searchAndDestroy(options.quoteOption.children);
            
            subscriber.next('complete');
            subscriber.complete();
            this.updated.next();
          },
          (err)=>{
            if(options.showErrors === undefined || options.showErrors == true){
              this.snackBar.open(`Error deleting quote option kit part${qokpGuids.length > 1? 's' : ''}`, 'Close', {duration: Infinity});
            }
            subscriber.error(err);
            subscriber.complete();
            this.updated.next();
          }
        );
      }

      if(options?.showConfirm === false){
        del();
      }else{
        subscriber.next('confirming');
        const confTitle = options.quoteOptionKitParts.length == 1 ?
          `Delete ${options.quoteOptionKitParts[0].data.QuoteOptionPart?.QuotePart.QuotePart_Code||options.quoteOptionKitParts[0].data.QuoteOptionKit?.QuoteOptionKit.QuoteOptionKit_Name}?`
          : `Delete ${options.quoteOptionKitParts.length} items?`;
        const confMsg = options.quoteOptionKitParts.length == 1 ?
          `Are you sure you want to delete ${options.quoteOptionKitParts[0].data.QuoteOptionPart?.QuotePart.QuotePart_Code||options.quoteOptionKitParts[0].data.QuoteOptionKit?.QuoteOptionKit.QuoteOptionKit_Name}?`
          : `Are you sure you want to delete ${options.quoteOptionKitParts.length} items?`;

        this.modals.confirm(confTitle, confMsg).subscribe((result: boolean) => {
          if(result){
            del();
          }else{
            subscriber.next('canceled');
            subscriber.complete();
            this.updated.next();
          }
        });
      }
    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /**
   * Destroys all the currently selected QuoteOptionKitParts.
   * @param options 
   * @returns {Observable<QuoteEditableResponseType>}
   */
  destroySelectedQuoteOptionChildren(options?: {
    quoteOption: QuoteOptionEditableInterface;
    showConfirm?: boolean;
    showErrors?: boolean;
  }): Observable<QuoteEditableResponseType> {
    const obs = new Observable<QuoteEditableResponseType>((subscriber)=>{
      const selectedChildren = this.getSelectedQuoteOptionKitPartChildren({quoteOption: options.quoteOption});
      const del = ()=>{
        subscriber.next('loading');
        if(selectedChildren.length == 0){
          subscriber.next('complete');
          subscriber.complete();
          this.updated.next();
        }else{
          this.destroyQuoteOptionKitParts({
            quoteOption: options.quoteOption, 
            quoteOptionKitParts: selectedChildren, 
            showConfirm: false
          }).subscribe(
            (data)=>{
              subscriber.next('complete');
              subscriber.complete();
              this.updated.next();
            },
            (err)=>{
              if(options.showErrors === undefined || options.showErrors == true){
                this.snackBar.open('Error deleting some or all of the selected children', 'Close', {duration: Infinity});
              }
              subscriber.error(err);
              subscriber.complete();
              this.updated.next();
            },
            ()=>{
              this.updated.next();
            }
          );
        }
      }
      
      if(options?.showConfirm === false){
        del();
      }else{
        subscriber.next('confirming');
        const confTitle = selectedChildren.length == 1 ?
        `Really delete ${selectedChildren[0]?.data?.QuoteOptionKit?.QuoteOptionKit?.QuoteOptionKit_Name||selectedChildren[0]?.data?.QuoteOptionPart?.QuotePart?.QuotePart_Code}?`
        : `Really delete ${selectedChildren.length} items?`;
        const confMsg = selectedChildren.length == 1 ?
          `Are you sure you want to delete ${selectedChildren[0]?.data?.QuoteOptionKit?.QuoteOptionKit?.QuoteOptionKit_Name||selectedChildren[0]?.data?.QuoteOptionPart?.QuotePart?.QuotePart_Code}?`
          : `Are you sure you want to delete ${selectedChildren.length} items?`;

        this.modals.confirm(confTitle, confMsg).subscribe((result: boolean) => {
          if(result){
            del();
          }else{
            subscriber.next('canceled');
            subscriber.complete();
            this.updated.next();
          }
        });
      }

    });
    const sobs = obs.pipe(shareReplay());
    this.loadingUntilComplete(sobs);
    return sobs;
  }

  /* 
    OnlyState
    Feeling lonely? Take a look at the hottest state objects and functions around. 
    Payment is 5 braincells a month, and at the cost of my sanity you can request custom state objects and functions.
  */

    /**
     * Gets the selected QuoteKitParts.
     * @param options 
     * @returns {QuoteKitPartEditableInterface[]}
     */
  getSelectedChildren(options?: {
    includeSubSelected?: boolean;
  }): QuoteKitPartEditableInterface[] {
    const selectedChildren: QuoteKitPartEditableInterface[] = [];
    const getSelected = (children: QuoteKitPartEditableInterface[])=>{
      for(let qkp of children){
        if(qkp.selected.value == true){
          selectedChildren.push(qkp);
        }else if(qkp.data.QuoteKit){
          getSelected(qkp.children);
        }
        if(options?.includeSubSelected == true && qkp.data.QuoteKit){
          getSelected(qkp.children);
        }
      }
    };
    getSelected(this.quote.children);
    return selectedChildren;
  }

  /**
   * Deselects all the children of the Quote or provided QuoteKitPartEditable.
   * @param options 
   */
  deselectChildren(options?: {
    quoteKitPartToDeselect?: QuoteKitPartEditableInterface
  }){
    const desel = (children: QuoteKitPartEditableInterface[])=>{
      children.map((qkp)=>{
        qkp.selected.setValue(false);
        if(qkp.data.QuoteKit){
          desel(qkp.children);
        }
      });
    };
    
    if(
      options != null 
      && options?.quoteKitPartToDeselect!=null 
      && Array.isArray(options?.quoteKitPartToDeselect?.children)
    ){
      desel(options.quoteKitPartToDeselect.children);
    }else if(this.quote?.children.length > 0){
      desel(this.quote.children);
    }
  }

  /**
   * Returns a part breakdown of the Quote, which goes over each part, its overall quantity, and what kits have which quantity which make up the total.
   * @param options 
   * @returns {PartBreakdownItemGroupInterface[]}
   */
  getPartBreakdownGroups(options?: {
    tags: string[],
    quoteKitPart?: QuoteKitPartEditableInterface,
    invert?: boolean
  }): PartBreakdownItemGroupInterface[] {
    const children = Array.isArray(options?.quoteKitPart?.children)? options.quoteKitPart.children : options?.quoteKitPart?.data?.QuotePart ? [options.quoteKitPart] : this.quote.children;
    const parts: PartBreakdownItemInterface[] = [];
    const getParts = (children: QuoteKitPartEditableInterface[], parentKitQuantities: number[], parentKitNames: string[], location: BuildLocationDtoInterface|null)=>{
      for(let qkp of children){
        if(qkp.data.QuotePart){

          const condition = options?.invert == true ?
            qkp.data.QuotePart.QuotePartTags.findIndex(qpt=>options.tags.includes(qpt.Tag.Tag_Name)) == -1 
            : qkp.data.QuotePart.QuotePartTags.findIndex(qpt=>options.tags.includes(qpt.Tag.Tag_Name)) != -1;
          
          if(options == undefined || condition){
            let cost = qkp.data.QuotePart.QuotePart.QuotePart_Cost * qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
            let quantity = qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
            for(let i = 0; i < parentKitQuantities.length; i++){
              cost *= parentKitQuantities[i];
              quantity *= parentKitQuantities[i];
            }

            let loc: BuildLocationDtoInterface = location;
            if(qkp.data.BuildLocation.BuildLocation_Code != 'NONE' || location == null){
              loc = qkp.data.BuildLocation;
            }

            const laborPart: PartBreakdownItemInterface = {
              data: qkp.data,
              cost: cost,
              quantity: quantity,
              parents: [...parentKitNames],
              location: loc,
            };
            parts.push(laborPart);
          }
        }
        if(qkp.data.QuoteKit){
          const newParentKitQuantities = [...parentKitQuantities];
          newParentKitQuantities.push(qkp.data.QuoteKitPart.QuoteKitPart_Quantity);
          const newParentKitNames = [...parentKitNames];
          newParentKitNames.push(qkp.data.QuoteKit.QuoteKit.QuoteKit_Name);

          // If the location is not NONE, we need to update the location
          let loc: BuildLocationDtoInterface = location;
          if(qkp.data.BuildLocation.BuildLocation_Code != 'NONE' || location == null){
            loc = qkp.data.BuildLocation;
          }

          getParts(qkp.children, newParentKitQuantities, newParentKitNames, loc);
        }
      }
    }
    getParts(children, [], [], null);

    const partGroups: PartBreakdownItemGroupInterface[] = [];
    for(let prt of parts){
      const groupIndex = partGroups.findIndex((group)=>{
        return group.name == prt.data.QuotePart.QuotePart.QuotePart_Code;
      });
      if(groupIndex > -1){
        partGroups[groupIndex].totalCost += prt.cost;
        partGroups[groupIndex].quantity += prt.quantity;
        partGroups[groupIndex].items.push(prt);
      }else{
        const newGroup: PartBreakdownItemGroupInterface = {
          name: prt.data.QuotePart.QuotePart.QuotePart_Code,
          partCost: prt.data.QuotePart.QuotePart.QuotePart_Cost,
          totalCost: prt.cost,
          quantity: prt.quantity,
          desc: prt.data.QuotePart.QuotePart.QuotePart_Desc,
          items: [prt],
        };
        partGroups.push(newGroup);
      }
    }

    return partGroups;
  }

  /**
   * Gets the cost of the parts in the part breakdown groups.
   * @param options 
   * @returns {number}
   */
  getPartBreakdownGroupsCost(options: {
    tags: string[],
    invert?: boolean
  } | {
    partBreakdownGroups: PartBreakdownItemGroupInterface[]
  }): number {
    const groups = 'tags' in options? this.getPartBreakdownGroups(options) : options.partBreakdownGroups;
    let totalCost = 0;
    for(let group of groups){
      totalCost += group.totalCost;
    }
    return totalCost;
  }

  /**
   * Gets the total number of parts in the part breakdown groups.
   * @param options 
   * @returns {number}
   */
  getPartBreakdownGroupsTotalParts(options: {
    tags: string[],
    invert?: boolean
  } | {
    partBreakdownGroups: PartBreakdownItemGroupInterface[]
  }): number {
    const groups = 'tags' in options? this.getPartBreakdownGroups(options) : options.partBreakdownGroups;
    let totalParts = 0;
    for(let group of groups){
      totalParts += group.quantity;
    }
    return totalParts;
  }

  /**
   * Gets the QuoteKitParts that are Kits. If the quoteKitPart is provided, it only looks at it's children for kits.
   * @param options 
   * @returns {QuoteKitPartEditableInterface[]}
   */
  getKits(options?: {
    quoteKitPart?: QuoteKitPartEditableInterface,
    includeSubkits?: boolean
  }): QuoteKitPartEditableInterface[] {
    const children = Array.isArray(options?.quoteKitPart?.children)? options.quoteKitPart.children : options?.quoteKitPart?.data?.QuotePart ? [options.quoteKitPart] : this.quote.children;
    const kits: QuoteKitPartEditableInterface[] = [];
    const getKits = (children: QuoteKitPartEditableInterface[])=>{
      for(let qkp of children){
        if(qkp.data.QuoteKit){
          kits.push(qkp);
          if(options?.includeSubkits == true){
            getKits(qkp.children);
          }
        }
      }
    };
    getKits(children);
    return kits;
  }

  /**
   * Gets the QuoteKitParts that are Parts. If the quoteKitPart is provided, it only looks at it's children for parts.
   * @param options 
   * @returns {QuoteKitPartEditableInterface[]}
   */
  getParts(options?: {
    quoteKitPart?: QuoteKitPartEditableInterface,
    includeSubparts?: boolean
  }){
    const children = Array.isArray(options?.quoteKitPart?.children)? options.quoteKitPart.children : options?.quoteKitPart?.data?.QuotePart ? [options.quoteKitPart] : this.quote.children;
    const parts: QuoteKitPartEditableInterface[] = [];
    const getParts = (children: QuoteKitPartEditableInterface[])=>{
      for(let qkp of children){
        if(qkp.data.QuotePart){
          parts.push(qkp);
        }
        if(qkp.data.QuoteKit && options?.includeSubparts == true){
          getParts(qkp.children);
        }
      }
    };
    getParts(children);
    return parts;
  }

  /**
   * Gets the total number of parts in the Quote from the QuoteKitParts.
   * @param options 
   * @returns {number}
   */
  getTotalParts(options?: {
    quoteKitPart?: QuoteKitPartEditableInterface
  }): number {
    const children = Array.isArray(options?.quoteKitPart?.children)? options.quoteKitPart.children : options?.quoteKitPart?.data?.QuotePart ? [options.quoteKitPart] : this.quote.children;
    let totalParts = 0;
    for(let qkp of children){
      if(qkp.data.QuotePart){
        totalParts += qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
      }else if(qkp.data.QuoteKit != null && qkp.children != null && qkp.children.length > 0){
        const qkpTtl = this.getTotalParts({quoteKitPart: qkp});
        totalParts += qkpTtl * qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
      }
    }
    return totalParts;
  }

  /**
   * Gets the total cost of materials in the Quote from the QuoteKitParts.
   * @param options 
   * @returns {number}
   */
  getTotalPartCost(options?: {
    quoteKitPart?: QuoteKitPartEditableInterface
  }){
    const children = Array.isArray(options?.quoteKitPart?.children)? options.quoteKitPart.children : options?.quoteKitPart?.data?.QuotePart ? [options.quoteKitPart] : this.quote.children;
    let totalCost = 0;
    for(let qkp of children){
      if(qkp.data.QuotePart){
        totalCost += qkp.data.QuotePart.QuotePart.QuotePart_Cost * qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
      }else if(qkp.data.QuoteKit){
        totalCost += this.getTotalPartCost({quoteKitPart: qkp}) * qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
      }
    }
    return totalCost;
  }

  /**
   * Gets the final cost of the Quote from the materials, labor, and tax.
   * @param options 
   * @returns {number}
   */
  getTotalCost(options?: {
    quoteKitPart?: QuoteKitPartEditableInterface
  }): number {
    let totalCost = 0;
    
    // Set the options for the breakdowns
    let nonLaborOps = {tags: ['Labor'], invert: true}; // Just means get everything but parts with the Labor tag
    let laborOps = {tags: ['Labor']};
    if(options?.quoteKitPart){
      nonLaborOps['quoteKitPart'] = options.quoteKitPart;
      laborOps['quoteKitPart'] = options.quoteKitPart;
    }

    // Now we can get the cost of parts from getNonLaborParts
    const nonLaborBreakdown = this.getPartBreakdownGroups(nonLaborOps);
    const nonLaborCost = this.getPartBreakdownGroupsCost({partBreakdownGroups: nonLaborBreakdown});

    // Then, get the cost of labor parts
    const laborBreakdown = this.getPartBreakdownGroups(laborOps);
    const laborCost = this.getPartBreakdownGroupsCost({partBreakdownGroups: laborBreakdown});

    // Now for the secret crabby-patty formula
    totalCost = 
      ( 
        ( nonLaborCost * this.quote.data.Quote.Quote_MaterialMargin * this.quote.data.Quote.Quote_TaxMargin ) // Tax only the materials
        + ( laborCost * this.quote.data.Quote.Quote_LaborMargin ) 
      ) 
      * this.quote.data.Quote.Quote_GibsonMargin;

    return totalCost;
  }

  /**
   * Gets the raw cost of materials and labor from the Quote, without any markup or tax.
   * @param options 
   * @returns {number}
   */
  getRawCost(options?: {
    quoteKitPart?: QuoteKitPartEditableInterface
  }): number{ 
    let totalCost = 0;
    
    // Set the options for the breakdowns
    let nonLaborOps = {tags: ['Labor'], invert: true}; // Just means get everything but parts with the Labor tag
    let laborOps = {tags: ['Labor']};
    if(options?.quoteKitPart){
      nonLaborOps['quoteKitPart'] = options.quoteKitPart;
      laborOps['quoteKitPart'] = options.quoteKitPart;
    }

    // Now we can get the cost of parts from getNonLaborParts
    const nonLaborBreakdown = this.getPartBreakdownGroups(nonLaborOps);
    const nonLaborCost = this.getPartBreakdownGroupsCost({partBreakdownGroups: nonLaborBreakdown});

    // Then, get the cost of labor parts
    const laborBreakdown = this.getPartBreakdownGroups(laborOps);
    const laborCost = this.getPartBreakdownGroupsCost({partBreakdownGroups: laborBreakdown});

    totalCost = nonLaborCost + laborCost;
    return totalCost;
  }

  /**
   * Gets the final cost of labor.
   * @param options 
   * @returns {number}
   */
  getLaborCost(options?: {
    quoteKitPart?: QuoteKitPartEditableInterface
  }){
    let totalCost = 0;
    
    // Set the options for the breakdowns
    let laborOps = {tags: ['Labor']};
    if(options?.quoteKitPart){
      laborOps['quoteKitPart'] = options.quoteKitPart;
    }

    // Then, get the cost of labor parts
    const laborBreakdown = this.getPartBreakdownGroups(laborOps);
    const laborCost = this.getPartBreakdownGroupsCost({partBreakdownGroups: laborBreakdown});

    totalCost = laborCost * this.quote.data.Quote.Quote_TaxMargin * this.quote.data.Quote.Quote_LaborMargin * this.quote.data.Quote.Quote_GibsonMargin;
    return totalCost;
  }

  /**
   * Gets the raw cost of labor without and markup or tax.
   * @param options 
   * @returns {number}
   */
  getRawLaborCost(options?: {
    quoteKitPart?: QuoteKitPartEditableInterface
  }): number {
    let totalCost = 0;
    
    // Set the options for the breakdowns
    let laborOps = {tags: ['Labor']};
    if(options?.quoteKitPart){
      laborOps['quoteKitPart'] = options.quoteKitPart;
    }

    // Then, get the cost of labor parts
    const laborBreakdown = this.getPartBreakdownGroups(laborOps);
    const laborCost = this.getPartBreakdownGroupsCost({partBreakdownGroups: laborBreakdown});

    totalCost = laborCost;
    return totalCost;
  }

  /**
   * Gets the total number of labor parts in the Quote.
   * @param options 
   * @returns {number}
   */
  getTotalLaborParts(options?: {
    quoteKitPart?: QuoteKitPartEditableInterface
  }): number {
    let totalParts = 0;
    
    // Set the options for the breakdowns
    let laborOps = {tags: ['Labor']};
    if(options?.quoteKitPart){
      laborOps['quoteKitPart'] = options.quoteKitPart;
    }

    // Then, get the cost of labor parts
    const laborBreakdown = this.getPartBreakdownGroups(laborOps);
    const laborParts = this.getPartBreakdownGroupsTotalParts({partBreakdownGroups: laborBreakdown});

    totalParts = laborParts;
    return totalParts;
  }

  /**
   * Gets the final cost of materials.
   * @param options 
   * @returns 
   */
  getMaterialCost(options?: {
    quoteKitPart?: QuoteKitPartEditableInterface
  }): number {
    let totalCost = 0;
    
    // Set the options for the breakdowns
    let nonLaborOps = {tags: ['Labor'], invert: true}; // Just means get everything but parts with the Labor tag
    if(options?.quoteKitPart){
      nonLaborOps['quoteKitPart'] = options.quoteKitPart;
    }

    // Now we can get the cost of parts from getNonLaborParts
    const nonLaborBreakdown = this.getPartBreakdownGroups(nonLaborOps);
    const nonLaborCost = this.getPartBreakdownGroupsCost({partBreakdownGroups: nonLaborBreakdown});

    totalCost = nonLaborCost * this.quote.data.Quote.Quote_TaxMargin  * this.quote.data.Quote.Quote_MaterialMargin * this.quote.data.Quote.Quote_GibsonMargin;
    return totalCost;
  }

  /**
   * Gets the raw cost of materials without any markup or tax.
   * @param options 
   * @returns {number}
   */
  getRawMaterialCost(options?: {
    quoteKitPart?: QuoteKitPartEditableInterface
  }): number {
    let totalCost = 0;
    
    // Set the options for the breakdowns
    let nonLaborOps = {tags: ['Labor'], invert: true}; // Just means get everything but parts with the Labor tag
    if(options?.quoteKitPart){
      nonLaborOps['quoteKitPart'] = options.quoteKitPart;
    }

    // Now we can get the cost of parts from getNonLaborParts
    const nonLaborBreakdown = this.getPartBreakdownGroups(nonLaborOps);
    const nonLaborCost = this.getPartBreakdownGroupsCost({partBreakdownGroups: nonLaborBreakdown});

    totalCost = nonLaborCost;
    return totalCost;
  }

  /**
   * Gets the total number of material parts in the Quote.
   * @param options 
   * @returns {number}
   */
  getTotalMaterialParts(options?: {
    quoteKitPart?: QuoteKitPartEditableInterface
  }): number {
    let totalParts = 0;
    
    // Set the options for the breakdowns
    let nonLaborOps = {tags: ['Labor'], invert: true}; // Just means get everything but parts with the Labor tag
    if(options?.quoteKitPart){
      nonLaborOps['quoteKitPart'] = options.quoteKitPart;
    }

    // Now we can get the cost of parts from getNonLaborParts
    const nonLaborBreakdown = this.getPartBreakdownGroups(nonLaborOps);
    const nonLaborParts = this.getPartBreakdownGroupsTotalParts({partBreakdownGroups: nonLaborBreakdown});

    totalParts = nonLaborParts;
    return totalParts;
  }

  // Options stuff
  
  /**
   * Gets the selected QuoteOptionKitPart children, or the selected children inside of the provided QuoteOptionKitPart.
   * @param options 
   * @returns {QuoteOptionKitPartEditableInterface[]}
   */
  getSelectedQuoteOptionKitPartChildren(options?: {
    quoteOption: QuoteOptionEditableInterface;
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface
  }): QuoteOptionKitPartEditableInterface[] {
    const selectedChildren: QuoteOptionKitPartEditableInterface[] = [];
    const getSelected = (children: QuoteOptionKitPartEditableInterface[])=>{
      for(let qkp of children){
        if(qkp.selected.value == true){
          selectedChildren.push(qkp);
        }else if(qkp.data.QuoteOptionKit){
          getSelected(qkp.children);
        }
      }
    };
    getSelected(options?.quoteOptionKitPart?.children || options.quoteOption.children);
    return selectedChildren;
  }

  /**
   * Deselects all the children of the QuoteOption or provided QuoteOptionKitPart.
   * @param options 
   */
  deselectQuoteOptionKitPartChildren(options?: {
    quoteOption: QuoteOptionEditableInterface;
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface
  }){
    const desel = (children: QuoteOptionKitPartEditableInterface[])=>{
      children.map((qkp)=>{
        qkp.selected.setValue(false);
        if(qkp.data.QuoteOptionKit){
          desel(qkp.children);
        }
      });
    };
    desel(options?.quoteOptionKitPart?.children || options.quoteOption.children);
  } 
  
  /**
   * Gets a part breakdown of the QuoteOption, which goes over each part, its overall quantity, and what kits have which quantity which make up the total.
   * @param options 
   * @returns {OptionPartBreakdownItemGroupInterface[]}
   */
  getOptionPartBreakdownGroups(options: {
    tags: string[];
    quoteOption: QuoteOptionEditableInterface;
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface;
    invert?: boolean;
  }): OptionPartBreakdownItemGroupInterface[] {
    const children = Array.isArray(options?.quoteOptionKitPart?.children)? options.quoteOptionKitPart.children : options.quoteOption.children;
    const parts: OptionPartBreakdownItemInterface[] = [];
    const getParts = (children: QuoteOptionKitPartEditableInterface[], parentKitQuantities: number[], parentKitNames: string[], location: BuildLocationDtoInterface|null)=>{
      for(let qokp of children){
        if(qokp?.data?.QuoteOptionPart?.QuotePart){
          const condition = options.invert == true ?
            qokp.data.QuoteOptionPart.QuotePartTags.findIndex(qpt=>options.tags.includes(qpt.Tag.Tag_Name)) == -1 
            : qokp.data.QuoteOptionPart.QuotePartTags.findIndex(qpt=>options.tags.includes(qpt.Tag.Tag_Name)) != -1;

          if(condition){
            let cost = qokp.data.QuoteOptionPart.QuotePart.QuotePart_Cost * qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
            let quantity = qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
            for(let i = 0; i < parentKitQuantities.length; i++){
              cost *= parentKitQuantities[i];
              quantity *= parentKitQuantities[i];
            }

            const laborPart: OptionPartBreakdownItemInterface = {
              data: qokp.data,
              cost: cost,
              quantity: quantity,
              parents: [...parentKitNames]
            };
            parts.push(laborPart);
          }
        }
        if(qokp.data.QuoteOptionKit){
          const newParentKitQuantities = [...parentKitQuantities];
          newParentKitQuantities.push(qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity);
          const newParentKitNames = [...parentKitNames];
          newParentKitNames.push(qokp.data.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Name);

          // If the location is not NONE, we need to update the location
          let loc: BuildLocationDtoInterface = location;
          if(qokp.data.BuildLocation.BuildLocation_Code != 'NONE' || location == null){
            loc = qokp.data.BuildLocation;
          }

          getParts(qokp.children, newParentKitQuantities, newParentKitNames, loc);
        }
      }
    }
    getParts(children, [], [], null);

    const partGroups: OptionPartBreakdownItemGroupInterface[] = [];
    for(let prt of parts){
      const groupIndex = partGroups.findIndex((group)=>{
        return group.name == prt.data.QuoteOptionPart.QuotePart.QuotePart_Code;
      });
      if(groupIndex > -1){
        partGroups[groupIndex].totalCost += prt.cost;
        partGroups[groupIndex].quantity += prt.quantity;
        partGroups[groupIndex].items.push(prt);
      }else{
        const newGroup: OptionPartBreakdownItemGroupInterface = {
          name: prt.data.QuoteOptionPart.QuotePart.QuotePart_Code,
          partCost: prt.data.QuoteOptionPart.QuotePart.QuotePart_Cost,
          totalCost: prt.cost,
          quantity: prt.quantity,
          desc: prt.data.QuoteOptionPart.QuotePart.QuotePart_Desc,
          items: [prt],
        };
        partGroups.push(newGroup);
      }
    }

    return partGroups;
  }

  /**
   * Gets the Options total QuoteOptionKitParts quantity.
   * @param options 
   * @returns {number}
   */
  getOptionTotalParts(options?: {
    quoteOption: QuoteOptionEditableInterface;
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface;
  }): number {
    const children = Array.isArray(options?.quoteOptionKitPart?.children)? options.quoteOptionKitPart.children : options?.quoteOption?.children;
    let totalParts = 0;
    for(let qkp of children){
      if(qkp.data.QuoteOptionPart){
        totalParts += qkp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
      }else if(qkp.data.QuoteOptionKit){
        totalParts += this.getOptionTotalParts({quoteOption: options.quoteOption, quoteOptionKitPart: qkp}) * qkp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
      }
    }
    return totalParts;
  }

  /**
   * Gets the QuoteOptionPartBreakdownGroups cost.
   * @param options 
   * @returns {number}
   */
  getOptionPartBreakdownGroupsCost(options: {
    tags: string[],
    quoteOption: QuoteOptionEditableInterface,
    invert?: boolean
  } | {
    optionPartBreakdownGroups: OptionPartBreakdownItemGroupInterface[]
  }): number {
    const groups = 'tags' in options? this.getOptionPartBreakdownGroups(options) : options.optionPartBreakdownGroups;
    let totalCost = 0;
    for(let group of groups){
      totalCost += group.totalCost;
    }
    return totalCost;
  }

  /**
   * Gets the total number of parts in the QuoteOptionPartBreakdownGroups.
   * @param options 
   * @returns {number}
   */
  getOptionPartBreakdownGroupsTotalParts(options: {
    tags: string[],
    quoteOption: QuoteOptionEditableInterface,
    invert?: boolean
  } | {
    optionPartBreakdownGroups: OptionPartBreakdownItemGroupInterface[]
  }): number {
    const groups = 'tags' in options? this.getOptionPartBreakdownGroups(options) : options.optionPartBreakdownGroups;
    let totalParts = 0;
    for(let group of groups){
      totalParts += group.quantity;
    }
    return totalParts;
  }

  /**
   * Gets the total cost of the QuoteOption without margins or tax.
   * @param options 
   * @returns {number}
   */
  getRawOptionCost(options?: {
    quoteOption: QuoteOptionEditableInterface,
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface
  }){
    let totalCost = 0;
    
    // Set the options for the breakdowns
    let nonLaborOps = {quoteOption: options.quoteOption, tags: ['Labor'], invert: true}; // Just means get everything but parts with the Labor tag
    let laborOps = {quoteOption: options.quoteOption, tags: ['Labor']};
    if(options?.quoteOptionKitPart != null){
      nonLaborOps['quoteOptionKitPart'] = options.quoteOptionKitPart;
      laborOps['quoteOptionKitPart'] = options.quoteOptionKitPart;
    }

    // Now we can get the cost of parts from getNonLaborParts
    const nonLaborBreakdown = this.getOptionPartBreakdownGroups(nonLaborOps);
    const nonLaborCost = this.getOptionPartBreakdownGroupsCost({optionPartBreakdownGroups: nonLaborBreakdown});

    // Then, get the cost of labor parts
    const laborBreakdown = this.getOptionPartBreakdownGroups(laborOps);
    const laborCost = this.getOptionPartBreakdownGroupsCost({optionPartBreakdownGroups: laborBreakdown});

    totalCost = nonLaborCost + laborCost;
    return totalCost;
  }

  /**
   * Gets the total cost of the QuoteOption with margins and tax.
   * @param options
   * @returns {QuoteOptionKitPartEditableInterface[]}
   */
  getOptionKits(options?: {
    quoteOption: QuoteOptionEditableInterface,
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface,
    includeSubkits?: boolean
  }): QuoteOptionKitPartEditableInterface[] {
    const children = Array.isArray(options?.quoteOptionKitPart?.children)? options.quoteOptionKitPart.children : options.quoteOption.children;
    const kits: QuoteOptionKitPartEditableInterface[] = [];
    const getKits = (children: QuoteOptionKitPartEditableInterface[])=>{
      for(let qkp of children){
        if(qkp.data.QuoteOptionKit){
          kits.push(qkp);
          if(options?.includeSubkits == true){
            getKits(qkp.children);
          }
        }
      }
    };
    getKits(children);
    return kits;
  }

  /**
   * Gets the QuoteOptionKitPart children that are Parts.
   * @param options 
   * @returns {QuoteOptionKitPartEditableInterface[]}
   */
  getOptionParts(options?: {
    quoteOption: QuoteOptionEditableInterface,
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface,
    includeSubparts?: boolean
  }): QuoteOptionKitPartEditableInterface[] {
    const children = Array.isArray(options?.quoteOptionKitPart?.children)? options.quoteOptionKitPart.children : options.quoteOption.children;
    const parts: QuoteOptionKitPartEditableInterface[] = [];
    const getParts = (children: QuoteOptionKitPartEditableInterface[])=>{
      for(let qkp of children){
        if(qkp.data.QuoteOptionPart){
          parts.push(qkp);
        }
        if(qkp.data.QuoteOptionKit && options?.includeSubparts == true){
          getParts(qkp.children);
        }
      }
    };
    getParts(children);
    return parts;
  }

  /**
   * Flattens the hierarchical structure of QuoteKitPartEditableInterface objects into a flat array of ContextQuotePartDtoInterface objects
   * @param options 
   * @returns {ContextQuotePartDtoInterface[]}
   */
  convertQuoteKitPartEditablesToQuoteKitPartDtos(options?: {
    quoteKitPartEditable?: QuoteKitPartEditableInterface
  }): ContextQuotePartDtoInterface[] {
    const children = Array.isArray(options?.quoteKitPartEditable?.children)? options.quoteKitPartEditable.children : this.quote.children;
    const conv = (children: QuoteKitPartEditableInterface[])=>{
      const qps: ContextQuotePartDtoInterface[] = [];
      for(let qkp of children){
        if(qkp.data.QuotePart){
          const qp: QuotePartDtoInterface = {
            QuotePart_guid: qkp.data.QuotePart.QuotePart.QuotePart_guid,
            QuotePart_Code: qkp.data.QuotePart.QuotePart.QuotePart_Code,
            QuotePart_Cost: qkp.costControl.value,
            QuotePart_Desc: qkp.data.QuotePart.QuotePart.QuotePart_Desc,
            QuotePart_Ext_Desc: qkp.data.QuotePart.QuotePart.QuotePart_Ext_Desc,
            QuotePart_Type: qkp.data.QuotePart.QuotePart.QuotePart_Type,
            QuotePart_Inactive: qkp.data.QuotePart.QuotePart.QuotePart_Inactive
          };
          const p: ContextPartDtoInterface = qkp.data.QuotePart.Part;
          qps.push({
            QuotePart: qp,
            Part: p,
            QuotePartTags: qkp.data.QuotePart.QuotePartTags
          });
        }
        if(qkp.data.QuoteKit){
          qps.push(...conv(qkp.children));
        }
      }
      return qps;
    }
    return conv(children);
  }

  /**
   * Gets the QuoteOptionReplaces (credited parts) from the QuoteOption.
   * @param options 
   * @returns {QuoteKitPartEditableDataInterface[]}
   */
  getCreditedQuoteKitParts(options: {
    quoteOption: QuoteOptionEditableInterface;
    useOriginalQuoteOptionReplace?: boolean;
  }): QuoteKitPartEditableInterface[] {
    const creditedParts: QuoteKitPartEditableInterface[] = [];

    const getParts = (children: QuoteKitPartEditableInterface[])=>{
      for(let qkp of children){
        // If the part is in the credited parts control, add it to the credited parts
        if(options.quoteOption.creditedQuoteKitPartsControl.includes(qkp.data.QuoteKitPart.QuoteKitPart_guid)){
          creditedParts.push(qkp);
        }else if(qkp.children != null && qkp.children.length > 0){
          getParts(qkp.children);
        }
      }
    };
    const getOriginalParts = (children: QuoteKitPartEditableInterface[])=>{
      for(let qkp of children){
        const guids = options.quoteOption.creditedQuoteKitParts.map(qkp=>qkp.data.QuoteKitPart.QuoteKitPart_guid);
        if(guids.includes(qkp.data.QuoteKitPart.QuoteKitPart_guid)){
          creditedParts.push(qkp);
        }else if(qkp.data.QuoteKit != null){
          getOriginalParts(qkp.children);
        }
      }
    }
    if(options.useOriginalQuoteOptionReplace){
      getOriginalParts(this.quote.children);
    }else{
      getParts(this.quote.children);
    }
    return creditedParts;
  }

  /**
   * Gets the QuoteKitPartEditable from it's guid.
   * @param options 
   * @returns {QuoteKitPartEditableInterface}
   */
  getQuoteKitPartEditableByGuid(options: {
    quoteKitPartGuid: string,
  }): QuoteKitPartEditableInterface {
    const getQKP = (children: QuoteKitPartEditableInterface[]): QuoteKitPartEditableInterface=>{
      for(let qkp of children){
        if(qkp.data.QuoteKitPart.QuoteKitPart_guid == options.quoteKitPartGuid){
          return qkp;
        }else if(qkp.data.QuoteKit){
          const found = getQKP(qkp.children);
          if(found != null){
            return found;
          }
        }
      }
      return null;
    };
    return getQKP(this.quote.children);
  }

  /**
   * Returns whether a QuoteKitPart is credited in the QuoteOption.
   * @param options 
   * @returns {boolean}
   */
  isQuoteKitPartCredited(options: {
    quoteKitPart: QuoteKitPartEditableInterface;
    quoteOption: QuoteOptionEditableInterface;
  }): boolean {
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption});
    return creditedParts.findIndex((qkp)=>qkp.data.QuoteKitPart.QuoteKitPart_guid == options.quoteKitPart.data.QuoteKitPart.QuoteKitPart_guid) != -1;
  }

  /**
   * Returns whether a QuoteKitPart has children that are credited in the Option.
   * @param options 
   * @returns {boolean}
   */
  isQuoteKitPartsChildrenCredited(options: {
    quoteKitPart: QuoteKitPartEditableInterface;
    quoteOption: QuoteOptionEditableInterface;
  }){
    const children = Array.isArray(options.quoteKitPart.children)? options.quoteKitPart.children : [];
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption});
    const getIsCredited = (children: QuoteKitPartEditableInterface[])=>{
      for(let qkp of children){
        if(creditedParts.findIndex((qkpCred)=>qkpCred.data.QuoteKitPart.QuoteKitPart_guid == qkp.data.QuoteKitPart.QuoteKitPart_guid) != -1){
          return true;
        }else if(qkp.data.QuoteKit){
          if(getIsCredited(qkp.children)){
            return true;
          }
        }
      }
      return false;
    };
    return getIsCredited(children);
  }

  /**
   * Gets the credited QuoteKitPart from the QuoteOption.
   * @param options 
   * @returns {QuoteKitPartEditableDataInterface}
   */
  getCreditedPartFromQuoteKitPart(options: {
    quoteKitPart: QuoteKitPartEditableInterface;
    quoteOption: QuoteOptionEditableInterface;
  }): QuoteKitPartEditableInterface|null {
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption});
    return creditedParts.find((qkp)=>qkp.data.QuoteKitPart.QuoteKitPart_guid == options.quoteKitPart.data.QuoteKitPart.QuoteKitPart_guid)||null;
  }

  /**
   * Adds a QuoteKitPart to the credited parts of the QuoteOption.
   * @param options 
   */
  addQuoteOptionCreditedQuoteKitPart(options: {
    quoteOption: QuoteOptionEditableInterface;
    quoteKitPart: QuoteKitPartEditableInterface;
  }){
    if(
      !options.quoteOption.creditedQuoteKitPartsControl.includes(options.quoteKitPart.data.QuoteKitPart.QuoteKitPart_guid)
    ){
      options.quoteOption.creditedQuoteKitPartsControl.push(options.quoteKitPart.data.QuoteKitPart.QuoteKitPart_guid);
    }
    this.updated.next();
  }

  /**
   * Removes a QuoteKitPart from the credited parts of the QuoteOption.
   * @param options 
   */
  removeQuoteOptionCreditedQuoteKitPart(options: {
    quoteOption: QuoteOptionEditableInterface;
    quoteKitPart: QuoteKitPartEditableInterface;
  }){
    const index = options.quoteOption.creditedQuoteKitPartsControl.findIndex((qkpGuid)=>qkpGuid == options.quoteKitPart.data.QuoteKitPart.QuoteKitPart_guid);
    if(index != -1){
      options.quoteOption.creditedQuoteKitPartsControl.splice(index, 1);
    }
    this.updated.next();
  }

  /**
   * Returns whether the credited parts of the QuoteOption have changed.
   * @param options 
   * @returns {boolean}
   */
  isQuoteOptionCreditedChanged(options: {
    quoteOption: QuoteOptionEditableInterface
  }){
    const newCreditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption})
      .map(qkp=>qkp.data.QuoteKitPart.QuoteKitPart_guid)
      .sort();
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption, useOriginalQuoteOptionReplace: true})
      .map(qkp=>qkp.data.QuoteKitPart.QuoteKitPart_guid)
      .sort();
    return JSON.stringify(newCreditedParts) != JSON.stringify(creditedParts); // Seeing this makes a mfer wish for lodash
  }

  /**
   * Resets the credited parts of the QuoteOption to it's last state.
   * @param options 
   */
  resetQuoteOptionCreditedPartsControl(options: {
    quoteOption: QuoteOptionEditableInterface
  }){
    options.quoteOption.creditedQuoteKitPartsControl = options.quoteOption.creditedQuoteKitParts.map(qkp=>qkp.data.QuoteKitPart.QuoteKitPart_guid);
    this.updated.next();
  }

  /**
   * Gets the total number of parts, labor and material, that are being replaced in this option.
   * @param {{quoteOption: QuoteOptionEditableInterface}} options 
   * @returns {number}
   */
  getCreditedQuoteOptionKitPartsTotalParts(options: {
    quoteOption: QuoteOptionEditableInterface
  }): number {
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption, useOriginalQuoteOptionReplace: true});
    let totalParts = 0;
    for(let qkp of creditedParts){
      let t = this.getTotalParts({quoteKitPart: qkp});
      totalParts += t
    }
    return totalParts;
  }
  
  /**
   * Gets the final cost of the parts that are being replaced in this option.
   * @param {{quoteOption: QuoteOptionEditableInterface}} options 
   * @returns {number}
   */
  getCreditedQuoteOptionKitPartsCost(options: {
    quoteOption: QuoteOptionEditableInterface
  }): number {
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption, useOriginalQuoteOptionReplace: true});
    let totalCost = 0;
    for(let qkp of creditedParts){
      totalCost += this.getTotalCost({quoteKitPart: qkp}) * qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
    }
    return totalCost;
  }

  /**
   * Gets the raw cost of the parts that are being replaced in this option.
   * @param {{quoteOption: QuoteOptionEditableInterface}} options 
   * @returns {number}
   */
  getCreditedQuoteOptionKitPartsRawCost(options: {
    quoteOption: QuoteOptionEditableInterface
  }): number {
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption, useOriginalQuoteOptionReplace: true});
    let totalCost = 0;
    for(let qkp of creditedParts){
      totalCost += this.getTotalPartCost({quoteKitPart: qkp}) * qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
    }
    return totalCost;
  }

  /**
   * Gets the number of material parts that are being replaced in this option.
   * @param {{quoteOption: QuoteOptionEditableInterface}} options 
   * @returns {number}
   */
  getCreditedQuoteOptionKitPartsMaterialTotalItems(options: {
    quoteOption: QuoteOptionEditableInterface
  }): number {
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption, useOriginalQuoteOptionReplace: true});
    let totalCost = 0;
    for(let qkp of creditedParts){
      totalCost += this.getTotalMaterialParts({quoteKitPart: qkp});
    }
    return totalCost;
  }

  /**
   * Gets the raw cost of the material parts that are being replaced in this option.
   * @param {{quoteOption: QuoteOptionEditableInterface}} options 
   * @returns {number}
   */
  getCreditedQuoteOptionKitPartsRawMaterialCost(options: {
    quoteOption: QuoteOptionEditableInterface
  }): number {
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption, useOriginalQuoteOptionReplace: true});
    let totalCost = 0;
    for(let qkp of creditedParts){
      totalCost += this.getRawMaterialCost({quoteKitPart: qkp}) * qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
    }
    return totalCost;
  }

  /**
   * Gets the final cost of the material parts that are being replaced in this option.
   * @param {{quoteOption: QuoteOptionEditableInterface}} options 
   * @returns {number}
   */
  getCreditedQuoteOptionKitPartsMaterialCost(options: {
    quoteOption: QuoteOptionEditableInterface
  }): number {
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption, useOriginalQuoteOptionReplace: true});
    let totalCost = 0;
    for(let qkp of creditedParts){
      totalCost += this.getMaterialCost({quoteKitPart: qkp}) * qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
    }
    return totalCost;
  }

  /**
   * Gets the number of labor parts that are being replaced in this option.
   * @param {{quoteOption: QuoteOptionEditableInterface}} options 
   * @returns {number}
   */
  getCreditedQuoteOptionKitPartsLaborTotalItems(options: {
    quoteOption: QuoteOptionEditableInterface
  }): number {
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption, useOriginalQuoteOptionReplace: true});
    let totalCost = 0;
    for(let qkp of creditedParts){
      totalCost += this.getTotalLaborParts({quoteKitPart: qkp});
    }
    return totalCost;
  }

  /**
   * Gets the raw cost of labor items that are being replaced in this option.
   * @param {{quoteOption: QuoteOptionEditableInterface}} options 
   * @returns {number}
   */
  getCreditedQuoteOptionKitPartsRawLaborCost(options: {
    quoteOption: QuoteOptionEditableInterface
  }): number {
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption, useOriginalQuoteOptionReplace: true});
    let totalCost = 0;
    for(let qkp of creditedParts){
      totalCost += this.getRawLaborCost({quoteKitPart: qkp}) * qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
    }
    return totalCost;
  }

  /**
   * Gets the final cost of labor items that are being replaced in this option.
   * @param {{quoteOption: QuoteOptionEditableInterface}} options 
   * @returns {number}
   */
  getCreditedQuoteOptionKitPartsLaborTotalCost(options: {
    quoteOption: QuoteOptionEditableInterface
  }): number {
    const creditedParts = this.getCreditedQuoteKitParts({quoteOption: options.quoteOption, useOriginalQuoteOptionReplace: true});
    let totalCost = 0;
    for(let qkp of creditedParts){
      totalCost += this.getLaborCost({quoteKitPart: qkp}) * qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
    }
    return totalCost;
  }

  getReplacementQuoteOptionKitPartsTotalParts(options: {
    quoteOption: QuoteOptionEditableInterface;
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface;
  }): number {
    const getTtl = (children: QuoteOptionKitPartEditableInterface[])=>{
      let totalParts = 0;
      for(let qokp of children){
        if(qokp.data.QuoteOptionPart){
          totalParts += qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
        }
        if(qokp.data.QuoteOptionKit){
          totalParts += getTtl(qokp.children) * qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
        }
      }
      return totalParts;
    }
    if('quoteOptionKitPart' in options){
      return getTtl(options.quoteOptionKitPart.children);
    }else {
      return getTtl(options.quoteOption.children);
    }
  }

  getReplacementQuoteOptionKitPartsCost(options: {
    quoteOption: QuoteOptionEditableInterface;
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface;
  }): number {
    return this.getReplacementQuoteOptionKitPartsLaborCost(options) + this.getReplacementQuoteOptionKitPartsMaterialCost(options);
  }

  getReplacementQuoteOptionKitPartsRawCost(options: {
    quoteOption: QuoteOptionEditableInterface;
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface;
  }): number {
    return this.getReplacementQuoteOptionKitPartsRawLaborCost(options) + this.getReplacementQuoteOptionKitPartsRawMaterialCost(options);
  }

  getReplacementQuoteOptionKitPartsMaterialTotalItems(options: {
    quoteOption: QuoteOptionEditableInterface;
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface;
  }): number {
    if('quoteOptionKitPart' in options){
      const lgroups = this.getOptionPartBreakdownGroups({quoteOption: options.quoteOption, quoteOptionKitPart: options.quoteOptionKitPart, tags: ['Labor'], invert: true});
      return this.getOptionPartBreakdownGroupsTotalParts({optionPartBreakdownGroups: lgroups});
    }else{
      const lgroups = this.getOptionPartBreakdownGroups({quoteOption: options.quoteOption, tags: ['Labor'], invert: true});
      return this.getOptionPartBreakdownGroupsTotalParts({optionPartBreakdownGroups: lgroups});
    }
  }

  getReplacementQuoteOptionKitPartsRawMaterialCost(options: {
    quoteOption: QuoteOptionEditableInterface;
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface;
  }): number {
    if('quoteOptionKitPart' in options){
      const lgroups = this.getOptionPartBreakdownGroups({quoteOption: options.quoteOption, quoteOptionKitPart: options.quoteOptionKitPart, tags: ['Labor'], invert: true});
      return this.getOptionPartBreakdownGroupsCost({optionPartBreakdownGroups: lgroups});
    }else{
      const lgroups = this.getOptionPartBreakdownGroups({quoteOption: options.quoteOption, tags: ['Labor'], invert: true});
      return this.getOptionPartBreakdownGroupsCost({optionPartBreakdownGroups: lgroups});
    }
  }

  getReplacementQuoteOptionKitPartsMaterialCost(options: {
    quoteOption: QuoteOptionEditableInterface;
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface;
  }): number {
    return this.getReplacementQuoteOptionKitPartsRawMaterialCost(options) * this.quote.data.Quote.Quote_MaterialMargin * this.quote.data.Quote.Quote_TaxMargin * this.quote.data.Quote.Quote_GibsonMargin;
  }

  getReplacementQuoteOptionKitPartsLaborTotalItems(options:{
    quoteOption: QuoteOptionEditableInterface;
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface
  }): number {
    if('quoteOptionKitPart' in options){
      const lgroups = this.getOptionPartBreakdownGroups({quoteOption: options.quoteOption, quoteOptionKitPart: options.quoteOptionKitPart, tags: ['Labor']});
      return this.getOptionPartBreakdownGroupsTotalParts({optionPartBreakdownGroups: lgroups});
    }else{
      const lgroups = this.getOptionPartBreakdownGroups({quoteOption: options.quoteOption, tags: ['Labor']});
      return this.getOptionPartBreakdownGroupsTotalParts({optionPartBreakdownGroups: lgroups});
    }
  }

  getReplacementQuoteOptionKitPartsRawLaborCost(options: {
    quoteOption: QuoteOptionEditableInterface;
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface;
  }): number {
    if('quoteOptionKitPart' in options){
      const lgroups = this.getOptionPartBreakdownGroups({quoteOption: options.quoteOption, quoteOptionKitPart: options.quoteOptionKitPart, tags: ['Labor']});
      return this.getOptionPartBreakdownGroupsCost({optionPartBreakdownGroups: lgroups});
    }else{
      const lgroups = this.getOptionPartBreakdownGroups({quoteOption: options.quoteOption, tags: ['Labor']});
      return this.getOptionPartBreakdownGroupsCost({optionPartBreakdownGroups: lgroups});
    }
  }

  getReplacementQuoteOptionKitPartsLaborCost(options: {
    quoteOption: QuoteOptionEditableInterface;
    quoteOptionKitPart?: QuoteOptionKitPartEditableInterface;
  }): number {
    return this.getReplacementQuoteOptionKitPartsRawLaborCost(options) * this.quote.data.Quote.Quote_LaborMargin * this.quote.data.Quote.Quote_GibsonMargin;
  }

  /**
   * Gets an array of all of the parts in the Quote from QuoteKitParts and QuoteOptionKitParts.
   * @returns {ContextQuotePartDtoInterface[]}
   */
  getQuoteParts(): ContextQuotePartDtoInterface[] {
    // Go through all the QuoteKitPartEditables and QuoteOptionKitPartEditables to get the QuoteParts
    // We use {[key: string]: ContextQuotePartDtoInterface} so that we don't have to filter out dupes later which is xtremely xpensive
    // Seriously, using this hashmap-like object over arrays is like going from a short sword to Rivers of Blood in a fight with Malania
    const getQuotePartsFromQuoteKitParts = (children: QuoteKitPartEditableInterface[], qps: {[key: string]: ContextQuotePartDtoInterface})=>{
      let quoteParts = {...qps};
      for(let qkp of children){
        if(qkp.data?.QuotePart){
          quoteParts[qkp.data.QuotePart.QuotePart.QuotePart_guid] = qkp.data.QuotePart;
        }else if(qkp.data.QuoteKit){
          quoteParts = {...quoteParts, ...getQuotePartsFromQuoteKitParts(qkp.children, quoteParts)};
        }
      }
      return quoteParts;
    };
    const getQuotePartsFromQuoteOptionKitParts = (children: QuoteOptionKitPartEditableInterface[], qps: {[key: string]: ContextQuotePartDtoInterface})=>{
      let quoteParts = {...qps};
      for(let qkp of children){
        if(qkp.data?.QuoteOptionPart?.QuotePart){
          quoteParts[qkp.data.QuoteOptionPart.QuotePart.QuotePart_guid] = qkp.data.QuoteOptionPart;
        }else if(qkp.data.QuoteOptionKit){
          quoteParts = {...quoteParts, ...getQuotePartsFromQuoteOptionKitParts(qkp.children, quoteParts)};
        }
      }
      return quoteParts;
    };

    let quoteParts: {[key: string]: ContextQuotePartDtoInterface} = {};
    quoteParts = getQuotePartsFromQuoteKitParts(this.quote.children, {});
    for(let qop of this.quote.options){
      quoteParts = {...quoteParts, ...getQuotePartsFromQuoteOptionKitParts(qop.children, quoteParts)};
    }
    
    return Object.values(quoteParts);
  }

  /**
   * Gets all the build locations
   * @returns {BuildLocationDtoInterface[]} A list of all the build locations in the Quote.
   */
  getBuildLocations(): BuildLocationDtoInterface[] {
    return this.buildLocations;
  }

  /**
   * Gets all the tags
   * @returns {TagDtoInterface[]}
   */
  getTags(options?: {
    tagGuids: string[];
  } | {
    tagNames: string[];
  }): TagDtoInterface[] {
    return this.tags;
  }

  /**
   * Gets a BuildLocation by it's BuildLocation_guid or BuildLocation_Code.
   * @param options 
   * @returns {BuildLocationDtoInterface}
   */
  getBuildLocation(options: 
    {
      buildLocationGuid: string;
    } | {
      buildLocationCode: string;
    }
  ): BuildLocationDtoInterface|null {
    if('buildLocationGuid' in options){
      return this.buildLocations.find((bl)=>bl.BuildLocation_guid == options.buildLocationGuid);
    }
    return this.buildLocations.find((bl)=>bl.BuildLocation_Code == options.buildLocationCode);
  }

  /**
   * Gets a Tag by it's Tag_guid or Tag_Name.
   * @param options 
   * @returns {TagDtoInterface}
   */
  getTag(options: {
    tagGuid: string
  } | {
    tagName
  }): TagDtoInterface {
    if('tagGuid' in options){
      return this.tags.find((tag)=>tag.Tag_guid == options.tagGuid);
    }
    return this.tags.find((tag)=>tag.Tag_Name == options.tagName);
  }

}
