<div class="container pb-5">
  <div class="card my-5 shadow-lg text-center" id="noprint">
      <div>
        <!-- <mat-accordion>
          <mat-expansion-panel *ngFor="let log of history">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ log[0].JobNumber }}
              </mat-panel-title>
              <mat-panel-description>
                {{ log[0].TechName }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <table class="table table-striped table-hover">
              <tr>
                <th>Tech</th>
                <th>Job Number</th>
                <th>Item</th>
                <th>Item Quantity</th>
              </tr>
              <tr *ngFor="let resp of log">
                <td>{{ resp.TechName }}</td>
                <td>{{ resp.JobNumber }}</td>
                <td>{{ resp.ItemCode }}</td>
                <td>{{ resp.Quantity }}</td>
              </tr>
            </table>
          </mat-expansion-panel>
        </mat-accordion> -->
    <ng-container>
      <h1>Print Pull Tickets</h1>
      <div id="PrintPullTickets" class="col-12">
        <h2 class="col-3">Search By Date:</h2>
        <input
        type="text"
        class="form-control p-2 col-3"
        id="FromDate"
        name="FromDate"
        bsDatepicker
        [(bsValue)]="FromDate"
        [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
        />
        <input
        type="text"
        class="form-control p-2 col-3"
        id="ToDate"
        name="ToDate"
        bsDatepicker
        [(bsValue)]="ToDate"
        [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
        />
        <div class="col-3">
          <button id="buttons" mat-raised-button color="danger" (click)="printThePullSheets(FromDate, ToDate)">
            <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i></span>
            <span *ngIf="!loading">Preview By Date</span></button>
        </div>
      </div>
      </ng-container>
      <br> <br>
      <div *ngIf="itemsToTransfer.length == 0 && !loading && dateFormatted != ''">
        No pull tickets ready for {{dateFormatted}}.
       </div>
<hr>

       <ng-container>
         <div class="col-12 p-2" id="PrintPullTickets">
           <h2 class="col-3">Search By Job #:</h2>
           <input class="col-6" type="text" class="form-control col-6" id="jobNumber" name="jobNumber" [(ngModel)]="jobNumber"/>
           <div class="col-3 align-content-left" >
             <button id="buttons" mat-raised-button color="danger" (click)="findJobByJobNumber(jobNumber)">Preview By Job Number</button>
           </div>
         </div>
         <div *ngIf="itemsByJobNumber">
           No pull tickets for {{numberProjected}}.
         </div>
       </ng-container>


      </div>
        <div *ngIf="!empty">
          <div class="m-0 p-0" *ngFor="let Jobs of itemsToTransfer">
          <div class="container-fluid">
          <div class="row justify-content-center" [id]="'pdfData'">
            <div
            class="row justify-content-center"
            [ngStyle]="{ 'min-width': '100%', 'max-width': '100%' }"
            >
            <table class="table table-bordered" id="page-break">
              <thead>
                <tr>
                  <td>
                    <img
                    [ngStyle]="{ height: '100px' }"
                    src="assets/gibsonLogo.PNG"
                    alt="image"
                    />
                  </td>
                  <td>
                    <div class="row justify-content-center mt-1 p-0">
                      <qr-code value="{{baseUrl}}/Service/MaterialOTC?jobnumber={{ Jobs[0].JobNumber }}"></qr-code>
                    </div>
                    <div class="row justify-content-center mt-1 p-0">
                      <p>
                        <b>Job #: {{ Jobs[0].JobNumber }}</b>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="row justify-content-center m-0 p-0">
                      <h3><b>{{Jobs[0].BusinessUnit}} Pull Sheet</b></h3>
                    </div>
                    <ng-container *ngIf="itemsToTransfer.length > 0">
                        <div class="row justify-content-center mt-1 p-0">
                          <h5><b>Address: {{Jobs[0].Address}}</b></h5>
                        </div>
                        <div class="row justify-content-center mt-1 p-0">
                          <p>
                            <b>Technician:</b>
                            {{ Jobs[0].TechName }}
                          </p>
                        </div>
                        <div class="row justify-content-center mt-1 p-0">
                          <p>
                            <b>Print Date:</b> {{ pdfTodayDate }}
                            <br>
                            <b>Job Scheduled Date:</b> {{ Jobs[0].ScheduledDate }}
                          </p>
                        </div>
                      </ng-container>
                    </td>
                    <td>
                      <div class="row justify-content-center mt-1 p-0">
                          <h4>Job #: {{ Jobs[0].JobNumber }}</h4>
                      <br>
                          <ngx-barcode6
                        [bc-value]="Jobs[0].JobNumber"
                        [bc-display-value]="false"
                        [bc-format]="'CODE128'"
                        [bc-width]="1"
                        [bc-height]="40"
                        ></ngx-barcode6>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tr class="table-primary" >
                  <th>Item</th>
                  <th>Barcode</th>
                  <th>Description</th>
                  <th>Quantity</th>
                </tr>
                <tr *ngFor="let itemgroup of Jobs">
                  <ng-container>
                    <th style="width: 10%">{{ itemgroup.ItemCode }}</th>
                    <td style="width: 20%">
                      <ngx-barcode6
                      [bc-value]="itemgroup.ItemCode"
                      [bc-display-value]="false"
                      [bc-format]="'CODE128'"
                      [bc-width]="1"
                      [bc-height]="40"
                      ></ngx-barcode6>
                    </td>
                    <td style="width: 50%">
                      {{ itemgroup.Description }}
                    </td>
                    <td style="width: 20%">{{ itemgroup.Ordered }}</td>
                  </ng-container>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <button mat-raised-button color="danger" *ngIf="print && itemsToTransfer.length > 0" (click)="printPage()">
        <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i></span>
        Print</button>
    </div>




  </div>
</div>
<div id="print-view-page">
  <div class="m-0 p-0" *ngFor="let Jobs of itemsToTransfer">
    <div class="container-fluid">
    <div class="row justify-content-center" [id]="'pdfData'">
      <div
      class="row justify-content-center"
      [ngStyle]="{ 'min-width': '100%', 'max-width': '100%' }"
      >
      <h4>If needing to add additional parts: from the home screen of the scanner please scan the QR code provided to go the
        correct website.
      <br>
     If already in the web app: go to Service | Warranty -> Material OTC then scan barcode to populate the job ticket.
    </h4>
      <table class="table table-bordered" id="page-break">
        <thead>
          <tr>
            <td>
              <img
              [ngStyle]="{ height: '100px' }"
              src="assets/gibsonLogo.PNG"
              alt="image"
              />
            </td>
            <td>
              <div class="row justify-content-center mt-1 p-0">
                <qr-code value="{{baseUrl}}/Service/MaterialOTC?jobnumber={{ Jobs[0].JobNumber }}"></qr-code>
              </div>
              <div class="row justify-content-center mt-1 p-0">
                <p>
                  <b>Job #: {{ Jobs[0].JobNumber }}</b>
                </p>
              </div>
            </td>
            <td>
              <div class="row justify-content-center m-0 p-0">
                <h3><b>{{Jobs[0].BusinessUnit}} Pull Sheet</b></h3>
              </div>
              <ng-container *ngIf="itemsToTransfer.length > 0">
                  <div class="row justify-content-center mt-1 p-0">
                    <h5><b>Address: {{Jobs[0].Address}}</b></h5>
                  </div>
                  <div class="row justify-content-center mt-1 p-0">
                    <p>
                      <b>Technician:</b>
                      {{ Jobs[0].TechName }}
                    </p>
                  </div>
                  <div class="row justify-content-center mt-1 p-0">
                    <p>
                      <b>Print Date:</b> {{ pdfTodayDate }}
                      <br>
                      <b>Job Scheduled Date:</b> {{ Jobs[0].ScheduledDate }}
                    </p>
                  </div>
                </ng-container>
              </td>
              <td>
                <div class="row justify-content-center mt-1 p-0">
                    <h4>Job #: {{ Jobs[0].JobNumber }}</h4>
                <br>
                    <ngx-barcode6
                  [bc-value]="Jobs[0].JobNumber"
                  [bc-display-value]="false"
                  [bc-format]="'CODE128'"
                  [bc-width]="1"
                  [bc-height]="40"
                  ></ngx-barcode6>
                </div>
              </td>
            </tr>
          </thead>
          <tr class="table-primary" >
            <th>Item</th>
            <th>Barcode</th>
            <th>Description</th>
            <th>Quantity</th>
          </tr>
          <tr *ngFor="let itemgroup of Jobs">
            <ng-container>
              <th style="width: 10%">{{ itemgroup.ItemCode }}</th>
              <td style="width: 20%">
                <ngx-barcode6
                [bc-value]="itemgroup.ItemCode"
                [bc-display-value]="false"
                [bc-format]="'CODE128'"
                [bc-width]="1"
                [bc-height]="40"
                ></ngx-barcode6>
              </td>
              <td style="width: 50%">
                {{ itemgroup.Description }}
              </td>
              <td style="width: 20%">{{ itemgroup.Ordered }}</td>
            </ng-container>
          </tr>
        </table>
      </div>
    </div>
  </div>
