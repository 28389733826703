<div [class]="'sidebar-bg ' + (open&&useBackground? 'active' : '')" (click)="closeSidebar()" (mouseenter)="closeSidebarHover()">
  
</div>

<div
  swipeable
  (swiped)="sidebarSwiped($event)"
  [class.left]="sidebarDirection=='left'"
  [class.right]="sidebarDirection=='right'"
  [class]="
    screenSize.getClasses() +
    (open ? ' active ' : '') +
    ' sidebar'
  "
>
  <div class="sidebar-inner">
    <ng-content></ng-content>
  </div>
</div>
