<div class="container my-4">
    <div class="row">
        <mat-card class="col-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
            <mat-card-header class="w-100 text-center">
                <mat-card-title>
                    Hi {{ authService.decodedToken?.unique_name | titlecase }}! Let's Change Your Password
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form [formGroup]="resetPasswordForm" style="text-align: center;">
                    <div class="pb-2">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Current Password</mat-label>
                            <input matInput type="password" formControlName="CurrentPassword" required>
                            <mat-error *ngIf="resetPasswordForm.get('CurrentPassword').invalid">
                                {{ getCurrentPasswordErrorMessage() }}
                            </mat-error>
                        </mat-form-field>
                    </div>
    
                    <div class="pb-2">
                        <mat-form-field style="width: 100%;">
                            <mat-label>New Password</mat-label>
                            <input matInput type="password" formControlName="NewPassword" required>
                            <mat-error *ngIf="resetPasswordForm.get('NewPassword').invalid">
                                {{ getNewPasswordErrorMessage() }}
                            </mat-error>
                        </mat-form-field>
                    </div>
    
                    <div class="pb-2">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Retype New Password</mat-label>
                            <input matInput type="password" formControlName="RetypeNewPassword" required>
                            <mat-error *ngIf="resetPasswordForm.get('RetypeNewPassword').invalid">
                                {{ getRetypeNewPasswordErrorMessage() }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </mat-card-content>
            <mat-card-actions align="center">
                <button mat-button (click)="cancelReset()" [disabled]="isLoading">Cancel</button>
                <button type="submit" mat-raised-button color="primary" (click)="resetPassword()" [disabled]="!resetPasswordForm.valid || isLoading" >Change Password</button>
              </mat-card-actions>
        </mat-card>
    </div>
</div>
