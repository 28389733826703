import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { AlertifyService } from '../alertify/alertify.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = 'https://proxy.gibsonplumbing.com/'; // Based url
  // baseUrl = 'https://localhost:44302/'; // Very sad day :(
  // baseUrl = 'https://proxy.gibsonplumbing.com/API_Apps/dev_jon/SageApi/';

  jwtHelper = new JwtHelperService();
  decodedToken: any;

  holidayTheme: boolean;

   constructor(
     private http: HttpClient,
     private alertify: AlertifyService
   ) { }

   login(model: any) {
     return this.http.post(this.baseUrl + 'jwt', model)
     .pipe(
      map((response: any) => {
        const user = response;
        if (user) {
          localStorage.setItem('token', user);
          this.decodedToken = this.jwtHelper.decodeToken(user);
          // console.log(this.decodedToken);
        }
      })
     );
   }

   register(model: any) {
     return this.http.post(this.baseUrl + 'register', model);
   }

   passwordlessLogin(token: string) {
    const parts = token.split('.');

    if (parts.length !== 3) {
      this.alertify.error('Link is invalid. Please copy all of link address into broswer and try again');
      return;
    }
    const decoded = this.jwtHelper.urlBase64Decode(parts[1]);
    if (!decoded) {
      this.alertify.error('Link is invalid. Please copy all of link address into broswer and try again');
      return;
    }

    localStorage.setItem('token', token);
    this.decodedToken = this.jwtHelper.decodeToken(token);
   }

   loggedIn() {
     const token = localStorage.getItem('token');
     return !this.jwtHelper.isTokenExpired(token);
   }
}
