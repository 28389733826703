import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import QuoteInclusionEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteInclusionEditableInterface';
import QuoteInclusionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteInclusionDtoInterface';
import { QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';

@Component({
  selector: 'app-qinclusion',
  templateUrl: './QuoteInclusionComponent.html',
  styleUrls: ['./QuoteInclusionComponent.css', '../QuotingTheme.scss']
})
export class QuoteInclusionComponent implements OnInit{

  @Input() inclusion: QuoteInclusionEditableInterface;

  constructor(
    public api: SageApiService,
    public screenSize: ScreenSizeService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public qe: QuoteEditableService
  ){
  }

  ngOnInit(): void {
    
  }

  resetControls(){
    this.inclusion.nameControl.setValue(this.inclusion.data.QuoteInclusion_Name);
    this.inclusion.descControl.setValue(this.inclusion.data.QuoteInclusion_Desc);
  }

  nameInput(e: InputEvent){
    const value = (e.target as HTMLInputElement).value;
    if(value.length > 100){
      const newVal = value.slice(0, 100);
      this.inclusion.nameControl.setValue(newVal);
      (e.target as HTMLInputElement).value = newVal;
    }
  }

  isSaveDisabled(){
    const different = 
      this.inclusion.nameControl.value !== this.inclusion.data.QuoteInclusion_Name 
      || this.inclusion.descControl.value !== this.inclusion.data.QuoteInclusion_Desc;

    const valid = 
      this.inclusion.nameControl.valid 
      && this.inclusion.descControl.valid;
    
    return !different || !valid;
  }

  nameBlur(){
    if(this.inclusion.nameControl.value === ''){
      this.inclusion.nameControl.setValue('Untitled');
    }
  }

  saveChanges(){
    this.qe.changeQuoteInclusion({
      quoteInclusion: this.inclusion,
    });
  }

  deleteExclusion(){
    this.qe.destroyQuoteInclusion({quoteInclusion: this.inclusion})
  }

}