<div class="container">
    <mat-drawer-container (backdropClick)="toggleItemEdit(null)">
        <mat-drawer [mode]="modeItemEdit" position="end" [(opened)]="showItemEdit" class="" style=" max-height: 100%;">        
            <!-- <ng-container *ngIf="needPOLineKey()"> -->
                <mat-card class="h-100 mat-elevation-z0" [hidden]="!needPOLineKey()">
                    <mat-card-header>
                        <div class="row w-100">
                            <div class="col-2 text-center p-0">
                                <button mat-icon-button (click)="toggleItemEdit(null)">
                                    <mat-icon> close </mat-icon>
                                </button>
                            </div>
                            <div class="col-8 text-center">
                                <h4>
                                    Pick an Item For this Invoice Line
                                </h4>
                               <h5>{{ itemSelected?.itemCode }}: {{ itemSelected?.itemCodeDesc }}  <br>
                               Invoiced: {{ itemSelected?.newQuantityInvoiced }} Cost: {{ itemSelected?.newUnitCost }}</h5> 
                            </div>
                            <div class="col-2 text-center p-0">
                                <button mat-icon-button (click)="getPurchaseOrderLines()">
                                    <mat-icon> refresh </mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-card-header>
                    <mat-card-content style="height: 60%;">
                        <div *ngIf="poLinesLoading">Fetching PO Items...</div>
                        <!-- <ng-template #SelectPOItem> -->

                            <div class="mat-elevation-z0 mat-table-responsive" style="max-height: 100%; overflow: auto;">
                                <table mat-table [dataSource]="poDataSource" class="w-100" #poSort="matSort" matSort>
        
                                    <!-- Item Column -->
                                    <ng-container matColumnDef="itemCode">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header="ItemCode"> Item </th>
                                        <td mat-cell *matCellDef="let element" class="px-1"> {{ element.ItemCode }} </td>
                                    </ng-container>
                                
                                    <!-- Item Description Column -->
                                    <ng-container matColumnDef="itemCodeDesc">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header="ItemCodeDesc"> Description </th>
                                        <td mat-cell *matCellDef="let element" class="px-1"> {{ element.ItemCodeDesc }} </td>
                                    </ng-container>

                                    <!-- Quantity Ordered Column -->
                                    <ng-container matColumnDef="quantityOrdered">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header="QuantityOrdered"> Ordered </th>
                                        <td mat-cell *matCellDef="let element" class="px-1 text-center"> {{ element.QuantityOrdered }} </td>
                                    </ng-container>

                                    <!-- Quantity Received Column -->
                                    <ng-container matColumnDef="quantityReceived">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header="QuantityReceived"> Received </th>
                                        <td mat-cell *matCellDef="let element" class="px-1 text-center"> {{ element.QuantityReceived }} </td>
                                    </ng-container>
                                
                                    <!-- Quantity Invoiced Column -->
                                    <ng-container matColumnDef="quantityInvoiced">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header="QuantityInvoiced"> Invoiced </th>
                                        <td mat-cell *matCellDef="let element" class="px-1 text-center"> {{ element.QuantityInvoiced }} </td>
                                    </ng-container>
        
                                    <!-- Unit Cost Column -->
                                    <ng-container matColumnDef="unitCost">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header="UnitCost"> Unit Cost </th>
                                        <td mat-cell *matCellDef="let element" class="px-1"> ${{ element.UnitCost }} </td>
                                    </ng-container>
                                
                                    <tr mat-header-row *matHeaderRowDef="poTableDisplayedColumns; sticky: true;"></tr>
                                    <tr mat-row *matRowDef="let row; columns: poTableDisplayedColumns;" [ngClass]="{'font-weight-bold': row == poItemSelected}" (click)="onPoItemSelected(row)"></tr>

                                </table>   
                                <mat-paginator matPaginator #poPaginator="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                            <!-- <mat-selection-list [(ngModel)]="poLinesIndex" [multiple]="false" class="h-100 overflow-auto">
                                <mat-list-option disabled>
                                    <div class="row">
                                        <div class="col-3"> <strong>Item Code</strong> </div>
                                        <div class="col-3"> <strong>Desc</strong> </div>
                                        <div class="col-2"> <strong>Ordered</strong></div>
                                        <div class="col-2"> <strong>Received</strong></div>
                                        <div class="col-2"> <strong>Invoiced</strong></div>
                                        <div class="col-2"> <strong>Cost</strong></div>
                                    </div>
                                </mat-list-option>
                                <mat-list-option *ngFor="let line of poLines; let poIndex = index; let isFirst = first;" [value]="poIndex">
                                    <div class="row">
                                        <div class="col-3"> <h6>{{ line?.ItemCode }}</h6> </div>
                                        <div class="col-3"> <h6>{{ line?.ItemCodeDesc }}</h6> </div>
                                        <div class="col-2">{{ line?.QuantityOrdered }}</div>
                                        <div class="col-2">{{ line?.QuantityReceived }}</div>
                                        <div class="col-2">{{ line?.QuantityInvoiced }}</div>
                                        <div class="col-2">{{ line?.UnitCost }}</div>
                                    </div>
                                </mat-list-option>
                            </mat-selection-list> -->
                        <!-- </ng-template> -->
                    </mat-card-content>
                    <mat-card-actions style="height: 15%;">
                        <div class="row no-gutters">
                            <div class="col-8 offset-1">
                                {{ poLines[poLinesIndex]?.ItemCode || 'No Item' }} Selected
                            </div>
                            <div class="col-2">
                                <button mat-raised-button class="float-right" color="success" (click)="assignPOLine()" [disabled]="!poLinesIndex && poLinesIndex !== 0">
                                    Save
                                </button>
                            </div>
                        </div>
                    </mat-card-actions>
                </mat-card>
            <!-- </ng-container> -->
            <ng-container *ngIf="!needPOLineKey()">
                <mat-card class="mh-100 mat-elevation-z0">
                    <mat-card-header>
                        <div class="row w-100">
                            <div class="col-2 text-center p-0">
                                <button mat-icon-button (click)="toggleItemEdit(null)">
                                    <mat-icon> close </mat-icon>
                                </button>
                            </div>
                            <div class="col-8 text-center">
                                <h4>
                                    Edit {{ itemSelected?.itemCode }}: <br> 
                                    {{ itemSelected?.itemCodeDesc }}
                                </h4>
                            </div>
                            <div class="col-2 text-center p-0">
                                <button mat-icon-button (click)="removeLineKey()">
                                    <mat-icon> mode_edit </mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-card-header>
                    
                    <mat-card-content class="overflow-y-auto">
                        <h5>Item Info</h5>
                        <div class="row no-gutters">
                            <div class="col-3">
                                <mat-form-field appearance="fill" style="width: 100%">
                                    <mat-label>Ordered</mat-label>
                                    <input matInput disabled [(ngModel)]="itemSelected.poQuantityOrdered">
                                </mat-form-field>
                            </div>
                            <div class="col-3">                                
                                <mat-form-field appearance="fill" style="width: 100%">
                                    <mat-label>Received</mat-label>
                                    <input matInput disabled [(ngModel)]="itemSelected.poQuantityReceived">
                                </mat-form-field>
                            </div>
                            <div class="col-3">                                
                                <mat-form-field appearance="fill" style="width: 100%">
                                    <mat-label>Invoiced</mat-label>
                                    <input matInput disabled [(ngModel)]="itemSelected.poQuantityInvoiced">
                                </mat-form-field>
                            </div>
                        </div>

                        <h5>Update Item</h5>
                        <div class="row no-gutters">
                            <div class="col-3 text-center">Invoice</div>
                            <div class="col-3 text-center">PO</div>
                            <div class="col-6 text-center"></div>
                        </div>
                        <div class="row no-gutters mt-2">
                            <div class="col-3 text-center">
                                <mat-form-field appearance="fill" style="width: 100%">
                                    <mat-label>Invoiced</mat-label>
                                    <input matInput disabled [(ngModel)]="itemSelected.quantityInvoiced">
                                </mat-form-field>
                            </div>
                            <div class="col-3 text-center">
                                <mat-form-field appearance="fill" style="width: 100%">
                                    <mat-label>Available</mat-label>
                                    <input matInput disabled [(ngModel)]="itemSelected.poQuantityReceived - itemSelected.poQuantityInvoiced">
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field appearance="fill" style="width: 100%" class="ml-1">
                                    <mat-label>Approved Invoiced Qty</mat-label>
                                    <input type="number" matInput [(ngModel)]="itemSelected.newQuantityInvoiced">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row no-gutters mt-2">
                            <div class="col-3 text-center">
                                <mat-form-field appearance="fill" style="width: 100%">
                                    <mat-label>Cost</mat-label>
                                    <input matInput disabled [(ngModel)]="itemSelected.unitCost">
                                </mat-form-field>
                            </div>
                            <div class="col-3 text-center">
                                <mat-form-field appearance="fill" style="width: 100%">
                                    <mat-label>Cost</mat-label>
                                    <input matInput disabled [(ngModel)]="itemSelected.poUnitCost">
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field appearance="fill" style="width: 100%" class="ml-1">
                                    <mat-label>Approved Unit Cost</mat-label>
                                    <input type="number" matInput [(ngModel)]="itemSelected.newUnitCost">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row no-gutters mt-2">
                            <div class="col-6">
                                <mat-form-field appearance="fill" style="width: 100%">
                                    <mat-label>PO Coment</mat-label>
                                    <textarea matInput placeholder="No Comment Entered" [(ngModel)]="itemSelected.poCommentText" disabled></textarea>
                                  </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field appearance="fill" style="width: 100%" class="ml-1">
                                    <mat-label>Approved Comment</mat-label>
                                    <textarea matInput placeholder="Ex. Enter a comment" [(ngModel)]="itemSelected.newCommentText" maxlength="2048"></textarea>
                                    <mat-hint align="end"> {{ itemSelected.newCommentText.length }} / 2048</mat-hint>
                                  </mat-form-field>
                            </div>
                        </div>

                        <div class="row no-gutters w-100 mt-2">
                            <div class="col-8 offset-1">
                                
                            </div>
                            <div class="col-3">
                                <button mat-raised-button color="success" class="float-right" (click)="updateInvoiceItem()">
                                    Save
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                    
                    <!-- <mat-card-actions>

                    </mat-card-actions> -->
                </mat-card>
            </ng-container>
        </mat-drawer>
        <mat-drawer-content>
            <mat-card style="min-height: 100%;">
                <div class="row no-gutters">
                    <div class="col">
                        <button mat-raised-button class="float-right" (click)="updateInvoice()">
                            Edit Invoice Info <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                </div>
                <mat-card-header>
                    <div class="row pb-2 w-100">
                        <div class="col-sm-6">                        
                            <h4>InvoiceNo: {{ invoice?.invoiceNo | titlecase }}</h4>
                            <h4>PurchaseOrderNo: {{ invoice?.purchaseOrderNo | titlecase }}</h4>
                            <h4>
                                Invoice Date: {{ (invoice?.newInvoiceDate ? invoice?.newInvoiceDate : invoice?.invoiceDate) | date }}
                            </h4>
                            <!-- <h4>
                                  Receipt Date: {{ (invoice?.newReceiptDate ? invoice?.newReceiptDate : invoice?.receiptDate) | date }}
                            </h4> -->
                        </div>
                        <div class="col-sm-6">
                            <h4>Shipping Due: ${{ invoice?.shippingAmountDue || 0 | number : '1.2-2' }}</h4>
                            <h4>Tax Due: ${{ invoice?.newTaxAmount | number : '1.2-2' }} 
                                <mat-icon class="text-primary" *ngIf="calculateTaxDifferenceState() === 'decrease'" style="font-size: 16px; width: 16px; height: 16px;">arrow_drop_up</mat-icon>
                                <mat-icon class="text-success" *ngIf="calculateTaxDifferenceState() === 'increase'" style="font-size: 16px; width: 16px; height: 16px;">arrow_drop_down</mat-icon>
                                <mat-icon *ngIf="calculateTaxDifferenceState() === 'same'" style="font-size: 16px; width: 16px; height: 16px;">remove</mat-icon>
                                 {{ math.abs((invoice?.newTaxAmount - calculateSageSalesTax()))  | number : '1.2-2' }}
                            </h4>
                            <h4>Total Due: ${{ calculateTotalDue() | number : '1.2-2' }}
                                <mat-icon class="text-success" *ngIf="calculateTotalDueDifferenceState() === 'decrease'" style="font-size: 16px; width: 16px; height: 16px;">arrow_drop_down</mat-icon>
                                <mat-icon class="text-primary" *ngIf="calculateTotalDueDifferenceState() === 'increase'" style="font-size: 16px; width: 16px; height: 16px;">arrow_drop_up</mat-icon>
                                <mat-icon *ngIf="calculateTotalDueDifferenceState() === 'same'" style="font-size: 16px; width: 16px; height: 16px;">remove</mat-icon>
                                 {{ math.abs(invoice?.totalAmountDue - calculateTotalDue()) | number : '1.2-2' }}
                            </h4>
                        </div>
                    </div>
                </mat-card-header>
                <mat-card-content>
                    <div class="mat-elevation-z8 mat-table-responsive">
                        <table mat-table #invTable [dataSource]="dataSource" class="w-100" matSort #invSort="matSort" >

                            <!-- LineKey Column -->
                            <ng-container matColumnDef="lineKeySent">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Line # </th>
                                <td mat-cell *matCellDef="let element" class="text-center px-1"> 
                                    {{ element.newLineKey ? element.newLineKey :  element.lineKeySent }}
                                    <mat-error *ngIf="needPOLineKey(element)">Need to Match Item</mat-error> 
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <!-- Item Column -->
                            <ng-container matColumnDef="itemCode">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                                <td mat-cell *matCellDef="let element" class="px-1"> {{ element.itemCode }} </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
                        
                            <!-- Item Description Column -->
                            <ng-container matColumnDef="itemCodeDesc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                                <td mat-cell *matCellDef="let element" class="px-1"> {{ element.itemCodeDesc }} </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
                        
                            <!-- Quantity Invoiced Column -->
                            <ng-container matColumnDef="quantityInvoiced">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="newQuantityInvoiced"> Quantity Invoiced </th>
                                <td mat-cell *matCellDef="let element" class="px-1 text-center" [ngClass]="{'text-success': calculateInvoicedState(element) == 'same'}"> 
                                    {{ element.newQuantityInvoiced }} 
                                    <ng-container *ngIf="!needPOLineKey(element)">
                                        <mat-icon class="text-primary" *ngIf="calculateInvoicedState(element) == 'overinvoiced'" style="font-size: 16px; width: 16px; height: 16px;">arrow_drop_up</mat-icon>
                                        <mat-icon class="text-success" *ngIf="calculateInvoicedState(element) == 'underinvoiced'" style="font-size: 16px; width: 16px; height: 16px;">arrow_drop_down</mat-icon>
                                        <!-- <mat-icon *ngIf="calculateInvoicedState(element) == 'same'" style="font-size: 16px; width: 16px; height: 16px;">check</mat-icon> -->
                                         {{ calculateInvoicedState(element) == 'same' ? '' : calculateInvoicedDifference(element)  | number : '1.0-2' }}
                                    </ng-container>
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <!-- Unit Cost Column -->
                            <ng-container matColumnDef="unitCost">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="newUnitCost"> Unit Cost </th>
                                <td mat-cell *matCellDef="let element" class="px-1" [ngClass]="{'text-success': calculateItemCostDifferenceState(element) === 'same'}"> 
                                    ${{ element.newUnitCost }}
                                    <ng-container *ngIf="!needPOLineKey(element)">
                                        <mat-icon class="text-success" *ngIf="calculateItemCostDifferenceState(element) === 'decrease'" style="font-size: 16px; width: 16px; height: 16px;">arrow_drop_down</mat-icon>
                                        <mat-icon class="text-primary" *ngIf="calculateItemCostDifferenceState(element) === 'increase'" style="font-size: 16px; width: 16px; height: 16px;">arrow_drop_up</mat-icon>
                                        <!-- <mat-icon  *ngIf="calculateItemCostDifferenceState(element) === 'same'" style="font-size: 16px; width: 16px; height: 16px;">check</mat-icon> -->
                                        {{ calculateItemCostDifferenceState(element) === 'same' ? '' : calculateItemCostDifference(element) | number : '1.0-4' }}
                                    </ng-container>
                                </td>
                                <td mat-footer-cell *matFooterCellDef style="border-top: 2px solid darkgray;">Total:</td>
                            </ng-container>

                            <!-- Total Cost Column -->
                            <ng-container matColumnDef="totalCost">
                                <th mat-header-cell *matHeaderCellDef> Total Cost </th>
                                <td mat-cell *matCellDef="let element" class="px-1"> ${{ (element.newUnitCost  * element.newQuantityInvoiced) | number : '1.2-2' }} </td>
                                <td mat-footer-cell *matFooterCellDef style="border-top: 2px solid darkgray;"> ${{ sumTotalCost() | number : '1.2-2' }}</td>
                            </ng-container>

                            <!-- Status Column -->
                            <!-- <ng-container matColumnDef="approvalState">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                <td mat-cell *matCellDef="let element" class="px-1"> {{ element.approvalState }} </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container> -->

                            <!-- Action Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                                <td mat-cell *matCellDef="let element" class="px-1"> 
                                    <button mat-icon-button (click)="toggleItemEdit(element)" >
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
                        
                            <tr mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: true;"></tr>
                            <tr mat-row *matRowDef="let row; columns: tableDisplayedColumns;" [ngClass]="{'font-weight-bold': row == itemSelected}"></tr>
                            <tr mat-footer-row *matFooterRowDef="tableDisplayedColumns; sticky: true;" ></tr>

                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                                <!-- <td *ngIf="filteredValues.status === ''" class="mat-cell" colspan="5">No data matching the filter "{{ input.value }}"</td>
                                <td *ngIf="filteredValues.status !== ''" class="mat-cell" colspan="5">No data matching the filter "{{ input.value }}" and has status of "{{ filteredValues.status | titlecase }}"</td> -->
                            </tr>
                        </table>   
                        <mat-paginator #invPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                    </div>
                </mat-card-content>
                <mat-card-actions align="end">
                    <button mat-raised-button color="danger" (click)="deleteInvoice()">Delete</button>
                    <button mat-raised-button color="secondary" (click)="saveInvoice()">Save</button>
                    <button mat-raised-button color="success" (click)="confirmApproval()" [disabled]="approvalDisabled()">Approve</button>
                </mat-card-actions>
            </mat-card>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
