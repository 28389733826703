import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
  AfterViewInit,
  TemplateRef,
  ViewChild,
  Input,
  Directive,
  HostListener, AfterViewChecked
} from '@angular/core';
import {SageApiService} from '../../../_services/sageApi/sageApi.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../_services/auth/auth.service';
import {AlertifyService} from '../../../_services/alertify/alertify.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {concat} from 'rxjs';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead/typeahead-match.class';
import {WasabiApiService} from '../../../_services/wasabiApi/wasabiApi.service';
import {DatePipe} from '@angular/common';
import {BnNgIdleService} from 'bn-ng-idle';
// tslint:disable-next-line:prefer-const


@Component({
  selector: 'app-qrscanner',
  templateUrl: './qrscanner.component.html',
  styleUrls: ['./qrscanner.component.css']
})
// export class QrscannerComponent implements OnInit, AfterViewInit, AfterViewChecked {
export class QrscannerComponent implements OnInit, AfterViewInit {

  // inputs for Autofocus
  @ViewChild('unkownIC', {static: true}) unkownIC: ElementRef;

  @ViewChildren('input') inputs: QueryList<ElementRef>;

  // Open modal
  @ViewChild('unknownCode') unknownCode: TemplateRef<any>;

  // @ViewChild('contentPlaceholder') set content(content:  )
  // @Input() id : string;
  // contentPlaceholder: MyCustomComponent;

  isFocus = false;
  modalTemplate: BsModalRef;
  itemslist: string[];
  itemCollection = [];
  addressList = [];
  itemCodeList = [];
  itemList = [];
  tempAliasList = [];
  inputlist = ['workticket'];
  itemListSubmitted = [];
  AliasList = [];
  filtereditems = [];
  ICList = [];
  isLoading: boolean;
  addressName = '';
  WTScanner = '';
  ScannedUnknown = '';
  wantedICode = '';
  phases = [];
  phaseFilter = ['ZROUGHIN', 'ZSEWER', 'ZWATER', 'ZVANGUARD', 'ZTOPOUT', 'ZGAS', 'ZTRIM', 'ZDROPIN', 'ZPUNCH'];
  addressLoaded: boolean;
  phaseLoaded: boolean;
  selectedPhaseName: string;
  workticket: string;
  soNum: string;
  wtNum: string;
  datePipe = new DatePipe('en-US');
  isSubmitting: boolean;
  restrictUser: boolean;
  pauseTimeout: boolean;
  lastUpdate: number;
  dupFailFix: boolean;
  dupFailAlias = '';

  importTemplateInfo = {
    templateName: '',
    template: '',
    step: ''
  };


  constructor(
    private router: Router,
    private authService: AuthService,
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private wasabiApi: WasabiApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private bnIdle: BnNgIdleService,
    // private contentPlaceholder: MyCustomComponent
  ) {
  }

  // @HostListener('onOpenModal', ['$event']) public onListenerTriggered(event: any): void {
  //   // this.changeDetectorRef.detectChanges();
  //   this.unkownIC.nativeElement.focus();
  // }

  ngOnInit() {
    this.isSubmitting = false;
    this.restrictUser = false;
    this.pauseTimeout = false;
    this.itemCollection = [];
    this.itemListSubmitted = [];
    this.selectedPhaseName = '';
    this.soNum = '';
    this.wtNum = '';
    this.workticket = '';
    this.addressLoaded = false;
    this.isLoading = false;
    this.dupFailFix = false;
    this.lastUpdate = 0;

    if (localStorage.getItem('token') === null) {
      const model = {
        username: 'gguest',
        password: 'GPCrew2021'
      };
      this.isLoading = true;
      this.authService.login(model).subscribe(next => {
        // this.alertify.success('Logged in successfully');
      }, error => {
        console.log(error);
        this.isLoading = false;
        this.alertify.error('Log in Error');
      }, () => {
        this.itemList = [];
        this.inputlist = ['workticket'];
        this.restrictUser = true;
        this.isLoading = false;
        this.loadAddressList();
        this.createfilter();
        // this.pullSageItemList();
      });
    } else {
      if (this.authService.decodedToken.nameid === 'gguest') {
        this.restrictUser = true;
      }
      this.loadAddressList();
      this.createfilter();
      // this.pullSageItemList();

      this.changeDetectorRef.detectChanges();
      this.inputs.last.nativeElement.focus();
      let count = 0;
      this.bnIdle.startWatching(120).subscribe((isTimedOut: boolean) => {
        // count++;
        // console.log('Time Trigger');
        // this.pauseTimeout = !(count === 2 && this.pauseTimeout);
        // if (count === 2 && this.pauseTimeout){
        //   this.closeModal();
        //   console.log('system reset');
        // }
        if (isTimedOut && !this.pauseTimeout){
          count = 0;
          console.log('Timed Out');
          if (this.workticket !== '' && this.itemList !== []){
            // console.log('Auto-Submitting scanned data');
            this.alertify.warning('User Inactive Auto-Submitting to Work Ticket');
            this.lastUpdate++;
            this.updateMaterial(this.workticket);
          }
        }
      });
    }
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(){
    this.changeDetectorRef.detectChanges();
    this.inputs.last.nativeElement.focus();
    console.log(this.unkownIC);
  }

  // ngAfterViewChecked() {
  //   if (this.isFocus && this.unkownIC.nativeElement){
  //     this.unkownIC.nativeElement.focus();
  //     this.isFocus = false;
  //   }
  // }

  loadAddressList() {
    this.isLoading = true;
    this.addressList = [];
    this.sageApi.pullReport('AssignSchedule/Addresses').subscribe((rows: Array<any>) => {
      if (Array.isArray(rows)) {
        rows.forEach(row => {
          const address = {
            // name: row.Address + ' - ' + row.SalesOrderNo + ' - SUB: ' + row.UDF_SUBDIVISION,
            name: row.Address + ' - ' + row.SalesOrderNo,
            address: row.Address,
            salesOrderNo: row.SalesOrderNo,
            subdivision: row.UDF_SUBDIVISION
          };

          this.addressList.push(address);
        });
      }
    }, err => {
      this.alertify.error(err.message);
      console.log(err);
      this.isLoading = false;
    }, () => {
      // console.log(this.addressList);
      this.isLoading = false;
    });
  }

  addressSelected(event: TypeaheadMatch) {
    this.addressLoaded = true;
    this.phases = [];
    this.selectedPhaseName = '';
    this.phaseLoaded = false;
    // this.wtLoaded = false;
    this.loadPhases(event.item.salesOrderNo);
  }

  loadPhases(salesOrderNo: string) {
    this.sageApi.pullReport('QAWorkticket?salesOrderNo=' + salesOrderNo).subscribe((rows: Array<any>) => {
      if (Array.isArray(rows)) {
        rows.forEach(row => {
          const phase = {
            address: row.ShipToName,
            salesOrderNo: row.SalesOrderNo,
            WTNum: row.WTNumber,
            WTParentPhase: row.HdrParentItemCode,
            phaseName: '(' + row.WTNumber + ') ' + (row.HdrParentItemCode !== undefined ? row.HdrParentItemCode.substring(1) : 'undefined'),
            selected: false
          };
          // if (!this.phases.some(e => (e.salesOrderNo === phase.salesOrderNo && e.WTParentPhase === phase.WTParentPhase && e.WTNum === phase.WTNum))
          //   && this.phaseFilter.includes(phase.WTParentPhase)) {
          this.phases.push(phase);
          // }
        });
      }
    }, err => {
      this.alertify.error(err.message);
      console.log(err);
    }, () => {
      console.log(this.phases);
    });
  }

  phaseSelected(event: TypeaheadMatch) {
    this.phaseLoaded = true;
    this.soNum = event.item.salesOrderNo;
    this.wtNum = event.item.WTNum;
    this.pullMaterial();
  }

  openModal(template: TemplateRef<any>) {
    this.pauseTimeout = true;
    // console.log(this.houseInfo, this.houseLocationList);
    this.importTemplateInfo = {
      templateName: '',
      template: '',
      step: ''
    };

    // const modalRef = this.modalService.open
    //
    // // tslint:disable-next-line:no-unused-expression
    // @ViewChild('unkownIC') this.unkownIC: ElementRef;

    try {
      this.modalTemplate = this.modalService.show(template);
    }
    finally {
      this.sleep(2000).then(r => {
        // this.changeDetectorRef.detectChanges();
        console.log(this.unkownIC);
      });
      // this.unkownIC.nativeElement.focus();
      this.isFocus = true;
      setTimeout(() => {
        // this.unkownIC.nativeElement.focus();
      });
    }
    // this.changeDetectorRef.detectChanges();

  }

  sleep(ms){
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  closeModal(cancel = false) {
    if (cancel){
      const len = this.itemList.findIndex(inv => inv.name === this.ScannedUnknown);
      if (len !== -1){
        this.itemList[len].name = '';
        if (this.itemList[len].newQtyShippedTotal > 0){
          this.itemList[len].newQtyShippedTotal = 0;
          this.itemList[len].newQtyOrderedTotal = 0;
        }
      }
    }
    this.pauseTimeout = false;
    this.isFocus = false;
    this.modalTemplate.hide();
    this.changeDetectorRef.detectChanges();
    this.inputs.last.nativeElement.focus();
  }

  createTempAlias(){
    const index = this.ICList.findIndex(item => item.ItemCode.toLocaleLowerCase() === this.wantedICode.toLowerCase());
    console.log(index);
    if (index === -1){
      this.alertify.error('Error: Item Code not found. Please try again.');
      this.wantedICode = '';
      return;
    }else {
      const obj = {
        Alias: this.ScannedUnknown,
        ItemCode: this.ICList[index].ItemCode,
        Description: this.ICList[index].Description
      };

      this.tempAliasList.push(obj);
      const listIndex = this.itemList.length - 1;
      console.log('listIndex', listIndex);
      this.dupFailFix = listIndex === 0;
      this.dupFailAlias = this.dupFailFix ? this.ScannedUnknown : '';
      this.closeModal();
      this.filtereditems.push(obj);
      this.itemSelectedNew(obj.Alias, listIndex, this.workticket);
      this.wantedICode = '';
    }
  }

  itemSelectedNew(itemcode: string, index: any, workticket: any) {
    const partIndex = this.filtereditems.findIndex(item => item.Alias.toLocaleLowerCase() === itemcode.toLocaleLowerCase());
    let itemBuild = {
      Alias: '',
      ItemCode: '',
      Description: ''
    };
    if (partIndex === -1){
      // this.alertify.error('Error Item' + itemcode + ' Not Found');
      // this.modalService.open();
      this.ScannedUnknown = itemcode;
      this.openModal(this.unknownCode);
      console.log('item not found...', itemcode, partIndex);
      return;
    }else {
      itemBuild = this.filtereditems[partIndex];
      this.itemList[index].name = itemBuild.ItemCode;
      console.log('name changed to itemcode', this.itemList[index].name);
    }

    // const item = this.filtereditems[partIndex];

    if (itemcode === '' || itemcode === null) {
      return;
    }
    let dupCount = 0;
    let firstDupIndex = 0;
    let ind = 0;
    this.itemList.forEach(tok => {
      if (tok.name === itemBuild.ItemCode) {
        dupCount++;
        if (dupCount === 1) {
          firstDupIndex = ind;
        }
      }
      ind++;
    });
    // console.log('count?', this.itemList);
    // console.log(dupCount);
    // if (dupCount >= 2 || (dupCount >= 1 && this.dupFailFix)) {
    if (dupCount >= 2) {
      console.log('Duplicate');
      this.itemList[firstDupIndex].newQtyShippedTotal++;
      this.itemList[index].name = '';
      // this.dupFailFix = this.dupFailAlias === itemBuild.Alias;
      return;
    }
    const BigObj = {
      name: '',
      ItemDesc: '',
      newQtyOrderedTotal: 0,
      newQtyShippedTotal: 0,
      tickets: []
    };
    const hasTick = false;
    BigObj.name = itemBuild.ItemCode;
    BigObj.ItemDesc = itemBuild.Description;
    if (!hasTick) {
      BigObj.newQtyShippedTotal = 1;
      BigObj.newQtyOrderedTotal = 1;
      const obj = {
        name: itemBuild.ItemCode,
        lineKey: 'none',
        newItem: 'Y',
        newQtyOrdered: 1,
        newQtyShipped: 1,
      };
      BigObj.tickets.push(obj);
    }
    // console.log(BigObj);
    this.itemList[index] = BigObj;
    console.log(this.itemList);
    this.AddBackorderList();
  }

  AddAliases(){
    this.isLoading = true;
    const observables = [];
    const submitAlias = [];
    this.tempAliasList.forEach(item => {
      const obj = [item.Alias, item.ItemCode, item.Description];
      submitAlias.push(obj);
    });


    const object = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      aliasList: submitAlias
    };
    const endpoint = 'addAlias';
    console.log(object);
    observables.push(this.sageApi.putRequest(endpoint, object));
    this.alertify.warning(`Adding New Aliases`);
    concat(...observables).subscribe((response: any) => {
      console.log('response', response);
    }, err => {
      this.alertify.error('Error Submitting New Aliases to Sage: ' + err.error.Message);
      this.isLoading = false;
    }, () => {
      this.alertify.success('Successfully added Aliases to SAGE.');
      this.createfilter();
      this.tempAliasList = [];
      this.isLoading = false;
    });


  }

  // itemSelected(itemcode: string, workticket: any) {
  //   // console.log(workticket);
  //   if (this.itemList === [] || this.itemList.includes(tok => tok.name.toLocaleLowerCase() === itemcode.toLocaleLowerCase())) {
  //     console.log('Duplicate');
  //     return;
  //   }
  //   const BigObj = {
  //     name: '',
  //     newQtyOrderedTotal: 0,
  //     newQtyShippedTotal: 0,
  //     tickets: []
  //   };
  //   BigObj.name = itemcode;
  //   // this.itemList[index].name = itemcode;
  //   // console.log(this.itemCollection[workticket]);
  //   this.itemCollection[workticket].forEach(token => {
  //     if (token.ItemCode.toLocaleLowerCase() === BigObj.name.toLocaleLowerCase()) {
  //       // console.log(token);
  //       if (isNaN(BigObj.newQtyOrderedTotal)) {
  //         BigObj.newQtyOrderedTotal = token.QuantityOrdered;
  //       } else {
  //         BigObj.newQtyOrderedTotal += token.QuantityOrdered;
  //       }
  //       if (isNaN(BigObj.newQtyShippedTotal)) {
  //         BigObj.newQtyShippedTotal = token.newQtyShipped;
  //       } else {
  //         BigObj.newQtyShippedTotal += token.newQtyShipped;
  //       }
  //       const obj = {
  //         name: token.ItemCode,
  //         lineKey: token.LineKey,
  //         newQtyOrdered: token.newQtyOrdered,
  //         newQtyShipped: token.newQtyShipped,
  //       };
  //       BigObj.tickets.push(obj);
  //     }
  //   });
  //   // console.log(BigObj);
  //   this.itemList.push(BigObj);
  // }

  async pullSageItemList() {
    this.ICList = [];
    this.isLoading = true;
    this.sageApi.pullReport('ItemMaterial').subscribe((items: Array<any>) => {
      if (Array.isArray(items)) {
        items.forEach(item => {
          this.ICList.push(item);
        });
        console.log('ICList', this.ICList);
        this.ICList.forEach(it => {
          const obj = {
            Alias: it.ItemCode,
            ItemCode: it.ItemCode,
            Description: it.Description
          };
          this.filtereditems.push(obj);
        });
      }
    }, err => {
      console.log(err);
      this.isLoading = false;
    }, () => {
      this.isLoading = true;
      this.AliasList = [];
      this.sageApi.pullReport('QRCode/GetAlias').subscribe((rows: Array<any>) => {
        if (Array.isArray(rows)) {
          console.log('Alias Rows', rows);
          rows.forEach( row => {
            const index = this.ICList.findIndex(token => token.ItemCode === row.ItemCode);
            // console.log(index);
            const obj = {
              Alias: row.AliasItemNo,
              ItemCode: row.ItemCode,
              Description: index === -1 ? '' : this.filtereditems[index].Description
            };
            this.AliasList.push(obj);
            this.filtereditems.push(obj);
          });
          console.log('Alias List', this.AliasList);
        }
      }, err => {
        console.log(err);
        this.isLoading = false;
      }, () => {
        console.log('filtered Items', this.filtereditems);
        this.isLoading = false;
      });
    });
  }

  async loadAliasList(){
    this.isLoading = true;
    this.AliasList = [];
    this.sageApi.pullReport('QRCode/GetAlias').subscribe((rows: Array<any>) => {
      if (Array.isArray(rows)) {
        rows.forEach( row => {
          const obj = {
            Alias: row.AliasItemNo,
            ItemCode: row.ItemCode,
          };
          this.AliasList.push(obj);
        });
        console.log('Alias List', this.AliasList);
        this.AliasList.forEach(em => {
          // const index = this.filtereditems.findIndex(token => { token.Alias = em.Alias; });
          // if (index === -1){
          //
          // }
          const index = this.filtereditems.findIndex(token => { token.ItemCode = em.ItemCode; });
          const obj = {
            Alias: em.Alias,
            ItemCode: em.ItemCode,
            Description: index === -1 ? '' : this.filtereditems[index].Description
          };
          this.filtereditems.push(obj);
        });
      }
    }, err => {
      console.log(err);
      this.isLoading = false;
    }, () => {
      // return this.AliasList;
      this.isLoading = false;
      console.log('filtered Items', this.filtereditems);
    });
  }

  createfilter() {
    this.filtereditems = [];
    this.pullSageItemList().finally( () => {
      // this.loadAliasList().finally(() => {
        // console.log('CI', this.ICList);
        // console.log('Alias', this.AliasList);
        // this.ICList.forEach(it => {
        //   const obj = {
        //     Alias: it.ItemCode,
        //     ItemCode: it.ItemCode,
        //     Description: it.Description
        //   };
        //   this.filtereditems.push(obj);
        // });

        // this.AliasList.forEach(em => {
        //   // const index = this.filtereditems.findIndex(token => { token.Alias = em.Alias; });
        //   // if (index === -1){
        //   //
        //   // }
        //   const index = this.filtereditems.findIndex(token => { token.ItemCode = em.ItemCode; });
        //   const obj = {
        //     Alias: em.Alias,
        //     ItemCode: em.ItemCode,
        //     Description: index === -1 ? '' : this.filtereditems[index].Description
        //   };
        //   this.filtereditems.push(obj);
        // });
        // console.log('filtered Items', this.filtereditems);
        // });
      });
  }

  pullMaterial() {
    if (this.isLoading){
      this.alertify.warning('Error: Page still loading. Please try again...');
      this.WTScanner = '';
      return;
    }
    this.tempAliasList = [];
    this.isLoading = true;
    // search bars
    if (this.addressName !== '' && this.selectedPhaseName !== ''){
      this.workticket = this.soNum + this.wtNum;
      // this.isLoading = false;
    }
    // Scanner bar
    else {
      // 0123456-010-010
      console.log(this.WTScanner);
      if (this.WTScanner.length < 10){
        this.alertify.error('Error: Invalid WT Number Scan.');
        this.isLoading = false;
        this.WTScanner = '';
        return;
      }
      // Bar code Scan
      if (this.WTScanner.length <= 15){
        const WTSplit = this.WTScanner.split('-');
        if (WTSplit.length < 2){
          if (WTSplit[0].length >= 10){
            this.soNum = WTSplit[0].substring(0, 7);
            this.wtNum = WTSplit[0].substring(7, 10);
            this.workticket = this.soNum + this.wtNum;
            this.WTScanner = '';
            if (isNaN(Number(this.soNum)) || isNaN(Number(this.wtNum))){
              this.alertify.error('Error: Invalid WT Number Scan.');
              this.WTScanner = '';
              this.workticket = '';
              this.isLoading = false;
              return;
            }
          }
          else {
            this.alertify.error('Error: Invalid WT Number Scan.');
            this.WTScanner = '';
            this.isLoading = false;
            return;
          }
        }
        else {
          if (WTSplit[0].length !== 7 || WTSplit[1].length !== 3){
            this.alertify.error('Error: Invalid WT Number Scan.');
            this.WTScanner = '';
            this.isLoading = false;
            return;
          }else{
            this.soNum = WTSplit[0];
            this.wtNum = WTSplit[1];
            this.workticket = this.soNum + this.wtNum;
            this.WTScanner = '';
            if (isNaN(Number(this.soNum)) || isNaN(Number(this.wtNum))){
              this.alertify.error('Error: Invalid WT Number Scan.');
              this.WTScanner = '';
              this.workticket = '';
              this.isLoading = false;
              return;
            }
          }
        }
      }
      // QR code Scan
      else {
        const TicketNum = this.WTScanner.substring(this.WTScanner.length - 10);
        this.soNum = TicketNum.substring(0, 7);
        this.wtNum = TicketNum.substring(7, 10);
        this.workticket = this.soNum + this.wtNum;
        this.WTScanner = '';
        if (isNaN(Number(this.soNum)) || isNaN(Number(this.wtNum))){
          this.alertify.error('Error: Invalid WT Number Scan.');
          this.WTScanner = '';
          this.workticket = '';
          this.isLoading = false;
          return;
        }
      }
    }

    console.log(this.workticket);
    console.log(this.soNum);
    console.log(this.wtNum);
    if (this.itemCollection.hasOwnProperty(this.workticket)) {
      this.itemCollection[this.workticket] = [];
    }
    this.sageApi.pullReport(`UpdMatUsage?soNum=${this.soNum}&wtNum=${this.wtNum}`).subscribe((items: Array<any>) => {
      if (Array.isArray(items)) {
        items.forEach(item => {
          item.newQtyOrdered = item.QuantityOrdered;
          item.newQtyShipped = item.QuantityShipped;
          item.newCommentText = item.CommentText;
          item.loading = false;
          item.updated = false;
          item.edited = false;
          item.added = false;

          if (!this.itemCollection.hasOwnProperty(this.workticket)) {
            this.itemCollection[this.workticket] = [item];
          } else {
            this.itemCollection[this.workticket].push(item);
          }
        });
        console.log(this.itemCollection);
        this.itemList = [];
        this.inputlist = ['workticket'];
      }
    }, err => {
      this.isLoading = false;
      this.alertify.error('Error loading ' + this.workticket + ': ' + err.error.Message);
      console.log(err);
    }, () => {
      this.isLoading = false;
      if (this.itemCollection === [] || this.itemCollection[this.workticket] === undefined){
        this.alertify.error('Error: Invalid WT Number Scan.');
        this.soNum = '';
        this.wtNum = '';
        this.workticket = '';
        this.WTScanner = '';
        return;
      }
      this.phaseLoaded = true;
      this.AddBackorderList();
      // this.createfilter();
      // this.shipAllItems();
      // this.wtNumber = '';
    });

  }

  callScan() {
  }

  AddBackorderList() {
    const obj = {
      name: '',
      ItemDesc: '',
      // lineKey: '000000',
      newQtyOrderedTotal: 0,
      newQtyShippedTotal: 0,
      tickets: []
    };
    this.itemList.push(obj);
    this.inputlist.push('item');
    console.log(this.itemList);
    // Autofocus last input element
    this.changeDetectorRef.detectChanges();
    this.inputs.last.nativeElement.focus();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  // ngAfterViewChecked(){
  //   this.inputs.last.nativeElement.focus();
  // }

  minQtyOrdered(tickets: any) {
    const retItem = tickets;
    return retItem.reduce((min, p) => p.newQtyOrdered < min ? p.newQtyOrdered : retItem[0].newQtyOrdered);
  }

  lineEdited(index: number) {
    if (this.itemList[index].newQtyOrderedTotal < 0) {
      this.alertify.error('Error: quantity ordered for ' + this.itemList[index].name + ' cannot be below 0.');
      this.itemList[index].newQtyOrderedTotal = 0;
      return;
    }

    let totalOrdered = 0;
    this.itemList[index].tickets.forEach(tick => {
      totalOrdered += tick.newQtyOrdered;
    });
    if (this.itemList[index].newQtyOrderedTotal > totalOrdered) {

      const toAdd = this.itemList[index].newQtyOrderedTotal - totalOrdered;
      const minQty = this.minQtyOrdered(this.itemList[index].tickets);
      console.log(minQty);
      console.log(this.itemList[index]);
      let tickIndex = this.itemList[index].tickets.findIndex(item => {
        const num = item.newQtyOrdered;
        console.log(item.newQtyOrdered);
        return num === minQty;
        // item.newQtyOrdered.includes(minQty.toString());
      });
      console.log(tickIndex);
      if (tickIndex === -1 && (this.itemList[index].tickets !== [] || this.itemList[index].tickets !== undefined)) {
        tickIndex = 0;
      }

      if (this.itemList[index].tickets[tickIndex] !== undefined) {
        this.itemList[index].tickets[tickIndex].newQtyOrdered += toAdd;
      } else {
        this.itemList[index].tickets[tickIndex].newQtyOrdered = 0 + toAdd;
      }

    } else if (this.itemList[index].newQtyOrderedTotal < totalOrdered) {

      const tickLength = this.itemList[index].tickets.length;
      let qtyToRemove = totalOrdered - this.itemList[index].newQtyOrderedTotal;

      for (let i = tickLength - 1; i >= 0; i--) {
        if (this.itemList[index].tickets[i].newQtyOrdered !== 0 || qtyToRemove !== 0) {
          if (this.itemList[index].tickets[i].newQtyOrdered < qtyToRemove) {
            qtyToRemove = qtyToRemove - this.itemList[index].tickets[i].newQtyOrdered;
            this.itemList[index].tickets[i].newQtyOrdered = 0;
          } else {
            this.itemList[index].tickets[i].newQtyOrdered = this.itemList[index].tickets[i].newQtyOrdered - qtyToRemove;
            qtyToRemove = 0;
          }
        }
      }

      this.itemList[index].tickets.forEach(tick => {
        if (tick.newQtyShipped > tick.newQtyOrdered) {
          tick.newQtyShipped = tick.newQtyOrdered;
        }
      });
    }
    console.log(this.itemList[index]);
  }

  itemListMath() {
    this.itemList.forEach(tick => {
      let total = tick.newQtyShippedTotal;
      let oldOrderTotal = 0;
      tick.tickets.forEach(tok => {
        oldOrderTotal += tok.newQtyOrdered;
      });
      tick.tickets.forEach(single => {
        if (single.newQtyOrdered >= total) {
          single.newQtyShipped = total;
          total = 0;
        } else {
          single.newQtyShipped = single.newQtyOrdered;
          total = total - single.newQtyOrdered;
          // console.log(total);
        }
      });
      if (total > 0) {
        tick.tickets[tick.tickets.length - 1].newQtyOrdered += total;
        tick.tickets[tick.tickets.length - 1].newQtyShipped += total;
      }
      console.log(tick);
    });
  }

  updateMaterial(wtNumber: string, submit = false) {
    if ((this.itemList === [] || (this.itemList.length <= 1 && this.itemList[0].name === '')) && submit){
      this.alertify.warning('Error No Items to add');
      return;
    }
    let failedCheck = false;
    this.itemList.forEach(token => {
      console.log(token.name);
      if (token.newQtyShippedTotal > 0){
        const ind = this.ICList.findIndex(che => che.ItemCode === token.name);
        if (ind <= -1){
          this.alertify.error('Error: ItemCode ' + token.name + ' does not exist. Confirm list and try again...');
          failedCheck = true;
        }
      }
    });
    if (failedCheck){
      return;
    }
    this.isSubmitting = true;

    this.itemCollection[wtNumber][0].updated = true;
    this.itemCollection[wtNumber][0].loading = true;
    // console.log(this.itemCollection[wtNumber]);
    const today = new Date();
    const todayDate = this.datePipe.transform(today, 'yyyy-MM-dd');

    // const soNum = wtNumber.split('-')[0];
    // const soNum = wtNumber.substring(0, 7);
    const soNum = this.soNum;
    // const wtNum = wtNumber.split('-')[1];
    // const wtNum = wtNumber.substring(7);
    const wtNum = this.wtNum;

    const observables = [];
    const editedLinesArr = [];
    const addedLinesArr = [];
    // const endpoint = 'CounterMaterial';
    const endpoint = 'UpdMatUsageSubmit/App';

    this.itemList.forEach(item => {
      if (item.name !== '' && item.newQtyShippedTotal !== 0){
        const arr = [
          item.name,
          item.newQtyShippedTotal < 0 ? 0 : item.newQtyShippedTotal, // new quantity Ordered
          item.newQtyShippedTotal < 0 ? 0 : item.newQtyShippedTotal, // new quantity Shipped
          wtNum,
          this.itemCollection[wtNumber][0].JT158_WTParentLineKey,
          // 'Counter Scanner added - ' + todayDate + ' - ' + this.authService.decodedToken.nameid,
          this.authService.decodedToken.nameid,
          '0',
        ];
        addedLinesArr.push(arr);
      }
    });

    if (!submit){
      if (addedLinesArr.length <= 0){
        if (this.lastUpdate === 1){
          this.alertify.warning('No Items to update...');
          console.log('No Items to update...');
          this.lastUpdate++;
        }
        else if (this.lastUpdate > 1){
          console.log('Extended inactivity. Leaving Workticket...');
          this.alertify.warning('Extended inactivity. Leaving Workticket...');
          this.addressName = '';
          this.selectedPhaseName = '';
          this.workticket = '';
          this.itemList = [];
          this.inputlist = ['workticket'];
          this.itemCollection = [];
          this.lastUpdate = 0;
          this.phaseLoaded = false;
          this.itemListSubmitted = [];
        }
        else {
          this.alertify.error('Error No Items to add');
        }
        this.isSubmitting = false;
        return;
      }
    }else {
      if (addedLinesArr.length <= 0){
        this.alertify.error('Error No Items to add');
        this.isSubmitting = false;
        return;
      }
    }

    const object = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      salesOrderNo: soNum,
      SystemOperation: 'QR Scanner',
      Status: 'none',
      wtNum,
      editedLines: editedLinesArr,
      addedLines: addedLinesArr
    };
    console.log(object);
    observables.push(this.sageApi.putRequest(endpoint, object));
    // this.alertify.warning(`Updating shipped materials`);
    concat(...observables).subscribe((response: any) => {
      console.log('response', response);
      // if (response.Response.ReasonPhrase !== '') {
        // this.alertify.message('Successful with the following message ' + response.Response.ReasonPhrase, 0);
        // this.partsfailmessage = response.Response.ReasonPhrase.split(',');
      // } else {
       // this.alertify.success('Success');
      // }
    }, err => {
      this.itemCollection[wtNumber][0].loading = false;
      this.alertify.error('Error on ' + wtNumber + ': ' + err.error.Message);
      // this.alertify.error(err.error.text);
      this.isSubmitting = false;
    }, () => {
      // this.resetTicket(wtNumber);
      this.AddAliases();
      if ((this.lastUpdate === 1 || (this.itemList.length > 1 && this.itemList[0].name !== '')) && !submit){
        console.log('clean partial');
        this.itemList.forEach(item => {
          if (item.name !== ''){
            this.itemListSubmitted.push(item);
          }
        });
        // this.itemListSubmitted.push(this.itemList);
        console.log(this.itemListSubmitted);
        this.itemList = [];
        this.AddBackorderList();
        this.inputlist = ['workticket'];
        // this.phaseLoaded = false;
        // this.lastUpdate = false;
      }
      else {
        console.log('clean full');
        this.addressName = '';
        this.selectedPhaseName = '';
        this.workticket = '';
        this.itemList = [];
        this.inputlist = ['workticket'];
        this.phaseLoaded = false;
        this.itemCollection = [];
        this.itemListSubmitted = [];
        this.lastUpdate = 0;
      }
      this.alertify.success('Material Updated on ' + wtNumber);
      this.isSubmitting = false;
      // this.router.navigate(['/']);
      // this.changeToLW();
    });
  }


}
