<div class="container pb-5">
  <div class="card my-5 shadow-lg">
    <div class="card-body">
      <div class="row" id="filters">
        <div class="col-sm-3">
          <div class="card m-3">
            <form>
              <div class="form-group m-0">
                <input type="text" class="form-control" name="address" placeholder="Type Address..." [(ngModel)]="addressSearch">
              </div>
            </form>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card m-3">
            <div class="btn-group" dropdown [insideClick]="true">
              <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle">
                Select Statuses <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li role="menuitem" *ngFor="let status of statusList; let statusIndex = index"><a class="dropdown-item" (click)="onStatusSelected(status.name, statusIndex)">{{ status.name | titlecase }}<i *ngIf="status.selected" class="fas fa-check fa-border fa-pull-right"></i></a></li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem"><a class="dropdown-item" (click)="onStatusSelected('', 0)">Select None</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card m-3">
            <div class="btn-group" dropdown [insideClick]="true">
              <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle">
                Select Phases <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li role="menuitem" *ngFor="let phase of phaseList; let phaseIndex = index"><a class="dropdown-item" (click)="onPhaseSelected(phase.name, phaseIndex)">{{ phase.name | titlecase }}<i *ngIf="phase.selected" class="fas fa-check fa-border fa-pull-right"></i></a></li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem"><a class="dropdown-item" (click)="onPhaseSelected('', 0)">Select None</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card border-0 my-3 mx-0">
            <div class="form-group m-0">
              <button type="button" class="btn btn-primary float-right" (click)="openModal(editLeadTime)"><i class="fas fa-calendar"></i> Lead Times</button>
            </div>
          </div>
        </div>
      </div>

      <div class="" id="tickets">
        <ul class="list-group">
          <ng-container *ngFor="let ticket of scheduleConfirmations.tickets; let ticketIndex = index; let isFirst = first;">
            <ul class="list-group list-group-horizontal" *ngIf="isFirst">
              <li class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary" (click)="sortTickets('salesorderno')"> # </li>
              <li class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-light bg-primary" (click)="sortTickets('address')"> Address </li>
              <li class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary" (click)="sortTickets('phase')"> Phase </li>
              <li class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary" (click)="sortTickets('scheduleDate')"> Schedule Date </li>
              <li class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary" (click)="sortTickets('status')"> Status </li>
              <li class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary" (click)="sortTickets('status')"> Updated Date </li>
            </ul>
            
              <ul class="list-group list-group-horizontal" [ngStyle]="displayTicket(ticketIndex) ? {'display' : ''} : {'display' : 'none'}">
                <li class="list-group-item py-2 col-2 col-md-2 col-lg-2 text-center" [ngClass]="{'list-group-item-dark': ticketIndex % 2 !== 0}"> {{ ticket.salesorderno }}-{{ ticket.wtnumber }} </li>
                <li class="list-group-item py-2 col-2 col-md-2 col-lg-2" [ngClass]="{'list-group-item-dark': ticketIndex % 2 !== 0}"> {{ ticket.address }} </li>
                <li class="list-group-item py-2 col-2 col-md-2 col-lg-2 text-center" [ngClass]="{'list-group-item-dark': ticketIndex % 2 !== 0}"> {{ ticket.phase | titlecase }} </li>
                <li class="list-group-item py-2 col-2 col-md-2 col-lg-2 text-center" [ngClass]="{'list-group-item-dark': ticketIndex % 2 !== 0}"> {{ ticket.scheduleDate | date }} </li>
                <li class="list-group-item py-2 col-2 col-md-2 col-lg-2 text-center text-capitalize" [ngClass]="{'list-group-item-dark': ticketIndex % 2 !== 0}">
                  <div>
                    <span class="badge badge-pill" style="cursor: not-allowed; pointer-events: none;" [ngClass]="{'badge-primary': ticket.status === 'reschedule', 'badge-success': ticket.status === 'ready'}">
                      {{ ticket.status }} 
                    </span>
                    <a class="btn" (click)="showTicketNotes(ticketIndex)" *ngIf="ticket.notes !== ''">
                      <i class="fas fa-envelope-open-text"></i>
                    </a>
                  </div>

                  <span *ngIf="ticket.status === 'reschedule' && ticket.rescheduleDate > '1754-01-01'">
                    <br> for {{ ticket.rescheduleDate | date }}
                  </span>
                </li>
                <li class="list-group-item py-2 col-2 col-md-2 col-lg-2 text-center" [ngClass]="{'list-group-item-dark': ticketIndex % 2 !== 0}"> {{ ticket.statusDate | date }} {{ ticket.statusTime }} </li>
              </ul>

              <ul class="list-group list-group-horizontal" *ngIf="ticket.showNotes">
                <li class="list-group-item col-12 py-1">
                  {{ ticket.notes }}
                </li>
              </ul>

          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- Edit Lead Time -->
<ng-template #editLeadTime>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>Edit Lead Times</h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <form>
      <div class="form-row col-12 mb-2" *ngFor="let leadTime of phaseLeadTimes">
        <div class="col-4">
          <h3> {{ leadTime.name | titlecase }} </h3>
        </div>
        <div class="col-4">
          <h3> {{ leadTime.leadTime }} {{ leadTime.leadTime === 1 ? 'Day' : 'Days' }} </h3>
        </div>
        <div class="col-4">
          <label for="" class="sr-only">New Lead time</label>
          <input type="text" class="form-control" name="newLeadTime" [(ngModel)]="leadTime.leadTimeChange" autocomplete="off">
        </div>
      </div>

      <div class="form-row pt-2">
        <div class="col">
          <button type="submit" class="btn btn-primary float-right" (click)="editLeadTimeSubmittal()">Submit</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
