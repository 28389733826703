<mat-form-field *ngIf="!useNativeInput" [appearance]="ffAppearance" #formFieldElement class="growinput-ff">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    #inputElement
    [(formControl)]="control"
    [disabled]="disabled"
    [type]="type"
    [pattern]="pattern"
    (input)="inputEvent($event)"
    (change)="changeEvent($event)"
    (blur)="blurEvent($event)"
    (focus)="focusEvent($event)"
    (keydown)="keydownEvent($event)"
    (keyup)="keyupEvent($event)"
    ngDefaultControl
    matInput
    [class.outline]="ffAppearance=='outline'"
    class="growinput"
  />
</mat-form-field>
<input
  *ngIf="useNativeInput"
  #inputElement
  [(formControl)]="control"
  [disabled]="disabled"
  [type]="type"
  [pattern]="pattern"
  (input)="inputEvent($event)"
  (change)="changeEvent($event)"
  (blur)="blurEvent($event)"
  (focus)="focusEvent($event)"
  (keydown)="keydownEvent($event)"
  (keyup)="keyupEvent($event)"
  matInput
  class="growinput"
/>