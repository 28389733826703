import { Component, HostListener, ViewChild, OnInit, AfterViewInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../../_services/auth/auth.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { concat } from 'rxjs';

interface InvoiceItemInterface {
  id: number;
  approvalState: string;
  itemCode: string;
  origItemCode: string;
  itemCodeDesc: string;
  lineKeySent: string;
  newLineKey: string;
  quantityInvoiced: number;
  newQuantityInvoiced: number;
  poQuantityInvoiced: number;
  poQuantityReceived: number;
  poQuantityOrdered: number;
  unitCost: number;
  newUnitCost: number;
  poUnitCost: number;
  poCommentText: string;
  newCommentText: string;
}

interface InvoiceCreditInterface {
  invoiceNo: string;
  invoiceType: string;
  vendor: string;
}

interface InvoiceInterface {
  id: number;
  invoiceNo: string;
  invoiceDate: string;
  receiptDate: string;
  purchaseOrderNo: string;
  newReceiptDate: string;
  newInvoiceNo: string;
  newInvoiceDate: string;
  shippingAmountDue: number;
  taxAmountDue: number;
  newTaxAmount: number;
  totalAmountDue: number;
  items: InvoiceItemInterface[];
  credits: InvoiceCreditInterface[];
}

interface ApiPurchaseOrderLineIterface {
  PurchaseOrderNo: string;
  ItemCode: string;
  ItemCodeDesc: string;
  LineKey: string;
  QuantityOrdered: number;
  QuantityReceived: number;
  QuantityInvoiced: number;
  UnitCost: number;
  CommentText: string;
}

interface ApiInvoiceItemInterface {
  Id: number;
  ApprovalState: string;
  ItemCode: string;
  ItemCodeDesc: string;
  LineKeySent: string;
  NewLineKey: string;
  QuantityInvoiced: number;
  NewQuantityInvoiced: number;
  PO_QuantityInvoiced: number;
  PO_QuantityReceived: number;
  PO_QuantityOrdered: number;
  UnitCost: number;
  NewUnitCost: number;
  PO_UnitCost: number;
  PO_CommentText: string;
  NewCommentText: string;
}

interface ApiInvoiceInterface {
  Id: number;
  InvoiceDate: string;
  NewInvoiceDate: string;
  InvoiceNo: string;
  NewInvoiceNo: string;
  Items: ApiInvoiceItemInterface[];
  PurchaseOrderNo: string;
  ReceiptDate: string;
  NewReceiptDate: string;
  ShippingAmountDue: number;
  TaxAmountDue: number;
  NewTaxAmountDue: number;
  TotalAmountDue: number;
  NewTotalAmountDue: number;
}

interface ApiInvoiceItemUpdateRequestInterface {
  Id: number;
  LineKey: string;
  QuantityInvoiced: number;
  UnitCost: number;
  CommentText: string;
}

interface ApiInvoiceCreditUpdateRequestInterface {
  InvoiceNo: string;
  InvoiceType: string;
  Vendor: string;
  AssociatedInvoiceNo: string;
  AssociatedPurchaseOrder: string;
}

interface ApiInvoiceUpdateRequestInterface {
  Id: number;
  ReceiptDate: string;
  InvoiceNo: string;
  InvoiceDate: string;
  TaxAmount: number;
  TotalAmount: number;
  Lines: ApiInvoiceItemUpdateRequestInterface[];
  UsedInvoices: ApiInvoiceCreditUpdateRequestInterface[];
}


@Component({
  selector: 'app-roi-approve',
  templateUrl: './roi-approve.component.html',
  styleUrls: ['./roi-approve.component.css']
})
export class RoiApproveComponent implements OnInit, AfterViewInit {
  invoiceId: number | undefined;
  showItemEdit = false;
  modeItemEdit = 'over';
  itemSelected: InvoiceItemInterface;
  itemSelectedIndex: number;
  invoice: InvoiceInterface | null;
  poLines: ApiPurchaseOrderLineIterface[] = [];
  poLinesLoading = false;
  poLinesIndex: number;
  poItemSelected: ApiPurchaseOrderLineIterface;
  math = Math;

  tableDisplayedColumns: string[] = [
    'lineKeySent',
    'itemCode',
    'itemCodeDesc',
    'quantityInvoiced',
    'unitCost',
    'totalCost',
    'action'
  ];

  poTableDisplayedColumns: string[] = [
    'itemCode',
    'itemCodeDesc',
    'quantityOrdered',
    'quantityReceived',
    'quantityInvoiced',
    'unitCost'
  ];

  dataSource: MatTableDataSource<InvoiceItemInterface> | null;
  poDataSource: MatTableDataSource<ApiPurchaseOrderLineIterface> | null;

  @ViewChild('invPaginator') invPaginator: MatPaginator;
  @ViewChild('invSort') invSort: MatSort;
  @ViewChild('invTable') invTable: MatTable<any>;

  @ViewChild('poPaginator') poPaginator: MatPaginator;
  @ViewChild('poSort') poSort: MatSort;
  @ViewChild('poTable') poTable: MatTable<any>;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.invoice?.items);
    this.dataSource.paginator = this.invPaginator;
    this.dataSource.sort = this.invSort;

    this.poDataSource = new MatTableDataSource(this.poLines);
    this.poDataSource.paginator = this.poPaginator;
    this.poDataSource.sort = this.poSort;

    const routeParams = this.route.snapshot.paramMap;
    this.invoiceId = Number(routeParams.get('id'));
    this.getInvoice();
    // this.screenSize();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.invPaginator;
    this.dataSource.sort = this.invSort;

    this.poDataSource.paginator = this.poPaginator;
    this.poDataSource.sort = this.poSort;
  }

  toggleItemEdit(item: any) {
    this.showItemEdit = !this.showItemEdit;
    if (this.showItemEdit) {
      this.itemSelected = item;
      this.itemSelectedIndex = this.invoice.items.findIndex(x => x.id === this.itemSelected.id);
    } // } else {
    //   this.itemSelected = null;
    //   this.itemSelectedIndex = null;
    // }
  }

  sumTotalCost(): number {
    const sumTotalCost = this.invoice?.items.reduce(
      (sum, current) => {
        const qtyInvoiced = current.newQuantityInvoiced ? current.newQuantityInvoiced : current.quantityInvoiced;
        const cost = current.newUnitCost ? current.newUnitCost : current.unitCost;
        return sum + (qtyInvoiced * cost);
      }
    , 0);
    return sumTotalCost;
  }

  needPOLineKey(item: any = null) {
    let lineKeySelected = false;

    if (item !== null) {
      lineKeySelected =
        item &&
        this.poLines.some(x => x.LineKey === item?.newLineKey || x.LineKey === item?.lineKeySent) &&
        (item?.newLineKey?.length === 6 || item?.lineKeySent?.length === 6);
    } else {
      lineKeySelected = this.itemSelected &&
      this.poLines.some(x => x.LineKey === this.itemSelected?.newLineKey || x.LineKey === this.itemSelected?.lineKeySent) &&
      (this.itemSelected?.newLineKey?.length === 6 || this.itemSelected?.lineKeySent?.length === 6);
    }

    return !lineKeySelected;
  }

  removeLineKey() {
    this.invoice.items[this.itemSelectedIndex].itemCode = this.invoice.items[this.itemSelectedIndex].origItemCode;
    this.invoice.items[this.itemSelectedIndex].lineKeySent = '';
    this.invoice.items[this.itemSelectedIndex].newLineKey = '';
    this.itemSelected.lineKeySent = '';
    this.itemSelected.itemCode = this.itemSelected.origItemCode;
    this.itemSelected.newLineKey = '';
  }

  onPoItemSelected(line: ApiPurchaseOrderLineIterface) {
    this.poItemSelected = line;
    this.poLinesIndex = this.poLines.findIndex(x => x.LineKey === line.LineKey);
    console.log(line);
    // this.poLinesIndex = poIndex;
  }

  assignPOLine() {
    this.invoice.items[this.itemSelectedIndex].itemCode = this.poLines[this.poLinesIndex].ItemCode;
    this.invoice.items[this.itemSelectedIndex].newLineKey = this.poLines[this.poLinesIndex].LineKey;
    this.invoice.items[this.itemSelectedIndex].poQuantityInvoiced = this.poLines[this.poLinesIndex].QuantityInvoiced;
    this.invoice.items[this.itemSelectedIndex].poQuantityOrdered = this.poLines[this.poLinesIndex].QuantityOrdered;
    this.invoice.items[this.itemSelectedIndex].poQuantityReceived = this.poLines[this.poLinesIndex].QuantityReceived;
    this.invoice.items[this.itemSelectedIndex].poUnitCost = this.poLines[this.poLinesIndex].UnitCost;
    this.invoice.items[this.itemSelectedIndex].poCommentText = this.poLines[this.poLinesIndex].CommentText;
    this.invoice.items[this.itemSelectedIndex].newCommentText = this.poLines[this.poLinesIndex].CommentText;

    this.invTable.renderRows();
  }

  updateInvoiceItem() {
    this.invoice.items[this.itemSelectedIndex].newQuantityInvoiced = this.itemSelected.newQuantityInvoiced;
    this.invoice.items[this.itemSelectedIndex].newUnitCost = this.itemSelected.newUnitCost;
    this.invoice.items[this.itemSelectedIndex].newCommentText = this.itemSelected.newCommentText;

    this.invTable.renderRows();
    this.toggleItemEdit(null);
  }

  calculateSageSalesTax() {
    const tax = this.sumTotalCost() * 0.0825;
    return Number(Math.round(Number(tax + 'e' + 2)) + 'e-' + 2);
  }

  calculateTaxDifferenceState() {
    if (Math.abs(this.calculateSageSalesTax()) < Math.abs(this.invoice?.newTaxAmount)) {
      return 'decrease';
    } else if (Math.abs(this.calculateSageSalesTax()) > Math.abs(this.invoice?.newTaxAmount)) {
      return 'increase';
    } else {
      return 'same';
    }
  }

  calculateTotalDue() {
    const total = this.invoice.newTaxAmount + this.sumTotalCost();
    return Number(Math.round(Number(total + 'e' + 2)) + 'e-' + 2);
  }

  calculateTotalDueDifferenceState() {
    if (Math.abs(this.calculateTotalDue()) < Math.abs(this.invoice?.totalAmountDue)) {
      return 'decrease';
    } else if (Math.abs(this.calculateTotalDue()) > Math.abs(this.invoice?.totalAmountDue)) {
      return 'increase';
    } else {
      return 'same';
    }
  }

  calculateItemCostDifference(item: InvoiceItemInterface) {
    const unitCost = item.newUnitCost ? item.newUnitCost : item.unitCost;
    const unitCostDifference = unitCost - item.poUnitCost;
    return Number(Math.round(Number(unitCostDifference + 'e' + 4)) + 'e-' + 4);
  }

  calculateItemCostDifferenceState(item: InvoiceItemInterface) {
    const unitCost = item.newUnitCost ? item.newUnitCost : item.unitCost;
    if (Math.abs(unitCost) < Math.abs(item.poUnitCost)) {
      return 'decrease';
    } else if (Math.abs(unitCost) > Math.abs(item.poUnitCost)) {
      return 'increase';
    } else {
      return 'same';
    }
  }

  calculateInvoicedDifference(item: InvoiceItemInterface) {
    const quantityAvailableToInvoice = item.poQuantityReceived - item.poQuantityInvoiced;
    const invoicedDifference = quantityAvailableToInvoice - item.newQuantityInvoiced;
    return Math.abs(invoicedDifference);
  }

  calculateInvoicedState(item: InvoiceItemInterface) {
    const quantityAvailableToInvoice = item.poQuantityReceived - item.poQuantityInvoiced;
    if (quantityAvailableToInvoice - item.newQuantityInvoiced > 0) {
      return 'underinvoiced';
    } else if (quantityAvailableToInvoice - item.newQuantityInvoiced < 0) {
      return 'overinvoiced';
    } else {
      return 'same';
    }
  }

  approvalDisabled() {
    if (this.invoice?.items === undefined) {
      return true;
    }
    const disabled = this.invoice?.items.some(x => x.newLineKey?.length !== 6 && x.lineKeySent?.length !== 6);
    return disabled;
  }

  deleteInvoice() {
    console.log(this.invoice);
    const dialogRef = this.dialog.open(RoiApproveDeleteDialogComponent, {
      data: { invoice: this.invoice }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

      if (result === 'delete') {
        this.router.navigate(['/Accounting/Receipt/Invoice']);
      }
    });
  }

  saveInvoice() {
    console.log(this.invoice);
    const dialogRef = this.dialog.open(RoiApproveSaveDialogComponent, {
      data: { invoice: this.invoice }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

      if (result === 'save') {
        this.saveInvoiceConfirmed();
      }
    });
  }

  saveInvoiceConfirmed() {
    const endpoint = 'ReceiptOfInvoice/Pending/Save/' + this.invoice.id;
    const observables = [];
    const body: ApiInvoiceUpdateRequestInterface = {
      Id: this.invoice.id,
      ReceiptDate: this.invoice.newReceiptDate,
      InvoiceNo: this.invoice.newInvoiceNo,
      InvoiceDate: this.invoice.newInvoiceDate,
      TaxAmount: this.invoice.newTaxAmount,
      TotalAmount: this.calculateTotalDue(),
      Lines: [],
      UsedInvoices: []
    };

    this.invoice.items.forEach(item => {
      const line: ApiInvoiceItemUpdateRequestInterface = {
        Id: item.id,
        LineKey: item.lineKeySent.length === 6 ? item.lineKeySent : item.newLineKey,
        QuantityInvoiced: item.newQuantityInvoiced,
        UnitCost: item.newUnitCost,
        CommentText: item.newCommentText
      };

      body.Lines.push(line);
    });

    this.invoice.credits.forEach(credit => {
      const usedInvoice: ApiInvoiceCreditUpdateRequestInterface = {
        InvoiceNo: credit.invoiceNo,
        InvoiceType: credit.invoiceType,
        Vendor: credit.vendor,
        AssociatedInvoiceNo: this.invoice.invoiceNo,
        AssociatedPurchaseOrder: this.invoice.purchaseOrderNo
      };

      body.UsedInvoices.push(usedInvoice);
    });

    observables.push(this.sageApi.putRequest(endpoint, body));

    concat(...observables).subscribe(response => {
      console.log(response);
    }, err => {
      console.log(err);
      this.alertify.error('Error ' + err.error.Message);
    }, () => {
      this.alertify.success('Saved Successfully');
    });
  }

  updateInvoice() {
    const dialogRef = this.dialog.open(RoiApproveHeaderDialogComponent, {
      data: {
        invoice: this.invoice,
        sageSumTotalCost: this.sumTotalCost(),
        sageTax: this.calculateSageSalesTax(),
        invoiceDate: this.invoice.newInvoiceDate,
        receiptDate: this.invoice.newReceiptDate,
        taxAmount: this.invoice.newTaxAmount
      },
      width: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.invoice.newReceiptDate =
          result.updatedReceiptDate instanceof Date ?
            new Date(result.updatedReceiptDate.getTime() - (result.updatedReceiptDate.getTimezoneOffset() * 60000)).toISOString().slice(0, -5)
            : result.updatedReceiptDate;
        this.invoice.newInvoiceDate =
          result.updatedInvoiceDate instanceof Date ?
            new Date(result.updatedInvoiceDate.getTime() - (result.updatedInvoiceDate.getTimezoneOffset() * 60000)).toISOString().slice(0, -5)
            : result.updatedInvoiceDate;
        this.invoice.newTaxAmount = result.updatedTax;
      }
    });
  }

  confirmApproval() {
    const approvedDifferences: InvoiceItemInterface[] = [];
    this.invoice.items.forEach(item => {
      if (this.calculateInvoicedState(item) !== 'same' || this.calculateItemCostDifferenceState(item) !== 'same') {
        approvedDifferences.push(item);
      }
    });
    const dialogRef = this.dialog.open(RoiApproveApproveDialogComponent, {
      data: { invoice: this.invoice, differences: approvedDifferences }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

      if (result === 'approve') {
        this.approveInvoice();
      }
    });
  }

  approveInvoice() {
    const endpoint = 'ReceiptOfInvoice/Pending/Update/' + this.invoice.id;
    const observables = [];
    const body: ApiInvoiceUpdateRequestInterface = {
      Id: this.invoice.id,
      ReceiptDate: this.invoice.newReceiptDate,
      InvoiceNo: this.invoice.newInvoiceNo,
      InvoiceDate: this.invoice.newInvoiceDate,
      TaxAmount: this.invoice.newTaxAmount,
      TotalAmount: this.calculateTotalDue(),
      Lines: [],
      UsedInvoices: []
    };

    this.invoice.items.forEach(item => {
      const line: ApiInvoiceItemUpdateRequestInterface = {
        Id: item.id,
        LineKey: item.lineKeySent.length === 6 ? item.lineKeySent : item.newLineKey,
        QuantityInvoiced: item.newQuantityInvoiced,
        UnitCost: item.newUnitCost,
        CommentText: item.newCommentText
      };

      body.Lines.push(line);
    });

    this.invoice.credits.forEach(credit => {
      const usedInvoice: ApiInvoiceCreditUpdateRequestInterface = {
        InvoiceNo: credit.invoiceNo,
        InvoiceType: credit.invoiceType,
        Vendor: credit.vendor,
        AssociatedInvoiceNo: this.invoice.invoiceNo,
        AssociatedPurchaseOrder: this.invoice.purchaseOrderNo
      };

      body.UsedInvoices.push(usedInvoice);
    });

    observables.push(this.sageApi.putRequest(endpoint, body));

    concat(...observables).subscribe(response => {
      console.log(response);
    }, err => {
      console.log(err);
      this.alertify.error('Error ' + err.error.Message);
    }, () => {
      this.alertify.success('Approved Successfully');
      this.router.navigate(['/Accounting/Receipt/Invoice']);
    });
  }

  getInvoice() {
    if (!Number.isInteger(this.invoiceId)) {
      return;
    }

    this.sageApi.pullReport('ReceiptOfInvoice/Pending/' + this.invoiceId).subscribe((data: ApiInvoiceInterface) => {
      console.log(data);

      this.invoice = {
        id: data.Id,
        invoiceNo: data.InvoiceNo,
        invoiceDate: data.InvoiceDate,
        receiptDate: data.ReceiptDate,
        purchaseOrderNo: data.PurchaseOrderNo,
        newReceiptDate: data?.NewReceiptDate ?? data.ReceiptDate,
        newInvoiceNo: data?.NewInvoiceNo ?? data.InvoiceNo,
        newInvoiceDate: data?.NewInvoiceDate ?? data.InvoiceDate,
        shippingAmountDue: data.ShippingAmountDue,
        taxAmountDue: data.TaxAmountDue,
        newTaxAmount: data?.NewTaxAmountDue ?? data.TaxAmountDue,
        totalAmountDue: data.TotalAmountDue,
        items: [],
        credits: []
      };

      data.Items.forEach(item => {
        const itemObj: InvoiceItemInterface = {
          id: item.Id,
          approvalState: item.ApprovalState,
          itemCode: item.ItemCode,
          origItemCode: item.ItemCode,
          itemCodeDesc: item.ItemCodeDesc,
          lineKeySent: item.LineKeySent,
          newLineKey: item.NewLineKey,
          quantityInvoiced: item.QuantityInvoiced,
          newQuantityInvoiced: item?.NewQuantityInvoiced ?? item.QuantityInvoiced,
          poQuantityInvoiced: item.PO_QuantityInvoiced,
          poQuantityReceived: item.PO_QuantityReceived,
          poQuantityOrdered: item.PO_QuantityOrdered,
          unitCost: item.UnitCost,
          newUnitCost: item?.NewUnitCost ?? item.UnitCost,
          poUnitCost: item.PO_UnitCost,
          poCommentText: item.PO_CommentText,
          newCommentText: item?.NewCommentText ?? item.PO_CommentText
        };

        this.invoice.items.push(itemObj);
      });

    }, err => {
      console.log('Error Retreiving Invoice', err);
    }, () => {
      this.dataSource = new MatTableDataSource(this.invoice.items);
      this.dataSource.paginator = this.invPaginator;
      this.dataSource.sort = this.invSort;
      console.log(this.dataSource);
      this.getPurchaseOrderLines();
    });
  }

  getPurchaseOrderLines() {
    this.poLinesLoading = true;
    const endpoint = 'ReceiptOfInvoice/PurchaseOrderLines/' + this.invoice.purchaseOrderNo;
    this.sageApi.pullReport(endpoint).subscribe((data: ApiPurchaseOrderLineIterface[]) => {
      this.poLines = data;
      // console.log('po data', data);
    }, err => {
      console.log(err);
      this.poLinesLoading = false;
    }, () => {
      this.poDataSource = new MatTableDataSource(this.poLines);
      this.poDataSource.paginator = this.poPaginator;
      this.poDataSource.sort = this.poSort;
      this.poLinesLoading = false;
      console.log(this.poDataSource.sort);
    });
  }

  // @HostListener('window:resize', []) screenSize() {
  //   // lg (for laptops and desktops - screens equal to or greater than 1200px wide)
  //   // md (for small laptops - screens equal to or greater than 992px wide)
  //   // sm (for tablets - screens equal to or greater than 768px wide)
  //   // xs (for phones - screens less than 768px wide)

  //   if (window.innerWidth >= 1200) {
  //     this.modeItemEdit = 'side'; // lg
  //   } else if (window.innerWidth >= 992) {
  //     this.modeItemEdit = 'over'; // md
  //   } else if (window.innerWidth  >= 768) {
  //     this.modeItemEdit = 'over'; // sm
  //   } else if (window.innerWidth < 768) {
  //     this.modeItemEdit = 'over'; // xs
  //   }

  // }

}

@Component({
  selector: 'app-roi-approve-delete',
  templateUrl: './roi-approve-delete.component.html',
})
export class RoiApproveDeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RoiApproveDeleteDialogComponent>,
    private sageApi: SageApiService,
    @Inject(MAT_DIALOG_DATA) public data: { invoice: InvoiceInterface }
  ) {}

  confirmDelete() {
    // api call to delete invoice
    const endpoint = 'ReceiptOfInvoice/Pending/Delete/' + this.data.invoice.id;
    const body = {
      UsedInvoices: []
    };

    this.data.invoice.credits.forEach(credit => {
      const usedInvoice: ApiInvoiceCreditUpdateRequestInterface = {
        InvoiceNo: credit.invoiceNo,
        InvoiceType: credit.invoiceType,
        Vendor: credit.vendor,
        AssociatedInvoiceNo: this.data.invoice.invoiceNo,
        AssociatedPurchaseOrder: this.data.invoice.purchaseOrderNo
      };

      body.UsedInvoices.push(usedInvoice);
    });

    this.sageApi.postRequest(endpoint, body).subscribe(response => {
      console.log(response);
    }, err => {
      console.log(err);
    }, () => {
      console.log('finsihed');
    });

    this.dialogRef.close('delete');
  }
}

@Component({
  selector: 'app-roi-approve-save',
  templateUrl: './roi-approve-save.component.html',
})
export class RoiApproveSaveDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RoiApproveSaveDialogComponent>,
    private sageApi: SageApiService,
    @Inject(MAT_DIALOG_DATA) public data: { invoice: InvoiceInterface }
  ) {}

  confirmSave() {
    this.dialogRef.close('save');
  }
}

@Component({
  selector: 'app-roi-approve-approve',
  templateUrl: './roi-approve-approve.component.html'
})
export class RoiApproveApproveDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RoiApproveDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { invoice: InvoiceInterface, differences: InvoiceItemInterface[] }
  ) {}

  confirmApprove() {
    this.dialogRef.close('approve');
  }
}
@Component({
  selector: 'app-roi-approve-header',
  templateUrl: './roi-approve-header.component.html',
  styleUrls: ['./roi-approve-header.component.css']
})
export class RoiApproveHeaderDialogComponent {
  creditForm = new FormGroup({
    InvoiceNo: new FormControl('', [
      Validators.required
    ]),
    InvoiceType: new FormControl('credit', [
      Validators.required
    ])
  });

  constructor(
    public dialogRef: MatDialogRef<RoiApproveHeaderDialogComponent>,
    // private sageApi: SageApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      invoice: InvoiceInterface,
      sageSumTotalCost: number,
      sageTax: number,
      invoiceDate: string,
      receiptDate: string,
      taxAmount: number
    }
  ) {}

  confirmUpdate() {
    const updates = {
      updatedReceiptDate: this.data.receiptDate,
      updatedInvoiceDate: this.data.invoiceDate,
      updatedTax: this.data.taxAmount
    };
    console.log(updates);
    this.dialogRef.close(updates);
  }

  addCredit($event) {
    const credit: InvoiceCreditInterface = {
      invoiceNo: this.creditForm.get('InvoiceNo').value,
      invoiceType: this.creditForm.get('InvoiceType').value,
      vendor: 'MOORE01'
    };

    this.data.invoice.credits.unshift(credit);

    $event.currentTarget.reset();
    this.creditForm.reset({ InvoiceType: 'credit' });
  }

  removeCredit(creditIndex: number) {
    this.data.invoice.credits.splice(creditIndex, 1);
  }
}


