<div class="korppicker quoting-theme">
  <mat-form-field
    appearance="outline"
    class="korppicker-ff korppicker-type"
  >
    <mat-label>Kit/Part</mat-label>
    <mat-select
      (selectionChange)="kitOrPartControlChange()"
      [formControl]="typeControl"
      [disabled]="disabled"
      class="korppicker-sel korppicker-sel-type"
    >
      <mat-option value="Part">Part</mat-option>
      <mat-option value="Kit">Kit</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="korppicker-ff korppicker-korp"
  >
    <mat-label>{{typeControl.value}}</mat-label>
    <mat-select
      [formControl]="control"
      class="korppicker-sel korppicker-sel-korp"
    >
      <input
        matInput
        [formControl]="searchControl"
        [placeholder]="typeControl.value"
        (input)="selectedFilteredOptionsSubject.next($event.target.value)"
        class="korppicker-korp-sel-in"
      />
      <mat-divider></mat-divider>
      <mat-option [style]="{display: 'none'}"></mat-option>
      <mat-option *ngIf="control.value!=''" [value]="control.value" class="kortpicker-korp-op">
        <span class="kortpicker-korp-op-title">{{ control.value?.Part?.Part_Code || control.value.Kit_Name }}</span> - 
        <span class="kortpicker-korp-op-cost quoting-theme">
          <span class="kortpicker-korp-op-cost-icon">$</span>
          {{ control.value?.Part?.Part_Cost?.toFixed(2) || '' }}
        </span>
        <p class="kortpicker-korp-op-desc">{{ control.value?.Part?.Part_Desc || control.value.Kit_Desc }}</p>
      </mat-option>
      <mat-option *ngFor="let op of selectedOptions" [value]="op" class="kortpicker-korp-op">
        <span class="kortpicker-korp-op-title">
          {{ op?.Part?.Part_Code || op.Kit_Name }}
        </span>
        <span *ngIf="op?.Kit_Name == null" class="kortpicker-korp-op-cost quoting-theme">
          {{ ' - ' }} 
          <span class="kortpicker-korp-op-cost-icon">$</span>
          {{ op?.Part?.Part_Cost?.toFixed(2) || '' }}
        </span>
        <p class="kortpicker-korp-op-desc">{{ control.value?.Part?.Part_Desc || control.value.Kit_Desc }}</p>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
