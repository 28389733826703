import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';

@Component({
  selector: 'app-address-customer-assignment',
  templateUrl: './address-customer-assignment.component.html',
  styleUrls: ['./address-customer-assignment.component.css']
})
export class AddressCustomerAssignmentComponent implements OnInit {
  addCustomer: BsModalRef;
  newCustomerValid: boolean;
  newCustomer = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  };

  updateAllPrimaryEmail = '';
  updateAllSecondaryEmail = '';
  updateAllTertiaryEmail = '';

  addressName = '';
  addressList = [];
  addressLoading = false;

  subdivisionName = '';
  subdivisionList = [];

  builderName = '';
  builderSelected = false;
  builderList = [];

  customerList = [];

  filteredList = [];
  filteredListBuilder = [];


  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.loadAddressList();
    this.loadCustomerList();
  }

  openModal(template: TemplateRef<any>) {
    this.newCustomer = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: ''
    };

    this.addCustomer = this.modalService.show(template);
  }

  closeModal() {
    this.addCustomer.hide();
  }

  filterAddress(event: TypeaheadMatch) {
    console.log(event.item);
    this.subdivisionName = '';
    this.builderName = '';
    this.builderSelected = false;
    this.filteredList = this.addressList.filter(address => address.name.toLowerCase() === event.item.name.toLowerCase());
  }

  filterSubdivision(event: TypeaheadMatch) {
    console.log(event.item);
    this.addressName = '';
    this.builderName = '';
    this.builderSelected = false;
    this.filteredList = this.addressList.filter(address => address.subdivision.toLowerCase() === event.item.name.toLowerCase());
  }

  filterBuilder(event: TypeaheadMatch) {
    console.log(event.item, this.builderName);
    this.builderName = event.item.name;
    this.builderSelected = true;
  }

  displayTicket(addressListIndex: number) {
    let displayTicket = false;
    const subdivision = this.addressList[addressListIndex].subdivision;
    const address = this.addressList[addressListIndex].name;

    if ((address.includes(this.addressName) || this.addressName.length === 0)
          && (subdivision.includes(this.subdivisionName) || this.subdivisionName.length === 0)) {
            this.addressList[addressListIndex].display = true;
            displayTicket = true;
    } else {
      this.addressList[addressListIndex].display = false;
    }
    return displayTicket;
  }

  sortTickets(keyToSortBy: any) {
    const arr = this.filteredList;

    arr.sort((a, b) => {
      let ascDirection = false; // sort numbers in descending
      let ItemOne = a[keyToSortBy];
      let ItemTwo = b[keyToSortBy];

      if (typeof a[keyToSortBy] === 'string') {
        ItemOne = a[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ItemTwo = b[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ascDirection = true;
      }

      if (ItemOne < ItemTwo) {
        return ascDirection ? -1 : 1;
      }
      if (ItemOne > ItemTwo) {
        return ascDirection ? 1 : -1;
      }
      // items must be equal
      return 0;
    });
  }

  updateEmail(event: TypeaheadMatch, addressIndex: number, emailType: string) {
    const originalIndex = this.addressList.findIndex(address => address.salesOrderNo ===  this.filteredList[addressIndex].salesOrderNo);
    const observables = [];
    const body = {
      salesorderno: this.filteredList[addressIndex].salesOrderNo,
      email: event.item.emailAddress,
      emailSelection: emailType,
      user: this.authService.decodedToken?.nameid
    };
    observables.push(this.sageApi.putRequest('Users/Customers', body));

    concat(...observables).subscribe(response => {
      console.log('put request', response);
    }, error => {
      console.log(error);
      this.alertify.error('Error - ' + error);
    }, () => {
      if (emailType === 'primary') {
        this.filteredList[addressIndex].primaryEmail = event.item.emailAddress;
        this.addressList[originalIndex].primaryEmail = event.item.emailAddress;
      }
      if (emailType === 'secondary') {
        this.filteredList[addressIndex].secondaryEmail = event.item.emailAddress;
        this.addressList[originalIndex].secondaryEmail = event.item.emailAddress;
      }
      if (emailType === 'tertiary') {
        this.filteredList[addressIndex].tertiaryEmail = event.item.emailAddress;
        this.addressList[originalIndex].tertiaryEmail = event.item.emailAddress;
      }
      this.alertify.success('Success!');
    });
  }

  updateEmailAll(event: TypeaheadMatch, emailType: string) {
    this.filteredListBuilder = this.filteredList.filter(address => address.customerName.toLowerCase() === this.builderName.toLowerCase());
    const observables = [];
    this.filteredListBuilder.forEach(address => {
      const body = {
        salesorderno: address.salesOrderNo,
        email: event.item.emailAddress,
        emailSelection: emailType,
        user: this.authService.decodedToken?.nameid
      };
      observables.push(this.sageApi.putRequest('Users/Customers', body));
    });

    concat(...observables).subscribe(response => {
      console.log('put request', response);
    }, error => {
      console.log(error);
      this.alertify.error('Error - ' + error.message);
    }, () => {
      this.filteredListBuilder.forEach(address => {
        const originalIndex = this.addressList.findIndex(original => original.salesOrderNo ===  address.salesOrderNo);
        const filteredIndex = this.filteredList.findIndex(filtered => filtered.salesOrderNo === address.salesOrderNo);
        if (emailType === 'primary') {
          this.filteredList[filteredIndex].primaryEmail = event.item.emailAddress;
          this.filteredList[filteredIndex].primaryPhoneNumber = event.item.phoneNumber;
          this.addressList[originalIndex].primaryEmail = event.item.emailAddress;
          this.addressList[originalIndex].primaryPhoneNumber = event.item.phoneNumber;
        }
        if (emailType === 'secondary') {
          this.filteredList[filteredIndex].secondaryEmail = event.item.emailAddress;
          this.filteredList[filteredIndex].secondaryPhoneNumber = event.item.phoneNumber;
          this.addressList[originalIndex].secondaryEmail = event.item.emailAddress;
          this.addressList[originalIndex].secondaryPhoneNumber = event.item.phoneNumber;
        }
        if (emailType === 'tertiary') {
          this.filteredList[filteredIndex].tertiaryEmail = event.item.emailAddress;
          this.filteredList[filteredIndex].tertiaryPhoneNumber = event.item.phoneNumber;
          this.addressList[originalIndex].tertiaryEmail = event.item.emailAddress;
          this.addressList[originalIndex].tertiaryPhoneNumber = event.item.phoneNumber;
        }
      });
      this.alertify.success('Success!');
    });

    console.log(this.filteredListBuilder);
  }

  addNewCustomer(form: NgForm) {

    if (form.invalid) {
      this.newCustomerValid = false;
      return;
    }

    const observables = [];
    const body = {
      firstName: this.newCustomer.firstName.toLocaleLowerCase(),
      lastName: this.newCustomer.lastName.toLocaleLowerCase(),
      email: this.newCustomer.email.toLocaleLowerCase(),
      phoneNumber: this.newCustomer.phoneNumber.replace(/\D/g, ''),
      user: this.authService.decodedToken?.nameid.toLocaleLowerCase()
    };
    observables.push(this.sageApi.postRequest('Users/Customers', body));
    concat(...observables).subscribe(response => {
      console.log(response);
    }, error => {
      console.log(error);
      this.alertify.error('Error - ' + error);
    }, () => {
      form.resetForm();
      this.alertify.success('User Added!');
      this.loadCustomerList();
      this.addCustomer.hide();
    });
  }

  loadCustomerList() {
    this.sageApi.pullReport('Users/Customers').subscribe((customers: Array<any>) => {
      customers.forEach(customer => {
        const obj = {
          name: customer.FirstName + ' ' + customer.LastName,
          emailAddress: customer.email,
          phoneNumber: customer.phoneNumber
        };

        this.customerList.push(obj);
      });
    }, err => {
      console.log(err);
    }, () => {
      console.log(this.customerList);
    });
    //console.log(this.customerList);
  }

  loadAddressList() {
    this.addressLoading = true;

    this.sageApi.pullReport('Address').subscribe((address: Array<any>) => {
      this.addressList = [];
      address.forEach( add => {
        const obj = {
          name: add.ShipToName,
          salesOrderNo: add.SalesOrderNo,
          orderType: add.OrderType,
          subdivision: add.Subdivision,
          customerName: add.CustomerName,
          primaryPhoneNumber: add.PhoneNumberPrimary,
          primaryEmail: add.UDF_BUILDER_EMAIL_1,
          primaryEmailChange: '',
          secondaryPhoneNumber: add.PhoneNumberSecondary,
          secondaryEmail: add.UDF_BUILDER_EMAIL_2,
          secondaryEmailChange: '',
          tertiaryPhoneNumber: add.PhoneNumberTertiary,
          tertiaryEmail: add.UDF_BUILDER_EMAIL_3,
          tertiaryEmailChange: '',
          selected: false,
          display: true
        };

        const subdivisionObj = {
          name: add.Subdivision,
          selected: false
        };

        const builderObj = {
          name: add.CustomerName,
          selected: false
        };

        this.addressList.push(obj);
        if (!this.subdivisionList.some(subdivision => subdivision.name === subdivisionObj.name)) {
          this.subdivisionList.push(subdivisionObj);
        }
        if (!this.builderList.some(builder => builder.name === builderObj.name)) {
          this.builderList.push(builderObj);
        }


      });
    }, err => {
      console.log(err);
    }, () => {
      this.addressLoading = false;
      console.log(this.addressList, this.subdivisionList);
    });
  }

  checkEmails(email: string){
    if (email !== '' || email !== null){
      return this.customerList.some((customer) =>
        customer.emailAddress == email);
    } else {
      return false;
    }
  }
}
