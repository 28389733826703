<div *ngIf="quoteQuestion.QuoteQuestion" class="qqrow">
  <h4 class="qqrow-name">{{quoteQuestion.QuoteQuestion.QuoteQuestion_Name}}</h4>
  <h6 class="qqrow-desc">{{quoteQuestion.QuoteQuestion.QuoteQuestion_Desc}}</h6>
  <div
    *ngFor="let checked of answersChecked; let cbIndex = index"
    class="qqrow-ans"
  >
    <div class="qqrow-ans-main">
      <input
        [disabled]="disabled"
        [formControl]="answersChecked[cbIndex]"
        (change)="checkChanged($event.target.checked, cbIndex)"
        type="checkbox"
        [class.radio]="quoteQuestion.QuoteQuestion.QuoteQuestion_Type === 'radio'"
        class="qqrow-ans-main-cb"
      />
      <h5 class="qqrow-ans-sub-name">{{ quoteQuestion.QuoteQuestionAnswers[cbIndex].QuoteQuestionAnswer.QuoteQuestionAnswer_Name }}</h5>
    </div>
    <div class="qqrow-ans-sub">
      <h6 class="qqrow-ans-sub-desc">{{ quoteQuestion.QuoteQuestionAnswers[cbIndex].QuoteQuestionAnswer.QuoteQuestionAnswer_Desc }}</h6>
    </div>
  </div>
</div>