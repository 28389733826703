<div [class.addrfs-mobile]="isMobile" class="addrfs">
    <mat-card class="addrfs-card">
        <mat-card-title class="addrfs-card-title">
            Reverse Document search
        </mat-card-title>
        <mat-card-content>
            <div *ngIf="addressesLoading || documentsLoading" class="addrfs-card-loading">
                <mat-spinner diameter="75" class="addrfs-card-loading-pos-spinner"></mat-spinner>
            </div>
            <app-buff-searchable-sel (onSelectChange)="onSelectedAddressChange($event)"
                [disabled]="addressesLoading && documentsLoading" [options]="addressesOptions"
                placeholder="Address"></app-buff-searchable-sel>

            <ng-container *ngIf="!addressesLoading && !documentsLoading && documents.length > 0">
                <app-media-gallery [slides]="documents"></app-media-gallery>

                <hr />

                <h4 class="addrfs-card-ftitle">Files</h4>
                <table mat-table [dataSource]="documentsToTableData()" matSort class="addrfs-card-table">
                    <ng-container matColumnDef="FileName">
                        <th mat-header-cell *matHeaderCellDef> FileName </th>
                        <td mat-cell *matCellDef="let element">
                            <a [href]="element.FileName" target="_blank">
                                {{element.FileName}}
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="['FileName']">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['FileName'];">
                    </tr>
                </table>
            </ng-container>

        </mat-card-content>
    </mat-card>
</div>