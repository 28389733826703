<div class="container pb-5">
    <mat-card style="min-height: 100%;">
        <mat-card-content [hidden]="isPlanLoading">
            <mat-form-field class="w-50">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. DRH" #input>
            </mat-form-field>
            <div class="ml-2 float-right">
                <button mat-raised-button class="float-right" (click)="openPlanForm()">
                    Add Plan <mat-icon>add</mat-icon>
                </button>
            </div>

            <div class="mat-elevation-z8 mat-table-responsive" style="overflow: auto;">
                <table mat-table [dataSource]="dataSource" class="w-100" matSort>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Plan Name </th>
                        <td mat-cell *matCellDef="let element"> 
                            <mat-form-field floatLabel="never">
                                <input matInput placeholder="name" [value]="element.name" disabled style="color: black;">
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <!-- Description Column -->
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                        <td mat-cell *matCellDef="let element"> 
                            <mat-form-field floatLabel="never">
                                <textarea matInput placeholder="description" [value]="element.description" [(ngModel)]="element.description"></textarea>
                            </mat-form-field>
                        </td>

                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let element"> 
                            <ng-container *ngIf="!element.updating">
                                <button mat-raised-button (click)="savePlan(element)">
                                    {{ element.updated ? 'Saved' : 'Save' }} 
                                    <mat-icon class="text-success">save</mat-icon>
                                </button>
                            </ng-container>

                            <ng-container *ngIf="element.updating">
                                <mat-icon class="text-success">send</mat-icon>
                                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </ng-container>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: true;"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableDisplayedColumns;"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="5">No data matching the filter "{{ input.value }}"</td>
                    </tr>
                </table>   
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>