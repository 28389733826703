import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import PayLineItemsInterface from 'src/app/_services/sageApi/interfaces/pullReport/PayLineItemsInterface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertifyService } from 'src/app/_services/alertify/alertify.service';

@Component({
  selector: 'app-super-pay-line-items-table',
  templateUrl: './super-pay-line-items-table.component.html',
  styleUrls: ['./super-pay-line-items-table.component.css']
})
export class SuperPayLineItemsTable implements OnInit, AfterViewInit {

  isMobile: boolean = false;

  // Pay Line Items table state
  payLineItemsLoading: boolean = true;
  payLineItems: PayLineItemsInterface[] = [];
  payLineItemsData = new MatTableDataSource<PayLineItemsInterface>([]);
  payLineItemsFilterChange: boolean = true;
  payLineItemsAllTechs: string[] = [];
  payLineItemsTechControl = new FormControl([]);
  payLineItemsTechs: string[] = [];
  payLineItemsAddressControl: string = '';
  payLineItemsPayDateContrlMin: Date;
  payLineItemsPayDateContrlMax: Date;
  payLineItemsPayDateContrl = new FormGroup({
    start: new FormControl(null, [Validators.required]),
    end: new FormControl(new Date((new Date()).toISOString().slice(0, 10)), [Validators.required]),
  });
  payLineItemsSearchContrl: boolean = false;
  @ViewChild('payLineItemsDataPaginator', { read: MatPaginator }) payLineItemsDataPaginator: MatPaginator;
  @ViewChild(MatSort) payLineItemsDataSort: MatSort;

  constructor(
    private sageApi: SageApiService,
    breakpointObserver: BreakpointObserver,
    private alertify: AlertifyService
  ) {
    this.setDefaultDateStates();
    breakpointObserver.observe([
      Breakpoints.Small,
      Breakpoints.XSmall
    ]).subscribe(result => {
      if (result.matches) {
        this.isMobile = true;
      }else{
        this.isMobile = false;
      }
    });
  }

  ngOnInit(): void {
    this.pullPayLineItems(this.payLineItemsPayDateContrlMin.toISOString().slice(0, 10), this.payLineItemsPayDateContrlMax.toISOString().slice(0, 10));
  }

  ngAfterViewInit() {
    this.payLineItemsData.paginator = this.payLineItemsDataPaginator;
    this.payLineItemsData.sort = this.payLineItemsDataSort;
  }

  setDefaultDateStates(){
    const payLineItemsMinDate = new Date((new Date()).toISOString().slice(0, 10));
    payLineItemsMinDate.setMonth(payLineItemsMinDate.getMonth() - 12);
    payLineItemsMinDate.setHours(0);
    payLineItemsMinDate.setMinutes(0);
    payLineItemsMinDate.setSeconds(0);
    payLineItemsMinDate.setMilliseconds(0);
    const payLineItemsMaxDate = new Date((new Date()).toISOString().slice(0, 10));
    payLineItemsMaxDate.setDate(payLineItemsMaxDate.getDate() + 7);
    payLineItemsMaxDate.setHours(23);
    payLineItemsMaxDate.setMinutes(59);
    payLineItemsMaxDate.setSeconds(59);
    payLineItemsMaxDate.setMilliseconds(999);

    this.payLineItemsPayDateContrlMin = payLineItemsMinDate;
    this.payLineItemsPayDateContrlMax = payLineItemsMaxDate;
    this.payLineItemsPayDateContrl = new FormGroup({
      start: new FormControl(payLineItemsMinDate, [Validators.required]),
      end: new FormControl(payLineItemsMaxDate, [Validators.required]),
    });
  }
  
  /* ------------------ */
  /* payLineItems stuff */
  /* ------------------ */

  onPayLineItemsPayDate(e){
    this.setPayLineItemsPayDateSearchable();
  }

  setPayLineItemsPayDateSearchable(){
    let newPayLineItemsSearchContrl = true;
    if(this.payLineItemsPayDateContrl.value?.start == null || this.payLineItemsPayDateContrl.value?.end == null){
      newPayLineItemsSearchContrl = false;
    }else{
      if(this.payLineItemsPayDateContrl.value?.start.getTime() > this.payLineItemsPayDateContrl.value?.end.getTime()){
        newPayLineItemsSearchContrl = false;
      }
      if(this.payLineItemsPayDateContrl.value?.start.getTime() < this.payLineItemsPayDateContrlMin.getTime() || this.payLineItemsPayDateContrl.value?.end.getTime() > this.payLineItemsPayDateContrlMax.getTime()){
        newPayLineItemsSearchContrl = false;
      }
    }
    if(this.payLineItemsTechControl.value.length == 0){
      newPayLineItemsSearchContrl = false;
    }
    this.payLineItemsSearchContrl = newPayLineItemsSearchContrl;
  }

  pullPayLineItems(startDate: string, endDate: string){
    const reqPayLineItems = ()=>{
      this.sageApi.pullReport(`SuperMisc/pay-line-items?startDate=${startDate}&endDate=${endDate}`).subscribe((result: PayLineItemsInterface[])=>{
        this.payLineItemsLoading = false;
        this.payLineItems = result;
        this.payLineItemsData = new MatTableDataSource<PayLineItemsInterface>(result);
        const allTechs = this.getAllTechsFromPayLineItems(result);
        this.payLineItemsAllTechs = allTechs;
        this.payLineItemsTechs = allTechs;
        this.payLineItemsTechControl.setValue(allTechs);
        this.payLineItemsData.paginator = this.payLineItemsDataPaginator;
        this.payLineItemsData.sort = this.payLineItemsDataSort;
      },
      (err)=>{
        if(err?.status == 0){
          this.alertify.error(`Could not reach the server for the following resource: "SuperMisc/pay-line-items"! `);
        }else{
          this.alertify.error(`Could not load "SuperMisc/pay-line-items"! \nCheck with IT if this persists.`);
        }
        this.payLineItemsLoading = false;
        console.log(err);
      });
    }
    reqPayLineItems();
  }

  getAllTechsFromPayLineItems(items: PayLineItemsInterface[]) { 
    const techs: string[] = [];
    items.map(pli=>{
      if(!techs.find(t=>t==pli.TechName)){
        techs.push(pli.TechName);
      }
    });
    return techs;
  }

  onPayLineItemTechSearch(value: string|null, items: PayLineItemsInterface[]) { 
    const newPayLineItemsTechs = [];
    this.payLineItemsAllTechs.map(tech=>{
      if(value==null || tech.toLowerCase().includes(value.toLowerCase())){
        newPayLineItemsTechs.push(tech);
      }
    });
    this.payLineItemsTechs = newPayLineItemsTechs;
  }

  onPayLineItemTechSelectAll(){
    if(this.payLineItemsTechControl.value.sort().join() == this.payLineItemsTechs.sort().join()){
      this.payLineItemsTechControl.setValue([]);
    }else{
      this.payLineItemsTechControl.setValue(this.payLineItemsTechs);
    }
    this.setPayLineItemsPayDateSearchable();
  }

  isPayLineItemPaid(pli: PayLineItemsInterface): boolean{
    if(pli.UDF_PAY_DATE==undefined || pli.UDF_PAY_DATE==null){
      return false;
    }

    let pliDate = (new Date(pli.UDF_PAY_DATE||'2099-01-01')).getTime();
    const futureDateCutoff = (new Date('2098-01-01')).getTime();
    if(pliDate >= futureDateCutoff){
      return false;
    }

    pliDate = (new Date(pli.UDF_PAY_DATE||'1899-01-01')).getTime();
    const oldDateCutoff = (new Date('1900-01-01')).getTime();
    if(pliDate <= oldDateCutoff){
      return false;
    }
    return true;
  }

  onPayLineItemsFilterSearch(){
    
    const pliAddressPass = (pli: PayLineItemsInterface): boolean=>{
      return (this.payLineItemsAddressControl.trim()==''||pli.ShipToName.toLowerCase().includes(this.payLineItemsAddressControl.toLowerCase()));
    }

    const pliDatePass = (pli: PayLineItemsInterface): boolean=>{
      if(pli.UDF_PAY_DATE==null || pli.UDF_PAY_DATE.slice(2, 4) == '99'){
        return true;
      }
      if(
        (new Date(pli.UDF_PAY_DATE)).getTime() < this.payLineItemsPayDateContrl.value.end.getTime()
        && (new Date(pli.UDF_PAY_DATE)).getTime() > this.payLineItemsPayDateContrl.value.start.getTime()
      ){
        return true;
      }
      if(
        (new Date(pli.UDF_PAY_DATE)).toISOString().slice(0, 10) == this.payLineItemsPayDateContrl.value.start.toISOString().slice(0, 10)
        || (new Date(pli.UDF_PAY_DATE)).toISOString().slice(0, 10) == this.payLineItemsPayDateContrl.value.end.toISOString().slice(0, 10)
      ){
        return true;
      }
      return false;
    }

    this.payLineItemsSearchContrl = false;
    const newData: PayLineItemsInterface[] = [];
    this.payLineItems.map((pli)=>{
      if(
        this.payLineItemsTechControl.value.includes(pli.TechName) 
        && pliAddressPass(pli)
        && pliDatePass(pli)
      ){
        newData.push(pli);
      }
    })
    this.payLineItemsData = new MatTableDataSource<PayLineItemsInterface>(newData);
    this.payLineItemsData.paginator = this.payLineItemsDataPaginator;
    this.payLineItemsData.sort = this.payLineItemsDataSort;
  }

}