import {Injectable} from '@angular/core';
// @ts-ignore
import {Observable} from 'rxjs/Observable';
import {HttpClient, HttpHeaders} from '@angular/common/http';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
// import * as xml2js from 'xml2js';
const xml2js = require('xml2js');

@Injectable({
  providedIn: 'root'
})
export class MetabaseService {

  constructor(private httpClient: HttpClient) { }
  baseApiURL = 'https://metabase.gibsonplumbing.com/api';

  _getMetabaseAuthToken() {
    const requestURL = `${this.baseApiURL}/session`;

    // const headers = new Headers({
    //   // 'Cache-Control': 'no-cache',
    //   Accept: '*/*',
    //   // 'Content-Type': 'application/json',
    // });
    // const reportAuth = localStorage.getItem('token');
    const reportAuth = {
      // 'Cache-Control': 'no-cache',
      Accept: '*/*',
      // 'Content-Type': 'application/json',
      // 'X-Metabase-Session': mbAuthToken
    };
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);

    // TODO - update this to set credentials in ENV file
    const body = {
      username: 'plumbers@gibsonplumbing.com',
      password: 'Gibson1954!!Plumbing'
    };

    console.log(requestURL);

    // const options = new RequestOptions({ headers });

    // return this.httpClient.post(requestURL, body, { headers }).map((response: Response) => {
    //   console.log(response);
    //   return response.json();
    // // }).catch((response, v) => Observable.throw(response));
    // }).catch((response, v) => throwError(response));

    return this.httpClient.post(requestURL, body, {headers}).map( response => {
      // console.log(response);
      return response;
    });

  }

  metabaseDashboardUpdate(address: string, authToken: string) {
    // * The id in the URL can be changed to update other dashboards/questions - 2 is the id for House360 View * //
    const requestURL = `${this.baseApiURL}/dashboard/2`;
    // * Removes double quotes from auth token * //
    const mbAuthToken = authToken.replace(/['"]+/g, '');

    // const headers = new Headers({
    //   // 'Cache-Control': 'no-cache',
    //   Accept: '*/*',
    //   // 'Content-Type': 'application/json',
    //   'X-Metabase-Session': mbAuthToken
    // });
    // const reportAuth = localStorage.getItem('token');
    const reportAuth = {
        // 'Cache-Control': 'no-cache',
        Accept: '*/*',
        // 'Content-Type': 'application/json',
        'X-Metabase-Session': mbAuthToken
      };
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);

    // const body = {
    //   parameters: [{
    //     name: 'Address',
    //     slug: 'address',
    //     id: 'b9a9e9a3',
    //     type: 'category',
    //     default: [`${address}`],
    //     filteringParameters: ['26ab7212']
    //   }]
    // };

    // const body = {
    //   params: {
    //     Address: [address]
    //   }
    // };

    const body = {
      resource: { dashboard: 2},
      params: {
        Address: [address]
      },
      exp: Math.round(Date.now() / 1000) + (30 * 60) // 30 minute expiration
    };
    console.log(body, headers);

    // const options = new RequestOptions({ headers });

    // return this.httpClient.put(requestURL, body, { headers }).map((response: Response) => {
    //   return response.json();
    // }).catch((response) => Observable.throw(response));

    // return this.httpClient.post(requestURL, body, {headers}).map( response => {
    //   // console.log(response);
    //   return response;
    // });

    // const url = requestURL + '?select_address=' + address;
    const url = requestURL;
    // reportAuth = localStorage.getItem('token');
    // const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);
    console.log('From PUT REQUEST', url, body);

    return this.httpClient.put(url, body, { headers })
      .map((response: Response) => {
        return response;
      });

  }
}
