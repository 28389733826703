<mat-form-field appearance="outline" [class]="ffClass" class="seamsel">
    <mat-label>{{placeholder}}</mat-label>
    <mat-select *ngIf="!useNativeControl" multiple [errorStateMatcher]="errorMatcher" [formControl]="selectedOptions" [placeholder]="placeholder" (selectionChange)="onSelChange()"
        [class]="mselClass" class="seamsel-sel">
        <input matInput (keyup)="onSearch($event.target.value)"
            (keydown)="$event.stopPropagation()" [placeholder]="placeholder" class="seamsel-sel-in">
        <mat-divider></mat-divider>
        <button mat-button (click)="onSelectAll()" value="all"
            class="seamsel-sel-allbtn">
            {{this.selectedOptions.value.sort().join() == this.filteredOptions.sort().join() || isFilteredSubOfSelected()?
            "Deselect" : "Select All"}}
        </button>
        <mat-divider></mat-divider>
        <mat-option *ngFor="let op of options" [value]="op" [class.hidden]="isThisOptionHidden(op)" [class.empty]="op==''" class="seamsel-sel-op">{{op!=''?op:'Empty'}}</mat-option>
    </mat-select>
    <ng-container *ngIf="useNativeControl">
        <!-- <input (keyup)="onSearch($event.target.value)" [placeholder]="placeholder" class="seamsel-sel-in" matInput> -->
        <select [errorStateMatcher]="errorMatcher" [formControl]="selectedOptions" [placeholder]="placeholder" (selectionChange)="onSelChange()" multiple matNativeControl class="seamsel-sel seamsel-sel-native">
            <option
                *ngIf="this.selectedOptions.value.sort().join() == this.filteredOptions.sort().join()"
                [value]="randId+'-searchable-msel'"
                (click)="nativeSelectAll('Deselect')"
            >
                Deselect
            </option>
            <option
                *ngIf="this.selectedOptions.value.sort().join() != this.filteredOptions.sort().join()"
                [value]="randId+'-searchable-msel'"
                (click)="nativeSelectAll('Select All')"
            >
            Select All
            </option>
            <option *ngFor="let op of options" [value]="op" [class.hidden]="isThisOptionHidden(op)" [class.empty]="op==''" class="seamsel-sel-op">{{op!=''?op:'Empty'}}</option>
        </select>
    </ng-container>
</mat-form-field>