import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { concat } from "rxjs";
import { AlertifyService } from "src/app/_services/alertify/alertify.service";
import { AuthService } from "src/app/_services/auth/auth.service";
import { SageApiService } from "src/app/_services/sageApi/sageApi.service";

export interface ChangeOrder {
  id: "";
  date: Date;
  SubmittedBy: string;
  BuilderName: string;
  dateOfChange: Date;
  reason: string;
  inspector: string;
  subdivision: string;
  city: string;
  cost: number;
  ItemCode: string;
  Quantity: number;
  action: string;
  ARDivisionNo: string;
  customerNo: string;
  customerName: string;
  Warehouse: string;
  Margin: number;
  itemCost: number;
  taxable: string;
  OverrideCost: string;

}

@Component({
  selector: "app-change-order",
  templateUrl: "./change-order.component.html",
  styleUrls: ["./change-order.component.css"],
})
export class ChangeOrderComponent implements OnInit {
  urlParams: URLSearchParams;

  overrideCost: boolean;
  itemList = [];
  subdivisionList = [];
  customerList = [];

  changeOrder: ChangeOrder = {
    id: "",
    date: new Date(),
    SubmittedBy: "",
    BuilderName: "",
    dateOfChange: new Date(),
    reason: "",
    inspector: "",
    subdivision: "",
    city: "",
    cost: 0,
    ItemCode: "",
    Quantity: 0,
    action: "",
    ARDivisionNo: "",
    customerNo: "",
    customerName: "",
    Warehouse: "",
    Margin: 0,
    itemCost: 0,
    taxable: "",
    OverrideCost: "N"
  };
displayCost:string;
  itemPrice: number;

  id = "";
  edit = false;
  sending: boolean;
  filledOut: boolean;
  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.checkForParams();
    this.getSubdivision();
    this.getCustomers();
    this.changeDetectorRef.detectChanges();
    this.changeOrder.SubmittedBy = this.authService.decodedToken.nameid;
  }

  override(){
    this.changeOrder.OverrideCost = (this.changeOrder.OverrideCost== "N") ? this.changeOrder.OverrideCost = "Y" : this.changeOrder.OverrideCost = "N";
  }

  checkForParams() {
    this.urlParams = new URLSearchParams(window.location.search);
    if (this.urlParams.has("id")) {
      const answer = this.urlParams.get("id");
      this.id = answer;
      this.edit = true;
      this.sageApi.pullReport("ChangeOrder?id=" + this.id).subscribe((resp) => {
        this.changeOrder = {
          id: resp.id,
          date: new Date(),
          SubmittedBy: this.authService.decodedToken.nameid,
          BuilderName: resp.BuilderName,
          dateOfChange: resp.DateOfChange,
          reason: resp.Reason,
          inspector: resp.Inspector,
          subdivision: resp.Subdivision,
          city: resp.City,
          cost: resp.Cost,
          customerName: resp.CustomerName,
          ItemCode: resp.ItemCode.trim(),
          Quantity: resp.Quantity,
          action: resp.Action.trim(),
          ARDivisionNo: resp.ARDivisionNo,
          customerNo: resp.CustomerNo,
          Warehouse: resp.Warehouse,
          Margin: resp.Margin,
          itemCost: resp.itemCost,
          taxable: resp.Taxable,
          OverrideCost: resp.OverrideCost
        };
        this.itemPrice = this.changeOrder.itemCost;
        this.displayCost = this.changeOrder.cost.toFixed(2)
        this.overrideCost = this.changeOrder.OverrideCost == "Y" ? true : false;
        this.loadItemListPrice();

console.log(this.changeOrder)
console.log(this.overrideCost)
      });
    }
  }
  Submit() {
    this.sending = true;
    const observables = [];
    let endpoint = "";
    if (this.edit == false) {
      endpoint = "ChangeOrder";
    } else {
      endpoint = "ChangeOrder/EditChangeOrder";
    }
    observables.push(this.sageApi.putRequest(endpoint, this.changeOrder));
    concat(...observables).subscribe(
      (response: any) => {
        this.alertify.success(response);
        console.log(response);
      },
      (err) => {
        console.log(err);
        this.alertify.error(err.error.Message);
        this.sending = false;
      },
      () => {
        this.clearChangeOrder();
        this.sending = false;
          this.router.navigate(["Builder/ChangeOrder"], {});

      }
    );
  }

  checkForDetails() {
    let blockSend = true;
    if ((this.changeOrder.ARDivisionNo != "" || this.changeOrder.city != "" || this.changeOrder.subdivision != "" ) && this.changeOrder.reason != "") {
      blockSend = false;
    }
    if(this.overrideCost == false){
      this.calculateCost();
    }
    return blockSend;

  }

  clearChangeOrder() {
    this.changeOrder = {
      id: "",
      date: new Date(),
      SubmittedBy: "",
      BuilderName: "",
      dateOfChange: new Date(),
      reason: "",
      inspector: "",
      subdivision: "",
      city: "",
      cost: 0,
      ItemCode: "",
      Quantity: 0,
      action: "",
      ARDivisionNo: "",
      customerNo: "",
      Warehouse: "",
      Margin: 0,
      itemCost: 0,
      customerName: "",
      taxable: "",
      OverrideCost: "N"
    };
    this.loadItemListPrice();
    this.getSubdivision();
    this.getCustomers();
  }

  wholeNumber() {
    let wholeNumber = Math.round(this.changeOrder.Quantity);
    this.changeOrder.Quantity = wholeNumber;
    this.calculateCost();
  }

  calculateCost() {
    let newCost;
    if (this.changeOrder.taxable == "Y") {
      newCost = this.itemPrice * this.changeOrder.Quantity + this.itemPrice * this.changeOrder.Quantity * 0.0825;
    } else {
      newCost = this.itemPrice * this.changeOrder.Quantity;
    }
    let marginAsPercent = this.changeOrder.Margin / 100;
    this.changeOrder.cost = (newCost * marginAsPercent) + newCost;
    this.displayCost = this.changeOrder.cost.toFixed(2);
  }

  changeCost(){
    this.changeOrder.cost = Number(this.displayCost)
  }


  loadItemListPrice() {
    this.itemList = [];
    this.sageApi
      .pullReport("ChangeOrder/Parts?Warehouse=" + this.changeOrder.Warehouse)
      .subscribe((rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const obj = {
              name: row.ItemCode + "-" + row.ItemCodeDesc,
              itemCode: row.ItemCode,
              averageCost: row.AverageCost,
              discountMarkUp: row.DiscountMarkup1,
              matPrice: row.MatPrice,
            };
            this.itemList.push(obj);
          });
        }
      });
  }
  getCustomers() {
    this.customerList = [];
    this.sageApi
      .pullReport("SalesOrderEntry/Customers")
      .subscribe((rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            if (row.CustomerName != "" || row.CustomerName != undefined) {
              const cust = {
                name:
                  row.ARDivisionNo +
                  "-" +
                  row.CustomerNo +
                  " " +
                  row.CustomerName,
                ARDivisionNo: row.ARDivisionNo,
                CustomerNo: row.CustomerNo,
                CustomerName: row.CustomerName,
              };
              this.customerList.push(cust);
            }
          });
        }
      });
  }
  selectCustomer(customerInfo) {
    this.changeOrder.ARDivisionNo = customerInfo.item.ARDivisionNo;
    (this.changeOrder.customerNo = customerInfo.item.CustomerNo),
      (this.changeOrder.BuilderName = customerInfo.item.CustomerName);
  }

  ItemCodeChange(event) {
    this.itemPrice = 0;
    this.changeOrder.ItemCode = event.item.itemCode;
    if (event.item.discountMarkUp != undefined) {
      this.itemPrice = event.item.discountMarkUp;
      this.changeOrder.cost = event.item.discountMarkUp * this.changeOrder.Quantity;
      this.changeOrder.itemCost = event.item.discountMarkUp;
      this.calculateCost();
    } else {
      this.itemPrice = event.item.averageCost;
      this.changeOrder.cost = event.item.averageCost * this.changeOrder.Quantity;
      this.changeOrder.itemCost = event.item.averageCost;
      this.calculateCost();
    }
  }
  onSubdivisionSelect(event) {
    this.changeOrder.subdivision = event.item.UDF_SUBDIVISION_CODE;
  }

  getSubdivision() {
    this.subdivisionList = [];
    this.sageApi.pullReport("SalesOrderEntry/Subdivisions").subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            this.subdivisionList.push(row);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
      }
    );
  }
}
