import { Component, OnInit, TemplateRef } from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { Router } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';
import { DatePipe } from '@angular/common';
import { log } from 'util';

@Component({
  selector: 'app-receiving-create',
  templateUrl: './receiving-create.component.html',
  styleUrls: ['./receiving-create.component.css'],
})
export class ReceivingCreateComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig>;
  modalTemplate: BsModalRef;
  warehouseList = [];
  warehouseSelected = '';
  purchaseOrderSearch: string;
  purchaseOrderNo = '';
  whsecode = '';
  whseIncorrect = true;
  purchaseOrderLoading = false;
  purchaseOrderLoaded = false;
  purchaseOrderListLoading = false;
  purchaseOrderList: {
    name: string;
    address: string;
    purchaseOrderNo: string;
    whse: string;
  }[] = [];
  purchaseOrderHeader = {
    purchaseOrderNo: '',
    invoiceNo: '',
    invoiceDate: new Date(),
    vendor: '',
    shippingAddress: '',
  };
  purchaseOrderLines: {
    itemCode: string;
    description: string;
    lineKey: string;
    qtyOrdered: number;
    qtyAvailable: number;
    qtyReceived: number;
    unitCost: number;
    warehouseCode: string
  }[] = [];

  invoiceNo = '';
  ediReceipt: boolean;
  badInvoiceDate: boolean;
  selectedInvoiceDate: Date;

  receiptLoading = false;
  whse003 = false;
  fileLoading: boolean;
  fileArray = [];
  fileList = [];

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.getPurchaseOrderList();
    this.bsConfig = Object.assign({}, { containerClass: 'theme-red' });
    this.badInvoiceDate = false;
    this.selectedInvoiceDate = new Date();
    this.selectWarehouse();
  }

  logDate(event, invoicedate) {
    const invoiceMonth = new Date(event).getMonth();
    const invoiceYear = new Date(event).getFullYear();
    const invoiceDay = new Date(event).getDate();
    const today = new Date();
    const todayMonth = today.getMonth();
    const todayYear = today.getFullYear();
    const todayDay = today.getDate();
    // const LastDayOfMonth = today.setDate(0);
    const LastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      0
    ).getDate();
    today.setMonth(today.getMonth() - 1);
    const LastMonth = today.getMonth();
    const GoodDate =
      (LastMonth === invoiceMonth && invoiceDay > LastDayOfMonth - 15) ||
      (invoiceYear === todayYear &&
        todayMonth === invoiceMonth &&
        todayDay >= invoiceDay);
    // console.log('Invoice date', invoicedate);
    // console.log('Invoice Month', invoiceMonth);
    // console.log('Invoice Year', invoiceYear);
    // console.log('Invoice day', invoiceDay);
    // console.log('Today Date', today);
    // console.log('Today Month', todayMonth);
    // console.log('Today Year', todayYear);
    // console.log('Today Day', todayDay);
    // console.log('Last Month', LastMonth);
    // console.log('Last day of last Month', LastDayOfMonth);
    // console.log('GoodDate', GoodDate);
    // console.log('last 15 of last month', (LastMonth === invoiceMonth && invoiceDay > LastDayOfMonth - 15));
    // console.log('not older than today', (invoiceYear === todayYear && todayMonth === invoiceMonth && todayDay >= invoiceDay));

    if (!GoodDate) {
      this.badInvoiceDate = true;
      console.log(this.selectedInvoiceDate);
      console.log(event);
      this.selectedInvoiceDate = invoicedate;
      this.alertify.warning(
        'Date outside current pay period. Please select a date within this Month on the last 15 days of last Month'
      );
      return;
    } else {
      this.badInvoiceDate = false;
      this.selectedInvoiceDate = new Date(event);
      this.purchaseOrderHeader.invoiceDate = this.selectedInvoiceDate;
    }
  }

  purchaseOrderSelected(event: TypeaheadMatch) {
    this.purchaseOrderNo = event.item.purchaseOrderNo;
    this.whsecode = event.item.whse;
    this.whse003 = event.item.whse === '003';
  }

  ediReceiptSelected(edi: boolean) {
    this.ediReceipt = edi;
  }

  getPurchaseOrderList() {
    this.purchaseOrderListLoading = true;
    this.sageApi.pullReport('ReceiptOfGoods/PurchaseOrder/List').subscribe(
      (purchaseOrders: Array<any>) => {
        if (Array.isArray(purchaseOrders)) {
          purchaseOrders.forEach((order) => {
            const obj = {
              name: order.PurchaseOrderNo + ' - ' + order.ShipToName,
              address: order.ShipToName,
              purchaseOrderNo: order.PurchaseOrderNo,
              whse: order.WarehouseCode,
            };
            this.purchaseOrderList.push(obj);
          });
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        console.log('Success');
        this.purchaseOrderListLoading = false;
      }
    );
  }

  checkIfPendingReceiptExists() {
    this.purchaseOrderLoading = true;
    this.sageApi
      .pullReport(
        'ReceiptOfGoods?PurchaseOrderNo=' +
          this.purchaseOrderNo +
          '&InvoiceNo=' +
          this.invoiceNo
      )
      .subscribe(
        (receipt) => {
          if (receipt) {
            console.log('Receipt Exists ', receipt);
            this.purchaseOrderLoading = false;
            this.router.navigate(['/Material/Receiving'], {
              queryParams: {
                PurchaseOrderNo: this.purchaseOrderNo,
                InvoiceNo: this.invoiceNo,
              },
            });
          } else {
            this.getPurchaseOrderLines();
          }
        },
        (err) => {
          console.log(err);
          this.purchaseOrderLoading = false;
          this.alertify.error(err.message);
        },
        () => {}
      );
  }

  getPurchaseOrderLines() {
    this.purchaseOrderLoading = true;
    this.purchaseOrderLines = [];
    this.sageApi
      .pullReport(
        'ReceiptOfGoods/PurchaseOrder?PurchaseOrderNo=' + this.purchaseOrderNo
      )
      .subscribe(
        (Lines: Array<any>) => {
          if (Array.isArray(Lines)) {
            Lines.forEach((line, index) => {
              if (index === 0) {
                const today = new Date();
                const todayTimeZone = new Date(
                  today.getTime() - today.getTimezoneOffset() * 60000
                );

                this.purchaseOrderHeader.purchaseOrderNo = line.PurchaseOrderNo;
                this.purchaseOrderHeader.invoiceNo = this.invoiceNo;
                this.purchaseOrderHeader.shippingAddress = line.ShipToName;
                this.purchaseOrderHeader.vendor = line.VendorNo;
                this.purchaseOrderHeader.invoiceDate = todayTimeZone;
                this.selectedInvoiceDate = this.purchaseOrderHeader.invoiceDate;
                console.log(this.purchaseOrderHeader);
              }
              const obj = {
                itemCode: line.ItemCode,
                description: line.ItemCodeDesc,
                lineKey: line.LineKey,
                qtyOrdered: line.QuantityOrdered,
                qtyAvailable: line.QuantityAvailable,
                qtyReceived: 0,
                unitCost: line.UnitCost,
                qtyActuallyReceived: line.QuantityReceived,
                warehouseCode: line.WarehouseCode
              };
              this.purchaseOrderLines.push(obj);
            });
          }
        },
        (err) => {
          console.log(err);
          this.purchaseOrderLoading = false;
          this.alertify.error(err.message);
        },
        () => {
          this.purchaseOrderLoading = false;
          this.purchaseOrderLoaded = true;
          this.alertify.success(
            this.purchaseOrderHeader.purchaseOrderNo + ' loaded successfully!'
          );
          console.log(this.purchaseOrderLines);
        }
      );
  }

  itemsOverReceived() {
    // return this.purchaseOrderLines.some(lines => lines.qtyReceived > lines.qtyAvailable);
    // INFO: Allowing Overreceiving of Items
    return false;
  }

  postCreateReceiptOfGoods() {
    this.receiptLoading = true;
    const observables = [];
    // const invoiceDateISO = new Date(
    //   this.purchaseOrderHeader.invoiceDate.getTime() - (this.purchaseOrderHeader.invoiceDate.getTimezoneOffset() * 60000))
    //   .toISOString().split('T')[0].replace(/-/g, '');
    const invoiceDateISO = this.datePipe.transform(
      this.purchaseOrderHeader.invoiceDate,
      'yyyy-MM-dd'
    );

    const body = {
      Username: this.authService.decodedToken.nameid,
      Password:
        this.authService.decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
        ],
      PurchaseOrderNo: this.purchaseOrderHeader.purchaseOrderNo,
      InvoiceNo: this.purchaseOrderHeader.invoiceNo,
      InvoiceDate: invoiceDateISO,
      EdiReceipt: this.ediReceipt ? 'Y' : 'N',
      Lines: [],
      OverReceivedLines: [],
    };
    this.purchaseOrderLines.forEach((line) => {
      if (line.qtyReceived > line.qtyAvailable) {
        const overReceivedItem = {
          ItemCode: line.itemCode,
          OrderLineKey: line.lineKey,
          QuantityReceived: line.qtyReceived - line.qtyAvailable,
          UnitCost: line.unitCost,
        };
        body.OverReceivedLines.push(overReceivedItem);

        const fullyReceivedItem = {
          ItemCode: line.itemCode,
          LineKey: line.lineKey,
          QuantityReceived: line.qtyAvailable,
          UnitCost: line.unitCost,
        };
        body.Lines.push(fullyReceivedItem);
      } else if (line.qtyReceived > 0) {
        const obj = {
          ItemCode: line.itemCode,
          LineKey: line.lineKey,
          QuantityReceived: line.qtyReceived,
          UnitCost: line.unitCost,
        };
        body.Lines.push(obj);
      }
    });

    if (body.Lines.length === 0) {
      this.alertify.error('No Items Received');
      this.receiptLoading = false;
      return;
    }

    // console.log(body);
    observables.push(this.sageApi.postRequest('ReceiptOfGoods/App', body));

    concat(...observables).subscribe(
      (response) => {
        console.log('post request', response);
      },
      (err) => {
        console.log(err);
        this.receiptLoading = false;
        this.alertify.error('Error - ' + err.message);
      },
      () => {
        this.alertify.success(
          this.purchaseOrderHeader.purchaseOrderNo +
            ' receipt successfully created!'
        );
        this.resetData();
        this.router.navigate(['/Material/Receiving']);
      }
    );
  }

  cancelReceipt() {
    this.resetData();
    this.alertify.message('Receipt Cancelled');
  }

  openModal(template: TemplateRef<any>) {
    this.modalTemplate = this.modalService.show(template);
  }

  closeModal() {
    this.modalTemplate.hide();
  }

  fileNames() {
    const route = `ReceiptOfGoods/file?purchaseOrderNo=${this.purchaseOrderHeader.purchaseOrderNo}`;
    this.fileList = [];
    this.sageApi.pullReport(route).subscribe(
      (response: any) => {
        console.log(response, response.split(', \\\\gp-nas2\\SO_WT_Files'));
        const splitFiles = response.split(', \\\\gp-nas2\\SO_WT_Files');
        splitFiles.forEach((file) => {
          console.log(file);
          if (file) {
            this.fileList.push({
              name: file.split(
                this.purchaseOrderHeader.purchaseOrderNo + '\\'
              )[1],
            });
          }
        });
      },
      (err) => {
        console.log(err);
        this.alertify.error(err.message);
      },
      () => {
        console.log(this.fileList);
      }
    );
  }

  fileDownload(fileName: string) {
    const route = `ReceiptOfGoods/file/download?purchaseOrderNo=${this.purchaseOrderHeader.purchaseOrderNo}&fileName=${fileName}`;

    this.sageApi.getFile(route).subscribe(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        if (fileName) {
          downloadLink.setAttribute('download', fileName);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
      },
      (err) => {
        console.log(err);
        this.alertify.error(err.message);
      }
    );
  }

  fileChosen(event) {
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        const obj = {
          name: files[index].name,
          data: files[index],
        };

        this.fileArray = [obj];
      }
    }
  }

  fileUpload() {
    this.fileLoading = true;
    // tslint:disable-next-line:max-line-length
    const route = `ReceiptOfGoods/file/upload?purchaseOrderNo=${this.purchaseOrderHeader.purchaseOrderNo}&invoiceNo=${this.purchaseOrderHeader.invoiceNo}`;
    const sentFiles = [];

    this.fileArray.forEach((file) => {
      sentFiles.push(file.data);
    });

    console.log(sentFiles);

    this.sageApi.uploadFile(route, sentFiles).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
        this.fileLoading = false;
      },
      () => {
        this.resetUpload();
        this.alertify.success('File Loaded');
      }
    );
  }

  resetUpload() {
    this.fileArray = [];
    this.fileLoading = false;
  }

  resetData() {
    this.warehouseSelected = '';
    this.whseIncorrect = true;

    this.badInvoiceDate = false;
    this.selectedInvoiceDate = new Date();
    const today = new Date();
    const todayTimeZone = new Date(
      today.getTime() - today.getTimezoneOffset() * 60000
    );
    this.purchaseOrderHeader = {
      purchaseOrderNo: '',
      invoiceNo: '',
      invoiceDate: todayTimeZone,
      vendor: '',
      shippingAddress: '',
    };
    this.purchaseOrderLines = [];
    this.purchaseOrderSearch = '';
    this.purchaseOrderNo = '';
    this.invoiceNo = '';
    this.ediReceipt = false;
    this.purchaseOrderLoaded = false;
    this.receiptLoading = false;
  }

  selectWarehouse() {
    this.warehouseList = [];
    this.sageApi.pullReport(`TransactionEntry/Warehouses`).subscribe(
      (items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach((item) => {
            item.WhseDesc = item.WarehouseCode + '-' + item.WarehouseDesc;
            this.warehouseList.push(item);
          });
          console.log('Warehouses', this.warehouseList);
        }
      },
      (err) => {
        this.alertify.error('Error loading ' + err.error.Message);
        console.log(err);
      }
    );
  }

  checkWarehouse(event: TypeaheadMatch) {
    var errorResp = "";
    this.purchaseOrderLines.forEach(x => {
      if(x.warehouseCode != ""){

        if(x.warehouseCode != this.whsecode){
          errorResp += x.itemCode + ", ";
        }
      }
    });
      if(errorResp != ""){
        this.whseIncorrect = true;
     return   this.alertify.error("Item codes: "+errorResp + " are designated for a different warehouse than the PO header specifies. Please update the warehouse in Sage before continuing.");
      }

    this.warehouseSelected = event.item.WarehouseCode;
    console.log(this.warehouseSelected);
    if (this.warehouseSelected === this.whsecode) {
      this.whseIncorrect = false;
    } else {
      this.alertify.error(
        'This PO is intended for ' +
          this.whsecode +
          '. You will not be able to receive for ' +
          this.warehouseSelected +
          '.'
      );
      console.log(this.whsecode);
      this.warehouseSelected = '';
      this.whseIncorrect = true;
    }

  }
  checkForErrors() {
    if (this.whseIncorrect === true) {
      this.alertify.warning('Please select the warehouse to receive to.');
    }
  }
}
