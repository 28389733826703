import {  Component,  OnInit,} from "@angular/core";
import { SageApiService } from "../../../_services/sageApi/sageApi.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { setDay } from "ngx-bootstrap/chronos/utils/date-setters";
@Component({
  selector: "app-warehouse-transfer-history",
  templateUrl: "./warehouse-transfer-history.component.html",
  styleUrls: ["./warehouse-transfer-history.component.css"],
})
export class WarehouseTransferHistoryComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig>;
  transferHistory: {
    BatchNo: string;
    ItemCode: string;
    WarehouseFrom: string;
    WarehouseTo: string;
    CreatedBy: string;
    DateCreated: Date;
    Status: string;
  }[] = [];
  loadingHistory: boolean;
  IntStatus = "";
  BatchNumFilter = "";
  ItemCodeFilter = "";
  CreatedByFilter = "";
  LastSentDate: Date;
  sortedTransferHistory: {
    BatchNo: string;
    ItemCode: string;
    WarehouseFrom: string;
    WarehouseTo: string;
    CreatedBy: string;
    DateCreated: Date;
    Status: string;
  }[] = [];
  constructor(
    private sageApi: SageApiService,
  ) {}

  ngOnInit(): void {
    this.IntStatus = "All";
    var date = new Date();
    this.LastSentDate = new Date(new Date().setDate(date.getDate() - 1));
    this.getTransferHistory();
    this.sortedTransferHistory = this.transferHistory.sort((a, b) =>
      a.Status.toLocaleLowerCase() > b.Status.toLocaleLowerCase()
        ? 1
        : a.Status.toLocaleLowerCase() < b.Status.toLocaleLowerCase()
        ? -1
        : 0
    );
  }
  changeTextColorIfError(step: any) {
    if (step.Status === "Error") {
      //background color == danger
      return "red";
    }
  }
  sortReceiptHistByError() {
    this.sortedTransferHistory = this.transferHistory.sort((a, b) =>
      a.Status.toLocaleLowerCase() > b.Status.toLocaleLowerCase()
        ? 1
        : a.Status.toLocaleLowerCase() < b.Status.toLocaleLowerCase()
        ? -1
        : 0
    );
  }

  getTransferHistory() {
    this.transferHistory = [];
    this.loadingHistory = true;
    this.sageApi
      .pullReport("WarehouseTransfer/History")
      .subscribe((items: Array<any>) => {
        items.forEach((item) => {
          const obj = {
            BatchNo: item.BatchNo,
            ItemCode: item.ItemCode,
            Quantity: item.TransQty,
            WarehouseFrom: item.WhseFrom,
            WarehouseTo: item.WhseTo,
            CreatedBy: item.CreatedBy,
            DateCreated: item.CreatedDate,
            Status: item.SubmitState,
          };
          this.transferHistory.push(obj);
        });
        this.loadingHistory = false;
      });
    console.log(this.transferHistory);
  }

  historyFilters(step: any) {
    const setDate = new Date(step.DateCreated).setHours(0, 0, 0, 0);
    const filDate = new Date(this.LastSentDate).setHours(0, 0, 0, 0);

    return (
      (step.Status === this.IntStatus || this.IntStatus === "All") &&
      setDate >= filDate &&
      (this.CreatedByFilter !== ""
        ? step.CreatedBy.toLocaleLowerCase().includes(
            this.CreatedByFilter.toLocaleLowerCase()
          )
        : true) &&
      (this.BatchNumFilter !== ""
        ? step.BatchNo.toLocaleLowerCase().includes(
            this.BatchNumFilter.toLocaleLowerCase()
          )
        : true) &&
        (this.ItemCodeFilter !== ""
        ? step.ItemCode.toLocaleLowerCase().includes(
            this.ItemCodeFilter.toLocaleLowerCase()
          )
        : true)
    );
  }
}
