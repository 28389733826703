<h1 mat-dialog-title>Add New Subdivision</h1>
<div mat-dialog-content class="form">
  <mat-form-field>
    <mat-label>Subdivision</mat-label>
    <input matInput [(ngModel)]="subdivison">
  </mat-form-field>
  <mat-form-field>
    <mat-label>City</mat-label>
    <input matInput [(ngModel)]="city">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Super</mat-label>
    <mat-select [(ngModel)]="Super">
      <ng-container *ngFor="let super of superList">
        <mat-option [value]="super.UDF_LEADMAN">{{ super.Leadman | titlecase }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <mat-checkbox [value]="gas" color="primary" [(ngModel)]="gas">Gas Inspection</mat-checkbox>
  <!-- <mat-checkbox [value]="cityCrew" color="primary" [(ngModel)]="cityCrew">City Crew Required</mat-checkbox> -->
  <mat-checkbox [value]="cityInspection" color="primary" [(ngModel)]="cityInspection">City Inspection
    Required</mat-checkbox>
  <mat-checkbox [value]="tnrcc" color="primary" [(ngModel)]="tnrcc">TNRCC Required</mat-checkbox>

</div>
<div mat-dialog-actions>
  <button mat-button color="warn" mat-flat-button (click)="onNoClick()">Exit</button>

  <button mat-button color="primary" mat-flat-button (click)="checkData()">Create Subdivision</button>
</div>
