import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import QuoteExclusionEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteExclusionEditableInterface';
import QuoteExclusionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteExclusionDtoInterface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import { QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';

@Component({
  selector: 'app-qexclusion',
  templateUrl: './QuoteExclusionComponent.html',
  styleUrls: ['./QuoteExclusionComponent.css', '../QuotingTheme.scss']
})
export class QuoteExclusionComponent implements OnInit{

  @Input() exclusion: QuoteExclusionEditableInterface;

  constructor(
    public api: SageApiService,
    public screenSize: ScreenSizeService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    private qe: QuoteEditableService
  ){
  }

  ngOnInit(): void {
    
  }

  resetControls(){
    this.exclusion.nameControl.setValue(this.exclusion.data.QuoteExclusion_Name);
    this.exclusion.descControl.setValue(this.exclusion.data.QuoteExclusion_Desc);
  }

  nameInput(e: InputEvent){
    const value = (e.target as HTMLInputElement).value;
    if(value.length > 100){
      const newVal = value.slice(0, 100);
      this.exclusion.nameControl.setValue(newVal);
      (e.target as HTMLInputElement).value = newVal;
    }
  }

  isSaveDisabled(){
    const different = 
      this.exclusion.nameControl.value !== this.exclusion.data.QuoteExclusion_Name 
      || this.exclusion.descControl.value !== this.exclusion.data.QuoteExclusion_Desc;

    const valid = 
      this.exclusion.nameControl.valid 
      && this.exclusion.descControl.valid;
    
    return !different || !valid;
  }

  nameBlur(){
    if(this.exclusion.nameControl.value === ''){
      this.exclusion.nameControl.setValue('Untitled');
    }
  }

  saveChanges(){
    this.qe.changeQuoteExclusion({quoteExclusion: this.exclusion});
  }

  deleteExclusion(){
    this.qe.destroyQuoteExclusion({quoteExclusion: this.exclusion})
  }

}