import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import KitRegions from '../KitRegions';
import KitPhases from '../KitPartPhases';
import { timeStamp } from 'console';
import BuildLocationDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/BuildLocationDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';

@Component({
  selector: 'app-kit-sidepanel',
  templateUrl: './KitSidepanelComponent.html',
  styleUrls: ['./KitSidepanelComponent.css', '../QuotingTheme.scss']
})
export class KitSidepanelComponent implements OnInit, OnDestroy, OnChanges {

  @Input() open: boolean = false;

  @Input() title = 'Untitled';
  @Input() description = '';
  @Input() saving: boolean = false;

  @Input() buildLocation = '';
  @Input() region = '';
  
  @Input() partTotal = 0;
  @Input() costTotal = 0;

  isSidebarOpen = false;

  @ViewChild('titleInput', {static: true}) public titleInput: ElementRef;
  
  titleControl = new FormControl(this.title, [Validators.required]);
  descriptionControl = new FormControl(this.description, [Validators.required, Validators.maxLength(255)]);
  
  regionControl = new FormControl(this.region, [Validators.required]);

  buildLocationControl = new FormControl(this.buildLocation, [Validators.required]);
  
  heightOffset = 0;
  
  kitRegions = KitRegions;
  
  buildLocations: BuildLocationDtoInterface[] = [];

  @Output() onChange = new EventEmitter<{
    title: string;
    region: string;
    description: string;
    buildLocation: BuildLocationDtoInterface|null;
  }>();

  @Output() onUpdateParts = new EventEmitter();

  @Output() openChange = new EventEmitter<boolean>();
  
  constructor(
    public screenSize: ScreenSizeService,
    public cdr: ChangeDetectorRef,
    private api: SageApiService,
  ) {}
  
  ngOnInit() {
    this.buildLocationControl.setValue(this.buildLocation);
    this.api.pullReport(
      'buildlocations', 
      {
        matchCriteria: [], // Just use the default criteria 
        exp: 60000*5 // 5min expiration
      }
    ).subscribe((response) => {
      this.buildLocations = response;
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.resetControls();
  }

  resetControls(){
    this.titleControl = new FormControl(this.title, [Validators.required]);
    this.regionControl = new FormControl(this.region, [Validators.required]);
    this.descriptionControl = new FormControl(this.description, [Validators.maxLength(255)]);
  }

  titleBlur(event){
    if(this.titleControl.value.trim() == ''){
      this.titleControl.setValue('Untitled');
    }else{
      this.titleControl.setValue(this.titleControl.value.trim());
    }
  }

  saveChanges(){
    this.title = this.titleControl.value;
    this.region = this.regionControl.value;
    this.buildLocation = this.buildLocationControl.value;

    const newbl: BuildLocationDtoInterface|null = this.buildLocations.find((bl) => bl?.BuildLocation_Code == this.buildLocationControl.value);

    this.onChange.emit({
      title: this.titleControl.value, 
      region: this.regionControl.value,
      description: this.descriptionControl.value,
      buildLocation: newbl
    });
  }

  cancelChanges(){
    this.titleControl.setValue(this.title);
    this.regionControl.setValue(this.region);
  }

  updatePrices(){
    this.onUpdateParts.emit();
  }

  openChangeHandler(newOpen: boolean){
    this.openChange.emit(newOpen);
  }

  getBuildLocationOptions(){
    return this.buildLocations.map((bl)=>bl?.BuildLocation_Code);
  }

}