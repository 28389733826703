<div class="container pb-5">
  <div class="card my-5 pb-2 shadow-lg text-center" >
<h1>Create New User</h1>
<div class="NewUserForm">
  <form class="NewUserForm">
    <mat-form-field appearance="outline">
      <mat-label>Username</mat-label>
      <input matInput type="text" appearance="outline"
      name="username" placeholder="username" [(ngModel)]="userinformation.username"
      [typeahead]="allUsersWithNoAccount"
      autocomplete="off" (typeaheadOnSelect)="selectUser($event)">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Role</mat-label>
      <input matInput type="text"
      name="role" placeholder="Role" [(ngModel)]="userinformation.role"
      [typeahead]="allRoles" (typeaheadOnBlur)="confirmRole()"
      autocomplete="off" (typeaheadOnSelect)="selectRole($event)">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Super Name</mat-label>
      <input matInput type="text" [(ngModel)]="userinformation.superName"
      [typeahead]="allSupers" typeaheadOptionField="nickname" (typeaheadOnBlur)="confirmSuper()"
      name="superName" placeholder="Super Name"  (typeaheadOnSelect)="selectRole($event)"
      >
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Tech Code</mat-label>
    <input matInput type="text" [(ngModel)]="userinformation.techCode"
    [typeahead]="allTechs" typeaheadOptionField="name"
     autocomplete="off"
    (typeaheadOnSelect)="selectTech($event)"
    name="techCode" placeholder="techCode"
    >
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Job Title</mat-label>
    <input matInput type="text" [(ngModel)]="userinformation.jobTitle"
    [typeahead]="jobDepartments"
    name="jobTitle" placeholder="Job Title"
    >
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Job Department</mat-label>
    <input matInput type="text" [(ngModel)]="userinformation.jobDepartment"
    [typeahead]="jobTitlesForRole"
    name="jobDepartment" placeholder="Job Department"
    >
  </mat-form-field>
  <button type="submit" [disabled]="checkForm()"
  (click)="submitForm()" class="btn btn-primary">Submit</button>
</form>
</div>


<!-- <hr>
<h1>Edit Existing Users</h1>
<div>
<form>
<mat-form-field appearance="outline">
  <mat-label>Username</mat-label>
  <input matInput type="text" appearance="outline"
   name="username" placeholder="username"
   [(ngModel)]="username"
   [typeahead]="allUsers" typeaheadOptionField="username"
   autocomplete="off" (typeaheadOnSelect)="selectUser($event)">
</mat-form-field>
<mat-form-field appearance="outline">
  <mat-label>Role</mat-label>
  <input matInput type="text"
  name="role" placeholder="username"
  [(ngModel)]="role"
  [typeahead]="allRoles"
  autocomplete="off" (typeaheadOnSelect)="selectUser($event)">
</mat-form-field>
<mat-form-field appearance="outline">
  <mat-label>Job Title</mat-label>
  <input matInput type="text"
  name="jobTitle" placeholder="jobTitle"
  [(ngModel)]="jobTitle">
</mat-form-field>
</form>
</div> -->
  </div>
</div>
