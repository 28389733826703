    <div class="container pb-5">
      <div class="row">
        <div class="w-100">
          <div class="pt-5 pb-5" id="request-container">
            <div class="card p-2 shadow-lg">
              <div class="row px-3 mb-2">
                <div class="col-12">
                  <h3>Current Lead Times</h3>
                </div>
                <ng-container *ngFor="let leadTime of phaseLeadTimes">
                  <div class="col-4 col-md-2">{{ leadTime.name | titlecase }}: {{ leadTime.leadTime }} {{ leadTime.leadTime === 1 ? 'Day' : 'Days' }}</div>
                </ng-container>
              </div>

              <ng-container *ngFor="let date of scheduleConfirmations | keyvalue; let dateIndex = index; let isDateFirst = first">
                <div class="card-header bg-primary border-0 py-1 rounded" [ngClass]="!isDateFirst ? 'mt-2' : ''">
                  <div class="row">
                      <div class="col-10 offset-1 text-center text-light pt-2">
                          <ng-container *ngIf="isDateFirst">
                            <strong>Current Schedule Confirmations ({{ date.key | date}})</strong>
                          </ng-container>
                          <ng-container *ngIf="!isDateFirst">
                            <strong>Upcoming Schedule Confirmations ({{ date.key | date}})</strong>
                          </ng-container>
                      </div>
                      <div class="col-1 text-center text-light pr-1">
                        <a class="btn" (click)="showTickets(date.key)">
                          <i class="fas fa-ellipsis-h" *ngIf="!date.value.showTickets"></i>
                          <i class="fas fa-ellipsis-v" *ngIf="date.value.showTickets"></i>
                        </a>
                      </div>
                  </div>
                </div>
    
                <ul class="list-group" *ngIf="date.value.showTickets">
                  <ng-container *ngFor="let subdivision of date.value.subdivisions | keyvalue; let subdivisionIndex = index;">
                    <li class="list-group-item col-sm-12 border-dark p-0 pl-1 text-sm font-weight-bold bg-dark text-light">
                      {{ subdivision.key }}
                    </li> 
                    <ng-container *ngFor="let ticket of subdivision.value; let ticketIndex = index">
                      <li class="list-group-item py-1">
                        <ul class="list-group list-group-horizontal">
                          <li class="list-group-item col-5 border-0 p-0 pt-2 text-sm font-weight-bold">{{ticket.address | titlecase}}</li>
                          <li class="list-group-item col-2 border-0 p-0 pt-2 text-sm">{{ticket.phase | titlecase}}</li>
                          <li class="list-group-item col-3 border-0 p-0 pt-2 text-sm">{{ticket.status | titlecase}}</li>
                          <li class="list-group-item col-2 border-0 p-0 text-sm text-right" [ngClass]="{ 'pt-2': ticket.updated }">
                            <ng-container *ngIf="!(nextDay.slice(0, 10) === date.key.slice(0, 10) && currentHour >= 15)">
                              <a class="btn" *ngIf="!ticket.updated" (click)="openModal(editConfirmation, date.key, subdivision.key, ticketIndex)">
                                <i class="fas fa-edit"></i>
                              </a>
                            </ng-container>
                            <span *ngIf="ticket.updated" class="badge badge-pill badge-success">Updated</span>
                          </li>
                        </ul>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Single Confirmation -->
    <ng-template #editConfirmation>
      <div class="container shadow-lg" id="initial slide" *ngIf="editSlideInitial">
        <div class="row p-2">
          <div class="col-12 text-right mb-3">
          <button type="button" class="close" (click)="closeModal()">
            <span><i class="fas fa-window-close"></i></span>
          </button>
          </div>
        </div>
  
        <div class="row">
          <div class="col-12 text-center">
            <h2><strong>Is the {{ scheduleConfirmations[editDateKey].subdivisions[editSubdivisionKey][editTicketIndex].phase | titlecase }} for <br> {{ scheduleConfirmations[editDateKey].subdivisions[editSubdivisionKey][editTicketIndex].address }} ready?</strong></h2>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center mt-3 mb-3">
            <button type="button" class="btn btn-secondary btn-lg mr-2" (click)="editSlide('reschedule')">Reschedule</button>
            <button type="button" class="btn btn-primary btn-lg ml-2" (click)="editSlide('terms')">Ready</button>
          </div>
        </div>
      </div>

      <div class="container" id="term slide" *ngIf="editSlideTerms">
        <div class="row p-2">
          <div class="col-12 text-right mb-3">
          <button type="button" class="close" (click)="closeModal()">
            <span><i class="fas fa-window-close"></i></span>
          </button>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <h2><strong>{{ scheduleConfirmations[editDateKey].subdivisions[editSubdivisionKey][editTicketIndex].phase | titlecase }} Terms</strong></h2>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" *ngFor="let term of phaseTerms[editTermsIndex]?.terms">
                {{ term.name }} <br>
                <small>{{ term.description }}</small>
              </li>
            </ul>
          </div>
        </div>

        <div class="row mx-3">
          <div class="col-12 mb-2">
            <button type="button" class="btn btn-info float-right" (click)="showTicketNotes()">Add Notes</button>
          </div>
          <div class="col-12 mb-2" *ngIf="scheduleConfirmations[editDateKey].subdivisions[editSubdivisionKey][editTicketIndex].showNotes">
            <textarea class="form-control" name="notes" [(ngModel)]="scheduleConfirmations[editDateKey].subdivisions[editSubdivisionKey][editTicketIndex].notes" placeholder="Enter Notes about Terms or Misc. Notes"></textarea>
          </div>
        </div>

        <div class="row mx-3 pt-2 border border-1">
          <div class="col-12">
            <p>By selecting agree, you are assuring the site is ready in accordance with the requirements listed above.  Additionally, you accept that a fee of $250.00 per instance will be charged should the site not be ready.</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center mt-3 mb-3">
            <button type="button" class="btn btn-secondary btn-lg mr-2" (click)="editSlide('initial')">Cancel</button>
            <button type="button" class="btn btn-primary btn-lg ml-2" (click)="updateConfirmation('ready')">I Agree</button>
          </div>
        </div>
      </div>

      <div class="container" id="reschedule slide" *ngIf="editSlideReschedule">
        <div class="row p-2">
          <div class="col-12 text-right mb-3">
          <button type="button" class="close" (click)="closeModal()">
            <span><i class="fas fa-window-close"></i></span>
          </button>
          </div>
        </div>

        <div class="row" *ngIf="scheduleConfirmations[editDateKey].subdivisions[editSubdivisionKey][editTicketIndex].phase !== 'trim'">
          <div class="col-12 text-center">
            <h2><strong>The {{ scheduleConfirmations[editDateKey].subdivisions[editSubdivisionKey][editTicketIndex].phase | titlecase }} for <br> <u>{{ scheduleConfirmations[editDateKey].subdivisions[editSubdivisionKey][editTicketIndex].address }}</u> will be rescheduled for our next available date.</strong></h2>
            <p class="lead">The current {{ scheduleConfirmations[editDateKey].subdivisions[editSubdivisionKey][editTicketIndex].phase | titlecase }} lead time is {{ phaseLeadTimes[editLeadTimeIndex].leadTime }} Days</p>
          </div>
        </div>

        <div class="row" *ngIf="scheduleConfirmations[editDateKey].subdivisions[editSubdivisionKey][editTicketIndex].phase === 'trim'">
          <div class="col-12 text-center">
            <h2><strong>Please select a schedule date for the {{ scheduleConfirmations[editDateKey].subdivisions[editSubdivisionKey][editTicketIndex].phase | titlecase }} at <br> <u>{{ scheduleConfirmations[editDateKey].subdivisions[editSubdivisionKey][editTicketIndex].address }}</u></strong></h2>
            <div class="form-group px-5">
              <input type="text" placeholder="Click to choose a date" class="form-control" bsDatepicker [bsConfig]="bsConfig" [(ngModel)]="editRescheduleDate">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center mt-3 mb-3">
            <button type="button" class="btn btn-secondary btn-lg mr-2" (click)="editSlide('initial')">Cancel</button>
            <button type="button" class="btn btn-primary btn-lg ml-2" (click)="updateConfirmation('reschedule')" [disabled]="editRescheduleDate === '' && scheduleConfirmations[editDateKey].subdivisions[editSubdivisionKey][editTicketIndex].phase === 'trim'">Request</button>
          </div>
        </div>
      </div>
    </ng-template>
