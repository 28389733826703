<div [class.split-mobile]="isMobile" class="split">
  <mat-card class="split-card">
    <mat-card-title class="split-card-title">
      Pay Punch Items
      <div *ngIf="payLineItemsLoading" class="split-card-title-spinner">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
    </mat-card-title>
    <mat-card-content>
      <div class="split-card-controls">
        
        <mat-form-field appearance="outline" class="split-card-controls-ff">
          <mat-select  multiple [formControl]="payLineItemsTechControl" placeholder="Technician(s)" [disabled]="payLineItemsLoading" (selectionChange)="setPayLineItemsPayDateSearchable()" class="split-card-controls-item split-card-controls-techsel">
            <input matInput (keyup)="onPayLineItemTechSearch($event.target.value, payLineItems)" (keydown)="$event.stopPropagation()" placeholder="Techname" class="split-card-controls-techsel-in">
            <mat-divider></mat-divider>
            <button mat-button (click)="onPayLineItemTechSelectAll()" value="all" class="split-card-controls-techsel-all">
              {{this.payLineItemsTechControl.value.sort().join() == this.payLineItemsTechs.sort().join()? "Deselect" : "All Techs"}}
            </button>
            <mat-divider></mat-divider>
            <mat-option *ngFor="let tech of payLineItemsTechs" [value]="tech">{{tech}}</mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="outline" class="split-card-controls-ff">
          <mat-label>Address</mat-label>
          <input matInput type="text" [(ngModel)]="payLineItemsAddressControl" [disabled]="payLineItemsLoading" (input)="setPayLineItemsPayDateSearchable()" class="split-card-controls-item split-card-controls-address"/>
        </mat-form-field>
        
        <mat-form-field appearance="outline" class="split-card-controls-ff">
          <mat-label>Pay Date</mat-label>
          <mat-date-range-input [formGroup]="payLineItemsPayDateContrl" [rangePicker]="picker" [min]="payLineItemsPayDateContrlMin" [max]="payLineItemsPayDateContrlMax" [disabled]="payLineItemsLoading">
            <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="onPayLineItemsPayDate($event)">
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onPayLineItemsPayDate($event)">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker [disabled]="payLineItemsLoading" class="split-card-controls-item split-card-controls-address"></mat-date-range-picker>
        </mat-form-field>
  
        <button (click)="onPayLineItemsFilterSearch()" mat-raised-button color="accent" extended [disabled]="!payLineItemsSearchContrl" class="split-card-controls-item split-card-controls-search">Search</button>
  
      </div>
  
      <div class="split-card-data">
  
        <div class="split-card-data-ctn">
          <table mat-table [dataSource]="payLineItemsData" matSort class="split-card-data-ctn-table">
  
            <ng-container matColumnDef="SalesOrderNo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> SO# </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td sm">
                <span class="split-card-data-ctn-table-td-con">
                  {{element.SalesOrderNo}}
                </span>
              </td>
            </ng-container>
            
            <ng-container matColumnDef="JT158_WTNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> WT# </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td xs"> 
                <span class="split-card-data-ctn-table-td-con">
                  {{element.JT158_WTNumber}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="ShipToName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td lg">
                <span class="split-card-data-ctn-table-td-con">
                  {{element.ShipToName}} 
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="UDF_PAY_DATE">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> PayDate </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td md"> 
                <span class="split-card-data-ctn-table-td-con">
                  {{element.UDF_PAY_DATE.slice(0, 10)}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="ItemCodeDesc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td xl"> 
                <span class="split-card-data-ctn-table-td-con">
                  {{element.ItemCodeDesc}} 
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="UDF_SUB_VENDOR">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> SubVendor </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td sm"> 
                <span class="split-card-data-ctn-table-td-con">
                  {{element.UDF_SUB_VENDOR}} 
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="TechName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> TechName </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td lg"> 
                <span class="split-card-data-ctn-table-td-con">
                  {{element.TechName}} 
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="TechSubTotal">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> TechSubTtl </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td sm"> 
                <span class="split-card-data-ctn-table-td-con">
                  {{element.TechSubTotal!=null? '$'+element.TechSubTotal : ''}} 
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="CrewLeadName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CrewLeadName </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td lg">
                <span class="split-card-data-ctn-table-td-con">
                  {{element.CrewLeadName}} 
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="CrewLeadTotal">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CrewLeadTtl </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td sm">
                <span class="split-card-data-ctn-table-td-con">
                  {{element.CrewLeadTotal!=null? '$'+element.CrewLeadTotal : ''}} 
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="Crew1Name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Crew1Name </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td lg">
                <span class="split-card-data-ctn-table-td-con">
                  {{element.Crew1Name}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="Crew1Ttl">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Crew1Ttl </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td sm">
                <span class="split-card-data-ctn-table-td-con">
                  {{element.Crew1Ttl!=null? '$'+element.Crew1Ttl : ''}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="Crew2Name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Crew2Name </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td lg">
                <span class="split-card-data-ctn-table-td-con">
                  {{element.Crew2Name}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="Crew2Ttl">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Crew2Ttl </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td sm">
                <span class="split-card-data-ctn-table-td-con">
                  {{element.Crew2Ttl!=null? '$'+element.Crew2Ttl : ''}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="TotalCost">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> TotalCost </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td sm">
                <span class="split-card-data-ctn-table-td-con">
                  {{element.TotalCost!=null? '$'+element.TotalCost : ''}}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="UDF_PAY_DATE-CONDITIONAL">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Conditional </th>
              <td mat-cell *matCellDef="let element" [class.paid]="isPayLineItemPaid(element)" class="split-card-data-ctn-table-td md"> 
                <span class="split-card-data-ctn-table-td-con">
                  {{isPayLineItemPaid(element)? 'PAID' : 'UNPAID'}}
                </span>
              </td>
            </ng-container> 
  
            <tr mat-header-row *matHeaderRowDef="['SalesOrderNo', 'JT158_WTNumber', 'ShipToName', 'UDF_PAY_DATE', 'ItemCodeDesc', 'UDF_SUB_VENDOR', 'TechName', 'TechSubTotal', 'CrewLeadName', 'CrewLeadTotal', 'Crew1Name', 'Crew1Ttl', 'Crew2Name', 'Crew2Ttl', 'TotalCost', 'UDF_PAY_DATE-CONDITIONAL']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['SalesOrderNo', 'JT158_WTNumber', 'ShipToName', 'UDF_PAY_DATE', 'ItemCodeDesc', 'UDF_SUB_VENDOR', 'TechName', 'TechSubTotal', 'CrewLeadName', 'CrewLeadTotal', 'Crew1Name', 'Crew1Ttl', 'Crew2Name', 'Crew2Ttl', 'TotalCost', 'UDF_PAY_DATE-CONDITIONAL'];"></tr>
          </table>
        </div>
      
        <div class="split-card-data-table-controls">
          <mat-paginator 
            #payLineItemsDataPaginator
            [pageSizeOptions]="[10, 20]"
            showFirstLastButtons
            aria-label="Select page of periodic elements"
          >
          </mat-paginator>
        </div>
  
      </div>
  
    </mat-card-content>
  </mat-card>
</div>