<div class="container">
  <div class="card border-1 m-3">
    <!--    Header-->
    <div class="card-header text-center">
      <div class="card-title text-danger" *ngIf="noTicket && !isLoading"><b>**INVALID TICKET**</b></div>
      <div class="card-title text-success" *ngIf="noTicket && isLoading"><b>**LOADING TICKET**</b></div>
      <div class="card-title text-success" *ngIf="isSubmitting"><b>**UPDATING TICKET**</b></div>
      <div class="card-title"><b>WT: {{soNum}}-{{wtNum}}</b></div>
      <div class="card-title"><b>Address: {{address}}</b></div>
    </div>
    <div class="card-body">
      <!--      <div class="d-flex justify-content-center">-->
      <!--        <button class="btn btn-primary" [disabled]="noTicket || isLoading" (click)="openModal(imageModal); fileNames()">Upload Image</button>-->
      <!--      </div>-->
      <!--      <div class="row justify-content-center my-3">-->
      <!--        <div class=" ml-2 text-center">-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="d-flex justify-content-center mb-3">-->
      <!--        <button class="btn-primary btn" [disabled]="noTicket || isLoading || toLWSubmitted" (click)="changeToLW()">Move to LW</button>-->
      <!--      </div>-->
      <div class="text-center m-2">
        <button class="btn btn-sm btn-secondary" (click)="shownotes ? shownotes = false: shownotes = true"><span *ngIf="!shownotes">Show Notes</span><span *ngIf="shownotes">Hide Notes</span></button>
      </div>
<!--      <ng-container *ngIf="!hasLW">-->
<!--        <div class="text-center">-->
<!--          <h5 class="text-success"><b>***NEW Workticket***</b></h5>-->
<!--        </div>-->
<!--        <ng-container *ngIf="shownotes">-->
<!--          <div class="text-center text-warning">-->
<!--            <span><b class="text-danger">**Note</b>: This is a new house where none of the parts has been shipped yet.-->
<!--              As such, the not shipped table will not show for easier traversal of the page.<b class="text-danger">**</b>-->
<!--            </span>-->
<!--          </div>-->
<!--        </ng-container>-->
<!--      </ng-container>-->
      <ng-container *ngIf="hasLW">
        <div class="text-center">
          <h5 class="text-danger"><b>***Old Workticket***</b></h5>
        </div>
        <ng-container *ngIf="shownotes">
          <div class="text-center text-warning">
            <span><b class="text-danger">**Note</b>: This is a old house that has been processed (moved to the LW, left
              warehouse, status) once before. The tables below will show what parts are missing or over-shipped.
              <b class="text-danger">**</b>
            </span>
          </div>
        </ng-container>
      <div class="text-center">
        <div class="table-responsive">
          <table class="table table-hover">
            <ng-container *ngIf="hasUnder">
              <thead>
            <tr>
<!--              <th></th>-->
              <th colspan="4"><h5><b>Items Not Shipped:</b></h5></th>
<!--              <th></th>-->
            </tr>
            <tr>
              <th><b>Item Code</b></th>
              <th><b>Qty Ordered</b></th>
              <th><b>Qty Shipped</b></th>
              <th><b># parts Needed</b></th>
            </tr>
            </thead>
              <tbody>
            <ng-container *ngFor="let items of noShipList">
              <ng-container *ngIf="items.QtyOrderedTotal > items.QtyShippedTotal">
                <tr>
                  <td><b>{{items.name}}</b></td>
                  <td><b>{{items.QtyOrderedTotal}}</b></td>
                  <td><b>{{items.QtyShippedTotal}}</b></td>
                  <td><b>{{items.QtyOrderedTotal-items.QtyShippedTotal}}</b></td>
                </tr>
              </ng-container>
            </ng-container>
            </tbody>
            </ng-container>
            <ng-container *ngIf="hasOver">
            <thead>
            <tr>
              <th></th>
              <th colspan="4"><h5><b>Items Over Shipped:</b></h5></th>
              <th></th>
            </tr>
            <tr>
              <th><b>Item Code</b></th>
              <th><b>Qty Ordered</b></th>
              <th><b>Qty Shipped</b></th>
              <th><b># parts over</b></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let items of noShipList">
              <ng-container *ngIf="items.QtyOrderedTotal < items.QtyShippedTotal">
                <tr>
                  <td><b>{{items.name}}</b></td>
                  <td><b>{{items.QtyOrderedTotal}}</b></td>
                  <td><b>{{items.QtyShippedTotal}}</b></td>
                  <td><b>{{items.QtyShippedTotal-items.QtyOrderedTotal}}</b></td>
                </tr>
              </ng-container>
            </ng-container>
            </tbody>
            </ng-container>
          </table>
        </div>
      </div>
      </ng-container>

      <div class="text-center">
        <h5><b>Any Backorders/Order Changes?</b></h5>
      </div>
      <div class="text-center text-warning" *ngIf="shownotes">
        <span><b class="text-danger">**Note</b>: The 'Update Materials' button will ship <b>ALL</b> materials on the
          list if you press it and 'No' is selected. If 'Yes' is selected, all items will be shipped <b>EXCEPT</b> for
          the items that were selected below. They will be set the values you set below.<b class="text-danger">**</b>
        </span>
      </div>
      <div class="d-flex justify-content-center mt-1">
        <div class="p-2 text-center">
          <button class="btn-primary btn" [disabled]="noTicket || isLoading || matUpdated"
                  (click)="generatebackorders()">Yes <i *ngIf="!shipAll" class="fas fa-check"></i></button>
        </div>
        <div class="p-2 text-center">
          <button class="btn-primary btn" [disabled]="noTicket || isLoading || matUpdated" (click)="shipAll = true">No
            <i *ngIf="shipAll" class="fas fa-check"></i></button>
        </div>
      </div>
      <ng-container *ngIf="!shipAll">
        <div class="text-center mt-3">
          <h5><b>Items List</b></h5>
        </div>
        <div class="d-flex justify-content-center mt-1">
          <ul class="list-group list-group-flush">
            <ng-container *ngFor="let item of itemList; let index = index">
              <li class="list-group-item py-2 px-1 col col-md col-lg text-center">
                <div class="row m-0 p-0  justify-content-center">
                  <input class="form-control" [(ngModel)]="item.name"
                         (typeaheadOnSelect)="itemSelected($event, index, workticket)" [typeahead]="filtereditems"
                         typeaheadOptionField="ItemCode" placeholder="ItemCode" autocomplete="off">
                </div>
                <div class="row m-0 p-0">
                  <div class="col-3 text-center ml-0 mr-2 mt-1 mb-0 pb-0">
                    <label class="form-control-sm m-0 p-0" for="countLine"><b>Ordered:</b></label>
                  </div>
                  <div class="col-3 text-center ml-2 mt-1 mb-0 pb-0">
                    <label class="form-control-sm m-0 p-0" for="countLine"><b>Shipped:</b></label>
                  </div>
                </div>
                <div class="row m-0 p-0 justify-content-center">
                  <div class="col-3 mr-2 ml-0 mt-0">
                    <input class="form-control form-control-sm" id="countorder" style="width: 50px;" type="number"
                           [(ngModel)]="item.newQtyOrderedTotal" (click)="$event.target.select()"
                           (change)="lineEdited(index)"/>
                  </div>
                  <div class="col-3  ml-2 mt-0">
                    <input class="form-control form-control-sm" id="countLine" style="width: 50px;" type="number"
                           [(ngModel)]="item.newQtyShippedTotal" (click)="$event.target.select()"/>
                  </div>
                  <div class="col text-center">
                    <button class="btn btn-primary" (click)="RemoveBackorderList(index)"><i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
                <!--                </div>-->
              </li>
            </ng-container>
            <li class="list-group-item py-2 px-1 col col-md col-lg text-center">
              <button class="btn btn-primary" (click)="AddBackorderList()"><i class="fas fa-plus"></i></button>
            </li>
          </ul>
        </div>
      </ng-container>
      <div class="d-flex justify-content-center mt-1">
<!--        <button class="btn-primary btn" [disabled]="noTicket || isLoading" (click)="updateMaterial(workticket)">Update-->
<!--          Materials <i *ngIf="matUpdated" class="fas fa-check"></i></button>-->
        <button class="btn-primary btn" [disabled]="noTicket || isLoading || isSubmitting || submitOnce" (click)="updateMaterial(workticket)">
          <span *ngIf="!isSubmitting">Update Materials <i *ngIf="matUpdated" class="fas fa-check"></i></span>
          <span *ngIf="isSubmitting"><i class="fas fa-sync fa-spin"></i></span></button>
      </div>
      <div class="row justify-content-center my-2">
        <div class=" ml-2 text-center"></div>
      </div>
      <div class="text-center text-warning mb-2" *ngIf="shownotes">
        <span><b class="text-danger">**Note</b>: This button will open a screen that will allow you to upload and down
          load images. Please take pictures of the parts sheets so that they may be saved.<b class="text-danger">**</b>
        </span>
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary" [disabled]="noTicket || isLoading" (click)="openModal(imageModal); fileNames()">
          Upload Image
        </button>
      </div>
      <div class="row justify-content-center my-2">
        <div class=" ml-2 text-center"></div>
      </div>
      <div class="text-center text-warning mb-2" *ngIf="shownotes">
        <span><b class="text-danger">**Note</b>: This button is <b class="text-danger">ONLY IF</b> this WorkTicket
          <b class="text-danger">NEEDS</b> to be moved to LW (Left Warehouse). If you don't need to move the Work
          Ticket to LW (such as with SERVICE and PUNCH tickets), then <b class="text-danger">DO NOT USE THIS BUTTON</b>
          . you are done with this page.<b class="text-danger">**</b></span>
      </div>
      <div class="d-flex justify-content-center mb-3">
        <button class="btn-primary btn" [disabled]="(noTicket || isLoading || isSubmitting) || servPun" (click)="changeToLW()">Move to LW</button>
      </div>
      <ng-container *ngIf="partsfailmessage.length > 0">
        <div class="d-flex justify-content-center mb-3">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>Materials that Failed to Ship</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let err of partsfailmessage">
                <ng-container *ngIf="!err.toLocaleLowerCase().includes('on your system')">
                  <tr>
                    <td class="text-center">
                      {{err}}
                    </td>
                  </tr>
                </ng-container>

              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
      <!--      <div class="row justify-content-center my-2">-->
      <!--        <div class=" ml-2 text-center text-warning">**The button below is only for cases when the images system does not work. This will most likely be due to a special character in the address.**</div>-->
      <!--      </div>-->
      <!--      <div class="d-flex justify-content-center mb-3">-->
      <!--        <button class="btn-primary btn" [disabled]="noTicket || isLoading || !matUpdated" (click)="imgoverride()">Image Override</button>-->
      <!--      </div>-->
      <!--      <div class="row justify-content-center my-2">-->
      <!--        <div class=" ml-2 text-center text-danger" *ngIf="imgOverride">**Skipping images. please make sure Pull Sheet is delivered to office marked as images not taken.**</div>-->
      <!--      </div>-->
    </div>
  </div>
</div>

<!-- Image modal-->
<ng-template #imageModal>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>File Contents for {{ address | titlecase}}</h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <div class="row mb-1" id="files">
      <div class="col-sm-3 pt-2">
        <div class="card text-center">
          <label class="pt-2 mb-0">
            <i class="fas fa-file-upload fa-4x text-info"></i>
            <input type="file" (change)="fileChosen($event)" style="display: none;">
          </label>
          <small>Upload</small>
          <ng-container *ngFor="let file of fileArray">
            <br> <small> {{ file.name }}</small>
          </ng-container>
        </div>
      </div>
      <div class="col-sm-3 pt-2" *ngFor="let file of fileList">
        <div class="card text-center">
          <a class="btn p-0 pt-2" (click)="fileDownload(file.name)"><i class="fas fa-file-alt fa-4x"></i></a>
          <small>{{ file.name }}</small>
        </div>
      </div>
    </div>

    <form>
      <div class="form-row pt-2">
        <div class="col">
          <button class="btn btn-primary float-right" (click)="closeModal()">Close</button>
          <button class="btn btn-info float-right mr-2" (click)="fileUpload()" *ngIf="fileArray.length > 0 "><span
            *ngIf="!fileLoading">Upload</span><span *ngIf="fileLoading"><i class="fas fa-sync fa-spin"></i></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!-- Image modal-->

<!-- submit modal -->
<ng-template #shipSubmit>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-9 offset-1 text-center">
        <h2> Confirm? </h2>
        <h5> The ticket's STATUS will be changed to LW and the inventory will be shipped</h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <div class="row mb-1" id="">
      <div class="col col-sm-6 text-center">
<!--        <button class="btn btn-primary" (click)="QRupdateMaterial(workticket)">Yes</button>-->
      </div>
      <div class="col col-sm-6 text-center">
        <button class="btn btn-primary" (click)="closeModal()">No</button>
      </div>
    </div>
  </div>
</ng-template>
