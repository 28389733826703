<div class="container">
  <div class="card my-5 shadow-lg">
    <div class="row px-3 pt-3 pb-4 d-flex justify-content-center">
      <ng-container>
        <div class="col-3 ">
          <div class="list-group list-group-horizontal">
            <button [disabled]="selectionTempSingle"
                    class="list-group-item list-group-item-action px-0 flex-fill text-center"
                    (click)="moveTemplateTab('single')">Template Editor
            </button>
          </div>
        </div>
        <div class="col-3 ">
          <div class="list-group list-group-horizontal">
            <button [disabled]="selectionTempMass"
                    class="list-group-item list-group-item-action px-0 flex-fill text-center"
                    (click)="moveTemplateTab('mass')">Mass Template Update
            </button>
          </div>
        </div>
        <div class="col-3 ">
          <div class="list-group list-group-horizontal">
            <button [disabled]="selectionSales"
                    class="list-group-item list-group-item-action px-0 flex-fill text-center"
                    (click)="moveTemplateTab('sales')">Part Replace
            </button>
          </div>
        </div>
      </ng-container>
      <!-- * BEGINNING of Moving Tab -->
      <div class="moving-selection-tab position-absolute col-3 col-sm-3 col-m-3 p-0" style="z-index: 1;"
      [ngClass]="{'offset0': selectionTempSingle, 'offset3' : selectionTempMass, 'offset6' : selectionSales }">
        <ul class="list-group list-group-horizontal list-unstyled">
          <li class="text-center p-3 flex-fill bg-primary text-light h5 mb-0"> {{ selectedTempName }} </li>
        </ul>
      </div>
      <!-- * END of Moving Tab -->

    </div>
    <ng-container *ngIf="selectionTempSingle">
      <div class="row px-3 pt-3 pb-4 d-flex justify-content-center">
        <!-- * BEGINNING of Edit Existing Template Nav -->
        <ng-container *ngIf="!creatingNewTemplate && userSelectedTemplate">
          <div class="col-1">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!userSelectedTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Info')">Info
              </button>
            </div>
          </div>
          <div class="col-1">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!userSelectedTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 000')">Step 000
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!userSelectedTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 005')">Step 005
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!userSelectedTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 006')">Step 006
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!userSelectedTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 010')">Step 010
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!userSelectedTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 020')">Step 020
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!userSelectedTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Review')">Review
              </button>
            </div>
          </div>
        </ng-container>
        <!-- * END of Edit Existing Template Nav -->

        <!-- *BEGINNING of Create New Template Nav -->
        <ng-container *ngIf="creatingNewTemplate">
          <div class="col-1">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!creatingNewTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Info')">Info
              </button>
            </div>
          </div>
          <div class="col-1">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!creatingNewTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 000')">Step 000
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!creatingNewTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 005')">Step 005
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!creatingNewTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 006')">Step 006
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!creatingNewTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 010')">Step 010
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!creatingNewTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 020')">Step 020
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!creatingNewTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Review')">Review
              </button>
            </div>
          </div>
        </ng-container>
        <!-- *END of Create New Template Nav -->

        ` <!-- *BEGINNING of Copy Template Nav -->
        <ng-container *ngIf="copyingTemplate">
          <div class="col-1">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!copyingTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Info')">Info
              </button>
            </div>
          </div>
          <div class="col-1">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!copyingTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 000')">Step 000
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!copyingTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 005')">Step 005
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!copyingTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 006')">Step 006
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!copyingTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 010')">Step 010
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!copyingTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Step 020')">Step 020
              </button>
            </div>
          </div>
          <div class="col-1" [ngClass]="{'disabled' : userSelectedTemplate}">
            <div class="list-group list-group-horizontal">
              <button [disabled]="!copyingTemplate"
                      class="list-group-item list-group-item-action px-0 flex-fill text-center"
                      (click)="moveSelectionTab('Review')">Review
              </button>
            </div>
          </div>
        </ng-container>
        <!-- *END of Copy Template Nav -->

        <!-- * BEGINNING of Moving Tab -->
        <div class="moving-selection-tab position-absolute col-1 p-0" style="z-index: 4;" [ngClass]="{'offset0': selectionTabInfo, 'offset1' : selectionTab000, 'offset2' : selectionTab005, 'offset3' : selectionTab006,'offset4' :
        selectionTab010,
        'offset5' : selectionTab020, 'offset6' :
        selectionTabReview }">
          <ul class="list-group list-group-horizontal list-unstyled">
            <li class="text-center py-3 flex-fill bg-primary text-light h5 mb-0"> {{ selectedTabName }} </li>
          </ul>
        </div>
        <!-- * END of Moving Tab -->

      </div>
      <div class="card-body">
        <!-- !!! Beginning of Editing Existing Templates !!! -->
        <ng-container *ngIf="!creatingNewTemplate && !copyingTemplate">
          <!-- * ==TAB INFO BEGINNING== INFO Default -->
          <ng-container *ngIf="selectionTabInfo">
            <div class="row d-flex justify-content-around align-items-center pt-4 pb-5">
              <h3>{{!userSelectedTemplate ? 'Template Search' : 'Template Edit'}}</h3>
              <button *ngIf="!userSelectedTemplate" type="button"
                      (click)="onOpenCreateNewTemplateModal(createNewTemplate)" class="btn btn-primary"
                      title="Create a brand new template">Create New Template
              </button>
              <!-- TODO - Future Feature - Allow user to copy from loaded template-->
              <!-- <button *ngIf="userSelectedTemplate" (click)="onOpenModal(copyTemplateFromEditScreen)" type="button" class="btn btn-primary" title="Copy this template into a new template">Copy to New Template</button> -->
            </div>
            <div class="row form-group">
              <label class="col-md-2">{{loadingData ? "Loading template info..." : "TemplateNo to Edit"}}</label>
              <input [disabled]="loadingData" class="col-md-8 form-control" name="" id="" aria-describedby="helpId"
                     placeholder="e.x. RIZZ2.5/3UP" [(ngModel)]="userInputTemplateNumber"
                     (typeaheadOnSelect)="onSelectTemplateNumber($event)"
                     [typeahead]="activeTemplates"
                     typeaheadOptionField="TemplateName" [typeaheadScrollable]="true" autocomplete="off">
            </div>
            <!-- * Table to Show Selected Template Information -->
            <div *ngIf="userSelectedTemplate" class="row">
              <table class="table table-hover">
                <thead class="thead-dark">
                <tr>
                  <th scope="col">Template No.</th>
                  <th scope="col">Step</th>
                  <th scope="col">Initial Status</th>
                  <th scope="col">Desc</th>
                  <th scope="col">Revision No.</th>
                  <th scope="col">Current Revision</th>
                  <th scope="col"># of Parts</th>
                  <th scope="col">Edit</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let step of selectedTemplate[0] | keyvalue; index as index" id="{{step.value['name']}}">
                  <ng-container *ngIf="step.value['templateData'].length> 0">
                    <td>
                      {{step.value['templateData'][0]['TemplateNo'] || ''}}
                    </td>
                    <td>
                      {{step.value['templateData'][0]['WTStep']}}
                    </td>
                    <td>
                      <label for="template-initial-status" class="sr-only">Initial Status</label>
                      <input style="width: 5rem;" type="text" class="form-control" name="template-initial-status"
                             aria-describedby="template-initial-status" placeholder=""
                             (change)="step.value['templateData'][0]['updated']= true"
                             [disabled]="step.value['templateData'][0]['created']"
                             [(ngModel)]="step.value['templateData'][0]['InitialStatusCode']">
                    </td>
                    <td>
                      <label for="template-description" class="sr-only">Description</label>
                      <input type="text" class="form-control" name="template-description"
                             aria-describedby="template-description" placeholder=""
                             (change)="step.value['templateData'][0]['updated']= true"
                             [disabled]="step.value['templateData'][0]['created']"
                             [(ngModel)]="step.value['templateData'][0]['Description']">
                    </td>
                    <td>
                      {{step.value['templateData'][0]['RevisionNo']}}
                    </td>
                    <td>{{step.value['templateData'][0]['CurrentRevision']}}</td>
                    <td>{{step.value['templateLines'].length}}</td>
                    <td>
                      <button [disabled]="step.value['templateLines'].length <1" type="button"
                              class="btn btn-primary btn-sm"
                              (click)="moveSelectionTab('Step ' + step.value['templateData'][0]['WTStep'])"
                              title="{{step.value['templateLines'].length <1 ? 'No lines to edit' : 'Edit lines in this step'}}">
                        Edit Step {{step.value['templateData'][0]['WTStep']}}</button>
                    </td>
                  </ng-container>
                </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
          <!-- * ==TAB INFO END== INFO Default -->

          <!-- * ==TAB 000 BEGINNING== Step 000 Default -->
          <ng-container *ngIf="selectionTab000">
            <div class="row">
              <div class="col-12 d-flex justify-content-around p-2">
                <h4>Add Warehouse/QA Items</h4>
                <button type="button" class="btn btn-primary"
                        (click)="onRemoveAllItemsFromTemplateStep('000', 'existing')">Remove ALL Items From Step 000
                </button>
              </div>
            </div>
            <div class="row">
              <div class="container">
                <table class="table table-hover table-striped">
                  <thead class="thead-dark">
                  <tr>
                    <th>Template</th>
                    <th>Revision</th>
                    <th>ItemCode</th>
                    <th>Desc</th>
                    <th>Qty</th>
                    <th>Comment</th>
                    <th>Location</th>
                    <th>Explode</th>
                    <th>Edit</th>
                  </tr>
                  </thead>
                  <tbody>
                  <!-- * BEGINNING of Add New Item to Step 000 -->
                  <ng-container class="w-100">
                    <tr style="background-color: lightgray;">
                      <td>{{selectedTemplate[0]['000']['templateData'][0].TemplateNo}}</td>
                      <td>{{selectedTemplate[0]['000']['templateData'][0].RevisionNo}}</td>
                      <td>
                        <label class="sr-only" for="step000-add-item-code">ItemCodeAdd</label>
                        <input type="text" name="step000-add-item-code" class="form-control w-100"
                               aria-describedby="step000-add-item-code" placeholder="Item Code"
                               [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                               (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                               [typeahead]="templateItemList"
                               typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                               [typeaheadOptionsInScrollableView]="20" autocomplete="off">
                      </td>
                      <td>{{templateItemsToAddToTemplate.ItemCodeDesc}}</td>
                      <td>
                        <div class="form-group">
                          <label for="step000-add-item-qty-req" class="sr-only">Quantity Required</label>
                          <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                                 class="form-control" name="step000-add-item-qty-req" id="step000-add-item-qty-req"
                                 aria-describedby="step000-add-item-qty-req">
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <label for="step000-add-item-comment" class="sr-only">ItemComment</label>
                          <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                    name="step000-add-item-comment" id="step000-add-item-comment" rows="2">Comments go here</textarea>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <label class="sr-only" for="step000-add-item-location">Location</label>
                          <input type="text" name="step000-add-item-location" class="form-control w-100"
                                 aria-describedby="step000-add-item-location" placeholder="Item Location"
                                 [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                                 [typeahead]="locationList"
                                 typeaheadOptionField="Location" autocomplete="off">
                        </div>
                      </td>
                      <td>
                        <div class="col-1 mt-2" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                             (click)="onExplodeKitItems()">
                          <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                            <i class="fas fa-bomb fa-2x text-dark"></i>
                          </span>
                          <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                            <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                            <i class="fas fa-ban fa-stack-2x text-primary"></i>
                          </span>
                        </div>
                      </td>
                      <td>
                        <button type="button" class="btn btn-primary btn-sm"
                                (click)="onAddItemToTemplate('000', 'existing')"
                                [disabled]="templateItemsToAddToTemplate.ItemCode === '' || templateItemsToAddToTemplate.QuantityRequired === ''">
                          Add
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                  <!-- * END of Add New Item to Step 000 -->
                  <ng-container *ngIf="selectedTemplate[0]['000'].templateLines.length> 0">
                    <tr *ngFor="let line of selectedTemplate[0]['000'].templateLines; index as index">
                      <td>{{line.TemplateNo}}</td>
                      <td style="width: 50px">
                        {{line.RevisionNo}}
                      </td>
                      <td>
                        {{line.ItemCode}}
                      </td>
                      <td
                        style="width: 175px;">{{line.ItemCodeDesc.length >= 30 ? line.ExtendedDescriptionText : line.ItemCodeDesc}}</td>
                      <td style="width: 75px;">
                        <div class="form-group w-100">
                          <label class="sr-only" for="step000-qty-req">QtyRequired</label>
                          <input type="text" class="form-control" name="step000-qty-req"
                                 aria-describedby="step000-qty-req" (change)="line.updated= true"
                                 [(ngModel)]="line.QuantityRequired">
                        </div>
                      </td>
                      <td>
                        <div class="form-group w-100">
                          <label for="step000-comment-text" class="sr-only">CommentText</label>
                          <textarea class="form-control" name="step000-comment-text" rows="2"
                                    (change)="line.updated= true" [(ngModel)]="line.CommentText"></textarea>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <label class="sr-only" for="step000-location">Location</label>
                          <input type="text" name="step000-location" class="form-control w-100"
                                 aria-describedby="step000-location" placeholder={{line.UDF_LOCATION}}
                                 [(ngModel)]="line.UDF_LOCATION" (change)="line.updated= true"
                                 (typeaheadOnSelect)="onChangeItemLocation($event, line, index, 'existing')"
                                 [typeahead]="locationList"
                                 typeaheadOptionField="Location" autocomplete="off">
                        </div>
                      </td>
                      <td>
                        {{line.ExplodeBOM === 'Y' ? 'Kit' : ''}}
                      </td>
                      <td>
                        <button type="button" class="btn btn-primary btn-sm"
                                (click)="onRemoveItemFromTemplate(line, index, 'existing')" title="Remove Item">X
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>
          <!-- * ==TAB 000 END== Step 000 Default -->


          <!-- *==TAB 005 BEGINNING== Step 005 User Added Step -->
          <ng-container *ngIf="selectionTab005">
            <div *ngIf="this.selectedTemplate[0]['005']['templateData'].length <1">
              <h3>This step does not exist on this template</h3>
              <p>Click here to
                <button type="button" class="btn btn-primary btn-sm"
                        (click)="onAddStepToExistingTemplate('005', 'existing')">Add Step 005
                </button>
                to TemplateNo: {{userInputTemplateNumber}}</p>
            </div>

            <ng-container *ngIf="this.selectedTemplate[0]['005']['templateData'].length> 0">
              <div class="row">
                <div *ngIf="selectedTemplate[0]['005']['templateLines'].length> 0"
                     class="col-12 d-flex justify-content-around p-2">
                  <h4>Optional Step 005</h4>
                  <button type="button" class="btn btn-primary"
                          (click)="onRemoveAllItemsFromTemplateStep('005', 'existing')">Remove ALL Items From Step 005
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="container">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>Template</th>
                      <th>Revision</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty</th>
                      <th>Comment</th>
                      <th>Location</th>
                      <th>Explode</th>
                      <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <!-- * BEGINNING of Add New Item to Step 005 -->
                    <ng-container class="w-100">
                      <tr style="background-color: lightgray;">
                        <td>{{selectedTemplate[0]['000']['templateData'][0].TemplateNo}}</td>
                        <td>{{selectedTemplate[0]['000']['templateData'][0].RevisionNo}}</td>
                        <td>
                          <label class="sr-only" for="step005-add-item-code">ItemCodeAdd</label>
                          <input type="text" name="step005-add-item-code" class="form-control w-100"
                                 aria-describedby="step005-add-item-code" placeholder="Item Code"
                                 [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                                 (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                                 [typeahead]="templateItemList"
                                 typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                                 [typeaheadOptionsInScrollableView]="20" autocomplete="off">
                        </td>
                        <td>{{templateItemsToAddToTemplate.ItemCodeDesc}}</td>
                        <td>
                          <div class="form-group">
                            <label for="step005-add-item-qty-req" class="sr-only">Quantity Required</label>
                            <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                                   class="form-control" name="step005-add-item-qty-req" id="step005-add-item-qty-req"
                                   aria-describedby="step005-add-item-qty-req">
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label for="step005-add-item-comment" class="sr-only">ItemComment</label>
                            <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                      name="step005-add-item-comment" id="step005-add-item-comment" rows="2">Comments go here</textarea>
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label class="sr-only" for="step005-add-item-location">Location</label>
                            <input type="text" name="step005-add-item-location" class="form-control w-100"
                                   aria-describedby="step005-add-item-location" placeholder="Item Location"
                                   [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                                   [typeahead]="locationList"
                                   typeaheadOptionField="Location" autocomplete="off">
                          </div>
                        </td>
                        <td>
                          <div class="col-1 mt-2" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                               (click)="onExplodeKitItems()">
                            <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                              <i class="fas fa-bomb fa-2x text-dark"></i>
                            </span>
                            <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                              <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                              <i class="fas fa-ban fa-stack-2x text-primary"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm"
                                  (click)="onAddItemToTemplate('005', 'existing')"
                                  [disabled]="templateItemsToAddToTemplate.ItemCode === '' || templateItemsToAddToTemplate.QuantityRequired === ''">
                            Add
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    <!-- * END of Add New Item to Step 005 -->
                    <ng-container *ngIf="selectedTemplate[0]['005'].templateLines.length> 0">
                      <tr *ngFor="let line of selectedTemplate[0]['005'].templateLines; index as index">
                        <td>{{line.TemplateNo}}</td>
                        <td style="width: 50px">
                          {{line.RevisionNo}}
                        </td>
                        <td>
                          {{line.ItemCode}}
                        </td>
                        <td
                          style="width: 175px;">{{line.ItemCodeDesc.length >= 30 ? line.ExtendedDescriptionText : line.ItemCodeDesc}}</td>
                        <td style="width: 75px;">
                          <div class="form-group w-100">
                            <label class="sr-only" for="step005-qty-req">QtyRequired</label>
                            <input type="text" class="form-control" name="step005-qty-req"
                                   aria-describedby="step005-qty-req" (change)="line.updated= true"
                                   [(ngModel)]="line.QuantityRequired">
                          </div>
                        </td>
                        <td>
                          <div class="form-group w-100">
                            <label for="step005-comment-text" class="sr-only">CommentText</label>
                            <textarea class="form-control" name="step005-comment-text" rows="2"
                                      (change)="line.updated= true" [(ngModel)]="line.CommentText"></textarea>
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label class="sr-only" for="step005-location">Location</label>
                            <input type="text" name="step005-location" class="form-control w-100"
                                   aria-describedby="step005-location" placeholder={{line.UDF_LOCATION}}
                                   [(ngModel)]="line.UDF_LOCATION" (change)="line.updated= true"
                                   (typeaheadOnSelect)="onChangeItemLocation($event, line, index, 'existing')"
                                   [typeahead]="locationList"
                                   typeaheadOptionField="Location" autocomplete="off">
                          </div>
                        </td>
                        <td>
                          {{line.ExplodeBOM === 'Y' ? 'Kit' : ''}}
                        </td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm"
                                  (click)="onRemoveItemFromTemplate(line, index, 'existing')" title="Remove Item">X
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <!-- *==TAB 005 END== Step 005 User Added Step -->


          <!-- *==TAB 006 BEGINNING== Step 006 User Added Step -->
          <ng-container *ngIf="selectionTab006">
            <div *ngIf="this.selectedTemplate[0]['006']['templateData'].length === 0">
              <h3>This step does not exist on this template</h3>
              <p>Click here to
                <button type="button" class="btn btn-primary btn-sm"
                        (click)="onAddStepToExistingTemplate('006', 'existing')">Add Step 006
                </button>
                to TemplateNo: {{userInputTemplateNumber}}</p>
            </div>

            <ng-container *ngIf="this.selectedTemplate[0]['006']['templateData'].length> 0">
              <div class="row">
                <div *ngIf="selectedTemplate[0]['006']['templateLines'].length> 0"
                     class="col-12 d-flex justify-content-around p-2">
                  <h4>Optional Step 006</h4>
                  <button type="button" class="btn btn-primary"
                          (click)="onRemoveAllItemsFromTemplateStep('006', 'existing')">Remove ALL Items From Step 006
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="container">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>Template</th>
                      <th>Revision</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty</th>
                      <th>Comment</th>
                      <th>Location</th>
                      <th>Explode</th>
                      <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <!-- * BEGINNING of Add New Item to Step 006 -->
                    <ng-container class="w-100">
                      <tr style="background-color: lightgray;">
                        <td>{{selectedTemplate[0]['000']['templateData'][0].TemplateNo}}</td>
                        <td>{{selectedTemplate[0]['000']['templateData'][0].RevisionNo}}</td>
                        <td>
                          <label class="sr-only" for="step006-add-item-code">ItemCodeAdd</label>
                          <input type="text" name="step006-add-item-code" class="form-control w-100"
                                 aria-describedby="step006-add-item-code" placeholder="Item Code"
                                 [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                                 (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                                 [typeahead]="templateItemList"
                                 typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                                 [typeaheadOptionsInScrollableView]="20" autocomplete="off">
                        </td>
                        <td>{{templateItemsToAddToTemplate.ItemCodeDesc}}</td>
                        <td>
                          <div class="form-group">
                            <label for="step006-add-item-qty-req" class="sr-only">Quantity Required</label>
                            <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                                   class="form-control" name="step006-add-item-qty-req" id="step006-add-item-qty-req"
                                   aria-describedby="step006-add-item-qty-req">
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label for="step006-add-item-comment" class="sr-only">ItemComment</label>
                            <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                      name="step006-add-item-comment" id="step006-add-item-comment" rows="2">Comments go here</textarea>
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label class="sr-only" for="step006-add-item-location">Location</label>
                            <!-- (typeaheadOnSelect)="onChangeItemLocation($event)" -->
                            <input type="text" name="step006-add-item-location" class="form-control w-100"
                                   aria-describedby="step006-add-item-location" placeholder="Item Location"
                                   [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                                   [typeahead]="locationList"
                                   typeaheadOptionField="Location" autocomplete="off">
                          </div>
                        </td>
                        <td>
                          <div class="col-1 mt-2" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                               (click)="onExplodeKitItems()">
                            <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                              <i class="fas fa-bomb fa-2x text-dark"></i>
                            </span>
                            <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                              <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                              <i class="fas fa-ban fa-stack-2x text-primary"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm"
                                  (click)="onAddItemToTemplate('006', 'existing')"
                                  [disabled]="templateItemsToAddToTemplate.ItemCode === '' || templateItemsToAddToTemplate.QuantityRequired === ''">
                            Add
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    <!-- * END of Add New Item to Step 006 -->
                    <ng-container *ngIf="selectedTemplate[0]['006'].templateLines.length> 0">
                      <tr *ngFor="let line of selectedTemplate[0]['006'].templateLines; index as index">
                        <td>{{line.TemplateNo}}</td>
                        <td style="width: 50px">
                          {{line.RevisionNo}}
                        </td>
                        <td>
                          {{line.ItemCode}}
                        </td>
                        <td
                          style="width: 175px;">{{line.ItemCodeDesc.length >= 30 ? line.ExtendedDescriptionText : line.ItemCodeDesc}}</td>
                        <td style="width: 75px;">
                          <div class="form-group w-100">
                            <label class="sr-only" for="step006-qty-req">QtyRequired</label>
                            <input type="text" class="form-control" name="step006-qty-req"
                                   aria-describedby="step006-qty-req" (change)="line.updated= true"
                                   [(ngModel)]="line.QuantityRequired">
                          </div>
                        </td>
                        <td>
                          <div class="form-group w-100">
                            <label for="step006-comment-text" class="sr-only">CommentText</label>
                            <textarea class="form-control" name="step006-comment-text" rows="2"
                                      (change)="line.updated= true" [(ngModel)]="line.CommentText"></textarea>
                          </div>
                          <!-- <label class="sr-only" for="step006-comment-text">CommentText</label>
                          <input type="text" name="step006-comment-text" aria-describedby="step006-comment-text"> -->
                        </td>
                        <td>
                          <div class="form-group">
                            <label class="sr-only" for="step006-location">Location</label>
                            <input type="text" name="step006-location" class="form-control w-100"
                                   aria-describedby="step006-location" placeholder={{line.UDF_LOCATION}}
                                   [(ngModel)]="line.UDF_LOCATION" (change)="line.updated= true"
                                   (typeaheadOnSelect)="onChangeItemLocation($event, line, index, 'existing')"
                                   [typeahead]="locationList"
                                   typeaheadOptionField="Location" autocomplete="off">
                          </div>
                        </td>
                        <td>
                          {{line.ExplodeBOM === 'Y' ? 'Kit' : ''}}
                        </td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm"
                                  (click)="onRemoveItemFromTemplate(line, index, 'existing')" title="Remove Item">X
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <!-- *==TAB 006 END== Step 006 User Added Step -->

          <!-- * ==TAB 010 BEGINNING== Step 010 Parts -->
          <ng-container *ngIf="selectionTab010">
            <div *ngIf="this.selectedTemplate[0]['006']['templateData'].length === 0">
              <h3>This step does not exist on this template</h3>
              <p>Click here to
                <button type="button" class="btn btn-primary btn-sm"
                        (click)="onAddStepToExistingTemplate('010', 'existing')">Add Step 010
                </button>
                to TemplateNo: {{userInputTemplateNumber}}</p>
            </div>
            <ng-container *ngIf="selectedTemplate[0]['010']['templateData'].length> 0">
              <div class="row">
                <div class="col-12 d-flex justify-content-around p-2">
                  <h4>Add Materials</h4>
                  <button type="button" class="btn btn-primary"
                          (click)="onRemoveAllItemsFromTemplateStep('010', 'existing')">Remove ALL Items From Step 010
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="mx-auto">
                  <ng-container>
                    <table class="table table-responsive table-hover table-striped">
                      <thead class="thead-dark">
                      <tr>
                        <th>Template</th>
                        <th>Revision</th>
                        <th>ItemCode</th>
                        <th>Desc</th>
                        <th>Qty</th>
                        <th>Comment</th>
                        <th>Location</th>
                        <th>Explode</th>
                        <th>Edit</th>
                      </tr>
                      </thead>
                      <tbody>
                      <!-- * BEGINNING Add New Item to Step 010 -->
                      <ng-container class="w-100">
                        <tr style="background-color: lightgray;">
                          <td>{{selectedTemplate[0]['010']['templateData'][0].TemplateNo}}</td>
                          <td>{{selectedTemplate[0]['010']['templateData'][0].RevisionNo}}</td>
                          <td>
                            <label class="sr-only" for="step010-add-item-code">ItemCodeAdd</label>
                            <input type="text" name="step010-add-item-code" class="form-control w-100"
                                   aria-describedby="step010-add-item-code" placeholder="Item Code"
                                   [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                                   (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                                   [typeahead]="templateItemList"
                                   typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150"
                                   [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="20"
                                   autocomplete="off">
                          </td>
                          <td>{{templateItemsToAddToTemplate.ItemCodeDesc}}</td>
                          <td>
                            <div class="form-group">
                              <label for="step010-add-item-qty-req" class="sr-only">Quantity Required</label>
                              <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                                     class="form-control" name="step010-add-item-qty-req" id="step010-add-item-qty-req"
                                     aria-describedby="step010-add-item-qty-req">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <label for="step010-add-item-comment" class="sr-only">ItemComment</label>
                              <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                        name="step010-add-item-comment" id="step010-add-item-comment" rows="2">Comments go here</textarea>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <label class="sr-only" for="step010-add-item-location">Location</label>
                              <!-- (typeaheadOnSelect)="onChangeItemLocation($event)" -->
                              <input type="text" name="step010-add-item-location" class="form-control w-100"
                                     aria-describedby="step010-add-item-location" placeholder="Item Location"
                                     [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                                     [typeahead]="locationList"
                                     typeaheadOptionField="Location" autocomplete="off">
                            </div>
                          </td>
                          <td>
                            <div class="col-1 mt-2" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                                 (click)="onExplodeKitItems()">
                              <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                                <i class="fas fa-bomb fa-2x text-dark"></i>
                              </span>
                              <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                                <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                                <i class="fas fa-ban fa-stack-2x text-primary"></i>
                              </span>
                            </div>
                          </td>
                          <td>
                            <button type="button" class="btn btn-primary btn-sm"
                                    (click)="onAddItemToTemplate('010', 'existing')"
                                    [disabled]="templateItemsToAddToTemplate.ItemCode === '' || templateItemsToAddToTemplate.QuantityRequired === ''">
                              Add
                            </button>
                          </td>
                        </tr>
                      </ng-container>
                      <!-- * END Add New Item To Step 010 -->
                      <ng-container *ngIf="selectedTemplate[0]['010'].templateLines.length> 0">
                        <tr *ngFor="let line of selectedTemplate[0]['010'].templateLines; index as index">
                          <td>{{line.TemplateNo}}</td>
                          <td style="width: 50px">
                            {{line.RevisionNo}}
                          </td>
                          <td>
                            {{line.ItemCode}}
                          </td>
                          <td
                            style="width: 175px;">{{line.ItemCodeDesc.length >= 30 ? line.ExtendedDescriptionText : line.ItemCodeDesc}}</td>
                          <td style="width: 75px;">
                            <div class="form-group w-100">
                              <label class="sr-only" for="step000-qty-req">QtyRequired</label>
                              <input type="text" class="form-control" name="step000-qty-req"
                                     aria-describedby="step000-qty-req" (change)="line.updated= true"
                                     [(ngModel)]="line.QuantityRequired">
                            </div>
                          </td>
                          <td>
                            <div class="form-group w-100">
                              <label for="step000-comment-text" class="sr-only">CommentText</label>
                              <textarea class="form-control" name="step000-comment-text" rows="2"
                                        (change)="line.updated= true" [(ngModel)]="line.CommentText"></textarea>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <label class="sr-only" for="step000-location">Location</label>
                              <input type="text" name="step000-location" class="form-control w-100"
                                     aria-describedby="step000-location" placeholder={{line.UDF_LOCATION}}
                                     [(ngModel)]="line.UDF_LOCATION" (change)="line.updated= true"
                                     (typeaheadOnSelect)="onChangeItemLocation($event, line, index, 'existing')"
                                     [typeahead]="locationList"
                                     typeaheadOptionField="Location" autocomplete="off">
                            </div>
                          </td>
                          <td>{{line.ExplodeBOM === 'Y' ? 'Kit' : ''}}</td>
                          <td>
                            <button type="button" class="btn btn-primary btn-sm"
                                    (click)="onRemoveItemFromTemplate(line, index, 'existing')" title="Remove Item">X
                            </button>
                          </td>
                        </tr>
                      </ng-container>
                      </tbody>
                    </table>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <!--  * ==TAB 010 END== Step 010 Parts -->

          <!--  * ==TAB 020 BEGINNING== Step 020 Labor -->
          <ng-container *ngIf="selectionTab020">
            <div *ngIf="this.selectedTemplate[0]['020']['templateData'].length <1">
              <h3>This step does not exist on this template</h3>
              <p>Click here to
                <button type="button" class="btn btn-primary btn-sm"
                        (click)="onAddStepToExistingTemplate('020', 'existing')">Add Step 020
                </button>
                to TemplateNo: {{userInputTemplateNumber}}</p>
            </div>
            <ng-container *ngIf="this.selectedTemplate[0]['020']['templateData'].length> 0">
              <div class="row">
                <div class="col-12 d-flex justify-content-around p-2">
                  <h4>Add Labor</h4>
                  <button type="button" class="btn btn-primary"
                          (click)="onRemoveAllItemsFromTemplateStep('020', 'existing')">Remove ALL Items From Step 020
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="mx-auto">
                  <table class="table table-responsive table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>Template</th>
                      <th>Revision</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty</th>
                      <th>Comment</th>
                      <th>Location</th>
                      <th>Explode</th>
                      <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <!-- * BEGINNING of Add New Item to Step 020 -->
                    <ng-container class="w-100">
                      <tr style="background-color: lightgray;">
                        <td>{{selectedTemplate[0]['020']['templateData'][0].TemplateNo}}</td>
                        <td>{{selectedTemplate[0]['020']['templateData'][0].RevisionNo}}</td>
                        <td>
                          <label class="sr-only" for="step020-add-item-code">ItemCodeAdd</label>
                          <input type="text" name="step020-add-item-code" class="form-control w-100"
                                 aria-describedby="step020-add-item-code" placeholder="Item Code"
                                 [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                                 (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                                 [typeahead]="templateItemList"
                                 typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                                 [typeaheadOptionsInScrollableView]="20" autocomplete="off">
                        </td>
                        <td>{{templateItemsToAddToTemplate.ItemCodeDesc}}</td>
                        <td>
                          <div class="form-group">
                            <label for="step020-add-item-qty-req" class="sr-only">Quantity Required</label>
                            <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                                   class="form-control" name="step020-add-item-qty-req" id="step020-add-item-qty-req"
                                   aria-describedby="step020-add-item-qty-req">
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label for="step020-add-item-comment" class="sr-only">ItemComment</label>
                            <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                      name="step020-add-item-comment" id="step020-add-item-comment" rows="2">Comments go here</textarea>
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label class="sr-only" for="step020-add-item-location">Location</label>
                            <input type="text" name="step020-add-item-location" class="form-control w-100"
                                   aria-describedby="step020-add-item-location" placeholder="Item Location"
                                   [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                                   [typeahead]="locationList"
                                   typeaheadOptionField="Location" autocomplete="off">
                          </div>
                        </td>
                        <td>
                          <div class="col-1 mt-2" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                               (click)="onExplodeKitItems()">
                            <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                              <i class="fas fa-bomb fa-2x text-dark"></i>
                            </span>
                            <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                              <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                              <i class="fas fa-ban fa-stack-2x text-primary"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm"
                                  (click)="onAddItemToTemplate('020', 'existing')"
                                  [disabled]="templateItemsToAddToTemplate.ItemCode === '' || templateItemsToAddToTemplate.QuantityRequired === ''">
                            Add
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    <!-- * END of Add New Item to Step 020 -->
                    <ng-container *ngIf="selectedTemplate[0]['020'].templateLines.length> 0
                      ">
                      <tr *ngFor="let line of selectedTemplate[0]['020'].templateLines; index as index">
                        <td>{{line.TemplateNo}}</td>
                        <td style="width: 50px">
                          {{line.RevisionNo}}
                        </td>
                        <td>
                          {{line.ItemCode}}
                        </td>
                        <td
                          style="width: 175px;">{{line.ItemCodeDesc.length >= 30 ? line.ExtendedDescriptionText : line.ItemCodeDesc}}</td>
                        <td style="width: 75px;">
                          <div class="form-group w-100">
                            <label class="sr-only" for="step000-qty-req">QtyRequired</label>
                            <input type="text" class="form-control" name="step000-qty-req"
                                   aria-describedby="step000-qty-req" (change)="line.updated= true"
                                   [(ngModel)]="line.QuantityRequired">
                          </div>
                        </td>
                        <td>
                          <div class="form-group w-100">
                            <label for="step000-comment-text" class="sr-only">CommentText</label>
                            <textarea class="form-control" name="step000-comment-text" rows="2"
                                      (change)="line.updated= true" [(ngModel)]="line.CommentText"></textarea>
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label class="sr-only" for="step000-location">Location</label>
                            <input type="text" name="step000-location" class="form-control w-100"
                                   aria-describedby="step000-location" placeholder={{line.UDF_LOCATION}}
                                   [(ngModel)]="line.UDF_LOCATION" (change)="line.updated= true"
                                   (typeaheadOnSelect)="onChangeItemLocation($event, line, index, 'existing')"
                                   [typeahead]="locationList"
                                   typeaheadOptionField="Location" autocomplete="off">
                          </div>
                        </td>
                        <td>{{line.ExplodeBOM === 'Y' ? 'Kit' : ''}}</td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm"
                                  (click)="onRemoveItemFromTemplate(line, index, 'existing')" title="Remove Item">X
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <!--  * ==TAB 020 END== Step 020 Labor -->

          <!--  * ==TAB REVIEW== Review Step -->
          <ng-container *ngIf="selectionTabReview">
            <div class="row">
              <div class="col-12"><h3 class="text-dark">
                Template: {{selectedTemplate[0]['000'].templateData[0].TemplateNo}}</h3></div>
            </div>
            <ul class="list-group">
              <li class="list-group-item w-100">
                <div
                  class="row w-100 mx-auto bg-primary font-weight-bold text-light p-1 d-flex justify-content-between align-items-center"
                  (click)="showReviewStep000= !showReviewStep000">
                  <div class="pl-2">
                    <h4>Step 000</h4>
                  </div>
                  <div>
                    <h4>Desc: {{selectedTemplate[0]['000'].templateData[0].Description}}</h4>
                  </div>
                  <div>
                    <h4>Initial Status: {{selectedTemplate[0]['000'].templateData[0].InitialStatusCode}}</h4>
                  </div>
                  <div *ngIf="showReviewStep000" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-down"></i>
                  </div>
                  <div *ngIf="!showReviewStep000" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-left"></i>
                  </div>
                </div>
                <ng-container *ngIf="showReviewStep000">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty Required</th>
                      <th>Comment</th>
                      <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let line of selectedTemplate[0]['000'].templateLines; index as index">
                      <tr>
                        <td>{{line.TemplateNo}}</td>
                        <td>{{line.RevisionNo}}</td>
                        <td>{{line.WTStep}}</td>
                        <td>{{line.ItemCode}}</td>
                        <td
                          class="w-25">{{line.ItemCodeDesc.length >= 30 ? line.ExtendedDescriptionText : line.ItemCodeDesc}}</td>
                        <td>{{line.QuantityRequired}}</td>
                        <td>{{line.CommentText}}</td>
                        <td>{{line.UDF_LOCATION}}</td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </ng-container>
              </li>
              <li *ngIf="selectedTemplate[0]['005'].templateLines.length> 0" class="list-group-item w-100">
                <div
                  class="row w-100 mx-auto bg-primary font-weight-bold text-light p-1 d-flex justify-content-between align-items-center"
                  (click)="showReviewStep005= !showReviewStep005">
                  <div class="pl-2">
                    <h4>Step 005</h4>
                  </div>
                  <div>
                    <h4>Desc: {{selectedTemplate[0]['005'].templateData[0].Description}}</h4>
                  </div>
                  <div>
                    <h4>Initial Status: {{selectedTemplate[0]['005'].templateData[0].InitialStatusCode}}</h4>
                  </div>
                  <div *ngIf="showReviewStep005" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-down"></i>
                  </div>
                  <div *ngIf="!showReviewStep005" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-left"></i>
                  </div>
                </div>
                <ng-container *ngIf="showReviewStep005">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty Required</th>
                      <th>Comment</th>
                      <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let line of selectedTemplate[0]['005'].templateLines; index as index">
                      <tr>
                        <td>{{line.TemplateNo}}</td>
                        <td>{{line.RevisionNo}}</td>
                        <td>{{line.WTStep}}</td>
                        <td>{{line.ItemCode}}</td>
                        <td
                          class="w-25">{{line.ItemCodeDesc.length >= 30 ? line.ExtendedDescriptionText : line.ItemCodeDesc}}</td>
                        <td>{{line.QuantityRequired}}</td>
                        <td>{{line.CommentText}}</td>
                        <td>{{line.UDF_LOCATION}}</td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </ng-container>
              </li>
              <li *ngIf="selectedTemplate[0]['006'].templateLines.length> 0" class="list-group-item w-100">
                <div
                  class="row w-100 mx-auto bg-primary font-weight-bold text-light p-1 d-flex justify-content-between align-items-center"
                  (click)="showReviewStep006= !showReviewStep006">
                  <div class="pl-2">
                    <h4>Step 006</h4>
                  </div>
                  <div>
                    <h4>Desc: {{selectedTemplate[0]['006'].templateData[0].Description}}</h4>
                  </div>
                  <div>
                    <h4>Initial Status: {{selectedTemplate[0]['006'].templateData[0].InitialStatusCode}}</h4>
                  </div>
                  <div *ngIf="showReviewStep006" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-down"></i>
                  </div>
                  <div *ngIf="!showReviewStep006" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-left"></i>
                  </div>
                </div>
                <ng-container *ngIf="showReviewStep006">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty Required</th>
                      <th>Comment</th>
                      <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let line of selectedTemplate[0]['006'].templateLines; index as index">
                      <tr>
                        <td>{{line.TemplateNo}}</td>
                        <td>{{line.RevisionNo}}</td>
                        <td>{{line.WTStep}}</td>
                        <td>{{line.ItemCode}}</td>
                        <td
                          class="w-25">{{line.ItemCodeDesc.length >= 30 ? line.ExtendedDescriptionText : line.ItemCodeDesc}}</td>
                        <td>{{line.QuantityRequired}}</td>
                        <td>{{line.CommentText}}</td>
                        <td>{{line.UDF_LOCATION}}</td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </ng-container>
              </li>
              <li *ngIf="selectedTemplate[0]['010'].templateLines.length> 0" class="list-group-item w-100">
                <div
                  class="row w-100 mx-auto bg-primary font-weight-bold text-light p-1 d-flex justify-content-between align-items-center"
                  (click)="showReviewStep010= !showReviewStep010">
                  <div class="pl-2">
                    <h4>Step 010</h4>
                  </div>
                  <div>
                    <h4>Desc: {{selectedTemplate[0]['010'].templateData[0].Description}}</h4>
                  </div>
                  <div>
                    <h4>Initial Status: {{selectedTemplate[0]['010'].templateData[0].InitialStatusCode}}</h4>
                  </div>
                  <div *ngIf="showReviewStep010" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-down"></i>
                  </div>
                  <div *ngIf="!showReviewStep010" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-left"></i>
                  </div>
                </div>
                <ng-container *ngIf="showReviewStep010">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty Required</th>
                      <th>Comment</th>
                      <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let line of selectedTemplate[0]['010'].templateLines; index as index">
                      <tr>
                        <td>{{line.TemplateNo}}</td>
                        <td>{{line.RevisionNo}}</td>
                        <td>{{line.WTStep}}</td>
                        <td>{{line.ItemCode}}</td>
                        <td
                          class="w-25">{{line.ItemCodeDesc.length >= 30 ? line.ExtendedDescriptionText : line.ItemCodeDesc}}</td>
                        <td>{{line.QuantityRequired}}</td>
                        <td>{{line.CommentText}}</td>
                        <td>{{line.UDF_LOCATION}}</td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </ng-container>
              </li>
              <li *ngIf="selectedTemplate[0]['020'].templateLines.length> 0" class="list-group-item w-100">
                <div
                  class="row w-100 mx-auto bg-primary font-weight-bold text-light p-1 d-flex justify-content-between align-items-center"
                  (click)="showReviewStep020= !showReviewStep020">
                  <div class="pl-2">
                    <h4>Step 020</h4>
                  </div>
                  <div>
                    <h4>Desc: {{selectedTemplate[0]['020'].templateData[0].Description}}</h4>
                  </div>
                  <div>
                    <h4>Initial Status: {{selectedTemplate[0]['020'].templateData[0].InitialStatusCode}}</h4>
                  </div>
                  <div *ngIf="showReviewStep020" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-down"></i>
                  </div>
                  <div *ngIf="!showReviewStep020" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-left"></i>
                  </div>
                </div>
                <ng-container *ngIf="showReviewStep020">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty Required</th>
                      <th>Comment</th>
                      <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let line of selectedTemplate[0]['020'].templateLines; index as index">
                      <tr>
                        <td>{{line.TemplateNo}}</td>
                        <td>{{line.RevisionNo}}</td>
                        <td>{{line.WTStep}}</td>
                        <td>{{line.ItemCode}}</td>
                        <td
                          class="w-25">{{line.ItemCodeDesc.length >= 30 ? line.ExtendedDescriptionText : line.ItemCodeDesc}}</td>
                        <td>{{line.QuantityRequired}}</td>
                        <td>{{line.CommentText}}</td>
                        <td>{{line.UDF_LOCATION}}</td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </ng-container>
              </li>
            </ul>
          </ng-container>
          <!--  * ==TAB REVIEW END== Review Step -->

          <!-- *BEGINNING Bottom Navigation Buttons -->
          <div *ngIf="userSelectedTemplate" class="row d-flex justify-content-between mt-5">
            <div>
              <button type="button" class="btn btn-primary btn-lg ml-5" (click)="onClickPreviousButton()">Previous
              </button>
            </div>
            <div>
              <button type="button" class="btn btn-primary btn-lg ml-5" (click)="onResetData()">Cancel Template Edit
              </button>
            </div>
            <div *ngIf="!this.selectionTabReview">
              <button type="button" class="btn btn-primary btn-lg mr-5" (click)="onClickNextButton()">Next</button>
            </div>
            <div *ngIf="this.selectionTabReview">
              <button type="button" (click)="onSubmitTemplateChanges()" class="btn btn-primary btn-lg mr-5">Finish
              </button>
            </div>
          </div>
          <!-- *END of Bottom Navigation Buttons -->
        </ng-container>
        <!-- !!! END of Editing Existing Templates !!! -->


        <!-- ! BEGINNING of Create New Template Layout -->
        <ng-container *ngIf="creatingNewTemplate">
          <!-- * BEGINNING of Info Tab -->
          <ng-container *ngIf="selectionTabInfo">
            <h3>Create New Template</h3>
          </ng-container>
          <!-- * END of Info Tab -->

          <!-- * BEGINNING of NEW TEMPLATE TAB 000 -->
          <ng-container *ngIf="selectionTab000">
            <form class="container">
              <!-- * BEGINNING of New Template Data -->
              <div class="row">
                <h4>Add Warehouse/QA Items</h4>
              </div>
              <div *ngIf="templateNumbersMatchError" class="row">
                <h3 class="text-warning"><span><i class="fas fa-exclamation-circle"></i></span> That template number
                  exists already, please use another number <span><i class="fas fa-exclamation-circle"></i></span></h3>
              </div>
              <div class="row">
                <div class="col-2">
                  <div class="form-group form-group-horizontal">
                    <label for="create-new-template-no">TemplateNo</label>
                    <input type="text" (change)="onSetTemplateNumberOnAllSteps($event.target.value, 'new')"
                           class="form-control" name="create-new-template-no" id="create-new-template-no"
                           aria-describedby="create-new-template-no"
                           [(ngModel)]="newBlankTemplate[0]['000'].templateData[0].TemplateNo"
                           placeholder="i.e. RI-BASE 621">
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label for="create-new-initial-status">Initial Status</label>
                    <input type="text" [(ngModel)]="newBlankTemplate[0]['000'].templateData[0].InitialStatusCode"
                           [disabled]="this.templateNumbersMatchError"
                           class="form-control" name="create-new-initial-status" id="create-new-initial-status"
                           aria-describedby="create-new-initial-status" placeholder="i.e. TR">
                  </div>
                </div>
                <div class="form-group col-6">
                  <label for="create-new-description">Description</label>
                  <textarea class="form-control" name="create-new-description"
                            [(ngModel)]="newBlankTemplate[0]['000'].templateData[0].Description"
                            [disabled]="this.templateNumbersMatchError" id="create-new-description" rows="1"></textarea>
                </div>
              </div>
              <!-- * END of New Template Data -->
            </form>
            <div class="container">
              <div style="background-color: lightgray" class="row pt-1">
                <div *ngIf="newBlankTemplate[0]['000'].templateData[0].TemplateNo.length <1" class="col-12 text-center">
                  <h3>Please Provide a Template Number before adding items</h3>
                </div>
                <ng-container
                  *ngIf="!templateNumbersMatchError && newBlankTemplate[0]['000'].templateData[0].TemplateNo.length> 1">
                  <div class="col-3">
                    <div class="form-group">
                      <label for="create-new-item-code">Item Code</label>
                      <input type="text" name="create-new-item-code" class="form-control"
                             aria-describedby="create-new-item-code" placeholder="Item Code"
                             [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                             (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                             [typeahead]="templateItemList"
                             typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                             [typeaheadOptionsInScrollableView]="20" autocomplete="off">
                    </div>
                  </div>
                  <div class="col-1">
                    <div class="form-group">
                      <label for="create-new-quantity-required">Quantity</label>
                      <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                             class="form-control" name="create-new-quantity-required" id="create-new-quantity-required"
                             aria-describedby="create-new-quantity-required" placeholder="i.e. 8">
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <label for="create-new-item-location">Location</label>
                      <input type="text" name="create-new-item-location" class="form-control"
                             aria-describedby="create-new-item-location" placeholder="Item Location"
                             [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                             [typeahead]="locationList"
                             typeaheadOptionField="Location" autocomplete="off">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label for="create-new-item-comment">Comment</label>
                      <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                name="create-new-item-comment" id="create-new-item-comment" rows="1"></textarea>
                    </div>
                  </div>
                  <div class="col-1 mt-4" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                       (click)="onExplodeKitItems()">
                  <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                    <i class="fas fa-bomb fa-2x text-dark"></i>
                  </span>
                    <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                    <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                    <i class="fas fa-ban fa-stack-2x text-primary"></i>
                  </span>
                  </div>
                  <div class="col-1">
                    <div class="form-group mt-4">
                      <button type="button" class="btn btn-primary btn-primary" (click)="onAddItemsToBlankTemplate('000')"
                              [disabled]="templateItemsToAddToTemplate.ItemCode === '' || templateItemsToAddToTemplate.QuantityRequired === ''">
                        Add
                      </button>
                    </div>
                  </div>
                </ng-container>
              </div>

              <ng-container *ngIf="newBlankTemplate[0]['000'].templateLines.length> 0">
                <table class="table">
                  <thead class="thead-dark">
                  <tr>
                    <th>TemplateNo</th>
                    <th>RevisionNo</th>
                    <th>Step</th>
                    <th>ItemCode</th>
                    <th>Desc</th>
                    <th>Quantity</th>
                    <th>Comment</th>
                    <th>Location</th>
                    <th>Edit</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let line of newBlankTemplate[0]['000'].templateLines; index as index">
                    <td>{{line.TemplateNo}}</td>
                    <td>{{line.RevisionNo}}</td>
                    <td>{{line.WTStep}}</td>
                    <td>{{line.ItemCode}}</td>
                    <td>{{line.ItemCodeDesc}}</td>
                    <td>{{line.QuantityRequired}}</td>
                    <td>{{line.CommentText}}</td>
                    <td>{{line.UDF_LOCATION}}</td>
                    <td>
                      <button type="button" class="btn btn-primary btnsm"
                              (click)="onRemoveItemFromTemplate(line, index, 'new')">X
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </ng-container>
          <!-- * END OF NEW TEMPLATE TAB 000 -->

          <!-- * BEGINNING of NEW TEMPLATE TAB 005 -->
          <ng-container *ngIf="selectionTab005">
            <button type="button" class="btn btn-primary btn-lg"
                    *ngIf="newBlankTemplate[0]['005']['templateData'][0]['created'] !== true"
                    (click)="newBlankTemplate[0]['005']['templateData'][0]['created']= true">Add Step 005
            </button>
            <ng-container *ngIf="newBlankTemplate[0]['005']['templateData'][0]['created'] === true">
              <form class="container">
                <!-- * BEGINNING of New Template Data -->
                <div class="row d-flex justify-content-between">
                  <h4>Optional Step 005</h4>
                  <button type="button" class="btn btn-primary"
                          (click)="newBlankTemplate[0]['005']['templateData']['0']['created']= false">Cancel Adding Step
                    005
                  </button>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div class="form-group form-group-horizontal">
                      <label for="create-new-template-no">TemplateNo</label>
                      <input type="text"
                             class="form-control" name="create-new-template-no" id="create-new-template-no"
                             aria-describedby="create-new-template-no" disabled
                             [placeholder]="newBlankTemplate[0]['000'].templateData[0].TemplateNo">
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <label for="create-new-initial-status">Initial Status</label>
                      <input type="text" [(ngModel)]="newBlankTemplate[0]['005'].templateData[0].InitialStatusCode"
                             class="form-control" name="create-new-initial-status" id="create-new-initial-status"
                             aria-describedby="create-new-initial-status" placeholder="i.e. TR">
                    </div>
                  </div>
                  <div class="form-group col-6">
                    <label for="create-new-description">Description</label>
                    <textarea class="form-control" name="create-new-description"
                              [(ngModel)]="newBlankTemplate[0]['005'].templateData[0].Description"
                              id="create-new-description" rows="1"></textarea>
                  </div>
                </div>
                <!-- * END of New Template Data -->
              </form>
              <div class="container">
                <div style="background-color: lightgray" class="row pt-1">
                  <div *ngIf="newBlankTemplate[0]['000'].templateData[0].TemplateNo.length <1" class="col-12 text-center">
                    <h3>Please Provide a Template Number before adding items</h3>
                  </div>
                  <ng-container *ngIf="newBlankTemplate[0]['000'].templateData[0].TemplateNo.length> 1">
                    <div class="col-3">
                      <div class="form-group">
                        <label for="create-new-item-code">Item Code</label>
                        <input type="text" name="create-new-item-code" class="form-control"
                               aria-describedby="create-new-item-code" placeholder="Item Code"
                               [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                               (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                               [typeahead]="templateItemList"
                               typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                               [typeaheadOptionsInScrollableView]="20" autocomplete="off">
                      </div>
                    </div>
                    <div class="col-1">
                      <div class="form-group">
                        <label for="create-new-quantity-required">Quantity</label>
                        <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                               class="form-control" name="create-new-quantity-required" id="create-new-quantity-required"
                               aria-describedby="create-new-quantity-required" placeholder="i.e. 8">
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <label for="create-new-item-location">Location</label>
                        <input type="text" name="create-new-item-location" class="form-control"
                               aria-describedby="create-new-item-location" placeholder="Item Location"
                               [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                               [typeahead]="locationList"
                               typeaheadOptionField="Location" autocomplete="off">
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label for="create-new-item-comment">Comment</label>
                        <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                  name="create-new-item-comment" id="create-new-item-comment" rows="1"></textarea>
                      </div>
                    </div>
                    <div class="col-1 mt-4" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                         (click)="onExplodeKitItems()">
                    <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                      <i class="fas fa-bomb fa-2x text-dark"></i>
                    </span>
                      <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                      <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                      <i class="fas fa-ban fa-stack-2x text-primary"></i>
                    </span>
                    </div>
                    <div class="col-1">
                      <div class="form-group mt-4">
                        <button type="button" class="btn btn-primary btn-primary"
                                (click)="onAddItemsToBlankTemplate('005')"
                                [disabled]="templateItemsToAddToTemplate.ItemCode === '' || templateItemsToAddToTemplate.QuantityRequired === ''">
                          Add
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <ng-container *ngIf="newBlankTemplate[0]['005'].templateLines.length> 0">
                  <table class="table">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Quantity</th>
                      <th>Comment</th>
                      <th>Location</th>
                      <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let line of newBlankTemplate[0]['005'].templateLines; index as index">
                      <td>{{line.TemplateNo}}</td>
                      <td>{{line.RevisionNo}}</td>
                      <td>{{line.WTStep}}</td>
                      <td>{{line.ItemCode}}</td>
                      <td>{{line.ItemCodeDesc}}</td>
                      <td>{{line.QuantityRequired}}</td>
                      <td>{{line.CommentText}}</td>
                      <td>{{line.UDF_LOCATION}}</td>
                      <td>
                        <button type="button" class="btn btn-primary btnsm"
                                (click)="onRemoveItemFromTemplate(line, index, 'new')">X
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <!-- * ENDING OF NEW TEMPLATE TAB 005 -->

          <!-- * BEGINNING OF NEW TEMPLATE TAB 006 -->
          <ng-container *ngIf="selectionTab006">
            <button type="button" class="btn btn-primary btn-lg"
                    *ngIf="newBlankTemplate[0]['006']['templateData'][0]['created'] !== true"
                    (click)="newBlankTemplate[0]['006']['templateData'][0]['created']= true">Add Step 006
            </button>
            <ng-container *ngIf="newBlankTemplate[0]['006']['templateData'][0]['created'] === true">
              <form class="container">
                <!-- * BEGINNING of New Template Data -->
                <div class="row d-flex justify-content-between">
                  <h4>Optional Step 006</h4>
                  <button type="button" class="btn btn-primary"
                          (click)="newBlankTemplate[0]['006']['templateData']['0']['created']= false">Cancel Adding Step
                    006
                  </button>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div class="form-group form-group-horizontal">
                      <label for="create-new-template-no">TemplateNo</label>
                      <input type="text"
                             class="form-control" name="create-new-template-no" id="create-new-template-no"
                             aria-describedby="create-new-template-no" disabled
                             [placeholder]="newBlankTemplate[0]['006'].templateData[0].TemplateNo">
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <label for="create-new-initial-status">Initial Status</label>
                      <input type="text" [(ngModel)]="newBlankTemplate[0]['006'].templateData[0].InitialStatusCode"
                             class="form-control" name="create-new-initial-status" id="create-new-initial-status"
                             aria-describedby="create-new-initial-status" placeholder="i.e. TR">
                    </div>
                  </div>
                  <div class="form-group col-6">
                    <label for="create-new-description">Description</label>
                    <textarea class="form-control" name="create-new-description"
                              [(ngModel)]="newBlankTemplate[0]['006'].templateData[0].Description"
                              id="create-new-description" rows="1"></textarea>
                  </div>
                </div>
                <!-- * END of New Template Data -->
              </form>
              <div class="container">
                <div style="background-color: lightgray" class="row pt-1">
                  <div *ngIf="newBlankTemplate[0]['006'].templateData[0].TemplateNo.length <1" class="col-12 text-center">
                    <h3>Please Provide a Template Number before adding items</h3>
                  </div>
                  <ng-container *ngIf="newBlankTemplate[0]['006'].templateData[0].TemplateNo.length> 1">
                    <div class="col-3">
                      <div class="form-group">
                        <label for="create-new-item-code">Item Code</label>
                        <input type="text" name="create-new-item-code" class="form-control"
                               aria-describedby="create-new-item-code" placeholder="Item Code"
                               [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                               (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                               [typeahead]="templateItemList"
                               typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                               [typeaheadOptionsInScrollableView]="5" autocomplete="off">
                      </div>
                    </div>
                    <div class="col-1">
                      <div class="form-group">
                        <label for="create-new-quantity-required">Quantity</label>
                        <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                               class="form-control" name="create-new-quantity-required" id="create-new-quantity-required"
                               aria-describedby="create-new-quantity-required" placeholder="i.e. 8">
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <label for="create-new-item-location">Location</label>
                        <input type="text" name="create-new-item-location" class="form-control"
                               aria-describedby="create-new-item-location" placeholder="Item Location"
                               [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                               [typeahead]="locationList"
                               typeaheadOptionField="Location" autocomplete="off">
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label for="create-new-item-comment">Comment</label>
                        <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                  name="create-new-item-comment" id="create-new-item-comment" rows="1"></textarea>
                      </div>
                    </div>
                    <div class="col-1 mt-4" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                         (click)="onExplodeKitItems()">
                    <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                      <i class="fas fa-bomb fa-2x text-dark"></i>
                    </span>
                      <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                      <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                      <i class="fas fa-ban fa-stack-2x text-primary"></i>
                    </span>
                    </div>
                    <div class="col-1">
                      <div class="form-group mt-4">
                        <button type="button" class="btn btn-primary btn-primary"
                                (click)="onAddItemsToBlankTemplate('006')"
                                [disabled]="templateItemsToAddToTemplate.ItemCode === '' || templateItemsToAddToTemplate.QuantityRequired === ''">
                          Add
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <ng-container *ngIf="newBlankTemplate[0]['006'].templateLines.length> 0">
                  <table class="table">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Quantity</th>
                      <th>Comment</th>
                      <th>Location</th>
                      <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let line of newBlankTemplate[0]['006'].templateLines; index as index">
                      <td>{{line.TemplateNo}}</td>
                      <td>{{line.RevisionNo}}</td>
                      <td>{{line.WTStep}}</td>
                      <td>{{line.ItemCode}}</td>
                      <td>{{line.ItemCodeDesc}}</td>
                      <td>{{line.QuantityRequired}}</td>
                      <td>{{line.CommentText}}</td>
                      <td>{{line.UDF_LOCATION}}</td>
                      <td>
                        <button type="button" class="btn btn-primary btnsm"
                                (click)="onRemoveItemFromTemplate(line, index, 'new')">X
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <!-- * END OF NEW TEMPLATE TAB 006 -->

          <!-- * BEGINNING OF NEW TEMPLATE TAB 010 -->
          <ng-container *ngIf="selectionTab010">
            <button type="button" class="btn btn-primary btn-lg"
                    *ngIf="newBlankTemplate[0]['010']['templateData'][0]['created'] !== true"
                    (click)="newBlankTemplate[0]['010']['templateData'][0]['created']= true">Add Step 010
            </button>
            <ng-container *ngIf="newBlankTemplate[0]['010']['templateData'][0]['created'] === true">
              <form class="container">
                <!-- * BEGINNING of New Template Data -->
                <div class="row d-flex justify-content-between">
                  <h4>Add Materials</h4>
                  <button type="button" class="btn btn-primary"
                          (click)="newBlankTemplate[0]['010']['templateData']['0']['created']= false">Cancel Adding Step
                    010
                  </button>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div class="form-group form-group-horizontal">
                      <label for="create-new-template-no">TemplateNo</label>
                      <input type="text"
                             class="form-control" name="create-new-template-no" id="create-new-template-no"
                             aria-describedby="create-new-template-no" disabled
                             [placeholder]="newBlankTemplate[0]['010'].templateData[0].TemplateNo">
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <label for="create-new-initial-status">Initial Status</label>
                      <input type="text" [(ngModel)]="newBlankTemplate[0]['010'].templateData[0].InitialStatusCode"
                             class="form-control" name="create-new-initial-status" id="create-new-initial-status"
                             aria-describedby="create-new-initial-status" placeholder="i.e. TR">
                    </div>
                  </div>
                  <div class="form-group col-6">
                    <label for="create-new-description">Description</label>
                    <textarea class="form-control" name="create-new-description"
                              [(ngModel)]="newBlankTemplate[0]['010'].templateData[0].Description"
                              id="create-new-description" rows="1"></textarea>
                  </div>
                </div>
                <!-- * END of New Template Data -->
              </form>
              <div class="container">
                <div style="background-color: lightgray" class="row pt-1">
                  <div *ngIf="newBlankTemplate[0]['010'].templateData[0].TemplateNo.length <1" class="col-12 text-center">
                    <h3>Please Provide a Template Number before adding items</h3>
                  </div>
                  <ng-container *ngIf="newBlankTemplate[0]['010'].templateData[0].TemplateNo.length> 1">
                    <div class="col-3">
                      <div class="form-group">
                        <label for="create-new-item-code">Item Code</label>
                        <input type="text" name="create-new-item-code" class="form-control"
                               aria-describedby="create-new-item-code" placeholder="Item Code"
                               [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                               (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                               [typeahead]="templateItemList"
                               typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                               [typeaheadOptionsInScrollableView]="20" autocomplete="off">
                      </div>
                    </div>
                    <div class="col-1">
                      <div class="form-group">
                        <label for="create-new-quantity-required">Quantity</label>
                        <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                               class="form-control" name="create-new-quantity-required" id="create-new-quantity-required"
                               aria-describedby="create-new-quantity-required" placeholder="i.e. 8">
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <label for="create-new-item-location">Location</label>
                        <input type="text" name="create-new-item-location" class="form-control"
                               aria-describedby="create-new-item-location" placeholder="Item Location"
                               [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                               [typeahead]="locationList"
                               typeaheadOptionField="Location" autocomplete="off">
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label for="create-new-item-comment">Comment</label>
                        <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                  name="create-new-item-comment" id="create-new-item-comment" rows="1"></textarea>
                      </div>
                    </div>
                    <div class="col-1 mt-4" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                         (click)="onExplodeKitItems()">
                    <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                      <i class="fas fa-bomb fa-2x text-dark"></i>
                    </span>
                      <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                      <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                      <i class="fas fa-ban fa-stack-2x text-primary"></i>
                    </span>
                    </div>
                    <div class="col-1">
                      <div class="form-group mt-4">
                        <button type="button" class="btn btn-primary btn-primary"
                                (click)="onAddItemsToBlankTemplate('010')"
                                [disabled]="templateItemsToAddToTemplate.ItemCode === '' || templateItemsToAddToTemplate.QuantityRequired === ''">
                          Add
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <ng-container *ngIf="newBlankTemplate[0]['010'].templateLines.length> 0">
                  <table class="table">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Quantity</th>
                      <th>Comment</th>
                      <th>Location</th>
                      <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let line of newBlankTemplate[0]['010'].templateLines; index as index">
                      <td>{{line.TemplateNo}}</td>
                      <td>{{line.RevisionNo}}</td>
                      <td>{{line.WTStep}}</td>
                      <td>{{line.ItemCode}}</td>
                      <td>{{line.ItemCodeDesc}}</td>
                      <td>{{line.QuantityRequired}}</td>
                      <td>{{line.CommentText}}</td>
                      <td>{{line.UDF_LOCATION}}</td>
                      <td>
                        <button type="button" class="btn btn-primary btnsm"
                                (click)="onRemoveItemFromTemplate(line, index, 'new')">X
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <!-- * END OF NEW TEMPLATE TAB 010 -->

          <!-- * BEGINNING OF NEW TEMPLATE TAB 020 -->
          <ng-container *ngIf="selectionTab020">
            <button type="button" class="btn btn-primary btn-lg"
                    *ngIf="newBlankTemplate[0]['020']['templateData'][0]['created'] !== true"
                    (click)="newBlankTemplate[0]['020']['templateData'][0]['created']= true">Add Step 020
            </button>
            <ng-container *ngIf="newBlankTemplate[0]['020']['templateData'][0]['created'] === true">
              <form class="container">
                <!-- * BEGINNING of New Template Data -->
                <div class="row d-flex justify-content-between">
                  <h4>Add Labor</h4>
                  <button type="button" class="btn btn-primary"
                          (click)="newBlankTemplate[0]['020']['templateData']['0']['created']= false">Cancel Adding Step
                    020
                  </button>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div class="form-group form-group-horizontal">
                      <label for="create-new-template-no">TemplateNo</label>
                      <input type="text"
                             class="form-control" name="create-new-template-no" id="create-new-template-no"
                             aria-describedby="create-new-template-no" disabled
                             [placeholder]="newBlankTemplate[0]['020'].templateData[0].TemplateNo">
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <label for="create-new-initial-status">Initial Status</label>
                      <input type="text" [(ngModel)]="newBlankTemplate[0]['020'].templateData[0].InitialStatusCode"
                             class="form-control" name="create-new-initial-status" id="create-new-initial-status"
                             aria-describedby="create-new-initial-status" placeholder="i.e. TR">
                    </div>
                  </div>
                  <div class="form-group col-6">
                    <label for="create-new-description">Description</label>
                    <textarea class="form-control" name="create-new-description"
                              [(ngModel)]="newBlankTemplate[0]['020'].templateData[0].Description"
                              id="create-new-description" rows="1"></textarea>
                  </div>
                </div>
                <!-- * END of New Template Data -->
              </form>
              <div class="container">
                <div style="background-color: lightgray" class="row pt-1">
                  <div *ngIf="newBlankTemplate[0]['020'].templateData[0].TemplateNo.length <1" class="col-12 text-center">
                    <h3>Please Provide a Template Number before adding items</h3>
                  </div>
                  <ng-container *ngIf="newBlankTemplate[0]['020'].templateData[0].TemplateNo.length> 1">
                    <div class="col-3">
                      <div class="form-group">
                        <label for="create-new-item-code">Item Code</label>
                        <input type="text" name="create-new-item-code" class="form-control"
                               aria-describedby="create-new-item-code" placeholder="Item Code"
                               [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                               (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                               [typeahead]="templateItemList"
                               typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                               [typeaheadOptionsInScrollableView]="5" autocomplete="off">
                      </div>
                    </div>
                    <div class="col-1">
                      <div class="form-group">
                        <label for="create-new-quantity-required">Quantity</label>
                        <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                               class="form-control" name="create-new-quantity-required" id="create-new-quantity-required"
                               aria-describedby="create-new-quantity-required" placeholder="i.e. 8">
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <label for="create-new-item-location">Location</label>
                        <input type="text" name="create-new-item-location" class="form-control"
                               aria-describedby="create-new-item-location" placeholder="Item Location"
                               [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                               [typeahead]="locationList"
                               typeaheadOptionField="Location" autocomplete="off">
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label for="create-new-item-comment">Comment</label>
                        <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                  name="create-new-item-comment" id="create-new-item-comment" rows="1"></textarea>
                      </div>
                    </div>
                    <div class="col-1 mt-4" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                         (click)="onExplodeKitItems()">
                    <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                      <i class="fas fa-bomb fa-2x text-dark"></i>
                    </span>
                      <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                      <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                      <i class="fas fa-ban fa-stack-2x text-primary"></i>
                    </span>
                    </div>
                    <div class="col-1">
                      <div class="form-group mt-4">
                        <button type="button" class="btn btn-primary btn-primary"
                                (click)="onAddItemsToBlankTemplate('020')"
                                [disabled]="templateItemsToAddToTemplate.ItemCode === '' || templateItemsToAddToTemplate.QuantityRequired === ''">
                          Add
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <ng-container *ngIf="newBlankTemplate[0]['020'].templateLines.length> 0">
                  <table class="table">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Quantity</th>
                      <th>Comment</th>
                      <th>Location</th>
                      <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let line of newBlankTemplate[0]['020'].templateLines; index as index">
                      <td>{{line.TemplateNo}}</td>
                      <td>{{line.RevisionNo}}</td>
                      <td>{{line.WTStep}}</td>
                      <td>{{line.ItemCode}}</td>
                      <td>{{line.ItemCodeDesc}}</td>
                      <td>{{line.QuantityRequired}}</td>
                      <td>{{line.CommentText}}</td>
                      <td>{{line.UDF_LOCATION}}</td>
                      <td>
                        <button type="button" class="btn btn-primary btnsm"
                                (click)="onRemoveItemFromTemplate(line, index, 'new')">X
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <!-- * END OF NEW TEMPLATE TAB 020 -->

          <!-- * BEGINNING OF NEW TEMPLATE REVIEW -->
          <ng-container *ngIf="selectionTabReview">
            <div>
              <div class="row text-center"><h4>
                TemplateNo: {{newBlankTemplate[0]['000']['templateData'][0].TemplateNo}}</h4></div>
              <div>
                <ul class="list-group">
                  <!-- ! Step 000 Begin -->
                  <li class="list-group-item col-12 bg-primary text-light p-1 font-weight-bold">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5>Step 000</h5>
                      </div>
                      <div>
                        <h5>Initial Status: {{newBlankTemplate[0]['000']['templateData'][0].InitialStatusCode}}</h5>
                      </div>
                      <div>
                        <h5>Desc: {{newBlankTemplate[0]['000']['templateData'][0].Description}}</h5>
                      </div>
                      <div>
                        <button type="button" class="btn btn-primary btn-sm"
                                (click)="showReviewStep000= !showReviewStep000">
                        <span *ngIf="showReviewStep000" style="font-size: 1.5rem;"><i
                          class="far fa-caret-square-down"></i></span>
                          <span *ngIf="!showReviewStep000" style="font-size: 1.5rem;"><i
                            class="far fa-caret-square-left"></i></span>
                        </button>
                      </div>
                    </div>
                  </li>
                  <li *ngIf="showReviewStep000" class="list-group-item">
                    <table class="table table-dark text-light tabl-borderless tabl-sm">
                      <tbody>
                      <tr *ngFor="let line of newBlankTemplate[0]['000']['templateLines']; index as index"
                          class="text-sm">
                        <ng-container>
                          <td>
                            {{line.ItemCode}}
                          </td>
                          <td>
                            {{line.ItemCodeDesc}}
                          </td>
                          <td>
                            {{line.QuantityRequired}}
                          </td>
                          <td>
                            {{line.UDF_LOCATION}}
                          </td>
                          <td>{{line.CommentText}}</td>
                        </ng-container>
                      </tr>
                      </tbody>
                    </table>
                  </li>
                  <!-- ! Step 000 END -->

                  <!-- ! Step 005 Begin -->
                  <li class="list-group-item col-12 bg-primary text-light p-1 font-weight-bold">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5>Step 005</h5>
                      </div>
                      <div>
                        <h5>Initial Status: {{newBlankTemplate[0]['005']['templateData'][0].InitialStatusCode}}</h5>
                      </div>
                      <div>
                        <h5>Desc: {{newBlankTemplate[0]['005']['templateData'][0].Description}}</h5>
                      </div>
                      <div>
                        <button type="button" class="btn btn-primary btn-sm"
                                (click)="showReviewStep005= !showReviewStep005">
                        <span *ngIf="showReviewStep005" style="font-size: 1.5rem;"><i
                          class="far fa-caret-square-down"></i></span>
                          <span *ngIf="!showReviewStep005" style="font-size: 1.5rem;"><i
                            class="far fa-caret-square-left"></i></span>
                        </button>
                      </div>
                    </div>
                  </li>
                  <li *ngIf="showReviewStep005" class="list-group-item">
                    <table class="table table-dark text-light tabl-borderless tabl-sm">
                      <tbody>
                      <tr *ngFor="let line of newBlankTemplate[0]['005']['templateLines']; index as index"
                          class="text-sm">
                        <ng-container>
                          <td>
                            {{line.ItemCode}}
                          </td>
                          <td>
                            {{line.ItemCodeDesc}}
                          </td>
                          <td>
                            {{line.QuantityRequired}}
                          </td>
                          <td>
                            {{line.UDF_LOCATION}}
                          </td>
                          <td>{{line.CommentText}}</td>
                        </ng-container>
                      </tr>
                      </tbody>
                    </table>
                  </li>
                  <!-- ! Step 005 END -->


                  <!-- ! Step 006 Begin -->
                  <li class="list-group-item col-12 bg-primary text-light p-1 font-weight-bold">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5>Step 006</h5>
                      </div>
                      <div>
                        <h5>Initial Status: {{newBlankTemplate[0]['006']['templateData'][0].InitialStatusCode}}</h5>
                      </div>
                      <div>
                        <h5>Desc: {{newBlankTemplate[0]['006']['templateData'][0].Description}}</h5>
                      </div>
                      <div>
                        <button type="button" class="btn btn-primary btn-sm"
                                (click)="showReviewStep006= !showReviewStep006">
                        <span *ngIf="showReviewStep006" style="font-size: 1.5rem;"><i
                          class="far fa-caret-square-down"></i></span>
                          <span *ngIf="!showReviewStep006" style="font-size: 1.5rem;"><i
                            class="far fa-caret-square-left"></i></span>
                        </button>
                      </div>
                    </div>
                  </li>
                  <li *ngIf="showReviewStep006" class="list-group-item">
                    <table class="table table-dark text-light tabl-borderless tabl-sm">
                      <tbody>
                      <tr *ngFor="let line of newBlankTemplate[0]['006']['templateLines']; index as index"
                          class="text-sm">
                        <ng-container>
                          <td>
                            {{line.ItemCode}}
                          </td>
                          <td>
                            {{line.ItemCodeDesc}}
                          </td>
                          <td>
                            {{line.QuantityRequired}}
                          </td>
                          <td>
                            {{line.UDF_LOCATION}}
                          </td>
                          <td>{{line.CommentText}}</td>
                        </ng-container>
                      </tr>
                      </tbody>
                    </table>
                  </li>
                  <!-- ! Step 006 END -->


                  <!-- ! Step 010 Begin -->
                  <li class="list-group-item col-12 bg-primary text-light p-1 font-weight-bold">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5>Step 010</h5>
                      </div>
                      <div>
                        <h5>Initial Status: {{newBlankTemplate[0]['010']['templateData'][0].InitialStatusCode}}</h5>
                      </div>
                      <div>
                        <h5>Desc: {{newBlankTemplate[0]['010']['templateData'][0].Description}}</h5>
                      </div>
                      <div>
                        <button type="button" class="btn btn-primary btn-sm"
                                (click)="showReviewStep010= !showReviewStep010">
                        <span *ngIf="showReviewStep010" style="font-size: 1.5rem;"><i
                          class="far fa-caret-square-down"></i></span>
                          <span *ngIf="!showReviewStep010" style="font-size: 1.5rem;"><i
                            class="far fa-caret-square-left"></i></span>
                        </button>
                      </div>
                    </div>
                  </li>
                  <li *ngIf="showReviewStep010" class="list-group-item">
                    <table class="table table-dark text-light tabl-borderless tabl-sm">
                      <tbody>
                      <tr *ngFor="let line of newBlankTemplate[0]['010']['templateLines']; index as index"
                          class="text-sm">
                        <ng-container>
                          <td>
                            {{line.ItemCode}}
                          </td>
                          <td>
                            {{line.ItemCodeDesc}}
                          </td>
                          <td>
                            {{line.QuantityRequired}}
                          </td>
                          <td>
                            {{line.UDF_LOCATION}}
                          </td>
                          <td>{{line.CommentText}}</td>
                        </ng-container>
                      </tr>
                      </tbody>
                    </table>
                  </li>
                  <!-- ! Step 010 END -->


                  <!-- ! Step 020 Begin -->
                  <li class="list-group-item col-12 bg-primary text-light p-1 font-weight-bold">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5>Step 020</h5>
                      </div>
                      <div>
                        <h5>Initial Status: {{newBlankTemplate[0]['020']['templateData'][0].InitialStatusCode}}</h5>
                      </div>
                      <div>
                        <h5>Desc: {{newBlankTemplate[0]['020']['templateData'][0].Description}}</h5>
                      </div>
                      <div>
                        <button type="button" class="btn btn-primary btn-sm"
                                (click)="showReviewStep020= !showReviewStep020">
                        <span *ngIf="showReviewStep020" style="font-size: 1.5rem;"><i
                          class="far fa-caret-square-down"></i></span>
                          <span *ngIf="!showReviewStep020" style="font-size: 1.5rem;"><i
                            class="far fa-caret-square-left"></i></span>
                        </button>
                      </div>
                    </div>
                  </li>
                  <li *ngIf="showReviewStep020" class="list-group-item">
                    <table class="table table-dark text-light tabl-borderless tabl-sm">
                      <tbody>
                      <tr *ngFor="let line of newBlankTemplate[0]['020']['templateLines']; index as index"
                          class="text-sm">
                        <ng-container>
                          <td>
                            {{line.ItemCode}}
                          </td>
                          <td>
                            {{line.ItemCodeDesc}}
                          </td>
                          <td>
                            {{line.QuantityRequired}}
                          </td>
                          <td>
                            {{line.UDF_LOCATION}}
                          </td>
                          <td>{{line.CommentText}}</td>
                        </ng-container>
                      </tr>
                      </tbody>
                    </table>
                  </li>
                  <!-- ! Step 020 END -->
                </ul>
              </div>
            </div>
          </ng-container>
          <!-- * END OF NEW TEMPLATE REVIEW -->

          <!-- *BEGINNING Bottom Navigation Buttons -->
          <div class="row d-flex justify-content-between mt-5">
            <div>
              <button type="button" class="btn btn-primary btn-lg ml-5" (click)="onClickPreviousButton()">Previous
              </button>
            </div>
            <div>
              <button type="button" class="btn btn-primary btn-lg ml-5" (click)="onResetData()">Cancel Template Creation
              </button>
            </div>
            <div *ngIf="!this.selectionTabReview">
              <button type="button" class="btn btn-primary btn-lg mr-5" (click)="onClickNextButton()">Next</button>
            </div>
            <div *ngIf="this.selectionTabReview">
              <button type="button" (click)="onSubmitTemplateCreation('new')" class="btn btn-primary btn-lg mr-5">Finish
              </button>
            </div>
          </div>
          <!-- *END of Bottom Navigation Buttons -->
        </ng-container>
        <!-- ! END of Create New Template Layout-->

        <!-- !!! Beginning of Copying Template !!! -->
        <ng-container *ngIf="copyingTemplate">
          <!-- * ==TAB INFO BEGINNING== INFO Default -->
          <ng-container *ngIf="selectionTabInfo">
            <div class="row p-2"><h4>This template copied from: {{templateNumberToCopy}}</h4></div>
            <!-- * Table to Show Selected Template Information -->
            <div *ngIf="selectedTemplateToCopy" class="row">
              <table class="table table-hover">
                <thead class="thead-dark">
                <tr>
                  <th scope="col">Template No.</th>
                  <th scope="col">Step</th>
                  <th scope="col">Initial Status</th>
                  <th scope="col">Desc</th>
                  <th scope="col">Revision No.</th>
                  <th scope="col">Current Revision</th>
                  <th scope="col"># of Parts</th>
                  <th scope="col">Edit</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let step of selectedTemplateToCopy[0] | keyvalue; index as index" id="{{step.value['name']}}">
                  <ng-container *ngIf="step.value['templateData'].length> 0">
                    <td>
                      {{step.value['templateData'][0]['TemplateNo'] || ''}}
                    </td>
                    <td>
                      {{step.value['templateData'][0]['WTStep']}}
                    </td>
                    <td>
                      <label for="template-initial-status" class="sr-only">Initial Status</label>
                      <input style="width: 5rem;" type="text" class="form-control" name="template-initial-status"
                             aria-describedby="template-initial-status" placeholder=""
                             [(ngModel)]="step.value['templateData'][0]['InitialStatusCode']">
                    </td>
                    <td>
                      <label for="template-description" class="sr-only">Description</label>
                      <input type="text" class="form-control" name="template-description"
                             aria-describedby="template-description" placeholder=""
                             [(ngModel)]="step.value['templateData'][0]['Description']">
                    </td>
                    <td>
                      {{step.value['templateData'][0]['RevisionNo']}}
                    </td>
                    <td>{{step.value['templateData'][0]['CurrentRevision']}}</td>
                    <td>{{step.value['templateLines'].length}}</td>
                    <td>
                      <button [disabled]="step.value['templateLines'].length <1" type="button"
                              class="btn btn-primary btn-sm"
                              (click)="moveSelectionTab('Step ' + step.value['templateData'][0]['WTStep'])"
                              title="{{step.value['templateLines'].length <1 ? 'No lines to edit' : 'Edit lines in this step'}}">
                        Edit Step {{step.value['templateData'][0]['WTStep']}}</button>
                    </td>
                  </ng-container>
                </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
          <!-- * ==TAB INFO END== INFO Default -->

          <!-- * ==TAB 000 BEGINNING== Step 000 Default -->
          <ng-container *ngIf="selectionTab000">
            <div class="row">
              <div class="col-12 d-flex justify-content-end p-2">
                <button type="button" class="btn btn-primary btn-lg"
                        (click)="onRemoveAllItemsFromTemplateStep('000', 'copied')">Remove ALL Items From Step 000
                </button>
              </div>
            </div>
            <div class="row">
              <div class="container">
                <table class="table table-hover table-striped">
                  <thead class="thead-dark">
                  <tr>
                    <th>Template</th>
                    <th>Revision</th>
                    <th>ItemCode</th>
                    <th>Desc</th>
                    <th>Qty</th>
                    <th>Comment</th>
                    <th>Location</th>
                    <th>Explode</th>
                    <th>Edit</th>
                  </tr>
                  </thead>
                  <tbody>
                  <!-- * BEGINNING of Add New Item to Step 000 -->
                  <ng-container class="w-100">
                    <tr style="background-color: lightgray;">
                      <td>{{selectedTemplateToCopy[0]['000']['templateData'][0].TemplateNo}}</td>
                      <td>{{selectedTemplateToCopy[0]['000']['templateData'][0].RevisionNo}}</td>
                      <td>
                        <label class="sr-only" for="step000-add-item-code">ItemCodeAdd</label>
                        <input type="text" name="step000-add-item-code" class="form-control w-100"
                               aria-describedby="step000-add-item-code" placeholder="Item Code"
                               [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                               (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                               [typeahead]="templateItemList"
                               typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                               [typeaheadOptionsInScrollableView]="20" autocomplete="off">
                      </td>
                      <td>{{templateItemsToAddToTemplate.ItemCodeDesc}}</td>
                      <td>
                        <div class="form-group">
                          <label for="step000-add-item-qty-req" class="sr-only">Quantity Required</label>
                          <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                                 class="form-control" name="step000-add-item-qty-req" id="step000-add-item-qty-req"
                                 aria-describedby="step000-add-item-qty-req">
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <label for="step000-add-item-comment" class="sr-only">ItemComment</label>
                          <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                    name="step000-add-item-comment" id="step000-add-item-comment" rows="2">Comments go here</textarea>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <label class="sr-only" for="step000-add-item-location">Location</label>
                          <input type="text" name="step000-add-item-location" class="form-control w-100"
                                 aria-describedby="step000-add-item-location" placeholder="Item Location"
                                 [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                                 [typeahead]="locationList"
                                 typeaheadOptionField="Location" autocomplete="off">
                        </div>
                      </td>
                      <td>
                        <div class="col-1 mt-2" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                             (click)="onExplodeKitItems()">
                          <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                            <i class="fas fa-bomb fa-2x text-dark"></i>
                          </span>
                          <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                            <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                            <i class="fas fa-ban fa-stack-2x text-primary"></i>
                          </span>
                        </div>
                      </td>
                      <td>
                        <button type="button" class="btn btn-primary btn-sm"
                                (click)="onAddItemToTemplate('000', 'copied')">Add
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                  <!-- * END of Add New Item to Step 000 -->
                  <ng-container *ngIf="selectedTemplateToCopy[0]['000'].templateLines.length> 0">
                    <tr *ngFor="let line of selectedTemplateToCopy[0]['000'].templateLines; index as index">
                      <td>{{line.TemplateNo}}</td>
                      <td style="width: 50px">
                        {{line.RevisionNo}}
                      </td>
                      <td>
                        {{line.ItemCode}}
                      </td>
                      <td style="width: 175px;">{{line.ItemCodeDesc}}</td>
                      <td style="width: 75px;">
                        <div class="form-group w-100">
                          <label class="sr-only" for="step000-qty-req">QtyRequired</label>
                          <input type="text" class="form-control" name="step000-qty-req"
                                 aria-describedby="step000-qty-req" [(ngModel)]="line.QuantityRequired">
                        </div>
                      </td>
                      <td>
                        <div class="form-group w-100">
                          <label for="step000-comment-text" class="sr-only">CommentText</label>
                          <textarea class="form-control" name="step000-comment-text" rows="2"
                                    [(ngModel)]="line.CommentText"></textarea>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <label class="sr-only" for="step000-location">Location</label>
                          <input type="text" name="step000-location" class="form-control w-100"
                                 aria-describedby="step000-location" placeholder={{line.UDF_LOCATION}}
                                 [(ngModel)]="line.UDF_LOCATION"
                                 (typeaheadOnSelect)="onChangeItemLocation($event, line, index, 'copied')"
                                 [typeahead]="locationList"
                                 typeaheadOptionField="Location" autocomplete="off">
                        </div>
                      </td>
                      <td>
                        {{line.ExplodeBOM === 'Y' ? 'Kit' : ''}}
                      </td>
                      <td>
                        <button type="button" class="btn btn-primary btn-sm"
                                (click)="onRemoveItemFromTemplate(line, index, 'copied')" title="Remove Item">X
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>
          <!-- * ==TAB 000 END== Step 000 Default -->

          <!-- *==TAB 005 BEGINNING== Step 005 User Added Step -->
          <ng-container *ngIf="selectionTab005">
            <div *ngIf="this.selectedTemplateToCopy[0]['005']['templateData'].length <1">
              <h3>This step does not exist on this template</h3>
              <p>Click here to
                <button type="button" class="btn btn-primary btn-sm"
                        (click)="onAddStepToExistingTemplate('005', 'copied')">Add Step 005
                </button>
                to TemplateNo: {{userInputTemplateNumber}}</p>
            </div>
            <ng-container *ngIf="this.selectedTemplateToCopy[0]['005']['templateData'].length> 0">
              <div class="row">
                <div *ngIf="selectedTemplateToCopy[0]['005']['templateLines'].length> 0"
                     class="col-12 d-flex justify-content-end p-2">
                  <button type="button" class="btn btn-primary btn-lg"
                          (click)="onRemoveAllItemsFromTemplateStep('005', 'copied')">Remove ALL Items From Step 005
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="container">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>Template</th>
                      <th>Revision</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty</th>
                      <th>Comment</th>
                      <th>Location</th>
                      <th>Explode</th>
                      <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <!-- * BEGINNING of Add New Item to Step 005 -->
                    <ng-container class="w-100">
                      <tr style="background-color: lightgray;">
                        <td>{{selectedTemplateToCopy[0]['005']['templateData'][0].TemplateNo}}</td>
                        <td>{{selectedTemplateToCopy[0]['005']['templateData'][0].RevisionNo}}</td>
                        <td>
                          <label class="sr-only" for="step005-add-item-code">ItemCodeAdd</label>
                          <input type="text" name="step005-add-item-code" class="form-control w-100"
                                 aria-describedby="step005-add-item-code" placeholder="Item Code"
                                 [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                                 (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                                 [typeahead]="templateItemList"
                                 typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                                 [typeaheadOptionsInScrollableView]="20" autocomplete="off">
                        </td>
                        <td>{{templateItemsToAddToTemplate.ItemCodeDesc}}</td>
                        <td>
                          <div class="form-group">
                            <label for="step005-add-item-qty-req" class="sr-only">Quantity Required</label>
                            <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                                   class="form-control" name="step005-add-item-qty-req" id="step005-add-item-qty-req"
                                   aria-describedby="step005-add-item-qty-req">
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label for="step005-add-item-comment" class="sr-only">ItemComment</label>
                            <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                      name="step005-add-item-comment" id="step005-add-item-comment" rows="2">Comments go here</textarea>
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label class="sr-only" for="step005-add-item-location">Location</label>
                            <input type="text" name="step005-add-item-location" class="form-control w-100"
                                   aria-describedby="step005-add-item-location" placeholder="Item Location"
                                   [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                                   [typeahead]="locationList"
                                   typeaheadOptionField="Location" autocomplete="off">
                          </div>
                        </td>
                        <td>
                          <div class="col-1 mt-2" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                               (click)="onExplodeKitItems()">
                            <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                              <i class="fas fa-bomb fa-2x text-dark"></i>
                            </span>
                            <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                              <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                              <i class="fas fa-ban fa-stack-2x text-primary"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm"
                                  (click)="onAddItemToTemplate('005', 'copied')">Add
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    <!-- * END of Add New Item to Step 005 -->
                    <ng-container *ngIf="selectedTemplateToCopy[0]['005'].templateLines.length> 0">
                      <tr *ngFor="let line of selectedTemplateToCopy[0]['005'].templateLines; index as index">
                        <td>{{line.TemplateNo}}</td>
                        <td style="width: 50px">
                          {{line.RevisionNo}}
                        </td>
                        <td>
                          {{line.ItemCode}}
                        </td>
                        <td style="width: 175px;">{{line.ItemCodeDesc}}</td>
                        <td style="width: 75px;">
                          <div class="form-group w-100">
                            <label class="sr-only" for="step005-qty-req">QtyRequired</label>
                            <input type="text" class="form-control" name="step005-qty-req"
                                   aria-describedby="step005-qty-req" [(ngModel)]="line.QuantityRequired">
                          </div>
                        </td>
                        <td>
                          <div class="form-group w-100">
                            <label for="step005-comment-text" class="sr-only">CommentText</label>
                            <textarea class="form-control" name="step005-comment-text" rows="2"
                                      [(ngModel)]="line.CommentText"></textarea>
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label class="sr-only" for="step005-location">Location</label>
                            <input type="text" name="step005-location" class="form-control w-100"
                                   aria-describedby="step005-location" placeholder={{line.UDF_LOCATION}}
                                   [(ngModel)]="line.UDF_LOCATION"
                                   (typeaheadOnSelect)="onChangeItemLocation($event, line, index, 'copied')"
                                   [typeahead]="locationList"
                                   typeaheadOptionField="Location" autocomplete="off">
                          </div>
                        </td>
                        <td>
                          {{line.ExplodeBOM === 'Y' ? 'Kit' : ''}}
                        </td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm"
                                  (click)="onRemoveItemFromTemplate(line, index, 'copied')" title="Remove Item">X
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <!-- *==TAB 005 END== Step 005 User Added Step -->


          <!-- *==TAB 006 BEGINNING== Step 006 User Added Step -->
          <ng-container *ngIf="selectionTab006">
            <div *ngIf="this.selectedTemplateToCopy[0]['006']['templateData'].length === 0">
              <h3>This step does not exist on this template</h3>
              <p>Click here to
                <button type="button" class="btn btn-primary btn-sm"
                        (click)="onAddStepToExistingTemplate('006', 'copied')">Add Step 006
                </button>
                to TemplateNo: {{userInputTemplateNumber}}</p>
            </div>

            <ng-container *ngIf="this.selectedTemplateToCopy[0]['006']['templateData'].length> 0">
              <div class="row">
                <div *ngIf="selectedTemplateToCopy[0]['006']['templateLines'].length> 0"
                     class="col-12 d-flex justify-content-end p-2">
                  <button type="button" class="btn btn-primary btn-lg"
                          (click)="onRemoveAllItemsFromTemplateStep('006', 'copied')">Remove ALL Items From Step 006
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="container">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>Template</th>
                      <th>Revision</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty</th>
                      <th>Comment</th>
                      <th>Location</th>
                      <th>Explode</th>
                      <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <!-- * BEGINNING of Add New Item to Step 006 -->
                    <ng-container class="w-100">
                      <tr style="background-color: lightgray;">
                        <td>{{selectedTemplateToCopy[0]['000']['templateData'][0].TemplateNo}}</td>
                        <td>{{selectedTemplateToCopy[0]['000']['templateData'][0].RevisionNo}}</td>
                        <td>
                          <label class="sr-only" for="step006-add-item-code">ItemCodeAdd</label>
                          <input type="text" name="step006-add-item-code" class="form-control w-100"
                                 aria-describedby="step006-add-item-code" placeholder="Item Code"
                                 [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                                 (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                                 [typeahead]="templateItemList"
                                 typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                                 [typeaheadOptionsInScrollableView]="20" autocomplete="off">
                        </td>
                        <td>{{templateItemsToAddToTemplate.ItemCodeDesc}}</td>
                        <td>
                          <div class="form-group">
                            <label for="step006-add-item-qty-req" class="sr-only">Quantity Required</label>
                            <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                                   class="form-control" name="step006-add-item-qty-req" id="step006-add-item-qty-req"
                                   aria-describedby="step006-add-item-qty-req">
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label for="step006-add-item-comment" class="sr-only">ItemComment</label>
                            <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                      name="step006-add-item-comment" id="step006-add-item-comment" rows="2">Comments go here</textarea>
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label class="sr-only" for="step006-add-item-location">Location</label>
                            <input type="text" name="step006-add-item-location" class="form-control w-100"
                                   aria-describedby="step006-add-item-location" placeholder="Item Location"
                                   [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                                   [typeahead]="locationList"
                                   typeaheadOptionField="Location" autocomplete="off">
                          </div>
                        </td>
                        <td>
                          <div class="col-1 mt-2" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                               (click)="onExplodeKitItems()">
                            <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                              <i class="fas fa-bomb fa-2x text-dark"></i>
                            </span>
                            <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                              <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                              <i class="fas fa-ban fa-stack-2x text-primary"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm"
                                  (click)="onAddItemToTemplate('006', 'copied')">Add
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    <!-- * END of Add New Item to Step 006 -->
                    <ng-container *ngIf="selectedTemplateToCopy[0]['006'].templateLines.length> 0">
                      <tr *ngFor="let line of selectedTemplateToCopy[0]['006'].templateLines; index as index">
                        <td>{{line.TemplateNo}}</td>
                        <td style="width: 50px">
                          {{line.RevisionNo}}
                        </td>
                        <td>
                          {{line.ItemCode}}
                        </td>
                        <td style="width: 175px;">{{line.ItemCodeDesc}}</td>
                        <td style="width: 75px;">
                          <div class="form-group w-100">
                            <label class="sr-only" for="step006-qty-req">QtyRequired</label>
                            <input type="text" class="form-control" name="step006-qty-req"
                                   aria-describedby="step006-qty-req" [(ngModel)]="line.QuantityRequired">
                          </div>
                        </td>
                        <td>
                          <div class="form-group w-100">
                            <label for="step006-comment-text" class="sr-only">CommentText</label>
                            <textarea class="form-control" name="step006-comment-text" rows="2"
                                      [(ngModel)]="line.CommentText"></textarea>
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label class="sr-only" for="step006-location">Location</label>
                            <input type="text" name="step006-location" class="form-control w-100"
                                   aria-describedby="step006-location" placeholder={{line.UDF_LOCATION}}
                                   [(ngModel)]="line.UDF_LOCATION"
                                   (typeaheadOnSelect)="onChangeItemLocation($event, line, index, 'copied')"
                                   [typeahead]="locationList"
                                   typeaheadOptionField="Location" autocomplete="off">
                          </div>
                        </td>
                        <td>
                          {{line.ExplodeBOM === 'Y' ? 'Kit' : ''}}
                        </td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm"
                                  (click)="onRemoveItemFromTemplate(line, index, 'copied')" title="Remove Item">X
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <!-- *==TAB 006 END== Step 006 User Added Step -->

          <!-- * ==TAB 010 BEGINNING== Step 010 Parts -->
          <ng-container *ngIf="selectionTab010">
            <div *ngIf="this.selectedTemplateToCopy[0]['010']['templateData'].length <1">
              <h3>This step does not exist on this template</h3>
              <p>Click here to
                <button type="button" class="btn btn-primary btn-sm"
                        (click)="onAddStepToExistingTemplate('010', 'copied')">Add Step 010
                </button>
                to TemplateNo: {{userInputTemplateNumber}}</p>
            </div>
            <ng-container *ngIf="this.selectedTemplateToCopy[0]['010']['templateData'].length> 0">
              <div class="row">
                <div class="col-12 d-flex justify-content-end p-2">
                  <button type="button" class="btn btn-primary btn-lg"
                          (click)="onRemoveAllItemsFromTemplateStep('010', 'copied')">Remove ALL Items From Step 010
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="mx-auto">
                  <ng-container>
                    <table class="table table-responsive table-hover table-striped">
                      <thead class="thead-dark">
                      <tr>
                        <th>Template</th>
                        <th>Revision</th>
                        <th>ItemCode</th>
                        <th>Desc</th>
                        <th>Qty</th>
                        <th>Comment</th>
                        <th>Location</th>
                        <th>Explode</th>
                        <th>Edit</th>
                      </tr>
                      </thead>
                      <tbody>
                      <!-- * BEGINNING Add New Item to Step 010 -->
                      <ng-container class="w-100">
                        <tr style="background-color: lightgray;">
                          <td>{{selectedTemplateToCopy[0]['010']['templateData'][0].TemplateNo}}</td>
                          <td>{{selectedTemplateToCopy[0]['010']['templateData'][0].RevisionNo}}</td>
                          <td>
                            <label class="sr-only" for="step010-add-item-code">ItemCodeAdd</label>
                            <input type="text" name="step010-add-item-code" class="form-control w-100"
                                   aria-describedby="step010-add-item-code" placeholder="Item Code"
                                   [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                                   (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                                   [typeahead]="templateItemList"
                                   typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150"
                                   [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="20"
                                   autocomplete="off">
                          </td>
                          <td>{{templateItemsToAddToTemplate.ItemCodeDesc}}</td>
                          <td>
                            <div class="form-group">
                              <label for="step010-add-item-qty-req" class="sr-only">Quantity Required</label>
                              <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                                     class="form-control" name="step010-add-item-qty-req" id="step010-add-item-qty-req"
                                     aria-describedby="step010-add-item-qty-req">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <label for="step010-add-item-comment" class="sr-only">ItemComment</label>
                              <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                        name="step010-add-item-comment" id="step010-add-item-comment" rows="2">Comments go here</textarea>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <label class="sr-only" for="step010-add-item-location">Location</label>
                              <input type="text" name="step010-add-item-location" class="form-control w-100"
                                     aria-describedby="step010-add-item-location" placeholder="Item Location"
                                     [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                                     [typeahead]="locationList"
                                     typeaheadOptionField="Location" autocomplete="off">
                            </div>
                          </td>
                          <td>
                            <div class="col-1 mt-2" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                                 (click)="onExplodeKitItems()">
                              <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                                <i class="fas fa-bomb fa-2x text-dark"></i>
                              </span>
                              <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                                <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                                <i class="fas fa-ban fa-stack-2x text-primary"></i>
                              </span>
                            </div>
                          </td>
                          <td>
                            <button type="button" class="btn btn-primary btn-sm"
                                    (click)="onAddItemToTemplate('010', 'copied')">Add
                            </button>
                          </td>
                        </tr>
                      </ng-container>
                      <!-- * END Add New Item To Step 010 -->
                      <ng-container *ngIf="selectedTemplateToCopy[0]['010'].templateLines.length> 0">
                        <tr *ngFor="let line of selectedTemplateToCopy[0]['010'].templateLines; index as index">
                          <td>{{line.TemplateNo}}</td>
                          <td style="width: 50px">
                            {{line.RevisionNo}}
                          </td>
                          <td>
                            {{line.ItemCode}}
                          </td>
                          <td style="width: 175px;">{{line.ItemCodeDesc}}</td>
                          <td style="width: 75px;">
                            <div class="form-group w-100">
                              <label class="sr-only" for="step010-qty-req">QtyRequired</label>
                              <input type="text" class="form-control" name="step010-qty-req"
                                     aria-describedby="step010-qty-req" [(ngModel)]="line.QuantityRequired">
                            </div>
                          </td>
                          <td>
                            <div class="form-group w-100">
                              <label for="step010-comment-text" class="sr-only">CommentText</label>
                              <textarea class="form-control" name="step010-comment-text" rows="2"
                                        [(ngModel)]="line.CommentText"></textarea>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <label class="sr-only" for="step010-location">Location</label>
                              <input type="text" name="step010-location" class="form-control w-100"
                                     aria-describedby="step010-location" placeholder={{line.UDF_LOCATION}}
                                     [(ngModel)]="line.UDF_LOCATION"
                                     (typeaheadOnSelect)="onChangeItemLocation($event, line, index, 'copied')"
                                     [typeahead]="locationList"
                                     typeaheadOptionField="Location" autocomplete="off">
                            </div>
                          </td>
                          <td>{{line.ExplodeBOM === 'Y' ? 'Kit' : ''}}</td>
                          <td>
                            <button type="button" class="btn btn-primary btn-sm"
                                    (click)="onRemoveItemFromTemplate(line, index, 'copied')" title="Remove Item">X
                            </button>
                          </td>
                        </tr>
                      </ng-container>
                      </tbody>
                    </table>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <!--  * ==TAB 010 END== Step 010 Parts -->

          <!--  * ==TAB 020 BEGINNING== Step 020 Labor -->
          <ng-container *ngIf="selectionTab020">
            <div *ngIf="this.selectedTemplateToCopy[0]['020']['templateData'].length <1">
              <h3>This step does not exist on this template</h3>
              <p>Click here to
                <button type="button" class="btn btn-primary btn-sm"
                        (click)="onAddStepToExistingTemplate('020', 'copied')">Add Step 020
                </button>
                to TemplateNo: {{userInputTemplateNumber}}</p>
            </div>
            <ng-container *ngIf="this.selectedTemplateToCopy[0]['020']['templateData'].length> 0">
              <div class="row">
                <div class="col-12 d-flex justify-content-end p-2">
                  <button type="button" class="btn btn-primary btn-lg"
                          (click)="onRemoveAllItemsFromTemplateStep('020', 'copied')">Remove ALL Items From Step 020
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="mx-auto">
                  <table class="table table-responsive table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>Template</th>
                      <th>Revision</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty</th>
                      <th>Comment</th>
                      <th>Location</th>
                      <th>Explode</th>
                      <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <!-- * BEGINNING of Add New Item to Step 020 -->
                    <ng-container class="w-100">
                      <tr style="background-color: lightgray;">
                        <td>{{selectedTemplateToCopy[0]['020']['templateData'][0].TemplateNo}}</td>
                        <td>{{selectedTemplateToCopy[0]['020']['templateData'][0].RevisionNo}}</td>
                        <td>
                          <label class="sr-only" for="step020-add-item-code">ItemCodeAdd</label>
                          <input type="text" name="step020-add-item-code" class="form-control w-100"
                                 aria-describedby="step020-add-item-code" placeholder="Item Code"
                                 [(ngModel)]="templateItemsToAddToTemplate.ItemCode"
                                 (typeaheadOnSelect)="onSetItemCodeDescOfAddedItem($event)"
                                 [typeahead]="templateItemList"
                                 typeaheadOptionField="ItemCode" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                                 [typeaheadOptionsInScrollableView]="20" autocomplete="off">
                        </td>
                        <td>{{templateItemsToAddToTemplate.ItemCodeDesc}}</td>
                        <td>
                          <div class="form-group">
                            <label for="step020-add-item-qty-req" class="sr-only">Quantity Required</label>
                            <input type="text" [(ngModel)]="templateItemsToAddToTemplate.QuantityRequired"
                                   class="form-control" name="step020-add-item-qty-req" id="step020-add-item-qty-req"
                                   aria-describedby="step020-add-item-qty-req">
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label for="step020-add-item-comment" class="sr-only">ItemComment</label>
                            <textarea class="form-control" [(ngModel)]="templateItemsToAddToTemplate.CommentText"
                                      name="step020-add-item-comment" id="step020-add-item-comment" rows="2">Comments go here</textarea>
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label class="sr-only" for="step020-add-item-location">Location</label>
                            <input type="text" name="step020-add-item-location" class="form-control w-100"
                                   aria-describedby="step020-add-item-location" placeholder="Item Location"
                                   [(ngModel)]="templateItemsToAddToTemplate.UDF_LOCATION"
                                   [typeahead]="locationList"
                                   typeaheadOptionField="Location" autocomplete="off">
                          </div>
                        </td>
                        <td>
                          <div class="col-1 mt-2" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== ''"
                               (click)="onExplodeKitItems()">
                            <span *ngIf="templateItemsToAddToTemplate.ExplodeBOM === 'Y'">
                              <i class="fas fa-bomb fa-2x text-dark"></i>
                            </span>
                            <span class="fa-stack" *ngIf="templateItemsToAddToTemplate.ExplodeBOM !== 'Y'">
                              <i class="fas fa-bomb fa-stack-1x text-dark"></i>
                              <i class="fas fa-ban fa-stack-2x text-primary"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm"
                                  (click)="onAddItemToTemplate('020', 'copied')">Add
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    <!-- * END of Add New Item to Step 020 -->
                    <ng-container *ngIf="selectedTemplateToCopy[0]['020'].templateLines.length> 0
                      ">
                      <tr *ngFor="let line of selectedTemplateToCopy[0]['020'].templateLines; index as index">
                        <td>{{line.TemplateNo}}</td>
                        <td style="width: 50px">
                          {{line.RevisionNo}}
                        </td>
                        <td>
                          {{line.ItemCode}}
                        </td>
                        <td style="width: 175px;">{{line.ItemCodeDesc}}</td>
                        <td style="width: 75px;">
                          <div class="form-group w-100">
                            <label class="sr-only" for="step000-qty-req">QtyRequired</label>
                            <input type="text" class="form-control" name="step000-qty-req"
                                   aria-describedby="step000-qty-req" [(ngModel)]="line.QuantityRequired">
                          </div>
                        </td>
                        <td>
                          <div class="form-group w-100">
                            <label for="step000-comment-text" class="sr-only">CommentText</label>
                            <textarea class="form-control" name="step000-comment-text" rows="2"
                                      [(ngModel)]="line.CommentText"></textarea>
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label class="sr-only" for="step000-location">Location</label>
                            <input type="text" name="step000-location" class="form-control w-100"
                                   aria-describedby="step000-location" placeholder={{line.UDF_LOCATION}}
                                   [(ngModel)]="line.UDF_LOCATION"
                                   (typeaheadOnSelect)="onChangeItemLocation($event, line, index, 'copied')"
                                   [typeahead]="locationList"
                                   typeaheadOptionField="Location" autocomplete="off">
                          </div>
                        </td>
                        <td>{{line.ExplodeBOM === 'Y' ? 'Kit' : ''}}</td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm"
                                  (click)="onRemoveItemFromTemplate(line, index, 'copied')" title="Remove Item">X
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <!--  * ==TAB 020 END== Step 020 Labor -->

          <!--  * ==TAB REVIEW== Review Step -->
          <ng-container *ngIf="selectionTabReview">
            <div class="row">
              <div class="col-12"><h3 class="text-dark">
                Template: {{selectedTemplateToCopy[0]['000'].templateData[0].TemplateNo}}</h3></div>
            </div>
            <ul class="list-group">
              <li class="list-group-item w-100">
                <div
                  class="row w-100 mx-auto bg-primary font-weight-bold text-light p-1 d-flex justify-content-between align-items-center"
                  (click)="showReviewStep000= !showReviewStep000">
                  <div class="pl-2">
                    <h4>Step 000</h4>
                  </div>
                  <div>
                    <h4>Desc: {{selectedTemplateToCopy[0]['000'].templateData[0].Description}}</h4>
                  </div>
                  <div>
                    <h4>Initial Status: {{selectedTemplateToCopy[0]['000'].templateData[0].InitialStatusCode}}</h4>
                  </div>
                  <div *ngIf="showReviewStep000" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-down"></i>
                  </div>
                  <div *ngIf="!showReviewStep000" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-left"></i>
                  </div>
                </div>
                <ng-container *ngIf="showReviewStep000">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty Required</th>
                      <th>Comment</th>
                      <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let line of selectedTemplateToCopy[0]['000'].templateLines; index as index">
                      <tr>
                        <td>{{line.TemplateNo}}</td>
                        <td>{{line.RevisionNo}}</td>
                        <td>{{line.WTStep}}</td>
                        <td>{{line.ItemCode}}</td>
                        <td class="w-25">{{line.ItemCodeDesc}}</td>
                        <td>{{line.QuantityRequired}}</td>
                        <td>{{line.CommentText}}</td>
                        <td>{{line.UDF_LOCATION}}</td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </ng-container>
              </li>
              <li *ngIf="selectedTemplateToCopy[0]['005'].templateLines.length> 0" class="list-group-item w-100">
                <div
                  class="row w-100 mx-auto bg-primary font-weight-bold text-light p-1 d-flex justify-content-between align-items-center"
                  (click)="showReviewStep005= !showReviewStep005">
                  <div class="pl-2">
                    <h4>Step 005</h4>
                  </div>
                  <div>
                    <h4>Desc: {{selectedTemplateToCopy[0]['005'].templateData[0].Description}}</h4>
                  </div>
                  <div>
                    <h4>Initial Status: {{selectedTemplateToCopy[0]['005'].templateData[0].InitialStatusCode}}</h4>
                  </div>
                  <div *ngIf="showReviewStep005" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-down"></i>
                  </div>
                  <div *ngIf="!showReviewStep005" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-left"></i>
                  </div>
                </div>
                <ng-container *ngIf="showReviewStep005">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty Required</th>
                      <th>Comment</th>
                      <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let line of selectedTemplateToCopy[0]['005'].templateLines; index as index">
                      <tr>
                        <td>{{line.TemplateNo}}</td>
                        <td>{{line.RevisionNo}}</td>
                        <td>{{line.WTStep}}</td>
                        <td>{{line.ItemCode}}</td>
                        <td class="w-25">{{line.ItemCodeDesc}}</td>
                        <td>{{line.QuantityRequired}}</td>
                        <td>{{line.CommentText}}</td>
                        <td>{{line.UDF_LOCATION}}</td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </ng-container>
              </li>
              <li *ngIf="selectedTemplateToCopy[0]['006'].templateLines.length> 0" class="list-group-item w-100">
                <div
                  class="row w-100 mx-auto bg-primary font-weight-bold text-light p-1 d-flex justify-content-between align-items-center"
                  (click)="showReviewStep006= !showReviewStep006">
                  <div class="pl-2">
                    <h4>Step 006</h4>
                  </div>
                  <div>
                    <h4>Desc: {{selectedTemplateToCopy[0]['006'].templateData[0].Description}}</h4>
                  </div>
                  <div>
                    <h4>Initial Status: {{selectedTemplateToCopy[0]['006'].templateData[0].InitialStatusCode}}</h4>
                  </div>
                  <div *ngIf="showReviewStep006" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-down"></i>
                  </div>
                  <div *ngIf="!showReviewStep006" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-left"></i>
                  </div>
                </div>
                <ng-container *ngIf="showReviewStep006">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty Required</th>
                      <th>Comment</th>
                      <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let line of selectedTemplateToCopy[0]['006'].templateLines; index as index">
                      <tr>
                        <td>{{line.TemplateNo}}</td>
                        <td>{{line.RevisionNo}}</td>
                        <td>{{line.WTStep}}</td>
                        <td>{{line.ItemCode}}</td>
                        <td class="w-25">{{line.ItemCodeDesc}}</td>
                        <td>{{line.QuantityRequired}}</td>
                        <td>{{line.CommentText}}</td>
                        <td>{{line.UDF_LOCATION}}</td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </ng-container>
              </li>
              <li *ngIf="selectedTemplateToCopy[0]['010'].templateLines.length> 0" class="list-group-item w-100">
                <div
                  class="row w-100 mx-auto bg-primary font-weight-bold text-light p-1 d-flex justify-content-between align-items-center"
                  (click)="showReviewStep010= !showReviewStep010">
                  <div class="pl-2">
                    <h4>Step 010</h4>
                  </div>
                  <div>
                    <h4>Desc: {{selectedTemplateToCopy[0]['010'].templateData[0].Description}}</h4>
                  </div>
                  <div>
                    <h4>Initial Status: {{selectedTemplateToCopy[0]['010'].templateData[0].InitialStatusCode}}</h4>
                  </div>
                  <div *ngIf="showReviewStep010" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-down"></i>
                  </div>
                  <div *ngIf="!showReviewStep010" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-left"></i>
                  </div>
                </div>
                <ng-container *ngIf="showReviewStep010">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty Required</th>
                      <th>Comment</th>
                      <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let line of selectedTemplateToCopy[0]['010'].templateLines; index as index">
                      <tr>
                        <td>{{line.TemplateNo}}</td>
                        <td>{{line.RevisionNo}}</td>
                        <td>{{line.WTStep}}</td>
                        <td>{{line.ItemCode}}</td>
                        <td class="w-25">{{line.ItemCodeDesc}}</td>
                        <td>{{line.QuantityRequired}}</td>
                        <td>{{line.CommentText}}</td>
                        <td>{{line.UDF_LOCATION}}</td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </ng-container>
              </li>
              <li *ngIf="selectedTemplateToCopy[0]['020'].templateLines.length> 0" class="list-group-item w-100">
                <div
                  class="row w-100 mx-auto bg-primary font-weight-bold text-light p-1 d-flex justify-content-between align-items-center"
                  (click)="showReviewStep020= !showReviewStep020">
                  <div class="pl-2">
                    <h4>Step 020</h4>
                  </div>
                  <div>
                    <h4>Desc: {{selectedTemplateToCopy[0]['020'].templateData[0].Description}}</h4>
                  </div>
                  <div>
                    <h4>Initial Status: {{selectedTemplateToCopy[0]['020'].templateData[0].InitialStatusCode}}</h4>
                  </div>
                  <div *ngIf="showReviewStep020" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-down"></i>
                  </div>
                  <div *ngIf="!showReviewStep020" class="pr-2" style="font-size: 1.5rem;">
                    <i class="far fa-caret-square-left"></i>
                  </div>
                </div>
                <ng-container *ngIf="showReviewStep020">
                  <table class="table table-hover table-striped">
                    <thead class="thead-dark">
                    <tr>
                      <th>TemplateNo</th>
                      <th>RevisionNo</th>
                      <th>Step</th>
                      <th>ItemCode</th>
                      <th>Desc</th>
                      <th>Qty Required</th>
                      <th>Comment</th>
                      <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let line of selectedTemplateToCopy[0]['020'].templateLines; index as index">
                      <tr>
                        <td>{{line.TemplateNo}}</td>
                        <td>{{line.RevisionNo}}</td>
                        <td>{{line.WTStep}}</td>
                        <td>{{line.ItemCode}}</td>
                        <td class="w-25">{{line.ItemCodeDesc}}</td>
                        <td>{{line.QuantityRequired}}</td>
                        <td>{{line.CommentText}}</td>
                        <td>{{line.UDF_LOCATION}}</td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </ng-container>
              </li>
            </ul>
          </ng-container>
          <!--  * ==TAB REVIEW END== Review Step -->

          <!-- *BEGINNING Bottom Navigation Buttons -->
          <div *ngIf="copyingTemplate" class="row d-flex justify-content-between mt-5">
            <div>
              <button type="button" class="btn btn-primary btn-lg ml-5" (click)="onClickPreviousButton()">Previous
              </button>
            </div>
            <div>
              <button type="button" class="btn btn-primary btn-lg ml-5" (click)="onResetData()">Cancel Template Copy
              </button>
            </div>
            <div *ngIf="!this.selectionTabReview">
              <button type="button" class="btn btn-primary btn-lg mr-5" (click)="onClickNextButton()">Next</button>
            </div>
            <div *ngIf="this.selectionTabReview">
              <button type="button" (click)="onSubmitTemplateCreation('copied')" class="btn btn-primary btn-lg mr-5">
                Finish
              </button>
            </div>
          </div>
          <!-- *END of Bottom Navigation Buttons -->
        </ng-container>
        <!-- !!! END of Copying Template !!! -->
      </div>
    </ng-container>
    <ng-container *ngIf="selectionTempMass">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="table-responsive tableFixedHeader">
            <table class="table table-condensed table-striped text-center">
              <thead>
              <th scope="col">WT step</th>
              <th scope="col">Location</th>
              <th scope="col">Item Code</th>
              <th scope="col">Qty</th>
              <th scope="col">Action</th>
              <th scope="col">New Item Code</th>
              <th scope="col">New Qty</th>
              <th scope="col">*</th>
              </thead>
              <tbody>
              <ng-container *ngFor="let massItem of massTemplateItemList; let index = index">
                <tr>
                  <td><input class="form-control form-control-sm" type="text" [ngStyle]="{'width' : '50px'}" [(ngModel)]="massItem.wtStep"></td>
                  <td><input class="form-control form-control-sm" type="text" [ngStyle]="{'width' : '100px'}"
                             type="text" [typeahead]="LocationList"
                             typeaheadOptionField="UDF_LOCATION_CODE"
                             typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                             [typeaheadOptionsInScrollableView]="20"
                             autocomplete="off"
                             [disabled]="massItem.partAction == 'Replace'"

                             [(ngModel)]="massItem.location"></td>
                  <td class="justify-content-center">
                    <div class="row m-0 p-0">
                      <input class="form-control form-control-sm"
                             type="text" [typeahead]="templateItemList"
                             typeaheadOptionField="ItemCode"
                             typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                             [typeaheadOptionsInScrollableView]="20"
                             autocomplete="off" [(ngModel)]="massItem.ItemCode" (typeaheadOnSelect)="setMassIC($event, index)">
                    </div>
                    <ng-container *ngIf="massItem.Kit === 'Y'">
                      <div class="row mx-0 mt-2 p-0">
                        <button *ngIf="massItem.showKit" (click)="massItem.showKit= !massItem.showKit" type="button" class="btn btn-sm btn-primary">Hide Kit ▼</button>
                        <button *ngIf="!massItem.showKit" (click)="massItem.showKit= !massItem.showKit" type="button" class="btn btn-sm btn-primary">Show kit ⯇</button>
                        <ng-container *ngIf="massItem.showKit">
                          <table class="table table-sm text-sm mt-2">
                            <thead>
                              <th>Item Code</th>
                              <th>Qty</th>
                            </thead>
                            <tbody>
                            <ng-container *ngFor="let kit of massItem.kitList">
                              <tr>
                                <td>{{kit.ItemCode}}</td>
                                <td>{{kit.QuantityRequired}}</td>
                              </tr>
                            </ng-container>
                            </tbody>
                          </table>
                        </ng-container>

                      </div>
                    </ng-container>
                  </td>
                  <td class="justify-content-center"><input class="form-control form-control-sm text-center ml-5" [disabled]="massItem.partAction !== 'Add'" [ngStyle]="{'width' : '65px'}" type="number" [(ngModel)]="massItem.Qty"></td>
                  <td class="justify-content-center-center">
                    <button class="btn btn-primary" *ngIf="massItem.partAction === 'Remove'" (click)="rotateAction(index)">{{massItem.partAction}}</button>
                    <button class="btn btn-danger" *ngIf="massItem.partAction === 'Replace'" (click)="rotateAction(index)">{{massItem.partAction}}</button>
                    <button class="btn btn-warning" *ngIf="massItem.partAction === 'Add'" (click)="rotateAction(index)">{{massItem.partAction}}</button>
                  </td>
                  <td class="justify-content-center">
                    <div class="row m-0 p-0">
                      <input class="form-control form-control-sm" type="text"
                             [typeahead]="templateItemList"
                             typeaheadOptionField="ItemCode"
                             typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
                             [typeaheadOptionsInScrollableView]="20"
                             autocomplete="off"
                             [(ngModel)]="massItem.RepItemCode"
                             [disabled]="massItem.partAction !== 'Replace'"
                             (typeaheadOnSelect)="setRepIC($event, index)">
                    </div>
                    <ng-container *ngIf="massItem.RepKit === 'Y'">
                      <div class="row mx-0 mt-2 p-0">
                        <button *ngIf="massItem.RepShowKit" (click)="massItem.RepShowKit= !massItem.RepShowKit" type="button" class="btn btn-sm btn-primary">Hide Kit ▼</button>
                        <button *ngIf="!massItem.RepShowKit" (click)="massItem.RepShowKit= !massItem.RepShowKit" type="button" class="btn btn-sm btn-primary">Show kit ⯇</button>
                        <ng-container *ngIf="massItem.RepShowKit">
                          <table class="table table-sm text-sm mt-2">
                            <thead>
                            <th>Item Code</th>
                            <th>Qty</th>
                            </thead>
                            <tbody>
                            <ng-container *ngFor="let kit of massItem.RepkitList">
                              <tr>
                                <td>{{kit.ItemCode}}</td>
                                <td>{{kit.QuantityRequired}}</td>
                              </tr>
                            </ng-container>
                            </tbody>
                          </table>
                        </ng-container>

                      </div>
                    </ng-container>
                  </td>
                  <td class="justify-content-center"><input class="form-control form-control-sm text-center ml-5" [ngStyle]="{'width' : '65px'}" type="number"
                                                            [disabled]="massItem.partAction !== 'Replace' || massItem.partAction == 'Replace'" [(ngModel)]="massItem.RepQty"></td>
                  <td class="justify-content-center"><button class="btn btn-primary" (click)="RemoveMassItem(index)"><b>-</b></button></td>
                </tr>
              </ng-container>
              <tr>
                <td colspan="2"></td>
                <td colspan="4">
                  <button class="btn btn-primary" (click)="addMassItem()"><b>+</b></button>
                </td>
                <td colspan="2"></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row justify-content-center my-2">
          <button class="btn btn-secondary" *ngIf="allTemplates" (click)="allTemplates= !allTemplates">All Templates</button>
          <button class="btn btn-primary" *ngIf="!allTemplates" (click)="allTemplates= !allTemplates">Selected Templates</button>
        </div>
        <ng-container *ngIf="!allTemplates">
          <div class="row my-2 mx-5">
            <label><b>Search Templates</b></label>
            <input class="form-control" type="text" [disabled]="loadingData" aria-describedby="helpId"
                   placeholder="e.x. RIZZ2.5/3UP" [(ngModel)]="MassTemplateNumber" autocomplete="off">
          </div>
          <div class="row justify-content-center">
            <div class="col-5 text-center py-2">
              <label><b>Template List</b></label>
              <select
              multiple="multiple"
              class="form-control"
              [ngStyle]="{'height' : '200px'}"
              [(ngModel)]="selectedOptionList">
                <ng-container *ngFor="let templates of activeMassTemplates; let ind = index">
                  <ng-container *ngIf="templates !== undefined && templates.TemplateNo !== ''
                  && templates.TemplateNo.toLowerCase().includes(MassTemplateNumber)">
                    <option>{{ind}} - {{templates.TemplateNo}}</option>
                  </ng-container>
                </ng-container>
              </select>
            </div>
            <div class="col-1">
              <div class="row my-4"></div>
              <div class="row mt-3"><button class="btn btn-primary my-2" (click)="addSelected()">Add selected</button></div>
              <div class="row my-3"></div>
              <div class="row"><button class="btn btn-primary" (click)="RemoveSelected()">Remove selected</button></div>
            </div>
            <div class="col-5 text-center py-2">
              <label><b>Update List</b></label>
              <select
                multiple="multiple"
                class="form-control"
                [ngStyle]="{'height' : '200px'}"
                [(ngModel)]="ChosenOptionList">
                <ng-container *ngFor="let templates of activeSubmitTemplates; let ind = index">
                  <ng-container *ngIf="templates.TemplateNo !== ''">
                    <option>{{ind}} - {{templates.TemplateNo}}</option>
                  </ng-container>
                </ng-container>
              </select>
            </div>
          </div>
        </ng-container>
        <div class="row justify-content-end my-2 mr-5">
          <button class="btn btn-primary" (click)="onOpenModal(MassTemplateSubmit)">Submit</button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="selectionSales">
    <table class="table table-condensed table-striped text-center">
      <tr>
        <th>Items</th>
        <th>Action</th>
        <th>Replacement</th>
      </tr>
      <tr *ngFor="let item of ItemCodeSales; let ind = index">
        <td>{{item.itemCode}}</td>
        <td>{{item.action}}</td>
        <td>{{item.replacement}}</td>
        <td class="justify-content-center"><button class="btn btn-primary" (click)="removeItem(ind)"><b>-</b></button></td>

      </tr>
      <tr>
        <td><input class="form-control form-control-sm"
          type="text" [typeahead]="allItemList"
          typeaheadOptionField="ItemCode"
          typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
          [typeaheadOptionsInScrollableView]="20"
          autocomplete="off" [(ngModel)]="itemAdded.itemCode" (typeaheadOnBlur)="setItemToChange($event, 'original')"></td>
        <td>
            <select class="form-control" [(ngModel)]="itemAdded.action">
              <option value="Remove">Remove</option>
              <option value="Replace">Replace</option>
            </select>
        </td>
          <td>
            <input
            [disabled]="itemAdded.action === 'Remove'"
            class="form-control form-control-sm"
          type="text" [typeahead]="templateItemList"
          typeaheadOptionField="ItemCode"
          typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
          [typeaheadOptionsInScrollableView]="20"
          autocomplete="off" [(ngModel)]="itemAdded.replacement" (typeaheadOnSelect)="setItemToChange($event, 'replacement')"></td>
          <td><button [disabled]="checkIfActiveAndNotKit()" class="btn btn-primary" (click)="addItem(itemAdded)">Add</button></td>

      </tr>
    </table>
    <div class="row justify-content-end my-2 mr-5">

<button [disabled]="ItemCodeSales.length == 0" class="btn btn-primary" (click)="onOpenSalesOrderUpdate(updateSalesOrders)">Confirm</button>
</div>
    </ng-container>
  </div>
</div>

<!-- * Mass Template Submit  -->
<ng-template #MassTemplateSubmit>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2> Update Templates? </h2>
        <h5></h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="onCloseModal(MassTemplateSubmit, 'MassTemplateSubmit')">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="ItemCodeLines.length > 0">
        <div class="col-12 text-center"><b>WARNING: the following part(s) are marked as Inactive and cannot be shipped.</b></div>
        <table class="table table-sm text-sm mt-2">
<!--          <thead>-->
<!--          <th>Item Code</th>-->
<!--          </thead>-->
          <tbody>
          <ng-container *ngFor="let part of ItemCodeLines; let ind = index">
            <tr>
              <td>{{part[0]}}</td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </ng-container>
    </div>
    <div class="row mb-1">
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" [disabled]="SubmittingData" (click)="onSubmitMassTemplateChanges()">Submit</button>
      </div>
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" (click)="onCloseModal(MassTemplateSubmit, 'MassTemplateSubmit')">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>
<!-- * Mass Template Submit End  -->

<!-- * Mass Template Submit  -->
<ng-template #MassTemplateSubmit>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2> Update Templates? </h2>
        <h5></h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="onCloseModal(MassTemplateSubmit, 'MassTemplateSubmit')">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" [disabled]="SubmittingData" (click)="onSubmitMassTemplateChanges()">Submit</button>
      </div>
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" (click)="onCloseModal(MassTemplateSubmit, 'MassTemplateSubmit')">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>
<!-- * Mass Template Submit End  -->

<!-- * Copy Template From Loaded Template Modal Beginning  -->
<ng-template #copyTemplateFromEditScreen>
  <div class="card container">
    <div class="row p-2">
      <div class="col-md-10">
        <h3>Copy {{userInputTemplateNumber}} to new template?</h3>
      </div>
      <div class="col-md-2">
        <button type="button" (click)="onCloseModal(copyTemplateFromEditScreen, 'copyTemplateFromEditScreen')"
                class="btn btn-primary btn-sm">
          <i class="far fa-times-circle" style="font-size: 1.3rem;"></i>
        </button>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-6">
        <button type="button" class="btn btn-primary btn-sm">COPY</button>
      </div>
      <div class="col-md-6">
        <button type="button" class="btn btn-primary btn-sm">CANCEL</button>
      </div>
    </div>
  </div>
</ng-template>
<!-- * Copy Template From Loaded Template Modal End  -->

<!-- * Create New Template Modal Beginning  -->
<ng-template #createNewTemplate>
  <div class="card container">
    <div class="row p-2">
      <div class="col-md-10">
        <h3>Create New Template</h3>
      </div>
      <div class="col-md-2">
        <button type="button" (click)="onCloseCreateNewTemplateModal()" class="btn btn-primary btn-sm">
          <i class="far fa-times-circle" style="font-size: 1.3rem;"></i>
        </button>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-6">
        <button type="button" class="btn btn-primary btn-lg" (click)="onCopyExistingTemplateButtonClick()">
          Copy From A Template
        </button>
      </div>
      <div class="col-md-6">
        <button type="button" class="btn btn-primary btn-lg" (click)="onCreateBlankTemplateButtonClick()">
          Create Blank Template
        </button>
      </div>
    </div>
    <div *ngIf="showTemplateToCopySearchArea" class="form-group p-2">
      <div class="row form-group form-group-horizontal">
        <label class="col-md-6" for="copy-template-new-template-no">New TemplateNo</label>
        <input type="text"
               class="col-md-6 form-control" name="copy-template-new-template-no" id="copy-template-new-template-no"
               aria-describedby="copy-template-new-template-no" [(ngModel)]="templateNumberForCopiedTemplate"
               (change)="onCheckNewTemplateNumberForCopiedTemplate($event)" placeholder="i.e. RI-BASE 621">
      </div>
      <div class="row form-group" *ngIf="templateNumberForCopiedTemplate">
        <label class="col-md-6" for="copy-template-input">Select Template to <strong>Copy</strong> From:</label>
        <input class="col-md-6 form-control" name="copy-template-input" id="copy-template-input"
               aria-describedby="copy-template-input" placeholder="e.x. RIZZ2.5/3UP" [(ngModel)]="templateNumberToCopy"
               [disabled]="templateNumbersMatchError"
               (typeaheadOnSelect)="onSelectTemplateToCopyFrom($event)" [typeahead]="activeTemplates"
               typeaheadOptionField="TemplateName" [typeaheadScrollable]="true" autocomplete="off">
      </div>
    </div>
    <div class="row" *ngIf="templateNumbersMatchError">
      <div class="col-12 text-center text-warning">
        <p>
          <i class="fas fa-exclamation-circle"></i>
          This template number already exists, please choose another
          <i class="fas fa-exclamation-circle"></i>
        </p>
      </div>
    </div>
  </div>
</ng-template>
<!-- * Create New Template Modal End  -->
<!-- * Create New Template Modal Beginning  -->
<ng-template #updateSalesOrders>
  <div class="card container">
    <div class="row p-2">
      <div class="col-md-10">
        <h3>Update Sales Orders</h3>
      </div>
      <div class="col-md-2">
        <button type="button" (click)="onCloseSalesOrderUpdate()" class="btn btn-primary btn-sm">
          <i class="far fa-times-circle" style="font-size: 1.3rem;"></i>
        </button>
      </div>
      <div class="col-md-10">
        <span *ngIf="countLoading">
          <i class="fas fa-sync fa-spin"></i>
        </span>
        <div *ngFor="let mess of warningMessage">
          <span>
            <p>{{mess}}</p>
          </span>
        </div>
          </div>
          <div class="row p-2">
            <button (click)="confirmedChangeSalesOrders()" class="btn btn-success">Confirm</button>
            <button (click)="onCloseSalesOrderUpdate()" class="btn btn-primary">Close</button>
          </div>
          </div>


  </div>
</ng-template>

