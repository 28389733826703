<div class="container pb-5">
  <div class="card my-5 shadow-lg text-center">
    <app-data-table [rawData]="OnHandStock" [tableColumns]="[
  {col: 'ItemCode', value: 'ItemCode', title: 'Item Code', size: 'md'},
  {col: 'Description', value: 'Description', title: 'Description', size: 'md'},
  {col: 'TotalQuantityOnHand', value: 'TotalQuantityOnHand', title: 'Quantity On Hand', size: 'md'},
]" [inColumns]="
  [
  {col: 'ItemCode',  title: 'Item Code'},
  {col: 'Description', title: 'Description'},
  ]"
        [isLoading]="OnHandStockLoading"
        [includeColumnSelector]="false"
        [searchOnChange]="true"
        accordianControls="true"
        accordianScreenSize="smd"
        title="Truck Manager">
      <ng-container table-content>
<div class="setTech">
  <mat-form-field >
    <mat-label>Technician</mat-label>
    <mat-select>
      <mat-option *ngFor="let tech of technicians" [value]="tech.name" (click)="setTech(tech)">
        {{ tech.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div>
    <button [disabled]="OnHandStockLoading || techId == ''" mat-raised-button color="accent" (click)="pullSageItemList()">Search</button>
  </div>
</div>
      </ng-container>
    </app-data-table>
  </div>
</div>
