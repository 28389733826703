<div class="qpartbreakdown quoting-theme">
  <div class="qpartbreakdown-controls">
    <div class="qpartbreakdown-controls-front">
      <mat-form-field appearance="outline" class="qpartbreakdown-controls-front-sort">
        <mat-label>Sort By</mat-label>
        <mat-select [formControl]="sortControl">
          <mat-option *ngFor="let reg of sortOptions" [value]="reg">{{reg}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="qpartbreakdown-controls-front-direction">
        <mat-label>Sort Direction</mat-label>
        <mat-select [formControl]="sortDirectionControl">
          <mat-option [value]="'ASC'">ASC</mat-option>
          <mat-option [value]="'DESC'">DESC</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="qpartbreakdown-controls-back">
      <button
        mat-raised-button
        class="qpartbreakdown-controls-back-btn"
        (click)="openPdf()"
      >
        <i class="fas fa-file-download"></i>
      </button>
    </div>
  </div>

  <div class="qpartbreakdown-list">
    <ng-container
      *ngFor="let pgroup of partGroups; let lIndex = index; trackBy: trackGroup"
    >
      <div class="qpartbreakdown-item">
        <div class="qpartbreakdown-item-header">
          <div class="qpartbreakdown-item-header-front">
            <h2
              class="qpartbreakdown-item-header-front-name qpartbreakdown-item-header-item"
            >
              {{pgroup.name}}
            </h2>
            <h3
              class="qpartbreakdown-item-header-front-times qpartbreakdown-item-header-item"
            >
              &times;
            </h3>
            <h3
              class="qpartbreakdown-item-header-front-quan qpartbreakdown-item-header-item"
            >
              {{pgroup.quantity}}
            </h3>
            <h3
              class="qpartbreakdown-item-header-front-pcost qpartbreakdown-item-header-item"
            >
              <span class="qpartbreakdown-item-header-front-pcost-icon"
                >$
              </span>
              <span>{{pgroup.partCost.toFixed(2)}}</span>
              <span class="qpartbreakdown-item-header-front-pcost-per"
                >/part</span
              >
            </h3>
          </div>
          <div class="qpartbreakdown-item-header-back">
            <h3
              class="qpartbreakdown-item-tcost-header-back-tcost qpartbreakdown-item-header-item"
            >
              <span
                class="qpartbreakdown-item-tcost-header-back-tcost-icon"
                >$</span
              >
              {{ pgroup.totalCost.toFixed(2) }}
            </h3>
          </div>
        </div>
        <h6 class="qpartbreakdown-item-desc">{{ pgroup.desc }}</h6>
        <ng-container *ngFor="let item of pgroup.items; trackBy: trackItem">
          <mat-card class="qpartbreakdown-item-parts">
            <div class="qpartbreakdown-item-parts-header">
              <div class="qpartbreakdown-item-parts-header-front">
                <h3 class="qpartbreakdown-item-parts-header-front-code">
                  {{ item.data.QuotePart.QuotePart.QuotePart_Code }}
                </h3>
                <i
                  class="qpartbreakdown-item-parts-header-front-quan-icon"
                  >&times;</i
                >
                <h4 class="qpartbreakdown-item-parts-header-front-quan">
                  {{ item.quantity }}
                </h4>
              </div>
              <div class="qpartbreakdown-item-parts-header-back">
                <h4 class="qpartbreakdown-item-parts-header-back-cost">
                  <span
                    class="qpartbreakdown-item-parts-header-back-cost-icon"
                    >$</span
                  >
                  {{ item.cost.toFixed(2) }}
                </h4>
              </div>
            </div>
            <div class="qpartbreakdown-item-parts-content">
              <div class="qpartbreakdown-item-parts-content-parents">
                <span
                  *ngFor="let parent of item.parents; let pIndex = index"
                  class="qpartbreakdown-item-parts-content-parents-item"
                >
                  <i
                    class="fas fa-chevron-right qpartbreakdown-item-parts-content-parents-item-icon"
                  ></i>
                  <h6
                    class="qpartbreakdown-item-parts-content-parents-item-txt"
                  >
                    {{ parent }}
                  </h6>
                </span>
              </div>
            </div>
          </mat-card>
        </ng-container>
      </div>
      <!-- <pre>{{ item | json }}</pre> -->
    </ng-container>
  </div>
</div>
