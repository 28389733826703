import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import {Observable, from as fromPromiseToObs} from 'rxjs';
// import * as xml2js from 'xml2js';
const xml2js = require('xml2js');

interface CachedDataInterface {
  createdAt: number; // Unix time (ms snice epoch). Used for detecting stale
  exp: number; // Milliseconds for determining if data is stale. If Date.now() is older than createdAt+exp than we consider the cached data stale
  matchCriteria: string[]; // When making a req, this is used to see if the request matches a cache response already in cachedData
  data: any; // Neccessary any - sorry not sorry - actually needed
  isResolving?: Observable<any>; // If we are already working on it, then we xpose the observable for them to attach to. After we are done, we set this to undefined
}

interface PullReportCacheOptionsInterface {
  matchCriteria: string[];
  exp: number; // Milliseconds for determining if data is stale. If Date.now() is older than createdAt+exp than we consider the cached data stale
}

@Injectable({
  providedIn: 'root'
})
export class SageApiService {

  // apiUrl = 'https://proxy.gibsonplumbing.com/API_Apps/dev_robert/SageApi/api/';
  sDataUrl = 'https://proxy.gibsonplumbing.com/sdata/MasApp/MasContract/PLU';
  // apiUrl = 'https://proxy.gibsonplumbing.com/API_Apps/dev_jon/SageApi/api/';
  // apiUrl = 'https://localhost:44302/api/'; // Very sad day :(
  apiUrl = 'https://proxy.gibsonplumbing.com/api/';

  imageUrlList: any;
  imageUrlListReports: any = [];

  static cachedData: CachedDataInterface[] = [];

  // @ts-ignore
  constructor(private httpClient: HttpClient) {
    (window as any).logSageApiCache = ()=>{console.log(SageApiService.cachedData)}
   }

  removeStaleCachedData(){
    let now = Date.now();
    for(let dataIndex = SageApiService.cachedData.length-1; dataIndex > 0; dataIndex--){
      const data = SageApiService.cachedData[dataIndex];
      if(data.createdAt+data.exp < now){
        SageApiService.cachedData.splice(dataIndex, 1);
      }
    }
  }

  clearCachedData(){
    SageApiService.cachedData = [];
  }

  pullReport(selectedData: string, cacheOptions?: PullReportCacheOptionsInterface) {
    this.removeStaleCachedData();
    if(cacheOptions == undefined){
      const url = this.apiUrl + selectedData;
      // const reportAuth = localStorage.getItem('reportToken').replace(/"/g, '');
      const reportAuth = localStorage.getItem('token');
      const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);

      return this.httpClient.get(url, { headers });
    }else{
      const url = this.apiUrl + selectedData;
      const reportAuth = localStorage.getItem('token');
      let uniqueIdentifier = 'noUniqueIdentifier';
      if(reportAuth && reportAuth != '' && reportAuth.split('.').length >= 2){
        const pubPartOfToken = window.atob(reportAuth.split('.')[1]);
        const jsonToken = JSON.parse(pubPartOfToken);
        if(jsonToken && jsonToken.unique_name){
          uniqueIdentifier = jsonToken.unique_name
        }
      }
      const matchingCriteria = [...cacheOptions.matchCriteria, url, uniqueIdentifier];

      const now = Date.now();
      const matchingDataIndex = SageApiService.cachedData.findIndex(cd=>{
        if(
          cd.matchCriteria.sort().join(',')==matchingCriteria.sort().join(',')
          && cd.createdAt+cd.exp >= now
        ){
          return true;
        }
        return false;
      });
      const matchingData = matchingDataIndex!=-1?SageApiService.cachedData[matchingDataIndex]:undefined;
      if(matchingData!=undefined && matchingData.isResolving != undefined){
        return matchingData.isResolving;
      }else if(matchingData){
        const newObs = fromPromiseToObs(Promise.resolve(matchingData.data));
        return newObs;
      }else{
        const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);
        const newRequest = this.httpClient.get(url, { headers });
        SageApiService.cachedData.push({
          createdAt: now,
          exp: cacheOptions.exp,
          matchCriteria: matchingCriteria,
          data: undefined,
          isResolving: newRequest
        });
        newRequest.subscribe((data)=>{
          const matchingDataIndexAfterSub = SageApiService.cachedData.findIndex(cd=>{
            if(
              cd.matchCriteria.sort().join(',')==matchingCriteria.sort().join(',')
            ){
              return true;
            }
            return false;
          });
          const matchingDataAfterSub = matchingDataIndexAfterSub>=0?SageApiService.cachedData[matchingDataIndexAfterSub]:undefined;
          if(matchingDataAfterSub){
            SageApiService.cachedData[matchingDataIndexAfterSub].data = data;
            SageApiService.cachedData[matchingDataIndexAfterSub].isResolving = undefined;
          }
        });
        return newRequest;
      }
    }
  }

  deleteRequest(selectedData){
    const url = this.apiUrl + selectedData;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);
    return this.httpClient.delete(url, { headers });
  }

  getFile(selectedData: string) {
    const url = this.apiUrl + selectedData;
    const reportAuth = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);

    return this.httpClient.get(url, { headers: httpHeaders, responseType: 'blob' as 'json'});
  }

  uploadFile(selectedData: string, sentfiles: any) {
    const url =  this.apiUrl + selectedData;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);
    const formData = new FormData();

    sentfiles.forEach((file, index) => {
      formData.append('file' + index , file);
    });

    return this.httpClient.post(url, formData, { headers });
  }

  putRequest(route: string, body: object): Observable<any> {
    const url = this.apiUrl + route;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);

    return this.httpClient.put(url, body, { headers })
    .map((response) => {
      return response;
    });
  }

  postRequest(route: string, body: object) {
    const url = this.apiUrl + route;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);

    return this.httpClient.post(url, body, { headers })
    .map((response) => {
      return response;
    });
  }

  postRequest2(route: string, body: object) {
    const url = this.apiUrl + route;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);

    return this.httpClient.post(url, body, { headers });
  }

  postBlob(route: string, body: object): Observable<Blob> {
    const url = this.apiUrl + route;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);

    return this.httpClient.post<Blob>(url, body, { headers, responseType: 'blob' as 'json'});
  }

  patchRequest(route: string, body?: object) {
    const url = this.apiUrl + route;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);

    return this.httpClient.patch(url, body, { headers });
  }

  reassignSuper(object) {
    const url = this.apiUrl + 'ReassignSuper';
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);
    const body = {
        SalesOrderNo: object.salesOrderNo,
        WTNumber: object.wtNumber,
        WTStep: object.wtStep,
        newSuper: object.newSuper,
        oldSuper: object.oldSuper,
        reassignedBy: object.reassignedBy,
        superOverwrite: object.superOverwrite
    };

    return this.httpClient.put(url, body, { headers })
    .map((response: Response) => {
      return response;
    });
  }

  updateWTStatus(object) {
    const url = this.apiUrl + 'UpdWTStatus';
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);
    const body = {
      username: object.username,
      password: object.password,
      salesorderno: object.salesOrderNo,
      wtnumber: object.wtNumber,
      wtstep: object.wtStep,
      notes: object.notes,
      status: object.status
    };

    return this.httpClient.put(url, body, { headers })
    .map((response: Response) => {
      return response;
    });
  }

  updateMaterialUsage(object) {
    const url = this.apiUrl + 'UpdMatUsage';
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);

    const body = {
      username: object.username,
      password: object.password,
      salesorderno: object.salesOrderNo,
      editedlines: object.editedLines,
      addedlines: object.addedLines
    };

    return this.httpClient.put(url, body, {headers})
    .map((response: Response) => {
      return response;
    });
  }

  QRupdateMaterialUsage(object) {
    const url = this.apiUrl + 'QRCode/Update/MAT';
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);

    const body = {
      username: object.username,
      password: object.password,
      salesorderno: object.salesOrderNo,
      editedlines: object.editedLines,
      addedlines: object.addedLines
    };

    return this.httpClient.put(url, body, {headers})
    .map((response: Response) => {
      return response;
    });
  }

  scheduleTicketSuper(object) {
    const url = this.apiUrl + 'SchSuper';
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);

    const body = {
      SalesOrderNo: object.salesOrderNo,
      WTNumber: object.wtNumber,
      WTStep: object.wtStep,
      ScheduleDate: object.newScheduleDate,
      ScheduleOrder: object.Order,
      ScheduleOrderEpoch: object.OrderTime,
      reassignedBy: object.updatedBy
    };
    return this.httpClient.put(url, body, { headers })
      .map((response: Response) => {
        return response;
      });
  }

  scheduleConfirmationSubmittal(object) {
    const url = this.apiUrl + 'ScheduleConfirmation';
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);

    const body = object;

    return this.httpClient.put(url, body, { headers })
      .map((response: Response) => {
        return response;
      });
  }

  _generateSDataBody(payload: string) {
    return (
      `<entry xmlns:atom="http://www.w3.org/2005/Atom" ` +
        `xmlns:xs="http://www.w3.org/2001/XMLSchema" ` +
        `xmlns:cf="http://www.microsoft.com/schemas/rss/core/2005" ` +
        `xmlns="http://www.w3.org/2005/Atom" ` +
        `xmlns:sdata="http://schemas.sage.com/sdata/2008/1" ` +
        `xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ` +
        `xmlns:opensearch="http://a9.com/-/spec/opensearch/1.1/" ` +
        `xmlns:sync="http://schemas.sage.com/sdata/sync/2008/1" ` +
        `xmlns:sme="http://schemas.sage.com/sdata/sme/2007" ` +
        `xmlns:http="http://schemas.sage.com/sdata/http/2008/1">` +
      '<sdata:payload>' +
      payload +
      '</sdata:payload>' +
      '</entry>'
    );
  }

  _callPut() {
    const url = this.sDataUrl + `/JT_WorkTicket('0009998;030;000')/`;
    const tableName = 'JT_WorkTicket';
    const body = this._generateSDataBody(
      `<JT_WorkTicket sdata:uri="/sdata/MasApp/MasContract/` +
      'PLU' +
      `/JT_WorkTicket('0009998;030;000')" xmlns="">` +
      `<StatusCode>WOB</StatusCode>` +
      `<UDF_TRACKING_COMMENT>Angular 8</UDF_TRACKING_COMMENT>` +
      '</JT_WorkTicket>'
    );
    const sDataAuth = 'ZGVzdHJhZGE6R29kaXNsb3Zl';
    const httpOptions = {
      headers: new HttpHeaders({
        'cache-control': 'no-cache',
        Accept: '*/*',
        Authorization: `Basic ${sDataAuth}`,
        'Content-Type': 'application/atom+xml;type=entry'
      }),
      responseType: 'text' as 'json'
    };
    const sageApiInstance = this;
    return this.httpClient.put(url, body, httpOptions)
      .map((response: Response) => {
        let returnedResponse;
        xml2js.parseString(response, (err, result) => {
          const payLoadArray = [];
          const detailArray = {};
          (result.entry['sdata:payload'][0][tableName]).forEach(detail => {
            const element = Object.keys(detail);
            for (let index = 0; index < Object.keys(detail).length; index++) {
              const name = element[index];
              if (name !== '$') {
                detailArray[name] = detail[name][0];
              }
            }
          });
          payLoadArray.push(detailArray);
          returnedResponse = payLoadArray;
        });
        return returnedResponse;
      });
  }

  checkLogin() {
    const whereEncoded = `PurchaseOrderNo ne null`;
    const url = this.sDataUrl + `/PO_PurchaseOrderHeader()?count=10000&where=${whereEncoded}`;
    const tableName = 'PO_PurchaseOrderHeader';
    // '/JT_WorkTicket/$schema';
    const sDataAuth = 'ZGVzdHJhZGE6R29kaXNsb3Zl';
    const httpOptions = {
      headers: new HttpHeaders({
        'cache-control': 'no-cache',
        Accept: '*/*',
        Authorization: `Basic ${sDataAuth}`
      }),
      responseType: 'text' as 'json'
    };

    const sageApiInstance = this;
    return this.httpClient.get(url, httpOptions)
      .map((response: Response) => {
        let returnedResponse;
        xml2js.parseString(response, (err, result) => {
          const payLoadArray = [];
          console.log(result.feed.entry);
          if (result.feed.entry.length > 0) {
            result.feed.entry.forEach(entry => {
              const detailArray = {};
              (entry['sdata:payload'][0][tableName]).forEach(detail => {
                const element = Object.keys(detail);
                for (let index = 0; index < Object.keys(detail).length; index++) {
                  const name = element[index];
                  if (name !== '$') {
                    detailArray[name] = detail[name][0];
                  }
                }
              });
              payLoadArray.push(detailArray);
            });
          }
          returnedResponse = payLoadArray;
        });
        return returnedResponse;
        // sageApiInstance._getJsonDataFromXML(response.text(), 'SO_UDT_SUBDIVISION');
      }); // look up when server doesnt return json data pipe on angular.io
  }

  // // dashboard scout images Ne
  // _getImages(address: any) {
  //   const observables = [];
  //   this.imageUrlList.length = 0;
  //   const albumBucketName = 'gibson-qa';
  //   const AWS = require('aws-sdk');
  //   const accessKeyId = 'XBCEO6TZ5X4188GVG4ZT';
  //   const secretAccessKey = 'EHw4C4Re1OOCYZNdrL8yrqpmcO0ZuiQnfJ3zrl2z';
  //   const wasabiEndpoint = new AWS.Endpoint('https://s3.wasabisys.com');
  //   const s3 = new AWS.S3({
  //     endpoint: wasabiEndpoint,
  //     region: 'us-east-1',
  //     version: 'latest',
  //     accessKeyId,
  //     secretAccessKey,
  //     params: {
  //       Bucket: 'gibson-qa'
  //     }
  //   });
  //   const self = this;
  //   const albumPhotosKey = 'uploads/' + address;
  //
  //   s3.listObjects({ Prefix: albumPhotosKey }, function(err, data) {
  //     if (err) {
  //       return alert('There was an error viewing your album: ' + err.message);
  //     } else {
  //       // 'this' references the AWS.Response instance that represents the response
  //       const href = this.request.httpRequest.endpoint.href;
  //       const bucketUrl = href + albumBucketName + '/';
  //       console.log('bucket url: ' + bucketUrl);
  //       const photos = data.Contents.map((photo) => {
  //         const photoKey = photo.Key;
  //         // console.log('photo key: '+ photoKey);
  //         const photoUrl = bucketUrl + encodeURIComponent(photoKey);
  //         console.log('photo url: ' + photoUrl);
  //         self.imageUrlList.push(photoUrl);
  //       });
  //       console.log(self.imageUrlList);
  //       return self.imageUrlList;
  //     }
  //   });
  //
  //   return Observable.of(self.imageUrlList);
  // }
  //
  // // Get Images function may not work due to change requests
  // getImages(address: string) {
  //   const url = `https://qa.gibsonplumbing.com/GibsonQA/gibson-plumbing.php?op=list&WTN=${address}`;
  //   return this._http
  //     .post(
  //       url,
  //       {
  //         WTN: address
  //       },
  //       this._header()
  //     )
  //     .map((res) => {
  //       console.log(res);
  //       console.log(res.json());
  //       return res.json();
  //     });
  // }
  //
  // _header(moreHeaders?: Array<any>) {
  //   const authCode = this.auth.getUserToken();
  //   const headers = new Headers();
  //   headers.append('Authorization', `Basic ${authCode}`);
  //   if (moreHeaders && moreHeaders.length) {
  //     for (const i in moreHeaders) {
  //       headers.append(moreHeaders[i].key, moreHeaders[i].value);
  //     }
  //   }
  //   return new RequestOptions({ headers });
  // }

  // _getPunchImages(address: any, imageKey: string) {
  //   const observables = [];
  //   this.imageUrlList.length = 0;
  //   const albumBucketName = 'gibson-qa';
  //   const AWS = require('aws-sdk');
  //   const accessKeyId = 'XBCEO6TZ5X4188GVG4ZT';
  //   const secretAccessKey = 'EHw4C4Re1OOCYZNdrL8yrqpmcO0ZuiQnfJ3zrl2z';
  //   const wasabiEndpoint = new AWS.Endpoint('https://s3.wasabisys.com');
  //   const s3 = new AWS.S3({
  //     endpoint: wasabiEndpoint,
  //     region: 'us-east-1',
  //     version: 'latest',
  //     accessKeyId,
  //     secretAccessKey,
  //     params: {
  //       Bucket: 'gibson-qa'
  //     }
  //   });
  //   const self = this;
  //   const albumPhotosKey = 'uploads/' + address;
  //
  //   s3.listObjects({ Prefix: albumPhotosKey }, function (err, data) {
  //     if (err) {
  //       return alert('There was an error viewing your album: ' + err.message);
  //     } else {
  //       // 'this' references the AWS.Response instance that represents the response
  //       const href = this.request.httpRequest.endpoint.href;
  //       const bucketUrl = href + albumBucketName + '/';
  //       // console.log('bucket url: ' + bucketUrl);
  //       const photos = data.Contents.map((photo) => {
  //         // console.log('photo', photo);
  //         const photoKey = photo.Key;
  //         // console.log('photo key: '+ photoKey);
  //         const photoUrl = bucketUrl + encodeURIComponent(photoKey);
  //         // console.log('photo url: '+ photoUrl);
  //         const punchPhotoKey = photoKey.split('-')[0];
  //         // console.log('punchphotokey:', punchPhotoKey);
  //         // console.log('our punch image key', albumPhotosKey + '/' + imageKey);
  //         if (punchPhotoKey === albumPhotosKey + '/' + imageKey) {
  //           self.imageUrlList.push(photoUrl);
  //         }
  //       });
  //       console.log(self.imageUrlList);
  //       return self.imageUrlList;
  //     }
  //   });
  //
  //   return Observable.of(self.imageUrlList);
  // }

}
