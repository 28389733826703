import { Component, OnInit } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { AlertifyService } from 'src/app/_services/alertify/alertify.service';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import House360ReportInterface from 'src/app/_services/sageApi/interfaces/pullReport/House360ReportInterface';
import House360TrimItemInterface from 'src/app/_services/sageApi/interfaces/pullReport/House360TrimItemInterface';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';


@Component({
  selector: 'app-house-360',
  templateUrl: './house-360.component.html',
  styleUrls: ['./house-360.component.css']
})
export class House360Component implements OnInit {

  house360Reports: House360ReportInterface[] = []
  house360ReportsLoading: boolean = true;
  house360ReportsSearchable: boolean = false;
  house360ReportsAddressContrl = new FormControl('', []);
  house360ReportsSONumContrl = new FormControl('', []);
  house360ReportsDateContrlMin: Date;
  house360ReportsDateContrlMax: Date;
  house360ReportsDateContrl = new FormGroup({
    start: new FormControl(null, []),
    end: new FormControl(null, []),
  });

  house360TrimItems: House360TrimItemInterface[] = []
  house360TrimItemsLoading: boolean = true;
  house360TrimSearchable: boolean = false;
  house360TrimAddressContrl = new FormControl('', []);
  house360TrimSONumContrl = new FormControl('', []);
  house360TrimDateContrlMin: Date;
  house360TrimDateContrlMax: Date;
  house360TrimDateContrl = new FormGroup({
    start: new FormControl(null, []),
    end: new FormControl(null, []),
  });

  constructor(
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    public screenSize: ScreenSizeService
  ) {
    this.setDefaultHouse360ReportsDate();
    this.setDefaultHouse360TrimDate();
  }

  ngOnInit() {
    this.house360ReportsLoading = false;
    this.house360TrimItemsLoading = false;

    this.setDefaultHouse360ReportsDate();
    // this.setHouse360ReportsDateSearchable();

    this.setDefaultHouse360TrimDate();
    // this.setHouse360TrimDateSearchable();
    
    // this.pullHouse360();
    // this.pullHouse360TrimItems();
  }

  pullHouse360(){
    this.house360ReportsLoading = true;
    const path = 'SuperHouse360/360-report';
    const qparams = new URLSearchParams();

    if(this.house360ReportsAddressContrl.value != ''){
      qparams.set('address', this.house360ReportsAddressContrl.value);
    }
    if(this.house360ReportsSONumContrl.value != ''){
      qparams.set('soNumber', this.house360ReportsSONumContrl.value);
    }
    if(
      !(this.house360ReportsDateContrl.value?.start == null || this.house360ReportsDateContrl.value?.start == '') 
      && !(this.house360ReportsDateContrl.value?.end == null || this.house360ReportsDateContrl.value?.end == ''
    )){
      qparams.set('startDate', this.house360ReportsDateContrl.value.start.toISOString().slice(0, 10));
      qparams.set('endDate', this.house360ReportsDateContrl.value.end.toISOString().slice(0, 10));
    }
    this.sageApi.pullReport(
      `${path}?${qparams.toString()}`,
      {
        matchCriteria: ['360-report'],
        exp: 60000 // 1min
      }
    ).subscribe((results: House360ReportInterface[])=>{
      this.house360Reports = results.sort((a,b)=>{return parseInt(`${a.S_O_||0}`) - parseInt(`${b.S_O_||0}`);});;
      this.house360ReportsLoading = false;
    },
    (err)=>{
      if(err?.status == 0){
        this.alertify.error(`Could not reach the server for the following resource: "SuperHouse360/360-report"! `);
      }else{
        this.alertify.error(`Could not load "SuperHouse360/360-report"! \nCheck with IT if this persists.`);
      }
      this.house360ReportsLoading = false;
      console.log(err);
    });
  }

  setDefaultHouse360ReportsDate(setHouse360ReportsDateContrl?: boolean){
    const house360ReportsMinDate = new Date((new Date()).toISOString().slice(0, 10));
    house360ReportsMinDate.setMonth(house360ReportsMinDate.getMonth() - 24);
    house360ReportsMinDate.setHours(0);
    house360ReportsMinDate.setMinutes(0);
    house360ReportsMinDate.setSeconds(0);
    house360ReportsMinDate.setMilliseconds(0);
    const house360ReportsMaxDate = new Date((new Date()).toISOString().slice(0, 10));
    house360ReportsMaxDate.setDate(house360ReportsMaxDate.getDate() + 7);
    house360ReportsMaxDate.setHours(23);
    house360ReportsMaxDate.setMinutes(59);
    house360ReportsMaxDate.setSeconds(59);
    house360ReportsMaxDate.setMilliseconds(999);

    const house360ReportsstartDate = new Date((new Date()).toISOString().slice(0, 10));
    house360ReportsstartDate.setMonth(house360ReportsMinDate.getMonth() - 6);
    house360ReportsstartDate.setHours(23);
    house360ReportsstartDate.setMinutes(59);
    house360ReportsstartDate.setSeconds(59);
    house360ReportsstartDate.setMilliseconds(999);

    this.house360ReportsDateContrlMin = house360ReportsMinDate;
    this.house360ReportsDateContrlMax = house360ReportsMaxDate;
    if(setHouse360ReportsDateContrl){
      this.house360ReportsDateContrl = new FormGroup({
        start: new FormControl(house360ReportsstartDate, []),
        end: new FormControl(house360ReportsMaxDate, []),
      });
    }
  }

  onHouse360ReportsDateClick(){
    if(!this.house360ReportsDateContrl.dirty){
      this.setDefaultHouse360ReportsDate(true);
      this.setHouse360ReportsSearchable();
    }
  }

  onHouse360ReportsDateChange(e){
    this.setHouse360ReportsSearchable();
  }

  isHouse360ReportsDateEmpty(){
    let dateEmpty = false;
    if(this.house360ReportsDateContrl.value?.start == null && this.house360ReportsDateContrl.value?.end == null){
      dateEmpty = true;
    }
    if(this.house360ReportsDateContrl.value?.start == '' && this.house360ReportsDateContrl.value?.end == ''){
      dateEmpty = true;
    }
    return dateEmpty;
  }

  isHouse360ReportsDateValid(){
    let dateSearchable = true;
    if(this.house360ReportsDateContrl.value?.start == '' || this.house360ReportsDateContrl.value?.end == ''){
      dateSearchable = false;
    }else if(this.house360ReportsDateContrl.value?.start == null || this.house360ReportsDateContrl.value?.end == null){
      dateSearchable = false;
    }else{
      if(this.house360ReportsDateContrl.value?.start.getTime() > this.house360ReportsDateContrl.value?.end.getTime()){
        dateSearchable = false;
      }
      if(this.house360ReportsDateContrl.value?.start.getTime() < this.house360ReportsDateContrlMin.getTime() || this.house360ReportsDateContrl.value?.end.getTime() > this.house360ReportsDateContrlMax.getTime()){
        dateSearchable = false;
      }
    }
    return dateSearchable;
  }

  isHouse360ReportsControlsEmpty(){
    let controlsEmpty = false;
    if(
      (this.house360ReportsDateContrl.value?.start == null || this.house360ReportsDateContrl.value?.start == '')
      && (this.house360ReportsDateContrl.value?.end == null || this.house360ReportsDateContrl.value.end == '')
      && this.house360ReportsAddressContrl.value == ''
      && this.house360ReportsSONumContrl.value == ''
    ){
      controlsEmpty = true;
    }
    return controlsEmpty;
  }

  setHouse360ReportsSearchable(){
    const isDateEmpty = this.isHouse360ReportsDateEmpty();
    const isDateSearchable = this.isHouse360ReportsDateValid();
    const isControlsEmpty = this.isHouse360ReportsControlsEmpty();

    if(!isDateEmpty && !isDateSearchable){
      this.house360ReportsSearchable = false;
      return;
    }
    this.house360ReportsSearchable = !isControlsEmpty;
  }

  pullHouse360TrimItems(){
    this.house360TrimItemsLoading = true;
    const path = 'SuperHouse360/ztrim-house-360';
    const qparams = new URLSearchParams();

    if(this.house360TrimAddressContrl.value != ''){
      qparams.set('address', this.house360TrimAddressContrl.value);
    }
    if(this.house360TrimSONumContrl.value != ''){
      qparams.set('soNumber', this.house360TrimSONumContrl.value);
    }
    if(
      !(this.house360TrimDateContrl.value?.start == null || this.house360TrimDateContrl.value?.start == '') 
      && !(this.house360TrimDateContrl.value?.end == null || this.house360TrimDateContrl.value?.end == ''
    )){
      qparams.set('startDate', this.house360TrimDateContrl.value.start.toISOString().slice(0, 10));
      qparams.set('endDate', this.house360TrimDateContrl.value.end.toISOString().slice(0, 10));
    }
    this.sageApi.pullReport(
      `${path}?${qparams.toString()}`,
      {
        matchCriteria: ['ztrim-house-360'],
        exp: 60000 // 1min
      }
    ).subscribe((results: House360TrimItemInterface[])=>{
      this.house360TrimItems = results.sort((a,b)=>{return parseInt(`${a.SONum||0}`) - parseInt(`${b.SONum||0}`);});
      this.house360TrimItemsLoading = false;
    },
    (err)=>{
      if(err?.status == 0){
        this.alertify.error(`Could not reach the server for the following resource: "SuperHouse360/ztrim-house-360"! `);
      }else{
        this.alertify.error(`Could not load "SuperHouse360/ztrim-house-360"! \nCheck with IT if this persists.`);
      }
      this.house360TrimItemsLoading = false;
      console.log(err);
    });
  }

  setDefaultHouse360TrimDate(setHouse360TrimReportsDateContrl?: boolean){
    const house360TrimMinDate = new Date((new Date()).toISOString().slice(0, 10));
    house360TrimMinDate.setMonth(house360TrimMinDate.getMonth() - 24);
    house360TrimMinDate.setHours(0);
    house360TrimMinDate.setMinutes(0);
    house360TrimMinDate.setSeconds(0);
    house360TrimMinDate.setMilliseconds(0);
    const house360TrimMaxDate = new Date((new Date()).toISOString().slice(0, 10));
    house360TrimMaxDate.setDate(house360TrimMaxDate.getDate() + 7);
    house360TrimMaxDate.setHours(23);
    house360TrimMaxDate.setMinutes(59);
    house360TrimMaxDate.setSeconds(59);
    house360TrimMaxDate.setMilliseconds(999);

    const house360TrimstartDate = new Date((new Date()).toISOString().slice(0, 10));
    house360TrimstartDate.setMonth(house360TrimMinDate.getMonth() - 1);
    house360TrimstartDate.setHours(23);
    house360TrimstartDate.setMinutes(59);
    house360TrimstartDate.setSeconds(59);
    house360TrimstartDate.setMilliseconds(999);

    this.house360TrimDateContrlMin = house360TrimMinDate;
    this.house360TrimDateContrlMax = house360TrimMaxDate;
    if(setHouse360TrimReportsDateContrl){
      this.house360TrimDateContrl = new FormGroup({
        start: new FormControl(house360TrimstartDate, []),
        end: new FormControl(house360TrimMaxDate, []),
      });
    }
  }

  onHouse360TrimDateClick(){
    if(!this.house360TrimDateContrl.dirty){
      this.setDefaultHouse360TrimDate(true);
      this.setHouse360TrimSearchable();
    }
  }

  onHouse360TrimDateChange(e){
    this.setHouse360TrimSearchable();
  }

  isHouse360TrimDateEmpty(){
    let dateEmpty = false;
    if(this.house360TrimDateContrl.value?.start == null && this.house360TrimDateContrl.value?.end == null){
      dateEmpty = true;
    }
    if(this.house360TrimDateContrl.value?.start == '' && this.house360TrimDateContrl.value?.end == ''){
      dateEmpty = true;
    }
    return dateEmpty;
  }

  isHouse360TrimDateValid(){
    let dateSearchable = true;
    if(this.house360TrimDateContrl.value?.start == '' || this.house360TrimDateContrl.value?.end == ''){
      dateSearchable = false;
    }else if(this.house360TrimDateContrl.value?.start == null || this.house360TrimDateContrl.value?.end == null){
      dateSearchable = false;
    }else{
      if(this.house360TrimDateContrl.value?.start.getTime() > this.house360TrimDateContrl.value?.end.getTime()){
        dateSearchable = false;
      }
      if(this.house360TrimDateContrl.value?.start.getTime() < this.house360TrimDateContrlMin.getTime() || this.house360TrimDateContrl.value?.end.getTime() > this.house360TrimDateContrlMax.getTime()){
        dateSearchable = false;
      }
    }
    return dateSearchable;
  }

  isHouse360TrimControlsEmpty(){
    let controlsEmpty = false;
    if(
      (this.house360TrimDateContrl.value?.start == null || this.house360TrimDateContrl.value?.start == '')
      && (this.house360TrimDateContrl.value?.end == null || this.house360TrimDateContrl.value.end == '')
      && this.house360TrimAddressContrl.value == ''
      && this.house360TrimSONumContrl.value == ''
    ){
      controlsEmpty = true;
    }
    return controlsEmpty;
  }

  setHouse360TrimSearchable(){
    const isDateEmpty = this.isHouse360TrimDateEmpty();
    const isDateSearchable = this.isHouse360TrimDateValid();
    const isControlsEmpty = this.isHouse360TrimControlsEmpty();

    if(!isDateEmpty && !isDateSearchable){
      this.house360TrimSearchable = false;
      return;
    }
    this.house360TrimSearchable = !isControlsEmpty;
  }

  normalizeDate(row: House360ReportInterface, val: string): string{
    let d = val?.slice(0, 10);
    if(d == '1753-01-01'){ // This is our wonderfull null date
      return '';
    }
    return d;
  }

  normalizeMoney(row: House360ReportInterface, val: string): string{
    return `$${parseInt(val).toFixed(2)}`;
  }

}
