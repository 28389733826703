<ng-container>
  <li class="list-group-item" *ngIf="filterCounterTickets"
      [ngStyle]="{ 'background-color' : ticketColor}" cdkDrag>
    <ul class="list-group list-group-horizontal-sm">
      <li class="list-group-item col-sm border-0 p-0 text-sm font-weight-bold">
        <button class="btn btn-link font-weight-bold" (click)="requestModalOpenHandler()">#{{tickets.fullTicketNo}}</button></li>
    </ul>
    <ul class="list-group">
      <li class="list-group-item border-0 p-0 text-sm"
          (click)="tickets.displayInfo ? tickets.displayInfo = false : tickets.displayInfo = true">
        <i *ngIf="!tickets.displayInfo" class="fas fa-caret-right pr-1"></i>
        <i *ngIf="tickets.displayInfo" class="fas fa-caret-down pr-1"></i>{{tickets.address}}</li>
      <li class="list-group-item border-0 p-0 text-sm" *ngIf="tickets.displayInfo">{{tickets.city}}</li>
      <li class="list-group-item border-0 p-0 text-sm" *ngIf="tickets.displayInfo">DIV: {{tickets.subdivision}}</li>
      <li
        class="list-group-item border-0 p-0 text-sm" *ngIf="tickets.displayInfo">{{tickets.itemCode === '' ? phaseByCrew : tickets.itemCode}}</li>
      <li class="list-group-item border-0 p-0 text-sm" *ngIf="tickets.displayInfo">Status: {{tickets.statusCode}}</li>
      <li class="list-group-item border-0 p-0 text-sm">
        Crew: {{tickets.techID + ' - ' + techName}}</li>
    </ul>
  </li>
</ng-container>