<div cdkDropListGroup>
  <div class="card border-0 jumbotron p-0">
    <div class="card-header" *ngIf="authService.decodedToken.role !== 'super' && authService.decodedToken.role !== 'superdfw'">
      <div class="row">
        <div class="col-sm-3">
          <div class="card m-1">
            <div class="btn-group" dropdown>
              <button dropdownToggle type="button" class="btn btn-outline-dark dropdown-toggle">
                {{ currentSuper }} Selected <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li role="menuitem" *ngFor="let super of supersList; let supersIndex = index">
                  <a class="dropdown-item" (click)="onSuperSelected(super.techCode, supersIndex)">
                    <span>{{ super.name }}</span><i
                  *ngIf="super.selected" class="fas fa-check fa-border fa-pull-right"></i></a></li>
                <!-- <li class="divider dropdown-divider"></li>
                <li role="menuitem"><a class="dropdown-item" (click)="onSuperSelected('', 0)">Select None</a>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-header" *ngIf="authService.decodedToken.role == 'superdfw'">
      <div class="row">
        <div class="col-sm-3">
          <div class="card m-1">
            <div class="btn-group" dropdown>
              <button dropdownToggle type="button" class="btn btn-outline-dark dropdown-toggle">
                {{ currentSuper }} Selected <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li role="menuitem" *ngFor="let super of supersList; let supersIndex = index"
                    [ngStyle]="{'display': super.name == '' || !(super.name !== undefined && super.name.toLocaleLowerCase().includes('dfw')) ? 'none' : ''}">
                  <a class="dropdown-item" (click)="onSuperSelected(super.techCode, supersIndex)">
                    <span>{{ super.name }}</span><i
                    *ngIf="super.selected" class="fas fa-check fa-border fa-pull-right"></i></a></li>
                <!-- <li class="divider dropdown-divider"></li>
                <li role="menuitem"><a class="dropdown-item" (click)="onSuperSelected('', 0)">Select None</a>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body border-0">
      <div id="dragDropArea" class="row">
        <div class="container col-12 col-sm-3 pl-0">
          <div>
            <div class="card m-3 bg-primary">
              <div class="card-header">
                <div class="row">
                  <div class="col-12 text-center text-white">
                    <strong>{{ currentSuper }} Tickets</strong>
                  </div>
                </div>
              </div>
              <ul
                class="list-group list-group-flush"
                cdkDropList
                cdkDropListOrientation="vertical"
                [cdkDropListData]="supersList[currentSuperIndex].tickets.unsorted?.punch"
                (cdkDropListDropped)="drop($event, 'unsorted', currentSuperIndex)">
                <ng-container
                  *ngFor="let item of supersList[currentSuperIndex].tickets.unsorted?.punch; let ticketIndex = index; let c = count; let isFirst = first;">
                  <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                    Total Tickets {{ c }}
                  </li>
                  <li
                    class="list-group-item"
                    cdkDrag [cdkDragDisabled]="[item.disabled]">
                    <ul class="list-group list-group-horizontal">
                      <li class="list-group-item col-6 border-0 p-0 text-sm font-weight-bold mt-auto">
                        {{item.phase | titlecase}}
                        <span class="badge badge-primary badge-pill" *ngIf="!item.disabled">{{item.dip}}</span><span
                        *ngIf="item.disabled && !item.updated"><i class="fas fa-cog fa-spin"></i></span>
                      </li>
                      <li class="list-group-item col-6 border-0 p-0 text-sm text-right">
                        <button mat-icon-button (click)="onEditPunchTicket(currentSuperIndex, 'unsorted', ticketIndex)"
                                [disabled]="item.disabled">
                          <mat-icon>edit</mat-icon>
                        </button>
                      </li>
                    </ul>
                    <!-- <ul class="list-group list-group-horizontal">
                      <li class="list-group-item col-4 border-0 p-0 text-sm"><span class="badge badge-dark" *ngIf="item.phase.toLowerCase() === 'punch'">{{item.punchTime}}</span></li>
                      <li class="list-group-item col-4 border-0 p-0 text-sm"><span class="badge badge-primary badge-pill" *ngIf="!item.disabled">{{item.dip}}</span><span *ngIf="item.disabled && !item.updated"><i class="fas fa-cog fa-spin"></i></span></li>
                    </ul> -->
                    <ul class="list-group">
                      <li class="list-group-item border-0 p-0 text-sm">{{item.address}}</li>
                      <li class="list-group-item border-0 p-0 text-sm">{{item.subdivision | titlecase}}</li>
                      <li class="list-group-item border-0 p-0 text-sm">{{item.status}}</li>
                    </ul>
                    <ul class="list-group list-group-horizontal-sm">
                      <li
                        class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold">{{item.description}}</li>
                    </ul>
                  </li>
                </ng-container>
                <li
                  class="list-group-item text-center"
                ></li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="container col-9 bg-danger">
          <div class="container-fluid">
            <header>
              <h4 class="display-4 mb-4 text-center">November 2017</h4>
              <div class="row d-none d-sm-flex p-1 bg-dark text-white">
                <h5 class="col-sm p-1 text-center">Sunday</h5>
                <h5 class="col-sm p-1 text-center">Monday</h5>
                <h5 class="col-sm p-1 text-center">Tuesday</h5>
                <h5 class="col-sm p-1 text-center">Wednesday</h5>
                <h5 class="col-sm p-1 text-center">Thursday</h5>
                <h5 class="col-sm p-1 text-center">Friday</h5>
                <h5 class="col-sm p-1 text-center">Saturday</h5>
              </div>
            </header>
            <div class="row border border-right-0 border-bottom-0">
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted">
                <h5 class="row align-items-center">
                  <span class="date col-1">29</span>
                  <small class="col d-sm-none text-center text-muted">Sunday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted">
                <h5 class="row align-items-center">
                  <span class="date col-1">30</span>
                  <small class="col d-sm-none text-center text-muted">Monday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted">
                <h5 class="row align-items-center">
                  <span class="date col-1">31</span>
                  <small class="col d-sm-none text-center text-muted">Tuesday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">1</span>
                  <small class="col d-sm-none text-center text-muted">Wednesday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">2</span>
                  <small class="col d-sm-none text-center text-muted">Thursday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">3</span>
                  <small class="col d-sm-none text-center text-muted">Friday</small>
                  <span class="col-1"></span>
                </h5>
                <a class="event d-block p-1 pl-2 pr-2 mb-1 rounded text-truncate small bg-info text-white" title="Test Event 1">Test Event 1</a>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">4</span>
                  <small class="col d-sm-none text-center text-muted">Saturday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="w-100"></div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">5</span>
                  <small class="col d-sm-none text-center text-muted">Sunday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">6</span>
                  <small class="col d-sm-none text-center text-muted">Monday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">7</span>
                  <small class="col d-sm-none text-center text-muted">Tuesday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">8</span>
                  <small class="col d-sm-none text-center text-muted">Wednesday</small>
                  <span class="col-1"></span>
                </h5>
                <a class="event d-block p-1 pl-2 pr-2 mb-1 rounded text-truncate small bg-success text-white" title="Test Event 2">Test Event 2</a>
                <a class="event d-block p-1 pl-2 pr-2 mb-1 rounded text-truncate small bg-danger text-white" title="Test Event 3">Test Event 3</a>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">9</span>
                  <small class="col d-sm-none text-center text-muted">Thursday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">10</span>
                  <small class="col d-sm-none text-center text-muted">Friday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">11</span>
                  <small class="col d-sm-none text-center text-muted">Saturday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="w-100"></div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">12</span>
                  <small class="col d-sm-none text-center text-muted">Sunday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">13</span>
                  <small class="col d-sm-none text-center text-muted">Monday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">14</span>
                  <small class="col d-sm-none text-center text-muted">Tuesday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">15</span>
                  <small class="col d-sm-none text-center text-muted">Wednesday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">16</span>
                  <small class="col d-sm-none text-center text-muted">Thursday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">17</span>
                  <small class="col d-sm-none text-center text-muted">Friday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">18</span>
                  <small class="col d-sm-none text-center text-muted">Saturday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="w-100"></div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">19</span>
                  <small class="col d-sm-none text-center text-muted">Sunday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">20</span>
                  <small class="col d-sm-none text-center text-muted">Monday</small>
                  <span class="col-1"></span>
                </h5>
                <a class="event d-block p-1 pl-2 pr-2 mb-1 rounded text-truncate small bg-primary text-white" title="Test Event with Super Duper Really Long Title">Test Event with Super Duper Really Long Title</a>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">21</span>
                  <small class="col d-sm-none text-center text-muted">Tuesday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">22</span>
                  <small class="col d-sm-none text-center text-muted">Wednesday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">23</span>
                  <small class="col d-sm-none text-center text-muted">Thursday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">24</span>
                  <small class="col d-sm-none text-center text-muted">Friday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">25</span>
                  <small class="col d-sm-none text-center text-muted">Saturday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="w-100"></div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">26</span>
                  <small class="col d-sm-none text-center text-muted">Sunday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">27</span>
                  <small class="col d-sm-none text-center text-muted">Monday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">28</span>
                  <small class="col d-sm-none text-center text-muted">Tuesday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">29</span>
                  <small class="col d-sm-none text-center text-muted">Wednesday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                <h5 class="row align-items-center">
                  <span class="date col-1">30</span>
                  <small class="col d-sm-none text-center text-muted">Thursday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted">
                <h5 class="row align-items-center">
                  <span class="date col-1">1</span>
                  <small class="col d-sm-none text-center text-muted">Friday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted">
                <h5 class="row align-items-center">
                  <span class="date col-1">2</span>
                  <small class="col d-sm-none text-center text-muted">Saturday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="w-100"></div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted">
                <h5 class="row align-items-center">
                  <span class="date col-1">3</span>
                  <small class="col d-sm-none text-center text-muted">Sunday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted">
                <h5 class="row align-items-center">
                  <span class="date col-1">4</span>
                  <small class="col d-sm-none text-center text-muted">Monday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted">
                <h5 class="row align-items-center">
                  <span class="date col-1">5</span>
                  <small class="col d-sm-none text-center text-muted">Tuesday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted">
                <h5 class="row align-items-center">
                  <span class="date col-1">6</span>
                  <small class="col d-sm-none text-center text-muted">Wednesday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted">
                <h5 class="row align-items-center">
                  <span class="date col-1">7</span>
                  <small class="col d-sm-none text-center text-muted">Thursday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted">
                <h5 class="row align-items-center">
                  <span class="date col-1">8</span>
                  <small class="col d-sm-none text-center text-muted">Friday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
              <div class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted">
                <h5 class="row align-items-center">
                  <span class="date col-1">9</span>
                  <small class="col d-sm-none text-center text-muted">Saturday</small>
                  <span class="col-1"></span>
                </h5>
                <p class="d-sm-none">No events</p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-12 col-sm-3 pl-0">
          <div class="card mt-3 border-dark">
            <div class="card-header border-dark">
              <div class="col-12 text-center pl-0">
                <a (click)="calculateNextDays('backwards')"><i
                  class="fas fa-chevron-left fa-border fa-pull-left border-0"></i>{{ colOneDate | date : "EEE MMM, d, y" }}
                </a>
              </div>
            </div>
            <ul class="list-group list-group-flush"
                cdkDropList
                cdkDropListOrientation="vertical"
                [cdkDropListData]="supersList[currentSuperIndex].tickets[colOneDate]?.punch"
                (cdkDropListDropped)="drop($event, colOneDate, currentSuperIndex)">
              <ng-container
                *ngFor="let item of supersList[currentSuperIndex].tickets[colOneDate]?.punch; let ticketIndex = index; let c = count; let isFirst = first;">
                <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                  <strong>{{ c }}</strong> Tickets
                </li>
                <li
                  class="list-group-item"
                  cdkDrag [cdkDragDisabled]="[item.disabled]">
                  <ul class="list-group list-group-horizontal">
                    <li class="list-group-item col-6 border-0 p-0 text-sm font-weight-bold mt-auto">
                      #{{item.superOrder + 1}} {{item.phase | titlecase}}
                      <span class="badge badge-primary badge-pill" *ngIf="!item.disabled">{{item.dip}}</span><span
                      *ngIf="item.disabled && !item.updated"><i class="fas fa-cog fa-spin"></i></span>
                    </li>
                    <li class="list-group-item col-6 border-0 p-0 text-sm text-right">
                      <button mat-icon-button (click)="onEditPunchTicket(currentSuperIndex, colOneDate, ticketIndex)"
                              [disabled]="item.disabled">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </li>
                  </ul>
                  <!-- <ul class="list-group list-group-horizontal-sm">
                    <li class="list-group-item col-4 border-0 p-0 text-sm"><span class="badge badge-dark" *ngIf="item.phase.toLowerCase() === 'punch'">{{item.punchTime}}</span></li>
                    <li class="list-group-item col-4 border-0 p-0 text-sm"><span class="badge badge-primary badge-pill" *ngIf="!item.disabled">{{item.dip}}</span><span *ngIf="item.disabled && !item.updated"><i class="fas fa-cog fa-spin"></i></span></li>
                  </ul> -->
                  <ul class="list-group">
                    <li class="list-group-item border-0 p-0 text-sm">{{item.address}}</li>
                    <li class="list-group-item border-0 p-0 text-sm">{{item.subdivision | titlecase}}</li>
                    <li class="list-group-item border-0 p-0 text-sm">{{item.status}}</li>
                  </ul>
                  <ul class="list-group list-group-horizontal-sm">
                    <li
                      class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold">{{item.description}}</li>
                  </ul>
                </li>
              </ng-container>
              <li
                class="list-group-item text-center"
              ></li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-3 pl-0">
          <div class="card mt-3 border-dark">
            <div class="card-header border-dark">
              <div class="col-12 text-center">
                {{ colTwoDate | date : "EEE MMM, d, y" }}
              </div>
            </div>
            <ul class="list-group list-group-flush"
                cdkDropList
                cdkDropListOrientation="vertical"
                [cdkDropListData]="supersList[currentSuperIndex].tickets[colTwoDate]?.punch"
                (cdkDropListDropped)="drop($event, colTwoDate, currentSuperIndex)">
              <ng-container
                *ngFor="let item of supersList[currentSuperIndex].tickets[colTwoDate]?.punch; let ticketIndex = index; let c = count; let isFirst = first">
                <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                  <strong>{{ c }}</strong> Tickets
                </li>
                <li
                  class="list-group-item"
                  cdkDrag [cdkDragDisabled]="[item.disabled]">
                  <ul class="list-group list-group-horizontal">
                    <li class="list-group-item col-6 border-0 p-0 text-sm font-weight-bold mt-auto">
                      #{{item.superOrder + 1}} {{item.phase | titlecase}}
                      <span class="badge badge-primary badge-pill" *ngIf="!item.disabled">{{item.dip}}</span><span
                      *ngIf="item.disabled && !item.updated"><i class="fas fa-cog fa-spin"></i></span>
                    </li>
                    <li class="list-group-item col-6 border-0 p-0 text-sm text-right">
                      <button mat-icon-button (click)="onEditPunchTicket(currentSuperIndex, colTwoDate, ticketIndex)"
                              [disabled]="item.disabled">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </li>
                  </ul>
                  <!-- <ul class="list-group list-group-horizontal">
                    <li class="list-group-item col-4 border-0 p-0 text-sm"><span class="badge badge-dark" *ngIf="item.phase.toLowerCase() === 'punch'">{{item.punchTime}}</span></li>
                    <li class="list-group-item col-4 border-0 p-0 text-sm"><span class="badge badge-primary badge-pill" *ngIf="!item.disabled">{{item.dip}}</span><span *ngIf="item.disabled && !item.updated"><i class="fas fa-cog fa-spin"></i></span></li>
                  </ul> -->
                  <ul class="list-group">
                    <li class="list-group-item border-0 p-0 text-sm">{{item.address}}</li>
                    <li class="list-group-item border-0 p-0 text-sm">{{item.subdivision | titlecase}}</li>
                    <li class="list-group-item border-0 p-0 text-sm">{{item.status}}</li>
                  </ul>
                  <ul class="list-group list-group-horizontal-sm">
                    <li
                      class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold">{{item.description}}</li>
                  </ul>
                </li>
              </ng-container>
              <li
                class="list-group-item text-center"
              ></li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-3 pl-0">
          <div class="card mt-3 border-dark">
            <div class="card-header border-dark">
              <div class="col-12 text-center pr-0">
                <a (click)="calculateNextDays('forward')"> {{ colThreeDate | date : "EEE MMM, d, y" }} <i
                  class="fas fa-chevron-right fa-border fa-pull-right border-0"></i></a>
              </div>

            </div>
            <ul class="list-group list-group-flush"
                cdkDropList
                cdkDropListOrientation="vertical"
                [cdkDropListData]="supersList[currentSuperIndex].tickets[colThreeDate]?.punch"
                (cdkDropListDropped)="drop($event, colThreeDate, currentSuperIndex)">
              <ng-container
                *ngFor="let item of supersList[currentSuperIndex].tickets[colThreeDate]?.punch; let ticketIndex = index; let c = count; let isFirst = first">
                <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                  <strong>{{ c }}</strong> Tickets
                </li>
                <li
                  class="list-group-item"
                  cdkDrag [cdkDragDisabled]="[item.disabled]">
                  <ul class="list-group list-group-horizontal">
                    <li class="list-group-item col-6 border-0 p-0 text-sm font-weight-bold mt-auto">
                      #{{item.superOrder + 1}} {{item.phase | titlecase}}
                      <span class="badge badge-primary badge-pill" *ngIf="!item.disabled">{{item.dip}}</span><span
                      *ngIf="item.disabled && !item.updated"><i class="fas fa-cog fa-spin"></i></span>
                    </li>
                    <li class="list-group-item col-6 border-0 p-0 text-sm text-right">
                      <button mat-icon-button (click)="onEditPunchTicket(currentSuperIndex, colThreeDate, ticketIndex)"
                              [disabled]="item.disabled">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </li>
                  </ul>
                  <!-- <ul class="list-group list-group-horizontal">
                    <li class="list-group-item col-4 border-0 p-0 text-sm"><span class="badge badge-dark" *ngIf="item.phase.toLowerCase() === 'punch'">{{item.punchTime}}</span></li>
                    <li class="list-group-item col-4 border-0 p-0 text-sm"><span class="badge badge-primary badge-pill" *ngIf="!item.disabled">{{item.dip}}</span><span *ngIf="item.disabled && !item.updated"><i class="fas fa-cog fa-spin"></i></span></li>
                  </ul> -->
                  <ul class="list-group">
                    <li class="list-group-item border-0 p-0 text-sm">{{item.address}}</li>
                    <li class="list-group-item border-0 p-0 text-sm">{{item.subdivision | titlecase}}</li>
                    <li class="list-group-item border-0 p-0 text-sm">{{item.status}}</li>
                  </ul>
                  <ul class="list-group list-group-horizontal-sm">
                    <li
                      class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold">{{item.description}}</li>
                  </ul>
                </li>
              </ng-container>
              <li
                class="list-group-item text-center"
              ></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
