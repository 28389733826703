<h1 mat-dialog-title class="text-center">
    <mat-icon class="text-primary" style="font-size: 100px; width: 100px; height: 100px;">delete_forever</mat-icon> <br>
    Are you sure?
</h1>
<div mat-dialog-content class="text-center">
    Do you want to delete 
    Invoice {{ data.invoice.invoiceNo }} for PO {{ data.invoice.purchaseOrderNo }}?
</div>
<div mat-dialog-actions>
    <div class="text-center w-100">
        <button mat-stroked-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="danger" (click)="confirmDelete()">Delete</button>
    </div>
</div>
