<mat-card class="qnote">
  <app-grow-input
    [control]="billingTerm.nameControl"
    (inputEmitter)="nameInput($event)"
    (blurEmitter)="nameBlur()"
    [useNativeInput]="true"
    minWidth="100%"
    maxWidth="100%"
    class="qnote-name"
  ></app-grow-input>
  <mat-form-field appearance="outline" class="qnote-desc">
    <mat-label>Description</mat-label>
    <textarea
      [formControl]="billingTerm.descControl"
      matInput
      class="qnote-desc-in"
    ></textarea>
    <div class="qnote-desc-char">
        <span class="qnote-desc-char-len" [style]="{color: billingTerm.descControl.value.length <= 1024?'var(--gpc-primary)' : 'var(--gpc-warn)'}">{{billingTerm.descControl.value.length}}{{' '}}</span>
        /1024
    </div>
  </mat-form-field>
  <div class="qnote-actions">
    <div class="qwxclu-actions-front">
      <button (click)="deleteExclusion()" color="warn" mat-flat-button mat-button>Delete</button>
    </div>
    <div class="qwxclu-actions-back">
      <button
        [disabled]="isSaveDisabled()"
        (click)="resetControls()"
        color="warn"
        mat-flat-button
        mat-button
      >
        Cancel
      </button>
      <button
        [disabled]="isSaveDisabled()"
        (click)="saveChanges()"
        color="primary"
        mat-flat-button
        mat-button
      >
        Save
      </button>
    </div>
  </div>
</mat-card>
