import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScheduleConfirmationComponent } from './Components/Customers/schedule-confirmation/schedule-confirmation.component';
import { ScheduleConfirmationQueueComponent } from './Components/Scheduler/schedule-confirmation-queue/schedule-confirmation-queue.component';
import { AddressCustomerAssignmentComponent } from './Components/Scheduler/address-customer-assignment/address-customer-assignment.component';
import { LoginPasswordlessComponent } from './Components/UserInfo/login-passwordless/login-passwordless.component';
import { SchedulingComponent } from './Components/Scheduler/scheduling/scheduling.component';
import { HomePageComponent } from './Components/home-page/home-page.component';
import { AMDashboardComponent } from '../app/Components/AreaManagers/AMDashboard/AMDashboard.component';
import { ScheduleTicketsComponent } from '../app/Components/Supers/scheduleTickets/scheduleTickets.component';
import { TechTodoComponent } from '../app/Components/Supers/tech-todo/tech-todo.component';
import { EditWorkticketsComponent } from './Components/Material/edit-worktickets/edit-worktickets.component';
import { AuthGuard } from '../app/_guards/auth.guard';
import { ReworkQueueComponent } from './Components/Scheduler/rework-queue/rework-queue.component';
import { UploadPlansComponent } from './Components/UserInfo/upload-plans/upload-plans.component';
import { HouseEditorComponent } from './Components/BuilderAssociates/house-editor/house-editor.component';
import { ReceivingCreateComponent } from './Components/Material/receiving-create/receiving-create.component';
import { ReceivingUpdateComponent } from './Components/Material/receiving-update/receiving-update.component';
import { PhasePayComponent } from './Components/Supers/phase-pay/phase-pay.component';
//Removed PhasePayHistoryComponent with User Story 172
//import { PhasePayHistoryComponent } from './Components/Supers/phase-pay-history/phase-pay-history.component';
import { ReceiptOfGoodsApprovalComponent } from './Components/Accounting/receipt-of-goods-approval/receipt-of-goods-approval.component';
import { ReceiptOfInvoiceApprovalComponent } from './Components/Accounting/receipt-of-invoice-approval/receipt-of-invoice-approval.component';
import {AssignScheduleComponent} from './Components/Scheduler/assign-schedule/assign-schedule.component';
import { qaWorkticketComponent } from './Components/Supers/QA_WorkTicket/qa-workticket.component';
import { TemplateEditorComponent } from './Components/BuilderAssociates/template-editor/template-editor.component';
import {QrpartspullComponent} from './Components/QRCodeURLs/QRPartsPull/qrpartspull.component';
import { SettingsComponent } from './Components/Settings/settings/settings.component';
import {QrcrewlabordetailComponent} from './Components/QRCodeURLs/QRCrewlaborDetail/qrcrewlabordetail.component';
import { WomsManagementQueueComponent } from './Components/AreaManagers/woms-management-queue/woms-management-queue.component';
import { UnshippedItemsComponent } from './Components/Inventory/unshipped-items/unshipped-items.component';
import { RoiPendingComponent } from './Components/Accounting/ReceiptOfInvoice/roi-pending/roi-pending.component';
import { RoiApproveComponent } from './Components/Accounting/ReceiptOfInvoice/roi-approve/roi-approve.component';
import { SuperReportsComponent } from './Components/Supers/super-reports/super-reports.component';
import { SuperMiscpayComponent } from './Components/Supers/super-miscpay/super-miscpay.component';
import {WomsMgtComponent} from './Components/WOMs/MGT/woms-mgt.component';
// @ts-ignore
import { QrscannerComponent } from './Components/QRCodeURLs/QRScanner/qrscanner.component';
import { PasswordResetComponent } from './Components/UserInfo/password-reset/password-reset.component';
import {vpoPunchComponent} from './Components/Supers/Punch/vpo-punch.component';
import {WomsAcctComponent} from './Components/WOMs/ACCT/woms-acct.component';
import {WomsPriceComponent} from './Components/WOMs/PRICE/woms-price.component';
import {WarehouseTransferComponent} from './Components/Material/warehousetransfer/warehouse-transfer.component';
import {JobRequisitionComponent} from './Components/Material/job-requisition/job-requisition.component';
import { PullTicketCreationComponent } from './Components/ServiceTitan/pull-ticket-creation/pull-ticket-creation.component';
import {PullTicketHistoryComponent} from './Components/ServiceTitan/pull-ticket-history/pull-ticket-history.component';
import { WarehouseTransferTruckComponent } from './Components/ServiceTitan/warehouse-transfer-truck/warehouse-transfer-truck.component';
import { PullTicketAdditionalPartsComponent } from './Components/ServiceTitan/pull-ticket-additional-parts/pull-ticket-additional-parts.component';
import { MaterialScheduledComponent } from './Components/ServiceTitan/material-scheduled/material-scheduled.component';
import { MaterialReturnComponent } from './Components/ServiceTitan/material-return/material-return.component';
import { House360Component } from './Components/Supers/house-360/house-360.component';
import { AddressFilesComponent } from './Components/Supers/address-files/address-files.component';
import { AddressCreationComponent } from './Components/Scheduler/address-creation/address-creation.component';
import { WarehouseTransferHistoryComponent } from './Components/Material/warehouse-transfer-history/warehouse-transfer-history.component';
import { KitsViewComponent } from './Components/Quoting/KitsViewComponent/KitsViewComponent';
import { QuoteViewComponent } from './Components/Quoting/QuoteViewComponent/QuoteViewComponent';
import { KitViewComponent } from './Components/Quoting/KitViewComponent/KitViewComponent';
import { QuotesViewComponent } from './Components/Quoting/QuotesViewComponent/QuotesViewComponent';
import { QuoteQuestionsEditorViewComponent } from './Components/Quoting/QuoteQuestionsEditorViewComponent/QuoteQuestionsEditorViewComponent';
import { QuoteDashboardComponentComponent } from './Components/Quoting/QuoteDashboardComponent/QuoteDashboardComponent.component';
import { TruckManagerComponent } from './Components/ServiceTitan/truck-manager/truck-manager.component';
import { BatchingPaymentsComponent } from './Components/ServiceTitan/batching-payments/batching-payments.component';
import { ClearDateComponent } from './Components/Scheduler/clear-date/clear-date.component';
import { NewUserComponent } from './Components/Settings/new-user/new-user.component';
import { EditUserComponent } from './Components/Settings/edit-user/edit-user.component';
import { SubdivisionSetupComponent } from './Components/Settings/subdivision-setup/subdivision-setup.component';
import { ChangeOrderComponent } from './Components/BuilderAssociates/change-order/change-order.component';
import { ChangeOrderApprovalComponent } from './Components/AreaManagers/ChangeOrderApproval/ChangeOrderApproval.component';
import { PartsViewComponent } from './Components/Quoting/PartsViewComponent/PartsViewComponent';
import { PreviewQuotePdfViewComponent } from './Components/Quoting/PreviewQuotePdfViewComponent/PreviewQuotePdfViewComponent';
const routes: Routes = [
  { path: '', component: HomePageComponent},
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: ['tech', 'super', 'superdfw', 'material', 'schedule', 'am', 'mat_mgt', 'res_mgt', 'acct', 'acct_mgt', 'mgt', 'admin', 'superadmin', 'dfw_sch' ]},
    children: [
      { path: 'upload/plans', component: UploadPlansComponent },
      { path: 'Settings', component: SettingsComponent },
      { path: 'QR/PartsPull', component: QrpartspullComponent },
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: ['all']},
    children: [
      { path: 'QR/CrewLaborDetail', component: QrcrewlabordetailComponent },
      { path: 'Inventory/QRScanner', component: QrscannerComponent },
      { path: 'User/Password/Reset', component: PasswordResetComponent }
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: ['mat_mgt', 'res_mgt', 'acct', 'acct_mgt', 'mgt', 'admin', 'superadmin', 'matDFW']},
    children: [
      { path: 'Inventory/UnshippedItems', component: UnshippedItemsComponent },
      // { path: 'Inventory/OrderCloseout', component: OrderCloseoutComponent }
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: ['superadmin']},
    children: [

    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: ['acct', 'acct_mgt', 'mgt', 'admin', 'superadmin', 'dfw_sch','service']},
    children: [
      { path: 'Accounting/Receipt/Invoice/Approval', component: ReceiptOfInvoiceApprovalComponent },
      { path: 'Accounting/Receipt/Invoice', component: RoiPendingComponent },
      { path: 'Accounting/Receipt/Invoice/:id', component: RoiApproveComponent },
      { path: 'Accounting/Receipt/Goods/Approval', component: ReceiptOfGoodsApprovalComponent }
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: ['res_mgt', 'acct', 'acct_mgt', 'mgt', 'dfw_sch', 'admin', 'superadmin']},
    children : [
      { path: 'Woms/Accounting/Queue', component: WomsAcctComponent },
      { path: 'Woms/Pricing/Queue', component: WomsPriceComponent },
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: ['material', 'mat_mgt', 'res_mgt', 'mgt', 'admin', 'superadmin', 'matDFW'  ]},
    children: [
      { path: 'Material/Receiving/Create', component: ReceivingCreateComponent },
      { path: 'Material/Receiving', component: ReceivingUpdateComponent },
      { path: 'MaterialRequisition', component: JobRequisitionComponent },
    ]
  },
  {
path: '',
runGuardsAndResolvers: 'always',
canActivate: [AuthGuard],
data: {role: ['serviceAppr', 'mat_mgt', 'mgt', 'admin', 'superadmin' ]},
children:[
  { path: 'Service/MaterialIssue', component: WarehouseTransferTruckComponent},
  { path: 'Service/MaterialOTC', component: PullTicketAdditionalPartsComponent},
  { path: 'Service/MaterialReturn', component: MaterialReturnComponent},
]
  },

{
  path: '',
  runGuardsAndResolvers: 'always',
  canActivate: [AuthGuard],
  data: {role: ['material', 'mat_mgt', 'mgt', 'admin', 'superadmin', 'service', 'serviceAppr']},
  children:[
    {path: 'Service/MaterialTransfer', component: PullTicketCreationComponent},
    {path: 'Service/MaterialHistory', component: PullTicketHistoryComponent},
    {path: 'Service/TruckManager', component: TruckManagerComponent},

  ]
},
{
  path: '',
  runGuardsAndResolvers: 'always',
  canActivate: [AuthGuard],
  data: { role: ['admin', 'superadmin', 'service'] },
  children: [
    {path: 'Service/Batching', component: BatchingPaymentsComponent },
  ]
},
{
  path: '',
  runGuardsAndResolvers: 'always',
  canActivate: [AuthGuard],
  data: { role: ['builder', 'res_mgt', 'mgt', 'admin', 'superadmin', 'dfw_sch', 'purchasing', 'service', 'schedule', 'warrantyCoord'] },
  children: [
    {path: 'Builder/House/Edit', component: HouseEditorComponent },
  ]
},
{
  path: '',
  runGuardsAndResolvers: 'always',
  canActivate: [AuthGuard],
  data: { role: ['builder', 'res_mgt', 'mgt', 'admin', 'superadmin', 'purchasing', 'am'] },
  children: [
    {path: 'Builder/ChangeOrder', component: ChangeOrderApprovalComponent},
    {path: 'Builder/ChangeOrder/Create', component: ChangeOrderComponent}
  ]
},
{
  path: '',
  runGuardsAndResolvers: 'always',
  canActivate: [AuthGuard],
  data: { role: ['builder', 'res_mgt', 'mgt', 'admin', 'superadmin', 'purchasing', 'am'] },
  children: [
    {path: 'Builder/ChangeOrder', component: ChangeOrderApprovalComponent},
    {path: 'Builder/ChangeOrder/Create', component: ChangeOrderComponent}
  ]
},
{
  path: '',
  runGuardsAndResolvers: 'always',
  canActivate: [AuthGuard],
  data: {role: ['material', 'mat_mgt', 'mgt', 'admin', 'superadmin', 'service', 'purchasing']},
  children:[
    {path: 'Service/MaterialScheduled', component: MaterialScheduledComponent},
  ]
},
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: ['material', 'mat_mgt', 'res_mgt', 'mgt', 'admin', 'superadmin', 'dfw_sch', 'matDFW']},
    children: [
      { path: 'WorkTicketEdit', component: EditWorkticketsComponent },
      // { path: 'WarehouseTransfer', component: WarehouseTransferComponent }
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: [ 'mat_mgt', 'admin', 'superadmin', 'matDFW']},
    children: [
      { path: 'WarehouseTransfer', component: WarehouseTransferHistoryComponent },
      { path: 'WarehouseTransfer/Create', component: WarehouseTransferComponent }

    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: ['res_mgt', 'mgt', 'superadmin', 'admin', 'super', 'superdfw', 'am', 'warrantyCoord']},
    children: [
      { path: 'scheduling', component: SchedulingComponent },
      { path: 'SuperSchedule', component: ScheduleTicketsComponent },

    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: ['res_mgt', 'mgt', 'superadmin', 'admin', 'am']},
    children: [
      { path: 'AMDashboard', component: AMDashboardComponent },
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: ['res_mgt', 'mgt', 'superadmin', 'admin', 'am', 'super', 'superdfw']},
    children: [
      { path: 'Woms/Management/Queue', component: WomsMgtComponent },
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: ['superadmin', 'mgt', 'admin', 'am', 'tech', 'mat_mgt', 'material', 'super', 'superdfw', 'techWar', 'matDFW']},
    children: [
      { path: 'techtodo', component: TechTodoComponent },
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { role: ['builder', 'res_mgt', 'mgt', 'admin', 'superadmin'] },
    children: [
      {path: 'Builder/Template/Edit', component: TemplateEditorComponent},
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { role: ['builder', 'res_mgt', 'mgt', 'admin', 'superadmin','schedule','warrantyCoord'] },
    children: [
      {path: 'Schedule/HouseCreate', component: AddressCreationComponent},
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { role: ['schedule', 'res_mgt', 'mgt', 'admin', 'superadmin', 'warrantyCoord'] },
    children: [
      {path: 'Schedule/Confirmation/Queue', component: ScheduleConfirmationQueueComponent },
      {path: 'Schedule/Customer/Assignment', component: AddressCustomerAssignmentComponent },
      {path: 'Schedule/Rework/Queue', component: ReworkQueueComponent },
      {path: 'Schedule/Assign/Schedule', component: AssignScheduleComponent },
      {path: 'Schedule/ClearDate', component: ClearDateComponent}
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { role: ['res_mgt', 'mgt', 'admin', 'superadmin', 'super', 'superdfw', 'am', 'acct', 'acct_mgt', 'dfw_sch'] },
    children: [
      // { path: 'supers/Workticket', component: qaWorkticketComponent },
      // { path: 'supers/Punch', component: vpoPunchComponent },
      { path: 'supers/super-reports', component: SuperReportsComponent },
      { path: 'supers/house360', component: House360Component },
      { path: 'supers/miscpay', component: SuperMiscpayComponent },
      { path: 'PhasePay', component: PhasePayComponent },
      // Removed with User Story 172
      // { path: 'PhasePay/History', component: PhasePayHistoryComponent }
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { role: ['admin', 'superadmin', 'super', 'superdfw', 'am', 'acct', 'acct_mgt'] },
    children: [
      { path: 'PhasePay', component: PhasePayComponent },
      { path: 'SubdivisionEdit', component: SubdivisionSetupComponent }
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {role: ['res_mgt', 'mgt', 'superadmin', 'admin', 'super', 'superdfw', 'am', 'warrantyCoord', 'tech','schedule', 'acct', 'builder','acct_mgt', 'dfw_sch', 'techWar']},
    children: [
      { path: 'supers/address-files', component: AddressFilesComponent },

    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { role: ['schedule', 'res_mgt', 'techWar', 'mgt', 'admin', 'superadmin', 'super', 'superdfw', 'am', 'acct', 'acct_mgt', 'dfw_sch', 'warrantyCoord'] },
    children: [
      { path: 'supers/Workticket', component: qaWorkticketComponent },
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { role: ['schedule', 'res_mgt', 'mgt', 'admin', 'superadmin', 'super', 'superdfw', 'am', 'warrantyCoord', 'service', 'acct', 'acct_mgt'] },
    children: [
      { path: 'supers/Punch', component: vpoPunchComponent }
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { role: ['superadmin']},
    children: [
      { path: 'Schedule/Confirmation', component: ScheduleConfirmationComponent},
      { path:'NewUser', component: NewUserComponent },
      { path: 'EditUser', component: EditUserComponent }
    ]
  },
  {
    path: '',
    children: [
      { path: 'Login/Link', component: LoginPasswordlessComponent }
    ]
  },
  {
    path: 'quoting',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { role: ['superadmin', 'admin', 'builder']},
    children: [
      { path: 'dashboard', component: QuoteDashboardComponentComponent }, // View all quotes and search for quotes
      { path: 'quotes', component: QuotesViewComponent }, // View all quotes and search for quotes
      { path: 'quote/:quoteGuid', component: QuoteViewComponent }, // Edit a quote and add parts/kits
      { path: 'kits', component: KitsViewComponent }, // View all kits and search for kits
      { path: 'kit/:kidGuid', component: KitViewComponent }, // Edit a kit and add parts
      // Questions temporarily disabled for now -> Can't fix in time for V1 rollout. Will be fixed after rollout of V1
      { path: 'questions', component: QuoteQuestionsEditorViewComponent }, // View and edit questions when you are creating a quote
      { path: 'parts', component: PartsViewComponent }, // View and edit parts in kits, but not quotes unless it is a new part not already in the quote
      { path: 'preview-quote-pdf/:quoteGuid', component: PreviewQuotePdfViewComponent } // View the PDF of the quote
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
