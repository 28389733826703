import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef, OnDestroy, ComponentRef, AfterViewInit } from '@angular/core';
import { SettingsDirective } from '../settings.directive';
import { AuthService } from 'src/app/_services/auth/auth.service';

import { PunchAssignableTechsComponent } from '../punch-assignable-techs/punch-assignable-techs.component';
import { SubdivisionSetupComponent } from '../subdivision-setup/subdivision-setup.component';
import { PlanSetupComponent } from '../plan-setup/plan-setup.component';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, AfterViewInit {
  @ViewChild(SettingsDirective, { static: true }) appSettings: SettingsDirective;
  homePageSelected = true;
  settingsPageList: {
    name: string,
    component: any,
    selected: boolean
  }[] = [
    {
      name: 'PunchAssignableTechs',
      component: PunchAssignableTechsComponent,
      selected: false
    },
    {
      name: 'SubdivisionSetup',
      component: SubdivisionSetupComponent,
      selected: false
    },
    {
      name: 'PlanSetup',
      component: PlanSetupComponent,
      selected: false
    }
  ];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  navShow(group: string) {
    let allowedRoles: any = [];
    let unallowedRoles: any = [];
    switch (group) {
      case 'superadmin':
        allowedRoles = ['superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'admin':
        allowedRoles = ['admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'mgt':
        allowedRoles = ['mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'mat_mgt':
        allowedRoles = ['mat_mgt', 'res_mgt', 'mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'acct_mgt' :
        allowedRoles = ['acct_mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'material':
        allowedRoles = ['builder', 'material', 'mat_mgt', 'res_mgt', 'mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'accounting' :
        allowedRoles = ['acct', 'acct_mgt', 'mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'am':
        allowedRoles = ['am', 'res_mgt', 'mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'super':
        allowedRoles = ['super', 'am', 'res_mgt', 'mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'tech':
        allowedRoles = ['tech', 'am', 'mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'schedule':
        allowedRoles = ['schedule', 'res_mgt', 'mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'builder':
        allowedRoles = ['builder', 'res_mgt', 'mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'external':
        allowedRoles = ['ExternalBuilder', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'internal':
        unallowedRoles = ['ExternalBuilder'];
        return !unallowedRoles.includes(this.authService.decodedToken?.role);

      default:
        return false;
    }
  }

  activePage(name: string) {
    const index = this.settingsPageList.findIndex(x => x.name === name);
    return this.settingsPageList[index].selected;
  }

  settingPageSelected(name: string) {
    if (name === '') {
      this.settingsPageList.forEach(page => {
        page.selected = false;
      });
      this.homePageSelected = true;
      const viewContainerRef = this.appSettings.viewContainerRef;
      viewContainerRef.clear();
    }
    const index = this.settingsPageList.findIndex(x => x.name === name);
    if (index >= 0 && !this.settingsPageList[index].selected) {
      this.settingsPageList.forEach(page => {
        page.selected = false;
      });
      this.settingsPageList[index].selected = true;
      this.loadSettingsPage(this.settingsPageList[index]);
    }
  }

  loadSettingsPage(
    selectedComponent: {
      name: string,
      component: any,
      selected: boolean
    }
  ) {
    this.homePageSelected = false;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(selectedComponent.component);

    const viewContainerRef = this.appSettings.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
  }

}
