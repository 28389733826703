import {Component, HostListener, OnInit, TemplateRef} from '@angular/core';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../_services/auth/auth.service';
import {AlertifyService} from '../../../_services/alertify/alertify.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {concat} from 'rxjs';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { WasabiApiService } from '../../../_services/wasabiApi/wasabiApi.service';

@Component({
  selector: 'app-qrcrewlabordetail',
  templateUrl: './qrcrewlabordetail.component.html',
  styleUrls: ['./qrcrewlabordetail.component.css']
})
export class QrcrewlabordetailComponent implements OnInit {

  workticket: string;
  isSubmitting: boolean;
  urlParams: URLSearchParams;
  noTicket: boolean;
  isloading: boolean;
  isLoading: boolean;
  soNum: string;
  wtNum: string;
  fullticket: string;
  address: string;
  status: string;
  images: string[];
  loadingStatus: string;
  MESSAGES = {
    INVALID_WORKTICKET: 'Invalid Work Ticket Number'
  };

  // IMAGES
  fileLoading: boolean;
  fileArray = [];
  fileList = [];
  imageAdded: boolean;
  fileAddress: string;
  readyForQA: boolean;
  rocksPresent: boolean;

  modalTemplate: BsModalRef;
  importTemplateInfo = {
    templateName: '',
    template: '',
    step: ''
  };

  shipAll: boolean;
  itemList: {
    name: string,
    // lineKey: string,
    newQtyOrderedTotal: number,
    newQtyShippedTotal: number,
    tickets: any
  }[] = [];

  shipTotals = [];

  itemCollection = [];

  punModel: any = [];
  wtModel: any = [];
  laborModel: any = [];
  wtLoaded: boolean;
  qaModel: any = [];
  qaAddModel: any = [];
  qaItemList = [];
  cleanPhaseName: string;
  mobile: boolean;
  fileNames: Array<string> = [];
  imgAddress: string;
  imagesForViewing: Array<string> = [];
  displayScoutImages: boolean;

  completeall: boolean;

  // fileLoading: boolean;
  // fileArray = [];
  // fileList = [];
  // imageAdded: boolean;
  // chippingImage: boolean;
  // fileAddress: string;
  // wasabiApi
  imageArr: Array<any> = [];
  imageUploadComplete: boolean;
  isImageLoading: boolean;
  WTfull: string;
  batchNeeded: boolean;
  loginLoading: boolean;
  engSpan: boolean;
  showOtherTickets = false;
  showOpenTickets = false;
  showPhonePay = false;
  showPhoneOpen = false;

  payTotal: number;
  openTotal: number;
  apvTotal: number;
  weekTotal: number;
  openWTotal: number;
  apvWTotal: number;
  laborItems: any;
  OpenItems: any;
  openItemsLength: number;

  RocksAmount = 0;
  RocksImageSubmitted: boolean;
  RocksImageModal: boolean;

  payWeekStart: Date;
  payWeekEnd: Date;

  constructor(
    private router: Router,
    private authService: AuthService,
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private wasabiApi: WasabiApiService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event){
    // 420
    if (window.screen.width <= 767){
      this.mobile = true;
    }else {
      this.mobile = false;
    }
  }

  ngOnInit() {
    this.openItemsLength = 0;
    this.engSpan = true;
    this.completeall = false;
    this.readyForQA = true;
    this.rocksPresent = false;
    this.RocksImageModal = false;
    this.RocksImageSubmitted = false;
    if (localStorage.getItem('token') === null){
      const model = {
        username: 'gguest',
        password: 'GPCrew2021'
      };
      this.loginLoading = true;
      this.authService.login(model).subscribe(next => {
        // this.alertify.success('Logged in successfully');
      }, error => {
        console.log(error);
        this.loginLoading = false;
        this.alertify.error('Log in Error');
      }, () => {
        this.loginLoading = false;
        this.noTicket = true;
        this.shipAll = true;
        this.itemList = [];
        this.batchNeeded = false;

        // console.log('Screen width', window.screen.width);
        if (window.screen.width <= 767){
          this.mobile = true;
        }else {
          this.mobile = false;
        }


        this.urlParams = new URLSearchParams(window.location.search);
        if (this.urlParams.has('workticket')) {
          this.workticket = this.urlParams.get('workticket');
          // console.log(this.workticket);
          this.pullTicketInfo();
          this.pullMaterial();
            // this.shipAllItems();
            // this.noTicket = false;
            // this.loadData();
          this.loadQATicket();
            // this.router.navigate(['/']);
        }
      });
    } else {
      this.noTicket = true;
      this.shipAll = true;
      this.itemList = [];
      this.batchNeeded = false;

      // console.log('Screen width', window.screen.width);
      if (window.screen.width <= 767){
        this.mobile = true;
      }else {
        this.mobile = false;
      }

      this.urlParams = new URLSearchParams(window.location.search);
      if (this.urlParams.has('workticket')) {
        this.workticket = this.urlParams.get('workticket');
        // console.log(this.workticket);
        this.pullTicketInfo();
        this.pullMaterial();
        // this.shipAllItems();
        // this.noTicket = false;
        // this.loadData();
        this.loadQATicket();
      }
    }
  }

  gotToWT(WTobj: any){
    // console.log(WTobj.salesOrderNo + WTobj.lItems[0].wtNumber);
    this.router.navigate(['/QR/CrewLaborDetail'], { queryParams: {workticket : WTobj.salesOrderNo + WTobj.lItems[0].wtNumber}});
    // this.urlParams.set('workticket', WTobj.salesOrderNo + WTobj.lItems[0].wtNumber);
    this.workticket = WTobj.salesOrderNo + WTobj.lItems[0].wtNumber;
    this.engSpan = true;
    this.completeall = false;
    this.readyForQA = true;

    this.noTicket = true;
    this.shipAll = true;
    this.itemList = [];
    this.batchNeeded = false;

    // console.log('Screen width', window.screen.width);
    if (window.screen.width <= 767){
      this.mobile = true;
    }else {
      this.mobile = false;
    }

    // console.log(this.workticket);
    try {
      this.pullTicketInfo();
      this.pullMaterial();
      // this.shipAllItems();
      // this.noTicket = false;
      // this.loadData();
      this.loadQATicket();
      // this.router.navigate(['/']);
    } finally {
      this.loadImagesForViewing();
    }
    window.scroll(0, 0);
  }

  pullPay(parentObj: any){
    this.isLoading = true;
    this.weekTotal = 0;
    this.openWTotal = 0;
    this.apvWTotal = 0;
    this.laborItems = [];
    const salesOrderNumber = this.workticket.substring(0, 7);
    const wtNumber = this.workticket.substring(7);
    // @ts-ignore
    const qaSalesOrder = this.sageApi.pullReport('QRCode/Pay?tech=' + parentObj.techCode + '&schDate=' + parentObj.scheduleStringDate)
      .subscribe((op: Array<any>) => {
        // console.log('pull', op);
        op.forEach( labor => {
          let opentot = 0;
          let apvtot = 0;
          let tot = 0;
          const obj = {
            salesOrderNo: labor.SalesOrderNo,
            wtNumber: labor.WTNumber,
            wtStep: labor.WTStep,
            address: labor.ShipToName,
            status: labor.StatusCode,
            itemCode: labor.ItemCode,
            qtyOrd: labor.QuantityOrdered,
            qtyShp: labor.QuantityShipped,
            UnitCost: labor.UnitCost,
            TotalCost: labor.TotalPrice,
            parentLineCode: labor.HdrParentItemCode,
            techCode: labor.UDF_TECHNICIAN_CODE,
            approvedtechCode: labor.ApprovedTech,
            payDate:  labor.UDF_PAY_DATE || '1753-01-01',
            approvedDate:  labor.ApprovedDate || '1753-01-01',
            scheduleDate:  labor.UDF_DATE_SCHEDULED || '1753-01-01',
            superI: labor.UDF_LEADMAN === null ? '' : labor.UDF_LEADMAN,
            superEmail: labor.email === null ? '' : labor.email,
            superPhone: labor.PhoneNumber === null ? '' : labor.PhoneNumber,
          };
          const pdate = new Date(obj.payDate);
          const sdate = new Date(obj.scheduleDate);
          if (obj.approvedtechCode === '' || obj.approvedDate === '1753-01-01' || obj.approvedDate === '' || obj.approvedDate === null
            || obj.approvedDate === undefined){
            opentot = obj.TotalCost;
            this.openWTotal  += obj.TotalCost;
          }else {
            apvtot = obj.TotalCost;
            this.apvWTotal  += obj.TotalCost;
          }
          tot = obj.TotalCost;
          this.weekTotal += obj.TotalCost;

          if (!(obj.salesOrderNo === parentObj.salesOrderNo && obj.wtNumber === parentObj.wtNumber)){
            if (this.laborItems.length <= 0){
              this.laborItems.push({
                salesOrderNo: obj.salesOrderNo,
                lItems: [obj],
                openT: opentot,
                apvT: apvtot,
                tPay: tot
              });
            } else{
              const findSO = this.laborItems.findIndex(item => item.salesOrderNo === obj.salesOrderNo);
              if (findSO === -1){
                this.laborItems.push({
                  salesOrderNo: obj.salesOrderNo,
                  lItems: [obj],
                  openT: opentot,
                  apvT: apvtot,
                  tPay: tot
                });
              }else{
                this.laborItems[findSO].lItems.push(obj);
                this.laborItems[findSO].openT += opentot;
                this.laborItems[findSO].apvT += apvtot;
                this.laborItems[findSO].tPay += tot;
              }
            }
          }

          if (obj.superPhone !== '' && obj.superPhone !== null){
            this.showPhonePay = true;
          }

          // this.laborItems.push(obj);
          // console.log(obj.techCode, parentObj.techCode);
          // if (obj.salesOrderNo === parentObj.salesOrderNo){

          // }
        });
    }, err => {
      console.log(err);
      this.isLoading = false;
    }, () => {
        console.log('LaborItems', this.laborItems);
        this.isLoading = false;
      });
  }

  pullOpen(parentObj: any){
    this.isLoading = true;
    this.OpenItems = [];
    let wtStatus = '';
    const salesOrderNumber = this.workticket.substring(0, 7);
    const wtNumber = this.workticket.substring(7);
    console.log('TECH', parentObj.techCode);
    // @ts-ignore
    const qaSalesOrder = this.sageApi.pullReport('QRCode/OpenWT?tech=' + parentObj.techCode)
      .subscribe((op: Array<any>) => {
        // console.log('pull', op);
        op.forEach( labor => {
          let opentot = 0;
          let apvtot = 0;
          let tot = 0;
          const obj = {
            salesOrderNo: labor.SalesOrderNo,
            wtNumber: labor.WTNumber,
            wtStep: labor.WTStep,
            address: labor.ShipToName,
            status: labor.StatusCode,
            itemCode: labor.ItemCode,
            qtyOrd: labor.QuantityOrdered,
            qtyShp: labor.QuantityShipped,
            UnitCost: labor.UnitCost,
            TotalCost: labor.TotalPrice,
            parentLineCode: labor.HdrParentItemCode,
            techCode: labor.UDF_TECHNICIAN_CODE,
            approvedtechCode: labor.ApprovedTech,
            payDate:  labor.UDF_PAY_DATE || '1753-01-01',
            approvedDate:  labor.ApprovedDate || '1753-01-01',
            scheduleDate:  labor.UDF_DATE_SCHEDULED || '1753-01-01',
            superI: labor.UDF_LEADMAN === null ? '' : labor.UDF_LEADMAN,
            superEmail: labor.email === null ? '' : labor.email,
            superPhone: labor.PhoneNumber === null ? '' : labor.PhoneNumber,
          };
          const pdate = new Date(obj.payDate);
          const sdate = new Date(obj.scheduleDate);
          if (obj.wtStep === '000'){
            wtStatus = obj.status;
          }
          if (obj.wtStep === '020'){
            if (obj.approvedtechCode === '' || obj.approvedDate === '1753-01-01' || obj.approvedDate === '' || obj.approvedDate === null
              || obj.approvedDate === undefined){
              opentot = obj.TotalCost;
            }else {
              apvtot = obj.TotalCost;
            }
            tot = obj.TotalCost;
          }

          if (!(obj.salesOrderNo === parentObj.salesOrderNo && obj.wtNumber === parentObj.wtNumber)){
            if (this.OpenItems.length <= 0){
              this.OpenItems.push({
                salesOrderNo: obj.salesOrderNo,
                lItems: [obj],
                status: obj.status,
                openT: opentot,
                apvT: apvtot,
                tPay: tot
              });
            } else{
              const findSO = this.OpenItems.findIndex(item => item.salesOrderNo === obj.salesOrderNo);
              if (findSO === -1){
                this.OpenItems.push({
                  salesOrderNo: obj.salesOrderNo,
                  lItems: [obj],
                  status: obj.status,
                  openT: opentot,
                  apvT: apvtot,
                  tPay: tot
                });
              }else{
                if (obj.wtStep === '000'){
                  this.OpenItems[findSO].status = obj.status;
                }
                else {
                  this.OpenItems[findSO].lItems.push(obj);
                  this.OpenItems[findSO].openT += opentot;
                  this.OpenItems[findSO].apvT += apvtot;
                  this.OpenItems[findSO].tPay += tot;
                }
              }
            }
          }

          if (obj.superPhone !== '' && obj.superPhone !== null){
            this.showPhoneOpen = true;
          }

          // this.laborItems.push(obj);
          // console.log(obj.techCode, parentObj.techCode);
          // if (obj.salesOrderNo === parentObj.salesOrderNo){

          // }
        });
      }, err => {
        console.log(err);
        this.isLoading = false;
      }, () => {
        console.log('OpenItems', this.OpenItems);
        this.openItemsLength = 0;
        this.OpenItems.forEach(item => {
          if (item.status === 'REC' || item.status === 'LW'){
            this.openItemsLength++;
          }
        });
        this.isLoading = false;
      });
  }

  loadQATicket() {
    // this.newComment = '';
    this.fileAddress = '';
    this.imageAdded = false;
    // this.chippingImage = false;
    this.isLoading = true;
    // this.imgLoaded = false;
    // this.loadedwtnumber = this.wtnumber;
    // if (!this.workticket || this.wtnumber.length < this.WTNUMBER_LENGTH) {
    //   this.loadingStatus = this.MESSAGES.INVALID_WORKTICKET;
    //   console.log(this.loadingStatus);
    //   this.isLoading = false;
    //   return;
    // }
    const salesOrderNumber = this.workticket.substring(0, 7);
    const wtNumber = this.workticket.substring(7);
    // console.log(wtNumber);
    // this.loadPhases(salesOrderNumber);
    this.wtLoaded = false;

    const qaSalesOrder = this.sageApi.pullReport('QRCode/LoadQA?salesOrderNo=' + salesOrderNumber + '&WTNum=' + wtNumber)
      .subscribe((qa: Array<any>) => {
        this.wtModel = [];
        this.laborModel = [];
        this.punModel = [];
        this.qaModel = [];
        this.qaAddModel = [];
        this.payTotal = 0;
        this.apvTotal = 0;
        this.openTotal = 0;
        let doOnce = true;
        if (Array.isArray(qa)) {
          qa.forEach(q => {
            const icSplit = q.ItemCDesc.split('/--/');
            const obj = {
              salesOrderNo: q.SalesOrderNo,
              wtNumber: q.WTNumber,
              wtStep: q.WTStep,
              address: q.ShipToName,
              subdivision: q.UDF_SUBDIVISION,
              city: q.UDF_CITY,
              builder: q.BillToName,
              status: q.StatusCode,
              dip: q.DIP,
              tech: q.techName,
              parentLineKey: q.JT158_WTParentLineKey,
              lineKey: q.LineKey,
              parentLineCode: q.HdrParentItemCode,
              trackingComment: q.UDF_TRACKING_COMMENT,
              scheduleDate:  new Date(q.UDF_DATE_SCHEDULED || '1753-01-01'),
              scheduleStringDate:  q.UDF_DATE_SCHEDULED || '1753-01-01',
              techCode: q.UDF_TECHNICIAN_CODE,
              approvedtechCode: q.ApprovedTech,
              promiseDate: q.PromiseDate,
              itemCode: q.ItemCode,
              itemCodeDesc: q.ItemCDesc,
              ICEnglish: icSplit[0],
              ICSpanish: icSplit.length < 2 ? icSplit[0] : icSplit[1],
              code: q.UDF_ASSIGNED_CODE,
              updated: 'false',
              notes: q.UDF_INSP_NOTES === '' ? 'Comment Here:' : q.UDF_INSP_NOTES,
              completePipes: q.UDF_SCOUT_LM_PIPES_OUT,
              partialPipes: q.UDF_SCOUT_LM_PIPES_SHADING,
              damagedPipes: q.UDF_SCOUT_LM_DMG_PIPES,
              pipesOutExtended: q.UDF_SCOUT_LM_PIPES_OUT_EXT,
              tubBox: q.UDF_SCOUT_LM_TUB_BOX,
              pictures: q.UDF_SCOUT_LM_PICTURES,
              materialOnScout: q.UDF_MATERIAL_ON_SCOUT,
              scoutComment: q.UDF_SCOUT_LM_DESCRIPTION,
              completePipesUpdated: false,
              partialPipesUpdated: false,
              damagedPipesUpdated: false,
              pipesOutExtendedUpdated: false,
              tubBoxUpdated: false,
              isComplete: q.UDF_INSP_COMPL,
              isCompleteNew: q.UDF_INSP_COMPL,
              expectedCompletionDate: q.UDF_QA_EXP_COMPL_DATE || '    ',
              location: q.UDF_LOCATION,
              waterMeterPresent: q.UDF_WATER_METER_PRESENT === 'Y',
              // BUTTON GROUPS
              groupName: 'group' + q.LineKey,
              YID: 'optY' + q.LineKey,
              NID: 'optN' + q.LineKey,
              NoneID: 'optNone' + q.LineKey,
              superI: q.UDF_LEADMAN === null ? '' : q.UDF_LEADMAN,
              superEmail: q.email === null ? '' : q.email,
              superPhone: q.PhoneNumber === null ? '' : q.PhoneNumber,
              qtyOrd: q.QuantityOrdered,
              qtyShp: q.QuantityShipped,
              UnitCost: q.UnitCost,
              TotalCost: q.TotalPrice,
              payDate:  q.UDF_PAY_DATE || '1753-01-01',
              approvedDate:  q.ApprovedDate || '1753-01-01',
              billToInformation: q.BillToInformation
            };
            if (obj.itemCode.substring(0, 3) === '/QA') {
              this.qaModel.push(obj);
            }
            if (obj.itemCode === '/PUNCH') {
              this.punModel.push({ desc: obj.itemCodeDesc });
            }
            if (obj.wtStep === '020')
            {
              this.laborModel.push(obj);
              const pdate = new Date(obj.approvedDate);
              const sdate = new Date(obj.scheduleStringDate);
              if (obj.approvedtechCode === '' || obj.approvedDate === '1753-01-01' || obj.approvedDate === '' || obj.approvedDate === null
                || obj.approvedDate === undefined){
                this.openTotal += obj.TotalCost;
              }else {
                this.apvTotal += obj.TotalCost;
              }
              this.payTotal += obj.TotalCost;
            }
            else {
              this.wtModel.push(obj);
            }
            this.showPhonePay = obj.superPhone !== '' && obj.superPhone !== null;
            if (doOnce){
              this.pullPay(obj);
              this.pullOpen(obj);
              doOnce = false;
            }
          });
        }

      }, err => {
        console.log(err);
        this.isLoading = false;
      }, () => {
        // console.log(this.wtModel);
        if (!(Array.isArray(this.wtModel) && this.wtModel.length)) {
          console.log('BAD TICKET');
          //  alertrify message for bad phase

          this.wtLoaded = false;
        } else {
          this.loadQAChecklist(this.wtModel[0].parentLineCode, this.wtModel[0].city, this.wtModel[0].builder, this.wtModel[0].subdivision, this.wtModel[0].billToInformation);
          this.wtLoaded = true;
          const salesOrderNum = this.workticket.slice(0, 7);
          this.cleanPhaseName = this.wtModel[0].parentLineCode.substring(1);
          console.log('wtModel');
          console.log(this.wtModel);
          console.log('qaModal');
          console.log(this.qaModel);
          console.log('laborModal');
          console.log(this.laborModel);
          this.isLoading = false;
          this.readyForQA = this.wtModel[0].status.toLocaleLowerCase() === 'rec' || this.wtModel[0].status.toLocaleLowerCase() === 'lw';
          this.WTfull = this.wtModel[0].salesOrderNo + '-' + this.wtModel[0].wtNumber + '-000';
          this.imgAddress = this.wtModel[0].address;

          this.payWeekStart = this.wtModel[0].scheduleDate;
          while (this.payWeekStart.getDay() !== 5){
            // console.log('tick');
            const loop = this.payWeekStart.setDate(this.payWeekStart.getDate() - 1);
            this.payWeekStart = new Date(loop);
          }
          this.payWeekEnd = this.wtModel[0].scheduleDate;
          while (this.payWeekEnd.getDay() !== 4){
            // console.log('tock');
            const loop = this.payWeekEnd.setDate(this.payWeekEnd.getDate() + 1);
            this.payWeekEnd = new Date(loop);
          }
          console.log('ticket Date:', this.wtModel[0].scheduleDate);
          console.log('pay week start:', this.payWeekStart);
          console.log('pay week end:', this.payWeekEnd);
          // this.pullPay();
        }
      });
  }

  completeAllQA(){
    this.completeall = false;
    this.qaModel.forEach(qa => {
      qa.isCompleteNew = 'Y';
    });
    this.completeall = true;
}

  // loadPhases(salesOrderNo: string) {
  //   this.sageApi.pullReport('QAWorkticket?salesOrderNo=' + salesOrderNo).subscribe((rows: Array<any>) => {
  //     if (Array.isArray(rows)) {
  //       rows.forEach(row => {
  //         const phase = {
  //           address: row.ShipToName,
  //           salesOrderNo: row.SalesOrderNo,
  //           WTNum: row.WTNumber,
  //           WTParentPhase: row.HdrParentItemCode,
  //           phaseName: '(' + row.WTNumber + ') ' + (row.HdrParentItemCode !== undefined ? row.HdrParentItemCode.substring(1) : 'undefined'),
  //           selected: false
  //         };
  //         if (!this.phases.some(e => (e.salesOrderNo === phase.salesOrderNo && e.WTParentPhase === phase.WTParentPhase && e.WTNum === phase.WTNum))
  //           && this.phaseFilter.includes(phase.WTParentPhase)) {
  //           this.phases.push(phase);
  //         }
  //       });
  //     }
  //   }, err => {
  //     this.alertify.error(err.message);
  //     console.log(err);
  //   }, () => {
  //     // console.log(this.phases);
  //   });
  // }

  loadQAChecklist(parentItemCode: string, cityName: string, builderName: string, subdivision:string, billToInformation:string) {
    this.qaItemList = [];
    this.sageApi.pullReport('QAWorkticket/QAChecklist').subscribe((rows: Array<any>) => {
      if (Array.isArray(rows)) {
        console.log('rows', rows);
        rows.forEach(row => {
          var subName = row.Subdivision;
          if(subName != undefined ){
            subName = subName.trim();
            subName = subName.toLocaleUpperCase();
          }
          const item = {
            itemKey: row.ItemKey,
            inspectionItem: row.InspectionItem,
            cityName: row.CityName,
            phaseName: row.PhaseName,
            builderName: row.BuilderName.toLocaleUpperCase(),
            subdivision: row.Subdivision === null ? 'ALL' : row.Subdivision.trim().toLocaleUpperCase(),
            phaseNameClean: (row.PhaseName !== undefined ? row.PhaseName.substring(1) : 'undefined'),
            selected: false
          };
          // tslint:disable-next-line:max-line-length
          if ((parentItemCode === item.phaseName ||  item.phaseName === 'ALL')
            && (item.cityName === cityName || item.cityName === 'ALL')
            && (item.builderName === billToInformation.toLocaleUpperCase() || item.builderName === 'ALL' ||  item.builderName === null)
            && (item.subdivision == 'ALL' || item.subdivision == subdivision)
          ) {
                this.qaItemList.push(item);
          }
        });
      }
    }, err => {
      this.alertify.error(err.message);
      console.log(err);
    }, () => {
      console.log('QA ITEM LIST', this.qaItemList);
      this.addMissingChecks();
    });
  }

  loadImagesForViewing() {
    console.log('this.imgAddress', this.imgAddress);
    this.onGetTicketImages(this.imgAddress).then(() => {
      this.imagesForViewing = this.fileNames;
      this.fileNames = [];
    });
    // this.imagesForViewing = this.sageApi.imageUrlListReports;
    // this.imagesForViewing = this.wasabiApi.getImages(this.imgAddress);
    this.displayScoutImages = true;
  }


  onFileLoadCompleteW(address: string) {

    this.wasabiApi.uploadImage(address, this.imageArr).subscribe((res) => {
      console.log(res);
      this.imageUploadComplete = true;
    }, err => {
      console.log(err);
      this.fileNames = [];
      this.isImageLoading = false;
    }, () => {
      console.log('upload image complete');
      this.fileNames = [];
      this.isImageLoading = false;
      this.imageAdded = true;
    });
  }

  loadImagesS3() {
    // this.imgAddress = '1234 TEST HOUSE';
    this.isLoading = true;
    const folder = this.imgAddress + '/scout';
    // const images = this.sageApi._getImages(this.imgAddress + '/scout').subscribe((img) => {
    try{
      const images = this.wasabiApi.getImages(this.imgAddress + '/scout').then((img) => {
        console.log('heres the img ' + img);
      }, () => {
        console.log('we got the images');
        // console.log(this.sageApi.imageUrlListReports.length);
        // hack to get the img list to load before the post to sage
        setTimeout(() => {
        }, 1500);
      });
    } finally {
      if (this.RocksImageModal){
        this.RocksImageSubmitted = true;
        // this.submitQAItems('ROCK');
        this.closeModal();
      }
    }

    this.alertify.success('image Downloaded');
    this.isLoading = false;
    // this.imgLoaded = true;
  }


  onFileChangedW(event) {
    this.imageUploadComplete = false;
    this.isImageLoading = true;
    this.imageArr = [];
    this.fileNames = [];

    const files = event.target.files;
    console.log(files);
    for (const index in files) {
      if (files[index] instanceof File) {
        this.fileNames.push(files[index].name);

        const obj = {
          name: this.WTfull+files[index].name,
          data: files[index],
        };
        this.imageArr.push(obj);
      }
    }

    if(files.length > 0){
      this.onFileLoadCompleteW(this.imgAddress);
    }
    this.imageAdded = true;
  }

  async onGetTicketImages(address: string) {
    const response = await this.wasabiApi.getImages(address);
    try {
      this.fileNames = response;
    } catch (error) {
      this.alertify.error('There was an error loading the images for this house - please try again');
      console.warn(`Error loading images: ${error}`);
    }
  }

  addMissingChecks() {
    if (Array.isArray(this.qaItemList)) {
      this.qaItemList.forEach(qa => {
        // console.log(!this.qaModel.some(e => e.code === qa.itemKey));
        if (!this.qaModel.some(e => (e.code === qa.itemKey || (e.itemCode.length > 3 ? e.itemCode.substring(3) : '') === qa.itemKey))) {
          const icSplit = qa.inspectionItem.split('/--/');
          const obj = {
            salesOrderNo: this.wtModel[0].salesOrderNo,
            wtNumber: this.wtModel[0].wtNumber,
            wtStep: this.wtModel[0].wtStep,
            address: this.wtModel[0].address,
            subdivision: this.wtModel[0].subdivision,
            city: this.wtModel[0].city,
            builder: this.wtModel[0].builder,
            status: this.wtModel[0].status,
            dip: this.wtModel[0].dip,
            tech: this.wtModel[0].tech,
            parentLineKey: this.wtModel[0].parentLineKey,
            lineKey: 'none',
            parentLineCode: this.wtModel[0].parentLineCode,
            trackingComment: this.wtModel[0].trackingComment,
            scheduleDate:  this.wtModel[0].scheduleDate,
            promiseDate: this.wtModel[0].promiseDate,
            itemCode: '/QA' + qa.itemKey,
            itemCodeDesc:  qa.inspectionItem,
            ICEnglish: icSplit[0],
            ICSpanish: icSplit.length < 2 ? icSplit[0] : icSplit[1],
            code:  qa.itemKey,
            updated: 'false',
            notes: 'Comment here:',
            completePipes: this.wtModel[0].completePipes,
            partialPipes: this.wtModel[0].partialPipes,
            damagedPipes: this.wtModel[0].damagedPipes,
            pipesOutExtended: this.wtModel[0].pipesOutExtended,
            tubBox: this.wtModel[0].tubBox,
            pictures: this.wtModel[0].pictures,
            materialOnScout: this.wtModel[0].materialOnScout,
            scoutComment: this.wtModel[0].scoutComment,
            completePipesUpdated: false,
            partialPipesUpdated: false,
            damagedPipesUpdated: false,
            pipesOutExtendedUpdated: false,
            tubBoxUpdated: false,
            isComplete: 'N',
            isCompleteNew: 'N',
            location: this.wtModel[0].location,
            expectedCompletionDate: this.wtModel[0].expectedCompletionDate !== null ? this.wtModel[0].expectedCompletionDate : '17530101',
            // BUTTON GROUPS
            groupName: 'group' + qa.itemKey,
            YID: 'optY' + qa.itemKey,
            NID: 'optN' + qa.itemKey,
            NoneID: 'optNone' + qa.itemKey,
            superI: this.wtModel[0].superI,
            superEmail: this.wtModel[0].superEmail,
            PhoneNumber: this.wtModel[0].PhoneNumber
          };
          this.wtModel.push(obj);
          this.qaModel.push(obj);
        }
      });
      console.log('wtModel', this.wtModel);
      console.log('qaModel', this.qaModel);
    }
  }

  checkStat(isComplete: string) {
    // if (){
    //
    // }
    // console.log(isComplete);
  }

  // loadData() {
  //   this.isLoading = true;
  //   // this.imgLoaded = false;
  //   // this.loadedwtnumber = this.wtnumber;
  //   // if (!this.wtnumber || this.wtnumber.length < this.WTNUMBER_LENGTH) {
  //   //   this.loadingStatus = this.MESSAGES.INVALID_WORKTICKET;
  //   //   this.isLoading = false;
  //   //   return;
  //   // }
  //   const salesOrderNumber = this.workticket.split('-')[0];
  //   const wtNumber = this.workticket.split('-')[1];
  //
  //   // this.wtLoaded = false;
  //
  //   const qaSalesOrder = this.sageApi.pullReport('QA/' + salesOrderNumber + wtNumber + '000')
  //     .subscribe((qa: Array<any>) => {
  //       this.wtModel = [];
  //       this.punModel = [];
  //       this.qaModel = [];
  //       if (Array.isArray(qa)) {
  //         qa.forEach(q => {
  //           const obj = {
  //             soNum: q.SalesOrderNo,
  //             soWT: wtNumber,
  //             address: q.ShipToName,
  //             builder: q.BillToName,
  //             scheduleDate: ((q.UDF_DATE_SCHEDULED || '1753-01-01').slice(0, 10) === '1753-01-01')
  //               ? q.PromiseDate.slice(0, 10) : (q.UDF_DATE_SCHEDULED || '1753-01-01').slice(0, 10),
  //             trackingComment: q.UDF_TRACKING_COMMENT,
  //             newTrackingComment: '',
  //             combinedComment: '',
  //             parentItemCode: q.HdrParentItemCode,
  //             itemCode: q.ItemCode,
  //             subdivision: q.UDF_SUBDIVISION,
  //             dip: q.DIP,
  //             techCode: q.techName,
  //             statusCode: q.DIP < 0 ?
  //               q.HdrParentItemCode === 'ZPUNCH' || q.HdrParentItemCode === 'ZPUNCHB' || q.HdrParentItemCode === 'ZPUNCHT' ?
  //                 'Future Punch' : q.StatusCode
  //               : q.StatusCode === 'QA Complete' ? 'Builder Inspection' : q.StatusCode,
  //             newStatus: '',
  //             itemCodeDesc: q.ItemCodeDesc,
  //             parentLineKey: q.ParentLineKey,
  //             lineKey: q.LineKey,
  //             notes: q.UDF_INSP_NOTES === '' ? 'Comment Here:' : q.UDF_INSP_NOTES,
  //             code: q.QACode,
  //             location: q.UDF_LOCATION,
  //             updated: 'false',
  //             isComplete: q.UDF_INSP_COMPL,
  //             expectedCompletionDate: q.UDF_QA_EXP_COMPL_DATE || '    ',
  //             readyStatus: '',
  //             subvendor: q.UDF_SUB_VENDOR,
  //             completePipes: q.UDF_SCOUT_LM_PIPES_OUT,
  //             partialPipes: q.UDF_SCOUT_LM_PIPES_SHADING,
  //             damagedPipes: q.UDF_SCOUT_LM_DMG_PIPES,
  //             pipesOutExtended: q.UDF_SCOUT_LM_PIPES_OUT_EXT,
  //             tubBox: q.UDF_SCOUT_LM_TUB_BOX,
  //             pictures: q.UDF_SCOUT_LM_PICTURES,
  //             materialOnScout: q.UDF_MATERIAL_ON_SCOUT,
  //             scoutComment: q.UDF_SCOUT_LM_DESCRIPTION,
  //             completePipesUpdated: false,
  //             partialPipesUpdated: false,
  //             damagedPipesUpdated: false,
  //             pipesOutExtendedUpdated: false,
  //             tubBoxUpdated: false,
  //             UDF_TECHNICIAN_CODE: q.UDF_TECHNICIAN_CODE,
  //             repTech: q.REPTech || '',
  //             waterMeterPresent: q.UDF_WATER_METER_PRESENT === 'Y',
  //             techPhoneNumber : q.TechPhoneNumber || ''
  //           };
  //           if (obj.itemCode === '/QA') {
  //             this.qaModel.push(obj);
  //           }
  //           if (obj.itemCode === '/PUNCH') {
  //             this.punModel.push(obj);
  //           }
  //           this.wtModel.push(obj);
  //         });
  //       }
  //     }, err => {
  //       console.log(err);
  //       this.isLoading = false;
  //     }, () => {
  //       console.log(this.wtModel);
  //       console.log(this.punModel);
  //       console.log(this.qaModel);
  //       this.wtLoaded = true;
  //       const salesOrderNum = this.workticket.slice(0, 7);
  //       // this.loadHouseLocations(salesOrderNum);
  //       this.isLoading = false;
  //     });
  // }

  onMarkLineComplete(event: any, index: any) {
    console.log(`EVENT HERE ${event.target.checked}`);
    console.log(`STEP HERE ${index}`);
    if (event.target.checked === true) {
      this.qaModel[index].isComplete = 'Y';
      this.qaModel[index].updated = 'true';
    } else if (!event.target.checked) {
      this.qaModel[index].isComplete = 'N';
    }
  }

  pullTicketInfo(){
    // this.newComment = '';
    // this.fileAddress = '';
    this.imageAdded = false;
    // this.chippingImage = false;
    this.isLoading = true;
    // this.imgLoaded = false;
    // this.loadedwtnumber = this.wtnumber;
    if (!this.workticket || this.workticket.length < 10) {
      this.loadingStatus = this.MESSAGES.INVALID_WORKTICKET;
      // console.log(this.loadingStatus);
      this.isLoading = false;
      this.noTicket = true;
      return;
    }
    const salesOrderNumber = this.workticket.substring(0, 7);
    const wtNumber = this.workticket.substring(7);
    // console.log(salesOrderNumber + '-' + wtNumber);
    const loadingWT = this.sageApi.pullReport('QRCode/Parts?salesOrderNo=' + salesOrderNumber + '&WTNum=' + wtNumber)
      .subscribe((tickets: Array<any>) => {
        // console.log(tickets);
        if (Array.isArray(tickets)){
          tickets.forEach((ticket: any) => {
            this.soNum = ticket.SalesOrderNo;
            this.wtNum = ticket.WTNumber;
            this.address = ticket.Address;
            this.status = ticket.StatusCode;
          });
        }
      }, err => {
        console.log(err);
        this.isLoading = false;
      }, () => {
        this.noTicket = false;
        this.isLoading = false;
        // this.pullMaterial();
        // this.urlParams.set('workticket', this.workticket);
        // history.pushState(null, null, 'QR/PartsPull?' + this.urlParams.toString());
        // console.log(this.urlParams);
      });
  }

  changeToQAN(){
    if (!this.readyForQA){
      return;
    }
    this.isLoading = true;
    const observables = [];
    const obj = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      salesorderno: this.soNum,
      wtnumber: this.wtNum,
      notes: '',
      status: 'QAN'
    };
    console.log(obj);
    observables.push(this.sageApi.putRequest('UpdWTStatus/Group', obj));
    this.alertify.warning(`Submitting to QA`);
    concat(...observables).subscribe(response => {
      console.log(`put request`, response);
    }, error => {
      console.log(error);
      this.isLoading = false;
      this.alertify.error(`Error - ` + error);
    }, () => {
      this.isLoading = false;
      this.alertify.success(`Success!`);
    });
  }

  submitQAItems(rockOrQA = 'QA'){
    // this.rocksPresent = rockOrQA === 'rock';
    this.isLoading = true;
    const observables = [];
    const qaItems = [];
    const dateNow = new Date().toDateString();
    // if (this.qaAddModel !== []){
    //   // this.qaModel.push(this.qaAddModel);
    // }
    this.qaModel.forEach(item => {
      if (item.isComplete !== item.isCompleteNew) {
        const qa = [
          item.lineKey === 'none' || item.lineKey === '' ? 'none' : item.lineKey,
          item.salesOrderNo,
          item.wtNumber !== undefined ? item.wtNumber : this.wtModel[0].WTNumber,
          item.parentLineKey,
          item.isCompleteNew,
          item.code === '' ? ' ' : item.code,
          item.location === '' ? ' ' : item.location,
          item.notes === 'Comment here:' ? ' ' : item.notes,
          item.expectedCompletionDate,
          item.itemCode,
          item.itemCodeDesc === '' ? 'QA Item' : item.itemCodeDesc
        ];
        qaItems.push(qa);
      }
    });
    console.log(this.qaAddModel);
    if (this.qaAddModel !== []){
      // console.log('Alpha');
      this.qaAddModel.forEach(tack => {
        if ( tack.itemCodeDesc.length > 0){
          // console.log(tack);
          // console.log('BETA');
          const qa = [
            tack.lineKey === 'none' || tack.lineKey === '' ? 'none' : tack.lineKey,
            tack.salesOrderNo,
            tack.wtNumber !== undefined ? tack.wtNumber : this.wtModel[0].WTNumber,
            tack.parentLineKey,
            tack.isCompleteNew === false ? 'N' : tack.isCompleteNew,
            tack.code === '' ? ' ' : tack.code,
            tack.location === '' ? ' ' : tack.location,
            tack.notes === '' ? ' ' : 'Crew Comment: ' + tack.notes,
            tack.expectedCompletionDate,
            tack.itemCode,
            tack.itemCodeDesc === '' ? 'QA Item' : 'Crew Comment: ' + tack.itemCodeDesc
          ];
          qaItems.push(qa);
        }
      });
      // this.qaModel.push(this.qaAddModel);
    }
    console.log('length', qaItems.length);
    if (this.rocksPresent){
      qaItems.push(
        ['none', 'ZROCKSTD', '1', 'N', 'rock', '000', 'Y', '', '', 'N', 'N', 'N', 'N', 'Y', '', '', '0', '', 'ZROCK', 'Audit - Pending', 'N', 'Rock - Standard'],
        ['none', '/ROROUGH', '0', 'Y', 'rock', '020', 'N', '', '', 'N', 'N', 'N', 'N', 'Y', '', '', '0', 'NONE', '', '', 'N', 'Rock - Rough In'],
        ['none', '/ROSEWER', '0', 'Y', 'rock', '020', 'N', '', '', 'N', 'N', 'N', 'N', 'Y', '', '', '0', 'NONE', '', '', 'N', 'Rock - Sewer'],
        ['none', '/ROWATER', '0', 'Y', 'rock', '020', 'N', '', '', 'N', 'N', 'N', 'N', 'Y', '', '', '0', 'NONE', '', '', 'N', 'Rock - Water Line']);
    }

    // if (qaItems !== undefined && qaItems > 80){
    //   this.alertify.error('Error - Too many QA Items submitted for this WT');
    //   return;
    // }

    const SubQA = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      salesOrderNo: this.wtModel[0].salesOrderNo,
      wtNumber: this.wtModel[0].wtNumber,
      parentLineKey: this.wtModel[0].parentLineKey,
      status: this.readyForQA ? 'QAN' : 'not ready',
      trackComment: 'QR-CrewLaborDetail-Updated-' + dateNow,
      qaItem: qaItems,
      rockQTY: this.RocksAmount.toString(),
      rockOrQA,
      assignedTech: this.wtModel[0].techCode,
      rocksOption: this.rocksPresent ? (this.wtModel[0].itemCode.toLocaleLowerCase().includes('zroughin') ? 'R' :
        this.wtModel[0].itemCode.toLocaleLowerCase().includes('zsewer') ? 'S' :
          this.wtModel[0].itemCode.toLocaleLowerCase().includes('zwater') ? 'W' : 'N') : 'N'
    };

    console.log(SubQA);
    observables.push(this.sageApi.putRequest('QRCode/Update/CrewQA', SubQA));
    // this.closeModal();
    if (this.authService.decodedToken.nameid.toLocaleLowerCase() === 'gguest'){
      this.alertify.message('Updating the Workticket. You will be logged out once it is Complete.', 60);
    }
    else {
      this.alertify.message('Updating the Workticket. You will be redirected to the Home Page once it is Complete.', 60);
    }
    // observables.push(this.sageApi.putRequest('QRCodeSite', obj));
    concat(...observables).subscribe((e) => {
        console.log('put observable response', e);
      },
      (err) => {
        console.log(err);
        this.loadingStatus = 'Failed - look at console for Error';
        this.alertify.error(err.error.Message);
        // close modal
        // this.closeModal();
        // this.staticModal.hide();
        // console.log(this.dataModel);
        this.isLoading = false;
      },
      () => {
        // setTimeout(() => {
        //   this.isLoading = false;
        // }, 1500);
        // this.onResetDataClicked();
        this.loadingStatus = 'Work Ticket has been Updated';
        this.alertify.success(this.loadingStatus);
        // this.loadReport('SuperReport/Workday?leadman=');
        // close modal
        // this.staticModal.hide();
        // this.closeModal();
        this.isLoading = false;
        if (rockOrQA === 'QA'){
          if (this.authService.decodedToken.nameid.toLocaleLowerCase() === 'gguest'){
            this.logout();
          }
          else {
            this.router.navigate(['/']);
          }
        }
      });
  }

  logout() {
    localStorage.removeItem('token');
    this.authService.decodedToken = '';
    // this.alertify.message('logged out');
    this.router.navigate(['']);
  }

  closeModal() {
    this.RocksImageModal = false;
    this.modalTemplate.hide();
  }

  fileChosen(event) {
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        const obj = {
          name: files[index].name,
          data: files[index]
        };

        this.fileArray = [obj];
      }
    }
  }

  // imageName(imgArrIndex: number) {
  //   console.log(this.imagesForViewing[imgArrIndex].split('%2F').length);
  //   let name = this.imagesForViewing[imgArrIndex].split('%2F')[this.imagesForViewing[imgArrIndex].split('%2F').length - 1];
  //   name = name.replace(/%20/g, ' ');
  //   const namesplit = name.split('/');
  //   return 'Open ' + namesplit[namesplit.length - 1];
  // }

  imageName(imgArrIndex: number) {
    // console.log(this.imagesForViewing[imgArrIndex].split('%2F').length);
    let name = this.imagesForViewing[imgArrIndex].split('%2F')[this.imagesForViewing[imgArrIndex].split('%2F').length - 1];
    if (name !== null){
      name = name.replace(/%20/g, ' ');
    }
    const namesplit = name.split('/');
    return 'Open ' + namesplit[namesplit.length - 1];
  }

  fileNamesFunction() {
    const route = `QRCode/Files?address=${this.address}`;
    this.fileList = [];
    this.sageApi.pullReport(route).subscribe((response: any) => {
      console.log(response.split(', \\\\gp-nas2\\SO_WT_Files'));
      const splitFiles = response.split(', \\\\gp-nas2\\SO_WT_Files');
      splitFiles.forEach(file => {
        this.fileList.push({ name: file.split(this.address + '\\')[1]});
      });
    }, err => {
      console.log(err);
      this.alertify.error(err.message);
    }, () => {
      console.log(this.fileList);
    });
  }

  fileDownload(fileName: string) {
    const route = `QRCode/Files/download?address=${this.address}&fileName=${fileName}`;

    this.sageApi.getFile(route).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      if (fileName) {
        downloadLink.setAttribute('download', fileName);
      }
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.parentNode.removeChild(downloadLink);
    }, err => {
      console.log(err);
      this.alertify.error(err.message);
    });
  }

  fileUpload() {
    this.fileLoading = true;
    const route = `QRCode/Files/upload?address=${this.address}`;
    const sentFiles = [];

    this.fileArray.forEach( file => {
      sentFiles.push(file.data);
    });

    console.log(sentFiles);

    this.sageApi.uploadFile(route, sentFiles).subscribe((res) => {
      console.log(res);
    }, err => {
      console.log(err);
      this.fileLoading = false;
    }, () => {
      this.resetUpload();
      this.alertify.success('File Loaded');
    });
  }

  resetUpload() {
    this.fileArray = [];
    this.fileLoading = false;
  }


  openModal(template: TemplateRef<any>, phaseIndex: number = 0, stepIndex: number = 0, itemIndex: number = 0) {

    this.importTemplateInfo = {
      templateName: '',
      template: '',
      step: ''
    };

    this.modalTemplate = this.modalService.show(template);
  }

  shipAllItems(){
    this.itemCollection[this.workticket].forEach(item => {
      // const slashCheck = item.ItemCode.substring(0, 1);
      if (item.newQtyOrdered !== item.newQtyShipped && !item.JT158_WTParentLineKey.includes(item.LineKey)) {
        item.newQtyShipped = item.newQtyOrdered;
        item.edited = true;
      }
    });
    console.log('shipped', this.itemCollection[this.workticket]);
    // this.closeModal();
  }

  backorderShipAll(){
    this.itemCollection[this.workticket].forEach(item => {
      // const slashCheck = item.ItemCode.substring(0, 1);
      if (item.newQtyOrdered !== item.newQtyShipped && !item.JT158_WTParentLineKey.includes(item.LineKey)) {
        // console.log(item.LineKey);
        const backorderindex = this.itemList.findIndex(back => back.name.toLocaleLowerCase().includes(item.ItemCode.toLocaleLowerCase()));
        if (backorderindex > -1){
          this.itemList[backorderindex].tickets.forEach(tick => {
            if (tick.lineKey.includes(item.LineKey)){
              if (tick.newQtyOrdered >= this.itemList[backorderindex].newQtyShippedTotal){
                item.newQtyShipped = this.itemList[backorderindex].newQtyShippedTotal;
                this.itemList[backorderindex].newQtyShippedTotal = 0;
              } else {
                item.newQtyShipped = item.newQtyOrdered;
                this.itemList[backorderindex].newQtyShippedTotal = this.itemList[backorderindex].newQtyShippedTotal - item.newQtyOrdered;
              }
            }
          });
          // item.newQtyShipped = this.itemList[backorderindex].newQtyShipped;
        }else{
          item.newQtyShipped = item.newQtyOrdered;
        }
        item.edited = true;
      }
    });
    console.log('shipped', this.itemCollection[this.workticket]);
  }

  itemSelected(event: TypeaheadMatch, index: any, workticket: any) {
    // item.name = event.
    this.itemList[index].name = event.item.ItemCode;
    // this.itemList[index].lineKey = event.item.LineKey;
    console.log(this.itemCollection[workticket]);
    this.itemCollection[workticket].forEach(token => {
      if (token.ItemCode.toLocaleLowerCase().includes(this.itemList[index].name.toLocaleLowerCase())){
        if (isNaN(this.itemList[index].newQtyOrderedTotal)){
          this.itemList[index].newQtyOrderedTotal = event.item.QuantityOrdered;
        }else {
          this.itemList[index].newQtyOrderedTotal += event.item.QuantityOrdered;
        }
        if (isNaN(this.itemList[index].newQtyShippedTotal)){
          this.itemList[index].newQtyShippedTotal = event.item.newQtyShipped;
        }else {
          this.itemList[index].newQtyShippedTotal += event.item.newQtyShipped;
        }
        const obj = {
          name:  event.item.ItemCode,
          lineKey: event.item.LineKey,
          newQtyOrdered: event.item.newQtyOrdered,
          newQtyShipped: event.item.newQtyShipped,
        };
        this.itemList[index].tickets.push(obj);
      }
    });
    // this.itemList[index].newQtyOrdered = event.item.QuantityOrdered;
    // this.itemList[index].newQtyShipped = event.item.QuantityShipped;
  }

  generatebackorders(){
    this.shipAll = false;
    if (this.itemList.length === 0){
      this.AddBackorderList();
    }
  }

  lineEdited(index: number) {
    // tslint:disable-next-line:max-line-length
    // const checkQtyOrdered = this.itemCollection[this.workticket][index].QuantityOrdered !== this.itemCollection[this.workticket][index].newQtyOrdered;
    // tslint:disable-next-line:max-line-length
    // const checkQtyShipped = this.itemCollection[this.workticket][index].QuantityShipped !== this.itemCollection[this.workticket][index].newQtyShipped;
    // tslint:disable-next-line:max-line-length
    // const checkCommentText = this.itemCollection[this.workticket][index].CommentText !== this.itemCollection[this.workticket][index].newCommentText;

    // if (checkQtyOrdered || checkQtyShipped || checkCommentText) {
    //   this.itemCollection[this.workticket][index].edited = true;
    // }
    console.log(this.itemList);
  }

  AddBackorderList(){
    const obj = {
      name: '',
      // lineKey: '000000',
      newQtyOrderedTotal: 0,
      newQtyShippedTotal: 0,
      tickets: []
    };
    this.itemList.push(obj);
    console.log(this.itemList);
  }

  AddQAList(){
    const salesOrderNumber = this.workticket.substring(0, 7);
    const wtNumber = this.workticket.substring(7);
    const obj = {
      salesOrderNo: salesOrderNumber,
      wtNumber,
      wtStep: '000',
      address: this.qaModel[0].address,
      subdivision: this.qaModel[0].subdivision,
      city: this.qaModel[0].city,
      builder: this.qaModel[0].builder,
      status: this.qaModel[0].status,
      dip: this.qaModel[0].status,
      tech: this.qaModel[0].tech,
      parentLineKey: this.qaModel[0].parentLineKey,
      lineKey: 'none',
      parentLineCode: this.qaModel[0].parentLineCode,
      trackingComment: '',
      scheduleDate:  this.qaModel[0].scheduleDate,
      promiseDate: this.qaModel[0].promiseDate,
      itemCode: '/QA',
      itemCodeDesc: '',
      code: 'QRQAN',
      updated: 'false',
      notes: '',
      isComplete: false,
      isCompleteNew: false,
      expectedCompletionDate: this.qaModel[0].expectedCompletionDate || '    ',
      location: this.qaModel[0].location
    };
    this.qaAddModel.push(obj);
    console.log(this.qaAddModel);
  }

  RemoveBackorderList(index: number){
    this.itemList.splice(index, 1);
    console.log(this.itemList);
  }

  RemoveQAList(index: number){
    this.qaAddModel.splice(index, 1);
    console.log(this.qaAddModel);
  }

  pullMaterial() {
    if (this.workticket.length !== 10) {
      console.log('length hit');
      return;
    }

    if (this.itemCollection.hasOwnProperty(this.workticket)) {
      this.itemCollection[this.workticket] = [];
    }

    this.isLoading = true;
    console.log(this.workticket);
    this.soNum = this.workticket.substring(0, 7);
    this.wtNum = this.workticket.substring(7);
    // console.log(this.soNum);
    // console.log(this.wtNum);
    // const soNum = this.wtNumber.split('-')[0];
    // const wtNum = this.wtNumber.split('-')[1];

    this.sageApi.pullReport(`UpdMatUsage?soNum=${this.soNum}&wtNum=${this.wtNum}`).subscribe((items: Array<any>) => {
      if (Array.isArray(items)) {
        items.forEach(item => {
          // const obj = {
          //   LineKey: item.LineKey,
          //   CommentText: item.CommentText,
          //   ExtendedDescriptionKey: item.ExtendedDescriptionKey,
          //   ItemCode: item.ItemCode,
          //   ItemCodeDesc: item.ItemCodeDesc,
          //   JT158_WTNumber: item.JT158_WTNumber,
          //   JT158_WTParentLineKey: item.JT158_WTParentLineKey,
          //   QuantityOrdered: item.QuantityOrdered,
          //   QuantityShipped: item.QuantityShipped,
          //   SalesOrderNo: item.SalesOrderNo,
          //   ShipToName: item.ShipToName,
          //   UDF_LEADMAN: item.UDF_LEADMAN,
          //   UDF_LOCATION: item.UDF_LEADMAN,
          //   added: false,
          //   edited: false,
          //   loading: false,
          //   newCommentText: item.CommentText,
          //   newQtyOrdered: item.QuantityOrdered,
          //   newQtyShipped: item. QuantityShipped
          // };
          item.newQtyOrdered = item.QuantityOrdered;
          item.newQtyShipped = item.QuantityShipped;
          item.newCommentText = item.CommentText;
          item.loading = false;
          item.updated = false;
          item.edited = false;
          item.added = false;

          if (!this.itemCollection.hasOwnProperty(this.workticket)) {
            this.itemCollection[this.workticket] = [item];
          } else {
            this.itemCollection[this.workticket].push(item);
          }
        });
        console.log(this.itemCollection);
      }
    }, err => {
      this.isloading = false;
      this.alertify.error('Error loading ' + this.workticket + ': ' + err.error.Message);
      console.log(err);
    }, () => {
      this.isloading = false;
      // this.shipAllItems();
      // this.wtNumber = '';
    });
  }

  resetTicket(wtNumber: string) {
    delete this.itemCollection[wtNumber];
  }
}
