<div class="container pb-5">
  <div class="card my-5 shadow-lg text-center" id="noprint">
    <h1>Return</h1>
    <ng-container>
      <div class="col-12 pt-2 text-center">
        <label for="address">Scan WT Code</label>
        <!--          <label for="address">Dirección o WT#</label>-->
      </div>
      <div class="col-12">
        <input
          [disabled]="itemList.length > 0"
          class="form-control input"
          #input
          [(ngModel)]="jobNumber"
          (keyup.enter)="getTransferList(jobNumber)"
          placeholder="Scan QR or WT BarCode"
          autocomplete="off"
        />
        <button
          class="btn btn-primary"
          (click)="getTransferList(jobNumber)"
          [disabled]="jobNumber == ''"
        >
          <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i></span>
          <span *ngIf="!loading">Send</span>
        </button>
        <button class="btn btn-primary" (click)="reset()">Reset</button>
      </div>
    </ng-container>
    <ng-container *ngIf="itemList.length > 0">
      <table class="table table-stripped table-hover">
        <thead>
          <th>From Truck</th>
          <th>To Warehouse</th>
          <th>Item Code</th>
          <th>Item Description</th>
          <th>Ordered</th>
          <th>Shipped</th>
          <th>Returned</th>
          <th>Comment</th>
          <th>User</th>
        </thead>
        <tbody>
          <tr *ngFor="let item of itemList">
            <td>{{ item.WarehouseFrom }}</td>
            <td>{{ item.WarehouseTo }}</td>
            <td>{{ item.ItemCode }}</td>
            <td>{{ item.Description }}</td>
            <td>{{ item.Ordered }}</td>
            <td>{{ item.Shipped }}</td>
            <td>
              <input
                type="number"
                min="0"
                max="{{item.Shipped}}"
                class="form-control input"
                [(ngModel)]="item.Returned"
              />
            </td>
            <td>{{ item.Comment }}</td>
            <td>{{ item.User }}</td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-primary" (click)="return(itemList)" [disabled]="sending">
        <span *ngIf="sending"><i class="fas fa-sync fa-spin"></i></span>
        <span *ngIf="!sending">Return</span>
      </button>
    </ng-container>
  </div>
</div>
