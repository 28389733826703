<div class="container">
<!--  <div class="text-danger"><h5>*This Page is currently in BETA. (changes may occur to page's view and functionality) If-->
<!--    you see any issues or oddities on this page, please submit a report on the gibson portal (portal.gibsonplumbing.com)-->
<!--    with an image, WT number with phase (number or name), and what is wrong.*</h5></div>-->
  <div class="card border-1 m-3">
    <!--    Header-->
    <div class="card-header">
      <div class="row mb-1" id="">
        <div class="col-sm-5 col-md-4">
          <div class="row">
            <div class="col-12 col-md-4 pt-2 pb-1">
              <label class="font-weight-bold" for="address">Address: </label>
            </div>
            <div class="col-12 col-md">
              <input class="form-control" [(ngModel)]="addressName" (typeaheadOnSelect)="addressSelected($event)"
                     [typeahead]="addressList" typeaheadOptionField="name" placeholder="addressList" autocomplete="off">
              <!--              <small *ngIf="houseLoading">Getting {{ addressName }} Info...</small>-->
            </div>
          </div>
        </div>
        <div class="col-sm-5 col-md-3 py-1">
          <div class="row">
            <div class="col-8" dropdown>
              <button dropdownToggle type="button" class="btn btn-outline-dark dropdown-toggle text-capitalize"
                      [disabled]="!addressLoaded">
                <span *ngIf="selectedPhaseName == ''"> Phases: </span> {{ selectedPhaseName }}<span
                class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu dropdown-menu-right scrollable-menu" role="menu">
                <li role="menuitem" *ngFor="let phase of phases; let seriesIndex = index">
                  <a class="dropdown-item text-capitalize" (click)="phaseSelected(phase.phaseName, seriesIndex)">
                    <span>{{ phase.phaseName }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <!--            <div class="col-3  pt-2 text-center">-->
            <!--              <label><h5 class="font-weight-bold">OR</h5></label>-->
            <!--            </div>-->
          </div>
        </div>
        <!--        <div class="col-sm-10 col-md-1 pt-2">-->
        <!--        </div>-->
        <div class="col-sm-5 col-md py-1">
          <div class="row">
            <div class="col-12 col-md-2 pt-2">
              <label class="font-weight-bold" for="phases"> WT#:</label>
            </div>
            <div class="col-12 col-md-6">
              <input class="form-control" typeaheadOptionField="name" [(ngModel)]="wtnumber" (keyup)="onWTKeyUp($event)"
                     placeholder="#######-###" autocomplete="off">
              <!--              <small *ngIf="houseLoading">Getting {{ addressName }} Info...</small>-->
            </div>
            <div class="col py-1">
              <button type="button" class="btn btn-primary mb-2 float-right" (click)="loadQATicket()">Load</button>
            </div>
          </div>
        </div>
        <!--                <div class="col-sm-5 col-md-1 pt-1">-->
        <!--                    <button class="btn-primary">search</button>-->
        <!--                </div>-->
      </div>
    </div>
    <!--    End of Header-->
    <!--    Body-->
    <div class="card-body border-0" *ngIf="wtLoaded">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="row mb-2">
            <div class="col-12 col-md-5">
              <div class="row">
                <div class="col-12 col-md-5 pt-2">
                  <label class="font-weight-bold" for="wtNumber">WT Number: </label>
                </div>
                <div class="col">
                  <!--                  {{wtnumber}}-->
                  <input class="form-control" [(ngModel)]="wtnumber" placeholder="#######-###-###" disabled>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="row">
                <div class="col-12 col-md-4 pt-2">
                  <label class="font-weight-bold" for="address">Address: </label>
                </div>
                <div class="col">
                  <!--                  {{writeAddress}}-->
                  <input class="form-control" [(ngModel)]="writeAddress" placeholder="address" disabled>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="row">
                <div class="col-12 col-md-4 pt-2">
                  <label class="font-weight-bold" for="phase">Phase: </label>
                </div>
                <div class="col">
                  <!--                  {{cleanPhaseName}}-->
                  <input class="form-control" [(ngModel)]="cleanPhaseName" placeholder="PHASE" disabled>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-5">
              <div class="row">
                <div class="col-12 col-md-5 pt-2">
                  <label class="font-weight-bold" for="subdivision">Subdivision: </label>
                </div>
                <div class="col">
                  <!--                  {{writeSubdivision}}-->
                  <input class="form-control" [(ngModel)]="writeSubdivision" placeholder="subdivision" disabled>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="row">
                <div class="col-12 col-md-3 pt-2">
                  <label class="font-weight-bold" for="techName">Tech: </label>
                </div>
                <div class="col">
                  <!--                  {{writeTechName}}-->
                  <input class="form-control" [(ngModel)]="writeTechName" placeholder="Tech Name" disabled>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-3">
              <div class="row">
                <div class="col-12 col-md-2 col-lg-3 pt-2">
                  <label class="font-weight-bold" for="techphone">Phone: </label>
                </div>
                <div class="col col-md-3 col-lg pt-1">
                  <!--                  {{writeTechName}}-->
                  <input class="form-control" [(ngModel)]="techPhoneNumber" placeholder="No Num" disabled>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-3">
              <div class="row">
                <div class="col-12 col-md-4 pt-2">
                  <label class="font-weight-bold" for="status">Status: </label>
                </div>
                <div class="col">
                  <!--                  {{writeStatus}}-->
                  <input class="form-control" [(ngModel)]="writeStatus" placeholder="status" disabled>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="row">
                <div class="col-12 col-md-6 pt-2">
                  <label class="font-weight-bold" for="scheduleDate">Scheduled date: </label>
                </div>
                <div class="col">
                  <!--                  {{writeScheduleDate}}-->
                  <!--                  <input class="form-control" [(ngModel)]="writeScheduleDate" placeholder="MM/dd/YYYY" disabled>-->
                  <input
                    type="text"
                    class="form-control"
                    bsDatepicker
                    [bsConfig]="bsConfig"
                    (bsValueChange)="this.logDate($event)"
                    [(bsValue)]="writeScheduleDate"
                    [disabled]="true">
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="row">
                <div class="col-12 col-md-7 pt-2">
                  <label class="font-weight-bold" for="daysInProgress">Days in Progress: </label>
                </div>
                <div class="col">
                  <input class="form-control" [(ngModel)]="writeDIP" placeholder="##" disabled>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-1 pt-2 mr-1">
              <label class="font-weight-bold" for="comment">Builder: </label>
            </div>
            <div class="col-12 col-md-3">
              <input class="form-control" [(ngModel)]="builderName" placeholder="##" disabled>
            </div>
            <ng-container *ngIf="topoutORTrim()">
              <div class="col-10 col-md-3 pt-2">
                <label class="font-weight-bold">Water Meter Present: </label>
              </div>
              <div class="col col-md-4 pt-2">
                <button type="button" class="btn"
                        [ngStyle]="{'background-color': wtModel[0].waterMeterPresent && confirmWaterMeter? 'yellow' : 'red', 'color': wtModel[0].waterMeterPresent && confirmWaterMeter ? 'black' : 'white'}"
                        (click)="wtModel[0].waterMeterPresent = true; confirmWaterMeter = true">Yes <i *ngIf="wtModel[0].waterMeterPresent && confirmWaterMeter" class="fas fa-check"></i></button>
                <span class="ml-3 align-content-center"><b>/</b></span>
                <button type="button" class="ml-3 btn"
                        [ngStyle]="{'background-color': !wtModel[0].waterMeterPresent && confirmWaterMeter? 'yellow' : 'red', 'color': !wtModel[0].waterMeterPresent && confirmWaterMeter? 'black' : 'white'}"
                        (click)="wtModel[0].waterMeterPresent = false; confirmWaterMeter = true">No <i *ngIf="!wtModel[0].waterMeterPresent && confirmWaterMeter" class="fas fa-check"></i></button>
<!--                <input type="checkbox" class="form-check-input" id="water-meter-check"-->
<!--                       [(ngModel)]="wtModel[0].waterMeterPresent">-->
              </div>
            </ng-container>
            <div class="col-12 col-md-2 pt-2">
              <label class="font-weight-bold" for="comment">Comment: </label>
            </div>
            <div class="col-12 col-md pt-2">
              <textarea id="trackingComment" [(ngModel)]="writeTrackingComment" maxlength="500" rows="3"
                        class="form-control" type="text"
                        name="trackComment" disabled></textarea>
            </div>
          </div>
          <div class="row action-items-rows">
            <label class="font-weight-bold">Quick Action:</label>
            <button
              type="button"
              class="btn btn-primary button--com ml-2"
              [disabled]="!isValidWTNumber || isLoading"
              (click)="onQuickActionButtonClick('INS')">
              INS
            </button>

            <button
              type="button"
              class="btn btn-primary button--com ml-2"
              [disabled]="!isValidWTNumber || isLoading"
              (click)="onQuickActionButtonClick('COM')">
              COM
            </button>
          </div>
        </li>
        <li class="list-group-item m-0 p-0" *ngIf="!mobile">
          <div class="row border-top table-responsive punch-table-container py-2 m-0 px-0">
            <div class="punch-table-titles row">
              <div class=" col-md-10 col-lg-11 pb-1"><h4 class="lead text-center">Current Punch Items</h4></div>
              <div class="col pb-1 m-0">
                <button *ngIf="displayCurrentPunchSection"
                        (click)="displayCurrentPunchSection= !displayCurrentPunchSection" type="button"
                        class="btn btn-primary">▼
                </button>
                <button *ngIf="!displayCurrentPunchSection"
                        (click)="displayCurrentPunchSection= !displayCurrentPunchSection" type="button"
                        class="btn btn-primary">⯇
                </button>
              </div>
            </div>
            <table class="table" *ngIf="displayCurrentPunchSection">
              <thead class="thead-dark">
              <tr>
                <th>WT#</th>
                <th style="width: 35%;">Desc</th>
                <th style="width: 35%;">Comment</th>
                <th>Status</th>
                <th>DIP</th>
                <th>Tech</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let punch of punModel; let i= index">
                <td>{{punch.wtNumber}}</td>
                <td>{{punch.itemCodeDesc}}</td>
                <td>{{punch.trackingComment}}</td>
                <td>{{punch.punStatus}}</td>
                <td>{{punch.dip}}</td>
                <td>{{punch.tech || ''}} - {{punch.techCode || ''}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
        <ng-container *ngIf="backorderList != []">
          <li class="list-group-item mx-0 mt-3 p-0" *ngIf="!mobile">
          <div class="row border-top table-responsive punch-table-container py-2 m-0 px-0">
            <div class="punch-table-titles row">
              <div class=" col-md-10 col-lg-11 pb-1"><h4 class="lead text-center">Backorder Items</h4></div>
              <div class="col pb-1 m-0">
                <button *ngIf="displayCurrentBackOrderSection"
                        (click)="displayCurrentBackOrderSection= !displayCurrentBackOrderSection" type="button"
                        class="btn btn-primary">▼
                </button>
                <button *ngIf="!displayCurrentBackOrderSection"
                        (click)="displayCurrentBackOrderSection= !displayCurrentBackOrderSection" type="button"
                        class="btn btn-primary">⯇
                </button>
              </div>
            </div>
            <ng-container *ngIf="backorderList.length <= 0">
              <table class="table" *ngIf="displayCurrentBackOrderSection">
                <thead class="thead-dark">
                <tr>
                  <th class="text-center">No Backorder Parts Found / No se encontraron piezas de pedido pendiente</th>
                </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
            </ng-container>
            <ng-container *ngIf="backorderList.length > 0">
              <table class="table" *ngIf="displayCurrentBackOrderSection">
                <thead class="thead-dark">
                <tr>
                  <th>ItemCode</th>
                  <th style="width: 35%;">Desc</th>
                  <!--                <th>Qty Required</th>-->
                  <th>Qty BackOrdered</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let bo of backorderList; let i= index">
                  <td>{{bo.ItemCode}}</td>
                  <td>{{bo.ICDesc}}</td>
                  <!--                <td>{{bo.QuantityOrdered}}</td>-->
                  <td>{{bo.QuantityBackordered}}</td>
                </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </li>
        </ng-container>
        <li class="list-group-item">
<!--          <div class="row mb-2 text-warning">**There were some changes to the how some QA items are recorded in our-->
<!--            system. This may result in some of the older WTs QA having the items description in the Comment section of-->
<!--            the item. Please avoid adding comments to these QA items if possible, but comments can be added if-->
<!--            necessary. Newer tickets (from 6/29 onwards) will not have this issue.**-->
<!--          </div>-->
          <div class="row my-2"><label class="mr-2">Language: </label>
            <button class="btn" (click)="engSpan === true ? engSpan = false : engSpan = true"
                    [ngStyle]="{'background-color': engSpan ? 'red' : 'yellow', 'color': engSpan ? 'white' : 'black'}">
            <span *ngIf="!engSpan">Espanol</span>
            <span *ngIf="engSpan">English</span></button></div>
          <div class="row my-2 text-warning">**Note: <span *ngIf="engSpan"> Not all QA items will have a Spanish Translation</span>
            <span *ngIf="!engSpan"> No todos los elementos de control de calidad tendrán una traducción al español</span>**
          </div>
          <div class="row mb-2">
            <ng-container *ngIf="!mobile">
              <div class="panel col">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <th class="text-center">QA ID</th>
                      <th class="text-center">Task</th>
                      <th class="text-center">Complete?</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let step of qaModel; let i= index">
                      <!--                      <td *ngIf="step.isComplete !== 'Y'" class="text-center">-->
                      <td class="text-center">
                        {{step.itemCode.length > 3 ? step.itemCode.substring(3) : step.code}}
                      </td>
                      <!--                      <td *ngIf="step.isComplete !== 'Y'" class="text-center">-->
                      <td class="text-center">
<!--                        <div>{{step.itemCodeDesc}}</div>-->
                        <div>{{engSpan ? step.ICEnglish : step.ICSpanish}}</div>
<!--                      <textarea-->
<!--                        class="form-control"-->
<!--                        type="text"-->
<!--                        rows="4"-->
<!--                        maxlength="2000"-->
<!--                        [(ngModel)]="step.itemCodeDesc"-->
<!--                        (change)="step.updated= 'true'"-->
<!--                        disabled-->
<!--                      ></textarea>-->
                        <textarea
                          class="form-control mt-2"
                          type="text"
                          rows="4"
                          maxlength="2000"
                          *ngIf="step.isCompleteNew =='N'"
                          [(ngModel)]="step.notes"
                          (change)="step.updated= 'true'"></textarea>
                      </td>
                      <!--                      <td *ngIf="step.isComplete !== 'Y'" class="text-center">-->
                      <td class="text-center">
                        <div class="form-check">
                          <input type="checkbox" class="form-check-input" [checked]="step.isCompleteNew =='Y'"
                                 (change)="step.isCompleteNew = step.isCompleteNew != 'Y' ? 'Y' : 'N'"
                                 (click)="checkStat(step.isCompleteNew)">
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="mobile">
              <div class="panel col">
                <ng-container *ngFor="let step of qaModel; let i= index">
                  <!--                  <div *ngIf="step.isComplete !== 'Y'">-->
                  <div>
                    <div class="row">
                      <div class="col-5"><b>QA ID:</b></div>
                      <div class="col"><b>{{step.itemCode.length > 3 ? step.itemCode.substring(3) : step.code}}</b>
                      </div>
                    </div>
                    <div class="row my-1">
                      <div class="col-12"><b>Task:</b></div>
                      <div class="col-12">
<!--                        <div>{{step.itemCodeDesc}}</div>-->
                        <div>{{engSpan ? step.ICEnglish : step.ICSpanish}}</div>
<!--                        <textarea-->
<!--                          class="form-control"-->
<!--                          type="text"-->
<!--                          rows="4"-->
<!--                          maxlength="2000"-->
<!--                          [(ngModel)]="step.itemCodeDesc"-->
<!--                          (change)="step.updated= 'true'"-->
<!--                          disabled-->
<!--                        ></textarea>-->
                        <textarea
                          class="form-control mt-2"
                          type="text"
                          rows="4"
                          maxlength="2000"
                          *ngIf="step.isCompleteNew =='N'"
                          [(ngModel)]="step.notes"
                          (change)="step.updated= 'true'"></textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><b>Complete:</b></div>
                      <div class="form-check col">
                        <input type="checkbox" class="form-check-input" [checked]="step.isCompleteNew=='Y'"
                               (change)="step.isCompleteNew = step.isCompleteNew != 'Y' ? 'Y' : 'N'"
                               (click)="checkStat(step.isCompleteNew)">
                      </div>
                    </div>
                    <hr>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-12">
              <button type="button" class="btn btn-secondary mb-2" (click)="onReset()">Reset</button>
              <button type="button" class="btn btn-primary mb-2 float-right"
                      (click)="openModal(submitWT, false)">Submit
              </button>
              <a class="btn btn-secondary mb-2 mr-2 float-right" (click)="openModal(chippingModal, false)"
                 (click)="fileNamesChip()">Add Chipping Lines</a>
<!--              <button type="button" class="btn btn-outline-info mb-2 mr-2 float-right"-->
<!--                      (click)="openModal(imageModal, true)" (click)="fileNames()">Images-->
<!--              </button>-->
              <button type="button" class="btn btn-outline-info mb-2 mr-2 float-right"
                      (click)="openModal(imageModal, true)">Images
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- end of Body-->
  </div>
</div>

<!--* Chipping Modal -->
<ng-template #chippingModal>
  <div class="modal-dialog m-0" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left" id="chipping-modal">Chipping for {{addressName}}</h4>
        <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mb-0 pb-0">
        <form class="container-fluid">
          <div class="row">
            <div class="form-group col-md-4">
              <label for="completePipes">6" or <span style="color: orange;">LESS</span> out?</label>
              <input
                type="number"
                min="0"
                max="100"
                placeholder="0"
                [(ngModel)]="this.wtModel[0].completePipes"
                required
                name="completePipes"
                id="complete-pipes-value"
                class="form-control"
                (change)="this.wtModel[0].completePipesUpdated= true"/>
            </div>
            <div class="form-group col-md-4">
              <label for="pipesOutExtended">6" or <span style="color: blue;">MORE</span> out?</label>
              <input
                type="number"
                min="0"
                max="100"
                placeholder="0"
                [(ngModel)]="this.wtModel[0].pipesOutExtended"
                required
                name="pipesOutExtended"
                id="pipes-out-extended-value"
                class="form-control"
                (change)="this.wtModel[0].pipesOutExtendedUpdated= true"/>
            </div>
            <div class="form-group col-md-4">
              <label for="partialPipes">Partially out?</label>
              <input
                type="number"
                min="0"
                max="100"
                placeholder="0"
                [(ngModel)]="this.wtModel[0].partialPipes"
                required
                name="partialPipes"
                id="partial-pipes-value"
                class="form-control"
                (change)="this.wtModel[0].partialPipesUpdated= true"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="damagedPipes">Damaged pipes?</label>
              <input
                type="number"
                min="0"
                max="100"
                [(ngModel)]="wtModel[0].damagedPipes"
                name="damagedPipes"
                id="damaged-pipes-value" class="form-control" aria-describedby="damagedPipes"
                placeholder="Number of damaged pipes"
                (change)="this.wtModel[0].damagedPipesUpdated= true"/>
            </div>
            <div class="form-group col-md-6">
              <label for="tubBox">Tub box chipping?</label>
              <input
                type="number"
                min="0"
                max="100"
                placeholder="0"
                [(ngModel)]="this.wtModel[0].tubBox"
                required
                name="tubBox"
                id="tub-box-value"
                class="form-control"
                (change)="this.wtModel[0].tubBoxUpdated= true"/>
            </div>
          </div>
          <div class="row text-danger" *ngIf="!imageAdded">
            * An image MUST be submitted first before you can submit a chipping report. *
          </div>
          <div class="row text-success" *ngIf="imageAdded">
            * An image has been uploaded for chipping *
          </div>
        </form>
      </div>
      <!--      <ul class="list-group list-group-flush">-->
      <!--        <li class="list-group-item border-0">-->
      <!--          <div class="row mb-1" id="filesChip">-->
      <!--            <div class="col-sm-3 pt-2">-->
      <!--              <div class="card text-center">-->
      <!--                <label class="pt-2 mb-0">-->
      <!--                  <i class="fas fa-file-upload fa-4x text-info"></i>-->
      <!--                  <input type="file" (change)="fileChosen($event)" style="display: none;">-->
      <!--                </label>-->
      <!--                <small>Upload</small>-->
      <!--                <ng-container *ngFor="let file of fileArray">-->
      <!--                  <br> <small> {{ file.name }}</small>-->
      <!--                </ng-container>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="col-sm-3 pt-2" *ngFor="let file of fileList">-->
      <!--              <div class="card text-center">-->
      <!--                <a class="btn p-0 pt-2" (click)="fileDownload(file.name)"><i class="fas fa-file-alt fa-4x"></i></a>-->
      <!--                <small>{{ file.name }}</small>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </li>-->
      <!--      </ul>-->
      <hr/>
      <div class="container-fluid">
        <div class="row scout-button-container">
          <div>
            <label for="load-existing-images" class="btn btn-primary scout-img-button"
                   (click)="loadImagesForViewing()">Load Images</label>
          </div>
          <div>
            <label
              [class.disabled]="isLoading || isImageLoading"
              class="scout-modal-buttons btn btn-primary scout-img-button">
              {{ isImageLoading ? "Uploading" : "Upload Images" }}
              <input
                [disabled]="isLoading ? 'disabled' : ''"
                style="display: none;"
                accept="image/*"
                multiple="true"
                id="uploadBtn"
                type="file"
                (change)="onFileChangedW($event, 'chip')"
              />
            </label>
            <div>
              <small *ngIf="fileNamesW.length !== 0">{{
                fileNamesW.join(" ")
                }}</small>
            </div>
            {{ imageUploadComplete ? "Selected Image Uploaded" : "" }}
          </div>
          <div>
            <button
              [disabled]="isLoading || !imageAdded"
              class="scout-modal-buttons btn btn-primary scout-img-button"
              (click)="loadImagesS3()"
              type="submit"
              value="submit">
              <img *ngIf="isLoading" src="../../../../assets/ring-alt.svg"/>
              <span *ngIf="!isLoading">Submit</span>
            </button>
          </div>
          <div>
            <button type="button" class="btn btn-primary scout-img-button"
                    *ngIf="!displayScoutImages && imagesForViewing.length> 0"
                    (click)="displayScoutImages= !displayScoutImages">&#11207;
            </button>
            <button type="button" class="btn btn-primary scout-img-button"
                    (click)="displayScoutImages= !displayScoutImages" *ngIf="displayScoutImages">&#9660;
            </button>
          </div>
        </div>
        <div *ngIf="displayScoutImages" class="row scout-img-container" style="padding-top: 1rem;">
          <ng-container *ngFor="let img of imagesForViewing; let i= index">
            <div *ngIf="img.toLowerCase().slice(-3) === 'pdf'">
              <div class="row">
                <div class="col-sm-12">
                  <a [href]="imagesForViewing[i]" target="_blank" class="" [innerHtml]="imageName(i)"></a>
                </div>
              </div>
            </div>
          </ng-container>
          <hr>
          <ng-container *ngFor="let img of imagesForViewing; let i= index">
            <div *ngIf="img.toLowerCase().slice(-3) !== 'pdf' && img.slice(-1) !== '/'">
              <img
                width="100%"
                height="100%"
                src="{{ img }}"
              />
            </div>
<!--            <div *ngIf="img.slice(-3) === 'pdf'">-->
<!--              <div class="row">-->
<!--                <div class="col-sm-12">-->
<!--                  <a [href]="imagesForViewing[i]" target="_blank" class="" [innerHtml]="imageName(i)"></a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </ng-container>
        </div>
      </div>
      <!--      <div class="row mb-1">-->
      <!--        <div class="col text-center">-->
      <!--          <button class="btn btn-info mr-2" (click)="fileUpload('Y')" [disabled]="fileArray.length <= 0 ">-->
      <!--            <span *ngIf="!fileLoading">Upload</span><span *ngIf="fileLoading">-->
      <!--          <i class="fas fa-sync fa-spin"></i></span></button>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="modal-footer container-fluid">-->
      <!--        <div-->
      <!--          *ngIf="loadingStatusChipping"-->
      <!--          role="alert"-->
      <!--          class="row"-->
      <!--          style="padding: 10px;">-->
      <!--          <div style="text-align: center;">-->
      <!--            <label *ngIf="loadingStatusChipping && !isErrorChipping"><img-->
      <!--              *ngIf="isLoadingChipping && !isErrorChipping"-->
      <!--              src="../../../assets/ring-alt-colored.svg"-->
      <!--              alt="a moving orange ring"-->
      <!--            />{{ loadingStatusChipping }}</label>-->
      <!--            <label *ngIf="isErrorChipping" for="progress-bar"><span style="color: darkred;">-->
      <!--                        <img-->
      <!--                          src="../../../assets/importantRED.png"-->
      <!--                          alt="an exclamation with a red background"-->
      <!--                          style="max-height: 20px;"/>-->
      <!--                        Error: </span>{{ loadingStatusChipping }}</label>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="row" style="padding: 10px;">-->
      <!--          <button type="button" class="btn btn-secondary pull-left" data-dismiss="modal" (click)="closeModal()">Cancel-->
      <!--          </button>-->
      <!--          <button type="button" class="btn btn-primary pull-right" (click)="onChippingSubmit()"-->
      <!--                  [disabled]="!imageAdded">Submit-->
      <!--          </button>-->
      <!--          &lt;!&ndash;          <button type="button" class="btn btn-primary pull-right" (click)="onChippingSubmit()">Submit</button>&ndash;&gt;-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</ng-template>
<!--* Chipping Modal -->

<!-- Submit modal-->
<ng-template #submitWT>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2> Confirm Changes? </h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <hr>
    <div class="row mb-1">
      <div class="col-5 col-md-4 pt-2 text-center">
        <label class="font-weight-bold" for="statuschange">Change Status? </label>
      </div>
      <div class="col-5" dropdown>
        <!--        <input class="form-control" [(ngModel)]="writeStatus" placeholder="status" disabled>-->
        <button dropdownToggle type="button" class="btn btn-outline-dark dropdown-toggle text-capitalize">
          {{ newStatus }}<span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right scrollable-menu" role="menu">
          <li role="menuitem" *ngFor="let stat of statusCode; let seriesIndex = index">
            <a class="dropdown-item text-capitalize" (click)="statusSelected(stat)">
              <span>{{ stat.desc }}</span>
              <!--                            <i *ngIf="phase.selected" class="fas fa-check fa-border fa-pull-right"></i>-->
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-12 col-md-2 pt-2">
        <label class="font-weight-bold" for="newTrackComment">Comment: </label>
      </div>
      <div class="col col-md-9">
        <textarea id="newTrackComment" [disabled]="!unlockComment"
                  (click)="newComment.length > 0 ? commentApplied = true: commentApplied = false"
                  [(ngModel)]="newComment" maxlength="500" rows="3" class="form-control" type="text"
                  name="trackComment"></textarea>
      </div>
    </div>
    <ng-container *ngIf="topoutORTrim()">
      <div class="row mb-1">
        <div class="col-10 col-md-4 pt-2">
          <label class="font-weight-bold">Water Meter Present: </label>
        </div>
        <div class="col col-md-5 pt-2">
          <button type="button" class="btn"
                  [ngStyle]="{'background-color': wtModel[0].waterMeterPresent && confirmWaterMeter? 'yellow' : 'red', 'color': wtModel[0].waterMeterPresent && confirmWaterMeter ? 'black' : 'white'}"
                  (click)="wtModel[0].waterMeterPresent = true; confirmWaterMeter = true">Yes <i *ngIf="wtModel[0].waterMeterPresent && confirmWaterMeter" class="fas fa-check"></i></button>
          <span class="ml-3 align-content-center"><b>/</b></span>
          <button type="button" class="ml-3 btn"
                  [ngStyle]="{'background-color': !wtModel[0].waterMeterPresent && confirmWaterMeter? 'yellow' : 'red', 'color': !wtModel[0].waterMeterPresent && confirmWaterMeter? 'black' : 'white'}"
                  (click)="wtModel[0].waterMeterPresent = false; confirmWaterMeter = true">No <i *ngIf="!wtModel[0].waterMeterPresent && confirmWaterMeter" class="fas fa-check"></i></button>
          <!--                <input type="checkbox" class="form-check-input" id="water-meter-check"-->
          <!--                       [(ngModel)]="wtModel[0].waterMeterPresent">-->
        </div>
      </div>
    </ng-container>
    <hr>
    <ng-container *ngIf="(newStatus === 'STC' && !imageAdded)">
      <div class="row mb-1 justify-content-center text-danger">
        <b>**You must submit 2-3 Images Before you can close a service ticket**</b>
      </div>
    </ng-container>
    <div class="row mb-1">
      <div class="col-6 text-center">
        <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
      </div>
      <div class="col-6 text-center">
        <!--        <button class="btn btn-primary" [disabled]="newComment.length <= 0" (click)="submitQA()">Submit</button>-->
        <button class="btn btn-primary" [disabled]="topoutORTrim() && !confirmWaterMeter && !isLoading || (newStatus === 'STC' && !imageAdded)  && !isLoading"
                (click)="submitQA()">Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!-- Submit modal-->

<!-- Image modal-->
<!--<ng-template #imageModal>-->
<!--  <div class="container pb-3">-->
<!--    <div class="row p-2">-->
<!--      <div class="col-10 offset-1 text-center">-->
<!--        <h2>Images for: {{wtnumber}} </h2>-->
<!--      </div>-->
<!--      <div class="col-1">-->
<!--        <button type="button" class="close" (click)="closeModal()">-->
<!--          <span><i class="fas fa-window-close"></i></span>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="text-center">** Images related to Scouting are handled under the 'Add Chipping Lines' button**</div>-->
<!--    <hr>-->
<!--    <ul class="list-group list-group-flush">-->
<!--      <li class="list-group-item border-0">-->
<!--        <div class="row mb-1" id="files">-->
<!--          <div class="col-sm-3 pt-2">-->
<!--            <div class="card text-center">-->
<!--              <label class="pt-2 mb-0">-->
<!--                <i class="fas fa-file-upload fa-4x text-info"></i>-->
<!--                <input type="file" (change)="fileChosen($event)" style="display: none;">-->
<!--              </label>-->
<!--              <small>Upload</small>-->
<!--              <ng-container *ngFor="let file of fileArray">-->
<!--                <br> <small> {{ file.name }}</small>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-sm-3 pt-2" *ngFor="let file of fileList">-->
<!--            <div class="card text-center">-->
<!--              <a class="btn p-0 pt-2" (click)="fileDownload(file.name)"><i class="fas fa-file-alt fa-4x"></i></a>-->
<!--              <small>{{ file.name }}</small>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </li>-->
<!--    </ul>-->
<!--    <hr>-->
<!--    &lt;!&ndash;    <div class="row mb-1">&ndash;&gt;-->
<!--    &lt;!&ndash;      <div class="col text-center">&ndash;&gt;-->
<!--    &lt;!&ndash;        <label>Is this image upload for chipping report?&ndash;&gt;-->
<!--    &lt;!&ndash;          <input type="checkbox" [(ngModel)]="chippingImage"></label>&ndash;&gt;-->
<!--    &lt;!&ndash;      </div>&ndash;&gt;-->
<!--    &lt;!&ndash;    </div>&ndash;&gt;-->
<!--    &lt;!&ndash;    <hr>&ndash;&gt;-->
<!--    <div class="row mb-1">-->
<!--      <div class="col-sm-6 text-center">-->
<!--        <button class="btn btn-secondary" (click)="closeModal()">Close</button>-->
<!--      </div>-->
<!--      <div class="col-sm-6 text-center">-->
<!--        <button class="btn btn-info float-right mr-2" (click)="fileUploadChip('N')" [disabled]="fileArray.length <= 0 ">-->
<!--          <span *ngIf="!fileLoading">Upload</span><span *ngIf="fileLoading">-->
<!--          <i class="fas fa-sync fa-spin"></i></span></button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->

<ng-template #imageModal>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>Images for: {{wtnumber}} </h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <div class="text-center">** Images related to Scouting are handled under the 'Add Chipping Lines' button**</div>
    <hr>
    <div class="container-fluid">
      <div class="row scout-button-container">
        <div>
          <label for="load-existing-images" class="btn btn-primary scout-img-button"
                 (click)="loadImagesForViewing()">Load Images</label>
        </div>
        <div>
          <label
            [class.disabled]="isLoading || isImageLoading"
            class="scout-modal-buttons btn btn-primary scout-img-button">
            {{ isImageLoading ? "Uploading" : "Upload Images" }}
            <input
              [disabled]="isLoading ? 'disabled' : ''"
              style="display: none;"
              accept="image/*"
              multiple="true"
              id="uploadBtn"
              type="file"
              (change)="onFileChangedW($event)"
            />
          </label>
          <div>
            <small *ngIf="fileNamesW.length !== 0">{{
              fileNamesW.join(" ")
              }}</small>
          </div>
          {{ imageUploadComplete ? "Selected Image Uploaded" : "" }}
        </div>
<!--        <div>-->
<!--          <button-->
<!--            [disabled]="isLoading"-->
<!--            class="scout-modal-buttons btn btn-primary scout-img-button"-->
<!--            (click)="loadImagesS3Solo()"-->
<!--            type="submit"-->
<!--            value="submit">-->
<!--            <img *ngIf="isLoading" src="../../../../assets/ring-alt.svg"/>-->
<!--            <span *ngIf="!isLoading">Submit</span>-->
<!--          </button>-->
<!--        </div>-->
        <div>
          <button type="button" class="btn btn-primary scout-img-button"
                  *ngIf="!displayScoutImages && imagesForViewing.length> 0"
                  (click)="displayScoutImages= !displayScoutImages">&#11207;
          </button>
          <button type="button" class="btn btn-primary scout-img-button"
                  (click)="displayScoutImages= !displayScoutImages" *ngIf="displayScoutImages">&#9660;
          </button>
        </div>
      </div>
      <div *ngIf="displayScoutImages" class="row scout-img-container" style="padding-top: 1rem;">
        <ng-container *ngFor="let img of imagesForViewing; let i= index">
          <div *ngIf="img.toLowerCase().slice(-3) === 'pdf'">
            <div class="row">
              <div class="col-sm-12">
                <a [href]="imagesForViewing[i]" target="_blank" class="" [innerHtml]="imageName(i)"></a>
              </div>
            </div>
          </div>
        </ng-container>
        <hr>
        <ng-container *ngFor="let img of imagesForViewing; let i= index">
          <div *ngIf="img.toLowerCase().slice(-3) !== 'pdf' && img.slice(-1) !== '/'">
            <img
              width="100%"
              height="100%"
              src="{{ img }}"
            />
          </div>
<!--          <div *ngIf="img.slice(-3) === 'pdf'">-->
<!--            <div class="row">-->
<!--              <div class="col-sm-12">-->
<!--                <a [href]="imagesForViewing[i]" target="_blank" class="" [innerHtml]="imageName(i)"></a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
<!-- Image modal-->
