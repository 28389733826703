<ng-container *ngIf="qe.quote==null">
  <div class="quoteview-loading">
    <mat-spinner
      [diameter]="80"
      class="quoteview-loading-spinner"
    ></mat-spinner>
  </div>
</ng-container>
<ng-container *ngIf="qe.quote!=null">
  <app-koqappbar
    [title]="qe.quote.data.Quote.Quote_Name"
    [saving]="appbarSaving"
    [description]="qe.quote.data.Quote.Quote_Desc"
    [partTotal]="qe.getTotalParts()"
    [costTotal]="qe.getTotalCost()"
    (onChange)="appbarSavingUntilComplete(qe.changeQuote({quoteChanges: $event}))"
    (onTotalHover)="sidepanelOpen=true"
  >
  </app-koqappbar>
  <app-quote-sidepanel
    *ngIf="qe.quote?.data?.Quote != null"
    [open]="sidepanelOpen"
    [saving]="appbarSaving"
    [quote]="qe.quote.data.Quote"
    [itemTotal]="qe.getTotalParts()"
    [costTotal]="qe.getTotalCost()"
    [rawCost]="qe.getRawCost()"
    [partCost]="qe.getRawMaterialCost()"
    [partTotal]="qe.getTotalMaterialParts()"
    [laborCost]="qe.getRawLaborCost()"
    [laborTotal]="qe.getTotalLaborParts()"
    (onChange)="appbarSavingUntilComplete(qe.changeQuote({quoteChanges: $event}))"
    (openChange)="sidepanelOpen=$event"
  ></app-quote-sidepanel>

  <div class="quoteview-progbarctn">
    <mat-progress-bar *ngIf="qe.isLoading()" mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="quoteview-header">
    <mat-tab-group
      [selectedIndex]="activatedRoute.snapshot.queryParamMap.get('tab')"
      (selectedIndexChange)="changeTab($event)"
      class="quoteview-header-breadcrumbs"
    >
      <mat-tab label="Build"></mat-tab>
      <mat-tab label="Slicer"></mat-tab>
      <mat-tab label="Audit Labor"></mat-tab>
      <mat-tab label="Audit Mat"></mat-tab>
      <mat-tab label="Specs"></mat-tab>
      <mat-tab label="Billing"></mat-tab>
      <mat-tab label="Exclusions"></mat-tab>
      <mat-tab label="Inclusions"></mat-tab>
      <mat-tab label="Options"></mat-tab>
      <mat-tab label="Summary"></mat-tab>
    </mat-tab-group>
    <div class="quoteview-header-preview">
      <a [routerLink]="'/quoting/preview-quote-pdf/' + qe.quote.data.Quote.Quote_guid" target="_blank">
        <button
          breadcrumb-end-content
          color="primary"
          mat-flat-button
          [disabled]="loadingPDF"
          class="quoteview-header-preview-btn"
        >
          Preview Bid
        </button>
      </a>
    </div>
  </div>

  <app-breadcrumb
    [renderHeader]="false"
    queryString="tab"
    [crumbs]="[
      {name: 'Build', template: buildSection},
      {name: 'Slicer', template: dataSlicerSection},
      {name: 'Audit Labor', template: laborBreakdownSection},
      {name: 'Audit Mat', template: partBreakdownSection},
      {name: 'Specs', template: notesSection},
      {name: 'Billing', template: billingSection},
      {name: 'Exclusions', template: exclusionsSection},
      {name: 'Inclusions', template: inclusionsSection},
      {name: 'Options', template: optionsSection},
      {name: 'Summary', template: finishingSection},
    ]"
    [forceRender]="[0]"
  >

    <ng-template #buildSection>
      <!-- The ngIf is there for performance reasons. Please leave it there. -->
      <div
        [class.quoteview-build-active]="activatedRoute.snapshot.queryParamMap.get('tab') == '0'"
        class="quoteview-bread quoting-theme"
      >
        <div class="quoteview-bread-add">
          <button long-press (onLongPress)="menuTrigger.openMenu()" (onLongPressMiss)="addSheetOpen=true" mat-fab  class="quoteview-bread-add-btn">
            <i class="fas fa-plus"></i>
            <button mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" [style]="{width: '0px', height: '0px'}"></button>
          </button>
          <!-- Yes, this is a very nasty hack. Blame angular material for not having a way to open a menu programmatically. -->
          <mat-menu #menu="matMenu">
            <button (click)="addSheetOpen=true" mat-menu-item>Add parts / kits</button>
            <button (click)="openAddCustomKitSheet(qe.quote)" mat-menu-item>Add a custom kit</button>
            <button (click)="openAddCustomPartSheet(qe.quote)" mat-menu-item>Add a custom part</button>
          </mat-menu>
        </div>

        <div class="quoteview-parts-list">
          <ng-container
            *ngIf="qe.quote != null"
          >
            <ng-container *ngFor="let part of qe.getParts(); let pIndex = index; trackBy : trackByKitPartGuid; let last = last">
              <app-quotekprow
                (onSave)="rowSaved($event);"
                (onOpenAddSheet)="openAddPartsSheet($event)"
                (onCustomKitSheet)="openAddCustomKitSheet($event)"
                (onCustomPartSheet)="openAddCustomPartSheet($event)"
                [qkp]="part"
              >
              </app-quotekprow>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="qe.quote != null"
          >
            <ng-container *ngFor="let kit of qe.getKits(); let skIndex = index; trackBy : trackByKitPartGuid">
              <app-quotekprow
                (onSave)="rowSaved($event);"
                (onOpenAddSheet)="openAddPartsSheet($event)"
                (onCustomKitSheet)="openAddCustomKitSheet($event)"
                (onCustomPartSheet)="openAddCustomPartSheet($event)"
                [qkp]="kit"
              >
              </app-quotekprow>
            </ng-container>
          </ng-container>
        </div>
        <div
          *ngIf="qe.getSelectedChildren().length > 0"
          class="quoteview-qkp-controls"
        >
          <button
            (click)="loadingUntilComplete(qe.destroySelectedChildren())"
            color="warn"
            mat-flat-button
            class="quoteview-qkp-controls-delete"
          >
            Delete
          </button>
          <button
            [disabled]="qe.getSelectedChildren().length != 1"
            (click)="openEditPartBotSheet()"
            color="primary"
            mat-flat-button
            class="quoteview-qkp-controls-delete"
          >
            Edit
          </button>
        </div>
        <app-editqpartbs
          *ngIf="qe.getSelectedChildren().length == 1"
          [qkp]="qe.getSelectedChildren()[0]"
          [open]="editPartBotSheetOpen"
          (partSaved)="editPartBotSheetOpen=false; cdr.detectChanges()"
          (sheetClosed)="editPartBotSheetOpen=false"
        >
        </app-editqpartbs>
      </div>
    </ng-template>

    <ng-template #dataSlicerSection>
      <!-- The ngIf is there for performance reasons. Please leave it there. -->
      <div *ngIf="activatedRoute.snapshot.queryParamMap.get('tab') == '1'" class="quoteview-bread quoting-theme">
        <app-quoteslicer [partGroups]="qe.getPartBreakdownGroups({tags: [], invert: true})"></app-quoteslicer>
      </div>
    </ng-template>

    <ng-template #laborBreakdownSection>
      <!-- The ngIf is there for performance reasons. Please leave it there. -->
      <div *ngIf="activatedRoute.snapshot.queryParamMap.get('tab') == '2'" class="quoteview-bread quoting-theme">
        <app-qpartbreakdown
          [partGroups]="qe.getPartBreakdownGroups({tags: ['Labor']})"
        ></app-qpartbreakdown>
      </div>
    </ng-template>

    <ng-template #partBreakdownSection>
      <!-- The ngIf is there for performance reasons. Please leave it there. -->
      <div *ngIf="activatedRoute.snapshot.queryParamMap.get('tab') == '3'" class="quoteview-bread quoting-theme">
        <app-qpartbreakdown
          [partGroups]="qe.getPartBreakdownGroups({tags: ['Labor'], invert: true})"
        ></app-qpartbreakdown>
      </div>
    </ng-template>

    <ng-template #notesSection>
      <!-- The ngIf is there for performance reasons. Please leave it there. -->
      <div *ngIf="activatedRoute.snapshot.queryParamMap.get('tab') == '4'" class="quoteview-bread quoting-theme">
        <div class="quoteview-note-list">
          <ng-container
            *ngFor="let note of qe.quote.notes; let eIndex = index; trackBy : trackByQuoteNoteGuid"
          >
            <app-qnote
              [note]="note"
              (onDelete)="loadingUntilComplete(qe.destroyQuoteNote({quoteNote: note}))"
            ></app-qnote>
          </ng-container>
        </div>
        <!-- I am too lazy to make a bottom sheet for this. Yes, it breaks with tradition. But it also enforces my tradition of being lazy, so checkmate liberal. -->
        <div class="quoteview-bread-add">
          <button
            (click)="qe.addQuoteNote({newNote: {QuoteNote_Name: 'Untitled', QuoteNote_Desc: ''}})"
            mat-fab
            class="quoteview-bread-add-btn"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #billingSection>
      <!-- The ngIf is there for performance reasons. Please leave it there. -->
      <div *ngIf="activatedRoute.snapshot.queryParamMap.get('tab') == '5'" class="quoteview-bread quoting-theme">
        <div class="quoteview-billing-list">
          <ng-container
            *ngFor="let bt of qe.quote.billingTerms; let eIndex = index; trackBy : trackByQuoteBillingTermGuid"
          >
            <app-qbillingterm
              [billingTerm]="bt"
            ></app-qbillingterm>
          </ng-container>
        </div>
        <!-- I am too lazy to make a bottom sheet for this. Yes, it breaks with tradition. But it also enforces my tradition of being lazy, so checkmate liberal. -->
        <div class="quoteview-bread-add">
          <button
            (click)="loadingUntilComplete(qe.addQuoteBillingTerm({quoteBillingTerm: {QuoteBillingTerm_Name: 'Untitled', QuoteBillingTerm_Desc: ''}}))"
            mat-fab
            class="quoteview-bread-add-btn"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #exclusionsSection>
      <!-- The ngIf is there for performance reasons. Please leave it there. -->
      <div *ngIf="activatedRoute.snapshot.queryParamMap.get('tab') == '6'" class="quoteview-bread quoting-theme">
        <div class="quoteview-exclu-list">
          <ng-container
            *ngFor="let exclusion of qe.quote.exclusions; let eIndex = index"
          >
            <app-qexclusion
              [exclusion]="exclusion"
            ></app-qexclusion>
          </ng-container>
        </div>
        <!-- I am too lazy to make a bottom sheet for this. Yes, it breaks with tradition. But it also enforces my tradition of being lazy, so checkmate liberal. -->
        <div class="quoteview-bread-add">
          <button
            (click)="loadingUntilComplete(qe.addQuoteExclusion({quoteExclusion: {QuoteExclusion_Name: 'Untitled', QuoteExclusion_Desc: ''}}))"
            mat-fab
            class="quoteview-bread-add-btn"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #inclusionsSection>
      <!-- The ngIf is there for performance reasons. Please leave it there. -->
      <div *ngIf="activatedRoute.snapshot.queryParamMap.get('tab') == '7'" class="quoteview-bread quoting-theme">
        <div class="quoteview-exclu-list">
          <ng-container
            *ngFor="let inclusion of qe.quote.inclusions; let eIndex = index"
          >
            <app-qinclusion
              [inclusion]="inclusion"
            ></app-qinclusion>
          </ng-container>
        </div>
        <!-- I am too lazy to make a bottom sheet for this. Yes, it breaks with tradition. But it also enforces my tradition of being lazy, so checkmate liberal. -->
        <div class="quoteview-bread-add">
          <button
            (click)="loadingUntilComplete(qe.addQuoteInclusion({quoteInclusion: {QuoteInclusion_Name: 'Untitled', QuoteInclusion_Desc: ''}}))"
            mat-fab
            class="quoteview-bread-add-btn"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #optionsSection>
      <!-- The ngIf is there for performance reasons. Please leave it there. -->
      <div *ngIf="activatedRoute.snapshot.queryParamMap.get('tab') == '8'" class="quoteview-bread quoting-theme">
        <div class="quoteview-options-list">
          <app-qoptions [options]="qe.quote.options"></app-qoptions>
          <!-- <ng-container
            *ngFor="let option of qe.quote.options; let eIndex = index"
          >
            <app-qoption [option]="option"></app-qoption>
          </ng-container> -->
        </div>
        <!-- I am too lazy to make a bottom sheet for this. Yes, it breaks with tradition. But it also enforces my tradition of being lazy, so checkmate liberal. -->
      </div>
    </ng-template>

    <ng-template #finishingSection>
      <!-- The ngIf is there for performance reasons. Please leave it there. -->
      <div *ngIf="activatedRoute.snapshot.queryParamMap.get('tab') == '9'" class="quoteview-bread">
        <div class="breadsection">
          <app-qvsummary
            (onQuoteChange)="appbarSavingUntilComplete(qe.changeQuote({quoteChanges: $event}))"
            [quote]="qe.quote.data.Quote"
            [itemTotal]="qe.getTotalParts()"
            [costTotal]="qe.getTotalCost()"
            [rawCost]="qe.getTotalPartCost()"
            [partCost]="qe.getPartBreakdownGroupsCost({tags: ['Labor'], invert: true})"
            [partTotalCost]="qe.getPartBreakdownGroupsCost({tags: ['Labor'], invert: true}) * qe.quote.data.Quote.Quote_MaterialMargin * qe.quote.data.Quote.Quote_GibsonMargin"
            [partTotal]="qe.getPartBreakdownGroupsTotalParts({tags: ['Labor'], invert: true})"
            [laborCost]="qe.getPartBreakdownGroupsCost({tags: ['Labor']})"
            [laborTotalCost]="qe.getPartBreakdownGroupsCost({tags: ['Labor']}) * qe.quote.data.Quote.Quote_MaterialMargin * qe.quote.data.Quote.Quote_GibsonMargin"
            [laborTotal]="qe.getPartBreakdownGroupsTotalParts({tags: ['Labor']})"
          ></app-qvsummary>
        </div>
      </div>
    </ng-template>
  </app-breadcrumb>

  <ng-container *ngIf="qe.quote != null">
    <app-korp-picker-botsheet
      [open]="addSheetOpen"
      [parent]="addBotSheetTarget"
      [existingQuoteParts]="addSheetOpen?qe.getQuoteParts():[]"
      (sheetClosed)="closeAddPartsSheet()"
      (addItems)="
        closeAddPartsSheet();
        loadingUntilComplete(qe.addToQuote({parentQuoteKitPart: addBotSheetTarget?.data, kitsAndPartsToAdd: $event}));
        addBotSheetTarget = null;
      "
      class="quoteview-add"
    >
    </app-korp-picker-botsheet>

    <app-customqkit-botsheet
      [open]="customKitSheetOpen"
      [parentQuoteOrKit]="customKitTarget?customKitTarget:qe.quote"
      (sheetClosed)="closeCustomKitSheet()"
      (addQuoteKit)="
        loadingUntilComplete(qe.addToQuote({parentQuoteKitPart: customKitTarget, kitsAndPartsToAdd: {parts:[], kits: [], customParts: [], customKits: [$event]}}));
        customKitSheetOpen=false;
      "
      class="quoteview-add"
    >
    </app-customqkit-botsheet>

    <app-customqpart-botsheet
      [open]="customPartSheetOpen"
      [parentQuoteOrKit]="customPartTarget?customPartTarget:qe.quote"
      (sheetClosed)="closeCustomPartSheet()"
      (addQuotePart)="
        loadingUntilComplete(qe.addToQuote({parentQuoteKitPart: customPartTarget, kitsAndPartsToAdd: {parts:[], kits: [], customParts: [$event], customKits: []}}));
        customPartSheetOpen=false;
      "
      class="quoteview-add"
    >
    </app-customqpart-botsheet>
  </ng-container>
</ng-container>
