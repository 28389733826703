<div class="newChangeOrder">
  <button  mat-raised-button  [disabled]="user.role.toLowerCase() != 'builder' && user.role.toLowerCase() != 'superadmin'" color="success"
class="newChangeOrder"routerLink="/Builder/ChangeOrder/Create">Create New
    Change Order</button>
</div>
<mat-card-content [hidden]="loading">

  <table mat-table [dataSource]="dataSource" [style]="{'width': '100%'}" matSort>

    <ng-container matColumnDef="BuilderName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Builder Name </th>
      <td mat-cell *matCellDef="let element"> {{ element.BuilderName }} </td>
    </ng-container>

    <ng-container matColumnDef="City">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> City </th>
      <td mat-cell *matCellDef="let element"> {{ element.City }} </td>
    </ng-container>

    <ng-container matColumnDef="ItemCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ItemCode </th>
      <td mat-cell *matCellDef="let element"> {{ element.ItemCode }} </td>
    </ng-container>

    <ng-container matColumnDef="Subdivision">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Subdivision </th>
      <td mat-cell *matCellDef="let element"> {{ element.Subdivision }} </td>
    </ng-container>

    <ng-container matColumnDef="DateOfChange">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Change </th>
      <td mat-cell *matCellDef="let element"> {{ element.DateOfChange | date: 'shortDate'}} </td>
    </ng-container>

    <ng-container matColumnDef="Warehouse">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Warehouse </th>
      <td mat-cell  [ngStyle]="{'text-align': 'center'}" *matCellDef="let element"> {{ element.Warehouse }} </td>
    </ng-container>

    <ng-container matColumnDef="Cost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cost </th>
      <td mat-cell [ngStyle]="{'text-align': 'center'}" *matCellDef="let element"> ${{ element.Cost.toFixed(2) }} </td>
    </ng-container>

    <ng-container matColumnDef="Reason">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Reason </th>
      <td mat-cell  *matCellDef="let element"> {{ element.Reason }} </td>
    </ng-container>

    <ng-container matColumnDef="SalesManagerApproval">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> SM Approval </th>
      <td mat-cell  [ngStyle]="{'text-align': 'center'}"  *matCellDef="let element"> {{ element.SalesManagerApproval }} </td>
    </ng-container>

    <ng-container matColumnDef="AreaManagerApproval">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> AM Approval </th>
      <td mat-cell *matCellDef="let element"> {{ element.AreaManagerApproval }} </td>
    </ng-container>

    <ng-container matColumnDef="Action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="changeOrderSelected(element); openModal(approveChangeOrder)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: true;"></tr>
    <tr mat-row *matRowDef="let row; columns: tableDisplayedColumns;"></tr>

  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

</mat-card-content>

<ng-template #approveChangeOrder>
  <div class="changeOrderSelected">
    <div *ngIf="selectedChangeOrder.SalesManagerApproval == undefined && selectedChangeOrder.AreaManagerApproval == undefined">
      <button mat-raised-button class="bg-primary text-light" (click)="editInformation()">Edit</button>
    </div>
    <div *ngIf="selectedChangeOrder.BuilderName != '' && selectedChangeOrder.BuilderName != undefined" class="line">
      <div>
        <b>Builder: </b>
      </div>
      <div>
        {{selectedChangeOrder.BuilderName}}
      </div>
    </div>
    <div *ngIf="selectedChangeOrder.Reason != '' && selectedChangeOrder.Reason != undefined" class="line">
      <div>
        <mat-label><b>Reason:</b></mat-label>
      </div>
      <div>
        {{selectedChangeOrder.Reason}}
      </div>
    </div>
    <div *ngIf="selectedChangeOrder.DateOfChange != undefined" class="line">
      <div>
        <b>
          Anticipated Date Change:
        </b>
      </div>
      <div>
        {{selectedChangeOrder.DateOfChange | date: 'shortDate'}}
      </div>
    </div>
    <div *ngIf="selectedChangeOrder.Subdivision != '' && selectedChangeOrder.Subdivision != undefined" class="line">
      <div>
        <b>
          Subdivision:
        </b>
      </div>
      <div>
        {{selectedChangeOrder.Subdivision}}
      </div>
    </div>
    <div *ngIf="selectedChangeOrder.City != '' && selectedChangeOrder.City != undefined" class="line">
      <div>
        <b>
          City:
        </b>
      </div>
      <div>
        {{selectedChangeOrder.City}}
      </div>
    </div>
    <div *ngIf="selectedChangeOrder.Margin != undefined" class="line">
      <div>
        <b>
          Margin:
        </b>
      </div>
      <div>
        {{selectedChangeOrder.Margin}}%
      </div>
    </div>
    <div *ngIf="selectedChangeOrder.Warehouse != undefined && selectedChangeOrder.Warehouse != ''" class="line">
      <div>
        <b>
          Warehouse
        </b>
      </div>
      <div>
        {{selectedChangeOrder.Warehouse}}
      </div>
    </div>
    <div *ngIf="selectedChangeOrder.Cost != undefined" class="line">
      <div>
        <b>
          Cost:
        </b>
      </div>
      <div>
        {{selectedChangeOrder.Cost}}
      </div>
    </div>
    <div class="line">
      <div>
        <b>
          Sales Manager Approval:
        </b>
      </div>
      <div>
        <button [disabled]="user.role.toLowerCase() != 'admin'" mat-raised-button color="success"
          *ngIf="selectedChangeOrder.SalesManagerApproval == undefined"
          (click)="ManagerApproval('SalesManager')">Approve</button>
        <b *ngIf="selectedChangeOrder.SalesManagerApproval != undefined">
          <span *ngIf="sending"> <i class="fas fa-sync fa-spin"></i></span>
          <span *ngIf="!sending">
            {{selectedChangeOrder.SalesManagerApproval}} - {{selectedChangeOrder.SalesManagerApprovalDate | date:
            'shortDate'}}
          </span>
        </b>
      </div>
    </div>
    <div class="line">
      <div><b>Area Manager Approval:</b>
      </div>
      <div>
        <button mat-raised-button color="success" [disabled]="user.role.toLowerCase()!= 'am'"
          (click)="ManagerApproval('AreaManager')"
          *ngIf="selectedChangeOrder.AreaManagerApproval == undefined">Approve</button>
        <b *ngIf="selectedChangeOrder.AreaManagerApproval != undefined">
          <span *ngIf="sending"><i class="fas fa-sync fa-spin"></i></span>
          <span *ngIf="!sending">
            {{selectedChangeOrder.AreaManagerApproval}} - {{selectedChangeOrder.AreaManagerApprovalDate | date:
            'shortDate'}}
          </span>
        </b>
      </div>
    </div>
  </div>
</ng-template>
