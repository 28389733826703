import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {concat} from 'rxjs';
import {AlertifyService} from '../../../_services/alertify/alertify.service';
import {SageApiService} from '../../../_services/sageApi/sageApi.service';
import {AuthService} from '../../../_services/auth/auth.service';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';

export interface Ticket{
  workTicketNumber: string,
  address: string,
  phase: string,
  tech: string,
  leadman: string,
  status: string,
  statusDate: string,
  salesOrderNumber: string,
  daysInStatus: number,
  trackingComment: string,
  scheduleDate: string,
  updated: boolean,
  submitted: boolean,
  displayLines: boolean,
  setC: boolean,
  setP: boolean,
  setQ: boolean,
  lines: Array<any>;
  areaManager: string,
}

@Component({
  selector: 'app-rework-queue',
  templateUrl: './rework-queue.component.html',
  styleUrls: ['./rework-queue.component.css']
})
export class ReworkQueueComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    "view",
    "workTicketNumber",
    "address",
    "phase",
    "scheduleDate",
    "tech",
    "leadman",
    "statusDate",
    "daysInStatus",
    "Action",
  ];
  dataSource: MatTableDataSource<Ticket>;
  loadingTicketLines: boolean;
  types = new FormControl();
  typeList: string[] = ["LW", "REC", "INS", "CLD"]
  areaManagers = new FormControl();
  areaManagerList: string[] =[];
  phases = new FormControl();
  phaseList: string[] = [];
  leadmen = new FormControl();
  leadmanList: string[] = [];
  techs = new FormControl();
  techList: string[] = [];

  isDataListLoading: boolean = false;
  lines = [];
  trackingComment: string;
  loading = false;
  isLoading: boolean;
  bothTickets: {
    workTicketNumber: string,
    address: string,
    phase: string,
    tech: string,
    leadman: string,
    status: string,
    statusDate: string,
    salesOrderNumber: string,
    daysInStatus: number,
    trackingComment: string,
    scheduleDate: string,
    updated: boolean,
    submitted: boolean,
    displayLines: boolean,
    setC: boolean,
    setP: boolean,
    setQ: boolean,
    lines: Array<any>;
    areaManager: string,
  }[] = [];
  BothTicketLines = [];
  submitTickets = [];
  importTemplateInfo = {
    templateName: '',
    template: '',
    step: ''
  };
  modalTemplate: BsModalRef;
  importTemplateIndex: string;
  modalTicket: object;
  modalStatus: string;
  newComment: string;
  waitingOnBuilder: boolean;
  // tslint:disable-next-line:max-line-length
  constructor(private sageApi: SageApiService, private authService: AuthService, private alertify: AlertifyService, private modalService: BsModalService) {
  }

  ngOnInit(): void {
    this.onGetTicketsBoth();
    this.onGetTicketLines();
    this.types.setValue(this.typeList)

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event){
    this.setupFilter("address")
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setupFilter(column: string) {
    this.dataSource.filterPredicate = (d: Ticket, filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  typeFilter(){
  console.log(this.types.value)
    const objs: Ticket[] = [];

      this.bothTickets.forEach(element => {
        if(this.types.value.some(x => x == element.status)){
          if(this.areaManagers.value.some(x => x == element.areaManager)){
            if(this.phases.value.some(x => x== element.phase.toUpperCase())){
              if(this.leadmen.value.some(x => x == element.leadman)){
                if(this.techs.value.some(x => x == element.tech.toUpperCase())){
                  objs.push(element)
                }
              }
            }
          }
        }
    })

  this.dataSource.data = objs;
  }


  onGetTicketsBoth() {
    this.bothTickets = [];
    this.isDataListLoading = true;
    const datePipe = new DatePipe('en-US');
    this.sageApi.pullReport("RECDashboard/Both").subscribe((list: Array<any>) => {
        if (Array.isArray(list)) {
          console.log(list)
          list.forEach((ticket) => {
            const obj: Ticket = {
              workTicketNumber: `${ticket.SalesOrderNo}-${ticket.WTNumber}`,
              address: ticket.ShipToName,
              phase: ticket.HdrParentItemCode.slice(1,`${ticket.phase}`.length).toLowerCase(),
              tech: ticket.Technician,
              leadman: ticket.UDF_LEADMAN,
              status: ticket.StatusCode,
              statusDate: datePipe.transform(ticket.StatusDate, 'longDate') || '1753-01-01',
              salesOrderNumber: ticket.SalesOrderNo,
              daysInStatus: ticket.DaysInREC,
              trackingComment: ticket.UDF_TRACKING_COMMENT,
              scheduleDate: datePipe.transform(ticket.UDF_DATE_SCHEDULED, 'longDate') || '1753-01-01',
              updated: false,
              submitted: false,
              displayLines: false,
              setC: false,
              setP: false,
              setQ: false,
              lines: [],
              areaManager: ticket.UDF_AREA_MANAGER
            };
            const scheduleDateWT = new Date(obj.scheduleDate);
            const scheduleDate = scheduleDateWT.toLocaleDateString();
            const date = new Date();
            const today = date.toLocaleDateString();
            if (obj.status !== 'PIW') {
              this.bothTickets.push(obj);
            }
          });
        } else {
          console.log('onGetTickets was a SINGLE OBJECT');
        }
      },
      (err) => {
        this.isDataListLoading = false;
        this.alertify.error(err.message);
      },
      () => {
        this.isDataListLoading = false;
        this.dataSource = new MatTableDataSource(this.bothTickets);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        console.log(this.dataSource)
        console.log(this.isDataListLoading);
        this.setAreaManagersAndPhases();
      }
    );
    console.log(this.bothTickets);
  }

  setAreaManagersAndPhases(){
    this.areaManagerList = [];
    this.phaseList = [];
    this.leadmanList = [];
    this.techList = [];
    this.bothTickets.forEach(element => {
      if(!this.areaManagerList.includes(element.areaManager)){
        this.areaManagerList.push(element.areaManager);
      }
      if(!this.phaseList.includes(element.phase.toUpperCase())){
        this.phaseList.push(element.phase.toUpperCase());
      }
      if(!this.leadmanList.includes(element.leadman)){
        this.leadmanList.push(element.leadman)
      }
      if(!this.techList.includes(element.tech.toUpperCase()))
        this.techList.push(element.tech.toUpperCase())
    });
    this.areaManagerList.sort();
    this.phaseList.sort();
    this.leadmanList.sort();
    this.techList.sort();
    this.areaManagers.setValue(this.areaManagerList);
    this.phases.setValue(this.phaseList);
    this.leadmen.setValue(this.leadmanList);
    this.techs.setValue(this.techList);
  }

  onGetTicketLines() {
    this.BothTicketLines = [];
    this.loadingTicketLines = true;
    this.sageApi.pullReport('RECDashboardLines').subscribe((lines: Array<any>) => {
      lines.forEach((line) => {
        this.BothTicketLines.push(line);
      });
      this.loadingTicketLines = false;
      console.log("finished loading")
    });
  }
  onKeyTech(e, column){
    if(column == "tech"){
      this.techList.filter(tech => tech.toLowerCase().includes(e));
    }
  }

  openModal(template: TemplateRef<any>, ticket: any, index, status) {
    this.importTemplateIndex = index;
    this.modalTicket = ticket;
    this.modalStatus = status;
    this.waitingOnBuilder = false;
    this.newComment = this.bothTickets[this.importTemplateIndex].trackingComment;
    this.importTemplateInfo = {
      templateName: '',
      template: '',
      step: ''
    };
    console.log(this.bothTickets[this.importTemplateIndex].trackingComment);
    this.modalTemplate = this.modalService.show(template);
  }

waitForLineHistory(ticket: Ticket){
  this.lines = [];
  this.BothTicketLines.forEach(item => {
    // tslint:disable-next-line:max-line-length
    if (`${item.SalesOrderNo}` === ticket.salesOrderNumber &&
      (item.ItemCode === '/QA' || item.ItemCode === '/PUNCH' || item.ItemCode === '/RIPUNCH'
      || item.ItemCode === '/TOP OUT - CHIPPING'|| item.ItemCode === 'ZPUNCH')) {
   this.lines.push(item)
    }
  });

}
checkForDetails(row:Ticket){
  if(this.loadingTicketLines == true){
    return false;
  }
  else{
    if(row.trackingComment != ""){
      return false;
    }
    else{
      return !this.BothTicketLines.some(x => x.SalesOrderNo == row.salesOrderNumber)
    }
  }
}

  openModalDetails(template: TemplateRef<any>, ticket: Ticket){
    console.log(ticket)
    this.trackingComment = "";
    this.waitForLineHistory(ticket);
    this.trackingComment = ticket.trackingComment
   if(this.lines.length == 0){
    this.modalTemplate = this.modalService.show(template)
   }else{
     this.modalTemplate = this.modalService.show(template,  {class: 'modal-lg'});
    }
    console.log(this.lines)
  }
  openSubmissionModal(template: TemplateRef<any>){
    this.modalTemplate = this.modalService.show(template);
  }

  closeModal() {
    this.modalTemplate.hide();
  }

  addToList(ticket, index, status, newComment) {
    const applyStatus = status;
    console.log('newComment: ' + this.newComment, 'modelComment: ' + newComment);
    // if (this.waitingOnBuilder){
    //   applyStatus = 'WOB';
    // }
    const tickArray = [ticket.workTicketNumber.slice(0, 7), ticket.workTicketNumber.slice(8),
      '000', newComment, applyStatus, index];
    if (ticket.updated) {
      const itemIndex = this.submitTickets.findIndex(item => (item[0] === tickArray[0] && item[1] === tickArray[1]));
      this.submitTickets.splice(itemIndex, 1);
      console.log('Removing', this.submitTickets);
      this.bothTickets[index].updated = false;
      if (status === 'COM') {
        this.bothTickets[index].setC = false;
        this.bothTickets[index].setP = false;
        this.bothTickets[index].setQ = false;
      } else if (status === 'PTL') {
        this.bothTickets[index].setC = false;
        this.bothTickets[index].setP = false;
        this.bothTickets[index].setQ = false;
      } else if (status === 'QAR') {
        this.bothTickets[index].setC = false;
        this.bothTickets[index].setP = false;
        this.bothTickets[index].setQ = false;
      }
    } else {
      this.submitTickets.push(tickArray);
      this.bothTickets[index].updated = true;
      if (status === 'COM') {
        this.bothTickets[index].setC = true;
        this.bothTickets[index].setP = false;
        this.bothTickets[index].setQ = false;
      } else if (status === 'PTL') {
        this.bothTickets[index].setC = false;
        this.bothTickets[index].setP = true;
        this.bothTickets[index].setQ = false;
      } else if (status === 'QAR') {
        this.bothTickets[index].setC = false;
        this.bothTickets[index].setP = false;
        this.bothTickets[index].setQ = true;
      }
      console.log('Adding', this.submitTickets);
      this.waitingOnBuilder = false;
      this.closeModal();
    }
  }

  submitList() {
    if (this.submitTickets.length == 0) {
      return;
    }
    this.loading = true;
    const observables = [];
    const body = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      ticketList: this.submitTickets
    };
    console.log(body, "updWTStatus/Group")
    observables.push(this.sageApi.putRequest('UpdWTStatus/Group', body));
    this.alertify.warning(`Updating Tickets`);
    concat(...observables).subscribe(response => {
      console.log(`put request`, response);
    }, error => {
      console.log(error);
      this.loading = false;
      this.alertify.error(`Error - ` + error);
    }, () => {
      this.submitTickets.forEach(item => {
        const index = item[5];
        this.bothTickets[index].submitted = true;
      });
    this.closeModal();
      this.loading = false;
      this.alertify.success(`Success!`);
      this.submitTickets = [];
      this.onGetTicketsBoth();
      this.onGetTicketLines();
    });
  }
}
