<div class="container">
  <div class="card border-1 m-3">
    <!--    Header-->
    <div class="card-header text-center">
      <div class="card-title text-danger" *ngIf="noTicket && !isLoading"><b>**INVALID TICKET\USER ID**</b></div>
      <div class="card-title text-success" *ngIf="noTicket && isLoading"><b>**LOADING TICKET**</b></div>
      <div class="card-title"><b>WT: {{soNum}}-{{wtNum}}</b></div>
      <div class="card-title"><b>Address: {{address}}</b></div>
<!--      <div class="card-title"><button (click)="openModal(QRImage)" >QR Code</button></div>-->
<!--      <div class="card-title"><qr-code value="{{soNum}}{{wtNum}}"></qr-code></div>-->
      <div class="card-title"><label class="mr-2">Language: </label>
        <button class="btn" (click)="engSpan === true ? engSpan = false : engSpan = true"
                [ngStyle]="{'background-color': engSpan ? 'red' : 'yellow', 'color': engSpan ? 'white' : 'black'}">
          <span *ngIf="!engSpan">Espanol</span>
          <span *ngIf="engSpan">English</span></button>
      </div>
      <div class="card-title text-warning">**Note: <span *ngIf="engSpan">Not all QA items will have a Spanish Translation</span>
        <span *ngIf="!engSpan">No todos los elementos de control de calidad tendrán una traducción al español</span>**
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-center">
        <!--        <button class="btn btn-primary" [disabled]="noTicket || isLoading" (click)="openModal(imageModal, true)" (click)="fileNames()">Upload Image</button>-->
        <div class="row m-0 p-0 justify-content-center">
          <div class="row m-0 p-0 justify-content-center">
            <button class="btn btn-primary" [disabled]="noTicket || isLoading"
                    (click)="openModal(imageModal); fileNamesFunction(); loadImagesForViewing()">Upload Image
            </button>
          </div>
          <!--          <div class="row m-2 p-0 justify-content-center">-->
          <!--            <button class="btn btn-primary" [disabled]="noTicket || isLoading" >Complete All QA Items</button>-->
          <!--          </div>-->
        </div>


        <!--        <button class="btn btn-primary mt-2" [disabled]="noTicket || isLoading" (click)="openModal(imageModal); fileNamesFunction(); loadImagesForViewing()">Upload Image</button>-->
      </div>
      <ng-container *ngIf="!mobile">
        <div class="row my-2">
          <div class="panel col">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                <tr>
                  <th class="text-center">QA ID</th>
                  <th class="text-center">Task</th>
                  <th class="text-center">Complete?</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let step of qaModel; let i= index">
                  <tr>
                    <!--                    <td *ngIf="step.isComplete !== 'Y'" class="text-center">-->
                    <td class="text-center">
                      {{step.itemCode.length > 3 ? step.itemCode.substring(3) : step.code}}
                    </td>
                    <!--                    <td *ngIf="step.isComplete !== 'Y'" class="text-center">-->
                    <td class="text-center">
                      <!--                      <div>{{step.itemCodeDesc}}</div>-->
                      <div>{{engSpan ? step.ICEnglish : step.ICSpanish}}</div>
                      <!--                      <textarea-->
                      <!--                        class="form-control"-->
                      <!--                        type="text"-->
                      <!--                        rows="4"-->
                      <!--                        maxlength="2000"-->
                      <!--                        [(ngModel)]="step.itemCodeDesc"-->
                      <!--                        (change)="step.updated= 'true'"-->
                      <!--                        disabled-->
                      <!--                      ></textarea>-->
                      <textarea
                        class="form-control mt-2"
                        type="text"
                        rows="4"
                        maxlength="2000"
                        *ngIf="step.isCompleteNew =='N'"
                        [(ngModel)]="step.notes"
                        (change)="step.updated= 'true'"></textarea>
                    </td>
                    <!--                    <td *ngIf="step.isComplete !== 'Y'" class="text-center">-->
                    <td class="text-center">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" [checked]="step.isCompleteNew =='Y'"
                               (change)="step.isCompleteNew = step.isCompleteNew != 'Y' ? 'Y' : 'N'"
                               (click)="checkStat(step.isCompleteNew)">
                      </div>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngFor="let step of qaAddModel; let i= index">
                  <tr>
                    <!--                    <td *ngIf="step.isComplete !== 'Y'" class="text-center">-->
                    <td class="text-center">
                      {{step.itemCode.length > 3 ? step.itemCode.substring(3) : step.code}}
                    </td>
                    <!--                    <td *ngIf="step.isComplete !== 'Y'" class="text-center">-->
                    <td class="text-center">
                      <textarea
                        class="form-control"
                        type="text"
                        rows="4"
                        maxlength="2000"
                        [(ngModel)]="step.itemCodeDesc"
                        (change)="step.updated= 'true'"
                      ></textarea>
                    </td>
                    <td class="text-center">
                      <div class="form-check row mb-5">
                        <input type="checkbox" class="form-check-input" [checked]="step.isCompleteNew == 'Y'"
                               (change)="step.isCompleteNew = step.isCompleteNew != 'Y' ? 'Y' : 'N'"
                               (click)="checkStat(step.isCompleteNew)">
                      </div>
                      <div class="form-check row mt-5 ml-0 mr-4">
                        <button class="btn btn-primary" (click)="RemoveQAList(i)"><i class="fas fa-times"></i></button>
                      </div>
                    </td>
                  </tr>
                </ng-container>
                <tr>
                  <!--                  <td></td>-->
                  <td colspan="3">
                    <div class="row m-0 p-0">
                      <div class="col-6"><label><h5><b>ADD QA Comments/ Items:</b></h5></label></div>
                      <div class="col ml-0 pl-0">
                        <button class="btn btn-primary" (click)="AddQAList()"><i class="fas fa-plus"></i></button>
                      </div>
                    </div>
<!--                    <div class="row m-2 p-0 justify-content-center">-->
<!--                      <button class="btn btn-primary" [disabled]="noTicket || isLoading" (click)="completeAllQA()">-->
<!--                        Complete All QA Items <i *ngIf="completeall" class="fas fa-check"></i></button>-->
<!--                    </div>-->
                  </td>
                  <!--                  <td></td>-->
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="mobile">
        <div class="row my-2">
          <div class="panel col">
            <div class="table-responsive">
              <table class="table table-hover">
                <tbody>
                <tr *ngFor="let step of qaModel; let i= index">
                  <div class="row mx-0 my-1 p-0">
                    <div class="col-4 text-center">
                      <b>QA ID:</b>
                    </div>
                    <div class="col-5">
                      <b>{{step.itemCode.length > 3 ? step.itemCode.substring(3) : step.code}}</b>
                    </div>
                  </div>
                  <div class="row mx-0 my-1 p-0">
                    <!--                      <label class="ml-3"><b>Task: </b><span>{{step.itemCodeDesc}}</span></label>-->
                    <label class="ml-3"><b>Task: </b><span>{{engSpan ? step.ICEnglish : step.ICSpanish}}</span></label>
                    <!--                      <textarea-->
                    <!--                        class="form-control"-->
                    <!--                        type="text"-->
                    <!--                        rows="4"-->
                    <!--                        maxlength="2000"-->
                    <!--                        [(ngModel)]="step.itemCodeDesc"-->
                    <!--                        (change)="step.updated= 'true'"-->
                    <!--                        disabled-->
                    <!--                      ></textarea>-->
                    <textarea
                      class="form-control mt-2"
                      type="text"
                      rows="4"
                      maxlength="2000"
                      *ngIf="step.isCompleteNew =='N'"
                      [(ngModel)]="step.notes"
                      (change)="step.updated= 'true'"></textarea>
                  </div>
                  <div class="row mx-0 my-1 p-0">
                    <div class="col-6 text-center">
                      <b>Complete?</b>
                    </div>
                    <div class="form-check col-2">
                      <input type="checkbox" class="form-check-input" [checked]="step.isCompleteNew =='Y'"
                             (change)="step.isCompleteNew = step.isCompleteNew != 'Y' ? 'Y' : 'N'"
                             (click)="checkStat(step.isCompleteNew)">
                    </div>
                  </div>
                  <hr class="rounded">
                </tr>
                <tr *ngFor="let step of qaAddModel; let i= index">
                  <div class="row mx-0 my-1 p-0">
                    <div class="col-4 text-center">
                      <b>QA ID:</b>
                    </div>
                    <div class="col-5">
                      <b>{{step.itemCode.length > 3 ? step.itemCode.substring(3) : step.code}}</b>
                    </div>
                  </div>
                  <div class="row mx-0 my-1 p-0">
                    <label class="ml-3"><b>Task:</b></label>
                    <textarea
                      class="form-control"
                      type="text"
                      rows="4"
                      maxlength="2000"
                      [(ngModel)]="step.itemCodeDesc"
                      (change)="step.updated= 'true'"></textarea>
                  </div>
                  <div class="row mx-0 my-1 p-0">
                    <div class="col-6 text-center">
                      <b>Complete?</b>
                    </div>
                    <div class="form-check col-2">
                      <div class="form-check row">
                        <input type="checkbox" class="form-check-input" [checked]="step.isCompleteNew == 'Y'"
                               (change)="step.isCompleteNew = step.isCompleteNew != 'Y' ? 'Y' : 'N'"
                               (click)="checkStat(step.isCompleteNew)">
                      </div>
                      <div class="form-check row ml-2">
                        <button class="btn btn-primary" (click)="RemoveQAList(i)"><i class="fas fa-times"></i></button>
                      </div>
                    </div>
                  </div>
                  <hr class="rounded">
                </tr>
                <tr>
                  <div class="row m-0 p-0">
                    <div class="col-6"><label><h5><b>ADD QA Comments/ Items:</b></h5></label></div>
                    <div class="col ml-3 pt-2 pl-0">
                      <button class="btn btn-primary" (click)="AddQAList()"><i class="fas fa-plus"></i></button>
                    </div>
                  </div>
<!--                  <div class="row m-2 p-0 justify-content-center">-->
<!--                    <button class="btn btn-primary" [disabled]="noTicket || isLoading" (click)="completeAllQA()">-->
<!--                      Complete All QA Items <i *ngIf="completeall" class="fas fa-check"></i></button>-->
<!--                  </div>-->
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="wtModel[0] !== undefined && (wtModel[0].parentLineCode.toLocaleLowerCase() == 'zroughin' ||
      wtModel[0].parentLineCode.toLocaleLowerCase() == 'zsewer' ||
      wtModel[0].parentLineCode.toLocaleLowerCase() == 'zwater')">
        <hr>
        <div class="text-center mt-3">
          <h5><b>Hammered Rock?</b></h5>
        </div>
        <div class="d-flex justify-content-center mt-1">
          <div class="p-2 text-center">
            <button class="btn-primary btn"
                    [disabled]="noTicket || isLoading || (wtModel[0] !== undefined &&
                  (wtModel[0].status.toLocaleLowerCase() !== 'rec' && wtModel[0].status.toLocaleLowerCase() !== 'lw'))"
                    (click)="rocksPresent = true">Yes <i *ngIf="rocksPresent" class="fas fa-check"></i></button>
<!--            <button class="btn-primary btn"-->
<!--                    (click)="rocksPresent = true">Yes <i *ngIf="rocksPresent" class="fas fa-check"></i></button>-->
          </div>
          <div class="p-2 text-center">
            <button class="btn-primary btn"
                    [disabled]="noTicket || isLoading || (wtModel[0] !== undefined &&
                  (wtModel[0].status.toLocaleLowerCase() !== 'rec' && wtModel[0].status.toLocaleLowerCase() !== 'lw'))"
                    (click)="rocksPresent = false">No <i *ngIf="!rocksPresent" class="fas fa-check"></i></button>
<!--            <button class="btn-primary btn"-->
<!--                    (click)="rocksPresent = false">No <i *ngIf="!rocksPresent" class="fas fa-check"></i></button>-->
          </div>
        </div>
        <ng-container *ngIf="rocksPresent">
          <div class="d-flex justify-content-center mt-1">
            <div class="p-2 text-center">
              <button class="btn-primary btn"
                      (click)="RocksAmount = RocksAmount - 1"><i class="fas fa-minus"></i></button>
            </div>
            <div class="p-2 mt-1 text-center">
              <input class="form-control form-control-sm" id="countorder" style="width: 50px;" type="number"
                     [(ngModel)]="RocksAmount" (click)="$event.target.select()"/>
            </div>
            <div class="py-2 px-0 mt-2 text-center">
              <b>FT</b>
            </div>
            <div class="p-2 text-center">
              <button class="btn-primary btn"
                      (click)="RocksAmount = RocksAmount + 1"><i class="fas fa-plus"></i></button>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-1">
            <button class="btn btn-primary" [disabled]="noTicket || isLoading"
                  (click)="RocksImageModal = true; openModal(imageModal); fileNamesFunction(); loadImagesForViewing()">Upload Image
            </button>
          </div>
        </ng-container>
      </ng-container>
      <hr>
      <div class="text-center mt-3">
        <h5><b>Ready for QA?</b></h5>
      </div>
      <div class="d-flex justify-content-center mt-1">
        <div class="p-2 text-center">
          <button class="btn-primary btn"
                  [disabled]="noTicket || isLoading || (wtModel[0] !== undefined &&
                  (wtModel[0].status.toLocaleLowerCase() !== 'rec' && wtModel[0].status.toLocaleLowerCase() !== 'lw'))"
                  (click)="readyForQA = true">Yes <i *ngIf="readyForQA" class="fas fa-check"></i></button>
<!--          <button class="btn-primary btn"-->
<!--                  (click)="readyForQA = true">Yes <i *ngIf="readyForQA" class="fas fa-check"></i></button>-->
        </div>
        <div class="p-2 text-center">
          <button class="btn-primary btn"
                  [disabled]="noTicket || isLoading || (wtModel[0] !== undefined &&
                  (wtModel[0].status.toLocaleLowerCase() !== 'rec' && wtModel[0].status.toLocaleLowerCase() !== 'lw'))"
                  (click)="readyForQA = false">No <i *ngIf="!readyForQA" class="fas fa-check"></i></button>
<!--          <button class="btn-primary btn"-->
<!--                  (click)="readyForQA = false">No <i *ngIf="!readyForQA" class="fas fa-check"></i></button>-->
        </div>
      </div>
      <div class="d-flex justify-content-center mt-1">
        <!--        <button class="btn-primary btn" [disabled]="noTicket || isLoading" (click)="openModal(QANsubmit)">Submit</button>-->
        <button class="btn-primary btn"
                [disabled]="(noTicket || isLoading || (wtModel[0] !== undefined &&
                (wtModel[0].status.toLocaleLowerCase() !== 'rec' && wtModel[0].status.toLocaleLowerCase() !== 'lw'
                && wtModel[0].status.toLocaleLowerCase() !== 'qan' && wtModel[0].status.toLocaleLowerCase() !== 'qar'))) || (rocksPresent && !RocksImageSubmitted)"
                (click)="submitQAItems()">Submit
        </button>
<!--        <button class="btn-primary btn"-->
<!--                (click)="submitQAItems()">Submit-->
<!--        </button>-->
      </div>
      <hr>
      <ng-container *ngIf="!mobile && wtModel[0] !== undefined">
        <div class="d-flex justify-content-center mt-2">
          <span><h5><b>Pay Week of {{payWeekStart | date:'MM/dd/yyyy'}} - {{payWeekEnd | date:'MM/dd/yyyy'}}
            :</b></h5></span>
        </div>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
            <tr>
              <th class="text-center">Address</th>
              <!--              <th class="text-center">Tech code</th>-->
              <th class="text-center">Phase</th>
              <th class="text-center">Status</th>
              <th class="text-center">Super</th>
              <!--              <ng-container *ngIf="wtModel[0].superEmail !== ''">-->
              <!--                <th class="text-center">Email</th>-->
              <!--              </ng-container>-->
              <ng-container *ngIf="showPhonePay">
                <th class="text-center">Phone #</th>
              </ng-container>
              <th class="text-center">Total Pay</th>
            </tr>
            </thead>
            <tbody class="text-center">
            <tr>
              <td>{{address}}</td>
              <!--              <td>{{wtModel[0].tech}}</td>-->
              <td>{{wtModel[0].itemCode.substring(1)}}</td>
              <td>{{wtModel[0].status}}</td>
              <td>{{wtModel[0].superI}}</td>
              <!--              <ng-container *ngIf="wtModel[0].superEmail !== ''">-->
              <!--                <td>{{wtModel[0].superEmail}}</td>-->
              <!--              </ng-container>-->
              <ng-container *ngIf="showPhonePay">
                <td>{{wtModel[0].superPhone}}</td>
              </ng-container>
              <td>
                <div><b>Open: ${{openTotal}}</b></div>
                <div><b>Approved: ${{apvTotal}}</b></div>
                <div class="mt-2"><h5><b>Total: ${{payTotal}}</b></h5></div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <b>Show Pay Week Tickets:</b>
                </div>
                <div></div>
              </td>
              <td>
                <button *ngIf="showOtherTickets"
                        (click)="showOtherTickets= !showOtherTickets" type="button"
                        class="btn btn-primary">▼
                </button>
                <button *ngIf="!showOtherTickets"
                        (click)="showOtherTickets= !showOtherTickets" type="button"
                        class="btn btn-primary">⯈
                </button>
              </td>
              <td></td>
              <td>
                <ng-container *ngIf="!showPhonePay && !showOtherTickets">
                  <div><b>Week's Open:</b></div>
                  <div><b>Week's Approved:</b></div>
                  <div class="mt-2"><h5><b>Week's Total:</b></h5></div>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="showPhonePay && !showOtherTickets">
                  <div><b>Open:</b></div>
                  <div><b>Approved:</b></div>
                  <div class="mt-2"><h5><b>Week's Total:</b></h5></div>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="!showOtherTickets">
                  <div><b>${{openWTotal}}</b></div>
                  <div><b>${{apvWTotal}}</b></div>
                  <div class="mt-2"><h5><b>${{weekTotal}}</b></h5></div>
                </ng-container>
              </td>
            </tr>
            <ng-container *ngIf="showOtherTickets">
              <ng-container *ngFor="let otherTickets of laborItems">
                <tr>
                  <td>{{otherTickets.lItems[0].address}}</td>
                  <!--              <td>{{wtModel[0].tech}}</td>-->
                  <td>{{otherTickets.lItems[0].parentLineCode.substring(1)}}</td>
                  <td>
                    <ng-container *ngIf="otherTickets.lItems[0].status.toLocaleLowerCase() !== 'rec'
                  && otherTickets.lItems[0].status.toLocaleLowerCase() !== 'lw'">
                      {{otherTickets.lItems[0].status}}
                    </ng-container>
                    <ng-container *ngIf="otherTickets.lItems[0].status.toLocaleLowerCase() === 'rec'
                  || otherTickets.lItems[0].status.toLocaleLowerCase() === 'lw'">
                      <button class="btn btn-primary" (click)="gotToWT(otherTickets)">
                        {{otherTickets.lItems[0].status}}
                      </button>
                    </ng-container>
                  </td>
                  <td>{{otherTickets.lItems[0].superI}}</td>
                  <!--              <ng-container *ngIf="wtModel[0].superEmail !== ''">-->
                  <!--                <td>{{wtModel[0].superEmail}}</td>-->
                  <!--              </ng-container>-->
                  <ng-container *ngIf="showPhonePay">
                    <td>{{otherTickets.lItems[0].superPhone}}</td>
                  </ng-container>
                  <td>
                    <div><b>Open: ${{otherTickets.openT}}</b></div>
                    <div><b>Approved: ${{otherTickets.apvT}}</b></div>
                    <div class="mt-2"><h5><b>Total: ${{otherTickets.tPay}}</b></h5></div>
                  </td>
                </tr>
              </ng-container>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <ng-container *ngIf="!showPhonePay && showOtherTickets">
                    <div><b>Open:</b></div>
                    <div><b>Approved:</b></div>
                    <div class="mt-2"><h5><b>Week's Total:</b></h5></div>
                  </ng-container>
                </td>
                <ng-container *ngIf="showPhonePay && showOtherTickets">
                  <td>
                    <div><b>Week's Open:</b></div>
                    <div><b>Week's Approved:</b></div>
                    <div class="mt-2"><h5><b>Week's Total:</b></h5></div>
                  </td>
                </ng-container>
                <td>
                  <ng-container *ngIf="showOtherTickets">
                    <div><b>${{openWTotal}}</b></div>
                    <div><b>${{apvWTotal}}</b></div>
                    <div class="mt-2"><h5><b>${{weekTotal}}</b></h5></div>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr>
              <td><h5><b>Open Tickets:</b></h5></td>
              <td>
                <button *ngIf="showOpenTickets"
                        (click)="showOpenTickets= !showOpenTickets" type="button"
                        class="btn btn-primary">▼
                </button>
                <button *ngIf="!showOpenTickets"
                        (click)="showOpenTickets= !showOpenTickets" type="button"
                        class="btn btn-primary">⯈
                </button>
              </td>
              <td></td>
              <td><h5><b># of Tickets: {{openItemsLength}}</b></h5></td>
              <td></td>
              <td></td>
            </tr>
            <ng-container *ngIf="showOpenTickets">
              <tr>
                <th class="text-center">Address</th>
                <!--              <th class="text-center">Tech code</th>-->
                <th class="text-center">Phase</th>
                <th class="text-center">Status</th>
                <th class="text-center">Super</th>
                <!--              <ng-container *ngIf="wtModel[0].superEmail !== ''">-->
                <!--                <th class="text-center">Email</th>-->
                <!--              </ng-container>-->
                <ng-container *ngIf="showPhoneOpen">
                  <th class="text-center">Phone #</th>
                </ng-container>
                <th class="text-center">Total Pay</th>
              </tr>
              <ng-container *ngFor="let otherTickets of OpenItems">
                <ng-container *ngIf="otherTickets.status == 'LW' || otherTickets.status == 'REC'">
                <tr>
                  <td>{{otherTickets.lItems[0].address}}</td>
                  <!--              <td>{{wtModel[0].tech}}</td>-->
                  <td>{{otherTickets.lItems[0].parentLineCode.substring(1)}}</td>
                  <td>
                    <ng-container *ngIf="otherTickets.status.toLocaleLowerCase() !== 'rec'
                  && otherTickets.status.toLocaleLowerCase() !== 'lw'">
                      {{otherTickets.status}}
                    </ng-container>
                    <ng-container *ngIf="otherTickets.status.toLocaleLowerCase() === 'rec'
                  || otherTickets.status.toLocaleLowerCase() === 'lw'">
                      <button class="btn btn-primary" (click)="gotToWT(otherTickets)">
                        {{otherTickets.status}}
                      </button>
                    </ng-container>
                  </td>
                  <td>{{otherTickets.lItems[0].superI}}</td>
                  <!--              <ng-container *ngIf="wtModel[0].superEmail !== ''">-->
                  <!--                <td>{{wtModel[0].superEmail}}</td>-->
                  <!--              </ng-container>-->
                  <ng-container *ngIf="showPhoneOpen">
                    <td>{{otherTickets.lItems[0].superPhone}}</td>
                  </ng-container>
                  <td>
                    <div><b>Open: ${{otherTickets.openT}}</b></div>
                    <div><b>Approved: ${{otherTickets.apvT}}</b></div>
                    <div class="mt-2"><h5><b>Total: ${{otherTickets.tPay}}</b></h5></div>
                  </td>
                </tr>
                </ng-container>
              </ng-container>
            </ng-container>
            </tbody>
          </table>
        </div>
        <!--        <div class="d-flex justify-content-center mt-2">-->
        <!--          <h5><b>Open WTs:</b></h5>-->
        <!--        </div>-->

      </ng-container>
      <ng-container *ngIf="mobile && wtModel[0] !== undefined">
        <div class="d-flex justify-content-center mt-2">
          <h5><b>Pay:</b></h5>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <span><b>Address:</b> {{address}}</span>
        </div>
        <!--      <div class="d-flex justify-content-center mt-2">-->
        <!--        <span><b>Tech:</b> {{wtModel[0].tech}}</span>-->
        <!--      </div>-->
        <div class="d-flex justify-content-center mt-2">
          <span><b>Phase:</b> {{wtModel[0].itemCode.substring(1)}}</span>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <span><b>Status:</b> {{wtModel[0].status}}</span>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <span><b>Super:</b> {{wtModel[0].superI}}</span>
        </div>
        <!--      <ng-container *ngIf="wtModel[0].superEmail !== ''">-->
        <!--        <div class="d-flex justify-content-center mt-2">-->
        <!--          <span><b>email:</b> {{wtModel[0].superEmail}}</span>-->
        <!--        </div>-->
        <!--      </ng-container>-->
        <ng-container *ngIf="showPhonePay">
          <div class="d-flex justify-content-center mt-2">
            <span><b>Phone #:</b> {{wtModel[0].superPhone}}</span>
          </div>
        </ng-container>
        <div class="d-flex justify-content-center mt-2">
          <span><b>Open total: ${{openTotal}}</b></span>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <span><b>Approved total: ${{apvTotal}}</b></span>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <span><h5><b>WT Total: ${{payTotal}}</b></h5></span>
        </div>
        <hr>
        <div class="d-flex justify-content-center mt-2">
          <h5><b>Pay Week of {{payWeekStart | date:'MM/dd/yyyy'}} - {{payWeekEnd | date:'MM/dd/yyyy'}}:</b></h5>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <span><b>Show Pay Week Tickets: </b>
          <button *ngIf="showOtherTickets"
                  (click)="showOtherTickets= !showOtherTickets" type="button"
                  class="btn btn-primary">▼
          </button>
          <button *ngIf="!showOtherTickets"
                  (click)="showOtherTickets= !showOtherTickets" type="button"
                  class="btn btn-primary">⯇
          </button></span>
        </div>
        <ng-container *ngIf="showOtherTickets">
          <ng-container *ngFor="let otherTickets of laborItems">
            <hr>
            <div>
              <div class="d-flex justify-content-center mt-2">
                <span><b>Address:</b> {{otherTickets.lItems[0].address}}</span>
              </div>
              <!--      <div class="d-flex justify-content-center mt-2">-->
              <!--        <span><b>Tech:</b> {{wtModel[0].tech}}</span>-->
              <!--      </div>-->
              <div class="d-flex justify-content-center mt-2">
                <span><b>Phase:</b> {{otherTickets.lItems[0].parentLineCode.substring(1)}}</span>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <span><b>Status:</b> <ng-container *ngIf="otherTickets.lItems[0].status.toLocaleLowerCase() !== 'rec'
                  && otherTickets.lItems[0].status.toLocaleLowerCase() !== 'lw'">
                    {{otherTickets.lItems[0].status}}
                  </ng-container>
                  <ng-container *ngIf="otherTickets.lItems[0].status.toLocaleLowerCase() === 'rec'
                  || otherTickets.lItems[0].status.toLocaleLowerCase() === 'lw'">
                    <button class="btn btn-primary" (click)="gotToWT(otherTickets)">
                      {{otherTickets.lItems[0].status}}
                    </button>
                  </ng-container>
                </span>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <span><b>Super:</b> {{otherTickets.lItems[0].superI}}</span>
              </div>
              <!--      <ng-container *ngIf="wtModel[0].superEmail !== ''">-->
              <!--        <div class="d-flex justify-content-center mt-2">-->
              <!--          <span><b>email:</b> {{wtModel[0].superEmail}}</span>-->
              <!--        </div>-->
              <!--      </ng-container>-->
              <ng-container *ngIf="showPhonePay">
                <div class="d-flex justify-content-center mt-2">
                  <span><b>Phone #:</b> {{otherTickets.lItems[0].superPhone}}</span>
                </div>
              </ng-container>
              <div class="d-flex justify-content-center mt-2">
                <span><b>Open Total: ${{otherTickets.openT}}</b></span>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <span><b>Approved Total: ${{otherTickets.apvT}}</b></span>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <span><h5><b>WT Total: ${{otherTickets.tPay}}</b></h5></span>
              </div>

            </div>
          </ng-container>
        </ng-container>
        <hr>
        <div class="d-flex justify-content-center mt-2">
          <span><b>Week's Open Total: ${{openWTotal}}</b></span>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <span><b>Week's Approved Total: ${{apvWTotal}}</b></span>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <span><h5><b>Week's Total: ${{weekTotal}}</b></h5></span>
        </div>
        <hr>
        <div class="d-flex justify-content-center mt-2">
          <span><b>Open Tickets: </b>
          <button *ngIf="showOpenTickets"
                  (click)="showOpenTickets= !showOpenTickets" type="button"
                  class="btn btn-primary">▼
          </button>
          <button *ngIf="!showOpenTickets"
                  (click)="showOpenTickets= !showOpenTickets" type="button"
                  class="btn btn-primary">⯇
          </button></span>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <span><b># of Tickets: {{OpenItems.length}}</b></span>
        </div>
        <ng-container *ngIf="showOpenTickets">
          <ng-container *ngFor="let otherTickets of OpenItems">
            <hr>
            <div>
              <div class="d-flex justify-content-center mt-2">
                <span><b>Address:</b> {{otherTickets.lItems[0].address}}</span>
              </div>
              <!--      <div class="d-flex justify-content-center mt-2">-->
              <!--        <span><b>Tech:</b> {{wtModel[0].tech}}</span>-->
              <!--      </div>-->
              <div class="d-flex justify-content-center mt-2">
                <span><b>Phase:</b> {{otherTickets.lItems[0].parentLineCode.substring(1)}}</span>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <span><b>Status:</b> <ng-container *ngIf="otherTickets.lItems[0].status.toLocaleLowerCase() !== 'rec'
                  && otherTickets.lItems[0].status.toLocaleLowerCase() !== 'lw'">
                    {{otherTickets.lItems[0].status}}
                  </ng-container>
                  <ng-container *ngIf="otherTickets.lItems[0].status.toLocaleLowerCase() === 'rec'
                  || otherTickets.lItems[0].status.toLocaleLowerCase() === 'lw'">
                    <button class="btn btn-primary" (click)="gotToWT(otherTickets)">
                      {{otherTickets.lItems[0].status}}
                    </button>
                  </ng-container>
                </span>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <span><b>Super:</b> {{otherTickets.lItems[0].superI}}</span>
              </div>
              <!--      <ng-container *ngIf="wtModel[0].superEmail !== ''">-->
              <!--        <div class="d-flex justify-content-center mt-2">-->
              <!--          <span><b>email:</b> {{wtModel[0].superEmail}}</span>-->
              <!--        </div>-->
              <!--      </ng-container>-->
              <ng-container *ngIf="showPhonePay">
                <div class="d-flex justify-content-center mt-2">
                  <span><b>Phone #:</b> {{otherTickets.lItems[0].superPhone}}</span>
                </div>
              </ng-container>
              <div class="d-flex justify-content-center mt-2">
                <span><b>Open Total: ${{otherTickets.openT}}</b></span>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <span><b>Approved Total: ${{otherTickets.apvT}}</b></span>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <span><h5><b>WT Total: ${{otherTickets.tPay}}</b></h5></span>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

    </div>
  </div>
</div>

<!-- Image modal-->
<!--<ng-template #imageModal>-->
<!--  <div class="container pb-3">-->
<!--    <div class="row p-2">-->
<!--      <div class="col-10 offset-1 text-center">-->
<!--        <h2>File Contents for {{ address | titlecase}}</h2>-->
<!--      </div>-->
<!--      <div class="col-1">-->
<!--        <button type="button" class="close" (click)="closeModal()">-->
<!--          <span><i class="fas fa-window-close"></i></span>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="row mb-1" id="files">-->
<!--      <div class="col-sm-3 pt-2">-->
<!--        <div class="card text-center">-->
<!--          <label class="pt-2 mb-0">-->
<!--            <i class="fas fa-file-upload fa-4x text-info"></i>-->
<!--&lt;!&ndash;            <input type="file" (change)="fileChosen($event)" style="display: none;">&ndash;&gt;-->
<!--            <input type="file" (change)="onFileChanged($event)" style="display: none;">-->
<!--          </label>-->
<!--          <small>Upload</small>-->
<!--          <ng-container *ngFor="let file of fileArray">-->
<!--            <br> <small> {{ file.name }}</small>-->
<!--          </ng-container>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-sm-3 pt-2" *ngFor="let file of fileList">-->
<!--        <div class="card text-center">-->
<!--&lt;!&ndash;          <a class="btn p-0 pt-2" (click)="fileDownload(file.name)"><i class="fas fa-file-alt fa-4x"></i></a>&ndash;&gt;-->
<!--          <a class="btn p-0 pt-2"><i class="fas fa-file-alt fa-4x"></i></a>-->
<!--          <small>{{ file.name }}</small>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row">-->
<!--      <hr>-->
<!--      <ng-container *ngFor="let img of imagesForViewing; let i= index">-->
<!--        <div *ngIf="img.slice(-3) !== 'pdf'">-->
<!--          <img-->
<!--            width="100%"-->
<!--            height="100%"-->
<!--            src="{{ img }}"-->
<!--          />-->
<!--        </div>-->
<!--        <div *ngIf="img.slice(-3) === 'pdf'">-->
<!--          <div class="row">-->
<!--            <div class="col-sm-12">-->
<!--              <a [href]="imageArr[i]" target="_blank" class="" [innerHtml]="imageName(i)"></a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </ng-container>-->
<!--    </div>-->

<!--    <form>-->
<!--      <div class="form-row pt-2">-->
<!--        <div class="col">-->
<!--          <button class="btn btn-primary float-right" (click)="closeModal()">Close</button>-->
<!--          <button class="btn btn-info float-right mr-2" (click)="fileUpload()" *ngIf="fileArray.length > 0 "><span *ngIf="!fileLoading">Upload</span><span *ngIf="fileLoading"><i class="fas fa-sync fa-spin"></i></span></button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </form>-->
<!--  </div>-->
<!--</ng-template>-->

<ng-template #imageModal>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>File Contents for {{ address | titlecase}}</h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <hr>
    <div class="container-fluid">
      <div class="row scout-button-container">
        <div class="ml-2">
          <label for="load-existing-images" class="btn btn-primary scout-img-button"
                 (click)="loadImagesForViewing()">Load Images</label>
        </div>
        <div class="ml-2">
          <label
            [class.disabled]="isLoading || isImageLoading"
            class="scout-modal-buttons btn btn-primary scout-img-button">
            {{ isImageLoading ? "Uploading" : "Upload Images" }}
            <input
              [disabled]="isLoading ? 'disabled' : ''"
              style="display: none;"
              accept="image/*"
              multiple="true"
              id="uploadBtn"
              type="file"
              (change)="onFileChangedW($event)"
            />
          </label>
          <div class="ml-2">
            <small *ngIf="fileNames.length !== 0">{{
              fileNames.join(" ")
              }}</small>
          </div>
          {{ imageUploadComplete ? "Selected Image Uploaded" : "" }}
        </div>
        <div class="ml-2">
          <button
            [disabled]="isLoading"
            class="scout-modal-buttons btn btn-primary scout-img-button"
            (click)="loadImagesS3()"
            type="submit"
            value="submit">
            <img *ngIf="isLoading" src="../../../../assets/ring-alt.svg"/>
            <span *ngIf="!isLoading">Submit</span>
          </button>
        </div>
        <div class="ml-2">
          <button type="button" class="btn btn-primary scout-img-button"
                  *ngIf="!displayScoutImages && imagesForViewing.length> 0"
                  (click)="displayScoutImages= !displayScoutImages">&#11207;
          </button>
          <button type="button" class="btn btn-primary scout-img-button"
                  (click)="displayScoutImages= !displayScoutImages" *ngIf="displayScoutImages">&#9660;
          </button>
        </div>
      </div>
      <div *ngIf="displayScoutImages" class="row scout-img-container" style="padding-top: 1rem;">
        <ng-container *ngFor="let img of imagesForViewing; let i= index">
          <div *ngIf="img.toLowerCase().slice(-3) !== 'pdf'">
            <img
              width="100%"
              height="100%"
              src="{{ img }}"
            />
          </div>
          <div *ngIf="img.toLowerCase().slice(-3) === 'pdf'">
            <div class="row">
              <div class="col-sm-12">
                <a [href]="imagesForViewing[i]" target="_blank" class="" [innerHtml]="imageName(i)"></a>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
<!-- Image modal-->

<ng-template #QRImage>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>{{ address | titlecase}}</h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <hr>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <qr-code value="{{soNum}}{{wtNum}}"></qr-code>
      </div>
    </div>
  </div>
</ng-template>

<!-- submit modal -->
<ng-template #QANsubmit>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2> Confirm? </h2>
        <h5></h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <div class="row mb-1" id="">
      <div class="col col-sm-6 text-center">
        <button class="btn btn-primary" (click)="submitQAItems()">Yes</button>
      </div>
      <div class="col col-sm-6 text-center">
        <button class="btn btn-primary" (click)="closeModal()">No</button>
      </div>
    </div>
  </div>
</ng-template>
