<mat-form-field appearance="outline" [class]="ffClass" class="seasel">
    <mat-label>{{placeholder}}</mat-label>
    <mat-select *ngIf="!useNativeControl" #searchableSelect [formControl]="selectedOption" [placeholder]="placeholder" (selectionChange)="onSelChange()"
        [class]="selClass" class="seasel-sel">
        <input matInput [formControl]="inputControl" (input)="onSearch($event.target.value, $event)"
            (keydown)="$event.stopPropagation()" [placeholder]="placeholder" class="seasel-sel-in">
        <mat-divider></mat-divider>
        <mat-option *ngIf="!required" [value]="" [class.hidden]="!showEmpty" class="seasel-sel-op none">None</mat-option>
        <mat-divider *ngIf="!required && showEmpty"></mat-divider>
        <mat-option *ngFor="let op of options" [value]="op" [class.hidden]="isThisOptionHidden(op)" [class.empty]="op==''" class="seasel-sel-op">{{op!=''?op:'Empty'}}</mat-option>
    </mat-select>
    <select *ngIf="useNativeControl" [formControl]="selectedOption" [placeholder]="placeholder" (selectionChange)="onSelChange()" multiple matNativeControl class="seasel-sel seasel-sel-native">
        <option *ngFor="let op of options" [value]="op.valueOf()" [class.hidden]="isThisOptionHidden(op.toString())" [class.empty]="op.valueOf()==''" class="seasel-sel-op">{{op.valueOf()!=''?op.toString():'Empty'}}</option>
    </select>
</mat-form-field>