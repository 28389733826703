<div class="container pb-5">
  <div class="card my-5 shadow-lg text-center" id="noprint">
    <h1>Service and Warranty OTC</h1>

    <div class="col-12 pt-2 text-center" *ngIf="jobNumber == ''">
      <label for="address">Scan WT Code</label>
      <div class="col-12">
        <input
        class="form-control input m-2"
        #input
        [(ngModel)]="JobScanner"
        (keyup.enter)="pullMaterial(JobScanner)"
        placeholder="Scan QR or WT BarCode"
        autocomplete="off"
        />
      </div>
    </div>

    <div class="card-body border-0 justify-content-center" *ngIf="jobNumber !== ''">
      <div class="row justify-content-center" id="itemAdd">
        <ng-container >
          <div class="text-center mt-3">
            <h5>
              <b>Job #: {{ jobNumber }}</b>
            </h5>
          </div>
        <div class="justify-content-center mt-1">
          <ng-container *ngFor="let item of ItemList; let index = index">
            <div class="col-12 m-0 p-0" id="wtsList" >
              <div class="justify-content-center mt-1 p-2" >
                  <input
                    class="form-control input"
                    #input
                    [(ngModel)]="item.Name"
                    (keyup.enter)="itemSelectedNew(item.Name, index)"
                    typeaheadOptionField="ItemCode"
                    placeholder="ItemCode"
                    autocomplete="off"
                  />
                  <!--[disabled]="restrictUser && index !== itemList.length-1"-->
                  <!--[typeahead]="filtereditems" (typeaheadOnSelect)="itemSelectedNew($event.item.ItemCode, index, job)"-->
            </div>
                <div class="d-flex justify-content-center mt-1" >
                  <div class="p-2 text-center">
                    <button
                      class="btn-primary btn"
                      (click)="
                        item.QtyShipped =
                          item.QtyShipped > 0
                            ? item.QtyShipped - 1
                            : 0">
                      <i class="fas fa-minus"></i>
                    </button>
                  </div>
                  <div class="p-2 mt-1 text-center">
                    <input
                      class="form-control form-control-sm"
                      id="countLine"
                      style="width: 50px"
                      type="number"
                      [(ngModel)]="item.QtyShipped"
                      (click)="$event.target.select()"
                    />
                  </div>
                  <div class="py-2 px-0 mt-2 text-center">
                    <b>QTY</b>
                  </div>
                  <div class="p-2 text-center">
                    <button
                      class="btn-primary btn"
                      (click)="
                        item.QtyShipped =
                          item.QtyShipped >= 0
                            ? item.QtyShipped + 1
                            : 0"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
                  <div class="p-2 text-center" *ngIf="ItemList.length > 1">
                    <button mat-raised-button  color="danger" (click)="DeleteItem(item)"><i class="fa fa-trash"></i></button>
                  </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <button mat-raised-button color="danger" (click)="submitList()">Submit Changes</button>
    </div>
  </div>
</div>
