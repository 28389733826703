import {
  Component,
  Input,
  OnInit,
  HostListener,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import * as Rx from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
declare var $: any;
declare var require;
import { concat, Subscription } from 'rxjs';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { tick } from '@angular/core/testing';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { any } from 'codelyzer/util/function';
import { tryCatch } from 'rxjs/internal-compatibility';
import { WasabiApiService } from '../../../_services/wasabiApi/wasabiApi.service';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-vpo-punch',
  templateUrl: './vpo-punch.component.html',
  styleUrls: ['./vpo-punch.component.css']
})
// tslint:disable-next-line:class-name
export class vpoPunchComponent implements OnInit {
  showBackCharge: boolean = true;
  modalTemplate: BsModalRef;
  Description = '';
  address = '';
  addressSelected = false;
  addressLoaded = false;
  addressLoading: boolean;
  payAmount = 0;
  backchargeAmount = 0;
  BillingAmount = 0;
  PONum = '';
  isLoadingTech: boolean;
  isLoadingLead: boolean;
  techLoaded = false;
  techCode: any;
  searchTech: any;
  searchLead: any;
  searchHelper: any;
  loadingStatus = '';
  materialNeeded: boolean | string = false;
  materialCheck = 'N';
  materialNeededDate = '';
  materialNeededItems: Array<any> = [];
  materialNeededQty = 1;
  LaborNeededQty = 1;
  itemCodesList: Array<any> = [{ code: 'MTL181', desc: 'mtl item 1' }, { code: 'MTL182', desc: 'mtl item 2' }];
  itemCodeSearchText = '';
  searchHitsItems: any = [];
  LaborHitsItems: any = [];
  punchSpecMaterialItems: any = [];
  punchSpecLaborItems: any = [];
  isLoading = false;
  isError = false;
  dateScheduled = '';
  showSecondMatLine = false;
  showThirdMatLine = false;
  showFourthMatLine = false;
  showFifthMatLine = false;
  showSixthMatLine = false;
  showSeventhMatLine = false;
  showEighthMatLine = false;
  showNinethMatLine = false;
  showTenthMatLine = false;
  lineOneMatQty = '  ';
  lineTwoMatQty = '  ';
  lineThreeMatQty = '  ';
  lineFourMatQty = '  ';
  lineFiveMatQty = '  ';
  lineSixMatQty = '  ';
  lineSevenMatQty = '  ';
  lineEightMatQty = '  ';
  lineNineMatQty = '  ';
  lineTenMatQty = '  ';
  lineOneMatDesc = ' ';
  lineTwoMatDesc = ' ';
  lineThreeMatDesc = ' ';
  lineFourMatDesc = ' ';
  lineFiveMatDesc = ' ';
  lineSixMatDesc = ' ';
  lineSevenMatDesc = ' ';
  lineEightMatDesc = ' ';
  lineNineMatDesc = ' ';
  lineTenMatDesc = ' ';
  nextDate: string;
  salesorder = '';
  dataModel: [];
  techsLoad: Array<any>;
  techsLoadSelect: string[] = [];
  punchLoadSelect: string[] = [];
  punchWarLoadSelect: string[] = [];
  leadsLoad: Array<any>;
  loadedAddresses: Array<any>;
  filteredAddresses: Array<any>;
  serviceCodes: Array<any>;
  // warrantyCodes: Array<any>;
  qaItemsForPunch: Array<any>;
  wts: Array<any>;
  backchargewts: Array<any>;

  punchLoadingStatus = '';
  backchargeLoadingStatus = '';
  tableLoaded = false;
  // *In relation to the work
  statuscode = 'PUN';
  workAlreadyComplete = false;
  // *In relation to the whole ticket
  ticketStatus = '';
  internalTech = '';
  usercode = '';
  leadcode = '';
  phase = 'ROUGHIN';
  ticketType = 'punch';
  vpoWho = 'CREW';
  crewBackCharge = false;
  builderBackCharge = false;
  noBackchargeNeeded = false;
  vpoNotes = '';
  combinedVPO = '';
  builderContactName = '';
  builderContactEmail1 = 'no email provided';
  builderContactEmail2 = 'no email provided';
  builderContactEmail3 = 'no email provided';
  builderReplace = false;
  submitButtonDisabled: boolean;
  filteredServiceCodes: Array<any> = [];
  selectedServiceCodes: Array<any> = [];
  // selectedWarrantyCodes: Array<any> = [];
  serviceCodeSearchText = '';
  showPunchHistory = true;
  showCommentHistory = true;
  showCurrentQA = true;
  // * Images
  imageUploadComplete = false;
  isImageLoading = false;
  imageArr: Array<any> = [];
  displayImagesArr: any = {};
  fileNames: Array<string> = [];
  imgLoaded = false;
  modalImgUrl: string;
  randomImageKey: string;
  fileArray = [];
  fileList = [];
  fileLoading = false;
  ZeroPartsPrice = false;

  AssigenTechContrl = new FormControl('', []);
  AssigenPunchContrl = new FormControl('', []);
  // AssigenWarrantyContrl = new FormControl('', []);

  itemList = [
    {
      name: 'S711182000 - vikrell tub',
      itemCode: 'S711182000',
      description: 'vikrell tub',
      UnitCost: 0,
      kit: 'N'
    },
  ];
  kits = [];
  warehouse = '';
  subdivisionList = [];

  userrole = this.authService.decodedToken.role;

  @ViewChild('submitButton') submitButton: ElementRef;
  @ViewChild('imgModal') imgModal;
  @ViewChild('materialModal') materialModal;
  locationCode: string;

  constructor(
    private router: Router,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private alertify: AlertifyService,
    private wasabiApi: WasabiApiService,
  ) { }


  ngOnInit() {
    this.techsLoad = [];
    this.techsLoadSelect = [];
    this.leadsLoad = [];
    this.qaItemsForPunch = [];
    this.loadAddresses();
    this.loadtech();
    this.loadLead();
    this.loadServiceCodes();
    this.loadMaterialItems();
    this.loadKits();
    this.loadSubdivision();

    const today = new Date();
    const date = today.getDate();
    const day = today.getDay();
    let tomorrow;
    if (day === 5) {
      tomorrow = today.setDate(date + 3);
    } else if (day === 6) {
      tomorrow = today.setDate(date + 2);
    } else {
      tomorrow = today.setDate(date + 1);
    }
    const datePipe = new DatePipe('en-US');
    this.nextDate = datePipe.transform(tomorrow, 'yyyy-MM-dd');
    (document.getElementById('vpo-scheduled-date') as HTMLInputElement).value = this.nextDate;
    this.dateScheduled = (document.getElementById('vpo-scheduled-date') as HTMLInputElement).valueAsDate
      .toISOString()
      .substr(0, 10)
      .replace(/-/g, '');

    const a = this.authService.decodedToken.nameid;
  }


  loadKits(){
    this.sageApi.pullReport('Punch/Kits').subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)){
          rows.forEach((row) => {
            const obj = {
              kit: row.Kit,
              itemCode: row.ItemCode,
              description: row.Description,
              quantity: row.Quantity,
            };
            this.kits.push(obj);
          });
        }
      }
    );
  }

  loadAddresses() {
    this.addressLoading = true;
    this.loadingStatus = `Loading Addresses...`;
    this.sageApi.pullReport('Address').subscribe(
      (address: Array<any>) => {
        this.loadedAddresses = [];
        address.forEach((add) => {
          const obj = {
            address: add.ShipToName,
            subdivision: add.Subdivision,
            salesOrderNo: add.SalesOrderNo,
            email1: add.UDF_BUILDER_EMAIL_1,
            email2: add.UDF_BUILDER_EMAIL_2,
            email3: add.UDF_BUILDER_EMAIL_3,
            name: `${add.ShipToName} - ${add.Subdivision} - ${add.SalesOrderNo}`,
            warehouse: add.WarehouseCode
          };
          this.loadedAddresses.push(obj);
        });
      },
      (err) => {
        this.addressLoading = false;
        this.loadingStatus = `Error loading addresses - please try again!`;
        console.error(err);
      },
      () => {
        this.addressLoading = false;
        this.loadingStatus = ``;
      }
    );
  }

  loadLead() {
    this.isLoadingLead = true;
    const name = '';
    const techNames = this.sageApi.pullReport('Punch/GetLeadmen').subscribe(
      (names: Array<any>) => {
        this.leadsLoad = [];
        const observable = [];
        if (Array.isArray(names)) {
          names.forEach((ste) => {
            const ob = {
              leadman: ste.leadman,
              superUser: ste.superUser,
              nickname: ste.nickname,
              email: ste.email,
              techTag: ste.nickname + ' [' + ste.leadman + ']'
            };
            this.leadsLoad.push(ob);
          });
        }
      },
      (err) => {
        this.isLoading = false;
        this.isLoadingLead = false;
        this.loadingStatus = 'Failed to load Leadmen';
        console.error(err);
      },
      () => {
        this.techLoaded = true;
        this.isLoadingLead = false;
        this.setDefaultTech();
      }
    );
  }

  loadSubdivision(){
    this.sageApi.pullReport("SalesOrderEntry/Subdivisions").subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            this.subdivisionList.push(row);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
      }
    );
  }

  loadtech() {
    this.isLoadingTech = true;
    const name = '';
    const techNames = this.sageApi.pullReport('Punch/GetTechs').subscribe(
      (names: Array<any>) => {
        this.techsLoad = [];
        this.techsLoadSelect = [];
        const observable = [];
        if (Array.isArray(names)) {
          names.forEach((ste) => {
            const ob = {
              tech: ste.TechnicianNo,
              firstName: ste.FirstName,
              lastName: ste.LastName,
              deptno: ste.TechnicianDeptNo,
              techCode: ste.TechnicianDeptNo + ste.TechnicianNo,
              assignedLM: ste.UDF_ASSIGNED_LEADMAN,
              techTag: ste.TechnicianDeptNo + ste.TechnicianNo + ' - ' + ste.FirstName + ' ' + ste.LastName + ' - ' +
                (ste.UDF_ASSIGNED_LEADMAN !== '' ? 'true' : 'false'),
              isinternal: ste.ScheduleGroup === 'PUNCH' && (ste.UDF_ASSIGNED_LEADMAN !== null && ste.UDF_ASSIGNED_LEADMAN !== '') ? 'Y' : 'N'
            };
            this.techsLoad.push(ob);
            this.techsLoadSelect.push(ob.techTag);
          });
        }
      },
      (err) => {
        this.isLoading = false;
        this.isLoadingTech = false;
        this.loadingStatus = 'Failed to load Techs';
        console.error(err);
      },
      () => {
        this.techLoaded = true;
        this.isLoadingTech = false;
        this.setDefaultTech();
      }
    );
  }

  loadItemList(warehouse) {
    this.itemList = [];
    this.warehouse = warehouse;
    this.sageApi.pullReport('Punch/Items?Warehouse=' + warehouse).subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const item = {
              name: row.ItemCode + ' - ' + row.ItemCodeDesc,
              itemCode: row.ItemCode,
              description: row.ItemCodeDesc,
              kit: row.Kit,
              UnitCost: row.MatPrice === undefined ? 0 : row.MatPrice
            };

            this.itemList.push(item);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {

      }
    );
  }

  setDefaultTech() {
    const user: string = this.authService.decodedToken.nameid.toLocaleLowerCase();
    let leadman: string;
    const input: any = document.querySelector(`#vpo-assigned-tech`);

    /*
      Be nice to your fellow developers.
      When you have more than three ternary operators,
      put it in a switch or an if/else statement.
    */
    user === `blaque` ? leadman = `Bobby Laque` :
      user === `rbrittian` ? leadman = `Ron Brittian` :
        user === `jfulghum` ? leadman = `Jake Fulghum` :
          user === `jdavis` ? leadman = `JAMES DAVIS` :
            user === `rtarrant` ? leadman = `Ronny Tarrant` :
              user === `joejr` ? leadman = `JOE BUSTOS, JR.` :
                user === `jloya` ? leadman = `JESUS LOYA` :
                  user === `krodriguez` ? leadman = `Kenneth Rodriguez` :
                    user === `gcervantes` ? leadman = `George Cervantes` :
                      user === `eowens` ? leadman = `Eric Owens` :
                        user === `dfarias` ? leadman = `David Farias` :
                          user === `gfreeman` ? leadman = `Gary Freeman` :
                            user === 'jdelgado' ? leadman = 'Joseph Delgado' :
                              user === `jtaylor` ? leadman = `Jared Taylor` : '';

    this.techsLoad.forEach(element => {
      if (element.assignedLM === leadman) {
        input.value = element.techCode;
        this.usercode = element.techCode;
        this.searchTech = element.techCode;
        this.internalTech = 'true';
      }
    });
  }

  loadServiceCodes() {
    this.serviceCodes = [];
    // this.warrantyCodes = [];
    this.isLoading = true;
    this.loadingStatus = `Loading service codes...`;
    const codesList = this.sageApi.pullReport('Punch/ServiceCodes').subscribe(
      (codes: Array<any>) => {
        let i = 0;
        codes.forEach((singleCode) => {
          const obj = {
            objectID: i,
            code: singleCode.UDF_CODE,
            desc: singleCode.UDF_DESCRIPTION,
            serviceTag: singleCode.UDF_CODE + ' - ' + singleCode.UDF_DESCRIPTION,
            type: singleCode.UDF_TYPE
          };
          if (obj.type === 'PUNCH'){
            this.serviceCodes.push(obj);
            this.punchLoadSelect.push(obj.serviceTag);
            i++;
          }
          // else if (obj.type === 'WARRANTY'){
          //   this.warrantyCodes.push(obj);
          //   this.punchWarLoadSelect.push(obj.serviceTag);
          // }
        });
        console.log('service codes', this.serviceCodes);
        // console.log('warranty codes', this.warrantyCodes);
      },
      (error) => {
        console.error(error);
      },
      () => {
        this.isLoading = false;
        this.loadingStatus = ``;
      }
    );
  }

  loadMaterialItems() {
    this.itemCodesList = [];
    this.isLoading = true;
    this.loadingStatus = `Loading service codes...`;
    const itemsList = this.sageApi.pullReport('/ItemMaterial').subscribe(
      (items: Array<any>) => {
        let i = 0;
        items.forEach((singleItem) => {
          const obj = {
            objectID: i,
            itemCode: singleItem.ItemCode,
            desc: singleItem.Description,
            category: singleItem.CommodityCode,
            partorLabor: 'P'
          };
          this.itemCodesList.push(obj);
          i++;
        });
      },
      (error) => {
        console.error(error);
      },
      () => {
        this.isLoading = false;
        this.loadingStatus = ``;
      }
    );
  }

  adCheck(st, i){
    const str = st.address + ' - ' + st.salesOrderNo;
    if (this.address.length < 5){
      return false;
    }else {
      return str.toLocaleLowerCase().includes(this.address.toLocaleLowerCase());
    }
  }

  tcCheck(tech){
    const setTech = tech.assignedLM !== '' ? 'true' : 'false';
    const str = tech.techCode + ' - ' + tech.firstName + ' ' + tech.lastName + ' - ' + setTech;
    if (this.searchTech !== undefined){
      if ( this.searchTech.length < 3){
        return false;
      }else {
        return str.toLocaleLowerCase().includes(this.searchTech.toLocaleLowerCase());
      }
    }else {
      return false;
    }
  }

  helpCheck(guest){
    const setTech = guest.assignedLM !== '' ? 'true' : 'false';
    const str = guest.techCode + ' - ' + guest.firstName + ' ' + guest.lastName + ' - ' + setTech;
    if (this.searchHelper !== undefined){
      if ( this.searchHelper.length < 3){
        return false;
      }else {
        return str.toLocaleLowerCase().includes(this.searchHelper.toLocaleLowerCase());
      }
    }else {
      return false;
    }
  }

  addressFilter(){
    this.filteredAddresses = [];
    if (this.address.length >= 3){
      this.loadedAddresses.forEach(dress => {
        const lineReads = dress.address + ' - ' + dress.salesOrderNo;
        if (lineReads.toLocaleLowerCase().includes(this.address.toLocaleLowerCase())){
          this.filteredAddresses.push(dress);
        }
      });
    }
  }

  onAddressSelected(event: TypeaheadMatch) {
    this.loadingStatus = '';
    this.address = event.item.address;
    this.salesorder = event.item.salesOrderNo;

    this.getLeadman(event.item.subdivision)

    this.addressSelected = true;
    if (this.loadedAddresses !== undefined){
      this.builderContactEmail1 = event.item.email1 === undefined ? ' ' : event.item.email1;
      this.builderContactEmail2 = event.item.email2 === undefined ? ' ' : event.item.email2;
      this.builderContactEmail3 = event.item.email3 === undefined ? ' ' : event.item.email3;
    }
    this.randomImageKey = this.randomImageKeyGenerator();
    this.loadSpecMaterial(this.salesorder);
    this.loadPreviousPunch();
    this.loadPreviousBackcharge();
    this.loadItemList(event.item.warehouse);
  }

  getLeadman(subdivision){
    const obj = this.subdivisionList.find(x => x.UDF_SUBDIVISION_CODE == subdivision);
    if(obj != undefined){
     this.leadcode = obj.UDF_LEADMAN_CODE;
     this.searchLead = obj.UDF_LEADMAN_CODE;
    }
  }

  randomImageKeyGenerator(): string {
    const min = Math.ceil(0);
    const max = Math.floor(9000000);
    const randomNum: string = (Math.random() * (max - min + 1)).toFixed(0).toString();
    const soNum: string = this.salesorder;
    const month: string = new Intl.DateTimeFormat('en-US', { month: 'long' }).format().slice(0, 3);
    // tslint:disable-next-line:new-parens
    const dayOfWeek: string = (new Date).toString().slice(0, 3);
    // tslint:disable-next-line:new-parens
    const dayOfMonth: string = (new Date).toString().slice(8, 10);

    return randomNum + soNum + month + dayOfWeek + dayOfMonth;
  }

  loadSpecMaterial(soNum: string) {
    this.isLoading = true;
    this.loadingStatus = `Loading Spec Material`;
    this.punchSpecMaterialItems = [];
    this.sageApi.pullReport(`PunchMaterialOnSpec/${soNum}`).subscribe(
      (materials: Array<any>) => {
        let i = 0;
        if (materials && Object.keys(materials).length < 1) {
          return;
        }
        if (Array.isArray(materials)) {
          materials.forEach(items => {
            const obj = {
              soNum: items.SalesOrderNo,
              linekey: items.LineKey,
              phase: items.Phase.slice(1),
              itemCode: items.ItemCode,
              itemDesc: items.ItemCodeDesc,
              qtyNeeded: items.QuantityOrdered,
              qtyUsed: items.QuantityShipped,
              location: items.UDF_LOCATION,
              catergory: items.Catergory,
              UnitCost: items.UnitCost + (items.UnitCost * .3),
              InactiveItem: items.InactiveItem
            };
            this.punchSpecMaterialItems.push(obj);
            i++;
          });
        }
      },
      (error) => {
        console.error(`Error loading Spec Material ${error}`);
        this.isLoading = false;
        this.loadingStatus = `Error Loading Material on Spec Sheet - please try again!`;
      },
      () => {
        this.isLoading = false;
        this.loadingStatus = ``;
      }
    );
  }

  loadSpecLabor(soNum: string) {
    this.isLoading = true;
    this.loadingStatus = `Loading Spec Labor`;
    this.punchSpecMaterialItems = [];
    this.sageApi.pullReport(`PunchMaterialOnSpec/${soNum}`).subscribe(
      (materials: Array<any>) => {
        let i = 0;
        if (materials && Object.keys(materials).length < 1) {
          return;
        }
        if (Array.isArray(materials)) {
          materials.forEach(items => {
            const obj = {
              soNum: items.SalesOrderNo,
              linekey: items.LineKey,
              phase: items.Phase.slice(1),
              itemCode: items.ItemCode,
              itemDesc: items.ItemCodeDesc,
              qtyNeeded: items.QuantityOrdered,
              qtyUsed: items.QuantityShipped,
              location: items.UDF_LOCATION,
              catergory: items.Catergory,
              inactiveItem: items.InactiveItem
            };
            this.punchSpecMaterialItems.push(obj);
            i++;
          });
        }
      },
      (error) => {
        console.error(`Error loading Spec Material ${error}`);
        this.isLoading = false;
        this.loadingStatus = `Error Loading Material on Spec Sheet - please try again!`;
      },
      () => {
        this.isLoading = false;
        this.loadingStatus = ``;
      }
    );
  }

  loadPreviousPunch() {
    this.loadQAforPunch();
    this.wts = [];
    this.punchLoadingStatus = 'Loading Punch Tickets...';
    let itemsLoaded = 0;
    const statusLoaded = 0;
    let failed: string;
    const punchLines = this.sageApi.pullReport('PUN/' + this.salesorder).subscribe(
      (pun: Array<any>) => {
        console.log(pun)
        console.log("prev pun")
        if (pun.length > 0) {
          if (Array.isArray(pun)) {
            pun.forEach((p) => {
              const obj = {
                wtNum: p.WTNumber,
                description: p.ItemCodeDesc,
                status: p.StatusCode,
                womsType: p.UDF_WOMS_TYPE,
                womsStatus: p.UDF_WOMS_STATUS,
                promiseDate: p.PromiseDate,
                techName: p.techName,
                techCode: p.UDF_TECHNICIAN_CODE,
                DIP: p.DIP
              };
              itemsLoaded++;
              this.punchLoadingStatus = 'Loaded ' + itemsLoaded + ' Punch Tickets';
              this.wts.push(obj);
            });
          }
          console.log(this.wts)
        } else {
          failed = 'Y';
        }
      },
      (err) => {
        console.error(err);
        this.isLoading = false;
      },
      () => {
        if (failed === 'Y') {
          this.isLoading = false;
          this.punchLoadingStatus = 'No Punch Work Tickets';
          return;
        }
        this.punchLoadingStatus = 'Loaded ' + itemsLoaded + ' Punch Tickets';
        this.isLoading = false;
        this.tableLoaded = true;
      }
    );
  }
  routeToWoms(){
    this.router.navigate(["Woms/Accounting/Queue"], {queryParams: {salesorder: this.salesorder}})
  }

  loadPreviousBackcharge() {
    this.backchargewts = [];
    this.backchargeLoadingStatus = 'Loading Backcharge Tickets...';
    let itemsLoaded = 0;
    const statusLoaded = 0;
    let failed: string;
    const backchargeLines = this.sageApi.pullReport('backcharge/' + this.salesorder).subscribe(
      (pun: Array<any>) => {
        console.log(pun)
        console.log("prev backcharge")
        if (pun.length > 0) {
          if (Array.isArray(pun)) {
            pun.forEach((p) => {
              const obj = {
                wtNum: p.WTNumber,
                description: p.ItemCodeDesc,
                status: p.StatusCode,
                womsType: p.UDF_WOMS_TYPE,
                womsStatus: p.UDF_WOMS_STATUS,
                promiseDate: p.PromiseDate,
                techName: p.techName,
                techCode: p.UDF_TECHNICIAN_CODE,
                trackingComment: p.UDF_TRACKING_COMMENT,
                unitPrice: p.UnitPrice,
                DIP: p.DIP,
                SalesOrderNo: p.SalesOrderNo
              };
              itemsLoaded++;
              this.backchargeLoadingStatus = 'Loaded ' + itemsLoaded + ' Punch Tickets';
              this.backchargewts.push(obj);
            });
          }
          console.log(this.backchargewts)
        } else {
          failed = 'Y';
        }
      },
      (err) => {
        console.error(err);
        this.isLoading = false;
      },
      () => {
        if (failed === 'Y') {
          this.isLoading = false;
          this.backchargeLoadingStatus = 'No Punch Work Tickets';
          return;
        }
        this.backchargeLoadingStatus = 'Loaded ' + itemsLoaded + ' Backcharge Tickets';
        this.isLoading = false;
        this.tableLoaded = true;
      }
    );
  }


  loadQAforPunch() {
    this.sageApi.pullReport(`QA/pun?soNum=${this.salesorder}`).subscribe(
      (qaData: Array<any>) => {
        qaData.forEach((qa) => {
          if (qa.StatusCode !== 'COM' && qa.StatusCode !== 'QAC') {
            this.qaItemsForPunch.push(qa);
          }
        });
      }
    );
  }

  onTechSelected(event: TypeaheadMatch) {
    let foundTech = null;
    this.AssigenTechContrl.setValue(event.value);
    foundTech = this.techsLoad.find(i => i.techTag === event.value);

    if (foundTech != null && foundTech !== [] && foundTech !== undefined) {
      this.usercode = foundTech.techCode;
      this.searchTech = foundTech.techCode;
      this.internalTech = foundTech.isinternal;

    }
  }

  onLeadSelected(event: TypeaheadMatch) {
    this.leadcode = event.item.leadman;
    this.searchLead = event.item.leadman;
  }

  onHelperSelected(event: TypeaheadMatch) {
    this.searchHelper = event.item.techCode;
  }

  onDateSelected(value) {
    this.dateScheduled = value;
  }

  onLocationButtons(input: string) {
    this.serviceCodeSearchText = '';
    this.locationCode = input;
  }

  onPunchCodeSelected(code: string) {
    this.selectedServiceCodes = [];
    this.AssigenPunchContrl.setValue(code);
    console.log(this.AssigenPunchContrl.value);
    const foundPunchCode = this.serviceCodes.find(i => i.serviceTag === code);

    if (foundPunchCode != null && foundPunchCode !== [] && foundPunchCode !== undefined){
      if (code !== '') {
        this.selectedServiceCodes.push(foundPunchCode.code);
        // this.selectedServiceCodes.push(code);
        console.log('selectedServiceCodes', this.selectedServiceCodes);
      }
    }
  }

  // onWarrantyCodeSelected(code: string) {
  //   this.selectedWarrantyCodes = [];
  //   this.AssigenWarrantyContrl.setValue(code);
  //   const foundPunchCode = this.warrantyCodes.find(i => i.serviceTag === code);
  //
  //   if (foundPunchCode != null && foundPunchCode !== [] && foundPunchCode !== undefined){
  //     if (code !== '') {
  //       this.selectedWarrantyCodes.push(foundPunchCode.code);
  //       // this.selectedWarrantyCodes.push(code);
  //       console.log('selectedWarrantyCodes', this.selectedWarrantyCodes);
  //     }
  //   }
  // }

  removePunchCode(punchIndex: number) {
    this.selectedServiceCodes.splice(punchIndex, 1);
  }

  onBuilderContactEmailSet(eventValue, localRef) {
    /*
      Plese, no more than three ternary operators at a time.
      Put in a switch or if/else statement.
    */
    // tslint:disable-next-line:no-unused-expression
    localRef === 'builderContactEmail1'
      ? (this.builderContactEmail1 = eventValue)
      : localRef === 'builderContactEmail2'
      ? (this.builderContactEmail2 = eventValue)
      : localRef === 'builderContactEmail3'
        ? (this.builderContactEmail3 = eventValue)
        // tslint:disable-next-line:no-unused-expression
        : 'no emails provided';
  }

  onAddMaterial() {
    this.materialModal.show();
  }

  onFileChanged(event: any){

  }

  imagesChosen(event) {
    const files = event.target.files;
    for (const ind in files) {
      if (files[ind] instanceof File) {
        const obj = {
          name: files[ind].name,
          data: files[ind]
        };
        this.fileArray = this.fileArray.concat(obj);
      }
    }
    console.log('images array', this.fileArray);
  }

  imagesUpload() {
    this.fileLoading = true;
    const sentFiles = [];
    console.log('plan array', this.fileArray);
    this.fileArray.forEach( file => {
      sentFiles.push(file.data);
    });

    this.wasabiApi.uploadFile(this.address, 'scouts', sentFiles).subscribe((res) => {
    }, err => {
      console.log(err);
      if (err.error.includes('request entity is too large')){
        this.alertify.error('Error: File entry size too large for submission. Decrease number of files submitted at once or lower quality of image.');
      }else {
        this.alertify.error('Error: ' + err.error);
      }
      this.fileLoading = false;
    }, () => {
      this.resetUpload();
      this.alertify.success('File Uploaded');
      this.resetUpload();
    });
  }

  resetUpload() {
    this.fileArray = [];
    this.fileLoading = false;
  }

  onSearchItems() {
    if (this.itemCodeSearchText === '' || this.itemCodeSearchText === ' ') {
      this.searchHitsItems.length = 0;
    } else {
      const hits = [];
      this.itemList.forEach(element => {
        if(element.itemCode.toLowerCase().includes(this.itemCodeSearchText.toLowerCase()) ||
        element.description.toLowerCase().includes(this.itemCodeSearchText.toLowerCase())){
          hits.push(element);
        }
      });
      console.log(hits)
      this.searchHitsItems = hits;
    }
  }

  onItemCodeSelected(itemCode: string, desc: string, price = 0) {
    const kitItems = [];
    this.itemCodeSearchText = '';
    this.searchHitsItems.length = 0;
    let kitPossible = [];
    let warningMessage = '';
    if (itemCode !== '') {
      let kit = false;
      if (price === 0){
        const ic = this.itemList.find(x => x.itemCode === itemCode);
        if (ic == undefined){
          kitPossible = this.kits.filter(x => x.kit == itemCode);
          kitPossible.forEach(item => {
            const itemFound = this.itemList.find(x => item.itemCode === x.itemCode);
            if (itemFound != undefined){
              kitItems.push(itemFound);
            }
            else{
              warningMessage += item.itemCode + ', ';
            }
          });
          if (warningMessage != ''){
            this.alertify.warning('This kit contains items: ' + warningMessage + ' that aren\'t present in warehouse ' + this.warehouse + '.');
          }
          kit = true;
        }
        if ( ic != undefined && ic.kit === 'Y'){
          const matching = this.kits.filter(x => x.kit == ic.itemCode);
          matching.forEach(kit => {
            const foundItem = this.itemList.find(x => x.itemCode == kit.itemCode);
            kitItems.push(foundItem);
        });
          kit = true;
        }
        if (ic !== undefined){
          price = ic.UnitCost;
        }
      }
      if (kit === false){
        this.materialNeededItems.push(
          {
            itemCode,
            desc,
            itemQty: this.materialNeededQty,
            // category,
            UnitCost: price
          });
        this.materialNeededQty = 1;
        }

       else if ( kit === true) {
        if (kitItems.length > 0){
          this.alertify.success('Added items from the kit: ' + itemCode);
        }
        kitItems.forEach(item => {
            this.materialNeededItems.push(
              {
                itemCode: item.itemCode,
                desc: item.description,
                itemQty: this.materialNeededQty,
                // category : item.category,
                UnitCost: item.UnitCost
              });
            this.materialNeededQty = 1;
          });
        }
      }
  }

  removeMaterialItemCode(itemIndex: number) {
    this.materialNeededItems.splice(itemIndex, 1);
  }

  cleanReasonCode(){
    // console.log('trigger');
    this.selectedServiceCodes = [];
    // this.selectedWarrantyCodes = [];
  }

  onTicketSubmit() {
    // *Checks to see if selected tech is a house tech and changes ticket status based on that value
    this.locationCode = 'GENERAL';
    this.internalTechChk();
    const date = new Date();
    const datePipe = new DatePipe('en-US');
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const todaydate = datePipe.transform(new Date(), 'yyyyMMddHHmmss');
    const userName = this.authService.decodedToken.nameid;
    const timestamp = `${month}/${day}/${year} - ${userName}`;
    const combinedBuilderEmails: string = [this.builderContactEmail1, this.builderContactEmail2, this.builderContactEmail3].join(', ');
    const Description: any = this.Description;
    const Salesorder = this.salesorder;
    const webKey = Salesorder + todaydate;
    const observables = [];
    const materialNeededItems = [];
    const addItemCodesPunch = [];
    const addServiceCodesPunch = [];
    // const addWarrantyCodesPunch = [];
    const addMaterialNeededItems = [];
    const matNeededDate: string = this.materialNeededItems.length > 0 ? this.dateScheduled.replace(/-/g, '') : '17530101';
    // const scheduleDate = this.dateScheduled.replace(/-/g, '');
    const scheduleDate = this.dateScheduled;
    let jtstatus = `PUN`;
    let whovpo = this.vpoWho;

    this.combinedVPO = `${this.vpoNotes} ${timestamp}`;
    const serviceCodeSlice = [];
    this.selectedServiceCodes.forEach((item) => {
      serviceCodeSlice.push(item.slice(0, 3));
    });

    let hasTech = false;
    this.techsLoad.forEach(tech => {
      if (tech.techCode === this.usercode || tech.techCode === this.searchTech) {
        hasTech = true;
      }
    });
    this.locationCode = 'GENERAL';

    if (this.ticketType === 'backcharge') {
      whovpo = 'NO TYPE';
      this.vpoWho = 'NO TYPE';
    }
    if (this.salesorder === '') {
      this.loadingStatus = 'Failed: Please select a sales order number';
      this.alertify.error(this.loadingStatus);
    } else if ((this.usercode === '' || !hasTech) && this.ticketType === 'punch') {
      if (!hasTech) {
        this.loadingStatus = 'Failed: Invalid Tech Code';
      } else {
        this.loadingStatus = 'Failed: Please select a Tech';
      }
      this.alertify.error(this.loadingStatus);
    } else if (this.dateScheduled === '' && this.ticketType === 'punch') {
      this.loadingStatus = 'Failed: Please enter Schedule Date';
      this.alertify.error(this.loadingStatus);
    } else if (this.phase === '' && this.ticketType === 'punch') {
      this.loadingStatus = 'Failed: Please enter a Phase';
      this.alertify.error(this.loadingStatus);
    } else if (this.vpoWho === '' && this.ticketType === 'punch') {
      this.loadingStatus = 'Failed: Please Choose Who to Backcharge';
      this.alertify.error(this.loadingStatus);
    } else if (this.vpoNotes.replace(/\s/g, '').length === 0 && this.ticketType === 'punch' && this.vpoWho === 'GIBSON') {
      this.loadingStatus = 'Failed: Please enter a Reason For Charge Selection';
      this.alertify.error(this.loadingStatus);
    } else if (this.selectedServiceCodes.length === 0 && this.ticketType === 'punch' && this.vpoWho === 'GIBSON') {
      this.loadingStatus = 'Failed: Please enter a Punch Code';
      this.alertify.error(this.loadingStatus);
    }
    // else if (this.selectedWarrantyCodes.length === 0 && this.ticketType === 'punch' && this.vpoWho === 'GIBSON'
    //   && this.AssigenPunchContrl.value.includes( 'Warranty')) {
    //   this.loadingStatus = 'Failed: Please enter a Warranty Code';
    //   this.alertify.error(this.loadingStatus);
    // }
    else if (Description === '') {
      this.loadingStatus = 'Failed: Please enter a description of the work';
      this.alertify.error(this.loadingStatus);
    } else if (Number(this.VPORequestAmount()) === 0 && this.vpoWho === 'BUILDER'){
      this.loadingStatus = 'Failed: Total VPO Request Amount cannot be $0';
      this.alertify.error(this.loadingStatus);
    }
    else {
      if (this.ticketType === 'service') {
        jtstatus = this.workAlreadyComplete ? 'STC' : 'STO';
      }

      // ! Checks to see if work has been completed, if so jtstatus is set to complete
      if (this.workAlreadyComplete && this.ticketType === 'punch') {
        jtstatus = `COM`;
      }
      // ******************************* SUMBMISSIONS ***************************************************************************************
      // ************************************************************************************************************************************

      let codeCount = 0;
      this.selectedServiceCodes.forEach(codes => {
        const obj2 = [
          Salesorder,
          codes,
          jtstatus,
          scheduleDate,
          codeCount,
          this.locationCode
        ];
        addServiceCodesPunch.push(obj2);
        codeCount++;
      });

      // let warCount = 0;
      // this.selectedWarrantyCodes.forEach(codes => {
      //   const obj2 = [
      //     Salesorder,
      //     codes,
      //     jtstatus,
      //     scheduleDate,
      //     codeCount,
      //     this.locationCode
      //   ];
      //   addWarrantyCodesPunch.push(obj2);
      //   warCount++;
      // });

      let itemCount = 0;
      this.materialNeededItems.forEach(item => {
        const obj2 = [
          Salesorder,
          item.itemCode,
          item.itemCode === '/NS-RES' ? item.desc : 'none',
          item.itemQty,
          jtstatus,
          scheduleDate,
          itemCount,
          ` `, // materialrush,
          this.combinedVPO
        ];
        addItemCodesPunch.push(obj2);
        itemCount++;
      });
      const billTotal = this.VPORequestAmount().toString();

      const obj = {
        username: this.authService.decodedToken.nameid,
        password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
        Description,
        salesOrderNum: Salesorder,
        assignedTech: this.usercode,
        assignedLead: this.searchLead,
        billingAmount: billTotal,
        payAmount: this.payAmount,
        backchargeAmount: this.backchargeAmount,
        PONum: this.PONum,
        timeToComplete: (this.payAmount / 45 * 60).toFixed(1),
        dateScheduled: scheduleDate,
        materialNeededDate: matNeededDate,
        materialDescription: '',
        combinedVPO: this.combinedVPO,
        vpoWho: this.vpoWho,
        builderContactName: this.builderContactName,
        combinedBuilderEmails,
        phase: this.phase,
        ticketStatus: this.ticketStatus,
        materialRush: '  ',
        serviceCode: serviceCodeSlice.join(`,`),
        randomImageKey: this.randomImageKey,
        helper: this.searchHelper,
        jtstatus,
        webKey,
        addItemCodesPunch,
        addServiceCodesPunch,
        // addWarrantyCodesPunch,
        addMaterialNeededItems,
        builderReplace: this.builderReplace ? 'Y' : 'N',
        builderEmail1: this.builderContactEmail1,
        builderEmail2: this.builderContactEmail2,
        builderEmail3: this.builderContactEmail3,
        ticketType: this.ticketType
      };

      console.log('observable', obj);
      // THIS ONE
      observables.push(this.sageApi.putRequest('UpdatePunch', obj));

      this.isLoading = true;
      this.imagesUpload();

      concat(...observables).subscribe(response => {

      }, err => {
        console.log(err);
        this.alertify.error('Error ' + err.error.Message);
        console.error(err);
        this.isLoading = false;
        this.isError = true;
        this.loadingStatus = `${err}`;
      }, () => {
        this.alertify.success('Loaded Successfully - Changes Committed to SAGE ✔️');
        this.isLoading = false;
        this.isError = false;
        this.loadingStatus = 'Loaded Successfully - Changes Committed to SAGE ✔️';
        this.onResetDataClicked();
      });
    }

  }

  VPORequestAmount(){
    const total = parseFloat(this.TotalMatPricing()) + parseFloat(this.BillingAmount.toString());
    return total.toFixed(2);
  }

  TotalMatPricing(){
    this.ZeroPartsPrice = false;
    let total = 0;
    /*
      This always returns false. Arrays use
      object comparison, not value comparison.
    */
    if (this.materialNeededItems !== undefined && this.materialNeededItems !== []) {
      this.materialNeededItems.forEach(item => {
        if (item.UnitCost === 0){
          this.ZeroPartsPrice = true;
        }
        total += (item.UnitCost * item.itemQty);
      });
    }
    return total.toFixed(2);
  }

  internalTechChk(): void {
    if (this.ticketType === 'backcharge') {
      this.ticketStatus = 'PDISC';
    } else if (this.vpoWho === 'BUILDER') {
      // * If tech codes DO NOT match then change status to MGTRV (RRO's queue)
      this.ticketStatus = 'ACCRV';
    } else if (this.vpoWho === 'CREW') {
      // * If tech codes DO NOT match then change status to MGTRV (RRO's queue)
      this.ticketStatus = 'MGTRV';
    } else if (this.vpoWho === 'GIBSON') {
      // * If tech codes DO NOT match then change status to MGTRV (RRO's queue)
      if ((this.BillingAmount > 0 || this.payAmount > 0) && this.internalTech === 'N'){
        this.ticketStatus = 'MGTRV';
      }else {
        this.ticketStatus = 'LOSS';
      }
    } else {
      // * If tech codes DO NOT match then change status to MGTRV (RRO's queue)
      this.ticketStatus = 'LOSS';
    }
  }

  onResetDataClicked() {
    this.address = '';
    this.usercode = '';
    this.Description = '';
    this.salesorder = '';
    this.payAmount = 0;
    this.loadingStatus = '';
    this.locationCode = null;
    this.materialNeeded = false;
    this.materialNeededItems = [];
    this.materialNeededQty = 1;
    this.itemCodeSearchText = '';
    this.crewBackCharge = false;
    this.builderBackCharge = false;
    this.noBackchargeNeeded = false;
    this.materialNeeded = false;
    this.materialCheck = 'N';
    this.filteredServiceCodes = [];
    this.combinedVPO = '';
    this.vpoNotes = '';
    this.vpoWho = 'CREW';
    this.lineOneMatDesc = '';
    this.lineTwoMatDesc = '';
    this.lineThreeMatDesc = '';
    this.lineFourMatDesc = '';
    this.lineFiveMatDesc = '';
    this.lineSixMatDesc = '';
    this.lineSevenMatDesc = '';
    this.lineEightMatDesc = '';
    this.lineNineMatDesc = '';
    this.lineTenMatDesc = '';
    this.lineOneMatQty = '';
    this.lineTwoMatQty = '';
    this.lineThreeMatQty = '';
    this.lineFourMatQty = '';
    this.lineFiveMatQty = '';
    this.lineSixMatQty = '';
    this.lineSevenMatQty = '';
    this.lineEightMatQty = '';
    this.lineNineMatQty = '';
    this.lineTenMatQty = '';
    this.phase = 'ROUGHIN';
    this.punchLoadingStatus = '';
    this.searchTech = '';
    this.AssigenTechContrl = new FormControl('', []);
    this.AssigenPunchContrl = new FormControl('', []);
    // this.AssigenWarrantyContrl = new FormControl('', []);
    this.searchLead = '';
    this.searchHelper = '';
    this.techLoaded = false;
    this.addressLoaded = false;
    this.addressSelected = false;
    this.builderContactName = '';
    this.builderContactEmail1 = '';
    this.builderContactEmail2 = '';
    this.builderContactEmail3 = '';
    this.selectedServiceCodes = [];
    // this.selectedWarrantyCodes = [];
    this.serviceCodeSearchText = '';
    (document.getElementById('vpo-scheduled-date') as HTMLInputElement).value = this.nextDate;
    this.randomImageKey = '';
    this.workAlreadyComplete = false;
    this.wts = [];
    this.BillingAmount = 0;
    this.resetUpload();
  }

  openModal(template: TemplateRef<any>) {
    this.modalTemplate = this.modalService.show(template, {class: 'modal-xl'});
  }

  closeModal() {
    this.modalTemplate.hide();
  }

}
