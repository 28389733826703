<ng-container *ngIf="qokp.data?.QuoteOptionPart">
  <mat-card [class.hover]="isHovered" [class.selected]="qokp.selected.value" [class]="screenSize.getClasses() + ' qokprow-card qokprow-card-part'">
    <div class="qokprow-card-header">
      <div class="qokprow-card-header-front">
        <mat-checkbox
          *ngIf="qokp.editable"
          [formControl]="qokp.selected"
          (change)="selectedChanged($event.checked)"
          class="qokprow-card-header-front-cb"
        ></mat-checkbox>
        <h3 class="qokprow-card-header-front-title">
          {{ qokp.data.QuoteOptionPart.QuotePart.QuotePart_Code }}
          <h6 class="qokprow-card-phase">{{ qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Phase }}</h6>
        </h3>
        <div class="qokprow-card-header-front-quan">
          <i class="qokprow-card-header-front-quan-icon">&times;</i>
          <app-grow-input
            *ngIf="qokp.editable"
            [control]="qokp.quantityControl"
            (inputEmitter)="quantityControlInput($event)"
            [useNativeInput]="true"
            minWidth="unset"
            maxWidth="unset"
            class="qokprow-card-header-front-quan-in"
          ></app-grow-input>
          <span *ngIf="!qokp.editable" class="qokprow-card-header-front-quan-txt">{{ qokp.quantityControl.value }}</span>
          <div
            *ngIf="
              qokp.quantityControl.value != qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity 
              || qokp.phaseControl.value != qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Phase 
              || qokp.costControl.value != qokp.data.QuoteOptionPart.QuotePart.QuotePart_Cost
              || qokp?.buildLocationControl.value != qokp?.data.BuildLocation.BuildLocation_Code
              || tagsControlsChanged()
            "
            class="qokprow-card-header-front-quan-controls"
          >
            <button
              [disabled]="qokp.quantityControl.value == '' || qokp.quantityControl.valid==false || qokp.phaseControl.value === '' || qokp.costControl.value === ''"
              (click)="saveChanges()"
              color="primary"
              mat-flat-button
              class="qokprow-card-header-front-quan-controls-item qokprow-card-header-front-quan-controls-save"
            >
              Save
            </button>
            <button
              (click)="cancelChanges()"
              color="warn"
              mat-flat-button
              class="qokprow-card-header-front-quan-controls-item qokprow-card-header-front-quan-controls-cancel"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="qokprow-card-header-end">
        <div [class.cost-hovered]="isCostHovered" class="qokprow-card-header-end-content">
          <h4 [class.hidden]="costControlVisible" class="qokprow-card-header-end-content-cost">
            <span class="qokprow-card-header-end-content-cost-icon">$</span>
            {{ cost.toFixed(2) }}
          </h4>
          <div 
            [class.focused]="costControlVisible || qokp.costControl.value != qokp.data.QuoteOptionPart.QuotePart.QuotePart_Cost" 
            class="qokprow-card-header-end-content-explanation"
          >
            <h4 class="qokprow-card-header-end-content-explanation-cost">
                <span class="qokprow-card-header-end-content-cost-icon">${{" "}}</span>
                <!-- {{ quoteKitPart.QuotePart.QuotePart.QuotePart_Cost.toFixed(2) }} -->
                <app-grow-input
                  [control]="qokp.costControl"
                  (inputEmitter)="costControlInput($event)"
                  (blurEmitter)="costControlBlur($event)"
                  (focusEmitter)="costControlVisible = true"
                  [useNativeInput]="true"
                  minWidth="25px"
                  maxWidth="100px"
                  class="qokprow-card-header-front-quan-in"
                ></app-grow-input>
                <span class="qokprow-card-header-end-content-cost-per">/part</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
    <span *ngIf="cost == 0" class="qokprow-card-noprice">No price associated with this part!</span>
    <div [class.editable]="qokp.editable" class="qokprow-card-content active">
      <div class="qokprow-card-content-tags">

        <span 
          (click)="isLocationPickerOpen = !isLocationPickerOpen" 
          type="button" 
          cdkOverlayOrigin 
          #buildLocationPill="cdkOverlayOrigin"
          [matTooltip]="qe.getBuildLocation({buildLocationCode: qokp?.buildLocationControl.value})?.BuildLocation_Desc" 
          [style.background]="getTagColor(qokp?.buildLocationControl.value!='NONE'?qokp?.buildLocationControl.value:'')" 
          [style.color]="(qokp?.buildLocationControl.value == 'NONE')?'#000':'#fff'" 
          class="qokprow-card-location-item"
        >
          <ng-container
            *ngIf="qokp?.buildLocationControl.value == 'NONE'"
          >
            <i class="fa fa-plus"></i>
          </ng-container>
          <ng-container
            *ngIf="qokp?.buildLocationControl.value != 'NONE'"
          >
            {{ qokp?.buildLocationControl.value }}
          </ng-container>

          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="buildLocationPill"
            [cdkConnectedOverlayOpen]="isLocationPickerOpen"
          >
            <app-searchable-sel 
              placeholder="Build Location" 
              [options]="getBuildLocations()"
              [required]="true"
              [defaultSelected]="qokp?.buildLocationControl.value"
              (onSelectChange)="buildLocationControlChange($event)"
              class="qokprow-card-location-item-sel"
            ></app-searchable-sel>
          </ng-template>

        </span>

        <span>{{' | '}}</span>

        <span 
          *ngFor="let tag of qokp.tagsControl.controls" 
          (click)="removeTag(tag.value)"
          [matTooltip]="qe.getTag({tagName: tag.value}).Tag_Desc" 
          [style.background]="getTagColor(tag.value)" 
          class="qokprow-card-content-tags-item"
        >
          {{ tag.value }}
        </span>
        <span 
          *ngIf="getAvailableTags().length > 0"
          (click)="tagPickerOpen=!tagPickerOpen"
          cdkOverlayOrigin
          #tagPill="cdkOverlayOrigin"
          matTooltip="Add a tag" 
          style.background="#fff" 
          [style.color]="'#000'" 
          class="qokprow-card-content-tags-item"
        >
          <i class="fa fa-plus"></i>
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="tagPill"
            [cdkConnectedOverlayOpen]="tagPickerOpen"
          >
            <app-searchable-sel
              *ngIf="qokp.data.QuoteOptionPart != undefined"
              (onSelectChange)="tagPickerControlChange($event)"
              placeholder="Tags"
              [options]="getAvailableTags()"
              [required]="true"
              class="qokprow-card-location-item-sel"
            ></app-searchable-sel>
          </ng-template>
        </span>
      </div>
      <div class="qokprow-card-content-desc">
        {{ qokp.data.QuoteOptionPart.QuotePart.QuotePart_Desc }}
      </div>
    </div>
  </mat-card>
</ng-container>

<ng-container *ngIf="qokp.data?.QuoteOptionKit">
  <mat-card 
    [class.hover]="isHovered" 
    [class.selected]="qokp.selected.value" 
    [class.expanded]="qokp.expanded" 
    [class]="screenSize.getClasses() + ' qokprow-card qokprow-card-kit'"
  >
    <div class="qokprow-card-header">
        <div class="qokprow-card-header-front">
          <mat-checkbox
            *ngIf="qokp.editable"
            [formControl]="qokp.selected"
            (change)="selectedChanged($event.checked)"
            class="qokprow-card-header-front-cb"
          ></mat-checkbox>
          <h3 class="qokprow-card-header-front-title">
            <app-grow-input
              *ngIf="qokp.editable"
              [control]="qokp.nameControl"
              [useNativeInput]="true"
              minWidth="25px"
              maxWidth="50vw"
              class="qokprow-card-header-front-title-in"
            ></app-grow-input>
          </h3>
          <div class="qokprow-card-header-front-quan">
            <i class="qokprow-card-header-front-quan-icon">&times;</i>
            <app-grow-input
              *ngIf="qokp.editable"
              [control]="qokp.quantityControl"
              (inputEmitter)="quantityControlInput($event)"
              (keydownEmitter)="$event.stopPropagation()"
              [useNativeInput]="true"
              minWidth="25px"
              maxWidth="100px"
              class="qokprow-card-header-front-quan-in"
            ></app-grow-input>
            <span *ngIf="!qokp.editable" class="qokprow-card-header-front-quan-txt">{{ qokp.quantityControl.value }}</span>
            <div
              *ngIf="
                qokp.quantityControl.value != qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity 
                || qokp.nameControl.value != qokp.data.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Name
                || qokp?.buildLocationControl.value != qokp?.data.BuildLocation.BuildLocation_Code
              "
              class="qokprow-card-header-front-quan-controls"
            >
              <button
                [disabled]="qokp.quantityControl.value == '' || qokp.quantityControl.valid==false"
                (click)="saveChanges()"
                color="primary"
                mat-flat-button
                class="qokprow-card-header-front-quan-controls-item qokprow-card-header-front-quan-controls-save"
              >
                Save
              </button>
              <button
                (click)="cancelChanges()"
                color="warn"
                mat-flat-button
                class="qokprow-card-header-front-quan-controls-item qokprow-card-header-front-quan-controls-cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div class="qokprow-card-header-end">
          <div class="qokprow-card-header-end-content">
            <h5 class="qokprow-card-header-end-content-parts">
              {{ totalParts * qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity }} parts
            </h5>
            <h4 class="qokprow-card-header-end-content-cost">
              <span class="qokprow-card-header-end-content-cost-icon">$</span>
              {{ cost.toFixed(2) }}
            </h4>
            <div class="qokprow-card-header-end-content-explanation">
              <h5 class="qokprow-card-header-end-content-explanation-parts">
                {{ totalParts }} parts/kit
              </h5>
              <h4 class="qokprow-card-header-end-content-explanation-cost">
                  <span class="qokprow-card-header-end-content-cost-icon">$</span>
                  {{ (cost / qokp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity).toFixed(2) }}<span class="qokprow-card-header-end-content-cost-per">/kit</span>
              </h4>
            </div>
          </div>
          <button (click)="qokp.expanded = !qokp.expanded" mat-mini-fab class="qokprow-card-header-end-drop mat-elevation-z2">
            <i [class.active]="qokp.expanded" class="qokprow-card-header-end-drop-icon fas fa-chevron-down"></i>
          </button>
        </div>
        <div class="qokprow-card-location">

          <span 
            (click)="isLocationPickerOpen = !isLocationPickerOpen" 
            type="button" 
            cdkOverlayOrigin 
            #buildLocationPill="cdkOverlayOrigin"
            [matTooltip]="qe.getBuildLocation({buildLocationCode: qokp?.buildLocationControl.value})?.BuildLocation_Desc" 
            [style.background]="getTagColor(qokp?.buildLocationControl.value!='NONE'?qokp?.buildLocationControl.value:'')" 
            [style.color]="(qokp?.buildLocationControl.value == 'NONE')?'#000':'#fff'" 
            class="qokprow-card-location-item"
          >
            <ng-container
              *ngIf="qokp?.buildLocationControl.value == 'NONE'"
            >
              <i class="fa fa-plus"></i>
            </ng-container>
            <ng-container
              *ngIf="qokp?.buildLocationControl.value != 'NONE'"
            >
              {{ qokp?.buildLocationControl.value }}
            </ng-container>

            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="buildLocationPill"
              [cdkConnectedOverlayOpen]="isLocationPickerOpen"
            >
              <app-searchable-sel 
                placeholder="Build Location" 
                [options]="getBuildLocations()"
                [required]="true"
                [defaultSelected]="qokp?.buildLocationControl.value"
                (onSelectChange)="buildLocationControlChange($event)"
                class="qokprow-card-location-item-sel"
              ></app-searchable-sel>
            </ng-template>

          </span>

        </div>
    </div>
    <div [class.active]="qokp.expanded" class="qokprow-card-content">
      <div class="qokprow-card-content-add">
        <button 
          long-press 
          (onLongPress)="menuTrigger.openMenu()" 
          (onLongPressMiss)="onOpenAddSheet.emit(qokp)"
          mat-fab 
          class="qokprow-card-content-add-btn"
        >
          <i class="fas fa-plus"></i>
          <button mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" [style]="{width: '0px', height: '0px'}"></button>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="onOpenAddSheet.emit(qokp)" mat-menu-item>Add parts / kits</button>
          <button (click)="onCustomKitSheet.emit(qokp)" mat-menu-item>Add a custom kit</button>
          <button (click)="onCustomPartSheet.emit(qokp)" mat-menu-item>Add a custom part</button>
        </mat-menu>
      </div>
      <div class="qokprow-card-content-subkit">
        <ng-container *ngFor="let row of getSubkits()">
          <app-qoptionkprow
            (onSave)="generateCost(); onSave.emit($event)" 
            (onOpenAddSheet)="onOpenAddSheet.emit($event)" 
            (onCustomKitSheet)="onCustomKitSheet.emit($event)" 
            (onCustomPartSheet)="onCustomPartSheet.emit($event)" 
            [qokp]="row"
            [qop]="qop"
          ></app-qoptionkprow>
        </ng-container>
        <ng-container *ngFor="let row of getParts()">
          <app-qoptionkprow 
            (onSave)="generateCost(); onSave.emit($event)" 
            (onOpenAddSheet)="onOpenAddSheet.emit($event)" 
            (onCustomKitSheet)="onCustomKitSheet.emit($event)" 
            (onCustomPartSheet)="onCustomPartSheet.emit($event)" 
            [qokp]="row"
            [qop]="qop"
          ></app-qoptionkprow>
        </ng-container>
      </div>
    </div>
  </mat-card>
</ng-container>
