import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatCard } from '@angular/material/card';
import { SwipedEventInterface } from 'src/app/Directives/swipeable/swipeable.directive';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.css'],
})
export class BottomSheetComponent implements OnInit {

  @Input() open = false;

  @HostBinding("style.--sheet-width")
  @Input() sheetWidth = '100%';

  @HostBinding("style.--sheet-min-width")
  @Input() sheetMinWidth = '100px';

  @HostBinding("style.--sheet-max-width")
  @Input() sheetMaxWidth = '100vw';

  @HostBinding("style.--sheet-height")
  @Input() sheetHeight = '85vh';

  @HostBinding("style.--sheet-min-height")
  @Input() sheetMinHeight = '100px';

  @HostBinding("style.--sheet-max-height")
  @Input() sheetMaxHeight = '100vh';

  @HostBinding("style.--sheet-background")
  @Input() sheetBackground = '#FFF';


  @Input() paperClass: string | string[] | Set<string> | {[key: string]: any} = '';

  @Input() canSwipeToClose = true;
  
  @Output() sheetClosed = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    document.addEventListener('keydown', (e) => {
      if(this.open && e.key == 'Escape'){
        this.sheetClosed.emit(false);
      }
    });
  }

  ngAfterViewInit() {
    
  }

  ngOnChanges(changes) {
    if(changes.open && changes.open.currentValue !== changes.open.previousValue){
      if(changes.open.currentValue == false){
        this.closeSheet();
      }
    }
  }

  closeSheet(){
    this.sheetClosed.emit(false);
  }

  cardSwiped(event: SwipedEventInterface) {
    if(this.canSwipeToClose && event.direction == 'down' && event.deg > 250 && event.deg < 290 && event.time < 100){
      this.sheetClosed.emit(false);
    }
  }
}