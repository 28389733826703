import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-searchable-sel',
  templateUrl: './searchable-sel.component.html',
  styleUrls: ['./searchable-sel.component.css']
})
export class SearchableSelComponent implements OnInit, OnChanges {
    
    isMobile: boolean = false;
    
    @Input() options: string[];
    @Input() defaultSelected: string;
    @Input() disabled: boolean = false;
    @Input() placeholder: string = 'Placeholder';
    @Input() required: boolean = false;
    @Input() showEmpty: boolean = true;
    @Input() ffClass: string = '';
    @Input() selClass: string = '';
    @Input() class: string = '';
    @Input() selectedOption: FormControl/*<(typeof this.options[number])[]>*/ = new FormControl();
    @Input() useNativeControl: boolean = false;
    
    @Input() inputControl: FormControl = new FormControl();

    @Output() onSelectChange = new EventEmitter<FormControl>();

    @Output() onSelectInputChange = new EventEmitter<string>();
    @Output() onSelectSearch = new EventEmitter<InputEvent>();

    @ViewChild(MatSelect) searchableSelect: MatSelect;

    filteredOptions: string[];
    
    constructor(
        breakpointObserver: BreakpointObserver
    ){
        breakpointObserver.observe([
            Breakpoints.Small,
            Breakpoints.XSmall
        ]).subscribe(result => {
            if (result.matches) {
                this.isMobile = true;
            }else{
                this.isMobile = false;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(JSON.stringify(changes?.options?.currentValue?.sort()) != JSON.stringify(changes?.options?.previousValue?.sort())){
            this.filteredOptions = this.options;
        }
        if(this.disabled){
            this.selectedOption.disable();
        }else{
            this.selectedOption.enable();
        }
        if(this.required){
            this.selectedOption.setValidators([Validators.required]);
        }
        if(this.defaultSelected){
            this.selectedOption.setValue(this.defaultSelected);
        }
    }
    
    ngOnInit(): void {
        
    }

    onSelChange(){
        this.onSelectChange.emit(this.selectedOption);
    }

    onSearch(value: string|null, event: InputEvent){
        // this.onSelectInputChange.emit(value);
        const newFOps = [];
        this.options.map((op)=>{
            if(value == null || op.toString().toLowerCase().includes(value.toString().toLowerCase())){
                newFOps.push(op);
            }
        });
        this.filteredOptions = newFOps;
    }

    isThisOptionHidden(option: string){
        return this.filteredOptions.findIndex(fop=>{
            return fop==option;
        }) == -1;
    }

}