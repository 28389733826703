<div class="bg-dark text-light rounded px-2">
    <h1 mat-dialog-title>{{ data.ticket.address }} - Punch Information</h1>
</div>

<div *ngIf="technicianListLoading" class="bg-secondary">
    <h3>Technician List Loading...</h3>
</div>

<mat-dialog-content>
    <div class="container">
        <div class="row mb-2">
            <div class="col-sm-6">
                <mat-list role="list">
                    <mat-list-item role="listitem">WT#: {{ data.ticket.soNum + '-' + data.ticket.wtNum }}</mat-list-item>
                    <mat-list-item role="listitem">Subdivision: {{ data.ticket.subdivision | titlecase }}</mat-list-item>
                    <mat-list-item role="listitem">Status: {{ data.ticket.status | titlecase }}</mat-list-item>
                  </mat-list>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100">
                    <mat-label>Tracking Comment</mat-label>
                    <textarea matInput rows="5" disabled [(ngModel)]="data.ticket.trackingComment"></textarea>
                </mat-form-field>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div class="row my-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100">
                    <mat-label>Current Technician</mat-label>
                    <input matInput disabled [(ngModel)]="data.ticket.techName">
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100">
                    <mat-label>New Technician</mat-label>
                    <mat-select [(value)]="data.newTechnician">
                        <mat-option value="">-- None --</mat-option>
                        <ng-container *ngFor="let tech of data.technicianList">
                            <mat-option [value]="tech.techCode">{{ tech.techName }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100">
                    <mat-label>Punch Description</mat-label>
                    <textarea matInput rows="5" disabled [(ngModel)]="data.ticket.description"></textarea>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100">
                    <mat-label>New Description</mat-label>
                    <textarea matInput rows="5" [(ngModel)]="data.newDescription"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="success" (click)="confirmUpdate()" [disabled]="updating">
        <span *ngIf="updating"><i class="fas fa-cog fa-spin"></i></span>
        <span *ngIf="!updating">Save</span>
    </button>
</mat-dialog-actions>