<ng-container *ngIf="part != null">
  <mat-card [class]="screenSize.getClasses() + ' kptarow-card'">
    <div class="kptarow-card-header">
      <div class="kptarow-card-header-front">
        <mat-checkbox
          *ngIf="editable"
          [checked]="selected"
          (change)="selectedChanged($event.checked)"
          class="kptarow-card-header-front-cb"
        ></mat-checkbox>
        <h3 class="kptarow-card-header-front-title">{{ part.Part.Part_Code }}</h3>
        <div class="kptarow-card-header-front-quan">
          <i class="kptarow-card-header-front-quan-icon">&times;</i>
          <app-grow-input
            *ngIf="editable"
            [control]="quantityControl"
            (inputEmitter)="quantityControlInput($event)"
            (blurEmitter)="quantityControlBlur($event)"
            [useNativeInput]="true"
            minWidth="25px"
            maxWidth="100px"
            class="kptarow-card-header-front-quan-in"
          ></app-grow-input>
          <span *ngIf="!editable" class="kptarow-card-header-front-quan-txt"
            >{{ quantityControl.value }}</span
          >
        </div>
        <mat-form-field
          class="kptarow-card-header-front-phase-ff"
        >
          <mat-label>Phase</mat-label>
          <mat-select
            [formControl]="phaseControl"
            (valueChange)="phaseControlChange($event)"
            class="kptarow-card-header-front-phase-ff-sel"
          >
            <mat-option *ngFor="let phase of kitPartPhases" [value]="phase.valueOf()">{{ phase.toString() }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="kptarow-card-header-end">
        <h4 class="kptarow-card-header-end-cost">
          <span class="kptarow-card-header-end-cost-icon">$</span>
          {{ cost.toFixed(2) }}
        </h4>
        <div class="kptarow-card-header-end-cost-explanation">
          <h4 class="kptarow-card-header-end-cost-explanation-txt">
            <span class="kptarow-card-header-end-cost-icon">$</span>
            {{ part.Part.Part_Cost.toFixed(2) }}<span
              class="kptarow-card-header-end-cost-per"
              >/part</span
            >
          </h4>
        </div>
      </div>
    </div>
    <div class="kptarow-card-content">
      <div class="kptarow-card-content-desc">
        {{ part.Part.Part_Desc }}
      </div>
    </div>
  </mat-card>
</ng-container>

<ng-container *ngIf="kit">
  <mat-card [class]="screenSize.getClasses() + ' kptarow-card'">
    <div class="kptarow-card-header">
      <div class="kptarow-card-header-front">
        <mat-checkbox
          *ngIf="editable"
          [checked]="selected"
          (change)="selectedChanged($event.checked)"
          class="kptarow-card-header-front-cb"
        ></mat-checkbox>
        <h3 class="kptarow-card-header-front-title">{{ kit.Kit_Name }}</h3>
        <div class="kptarow-card-header-front-quan">
          <i class="kptarow-card-header-front-quan-icon">&times;</i>
          <app-grow-input
            *ngIf="editable"
            [control]="quantityControl"
            (inputEmitter)="quantityControlInput($event)"
            (blurEmitter)="quantityControlBlur($event)"
            [useNativeInput]="true"
            minWidth="25px"
            maxWidth="100px"
            class="kptarow-card-header-front-quan-in"
          ></app-grow-input>
          <span *ngIf="!editable" class="kptarow-card-header-front-quan-txt"
            >{{ quantityControl.value }}</span
          >
        </div>
      </div>
      <div class="kptarow-card-header-end">
        <!-- <h4 class="kptarow-card-header-end-cost">
          <span class="kptarow-card-header-end-cost-icon">$</span>
          {{ cost.toFixed(2) }}
        </h4> -->
      </div>
    </div>
    <div class="kptarow-card-content">
      <div class="kptarow-card-content-desc">
        {{ kit.Kit_Desc }}
      </div>
      <!-- <div class="kptarow-card-content-subkit">
        <ng-container *ngFor="let row of getImmediateSubkits(kit)">
          <app-kptarow [kitPart]="row" [editable]="false"></app-kptarow>
        </ng-container>
        <ng-container *ngFor="let row of getImmediateKitParts(kit)">
          <app-kptarow [kitPart]="row" [editable]="false"></app-kptarow>
        </ng-container>
      </div> -->
    </div>
  </mat-card>
</ng-container>
