<div class="container">
  <div class="card border-1 mx-3 mt-3 mb-0">
    <div class="card-header text-center">
      <div class="row mb-1 justify-content-center">
        <h2><b>Warehouse Transfer</b></h2>
      </div>
    </div>
    <div class="card-body text-center">
      <!--      warehouse select menu-->
      <ng-container *ngIf="!itemMenu">
        <div class="row">
          <div class="col-12 col-md-6 mt-2">
            <label for=""><b>From Whse:</b></label>
            <input class="form-control input" #input [(ngModel)]="fromWhse" [disabled]="isLoading"
              (typeaheadOnSelect)="fromWhseSelected($event)" [typeahead]="warehouseList" typeaheadOptionField="WhseDesc"
              placeholder="ex. 001" autocomplete="off">
          </div>
          <div class="col-12 col-md-6 mt-2">
            <label for=""><b>To Whse:</b></label>
            <input class="form-control input" #input [(ngModel)]="toWhse" [disabled]="isLoading"
              (typeaheadOnSelect)="toWhseSelected($event)" [typeahead]="warehouseList" typeaheadOptionField="WhseDesc"
              placeholder="ex. 001" autocomplete="off">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button class="btn btn-primary" [disabled]="!validWhseCheck()" (click)="itemMenu = !itemMenu">Select
              Items</button>
          </div>
        </div>
      </ng-container>
      <!--      item select menu-->
      <ng-container *ngIf="itemMenu">
        <div class="justify-content-center mt-1" id="mobileScreen">
          <ng-container *ngFor="let item of itemList; let index = index">
            <div [ngStyle]="{'background-color': colorChange(item.newQtyShippedTotal, item.QuantityOnHand) }">

              <div class="col-12 m-0 p-0">
                <div class="row justify-content-center m-0 p-0">
                  <div class="col">
                    <input class="form-control input" #input [(ngModel)]="item.name"
                      (keyup.enter)="itemSelectedNew(item.name, index, item.newQtyShippedTotal)"
                      typeaheadOptionField="ItemCode" placeholder="ItemCode" autocomplete="off">
                    <!-- if wanting to have it autocomplete [typeahead]="filtereditems" per Robert causes issues with
                        scanner  -->
                  </div>
                </div>
              </div>
              <div class="col-12 m-0 p-0">
                <div class="row justify-content-center">
                  <div class="d-flex justify-content-center mt-1">
                    <div class="p-2 text-center">
                      <button class="btn-primary btn"
                        (click)=" item.newQtyShippedTotal = item.newQtyShippedTotal > 0 ? item.newQtyShippedTotal - 1 : 0">
                        <i class="fas fa-minus"></i></button>
                    </div>
                    <div class="p-2 mt-1 text-center">
                      <input class="form-control form-control-sm" id="countLine" style="width: 50px;" type="number"
                        [(ngModel)]="item.newQtyShippedTotal" (click)="$event.target.select()" value="1"
                        (keyup.enter)="itemSelectedNew(item.name, index, item.newQtyShippedTotal)"
                        (change)="colorChange(item.newQtyShippedTotal, item.QuantityOnHand)" />
                    </div>
                    <div class="py-2 px-0 mt-2 text-center">
                      <b>QTY</b>
                    </div>
                    <div class="p-2 text-center">
                      <button class="btn-primary btn"
                        (click)="item.newQtyShippedTotal = item.newQtyShippedTotal >= 0 ? item.newQtyShippedTotal + 1 : 0">
                        <i class="fas fa-plus"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2 mb-2">
                <div class="col" *ngIf="itemList.length > 1">
                  Warehouse Count:
                  <input style="width: 50px;" [(ngModel)]="item.QuantityOnHand" disabled />
                  <button class="btn btn-primary m-2" (click)="DeleteItem(item)">Delete</button>
                </div>
              </div>

            </div>
          </ng-container>
        </div>

        <div class="justify-content-center mt-1" id="computer">
          <table class="table table-striped table-hover">
            <thead class="text-center">
              <tr>
                <td>From Whse</td>
                <td>QTY on Hand</td>
                <td>ItemCode</td>
                <td>Qty To Transfer</td>
                <td>To Whse</td>
                <td></td>
              </tr>
            </thead>
            <tbody class="text-center">
            </tbody>
            <tr *ngFor="let item of itemList; let index = index"
              [ngStyle]="{'background-color': colorChange(item.newQtyShippedTotal, item.QuantityOnHand) }">
              <td>{{fromWhse}}</td>
              <td>
                <input class="form-control input" type="number" disabled [(ngModel)]="item.QuantityOnHand" />
              </td>
              <td>
                <input class="form-control input" [(ngModel)]="item.name"
                  (keyup.enter)="itemSelectedNew(item.name, index, item.newQtyShippedTotal)"
                  (blur)="getQuantityOnHand(item.name, index)" placeholder="ItemCode" autocomplete="off" />
              </td>
              <td>
                <input class="form-control input" type="number" min="1" [(ngModel)]="item.newQtyShippedTotal"
                  (keyup.enter)="itemSelectedNew(item.name, index, item.newQtyShippedTotal)" />
              </td>
              <td>{{toWhse}}</td>
              <td *ngIf="itemList.length > 1"><button mat-raised-button color="danger" (click)="DeleteItem(item)">
                  <i class="fa fa-trash"></i>

                </button>
              </td>
            </tr>
          </table>

        </div>

        <button id="computer" class="col-12 m-2 p-0" id="CancelButton" class="btn btn-outline-success" (click)=" AddItem()">
          New Item
        </button>
        <div class="col-12 m-0 p-0">
          <div class="row mx-2 p-0" id="submitItems">
            <div id="computer">
              <button class="btn btn-outline-dark" (click)=" clearAndReturnHome()">Cancel</button>
            </div>
            <div>
              <button class="btn btn-primary" [disabled]="checkSubmit()"
                (click)="openModal(ConfrimSubmission)">Submit</button>
            </div>
          </div>
        </div>



      </ng-container>

    </div>
  </div>
</div>

<!-- update items modal -->
<ng-template #ConfrimSubmission>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2> Confirm Submission? </h2>
        <h5>Transfer parts from Warehouse {{fromWhse}} to Warehouse {{toWhse}}</h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-6 text-center">
        <button class="btn btn-primary" [disabled]="isSubmitting" (click)="submitTransfer()">Yes</button>
      </div>
      <div class="col-6 text-center">
        <button class="btn btn-primary" (click)="closeModal()">No</button>
      </div>
    </div>
  </div>
</ng-template>
