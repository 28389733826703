import { Component, ElementRef, HostBinding, Inject, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';

interface DialogData {
  title: string;
  message: string;
  superConfirmText: string;
}

@Component({
  selector: 'app-super-confirm-modal',
  templateUrl: './super-confirm-modal.component.html',
  styleUrls: ['./super-confirm-modal.component.css'],
})
export class SuperConfirmModalComponent {

  confTextControl = new FormControl('', [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<SuperConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    });
  }

  ok() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}