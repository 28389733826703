<div cdkDropListGroup>
  <div class="card border-0">
    <div class="card-body border-0">
      <div class="row">
        <div class="col-sm-3">
          <div class="card m-3">
            <div class="btn-group" dropdown [insideClick]="true">
              <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle">
                Select Supers <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li role="menuitem" *ngFor="let super of supersList; let supersIndex = index"><a class="dropdown-item" (click)="onSuperSelected(super.name, supersIndex)"><span>{{ super.name }}</span><i *ngIf="super.selected" class="fas fa-check fa-border fa-pull-right"></i></a></li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem"><a class="dropdown-item" (click)="onSuperSelected('', 0)">Select None</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card m-3">
            <div class="btn-group" dropdown [insideClick]="true">
              <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle">
                Select Statuses <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li role="menuitem" *ngFor="let status of statusesList; let statusesIndex = index"><a class="dropdown-item" (click)="onStatusSelected(status.name, statusesIndex)">{{ status.name }}<i *ngIf="status.selected" class="fas fa-check fa-border fa-pull-right"></i></a></li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem"><a class="dropdown-item" (click)="onStatusSelected('', 0)">Select None</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card m-3">
            <div class="btn-group" dropdown [insideClick]="true">
              <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle">
                Select Phases <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li role="menuitem" *ngFor="let phase of phasesList; let phasesIndex = index"><a class="dropdown-item" (click)="onPhaseSelected(phase.name, phasesIndex)">{{ phase.name }}<i *ngIf="phase.selected" class="fas fa-check fa-border fa-pull-right"></i></a></li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem"><a class="dropdown-item" (click)="onPhaseSelected('', 0)">Select None</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card m-3 border-0">
            <div class="text-right">
              <button type="button" class="btn btn-info" (click)="refreshSupers()">
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <ng-container *ngFor="let super of supersList; let supersIndex = index">
          <ng-container *ngIf="super.selected">
            <div class="col-sm-6 col-lg-4 col-xl-3">
              <div class="card m-3">
                <div class="card-header">
                  <div class="row">
                    <div class=" col-8 offset-2 text-center">
                      {{super.name}}
                    </div>
                    <div class="col-2 text-right">
                      <!-- spot for count of tickets -->
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-sm-4 border-0 p-0 text-sm text-center"><button class="btn" (click)="sortTickets(supersIndex)">Address</button></div>
                    <div class="col-sm-4 border-0 p-0 text-sm text-center">Subdivision</div>
                    <div class="col-sm-3 border-0 p-0 text-sm text-center">Status</div>
                    <div class="col-sm-1 border-0 p-0 text-sm text-center">Dip</div>
                  </div> -->
                </div>
                <ul 
                class="list-group list-group-flush"
                cdkDropList
                cdkDropListOrientation="vertical"
                [cdkDropListData]="super.tickets"
                (cdkDropListDropped)="drop($event, super)">
                <ng-container *ngFor="let item of super.tickets; let ticketIndex = index">
                    <li 
                    class="list-group-item"
                    [ngStyle]="displayTicket(supersIndex, ticketIndex) ? {'display' : ''} : {'display' : 'none'}"
                    
                    cdkDrag [cdkDragDisabled]="[item.disabled]">
                    <ul class="list-group list-group-horizontal-sm" *ngIf="item.disabled && item.updated">
                      <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center font-weight-bold text-danger">{{item.errorMessage}}</li>
                    </ul>
                    <ul class="list-group list-group-horizontal-sm">
                      <li class="list-group-item col-sm-8 border-0 p-0 text-sm font-weight-bold">{{item.phase | titlecase}}</li>
                      <li class="list-group-item col-sm-3 border-0 p-0 text-sm"><span class="badge badge-dark" *ngIf="item.phase.toLowerCase() === 'punch'">{{item.punchTime}}</span></li>
                      <li class="list-group-item col-sm-1 border-0 p-0 text-sm"><span class="badge badge-primary badge-pill" *ngIf="!item.disabled">{{item.dip}}</span><span *ngIf="item.disabled && !item.updated"><i class="fas fa-cog fa-spin fa-2x"></i></span></li>
                    </ul>
                    <ul class="list-group list-group-horizontal-sm">
                      <li class="list-group-item col-sm-4 border-0 p-0 text-sm">{{item.address}}</li>
                      <li class="list-group-item col-sm-5 border-0 p-0 text-sm">{{item.subdivision | titlecase}}</li>
                      <li class="list-group-item col-sm-3 border-0 p-0 text-sm">{{item.status}}</li>
                    </ul>                    
                    <ul class="list-group list-group-horizontal-sm" *ngIf="item.phase.toLowerCase() === 'punch'">
                      <li class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold">{{item.description}}</li>
                    </ul>
                  </li>
                 </ng-container>
                 <li 
                 class="list-group-item text-center"
                 [ngStyle]="showEmptyTicket(supersIndex) ? {'display' : 'none'} : {'display' : ''}"
                 ></li>
                </ul>
              </div>              
            </div>
          </ng-container> <!-- Selected Supers Appear -->
        </ng-container> <!-- List Supers Tickets -->
      </div>
    </div>
  </div>
</div>
