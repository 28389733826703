<app-bottom-sheet
  [open]="open"
  (sheetClosed)="closeSheet()"
  [sheetWidth]="screenSize.getClassesArray().includes('smd')? '100vw' : '75vw'"
  sheetBackground="#fafafa"
  [class]="screenSize.getClasses()"
>
  <div class="cqpbotsheet">
    <div class="cqpbotsheet-header">
      <h1 class="cqpbotsheet-header-title">
        Add custom part to: {{ getTitle() }}
      </h1>
    </div>

    <div class="cqpbotsheet-controls">
      <mat-form-field
        appearance="outline"
        class="cqpbotsheet-controls-ff cqpbotsheet-controls-name"
      >
        <mat-label>Part Code</mat-label>
        <input
          [formControl]="codeControl"
          [disabled]="loading"
          matInput
          type="text"
          class="cqpbotsheet-controls-ff-in"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="editpartbs-header-controls-ff editpartbs-header-controls-phase"
      >
        <mat-label>Phase</mat-label>
        <mat-select
          [formControl]="phaseControl"
          class="kitpartrow-card-header-front-phase-ff-sel"
        >
          <mat-option
            *ngFor="let phase of kitPartPhases"
            [value]="phase.valueOf()"
            >{{ phase.toString() }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="cqpbotsheet-controls-ff cqpbotsheet-controls-quantity"
      >
        <mat-label>Quantity</mat-label>
        <input
          [formControl]="quantityControl"
          [disabled]="loading"
          digitOnly
          min="1"
          max="9999"
          matInput
          type="text"
          class="cqpbotsheet-controls-ff-in"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="cqpbotsheet-controls-ff cqpbotsheet-controls-quantity"
      >
        <mat-label>Cost</mat-label>
        <input
          [formControl]="costControl"
          [disabled]="loading"
          (input)="costControlInput($event)"
          matInput
          type="text"
          class="cqpbotsheet-controls-ff-in"
        />
      </mat-form-field>
      <!-- Use a text box for the descControl -->
      <mat-form-field
        appearance="outline"
        class="cqpbotsheet-controls-ff cqpbotsheet-controls-desc"
      >
        <mat-label>Description</mat-label>
        <textarea
          [formControl]="descControl"
          matInput
          type="text"
          class="cqpbotsheet-controls-ff-in"
        ></textarea>
      </mat-form-field>
    </div>
    
    <div class="cqpbotsheet-actions">
      <button
        (click)="closeSheet()"
        color="warn"
        mat-flat-button
        class="cqpbotsheet-actions-cancel"
      >
        Cancel
      </button>
      <button
        [disabled]="!canAdd()"
        (click)="addCustomKit()"
        color="primary"
        mat-flat-button
        class="cqpbotsheet-actions-submit"
      >
        Add
      </button>
    </div>
  </div>
</app-bottom-sheet>
