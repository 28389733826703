<div class="container pb-5">
  <div class="card my-5 shadow-lg text-center" id="noprint">
    <ng-container id="noprint">
      <h1>Material On Schedule</h1>
      <div id="MaterialPullByDate">
        <div class="col-5">
            From Date:
            <input
            type="text"
            class="form-control p-2"
            id="FromDate"
            name="FromDate"
            bsDatepicker
            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }"
            [(bsValue)]="FromDate"
            />
        </div>
          <div class="col-5">
            To Date:
            <input
            type="text"
            class="form-control p-2"
            id="ToDate"
            name="ToDate"
            bsDatepicker
            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }"
            [(bsValue)]="ToDate"
            />
          </div>
          <div class="col-2" >
            <button mat-raised-button color="danger" (click)="GetMaterial(FromDate, ToDate)"><span *ngIf="!MatLoading">Get Material</span>
            <span *ngIf="MatLoading"><i class="fas fa-sync fa-spin"></i></span></button>
            </div>
        </div>

  </ng-container>
  <div *ngIf="showMaterialsScheduled && !MatLoading">
    <div *ngIf="materialsOnSchedule.length ==0">
      <h3>No items on Schedule.</h3>
    </div>
    <table class="table table-hover table-striped" *ngIf="materialsOnSchedule.length > 0">
      <thead>
        <tr>
          <th>Item</th>
          <th>Description</th>
          <th>Job Number</th>
          <th>Address</th>
          <th>Scheduled Date</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let mat of materialsOnSchedule">
          <td>{{mat.Item}}</td>
          <td>{{mat.Description}}</td>
          <td>{{mat.JobNumber}}</td>
          <td>{{mat.Address}}</td>
          <td>{{mat.ScheduledDate}}</td>
          <td>{{mat.Quantity}}</td>
        </tr>
      </tbody>
    </table>
    <button [disabled]="sending"
    class="mb-2" mat-raised-button color="danger" (click)="saveExcel(FromDate, ToDate)">Download Excel</button>
  </div>
    </div>
    </div>
    <div *ngIf="showMaterialsScheduled" id="print-view-page">
      <table class="table table-hover table-striped">
    <thead>
      <tr>
        <th>Item</th>
        <th>Description</th>
        <th>Job Number</th>
        <th>Address</th>
        <th>Scheduled Date</th>
        <th>Quantity</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let mat of materialsOnSchedule">
        <td>{{mat.Item}}</td>
        <td>{{mat.Description}}</td>
        <td>{{mat.JobNumber}}</td>
        <td>{{mat.Address}}</td>
        <td>{{mat.ScheduledDate}}</td>
        <td>{{mat.Ordered}}</td>
      </tr>
    </tbody>
    </table>
        </div>
