<div [class]="screenSize.getClasses() + ' dattab'">
    <mat-card class="dattab-card">
        <mat-card-title class="dattab-card-title">
            {{title}}
            <ng-content select="[table-title]"></ng-content>

            <button
                *ngIf="includePdfDownload"
                mat-raised-button
                class="dattab-card-title-pdf"
                [matMenuTriggerFor]="pdfMenu"
            >
                <i class="fas fa-file-download"></i>
            </button>
            <mat-menu #pdfMenu="matMenu">
                <button (click)="openPdf()" mat-menu-item>PDF</button>
                <button 
                    *ngIf="data?.sort?.direction" 
                    (click)="openGroupedPdf()" 
                    mat-menu-item
                >
                    PDF with {{data?.sort?.active}} Grouping
                </button>
            </mat-menu>
        </mat-card-title>
        <!-- <span>{{rawData | json}}</span> -->
        <mat-card-content>
            <div *ngIf="isLoading" class="dattab-card-loading">
                <mat-spinner diameter="75" class="dattab-card-loading-pos-spinner"></mat-spinner>
            </div>
            <ng-content select="[table-content]"></ng-content>

            <ng-container *ngIf="(accordianControls && !screenSize.getClassesArray().includes(accordianScreenSize))">
                <ng-container *ngTemplateOutlet="controlsTemplate"></ng-container>
            </ng-container>
            <ng-container *ngIf="!accordianControls || (accordianControls && screenSize.getClassesArray().includes(accordianScreenSize))">
                <mat-expansion-panel class="dattab-card-accordian mat-elevation-z0">
                    <mat-expansion-panel-header>{{accordianTitle}}</mat-expansion-panel-header>
                    <ng-container *ngTemplateOutlet="controlsTemplate"></ng-container>
                </mat-expansion-panel>
            </ng-container>

            <ng-template #controlsTemplate>
                <div *ngIf="!noControls" class="dattab-card-controls">
                    <mat-expansion-panel *ngIf="screenSize.getClassesArray().includes('xsd')" class="mat-elevation-z0">
                    </mat-expansion-panel>
                    <ng-content select="[table-controls]"></ng-content>
    
                    <ng-container *ngIf="includeColumnSelector">
                        <app-searchable-msel [disabled]="isLoading" (onSelectChange)="onSelectedColumnsChange($event)"
                            [options]="includeColumnSelectorOptions" [defaultSelected]="includeColumnSelectorDefaultOptions"
                            placeholder="Columns" class="dattab-card-controls-ff"></app-searchable-msel>
                    </ng-container>
    
                    <ng-container *ngFor="let drange of drangeColumns; index as drpIndex">
                        <mat-form-field appearance="outline" class="dattab-card-controls-ff">
                            <mat-label>{{drange.title}}</mat-label>
                            <mat-date-range-input [formGroup]="drangeControls[drpIndex]" [rangePicker]="drangePicker"
                                [min]="normalizeDateStringToISO(drange.range.min)"
                                [max]="normalizeDateStringToISO(drange.range.max)" [disabled]="isLoading">
                                <input matStartDate formControlName="start" placeholder="Start date"
                                    (dateChange)="onDrangeChange($event, drange, drpIndex)">
                                <input matEndDate formControlName="end" placeholder="End date"
                                    (dateChange)="onDrangeChange($event, drange, drpIndex)">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="drangePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #drangePicker [disabled]="isLoading"></mat-date-range-picker>
                        </mat-form-field>
                    </ng-container>
    
                    <ng-container *ngFor="let dpicker of dpickerColumns; index as dpIndex">
                        <mat-form-field appearance="outline" [formGroup]="dpickerControls[dpIndex]"
                            class="dattab-card-controls-ff">
                            <mat-label>{{dpicker.title}}</mat-label>
                            <input (dateChange)="onDpickerChange($event, dpicker, dpIndex)"
                                (input)="onDpickerChange($event, dpicker, dpIndex)" matInput [matDatepicker]="datePicker"
                                [min]="normalizeDateStringToISO(dpicker.range.min)"
                                [max]="normalizeDateStringToISO(dpicker.range.max)" [disabled]="isLoading"
                                formControlName="value" />
                            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                            <mat-datepicker #datePicker [disabled]="isLoading"></mat-datepicker>
                        </mat-form-field>
                    </ng-container>
    
                    <ng-container *ngFor="let msel of mselColumns; index as mselIndex">
                        <app-searchable-msel [disabled]="isLoading || mselColumnOptions[mselIndex].length == 0" [required]="true" (onSelectChange)="onMselChange($event, msel, mselIndex)"
                            [options]="mselColumnOptions[mselIndex]" [defaultSelected]="mselColumnOptions[mselIndex]"
                            [placeholder]="msel.title" class="dattab-card-controls-ff"></app-searchable-msel>
                    </ng-container>
    
                    <ng-container *ngFor="let sel of selColumns; index as selIndex">
                        <app-searchable-sel [disabled]="isLoading || selColumnOptions[selIndex].length == 0" [required]="true" (onSelectChange)="onSelChange($event, sel, selIndex)"
                            [options]="selColumnOptions[selIndex]" [placeholder]="sel.title" class="dattab-card-controls-ff"></app-searchable-sel>
                    </ng-container>
                    
                    <ng-container *ngFor="let inCol of inColumns; index as inIndex">
                        <mat-form-field appearance="outline" class="dattab-card-controls-ff">
                            <mat-label>{{inCol.title}}</mat-label>
                            <input matInput type="text" [ngModel]="inControls[inIndex].value"
                                (input)="onInputChange($event, inCol, inIndex)" [disabled]="isLoading || rawData.length == 0"
                                class="dattab-card-controls-item dattab-card-controls-address" />
                        </mat-form-field>
                    </ng-container>
    
                    <button 
                        (click)="onSearch()" 
                        *ngIf="!searchOnChange" 
                        [disabled]="isLoading || !getSearchable()" 
                        mat-raised-button 
                        color="accent" 
                        extended 
                        class="dattab-card-controls-item dattab-card-controls-search"
                    >
                        Search
                    </button>
    
                    <button 
                        *ngIf="includeReset" 
                        (click)="resetControlsAndOptions()" 
                        mat-mini-fab
                        color="primary"
                        [disabled]="isLoading" 
                        class="dattab-card-controls-reset"
                    >
                        <img
                            src="/assets/resetIcon.svg"
                            alt="reset button"
                            class="dattab-card-controls-reset-icon"
                        />
                    </button>
                </div>
            </ng-template>


            <div class="dattab-card-data">

                <div class="dattab-card-data-ctn">
                    <table mat-table [dataSource]="data" matSort class="dattab-card-data-ctn-table">

                        <ng-container *ngFor="let tab of getTableColumnsThatMatchColumnSelect()">
                            <ng-container *ngIf="!tab.hidden" [matColumnDef]="tab.col">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="dattab-card-data-ctn-table-th"> {{tab.title}} </th>
                                <td mat-cell *matCellDef="let element" [class]="tab.size?tab.size:''" class="dattab-card-data-ctn-table-td">
                                    <span class="dattab-card-data-ctn-table-td-con">
                                        {{ getColumnValue(element, element[tab.col], tab.value) }}
                                    </span>
                                </td>
                            </ng-container>
                        
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="getTableColumns()">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: getTableColumns();">
                        </tr>
                    </table>
                </div>
                <div class="dattab-card-data-table-controls">
                    <mat-paginator #dataPaginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                </div>

            </div>
            <ng-content select="[table-footer]"></ng-content>
        </mat-card-content>
    </mat-card>
</div>