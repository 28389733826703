<div class="medgal">
    <div class="medgal-content">
        <ng-container *ngFor="let slide of slides; let slideIndex = index">
            <div *ngIf="isDisplayableMedia(slide)" [class.hidden]="slideIndex != currentSlide" class="medgal-content-slide">
                <img [src]="slide" class="medgal-content-slide-img" />
                <a [href]="slide" target="_blank" class="medgal-content-slide-title">{{slide}}</a>
            </div>
            <div *ngIf="!isDisplayableMedia(slide)" [class.hidden]="slideIndex != currentSlide" class="medgal-content-slide not-displayable-media">
                <img src="/assets/imageIcon.svg" class="medgal-content-slide-img" />
                <div class="medgal-content-slide-note">Download {{getFileExtension(slide)}} file to view</div>
                <a [href]="slide" target="_blank" class="medgal-content-slide-title">{{slide}}</a>
            </div>
        </ng-container>
        <i (click)="onPreviousClick()" class="fas fa-chevron-left arrow left medgal-control"></i>
        <i (click)="onNextClick()" class="fas fa-chevron-right arrow right medgal-control"></i>
    </div>
    
    <div class="medgal-picker" >
        <div class="medgal-picker-content" #medgalPickerContent>
            <ng-container *ngFor="let slide of slides; let slideIndex = index">
                <div (click)="currentSlide=slideIndex" [class.active]="slideIndex == currentSlide" class="medgal-picker-content-slide" #medgalPickerContentSlide>
                    <img *ngIf="isDisplayableMedia(slide)" [src]="slide" class="medgal-picker-content-slide-img" />
                    <img *ngIf="!isDisplayableMedia(slide)" src="/assets/imageIcon.svg" class="medgal-picker-content-slide-img" />
                </div>
            </ng-container>
        </div>
        <i class="fas fa-chevron-left arrow left medgal-picker-control" (click)="scrollPicker(-175)"></i>
        <i class="fas fa-chevron-right arrow right medgal-picker-control" (click)="scrollPicker(175)"></i>
    </div>
</div>