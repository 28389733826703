import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import KitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../../Platform/bottom-sheet/bottom-sheet.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import ContextKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitDtoInterface';
import PartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/PartDtoInterface';
import { map } from 'rxjs-compat/operator/map';
import ContextKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitPartDtoInterface';
import KitUtil from 'src/app/utils/KitUtil';
import { Context } from 'vm';
import { Observable, zip } from 'rxjs';
import KitPartPhases from '../KitPartPhases';
import kitPartPhases from '../KitPartPhases';
import KitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitPartDtoInterface';
import QuoteUtil from 'src/app/utils/QuoteUtil';
import ContextQuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteDtoInterface';
import ContextQuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteKitPartDtoInterface';
import ContextQuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuotePartInterface';
import QuoteKitPartEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteKitPartEditableInterface';
import ContextPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextPartDtoInterface';
import KitRegions from '../KitRegions';
import KitOverviewDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitOverviewDtoInterface';

interface AddItemInterface {
  data: KitOverviewDtoInterface|ContextPartDtoInterface;
  selected: boolean;
  quantity: number;
  phase: string;
}

interface AddPartInterface  {
  Part_guid: string;
  quantity: number;
  phase: string;
  cost: number;
}

interface AddKitInterface  {
  Kit_guid: string;
  quantity: number;
}

interface SelectableKitPart {
  data: ContextKitPartDtoInterface;
  selected: boolean;
  phase: string;
}

export interface AddItemsOutputInterface {
  parts: AddPartInterface[];
  kits: AddKitInterface[];
}

@Component({
  selector: 'app-korp-picker-botsheet',
  templateUrl: './KitOrPartPickerBotSheetComponent.html',
  styleUrls: ['./KitOrPartPickerBotSheetComponent.css', '../QuotingTheme.scss']
})
export class KitOrPartPickerBotSheetComponent implements OnInit, OnChanges {

  kitutil = new KitUtil();
  quoteutil = new QuoteUtil();

  @Input() parent: {parentGuid?: string; parentName: string;}|null = null;
  @Input() existingQuoteParts: ContextQuotePartDtoInterface[] = [];

  @Input() open = false;
  addPartsLoading = false;
  addPartsTypeControl = new FormControl('Part', [Validators.required]);
  addPartsNameControl = new FormControl('');
  addPartsRegionControl = new FormControl('All');
  addPartsPage = 0;
  addPartsPartPageSize = 25;
  addPartsKitPageSize = 25;
  addPartsHasMore = false;
  
  addPartsList: AddItemInterface[] = [];

  kitPartPhases = KitPartPhases;
  kitRegions = KitRegions;

  enableSearchDescription = false;

  @Output() addItems = new EventEmitter<AddItemsOutputInterface>();
  @Output() sheetClosed = new EventEmitter<void>();

  constructor(
    public api: SageApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ){}

  ngOnChanges(changes: SimpleChanges) {
    // if open changed to true, make sure you run the sheetOpened function
    if(changes?.open?.currentValue == true){
      this.sheetOpened();
    }
  }

  ngOnInit() {}

  disableControls(){
    // Yeah, pretty much the guys over at Angular are idiots for making you disable the control, but not listening the the element which is also disabled 
    this.addPartsTypeControl.disable();
    this.addPartsNameControl.disable();
    this.addPartsRegionControl.disable();
  }

  enableControls(){
    // Yeah, pretty much the guys over at Angular are idiots for making you disable the control, but not listening the the element which is also disabled 
    this.addPartsTypeControl.enable();
    this.addPartsNameControl.enable();
    this.addPartsRegionControl.enable();
  }

  removeUnselectedParts() {
    this.addPartsList = this.addPartsList.filter((part)=>{
      return part.selected;
    });
  }

  searchPartOrKit(page?: number){ // if undefined, it's a new search, otherwise, add to the results
    if(page == undefined){
      this.addPartsPage = 0;
      this.addPartsHasMore = false;
      this.removeUnselectedParts();
    }else{
      this.addPartsPage = page;
    }
    
    if(this.addPartsTypeControl.value == 'Part' && this.addPartsLoading == false){
      this.disableControls();
      this.addPartsLoading = true;
      const existingPartGuids: string[] = this.addPartsList.filter((apli)=>{
        return (apli.data as any)?.Part_guid != undefined;
      }).map((p)=>{
        return (p.data as unknown as PartDtoInterface)?.Part_guid;
      });

      this.api.pullReport(`parts?size=${this.addPartsPartPageSize}&offset=${this.addPartsPartPageSize*this.addPartsPage}&Part_Code=${this.addPartsNameControl.value}&searchDesc=${this.enableSearchDescription}`)
      .subscribe((data: PartDtoInterface[])=>{
        if(data.length == this.addPartsPartPageSize){
          this.addPartsHasMore = true;
        }else{
          this.addPartsHasMore = false;
        }
        this.addPartsLoading = false;
        // Yeah, pretty much the guys over at Angular are idiots for making you disable the control, but not listening the the element which is also disabled 
        this.enableControls();
        this.addPartsList =  [
          ...this.addPartsList, 
          ...data.map((originalPart)=>{
            const part: ContextPartDtoInterface = JSON.parse(JSON.stringify(originalPart));
            // If the part is in the existingQuoteParts, change it's pricing to the existingQuoteParts pricing
            const existingPart = this.existingQuoteParts.find((eqp)=>{
              return eqp.QuotePart.QuotePart_Code == part.Part.Part_Code;
            });
            if(existingPart != undefined){
              part.Part.Part_Cost = existingPart.QuotePart.QuotePart_Cost;
            }
            return {
              data: part, 
              selected: false, 
              quantity: 1, 
              phase: kitPartPhases[0].valueOf()
            };
          }).filter((itm)=>{
            return !existingPartGuids.includes(itm.data.Part.Part_guid);
          }),
        ];
      },
      (err)=>{
        this.addPartsLoading = false;
        this.enableControls();
        this.snackBar.open('There was an error searching for parts. Please try again.', 'Dismiss', {
          duration: Infinity,
        });
      });
    }else if(this.addPartsTypeControl.value == 'Kit' && this.addPartsLoading == false){
      this.disableControls();
      this.addPartsLoading = true;
      let kiturl = `kits-overview?size=${this.addPartsKitPageSize}&offset=${this.addPartsPage*this.addPartsKitPageSize}&Kit_Name=${this.addPartsNameControl.value.trim()}&searchDesc=${this.enableSearchDescription}`;
      if(this.addPartsNameControl.value.trim() == ''){
        // Get the date from two weeks ago and use that for the search instead
        const updatedDate = new Date();
        updatedDate.setDate(updatedDate.getDate() - 14);
        kiturl += `&updated_at=${updatedDate.toISOString().slice(0, 10)}`;
      }
      if(this.addPartsRegionControl.value != 'All'){
        kiturl += `&Kit_Region=${this.addPartsRegionControl.value}`;
      }
      this.api.pullReport(
        kiturl, 
        {
          matchCriteria: [
            kiturl
          ], 
          exp: 60000 // Cache for 1 min
        })
      .subscribe((data: KitOverviewDtoInterface[])=>{

        if(data.length == this.addPartsKitPageSize){
          this.addPartsHasMore = true;
        }else{
          this.addPartsHasMore = false;
        }

        this.addPartsLoading = false;
        this.enableControls();
        const existingKitGuids: string[] = this.addPartsList.filter((apli)=>{
          return (apli.data as any)?.Kit != undefined;
        }).map((k)=>{
          return (k.data as unknown as ContextKitDtoInterface)?.Kit?.Kit_guid;
        });
        
        this.addPartsList =  [
          ...this.addPartsList, 
          ...data.map((part)=>{
            return {data: part, selected: false, quantity: 1, phase: null};
          }).filter((itm)=>{
            return !existingKitGuids.includes(itm.data.Kit_guid);
          }),
        ];
      },
      (err)=>{
        this.addPartsLoading = false;
        this.enableControls();
        this.snackBar.open('There was an error searching for parts. Please try again.', 'Dismiss', {
          duration: Infinity,
        });
      });
    }
  }

  closeAddPartsSheet(){
    this.sheetClosed.emit();
  }

  sheetOpened(){
    this.addPartsList = [];
    this.addPartsNameControl.setValue('');
    this.addPartsHasMore = false;
  }

  addSelectedPartsToKit(){
    const toAdd = this.getSelectedAddPartsToKit().map((part)=>{
      const obj = {
        KitPart_Quantity: part.quantity,
        Part_guid: (part.data as any)?.Part?.Part_guid,
        Kit_guid: (part.data as any)?.Kit_guid,
        KitPart_Phase: part?.phase,
        KitPart_Cost: (part.data as any)?.Part?.Part_Cost,
      };
      return obj;
    });

    const toAddParts: AddPartInterface[] = toAdd.filter((upart)=>{
      return upart.Part_guid != undefined;
    }).map(part=>{
      return {
        Part_guid: part.Part_guid,
        quantity: part.KitPart_Quantity,
        phase: part.KitPart_Phase,
        cost: part.KitPart_Cost,
      };
    });

    const toAddKits: AddKitInterface[] = toAdd.filter((upart)=>{
      return upart.Kit_guid != undefined;
    }).map(kit=>{
      return {
        Kit_guid: kit.Kit_guid,
        quantity: kit.KitPart_Quantity,
      };
    });

    this.addItems.emit({
      parts: toAddParts, 
      kits: toAddKits
    });

  }

  getSelectedAddPartsToKit(){
    return this.addPartsList.filter((item) => item.selected);
  }

  getTitle(){
    return this?.parent?.parentName||'';
  }

}