<div>
    <div style="min-height: 100%;">
        <div id="content" [hidden]="isSubdivisionLoading">
          <div class="actions">

            <mat-form-field class="w-25">
              <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Dove Creek" #input>
          </mat-form-field>
          <button mat-raised-button class="float-right" color="danger" (click)="openDialog()">Add Subdivision <mat-icon>add</mat-icon></button>
          </div>
            <div class="mat-elevation-z8 mat-table-responsive" style="overflow: auto;">
                <table mat-table [dataSource]="dataSource" [ngStyle]="{'width':'100%'}" matSort>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-form-field floatLabel="never">
                                <input matInput placeholder="city" [value]="element.name" disabled style="color: black;">
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="superintendentMain">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Superintendent </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-form-field floatLabel="never">
                                <mat-label>Choose an option</mat-label>
                                <mat-select [(value)]="element.superintendentMain">
                                    <ng-container *ngFor="let super of superintendentList">
                                        <mat-option [value]="super.UDF_LEADMAN.toLocaleLowerCase()">{{ super.Leadman | titlecase }}</mat-option>
                                    </ng-container>
                                </mat-select>
                              </mat-form-field>
                            </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="superintendentGround">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ground Super </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-form-field floatLabel="never">
                                <mat-label>Choose an option</mat-label>
                                <mat-select [(ngModel)]="element.superintendentGround">
                                    <ng-container *ngFor="let super of superintendentList">
                                        <mat-option [value]="super.UDF_LEADMAN.toLocaleLowerCase()">{{ super.Leadman | titlecase }}</mat-option>
                                    </ng-container>
                                </mat-select>
                              </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="city">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> City Name </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-form-field floatLabel="never">
                                <input matInput placeholder="city" [value]="element.city" [(ngModel)]="element.city">
                            </mat-form-field>
                        </td>

                    </ng-container>

                    <ng-container matColumnDef="gas">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Gas </th>
                        <td mat-cell *matCellDef="let element" class="text-center">
                            <mat-checkbox class="" [value]="element.gas" color="primary" [(ngModel)]="element.gas"></mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="cityCrew">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> City Crew Required </th>
                        <td mat-cell *matCellDef="let element" class="text-center">
                            <mat-checkbox class="" [value]="element.cityCrew" color="primary" [(ngModel)]="element.cityCrew"></mat-checkbox>
                        </td>
                    </ng-container> -->

                    <ng-container matColumnDef="cityInspection">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> City Inspection Required </th>
                        <td mat-cell *matCellDef="let element" class="text-center">
                            <mat-checkbox class="" [value]="element.cityInspection" color="primary" [(ngModel)]="element.cityInspection"></mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tnrccInspection">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tnrcc Required </th>
                        <td mat-cell *matCellDef="let element" class="text-center">
                            <mat-checkbox class="" [value]="element.tnrccInspection" color="primary" [(ngModel)]="element.tnrccInspection"></mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="!element.updating">
                                <button mat-raised-button (click)="saveSubdivision(element)">
                                    {{ element.updated ? 'Saved' : 'Save' }}
                                    <mat-icon class="text-success">save</mat-icon>
                                </button>
                            </ng-container>

                            <ng-container *ngIf="element.updating">
                                <mat-icon class="text-success">send</mat-icon>
                                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </ng-container>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: true;"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableDisplayedColumns;"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="5">No data matching the filter "{{ input.value }}"</td>
                    </tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>

