import { MatPaginator } from "@angular/material/paginator";
import { SageApiService } from "src/app/_services/sageApi/sageApi.service";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { AlertifyService } from "src/app/_services/alertify/alertify.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

export interface PaymentBreakDown {
  amount: string;
  paymentType: string;
  jobType: string;
  batchName: string;
  builderPo: string;
  builderBillable: string;
  address: string;
}
export interface PaymentAmount {
  cash: number;
  check: number;
  CC: number;
  refund: number;
  Builders: number;
  AggregateOfService: number;
}
@Component({
  selector: "app-batching-payments",
  templateUrl: "./batching-payments.component.html",
  styleUrls: ["./batching-payments.component.css"],
})
export class BatchingPaymentsComponent implements OnInit {
  sendingPayments: boolean;
  sendingItems: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  modalTemplate: BsModalRef;
  items = [];

  displayedColumns: string[] = [
    "amount",
    "paymentType",
    "jobType",
    "batchName",
    "builderPo",
    "builderBillable",
    "address",
  ];
  paymentAmount: PaymentAmount;
  dataSource: MatTableDataSource<PaymentBreakDown> | null;
  paymentLoading: boolean;
  itemLoading: boolean;
  payments: PaymentBreakDown[] = [];
  serviceTotal: number;
  constructor(
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.getBatchesForToday();
    this.totals();
    this.getItemsPostedInvoice();
  }
  AfterLoad() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getBatchesForToday() {
    this.payments = [];
    this.paymentLoading = true;
    this.sageApi.pullReport("Pay").subscribe((rows: Array<any>) => {
      if (Array.isArray(rows)) {
        rows.forEach((row) => {
          let payment = "";
          if (row[1] == "633") {
            payment = "Cash";
          } else if (row[1] == "634") {
            payment = "Check";
          } else if (row[1] == "638") {
            payment = "Credit Card";
          } else if (row[1] == "639") {
            payment = "Refund - Credit Card";
          } else if (row[1] == "642") {
            payment = "Imported Default Credit Card";
          } else if (row[1] == "6738147") {
            payment = "Refund - Check";
          }

          const obj: PaymentBreakDown = {
            amount: row[0],
            paymentType: row[5] == "YES" ? 'Builder' : payment,
            jobType: row[2] == "514" ? "Service" : "Builder Billable",
            batchName: row[3],
            builderPo: row[4],
            builderBillable: row[5],
            address: row[6],
          };
          this.payments.push(obj);
        });
        console.log(this.payments);
        this.dataSource = new MatTableDataSource(this.payments);
        this.AfterLoad();
        this.paymentLoading = false;

      }
    });
  }

  totals() {
    this.serviceTotal = 0;
    this.paymentAmount = {
      cash: 0,
      CC: 0,
      check: 0,
      refund: 0,
      Builders: 0,
      AggregateOfService: 0,
    };
    this.sageApi
      .pullReport("ServiceTitanIntegration/AggregateDailyPayments")
      .subscribe(
        (obj) => {
          console.log(obj);
          this.paymentAmount = obj;
          this.serviceTotal =
            this.paymentAmount.CC +
            this.paymentAmount.cash +
            this.paymentAmount.check -
            +this.paymentAmount.refund;
        },
        (err) => {
          console.log(err.message);
          this.alertify.error(err.message);
        }
      );
    console.log(this.paymentAmount);
  }

  getItemsPostedInvoice() {
    this.itemLoading = true;
    this.items = [];
    this.sageApi
      .pullReport("ServiceTitan/ItemsInInvoice")
      .subscribe((rows: Array<any>) => {
        console.log(rows);
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            let obj = {
              quantity: Math.round(row.quantity),
              itemcode: row.skuName,
              description: row.description,
              inventoryLocation: row.inventoryLocation,
            };
            this.items.push(obj);
          });
        }
        this.itemLoading = false;
      });
  }

  submitPaymentToSage() {
    this.sendingPayments = true;
    this.sageApi
      .pullReport("ServiceTitanIntegration/DailyFinancialsToSage/PROD")
      .subscribe(
        (resp) => {
          console.log(resp);
          this.alertify.success(resp);
        },
        (err) => {
          this.closeModal();
          this.sendingPayments = false;
          console.log(err);
          this.alertify.error(err.message);
        },
        () => {
          this.closeModal();
          this.sendingPayments = false;
        }
      );
  }

  submitItemsToSage() {
    this.sendingItems = true;
    this.sageApi
      .pullReport("ServiceTitanIntegration/PROD/RemoveItemsFromTruckInSage")
      .subscribe(
        (resp) => {
          this.alertify.success(resp);
        },
        (err) => {
          this.closeModal();
          this.sendingItems = false;
          console.log(err);
          this.alertify.error(err.message);
        },
        () => {
          this.closeModal();
          this.sendingItems = false;
        }
      );
  }

  openModal(template: TemplateRef<any>) {
    this.modalTemplate = this.modalService.show(template);
  }
  closeModal() {
    this.modalTemplate.hide();
  }
}
