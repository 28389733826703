import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatDialog } from '@angular/material/dialog';
import KitOverviewDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitOverviewDtoInterface';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import ContextPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextPartDtoInterface';

@Component({
  selector: 'app-korp-picker',
  templateUrl: './KitOrPartPickerComponent.html',
  styleUrls: ['./KitOrPartPickerComponent.css', '../QuotingTheme.scss'],
})
export class KitOrPartPickerComponent implements OnInit, OnDestroy, OnChanges {

  @Input() disabled: boolean = false;

  loading = false;

  @Input() typeControl = new FormControl('Part', [Validators.required]);
  @Input() control: FormControl = new FormControl('', [Validators.required]);
  @Input() selectedControl = new FormControl('', [Validators.required]);
  @Input() selectedOptions:  KitOverviewDtoInterface[]|ContextPartDtoInterface[] = [];
  selectedFilteredOptionsSubject: Subject<string> = new Subject<string>();
  searchControl = new FormControl('');

  enableSearchDescription = false;

  constructor(
    public screenSize: ScreenSizeService,
    public api: SageApiService,
    public dialog: MatDialog
  ) {
    this.selectedFilteredOptionsSubject.pipe(debounceTime(2000)).subscribe((searchVal) => {
      this.loadNewQuoteAnswerKitOrPartOptions(searchVal);
    });
  }

  ngOnChanges(changes: SimpleChanges) { }
  
  ngOnInit() {
    // If control .value is a kitdto or partdto, then set selectedControl to that Part or Kit
    if(typeof this.control.value == 'object' && 'Part_guid' in this.control.value){
      this.typeControl.setValue('Part');
    }
    if(typeof this.control.value == 'object' && 'Kit_guid' in this.control.value){
      this.typeControl.setValue('Kit');
    }
  }

  ngOnDestroy() {}

  resetControls(){
    this.typeControl.setValue('Part');
    this.control = new FormControl('', [Validators.required]);
    this.selectedControl = new FormControl('', [Validators.required]);
    this.selectedOptions = [];
    this.searchControl.setValue('');
  }

  valid(){
    return this.loading == false && this.selectedControl.valid;
  }

  loadNewQuoteAnswerKitOrPartOptions(searchVal){
    const type = this.typeControl.value;
    if(type === 'Part'){
      this.api.pullReport(`parts?Part_Code=${searchVal}`).subscribe((data: ContextPartDtoInterface[])=>{
        this.selectedOptions = data;
      });
    }else if(type === 'Kit'){
      this.api.pullReport(`kits-overview?Kit_Name=${searchVal}`).subscribe((data: KitOverviewDtoInterface[])=>{
        this.selectedOptions = data;
      });
    }
  }

  kitOrPartControlChange(){
    this.control.setValue(''); 
    this.selectedOptions = []; 
    this.selectedControl.setValue(''); 
    this.searchControl.setValue('');
  }

}