
import { ApplicationRef, enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}


if (isDevMode()) {
  enableDevTooling();
}else{
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
}

async function enableDevTooling() {
  const { injector } = await platformBrowserDynamic().bootstrapModule(AppModule);
  const { enableDebugTools } = await import('@angular/platform-browser');
  const { components } = injector.get(ApplicationRef);
  for(let comp of components){
    enableDebugTools(comp);
  }
}