import {
  Component,
  Input,
  OnInit,
  HostListener,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import {concat, Subscription} from 'rxjs';
import {AlertifyService} from '../../../_services/alertify/alertify.service';
import {SageApiService} from '../../../_services/sageApi/sageApi.service';
import {AuthService} from '../../../_services/auth/auth.service';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead/typeahead-match.class';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {DatePipe} from '@angular/common';
import {tick} from '@angular/core/testing';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {any} from 'codelyzer/util/function';
import {tryCatch} from 'rxjs/internal-compatibility';
import ScheduleTicketInterface from '../interfaces/ScheduleTicketInterface';
import AssignSchedule_WTResponseType from 'src/app/_services/sageApi/interfaces/pullReport/AssignSchedule_WTResponseType';
import SubdivisionResponseType from 'src/app/_services/sageApi/interfaces/pullReport/SubdivisionResponseType';

@Component({
  selector: 'app-assign-schedule',
  templateUrl: './assign-schedule.component.html',
  styleUrls: ['./assign-schedule.component.css']
})
export class AssignScheduleComponent implements OnInit, AfterViewInit {
  bsConfig: Partial<BsDatepickerConfig>;
  modalTemplate: BsModalRef;
  addressName = '';
  phaseName = '';
  addressLoaded: boolean;
  addressList = [];
  AMSubdivisionsList = [];
  subDivList = [];
  wtsLoading: boolean;
  phaseList = [];
  techList = [];
  unassignedList = [];
  cityRegionList = [];
  sewerWaterList = [];
  SWCheckList = [];
  missingWaterSewer: {
    SalesOrderNo: string,
    Phase: string
  }[] = [];

  tickets: ScheduleTicketInterface[] = [];
  sortTenDay = {
    ticketsByDay: []
  };

  filterList: {
    name: string,
    superFilter: string,
    warehouseFilter: string,
    cityFilter: string,
    filterType: string
  }[] = [];
  selectedFilterName: string;
  selectedFilter: {
    name: string,
    superFilter: string,
    warehouseFilter: string,
    cityFilter: string,
    filterType: string
  };

  fiveDayList: Map<string, ScheduleTicketInterface[]>;
  fiveDayArray: {
    col: string;
    tickets: ScheduleTicketInterface[];
  }[] = [];

  WTTechSchedule = [];
  tenDayTechSchedule = [];
  weekofDate: Date;

  colOneDate: string;
  colTwoDate: string;
  colThreeDate: string;
  colFourDate: string;
  colFiveDate: string;
  colSixDate: string;
  colSevenDate: string;
  colOneTotalTime: string;
  colTwoTotalTime: string;
  colThreeTotalTime: string;
  colFourTotalTime: string;
  colFiveTotalTime: string;
  colSixTotalTime: string;
  colSevenTotalTime: string;
  filterTechName = '';
  filterscheduleGroup = '';
  isLoading: boolean;
  selectedWT: object;
  selectedSONum: string;
  selectedScheduleDate: Date;
  selectedWTNum: string;
  chosenCrewID: string;
  assigningDate: boolean;

  countMap: Map<string, string>;
  badDate: Map<string, boolean>;

  assignWTNo: string;
  assignAddress: string;
  assignDivision: string;
  assignItemCode: string;
  assignCrew: string;
  assignSONo: string;
  assignWTNum: string;
  assignWTStep: string;
  assignStatus: string;

  wtFilter: string;
  addressFilter: string;
  phaseFilter: string;
  crewFilter: string;
  divisionFilter: string;
  statusFilter: string;

  confirmDate: string;

  sanARegion: boolean;
  dallasRegion: boolean;
  otherRegion: boolean;

  sanTech: boolean;
  dallasTech: boolean;
  otherTech: boolean;

  saleOrderTicket: string;
  wTNumTicket: string;
  modalAssignDate: boolean;
  modalSetRTP: boolean;
  // techAssignDate: Date;

  dragSONum: string;
  dragWTNum: string;
  dragTech: string;
  dragItemCode: string;

  loadRefresh: boolean;

  subdivs: SubdivisionResponseType;

  // tslint:disable-next-line:max-line-length
  constructor(private sageApi: SageApiService, private authService: AuthService, private alertify: AlertifyService,
              private modalService: BsModalService, private cdr: ChangeDetectorRef, private datepipe: DatePipe) {
  }


  ngOnInit() {
    this.clearFilters();
    this.assigningDate = false;
    this.fiveDayList = new Map<string, any>();
    this.selectedWT = {};
    this.selectedSONum = '';
    this.selectedWTNum = '';
    this.filterTechName = '';
    this.filterscheduleGroup = '';
    this.selectedFilterName = 'All';
    this.selectedFilter = {
      name: 'All',
      superFilter: '',
      warehouseFilter: '',
      cityFilter: '',
      filterType: ''
    };
    this.missingWaterSewer = [];
    this.bsConfig = Object.assign({}, {containerClass: 'theme-red'});
    this.weekofDate = new Date();
    this.sanARegion = true;
    this.dallasRegion = true;
    this.otherRegion = true;
    this.modalAssignDate = false;
    this.modalSetRTP = false;
    const obj = {
      name: 'All',
      superFilter: '',
      warehouseFilter: '',
      cityFilter: '',
      filterType: ''
    };
    this.filterList = [];
    this.filterList.push(obj);
    // calculateNextDays requires that we have the subdivs loaded to calculate
    this.calculateNextDays('forward');
    this.calculateNextDays('backwards');
    this.loadSubdivs();
    this.loadAddressList();
    this.loadAMSubdivisionsList();
    this.loadTechs();
    this.loadCitiesList();
    this.loadFilters();
    // this.loadTenDay(this.weekofDate);
  }

  ngAfterViewInit() {
    // this.cdr.detectChanges();
  }

  setFilter(index){
    this.selectedFilter = this.filterList[index];
    this.selectedFilterName = this.filterList[index].name;
    if (this.filterList[index].filterType === 'AM'){
      this.subDivList = [];
      this.AMSubdivisionsList.forEach(sub => {
        const am = this.filterList[index].superFilter;
        if (sub.areaManager !== null && sub.areaManager.includes(am)){
          this.subDivList.push(sub.subdivision);
        }
      });
    }
  }

  loadSubdivs(): Promise<void>{
    this.isLoading = true;
    const prom = new Promise<void>((resolveProm)=>{
      this.sageApi.pullReport('Subdivision').subscribe((subdivs)=>{
        this.subdivs = subdivs as SubdivisionResponseType;
        this.isLoading = false;
      }, (err)=>{
        this.subdivs = [];
        this.alertify.error('Error occured while loading subdivisions');
        this.isLoading = false;
      });
    });
    return prom;
  }

  getTicketsWithCityInspectionsCount(tickets: ScheduleTicketInterface[]): number{
    let count = 0;
    for(let ticket of tickets){
      if(this.filterFiveDay(ticket)){
        const matchingSubdiv = this.subdivs.find((subdiv)=>subdiv.UDF_SUBDIVISION_CODE&&ticket.subdivision==subdiv.UDF_SUBDIVISION_CODE);
        if(matchingSubdiv && matchingSubdiv.UDF_CITY_INSPECTIONS=='Y'){
          count++;
        }
      }
    }
    return count;
  }

  loadFilters(){
    this.isLoading = true;
    let filterError = '';
    const obj = {
      name: 'All',
      superFilter: '',
      warehouseFilter: '',
      cityFilter: '',
      filterType: ''
    };
    this.filterList = [];
    this.filterList.push(obj);
    this.sageApi.pullReport('AppFilter?id=AS').subscribe((rows: Array<any>) => {
      if (Array.isArray(rows)) {
        rows.forEach(row => {
          const splitType = row.FilterType.split('-');
          if (splitType.length < 2){
            filterError += row.FilterType + ', ';
          }else{
            const item = {
              name: row.FilterName,
              superFilter: row.FilterValue == null ? '' : row.FilterValue,
              warehouseFilter: row.FilterValue2 == null ? '' : row.FilterValue2, // ARDivisionNo
              cityFilter: row.FilterValue3 == null ? '' : row.FilterValue3,
              filterType: splitType[1],
            };
            this.filterList.push(item);
          }
        });
      }
    }, err => {
      this.alertify.error(err.message);
      console.log(err);
      this.isLoading = false;
    }, () => {
      if (filterError !== ''){
        this.alertify.error('Error occured while loading filters. invalid filter type: ' + filterError);
      }
      this.isLoading = false;
    });
  }

  logDate(event, workTicket) {
    if (this.selectedScheduleDate.getTime() !== new Date(event).getTime()) {
      console.log('Old date:' + this.selectedScheduleDate);
      // workTicket.scheduleDate = new Date(event);
      this.selectedScheduleDate = new Date(event);
      // rowPhase.updated = true;
    }
  }

  calculateNextDays(direction: string) {
    let businessDays = 6;
    const colDate = new Date(this.colOneDate);
    // if ( direction === 'start') {
    //   colDate = this.weekofDate;
    // }
    const today = new Date();
    const startDate = (!this.colOneDate) ? new Date() : new Date(colDate.getTime() + (today.getTimezoneOffset() * 60000));
    let counter: number;
    if (direction === 'forward') {
      counter = 7; // set to 1 to count from next business day
    } else if (direction === 'backwards') {
      // if (startDate.getDay() === 1) {
      //   counter = -3;
      // } else {
      counter = -7;
      // }
    } else {
      return;
    }

    while (businessDays > 0) {
      const utcDate = new Date();
      const tmp = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
      tmp.setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + counter++);
      const tmpYear = tmp.getFullYear().toString();
      const tmpMonth = ('0' + (tmp.getMonth() + 1).toString()).slice(-2);
      const tmpDay = ('0' + tmp.getDate().toString()).slice(-2);
      const checkDate = tmpYear + '-' + tmpMonth + '-' + tmpDay + 'T00:00:00';
      switch (tmp.getDay()) {
        case 0:
          // case 6:
          break; // sunday & saturday
        default:
          if (businessDays === 6) {
            this.colOneDate = checkDate;
            this.weekofDate = new Date(this.colOneDate);
            this.loadTenDay(this.weekofDate);
          } else if (businessDays === 5) {
            this.colTwoDate = checkDate;
          } else if (businessDays === 4) {
            this.colThreeDate = checkDate;
          } else if (businessDays === 3) {
            this.colFourDate = checkDate;
          } else if (businessDays === 2) {
            this.colFiveDate = checkDate;
          } else if (businessDays === 1) {
            this.colSixDate = checkDate;
          }
          businessDays--;
      }
    }
    this.RebuildBadDate();
  }

  loadAddressList() {
    this.isLoading = true;
    this.addressList = [];
    this.sageApi.pullReport('AssignSchedule/Addresses').subscribe((rows: Array<any>) => {
      if (Array.isArray(rows)) {
        rows.forEach(row => {
          const address = {
            name: row.Address + ' - ' + row.SalesOrderNo + ' - SUB: ' + row.UDF_SUBDIVISION + ' - SUPER: '+row.UDF_LEADMAN,
            address: row.Address,
            salesOrderNo: row.SalesOrderNo,
            subdivision: row.UDF_SUBDIVISION,
            super: row.UDF_LEADMAN
          };

          this.addressList.push(address);
        });
      }
    }, err => {
      this.alertify.error(err.message);
      console.log(err);
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  loadAMSubdivisionsList() {
    this.isLoading = true;
    this.AMSubdivisionsList = [];
    this.sageApi.pullReport('AssignSchedule/AMSubdivisions').subscribe((rows: Array<any>) => {
      if (Array.isArray(rows)) {
        rows.forEach(row => {
          const address = {
            areaManager: row.UDF_AREA_MANAGER,
            leadman: row.UDF_LEADMAN,
            subdivision: row.UDF_SUBDIVISION_CODE
          };

          this.AMSubdivisionsList.push(address);
        });
      }
    }, err => {
      this.alertify.error(err.message);
      console.log(err);
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  loadCitiesList() {
    this.isLoading = true;
    this.cityRegionList = [];
    this.sageApi.pullReport('AssignSchedule/Cities').subscribe((rows: Array<any>) => {
      if (Array.isArray(rows)) {
        rows.forEach(row => {
          const obj = {
            region: row.CityRegion,
            city: row.CityName
          };
          this.cityRegionList.push(obj);
        });
      }
    }, err => {
      this.alertify.error(err.message);
      console.log(err);
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  addressSelected(event: TypeaheadMatch) {
    this.assigningDate = false;
    // Promise.all([this.loadTechScheduleSO(event.item.salesOrderNo)]).then(completeLoad => {
    //   this.loadSalesOrder(event.item.salesOrderNo); });
    // try{
    this.loadTechScheduleSO(event.item.salesOrderNo).then(() => this.loadSalesOrder(event.item.salesOrderNo));
    // }
    // finally{
    //   this.loadSalesOrder(event.item.salesOrderNo);
    // }

    // this.loadSalesOrder(event.item.salesOrderNo);
  }

  async loadTechScheduleSO(salesOrderNo: string) {
    this.WTTechSchedule = [];
    try{
      this.sageApi.pullReport('AssignSchedule/TechSchedule?salesOrderNo=' + salesOrderNo).subscribe((rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const obj = {
              salesOrderNo: row.SalesOrderNo,
              wtNum: row.WTNumber,
              wtstep: row.WTStep,
              scheduleDate: row.ScheduleDate,
              techDeptNo: row.TechnicianDeptNo,
              techNo: row.TechnicianNo
            };
            this.WTTechSchedule.push(obj);
          });
        }
      });
    } catch (e){
    } finally {
      // this.loadSalesOrder(salesOrderNo);
    }
    // return 'success';
  }

  filteredCounter(dayList: any){
    let count = 0;
    dayList.forEach(ticket => {
      if (this.filterFiveDay(ticket)){
        count++;
      }
    });
    return count;
  }

  refreshLoad(){
    if (this.tickets[0] === undefined || this.tickets[0].salesOrderNo === undefined){
      return;
    }

    this.loadRefresh = true;
    this.loadTechScheduleSO(this.tickets[0].salesOrderNo).then(() => {
      this.loadSalesOrder(this.tickets[0].salesOrderNo);
    });
    this.loadRefresh = false;
    // try{
    //   this.loadTechScheduleSO(this.tickets[0].salesOrderNo);
    // }
    // finally{
    //   try{
    //     this.loadSalesOrder(this.tickets[0].salesOrderNo);
    //   }
    //   finally {
    //     this.loadRefresh = false;
    //   }
    // }
    // try {
    //   this.loadTechScheduleSO(this.tickets[0].salesOrderNo);
    // }finally {
    //   try{
    //     this.tickets.forEach(ticket => {
    //       const matchindex = this.WTTechSchedule.findIndex(v => v.wtNum === ticket.wtNum);
    //       const techNumSch = matchindex < 0 ? '' : this.WTTechSchedule[matchindex].techDeptNo + this.WTTechSchedule[matchindex].techNo;
    //       const schedDate = matchindex < 0 ? '' : this.WTTechSchedule[matchindex].scheduleDate;
    //       ticket.scheduleDate = schedDate;
    //       ticket.techName = techNumSch !== '' ? this.getTechName(techNumSch) : '';
    //     });
    //   }catch (e){
    //     this.alertify.error('Error:' + e);
    //   }
    //   finally {
    //     this.loadRefresh = false;
    //     this.wtsLoading = false;
    //   }
    // }
  }

  loadSalesOrder(salesOrderNo: string) {
    this.phaseList = [];
    this.addressLoaded = false;
    this.wtsLoading = true;
    this.tickets = [];

    this.sageApi.pullReport('AssignSchedule/WT?salesOrderNum=' + salesOrderNo).subscribe((rows: AssignSchedule_WTResponseType) => {
      if (Array.isArray(rows)) {
        rows.forEach((row, rowIndex) => {
          const matchindex = this.WTTechSchedule.findIndex(v => v.wtNum === row.WTNumber);
          const techNumSch = matchindex < 0 ? '' : this.WTTechSchedule[matchindex].techDeptNo + this.WTTechSchedule[matchindex].techNo;
          const schedDate = matchindex < 0 ? '' : this.WTTechSchedule[matchindex].scheduleDate;
          const obj: ScheduleTicketInterface = {
            salesOrderNo: row.SalesOrderNo,
            wtNum: row.WTNumber,
            wtStep: row.WTStep,
            itemCode: row.ItemCode.substring(1),
            address: row.Address,
            techID: techNumSch,
            // row.UDF_TECHNICIAN_CODE,
            scheduleDate: schedDate,
            // techName: row.UDF_TECHNICIAN_CODE !== '' ? this.getTechName(row.UDF_TECHNICIAN_CODE) : ''
            techName: techNumSch !== '' ? this.getTechName(techNumSch) : '',
            assigningDate: false,
            statusCode: row.StatusCode,
            regionWarehouse: row.ARDivisionNo,
            builderEmails: [row.UDF_BUILDER_EMAIL_1, row.UDF_BUILDER_EMAIL_2, row.UDF_BUILDER_EMAIL_3],
            subdivision: row.UDF_SUBDIVISION,
            displayInfo: false,
          };
          this.tickets.push(obj);
        });
      }
    }, err => {
      console.log(err);
      this.alertify.error(err.message);
      this.wtsLoading = false;
    }, () => {
      this.wtsLoading = false;
      this.addressLoaded = true;
    });
  }

  loadSewerWater(ticket, colDate) {
    ticket.salesOrderNo = ticket.salesOrderNo === undefined ? this.dragSONum : ticket.salesOrderNo;
    // tslint:disable-next-line:max-line-length
    ticket.itemCode = ticket.itemCode === undefined ? this.dragItemCode :
      ticket.itemCode === '' ?
        this.setPhaseByCrew(ticket).toLocaleLowerCase().includes('missing') ? '' : this.setPhaseByCrew(ticket).substring(1)
        : ticket.itemCode;
    this.sewerWaterList = [];
    // this.addressLoaded = false;
    this.wtsLoading = true;

    this.sageApi.pullReport('AssignSchedule/SewerWater?salesOrderNum=' + ticket.salesOrderNo).subscribe((rows: Array<any>) => {
      if (Array.isArray(rows)) {
        rows.forEach((row, rowIndex) => {
          const matchindex = this.WTTechSchedule.findIndex(v => v.wtNum === row.WTNumber);
          const techNumSch = matchindex < 0 ? '' : this.WTTechSchedule[matchindex].techDeptNo + this.WTTechSchedule[matchindex].techNo;
          const schedDate = matchindex < 0 ? '' : this.WTTechSchedule[matchindex].scheduleDate;
          const obj = {
            salesOrderNo: row.SalesOrderNo,
            wtNum: row.WTNumber,
            wtStep: row.WTStep,
            itemCode: row.ItemCode.substring(1),
            address: row.Address,
            techID: techNumSch,
            // row.UDF_TECHNICIAN_CODE,
            scheduleDate: schedDate,
            // techName: row.UDF_TECHNICIAN_CODE !== '' ? this.getTechName(row.UDF_TECHNICIAN_CODE) : ''
            techName: techNumSch !== '' ? this.getTechName(techNumSch) : '',
            assigningDate: false,
            statusCode: row.StatusCode,
            regionWarehouse: row.ARDivisionNo,
            builderEmails: [row.UDF_BUILDER_EMAIL_1, row.UDF_BUILDER_EMAIL_2, row.UDF_BUILDER_EMAIL_3],
            subdivision: row.UDF_SUBDIVISION,
            displayInfo: false
          };
          this.sewerWaterList.push(obj);
        });
      }
    }, err => {
      console.log(err);
      this.alertify.error(err.message);
      this.wtsLoading = false;
    }, () => {
      this.applyToWT(ticket, colDate);
      // this.wtsLoading = false;
      // this.addressLoaded = true;
    });
  }

  loadTechs() {
    this.isLoading = true;
    this.techList = [];

    this.sageApi.pullReport('AssignSchedule/Techs').subscribe((rows: Array<any>) => {
      if (Array.isArray(rows)) {
        rows.forEach((row, rowIndex) => {
          const obj = {
            techID: row.TechnicianDeptNo + row.TechnicianNo,
            firstName: row.FirstName,
            lastName: row.LastName,
            fullName: row.FirstName + ' ' + row.LastName,
            scheduleGroup: row.ScheduleGroup,
            assignedLeadman: row.UDF_ASSIGNED_LEADMAN === null ? '' : row.UDF_ASSIGNED_LEADMAN,
            email: row.EMailAddress,
            crewLead: row.UDF_PRT_CREW_LEAD,
            crewEmp1: row.UDF_PRT_CREW_EMP1,
            crewEmp2: row.UDF_PRT_CREW_EMP2,
            jobCount: -1,
            warehouse: row.DefaultWarehouse
          };
          this.techList.push(obj);
          if ((obj.firstName.toLocaleLowerCase().includes('unassigned')
            && !obj.firstName.toLocaleLowerCase().includes('addl'))
            || (obj.lastName.toLocaleLowerCase().includes('unassigned')
              && !obj.lastName.toLocaleLowerCase().includes('addl'))) {
            this.unassignedList.push(obj);
          }
        });
      }
    }, err => {
      this.alertify.error(err.message);
      console.log(err);
      this.isLoading = false;
    }, () => {
      this.countLWREC();
      this.isLoading = false;
    });

  }

  getTechName(techCode: string) {
    const tech = this.techList.find(v => v.techID === techCode);
    return tech === undefined ? 'Error Undefined' : tech.firstName + ' ' + tech.lastName;
  }

  phaseSelected(event: TypeaheadMatch) {

  }

  sortTicketsTable(colName) {
    // I have yet to see these colName fields on here... but ok
    (this.tickets as unknown as any).sort((a, b) => a.colName > b.colName ? 1 : a.colName < b.colName ? -1 : 0);
  }

  countLWREC() {
    this.isLoading = true;
    this.sageApi.pullReport('AssignSchedule/LWREC').subscribe((count: string) => {
      this.countMap = new Map<string, string>();
      const techSplit = count.split('#');
      if (Array.isArray(techSplit)) {
        techSplit.forEach((newTech: string) => {
          const countSplit = newTech.split('?');
          if (countSplit.length === 2) {
            this.countMap.set(countSplit[0], countSplit[1]);
          } else {
            // Do nothing?
          }
        });
      }
    }, err => {
      // this.alertify.error(err.message);
      console.log(err);
      this.isLoading = false;
    }, () => {
      this.setcounts();
      this.isLoading = false;
    });
  }

  setcounts() {
    this.techList.forEach(tech => {
      if (this.countMap.has(tech.techID)) {
        tech.jobCount = this.countMap.get(tech.techID);
      }
    });
  }

  stopOnBlanks(tech: any) {
    return !(tech.fullName.toLocaleLowerCase().includes('unassigned') ||
      tech.fullName.toLocaleLowerCase().includes('third party') ||
      tech.fullName.toLocaleLowerCase().includes('starts'));
  }

  loadTenDay(date: Date) {
    this.isLoading = true;
    const datePipe = new DatePipe('en-US');
    this.tenDayTechSchedule = [];
    this.sageApi.pullReport('AssignSchedule/TenDay?startDate=' + datePipe.transform(date, 'yyyy-MM-dd')).subscribe((tickets: Array<any>) => {
      if (Array.isArray(tickets)) {
        tickets.forEach(ticket => {
          const obj = {
            scheduleDate: ticket.ScheduleDate,
            techID: ticket.TechnicianDeptNo + ticket.TechnicianNo,
            salesOrderNo: ticket.SalesOrderNo,
            wtNum: ticket.WTNumber,
            wtStep: ticket.WTStep,
            seqNum: ticket.SequenceNo,
            startTime: ticket.StartTime,
            hoursScheduled: ticket.HoursScheduled,
            dateCreated: ticket.DateCreated,
            timeCreated: ticket.TimeCreated,
            address: ticket.Address,
            city: ticket.City,
            itemCode: ticket.HdrParentItemCode,
            statusCode: ticket.StatusCode,
            fullTicketNo: ticket.SalesOrderNo + '-' + ticket.WTNumber, // + '-' + ticket.WTStep
            regionWarehouse: ticket.ARDivisionNo,
            builderEmails: [ticket.UDF_BUILDER_EMAIL_1, ticket.UDF_BUILDER_EMAIL_2, ticket.UDF_BUILDER_EMAIL_3],
            subdivision: ticket.UDF_SUBDIVISION,
            displayInfo: false
          };
          this.tenDayTechSchedule.push(obj);
        });
      }
    }, err => {
      this.alertify.error(err.message);
      console.log(err);
      this.isLoading = false;
    }, () => {
      this.sortFiveDay();
      this.isLoading = false;
    });
  }

  sortFiveDay() {
    this.SWCheckList = [];
    this.fiveDayArray = [];
    this.fiveDayList = new Map();
    this.fiveDayList.set(this.colOneDate, []);
    this.fiveDayList.set(this.colTwoDate, []);
    this.fiveDayList.set(this.colThreeDate, []);
    this.fiveDayList.set(this.colFourDate, []);
    this.fiveDayList.set(this.colFiveDate, []);
    this.fiveDayList.set(this.colSixDate, []);
    this.fiveDayList.set(this.colSevenDate, []);
    this.fiveDayList.set('unknown', []);

    const dateOne = new Date(this.colOneDate);
    const dateTwo = new Date(this.colTwoDate);
    const dateThree = new Date(this.colThreeDate);
    const dateFour = new Date(this.colFourDate);
    const dateFive = new Date(this.colFiveDate);
    const dateSix = new Date(this.colSixDate);
    const dateSeven = new Date(this.colSevenDate);

    let item = [];
    this.tenDayTechSchedule.forEach(ticket => {
      if (ticket.itemCode.toLocaleLowerCase().includes('zroughin') && !ticket.itemCode.toLocaleLowerCase().includes('zroughinex')){
        this.SWCheckList.push(ticket.salesOrderNo);
      }
      const setDate = new Date(ticket.scheduleDate);
      switch (setDate.getDate()) {
        case dateOne.getDate():
          item = this.fiveDayList.get(this.colOneDate);
          item.push(ticket);
          this.fiveDayList.set(this.colOneDate, item);
          break;
        case dateTwo.getDate():
          item = this.fiveDayList.get(this.colTwoDate);
          item.push(ticket);
          this.fiveDayList.set(this.colTwoDate, item);
          break;
        case dateThree.getDate():
          item = this.fiveDayList.get(this.colThreeDate);
          item.push(ticket);
          this.fiveDayList.set(this.colThreeDate, item);
          break;
        case dateFour.getDate():
          item = this.fiveDayList.get(this.colFourDate);
          item.push(ticket);
          this.fiveDayList.set(this.colFourDate, item);
          break;
        case dateFive.getDate():
          item = this.fiveDayList.get(this.colFiveDate);
          item.push(ticket);
          this.fiveDayList.set(this.colFiveDate, item);
          break;
        case dateSix.getDate():
          item = this.fiveDayList.get(this.colSixDate);
          item.push(ticket);
          this.fiveDayList.set(this.colSixDate, item);
          break;
        case dateSeven.getDate():
          item = this.fiveDayList.get(this.colSevenDate);
          item.push(ticket);
          this.fiveDayList.set(this.colSevenDate, item);
          break;
        default:
          item = this.fiveDayList.get('unknown');
          item.push(ticket);
          this.fiveDayList.set('unknown', item);
      }
    });

    const colOneTickets = this.fiveDayList.get(this.colOneDate);
    this.fiveDayArray.push({col: this.colOneDate, tickets: colOneTickets});

    const colTwoTickets = this.fiveDayList.get(this.colTwoDate);
    this.fiveDayArray.push({col: this.colTwoDate, tickets: colTwoTickets});

    const colThreeTickets = this.fiveDayList.get(this.colThreeDate);
    this.fiveDayArray.push({col: this.colThreeDate, tickets: colThreeTickets});

    const colFourTickets = this.fiveDayList.get(this.colFourDate);
    this.fiveDayArray.push({col: this.colFourDate, tickets: colFourTickets});

    const colFiveTickets = this.fiveDayList.get(this.colFiveDate);
    this.fiveDayArray.push({col: this.colFiveDate, tickets: colFiveTickets});

    const colSixTickets = this.fiveDayList.get(this.colSixDate);
    this.fiveDayArray.push({col: this.colSixDate, tickets: colSixTickets});

    const colSevenTickets = this.fiveDayList.get(this.colSevenDate);
    this.fiveDayArray.push({col: this.colSevenDate, tickets: colSevenTickets});

    this.fiveDayArray.push({col: 'newTicket', tickets: []});

    this.loadMissingList(this.SWCheckList);
  }

  loadMissingList(checklist){
    this.isLoading = true;
    let sendstring = '';
    if (checklist.length > 1){
      checklist.forEach(tic => {
        if (sendstring === ''){
          sendstring += tic;
        }else{
          sendstring += ('_' + tic);
        }
      });
    } else {
      sendstring = checklist[0];
    }
    if (sendstring === '' || checklist.length === 0){
      return;
    }
    this.sageApi.pullReport('AssignSchedule/HuntMissing?salesorderList=' + sendstring).subscribe((items: any) => {
      if ( items.length !== 0){
        items.forEach(so => {
          const splitter = so.split('Z');
          const obj = {
            SalesOrderNo: splitter[0],
            Phase: splitter[1]
          };
          if (!this.missingWaterSewer.find(item => (item.SalesOrderNo === obj.SalesOrderNo && item.Phase === obj.Phase))){
            this.missingWaterSewer.push(obj);
          }
        });
      }
    });
  }

  openModal(template: TemplateRef<any>, selectedPhase: any) {

    this.chosenCrewID = '';
    this.selectedWT = selectedPhase;
    this.modalAssignDate = false;
    this.modalSetRTP = false;
    this.selectedScheduleDate = new Date(selectedPhase.scheduleDate);
    // @ts-ignore
    if (this.selectedWT.regionWarehouse !== undefined && this.selectedWT.regionWarehouse.includes('13')) {
      this.sanTech = false;
      this.dallasTech = true;
      this.otherTech = true;
    } else {
      this.sanTech = true;
      this.dallasTech = false;
      this.otherTech = true;
    }
    // @ts-ignore
    if (this.selectedWT !== {}) {
      // @ts-ignore
      this.selectedSONum = this.selectedWT.salesOrderNo !== undefined ? this.selectedWT.salesOrderNo : 'undefined';
      // @ts-ignore
      this.selectedWTNum = this.selectedWT.wtNum !== undefined ? this.selectedWT.wtNum : 'Error';
    }
    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    this.filterscheduleGroup = this.selectedWT.itemCode.substring(0, 1).toLocaleLowerCase() === 'z' ? this.selectedWT.itemCode.substring(1) : this.selectedWT.itemCode;
    // @ts-ignore
    this.filterTechName = this.selectedWT.techID === undefined || this.selectedWT.techID === '' ? '' : this.getTechName(this.selectedWT.techID);
    // @ts-ignore
    this.chosenCrewID = this.selectedWT.techID === undefined || this.selectedWT.techID === '' ? '' : this.selectedWT.techID;
    // this.filterscheduleGroup = '';
    this.modalTemplate = this.modalService.show(template, {class: 'modal-xl'});
  }

  closeModal() {
    this.selectedWT = {};
    this.modalTemplate.hide();
  }

  FilterTechCheck(step: any) {
    const sanARegion = step.warehouse.includes('001');
    const dallasRegion = step.warehouse.includes('005');
    const regionCheck = (this.sanTech && sanARegion) ||
      (this.dallasTech && dallasRegion) ||
      (this.otherTech && !(sanARegion || dallasRegion));

    return step.fullName.toLocaleLowerCase().includes(this.filterTechName.toLocaleLowerCase())
      && step.scheduleGroup.toLocaleLowerCase().includes(this.filterscheduleGroup.toLocaleLowerCase())
      && regionCheck;
  }

  filterFiveDay(ticket: ScheduleTicketInterface): boolean {
    try {
      const phaseCheck = ticket.itemCode === '' || undefined ? this.setPhaseByCrew(ticket) : ticket.itemCode;
      const crewCheck = ticket.techID + ' - ' + this.getTechName(ticket.techID);
      const regionCheckSan = ticket.regionWarehouse.includes('11');
      const regionCheckDal = ticket.regionWarehouse.includes('13');
      const regionCheck = (this.sanARegion && regionCheckSan) ||
        (this.dallasRegion && regionCheckDal) ||
        (this.otherRegion && !(regionCheckSan || regionCheckDal));

      const returnVal = ticket.fullTicketNo.includes(this.wtFilter)
        && ticket.address.toLocaleLowerCase().includes(this.addressFilter.toLocaleLowerCase())
        && phaseCheck.toLocaleLowerCase().includes(this.phaseFilter.toLocaleLowerCase())
        && ticket.statusCode.toLocaleLowerCase().includes(this.statusFilter.toLocaleLowerCase())
        && ticket.subdivision.toLocaleLowerCase().includes(this.divisionFilter.toLocaleLowerCase())
        && crewCheck.toLocaleLowerCase().includes(this.crewFilter.toLocaleLowerCase())
        && (regionCheck);

      if (this.selectedFilterName !== 'All'){
        if (this.selectedFilter.filterType === 'CT'){
          const fitValue = this.selectedFilter.warehouseFilter;
          return returnVal && ticket.regionWarehouse.includes(fitValue);
        }else if (this.selectedFilter.filterType === 'AM'){
          const fitValue = ticket.subdivision;
          return returnVal && this.subDivList.includes(fitValue);
        }else {
          return returnVal;
        }
      }else {
        return returnVal;
      }

    } catch (e) {
      return false;
    }

  }

  filterWTPhases(ticket: any) {
    // return ticket.statusCode !== 'COM' && ticket.itemCode.toLocaleLowerCase().includes(this.phaseName.toLocaleLowerCase());
    return ticket.itemCode.toLocaleLowerCase().includes(this.phaseName.toLocaleLowerCase());
  }

  setPhaseByCrew(ticket: any) {
    const obj = this.techList.find(item => item.techID === ticket.techID);
    return obj !== undefined ? 'Z' + obj.scheduleGroup : 'Missing';
  }

  clearFilters() {
    this.wtFilter = '';
    this.addressFilter = '';
    this.phaseFilter = '';
    this.statusFilter = '';
    this.crewFilter = '';
    this.divisionFilter = '';
  }

  selectedCrew() {

  }

  unAssignedTicket(ticket: any) {
    return this.getTechName(ticket.techID).toLocaleLowerCase().includes('unassigned');
  }

  TicketColor(ticket: any) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const ticketDate = new Date(ticket.scheduleDate);
    ticketDate.setHours(0, 0, 0, 0);

    if (ticket.statusCode === 'RTP' && today > ticketDate){
      return 'Crimson';
    } else if (ticket.statusCode === 'RTP'){
      return 'lightgreen';
    } else if (ticket.statusCode === 'PIW' && today > ticketDate){
      return 'DarkOrange';
    }else if (ticket.statusCode === 'LW' && today > ticketDate){
      return 'DodgerBlue';
    }else if (ticket.statusCode === 'LW'){
      return 'aqua';
    }else if (ticket.statusCode === 'PIW'){
      return 'Gray';
    }else if ((ticket.statusCode === 'NEW' || ticket.statusCode ===  'RI'
      || ticket.statusCode ===  'TO' || ticket.statusCode ===  'TR')  && today === ticketDate){
      return 'orange';
    }else if ((ticket.statusCode === 'NEW' || ticket.statusCode ===  'RI'
      || ticket.statusCode ===  'TO' || ticket.statusCode ===  'TR')  && today > ticketDate) {
      return 'firebrick';
    } else if (this.WTMatchDay(ticket, this.colOneDate)){
      return 'red';
    } else if (this.errorTicket(ticket)){
      return 'orange';
    }else if (this.unAssignedTicket(ticket)){
      return 'yellow';
    }else {
      return '';
    }
  }

  errorTicket(ticket: any) {
    return this.getTechName(ticket.techID).toLocaleLowerCase().includes('undefined');
    // || ticket.itemCode === 'Error';
  }

  WTMatchDay(ticket: any, colDate) {
    if (this.assigningDate) {
      if ((ticket.salesOrderNo === this.assignSONo && ticket.wtNum === this.assignWTNum)
        || (this.assignCrew.includes(ticket.techID) && !this.unAssignedTicket(ticket))) {
        // this.badDate.set(colDate, true);
        return true;
      }
    } else {
      return false;
    }
  }

  runBadDateCheck(tickets: any, colDate) {
    let checker = false;
    if (tickets === undefined) {
      return checker;
    }
    tickets.forEach(ticket => {
      if (this.WTMatchDay(ticket, colDate)) {
        checker = true;
      }
    });
    return checker;
  }

  RebuildBadDate() {
    this.badDate = new Map<string, boolean>();
    this.badDate.set(this.colOneDate, false);
    this.badDate.set(this.colTwoDate, false);
    this.badDate.set(this.colThreeDate, false);
    this.badDate.set(this.colFourDate, false);
    this.badDate.set(this.colFiveDate, false);
    this.badDate.set(this.colSixDate, false);
    this.badDate.set(this.colSevenDate, false);
  }

  assigningDateBuild(ticket: any) {
    this.RebuildBadDate();
    this.tickets.forEach(item => {
      if (item.assigningDate) {
        item.assigningDate = false;
      }
    });
    this.assigningDate = true;
    ticket.assigningDate = true;
    this.assignWTNo = ticket.salesOrderNo + '-' + ticket.wtNum;
    this.assignSONo = ticket.salesOrderNo;
    this.assignWTNum = ticket.wtNum;
    this.assignWTStep = ticket.wtStep;
    this.assignAddress = ticket.address;
    this.assignDivision = ticket.subdivision;
    this.assignItemCode = ticket.itemCode;
    this.assignStatus = ticket.statusCode;
    if (ticket.regionWarehouse !== undefined && ticket.regionWarehouse.includes('13')) {
      this.sanARegion = false;
      this.dallasRegion = true;
      this.otherRegion = true;
    } else {
      this.sanARegion = true;
      this.dallasRegion = false;
      this.otherRegion = true;
    }
    this.assignCrew = ticket.techID === '' ? this.setUnassigned(ticket) : ticket.techID + ' - ' + ticket.techName;
    const ar = new Array<any>();
    ar.push(ticket);
    this.fiveDayArray[7].tickets = ar;
  }

  drop(event: CdkDragDrop<string[]>, colDate: string, superListIndex: number) {
    if (superListIndex === 7) {
      return;
    }
    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);
    if (event.previousContainer === event.container) {
      if (event.previousIndex === event.currentIndex) {
        return;
      }
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      if (colDate === 'newTicket') {
        return;
      }
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      // const ary = this.fiveDayArray[superListIndex].tickets as Array<object>;
      const ary = this.fiveDayArray[superListIndex].tickets;
      if (superListIndex === 7) {
        this.dragSONum = ary[0].salesOrderNo === undefined ? this.assignSONo : ary[0].salesOrderNo;
        this.dragWTNum = ary[0].wtNum === undefined ? this.assignSONo : ary[0].wtNum;
        this.dragTech = ary[0].techID === undefined ? this.assignCrew : ary[0].techID;
        this.dragItemCode = ary[0].itemCode === undefined ? this.assignItemCode :
          // tslint:disable-next-line:max-line-length
          ary[0].itemCode === '' ?
            this.setPhaseByCrew(ary[0]).toLocaleLowerCase().includes('missing') ? '' : this.setPhaseByCrew(ary[0]).substring(1)
            : ary[0].itemCode;
      } else {
        ary.forEach((wt: any) => {
          if (colDate !== wt.scheduleDate) {
            this.dragSONum = wt.salesOrderNo === undefined ? this.assignSONo : wt.salesOrderNo;
            this.dragWTNum = wt.wtNum === undefined ? this.assignSONo : wt.wtNum;
            this.dragTech = wt.techID === undefined ? this.assignCrew : wt.techID;
            this.dragItemCode = wt.itemCode === undefined ? this.assignItemCode :
              // tslint:disable-next-line:max-line-length
              wt.itemCode === '' ?
                // tslint:disable-next-line:max-line-length
                this.setPhaseByCrew(wt).toLocaleLowerCase().includes('missing') ? 'failed' : this.setPhaseByCrew(wt).substring(1)
                : wt.itemCode;
          }
        });
      }
      // this.supersList[superListIndex].tickets[colDate].punch[event.currentIndex].scheduleGroup =
      //   colDate === null ? 'unsorted' : colDate;
      const saveTicket = this.fiveDayArray[superListIndex].tickets;
      if (superListIndex === 7) {
        this.loadSewerWater(saveTicket, colDate);
      } else {
        this.loadSewerWater(saveTicket, colDate);
      }
      // this.onScheduleTicket(arrObj, colDate, event.currentIndex);
    }
  }

  updateWTSchedule(ticket: any) {

  }

  setUnassigned(ticket: any) {
    const obj = this.unassignedList.find(item => item.scheduleGroup.toLocaleLowerCase().includes(ticket.itemCode.toLocaleLowerCase()));
    return obj !== undefined ? obj.techID + ' - ' + obj.firstName + ' ' + obj.lastName : 'ERROR';
  }

  applyToWT(ticket: any, colDate) {
    if (colDate === 'newTicket') {
      return;
    }
    this.isLoading = true;
    this.saleOrderTicket = ticket.salesOrderNo !== undefined ? ticket.salesOrderNo : this.assignSONo;
    this.wTNumTicket = ticket.wtNum !== undefined ? ticket.wtNum : this.assignWTNum;
    const observables = [];
    // let body: {
    //   username: string,
    //   password: string,
    //   salesorderno: string,
    //   wtnumber: string,
    //   newtech: string,
    //   // tslint:disable-next-line:max-line-length
    //   newdate: string,
    // };

    if (this.modalSetRTP){
      const stat = {
        username: this.authService.decodedToken.nameid,
        password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
        salesorderno: ticket.salesOrderNo,
        wtnumber: ticket.wtNum,
        notes: 'Assigned RTP by - ' + this.authService.decodedToken.nameid,
        status: 'RTP'
      };
      observables.push(this.sageApi.putRequest('UpdWTStatus', stat));
    }

    let body = [];
    const fullList = {
      updateArray: []
    };

    if (colDate === 'techTicket') {
      // body = {
      //   username: this.authService.decodedToken.nameid,
      //   password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      //   salesorderno: ticket.salesOrderNo,
      //   wtnumber: ticket.wtNum,
      //   newtech: this.chosenCrewID,
      //   // tslint:disable-next-line:max-line-length
      //   newdate: this.modalAssignDate ? this.datepipe.transform(this.selectedScheduleDate, 'yyyy-MM-ddTHH:mm:ss') :
      //     (ticket.scheduleDate === '' || ticket.scheduleDate === undefined) ?
      //       '1753-01-01T00:00:00' : ticket.scheduleDate,
      // };
      body = [
        /*username:*/ this.authService.decodedToken.nameid,
        /*password:*/ this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
        /*salesorderno:*/ ticket.salesOrderNo,
        /*wtnumber:*/ ticket.wtNum,
        /*newtech:*/ this.chosenCrewID,
        // tslint:disable-next-line:max-line-length
        /*newdate:*/ this.modalAssignDate ? this.datepipe.transform(this.selectedScheduleDate, 'yyyy-MM-ddTHH:mm:ss') :
          (ticket.scheduleDate === '' || ticket.scheduleDate === undefined) ?
            '1753-01-01T00:00:00' : ticket.scheduleDate,
      ];
    } else {
      // body = {
      //   username: this.authService.decodedToken.nameid,
      //   password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      //   salesorderno: this.dragSONum,
      //   wtnumber: this.dragWTNum,
      //   newtech: this.dragTech === 'ERROR' || this.dragTech === undefined ? '' : this.dragTech,
      //   newdate: (colDate === 'newTicket' || colDate === undefined) ? '1753-01-01T00:00:00' : colDate,
      // };
      body = [
        /*username:*/ this.authService.decodedToken.nameid,
        /*password:*/ this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
        /*salesorderno:*/ this.dragSONum,
        /*wtnumber:*/ this.dragWTNum,
        /*newtech:*/ this.dragTech === 'ERROR' || this.dragTech === undefined ? '' : this.dragTech,
        /*newdate:*/ (colDate === 'newTicket' || colDate === undefined) ? '1753-01-01T00:00:00' : colDate,
      ];
    }
    fullList.updateArray.push(body);

    if (ticket.itemCode !== undefined && ticket.itemCode.toLocaleUpperCase().includes('ROUGHIN') && !ticket.itemCode.toLocaleLowerCase().includes('ROUGHINEX')) {
      this.alertify.warning('Roughin WT - Assigning Sewer and Water as well');
      this.sewerWaterList.forEach(item => {
        const swBody = [
          /*username:*/ this.authService.decodedToken.nameid,
          /*password:*/ this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
          /*salesorderno:*/ item.salesOrderNo,
          /*wtnumber:*/ item.wtNum,
          /*newtech:*/ body[4] !== undefined ? body[4] : '',
          /*newdate:*/ body[5] !== undefined ? body[5] : '1753-01-01T00:00:00'
        ];
        fullList.updateArray.push(swBody);
        // this.sewerWaterSubmit(item, body);
      });
    }
    this.alertify.warning('Updating ' + this.saleOrderTicket + '-' + this.wTNumTicket);
    observables.push(this.sageApi.putRequest('AssignSchedule', fullList));

    concat(...observables).subscribe(response => {
      console.log(`put request`, response);
    }, error => {
      console.log(error);
      this.isLoading = false;
      this.alertify.error(`Error - ` + error);
    }, () => {
      this.modalSetRTP = false;
      this.isLoading = false;
      this.alertify.success(`Success!`);
      if (colDate === 'newTicket') {
        this.closeModal();
      }
      this.calculateNextDays('forward');
      this.calculateNextDays('backwards');
    });
  }
}
