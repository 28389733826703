<div 
  *ngIf="renderHeader"
  [class]="screenSize.getClasses() + ' breadcr'"
>
  <div class="breadcr-content">
    <div class="breadcr-content-crumbs">
      <ng-content select="[breadcrumb-pre-content]"></ng-content>
      <ng-container *ngFor="let crumb of crumbs; let cIndex = index">
        <span (click)="changeSelectedCrumb(cIndex)" [class.active]="selectedCrumb==cIndex" [class.visited]="selectedCrumb>cIndex" class="breadcr-text">{{ crumb.name }}</span>
        <i *ngIf="cIndex < crumbs.length - 1" class="breadcr-icon fas fa-chevron-right arrow right"></i>
      </ng-container>
      <ng-content select="[breadcrumb-post-content]"></ng-content>
    </div>
    <ng-content select="[breadcrumb-end-content]"></ng-content>
  </div>
</div>

<ng-container *ngIf="forceRender === false">
  <ng-container *ngFor="let crumb of crumbs; let cIndex = index">
    <div class="breadcr-outlet">
      <ng-container *ngIf="cIndex == selectedCrumb && crumb.template!=undefined" [ngTemplateOutlet]="crumb.template"></ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="forceRender !== false">
  <ng-container *ngFor="let crumb of crumbs; let cIndex = index">
    <div [class.hidden]="cIndex != selectedCrumb" class="breadcr-outlet">
      <ng-container >
        <ng-container *ngIf="(cIndex == selectedCrumb || shouldForceRender(forceRender, cIndex)) && crumb.template!=undefined" [ngTemplateOutlet]="crumb.template"></ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>