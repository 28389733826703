import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {DatePipe} from '@angular/common';



@Component({
  selector: 'app-receiving-update',
  templateUrl: './receiving-update.component.html',
  styleUrls: ['./receiving-update.component.css']
})
export class ReceivingUpdateComponent implements OnInit {
  modalTemplate: BsModalRef;
  bsConfig: Partial<BsDatepickerConfig>;
  selectedReceiptDate: Date;

  receiptDeleteLoading = false;
  receiptDeleteBatchNo = '';

  receiptProfitCenterSelected = '';
  receiptProfitCenters: {
    name: string,
    code: string
  }[] = [];

  poNumFilter: string;
  BatchNumFilter: string;

  receiptPendingSearch = '';
  receiptsPending: {
    receiptType: string,
    receiptNo: string,
    receiptDate: any,
    batchNo: string,
    purchaseOrderNo: string,
    vendor: string,
    invoiceNo: string,
    invoiceDate: string,
    purchaseAddressCode: string,
    purchaseAddressName: string,
    whse: string,
    lines: {
      lineKey: string,
      orderLineKey: string,
      itemCode: string,
      description: string,
      qtyOrdered: number,
      qtyAvailable: number,
      qtyReceived: number,
      qtyCounted: number,
      unitCost: number,
    }[]
  }[] = [];
 historyLog: {
    PONumber: string,
    ItemCode: string,
    BatchNo: string,
    InvoiceNo: string,
    ReceiptNo: string,
    Qty: number,
    ReceivedDate: Date,
    internalStatus: string,
  }[] = [];

  receiptUpdateLoading: boolean;
  receiptSelected: boolean;
  receiptSelectedIndex = 0;
  IntStatus: string;
  LastRecevingDate: Date;
  LastRecevingDate2WeeksAgo: Date;

  fileLoading: boolean;
  fileArray = [];
  fileList = [];
  sortedReceiptsByType: {
    PONumber: string,
    ItemCode: string,
    BatchNo: string,
    InvoiceNo: string,
    ReceiptNo: string,
    Qty: number,
    ReceivedDate: Date,
    internalStatus: string,
  }[] = [];
  sortedReceiptsByTypeAndDate: {
    PONumber: string,
    ItemCode: string,
    BatchNo: string,
    InvoiceNo: string,
    ReceiptNo: string,
    Qty: number,
    ReceivedDate: Date,
    internalStatus: string,
  }[] = [];
  poLoading: boolean;
  loadingHistory = false;
  poLines = [];
  badReceiptDate: boolean;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.getPendingReceipts();
    this.getReceiptsHist();
    this.badReceiptDate = false;
    this.IntStatus = 'All';
    this.poNumFilter = '';
    this.BatchNumFilter = '';
    this.LastRecevingDate = new Date();
    this.sortedReceiptsByTypeAndDate = this.historyLog.sort((a,b) => (a.internalStatus.toLocaleLowerCase() > b.internalStatus.toLocaleLowerCase()) ? 1 :
    (a.internalStatus.toLocaleLowerCase() < b.internalStatus.toLocaleLowerCase()) ? -1 : 0);
  }


  sortReceiptHistByError(){
   this.sortedReceiptsByTypeAndDate = this.historyLog.sort((a,b) => (a.internalStatus.toLocaleLowerCase() > b.internalStatus.toLocaleLowerCase()) ? 1 :
    (a.internalStatus.toLocaleLowerCase() < b.internalStatus.toLocaleLowerCase()) ? -1 : 0);
  }


  receiptChosen(receipt: any) {
    const receiptIndex = this.receiptsPending
                          .findIndex(x => x.purchaseOrderNo === receipt.purchaseOrderNo && x.invoiceNo === receipt.invoiceNo &&
                                          x.receiptNo === receipt.receiptNo);
    console.log(receipt, receiptIndex);
    this.receiptSelectedIndex = receiptIndex;
    this.selectedReceiptDate = new Date(this.receiptsPending[receiptIndex].receiptDate);
    this.receiptSelected = true;
  }

  cancelReceipt() {
    this.receiptSelected = false;
  }

  filterByProfitCenter(profitCenterCode: string) {
    return this.receiptsPending.filter(x => x.purchaseAddressCode === profitCenterCode);
  }

  filterProfitCenter() {
    if (this.receiptProfitCenterSelected === '') {
      return this.receiptProfitCenters;
    }

    return this.receiptProfitCenters.filter(x => x.code === this.receiptProfitCenterSelected);
  }

  logDate(event, receipt) {
    if (new Date(receipt.receiptDate).getMonth() !== new Date(event).getMonth()){
      this.badReceiptDate = true;
      console.log(this.selectedReceiptDate);
      console.log(event);
      this.selectedReceiptDate = receipt.receiptDate;
      this.alertify.warning('Date outside current pay period. Please select a date within this Month.');
      return;
    }
    // console.log('logDate Run');
    if (this.selectedReceiptDate.getTime() !== new Date(event).getTime()) {
      this.badReceiptDate = false;
      console.log('Old date:' + this.selectedReceiptDate);
      // workTicket.scheduleDate = new Date(event);
      this.selectedReceiptDate = new Date(event);
      receipt.receiptDate = this.datePipe.transform(this.selectedReceiptDate, 'yyyy-MM-ddTHH:mm:ss');
      console.log(receipt.receiptDate);
      console.log(receipt);
      // rowPhase.updated = true;
      // console.log(event);
      // console.log('new Date:' + this.selectedScheduleDate);
    }
  }

  lastDate(y, m){
    return new Date(y, m + 1, 0).getDate();
  }

  firstDate(y, m){
    return new Date(y, m, 1).getDate();
  }

  logStatus(){
    // console.log('filter status', this.IntStatus);
  }

  changeTextColorIfError(step:any){
    if(step.internalStatus === 'Error')
    {
      //background color == danger
      return 'red';
    }
  }

  historyFilters(step: any){
    const setDate = new Date(step.ReceivedDate).setHours(0, 0, 0, 0);
    const filDate = new Date(this.LastRecevingDate).setHours(0, 0, 0, 0);

    return(step.internalStatus === this.IntStatus || this.IntStatus === 'All') && setDate >= filDate
      && (this.poNumFilter !== '' ? step.PONumber.toLocaleLowerCase().includes(this.poNumFilter.toLocaleLowerCase()) : true)
      && (this.BatchNumFilter !== '' ? step.BatchNo.toLocaleLowerCase().includes(this.BatchNumFilter.toLocaleLowerCase()) : true);
  }

  getReceiptsHist() {
    this.historyLog = [];
    this.loadingHistory = true;
    this.sageApi.pullReport('ReceiptOfGoods/LogHistory').subscribe((Items: Array<any>) => {
      console.log(Items);
      if (Array.isArray(Items)) {
        Items.forEach(item => {
          const obj = {
            PONumber: item.PoNumber,
            ItemCode: item.ItemCode,
            BatchNo: item.BatchNo,
            InvoiceNo: item.InvoiceNo,
            ReceiptNo: item.ReceiptNo,
            Qty: item.QtyReceived,
            ReceivedDate: item.DateReceived,
            internalStatus: item.InternalStatus,
          };
          this.historyLog.push(obj);
        });
      }
      this.sortReceiptHistByError();

    }, err => {
      console.log(err);
      this.loadingHistory = false;
      this.alertify.error(err.message);
    }, () => {
      console.log(this.receiptsPending);
      this.loadingHistory = false;
    });
  }

  getPendingReceipts() {
    this.receiptsPending = [];
    this.sageApi.pullReport('ReceiptOfGoods').subscribe((receipts: Array<any>) => {
      console.log(receipts);
      if (Array.isArray(receipts)) {
        receipts.forEach(receipt => {
          const profitCenterExists = this.receiptProfitCenters.some(x => x.code === receipt.PurchaseAddressCode);

          if (!profitCenterExists) {
            const profitCenter = {
              name: receipt.PurchaseName,
              code: receipt.PurchaseAddressCode
            };

            this.receiptProfitCenters.push(profitCenter);
          }

          const receiptExists = this.receiptsPending
                              .some(x => x.purchaseOrderNo === receipt.PurchaseOrderNo &&
                                         x.invoiceNo === receipt.UDF_EDI_INVOICE_NO &&
                                         x.receiptNo === receipt.ReceiptNo);
          if (!receiptExists) {
            const receiptObj = {
              receiptType: receipt.ReceiptType,
              receiptNo: receipt.ReceiptNo,
              receiptDate: receipt.ReceiptDate,
              batchNo: receipt.BatchNo,
              purchaseOrderNo: receipt.PurchaseOrderNo,
              vendor: receipt.VendorNo,
              invoiceNo: receipt.UDF_EDI_INVOICE_NO,
              invoiceDate: receipt.UDF_EDI_INVOICE_DATE.slice(0, 10),
              purchaseAddressName: receipt.PurchaseName,
              purchaseAddressCode: receipt.PurchaseAddressCode,
              whse: receipt.WarehouseCode,
              lines: [
                {
                  lineKey: receipt.LineKey,
                  orderLineKey: receipt.OrderLineKey,
                  itemCode: receipt.ItemCode,
                  description: receipt.ItemCodeDesc,
                  qtyOrdered: receipt.OriginalQtyOrdered,
                  qtyAvailable: receipt.QuantityAvailable,
                  qtyReceived: receipt.QuantityReceived,
                  qtyCounted: receipt.QuantityReceived,
                  unitCost: receipt.UnitCost
                }
              ]
            };
            this.receiptsPending.push(receiptObj);
          } else {
            const receiptIndex = this.receiptsPending
                                  .findIndex(x => x.purchaseOrderNo === receipt.PurchaseOrderNo && x.invoiceNo === receipt.UDF_EDI_INVOICE_NO &&
                                    x.receiptNo === receipt.ReceiptNo);
            const lineObj = {
              itemCode: receipt.ItemCode,
              description: receipt.ItemCodeDesc,
              lineKey: receipt.LineKey,
              orderLineKey: receipt.OrderLineKey,
              qtyOrdered: receipt.OriginalQtyOrdered,
              qtyAvailable: receipt.QuantityAvailable,
              qtyReceived: receipt.QuantityReceived,
              qtyCounted: receipt.QuantityReceived,
              unitCost: receipt.UnitCost
            };
            this.receiptsPending[receiptIndex].lines.push(lineObj);
          }
        });
      }
    }, err => {
      console.log(err);
      this.alertify.error(err.message);
    }, () => {
      console.log(this.receiptsPending);
      this.chooseReceiptFromParameters();
    });
  }

  chooseReceiptFromParameters() {
    this.route.queryParams.subscribe(params => {
      const receiptParameterIndex = this.receiptsPending
                                .findIndex(
                                  x => x.invoiceNo.toLocaleLowerCase().replace(/\./g, '') === params?.InvoiceNo.toLocaleLowerCase().replace(/\./g, '')
                                  && x.purchaseOrderNo === params?.PurchaseOrderNo
                                );
      if (receiptParameterIndex >= 0) {
        this.receiptChosen(receiptParameterIndex);
      }
    });
  }

  itemsOverReceived() {
    // return this.receiptsPending[this.receiptSelectedIndex].lines.some(lines => lines.qtyCounted > lines.qtyAvailable + lines.qtyReceived);
    // INFO: Allowing overreceiving of items
    return false;
  }

  putDeleteReceiptOfGoods() {
    this.receiptDeleteLoading = true;
    const observables = [];

    const body: {
      Username: string,
      Password: string,
      BatchNo: string,
    } = {
      Username: this.authService.decodedToken.nameid,
      Password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      BatchNo: this.receiptDeleteBatchNo,
    };

    observables.push(this.sageApi.postRequest('ReceiptOfGoods/Delete', body));

    concat(...observables).subscribe(response => {
      console.log('put request', response);
    }, err => {
      console.log(err);
      this.alertify.error('Error - ' + err.error.Message);
      this.receiptDeleteLoading = false;
    }, () => {
      this.alertify.success('Batch ' + this.receiptDeleteBatchNo + ' successfully deleted!');
      this.receiptsPending = this.receiptsPending.filter(item => {
        return item.batchNo !== this.receiptDeleteBatchNo;
      });
      this.receiptDeleteLoading = false;
      this.closeModal();
    });
  }

  putUpdateReceiptOfGoods() {
    this.receiptUpdateLoading = true;
    const observables = [];
    const body: {
      Username: string,
      Password: string,
      BatchNo: string,
      ReceiptType: string,
      ReceiptNo: string,
      ReceiptDate: string,
      Lines: {
        LineKey: string,
        QuantityReceived: number
      }[],
      OverReceivedItems: {
        PurchaseOrderNo: string,
        InvoiceNo: string,
        InvoiceDate: string,
        Lines: {
          ItemCode: string,
          OrderLineKey: string,
          QuantityReceived: string,
          UnitCost: string
        }[],
      }
    } = {
      Username: this.authService.decodedToken.nameid,
      Password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      BatchNo: this.receiptsPending[this.receiptSelectedIndex].batchNo,
      ReceiptType: this.receiptsPending[this.receiptSelectedIndex].receiptType,
      ReceiptNo: this.receiptsPending[this.receiptSelectedIndex].receiptNo,
      ReceiptDate: this.receiptsPending[this.receiptSelectedIndex].receiptDate,
      Lines: [],
      OverReceivedItems: {
        PurchaseOrderNo: this.receiptsPending[this.receiptSelectedIndex].purchaseOrderNo,
        InvoiceNo: this.receiptsPending[this.receiptSelectedIndex].invoiceNo,
        InvoiceDate: this.receiptsPending[this.receiptSelectedIndex].invoiceDate,
        Lines: []
      }

    };
    this.receiptsPending[this.receiptSelectedIndex].lines.forEach(line => {
      if (line.qtyCounted >= 0 && line.qtyCounted > line.qtyAvailable + line.qtyReceived) {
        // over receive
        const overReceivedItem = {
          ItemCode: line.itemCode,
          OrderLineKey: line.orderLineKey,
          QuantityReceived: (line.qtyCounted - (line.qtyAvailable + line.qtyReceived)).toString(),
          UnitCost: line.unitCost.toString()
        };
        body.OverReceivedItems.Lines.push(overReceivedItem);

        const fullyReceivedItem = {
          LineKey: line.lineKey,
          QuantityReceived: line.qtyAvailable + line.qtyReceived
        };
        body.Lines.push(fullyReceivedItem);

      } else if (line.qtyCounted >= 0 && line.qtyCounted !== line.qtyReceived) {
        const obj = {
          LineKey: line.lineKey,
          QuantityReceived: Boolean(line.qtyCounted) ? line.qtyCounted : 0
        };
        body.Lines.push(obj);
      }
    });
    // console.log(body);
    observables.push(this.sageApi.putRequest('ReceiptOfGoods/App', body));

    concat(...observables).subscribe(response => {
      console.log('put request', response);
    }, err => {
      console.log(err);
      this.alertify.error('Error - ' + err.error.Message);
      this.receiptUpdateLoading = false;
    }, () => {
      this.alertify.success(this.receiptsPending[this.receiptSelectedIndex].purchaseOrderNo + ' receipt successfully updated!');
      this.resetData();
      this.getPendingReceipts();
    });
  }

  openModal(template: TemplateRef<any>, batchNo: string = '') {
    this.receiptDeleteBatchNo = batchNo;
    this.modalTemplate = this.modalService.show(template);
  }

  closeModal() {
    this.modalTemplate.hide();
  }

  purchaseLines() {
    const route = `ReceiptOfGoods/PurchaseOrder?PurchaseOrderNo=${this.receiptsPending[this.receiptSelectedIndex].purchaseOrderNo}`;
    this.poLoading = true;
    this.poLines = [];
    this.sageApi.pullReport(route)
      .subscribe((Lines: Array<any>) => {
        if (Array.isArray(Lines)) {
          Lines.forEach((line) => {
            const obj = {
              itemCode: line.ItemCode,
              description: line.ItemCodeDesc,
              lineKey: line.LineKey,
              qtyOrdered: line.QuantityOrdered,
              qtyAvailable: line.QuantityAvailable,
              qtyReceived: 0,
              unitCost: line.UnitCost
            };
            if (!this.receiptsPending[this.receiptSelectedIndex].lines
                  .some(item => item.itemCode === line.ItemCode && item.orderLineKey === line.LineKey) && line.QuantityAvailable > 0) {
              this.poLines.push(obj);
            }
          });
        }
      }, err => {
        console.log(err);
        this.poLoading = false;
        this.alertify.error(err.message);
      }, () => {
        this.poLoading = false;
        console.log(this.poLines);
      });
  }

  addPurchaseOrderLine(item: any, index: number) {
    const observables = [];
    const body = {
      Username: this.authService.decodedToken.nameid,
      Password: this.authService.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'],
      BatchNo: this.receiptsPending[this.receiptSelectedIndex].batchNo,
      ReceiptType: this.receiptsPending[this.receiptSelectedIndex].receiptType,
      ReceiptNo: this.receiptsPending[this.receiptSelectedIndex].receiptNo,
      Lines: []
    };

    const obj = {
      ItemCode: item.itemCode,
      LineKey: item.lineKey,
      QuantityReceived: item.qtyAvailable === '' ? 0 : item.qtyAvailable,
      UnitCost: item.unitCost
    };

    body.Lines.push(obj);

    observables.push(this.sageApi.postRequest('ReceiptOfGoods/AddItems', body));

    concat(...observables).subscribe((response: any) => {
      console.log('post request', response);
      const lineObj = {
        itemCode: item.itemCode,
        description: item.description,
        lineKey: response,
        orderLineKey: item.lineKey,
        qtyOrdered: item.qtyOrdered,
        qtyAvailable: item.qtyAvailable,
        qtyReceived: item.qtyAvailable,
        qtyCounted: item.qtyAvailable,
        unitCost: item.unitCost
      };
      this.receiptsPending[this.receiptSelectedIndex].lines.push(lineObj);
      this.poLines.splice(index, 1);
    }, err => {
      console.log(err);
      this.alertify.error('Error - ' + err.message);
    }, () => {
      this.alertify.success(item.itemCode + ' successfully added!');
    });
  }

  fileNames() {
    const route = `ReceiptOfGoods/file?purchaseOrderNo=${this.receiptsPending[this.receiptSelectedIndex].purchaseOrderNo}`;
    this.fileList = [];
    this.sageApi.pullReport(route).subscribe((response: any) => {
      console.log(response, response.split(', \\\\gp-nas2\\SO_WT_Files'));
      const splitFiles = response.split(', \\\\gp-nas2\\SO_WT_Files');
      splitFiles.forEach(file => {
        console.log(file);
        if (file) {
          this.fileList.push({ name: file.split(this.receiptsPending[this.receiptSelectedIndex].purchaseOrderNo + '\\')[1]});
        }
      });
    }, err => {
      console.log(err);
      this.alertify.error(err.message);
    }, () => {
      console.log(this.fileList);
    });
  }

  fileDownload(fileName: string) {
    // tslint:disable-next-line:max-line-length
    const route = `ReceiptOfGoods/file/download?purchaseOrderNo=${this.receiptsPending[this.receiptSelectedIndex].purchaseOrderNo}&fileName=${fileName}`;

    this.sageApi.getFile(route).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      if (fileName) {
        downloadLink.setAttribute('download', fileName);
      }
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.parentNode.removeChild(downloadLink);
    }, err => {
      console.log(err);
      this.alertify.error(err.message);
    });
  }

  fileChosen(event) {
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        const obj = {
          name: files[index].name,
          data: files[index]
        };

        this.fileArray = [obj];
      }
    }
  }

  fileUpload() {
    this.fileLoading = true;
    // tslint:disable-next-line:max-line-length
    const route = `ReceiptOfGoods/file/upload?purchaseOrderNo=${this.receiptsPending[this.receiptSelectedIndex].purchaseOrderNo}&invoiceNo=${this.receiptsPending[this.receiptSelectedIndex].invoiceNo}`;
    const sentFiles = [];

    this.fileArray.forEach( file => {
      sentFiles.push(file.data);
    });

    console.log(sentFiles);

    this.sageApi.uploadFile(route, sentFiles).subscribe((res) => {
      console.log(res);
    }, err => {
      console.log(err);
      this.fileLoading = false;
    }, () => {
      this.resetUpload();
      this.alertify.success('File Loaded');
    });
  }

  resetUpload() {
    this.fileArray = [];
    this.fileLoading = false;
  }

  resetData() {
    this.receiptSelected = false;
    this.receiptsPending = [];
    this.receiptSelectedIndex = 0;
    this.receiptUpdateLoading = false;
    this.receiptPendingSearch = '';
  }
}
