<mat-form-field appearance="outline" [class]="ffClass" class="bseasel">
    <mat-label>{{placeholder}}</mat-label>
    <mat-select #searchableSelect [formControl]="selectedOption" [placeholder]="placeholder" (selectionChange)="onSelChange()"
        [class]="selClass" class="bseasel-sel">
        <input matInput [formControl]="inputControl" (keyup)="onSearchDebounce($event.target.value)"
            (keydown)="$event.stopPropagation()" [placeholder]="placeholder" class="bseasel-sel-in">
        <mat-divider></mat-divider>
        <mat-option *ngIf="!required" [value]="" class="bseasel-sel-op none">None</mat-option>
        <mat-divider></mat-divider>
        <ng-container *ngFor="let op of options">
            <mat-option [value]="op.valueOf()" *ngIf="!isThisOptionHidden(op.toString())" [class.empty]="op.valueOf()==''" class="bseasel-sel-op">{{op.valueOf()!=''?op.toString():'Empty'}}</mat-option>
        </ng-container>
    </mat-select>
</mat-form-field>