<div class="container-fluid">
  <div class="card border-1 m-3">
    <!--    Header-->
    <div class="card-header">
      <div class="row mb-1" id="addressInfo">
        <div class="col-sm-3 pt-2 text-center">
          <label for="address">Address or WT#</label>
        </div>
        <div class="col-sm-7">
          <div class="col">
            <input class="form-control" [(ngModel)]="addressName" (typeaheadOnSelect)="addressSelected($event)"
                   [typeahead]="addressList" typeaheadOptionField="name" placeholder="Enter Address or WT#" autocomplete="off">
            <small *ngIf="wtsLoading">Getting {{ addressName }} Info...</small>
          </div>
        </div>
      </div>
    </div>
    <!--    End of Header-->
    <div class="card-body border-0">
      <div class="row" id="wtsList">
        <div class="col-3">
          <label>Phase Filter: </label>
<!--          <input class="form-control" [(ngModel)]="phaseName" (typeaheadOnSelect)="phaseSelected($event)"-->
<!--                 [typeahead]="phaseList" typeaheadOptionField="name" placeholder="phases" autocomplete="off">-->
          <div class="row">
            <div class="col-7">
              <input type="text" required [disabled]="isLoading" class="form-control"
                     [(ngModel)]="phaseName" placeholder="Filter Phases..."/>
            </div>
            <div class="col">
              <button class="btn btn-primary" [disabled]="loadRefresh" (click)="refreshLoad()"><span *ngIf="!loadRefresh">Refresh</span><span *ngIf="loadRefresh"><i class="fas fa-sync fa-spin"></i></span></button>
            </div>
          </div>
        </div>
        <div class="col text-danger">
          **<b>WARNING</b>: Changing of the schedule will be instant, <b>HOWEVER</b> it will take time for the change
          to be recognized by the rest of the system (database). Secondly, the table below sometimes have an issue with
          loading the date and tech for the phases. The refresh button will new the table to reload the tech and date.
          It may take a few clicks of the refresh button.**
        </div>
        <div class="col-12 pt-2">
          <table class="table table-striped">
            <thead>
            <th (click)="sortTicketsTable('wtNum')">WT#</th>
            <th (click)="sortTicketsTable('itemCode')">Phase</th>
            <th (click)="sortTicketsTable('statusCode')">Status</th>
            <th (click)="sortTicketsTable('promiseDate')">Date</th>
            <th (click)="sortTicketsTable('techName')">Tech</th>
            <th>Scheduling Reason</th>
            <th>Assign</th>
            </thead>
            <tbody>
            <ng-container *ngFor="let ticket of tickets">
              <tr *ngIf="filterWTPhases(ticket)">
                <!--                <td>{{ticket.salesOrderNo}}-{{ticket.wtNum}}</td>-->
                <td>{{ticket.wtNum + ' - ' + ticket.wtStep}} </td>
                <td>{{ticket.itemCode}}</td>
                <td>{{ticket.statusCode}}</td>
                <td>{{ticket.scheduleDate | date}}</td>
                <td>{{ticket.techID}} - {{ticket.techName}}</td>
                <td></td>
                <td>
                  <button class="btn btn-primary mx-2" (click)="openModal(techModal, ticket)">Tech</button>
                  <button class="btn btn-primary mx-2" (click)="assigningDateBuild(ticket)">
                    <span *ngIf="!ticket.assigningDate">Date</span>
                    <span *ngIf="isLoading"><i class="fas fa-sync fa-spin"></i></span>
                    <span *ngIf="ticket.assigningDate">Go to Bottom <i class="fas fa-check"></i></span></button>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div cdkDropListGroup>
    <div class="card border-1 m-3 mt-0" id="tenDaySchedule">
      <!--    Header-->
      <div class="card-header">
        <div class="row mb-1 text-center" id="">
          <h5> 5 Day Schedule Report</h5>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-sm-3 col-lg-3">
              <div class="card m-1">
                <!-- * BEGINNING of Superintendent Selection -->
                <div class="btn-group" dropdown>
                  <button type="button" dropdownToggle class="btn btn-outline-dark dropwodn-toggle">{{selectedFilterName}} Selected <span class="caret"><i
                    class="fas fa-caret-down"></i></span></button>
                  <ul *dropdownMenu class="dropdown-menu" role="menu">
                    <li role="menuitem" *ngFor="let filt of filterList; index as index">
                      <a (click)="setFilter(index)" class="dropdown-item" style="cursor: pointer;">
                        <span>{{filt.name}}</span>
                        <i *ngIf="selectedFilterName === filt.name" class="fas
                    fa-check fa-border fa-pull-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <!-- * END of Superintendent Selection -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--    End of Header-->
      <div class="card-body border-0">
<!--        <div class="row mb-2">-->
<!--          <div class="col-2 text-center">-->
<!--            City Regions:-->
<!--          </div>-->
<!--          <div class="col-2">-->
<!--            <button class="btn" (click)="sanARegion === true ? sanARegion = false : sanARegion = true"-->
<!--                    [ngStyle]="{'background-color': sanARegion ? 'yellow' : 'red', 'color': sanARegion? 'black' : 'white'}">-->
<!--              <span *ngIf="!sanARegion">San Antonio</span>-->
<!--              <span *ngIf="sanARegion">San Antonio <i class="fas fa-check"></i></span></button>-->
<!--          </div>-->
<!--          <div class="col-2">-->
<!--            <button class="btn" (click)="dallasRegion === true ? dallasRegion = false : dallasRegion = true"-->
<!--                    [ngStyle]="{'background-color': dallasRegion ? 'yellow' : 'red', 'color': dallasRegion? 'black' : 'white'}">-->
<!--              <span *ngIf="!dallasRegion">Dallas</span>-->
<!--              <span *ngIf="dallasRegion">Dallas <i class="fas fa-check"></i></span></button>-->
<!--          </div>-->
<!--          <div class="col-2">-->
<!--            <button class="btn" (click)="otherRegion === true ? otherRegion = false : otherRegion = true"-->
<!--                    [ngStyle]="{'background-color': otherRegion ? 'yellow' : 'red', 'color': otherRegion? 'black' : 'white'}">-->
<!--              <span *ngIf="!otherRegion">Other</span>-->
<!--              <span *ngIf="otherRegion">Other <i class="fas fa-check"></i></span></button>-->
<!--          </div>-->
<!--        </div>-->
<!--        <hr>-->
        <div class="row">
          <div class="col-4 text-center">
            <div class="card border-dark">
              <div class="card-header">
                <div>WT box / drag box</div>
                <!--                <div class="card-title">{{assignWTNo}}</div>-->
              </div>
<!--              <ng-container *ngIf="assigningDate">-->
                <div class="card-body">
                  <ul class="list-group list-group-flush" *ngIf="fiveDayArray[7] !== undefined"
                      cdkDropList
                      cdkDropListOrientation="vertical"
                      [cdkDropListData]="fiveDayArray[7].tickets"
                      (cdkDropListDropped)="drop($event, 'newTicket', 7)">
                    <ng-container
                      *ngFor="let tickets of fiveDayArray[7].tickets; let ticketIndex = index; let c = count; let isFirst = first;">
                    <li class="list-group-item" cdkDrag>
                      <ul class="list-group">
                        <li class="list-group-item border-0 p-0 text-sm font-weight-bold">#{{tickets.salesOrderNo}}-{{tickets.wtNum}}</li>
                        <li class="list-group-item border-0 p-0 text-sm">Address: {{tickets.address}}</li>
                        <li class="list-group-item border-0 p-0 text-sm">City: {{tickets.city}}</li>
                        <li class="list-group-item border-0 p-0 text-sm">Subdivision: {{tickets.subdivision}}</li>
                        <li class="list-group-item border-0 p-0 text-sm">Phase: {{tickets.itemCode === '' ? setPhaseByCrew(tickets) : tickets.itemCode}}</li>
                        <li class="list-group-item border-0 p-0 text-sm">Status: {{tickets.statusCode}}</li>
                        <li class="list-group-item border-0 p-0 text-sm">Crew: {{tickets.techID + ' - ' + getTechName(tickets.techID)}}</li>
                      </ul>
                    </li>
                    <li class="list-group-item"></li>
                    </ng-container>
                  </ul>
                </div>
<!--                </ng-container>-->
            </div>

          </div>
          <div class="col-7 text-center">
            <ul class="list-group list-group-flush border-dark">
              <li class="list-group-item">Filters:</li>
              <li class="list-group-item">
                <ul class="list-group list-group-horizontal">
                  <li class="list-group-item col-6 border-0 pr-1">
                    <input type="text" required [disabled]="isLoading" class="form-control"
                           [(ngModel)]="wtFilter" placeholder="Filter WT#..."/>
                  </li>
                  <li class="list-group-item col-6 border-0 pl-1">
                    <input type="text" required [disabled]="isLoading" class="form-control"
                           [(ngModel)]="addressFilter" placeholder="Filter Address..."/>
                  </li>
                </ul>
              </li>
              <li class="list-group-item">
                <ul class="list-group list-group-horizontal">
                  <li class="list-group-item col-6 border-0 pr-1">
                    <input type="text" required [disabled]="isLoading" class="form-control"
                           [(ngModel)]="phaseFilter" placeholder="Filter Phase..."/>
                  </li>
                  <li class="list-group-item col-6 border-0 pl-1">
                    <input type="text" required [disabled]="isLoading" class="form-control"
                           [(ngModel)]="statusFilter" placeholder="Filter Status..."/>
                  </li>
                </ul>
              </li>
              <li class="list-group-item">
                <ul class="list-group list-group-horizontal">
                  <li class="list-group-item col-6 border-0 pr-1">
                    <input type="text" required [disabled]="isLoading" class="form-control"
                           [(ngModel)]="crewFilter" placeholder="Filter Crew..."/>
                  </li>
                  <li class="list-group-item col-6 border-0 pl-1">
                    <input type="text" required [disabled]="isLoading" class="form-control"
                           [(ngModel)]="divisionFilter" placeholder="Filter Subdivision..."/>
<!--                    <button class="btn btn-primary" (click)="clearFilters()">Clear</button>-->
                  </li>
                </ul>
              </li>

            </ul>
            <!--            <label>Start Date:-->
            <!--              <input type="text"-->
            <!--                     class="form-control"-->
            <!--                     bsDatepicker-->
            <!--                     [bsConfig]="bsConfig"-->
            <!--                     (bsValueChange)="this.logDate($event)"-->
            <!--                     [(bsValue)]="weekofDate">-->
            <!--            </label>-->
          </div>
        </div>
<!--                Missing SEWER/WATER Alert-->
<!--        <div class="col text-danger my-2">-->
<!--          <span><b class="text-danger">**Note</b>: A table will appear here for missing Sewer and water phases that-->
<!--            have a phase assigned in the schedule below. The Table will initially generate based the six days below.-->
<!--            The table will do a check every time to move the week (by pressing either the '<' or '>' buttons on the-->
<!--            sides of the dates) and add any other missing phases it finds to the list. This table clears on a page-->
<!--            refresh.<b class="text-danger">**</b>-->
<!--        </span>-->
<!--        </div>-->
<!--        <div class="row" *ngIf="missingWaterSewer.length > 0">-->
<!--          <label class="text-warning"><h5>Warning: WT missing Water and Sewer Phases</h5></label>-->
<!--          <table class="table table-danger">-->
<!--            <thead>-->
<!--            <tr>-->
<!--              <th class="col-1">WT#</th>-->
<!--              <th class="col-3">Missing Phase</th>-->
<!--              <th class="col-5"></th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <ng-container *ngFor="let item of missingWaterSewer">-->
<!--              <tr>-->
<!--                <td class="col-1">{{item.SalesOrderNo}}</td>-->
<!--                <td class="col-3">{{item.Phase}}</td>-->
<!--                <td class="col-5"></td>-->
<!--              </tr>-->
<!--            </ng-container>-->
<!--          </table>-->
<!--        </div>-->
        <div class="row">
          <div class="col-12 col-sm-2 pl-0">
            <div class="card mt-3 border-dark">
              <!--              <div class="card-header border-dark" [ngStyle]="{ 'background-color' : badDate.get(colOneDate) ? 'red' : ''}">-->
              <div class="card-header border-dark">
                <div class="col-12 text-center pl-0">
                  <a (click)="calculateNextDays('backwards')">
                    <i class="fas fa-chevron-left fa-border fa-pull-left border-0"></i>
                    {{ colOneDate | date : "EEE MMM, d, y" }} <span
                    *ngIf="fiveDayArray[0] !== undefined ? runBadDateCheck(fiveDayArray[0].tickets, colOneDate) : ''"><i class="fas fa-times"></i></span></a>
                </div>
              </div>
              <ng-container *ngIf="fiveDayArray[0] !== undefined">
                <ul class="list-group list-group-flush"
                    cdkDropList
                    cdkDropListOrientation="vertical"
                    [cdkDropListData]="fiveDayArray[0].tickets"
                    (cdkDropListDropped)="drop($event, colOneDate , 0)">
                  <ng-container
                    *ngFor="let tickets of fiveDayArray[0].tickets; let ticketIndex = index; let c = count; let isFirst = first;">
                    <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                      Total Tickets {{ filteredCounter(fiveDayArray[0].tickets) }}
                    </li>
                    <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                      Total City {{ getTicketsWithCityInspectionsCount(fiveDayArray[0].tickets) }}
                    </li>

                    <app-assign-schedule-ticket-item
                      [tickets]="tickets"
                      [filterCounterTickets]="filterFiveDay(tickets)"
                      [ticketColor]="TicketColor(tickets)"
                      [techName]="getTechName(tickets.techID)"
                      [phaseByCrew]="setPhaseByCrew(tickets)"
                      (onReqModalOpen)="openModal(techModal, tickets)"
                    ></app-assign-schedule-ticket-item>
                  </ng-container>
                  <li class="list-group-item"></li>
                  <li class="list-group-item"></li>
                </ul>
              </ng-container>
            </div>
          </div>
          <div class="col-12 col-sm-2 pl-0">
            <div class="card mt-3 border-dark">
              <!--              <div class="card-header border-dark" [ngStyle]="{ 'background-color' : badDate.get(colTwoDate) ? 'red' : ''}">-->
              <div class="card-header border-dark">
                <div class="col-12 text-center">
                  {{ colTwoDate | date : "EEE MMM, d, y" }} <span
                  *ngIf="fiveDayArray[1] !== undefined ? runBadDateCheck(fiveDayArray[1].tickets, colTwoDate): ''"><i class="fas fa-times"></i></span>
                </div>
              </div>
              <ng-container *ngIf="fiveDayArray[1] !== undefined">
                <ul class="list-group list-group-flush"
                    cdkDropList
                    cdkDropListOrientation="vertical"
                    [cdkDropListData]="fiveDayArray[1].tickets"
                    (cdkDropListDropped)="drop($event, colTwoDate, 1)">
                  <ng-container
                    *ngFor="let tickets of fiveDayArray[1].tickets; let ticketIndex = index; let c = count; let isFirst = first;">
                    <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                      Total Tickets {{ filteredCounter(fiveDayArray[1].tickets) }}
                    </li>
                    <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                      Total City {{ getTicketsWithCityInspectionsCount(fiveDayArray[1].tickets) }}
                    </li>
                    <app-assign-schedule-ticket-item
                      [tickets]="tickets"
                      [filterCounterTickets]="filterFiveDay(tickets)"
                      [ticketColor]="TicketColor(tickets)"
                      [techName]="getTechName(tickets.techID)"
                      [phaseByCrew]="setPhaseByCrew(tickets)"
                      (onReqModalOpen)="openModal(techModal, tickets)"
                    ></app-assign-schedule-ticket-item>
                  </ng-container>
                  <li class="list-group-item"></li>
                  <li class="list-group-item"></li>
                </ul>
              </ng-container>
            </div>
          </div>
          <div class="col-12 col-sm-2 pl-0">
            <div class="card mt-3 border-dark">
              <!--              <div class="card-header border-dark" [ngStyle]="{ 'background-color' : badDate.get(colThreeDate) ? 'red' : ''}">-->
              <div class="card-header border-dark">
                <div class="col-12 text-center">
                  {{ colThreeDate | date : "EEE MMM, d, y" }} <span
                  *ngIf="fiveDayArray[2] !== undefined ? runBadDateCheck(fiveDayArray[2].tickets, colThreeDate):''"><i class="fas fa-times"></i></span>
                </div>
              </div>
              <ng-container *ngIf="fiveDayArray[2] !== undefined">
                <ul class="list-group list-group-flush"
                    cdkDropList
                    cdkDropListOrientation="vertical"
                    [cdkDropListData]="fiveDayArray[2].tickets"
                    (cdkDropListDropped)="drop($event, colThreeDate, 2)">
                  <ng-container
                    *ngFor="let tickets of fiveDayArray[2].tickets; let ticketIndex = index; let c = count; let isFirst = first;">
                    <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                      Total Tickets {{ filteredCounter(fiveDayArray[2].tickets) }}
                    </li>
                    <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                      Total City {{ getTicketsWithCityInspectionsCount(fiveDayArray[2].tickets) }}
                    </li>
                    <app-assign-schedule-ticket-item
                      [tickets]="tickets"
                      [filterCounterTickets]="filterFiveDay(tickets)"
                      [ticketColor]="TicketColor(tickets)"
                      [techName]="getTechName(tickets.techID)"
                      [phaseByCrew]="setPhaseByCrew(tickets)"
                      (onReqModalOpen)="openModal(techModal, tickets)"
                    ></app-assign-schedule-ticket-item>
                  </ng-container>
                  <li class="list-group-item"></li>
                  <li class="list-group-item"></li>
                </ul>
              </ng-container>
            </div>
          </div>
          <div class="col-12 col-sm-2 pl-0">
            <div class="card mt-3 border-dark">
              <!--              <div class="card-header border-dark" [ngStyle]="{ 'background-color' : badDate.get(colFourDate) ? 'red' : ''}">-->
              <div class="card-header border-dark">
                <div class="col-12 text-center">
                  {{ colFourDate | date : "EEE MMM, d, y" }} <span
                  *ngIf="fiveDayArray[3] !== undefined ? runBadDateCheck(fiveDayArray[3].tickets, colFourDate):''"><i class="fas fa-times"></i></span>
                </div>
              </div>
              <ng-container *ngIf="fiveDayArray[3] !== undefined">
                <ul class="list-group list-group-flush"
                    cdkDropList
                    cdkDropListOrientation="vertical"
                    [cdkDropListData]="fiveDayArray[3].tickets"
                    (cdkDropListDropped)="drop($event, colFourDate, 3)">
                  <ng-container
                    *ngFor="let tickets of fiveDayArray[3].tickets; let ticketIndex = index; let c = count; let isFirst = first;">
                    <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                      Total Tickets {{ filteredCounter(fiveDayArray[3].tickets) }}
                    </li>
                    <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                      Total City {{ getTicketsWithCityInspectionsCount(fiveDayArray[3].tickets) }}
                    </li>
                    <app-assign-schedule-ticket-item
                      [tickets]="tickets"
                      [filterCounterTickets]="filterFiveDay(tickets)"
                      [ticketColor]="TicketColor(tickets)"
                      [techName]="getTechName(tickets.techID)"
                      [phaseByCrew]="setPhaseByCrew(tickets)"
                      (onReqModalOpen)="openModal(techModal, tickets)"
                    ></app-assign-schedule-ticket-item>
                  </ng-container>
                  <li class="list-group-item"></li>
                  <li class="list-group-item"></li>
                </ul>
              </ng-container>
            </div>
          </div>
          <div class="col-12 col-sm-2 pl-0">
            <div class="card mt-3 border-dark">
              <!--              <div class="card-header border-dark" [ngStyle]="{ 'background-color' : badDate.get(colFourDate) ? 'red' : ''}">-->
              <div class="card-header border-dark">
                <div class="col-12 text-center">
                  {{ colFiveDate | date : "EEE MMM, d, y" }} <span
                  *ngIf="fiveDayArray[4] !== undefined ? runBadDateCheck(fiveDayArray[4].tickets, colFiveDate):''"><i class="fas fa-times"></i></span>
                </div>
              </div>
              <ng-container *ngIf="fiveDayArray[4] !== undefined">
                <ul class="list-group list-group-flush"
                    cdkDropList
                    cdkDropListOrientation="vertical"
                    [cdkDropListData]="fiveDayArray[4].tickets"
                    (cdkDropListDropped)="drop($event, colFiveDate, 4)">
                  <ng-container
                    *ngFor="let tickets of fiveDayArray[4].tickets; let ticketIndex = index; let c = count; let isFirst = first;">
                    <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                      Total Tickets {{ filteredCounter(fiveDayArray[4].tickets) }}
                    </li>
                    <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                      Total City {{ getTicketsWithCityInspectionsCount(fiveDayArray[4].tickets) }}
                    </li>
                    <app-assign-schedule-ticket-item
                      [tickets]="tickets"
                      [filterCounterTickets]="filterFiveDay(tickets)"
                      [ticketColor]="TicketColor(tickets)"
                      [techName]="getTechName(tickets.techID)"
                      [phaseByCrew]="setPhaseByCrew(tickets)"
                      (onReqModalOpen)="openModal(techModal, tickets)"
                    ></app-assign-schedule-ticket-item>
                  </ng-container>
                  <li class="list-group-item"></li>
                  <li class="list-group-item"></li>
                </ul>
              </ng-container>
            </div>
          </div>
          <div class="col-12 col-sm-2 pl-0">
            <div class="card mt-3 border-dark">
              <!--              <div class="card-header border-dark" [ngStyle]="{ 'background-color' : badDate.get(colFiveDate) ? 'red' : ''}">-->
              <div class="card-header border-dark">
                <div class="col-12 text-center pr-0">
                  <a (click)="calculateNextDays('forward')">{{ colSixDate | date : "EEE MMM, d, y" }} <span
                    *ngIf="fiveDayArray[5] !== undefined ? runBadDateCheck(fiveDayArray[4].tickets, colSixDate):''"><i class="fas fa-times"></i></span>
                    <i class="fas fa-chevron-right fa-border fa-pull-right border-0"></i></a>
                </div>
              </div>
              <ng-container *ngIf="fiveDayArray[5] !== undefined">
                <ul class="list-group list-group-flush"
                    cdkDropList
                    cdkDropListOrientation="vertical"
                    [cdkDropListData]="fiveDayArray[5].tickets"
                    (cdkDropListDropped)="drop($event, colSixDate, 4)">
                  <ng-container
                    *ngFor="let tickets of fiveDayArray[5].tickets; let ticketIndex = index; let c = count; let isFirst = first;">
                    <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                      Total Tickets {{ filteredCounter(fiveDayArray[5].tickets) }}
                    </li>
                    <li class="list-group-item col-sm-12 border-0 p-0 text-sm text-center" *ngIf="isFirst">
                      Total City {{ getTicketsWithCityInspectionsCount(fiveDayArray[5].tickets) }}
                    </li>
                    <app-assign-schedule-ticket-item
                      [tickets]="tickets"
                      [filterCounterTickets]="filterFiveDay(tickets)"
                      [ticketColor]="TicketColor(tickets)"
                      [techName]="getTechName(tickets.techID)"
                      [phaseByCrew]="setPhaseByCrew(tickets)"
                      (onReqModalOpen)="openModal(techModal, tickets)"
                    ></app-assign-schedule-ticket-item>
                  </ng-container>
                  <li class="list-group-item"></li>
                  <li class="list-group-item"></li>
                </ul>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- Assign Tech Modal-->
<ng-template #techModal>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>Assign employee</h2>
        <h5> WT# {{selectedSONum}}-{{selectedWTNum}}</h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <hr>
    <div class="row my-1 text-center">
      <div class="col-3 text-center">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" [(ngModel)]="modalAssignDate" id="assignDateCheckbox">
          <label for="assignDateCheckbox" class="form-check-label">Assign date?</label>
        </div>
      </div>
      <div class="col-3 text-center">
        <input
          type="text"
          class="form-control"
          bsDatepicker
          [bsConfig]="bsConfig"
          (bsValueChange)="this.logDate($event, selectedWT)"
          [(bsValue)]="selectedScheduleDate"
          [disabled]="!modalAssignDate">
      </div>
      <div class="col-3 text-center"><input type="checkbox" class="form-check-input" [(ngModel)]="modalSetRTP" id="assignRTPCheckbox">
        <label for="assignRTPCheckbox" class="form-check-label">move to RTP?</label>
      </div>
    </div>
    <hr>
    <div class="row my-1 text-center">
      <div class="col-3 text-center">
        <input
          type="text"
          required
          [disabled]="isLoading"
          class="form-control"
          [(ngModel)]="filterTechName"
          placeholder="Filter Technician..."
        />
      </div>
      <div class="col-3 text-center">
        <input
          type="text"
          required
          [disabled]="isLoading"
          class="form-control"
          [(ngModel)]="filterscheduleGroup"
          placeholder="Filter Schedule Group..."
        />
      </div>
      <div class="col-2 text-center">
        <button class="btn" (click)="sanTech === true ? sanTech = false : sanTech = true"
                [ngStyle]="{'background-color': sanTech ? 'yellow' : 'red', 'color': sanTech? 'black' : 'white'}">
          <span *ngIf="!sanTech">San Antonio</span>
          <span *ngIf="sanTech">San Antonio <i class="fas fa-check"></i></span></button>
      </div>
      <div class="col-2 text-center">
        <button class="btn" (click)="dallasTech === true ? dallasTech = false : dallasTech = true"
                [ngStyle]="{'background-color': dallasTech ? 'yellow' : 'red', 'color': dallasTech? 'black' : 'white'}">
          <span *ngIf="!dallasTech">Dallas</span>
          <span *ngIf="dallasTech">Dallas <i class="fas fa-check"></i></span></button>
      </div>
      <div class="col-2 text-center">
        <button class="btn" (click)="otherTech === true ? otherTech = false : otherTech = true"
                [ngStyle]="{'background-color': otherTech ? 'yellow' : 'red', 'color': otherTech? 'black' : 'white'}">
          <span *ngIf="!otherTech">Other</span>
          <span *ngIf="otherTech">Other <i class="fas fa-check"></i></span></button>
      </div>
    </div>
    <div class="row my-1 text-center">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
          <th (click)="sortTicketsTable('techID')">Tech ID</th>
          <th (click)="sortTicketsTable('fullName')">Name</th>
          <th (click)="sortTicketsTable('jobCount')">Job Count</th>
          <th (click)="sortTicketsTable('scheduleGroup')">Schedule Group</th>
          <th (click)="sortTicketsTable('assignedLeadman')">Assigned Leadman</th>
          <th>Email</th>
          <th>Crew Lead</th>
          <th>Employee 1</th>
          <th>Employee 2</th>
          <th>Select</th>
          </thead>
          <tbody>
          <ng-container *ngFor="let tech of techList">
            <tr *ngIf="FilterTechCheck(tech)" [ngStyle]="{ 'background-color' : tech.jobCount >= 10 && stopOnBlanks(tech) ? 'yellow' : ''}">
              <!--                <td>{{ticket.salesOrderNo}}-{{ticket.wtNum}}</td>-->
              <td>{{tech.techID}}</td>
              <td>{{tech.fullName}}</td>
              <td>{{tech.jobCount}}</td>
              <td>{{tech.scheduleGroup}}</td>
              <td>{{tech.assignedLeadman}}</td>
              <td>{{tech.email}}</td>
              <td>{{(tech.crewLead + "-" + (getTechName(tech.crewLead)).includes('undefined') ? '' : getTechName(tech.crewLead))}}</td>
              <td>{{(tech.crewEmp1 + "-" + (getTechName(tech.crewEmp1)).includes('undefined') ? '' : getTechName(tech.crewEmp1))}}</td>
              <td>{{(tech.crewEmp2 + "-" + (getTechName(tech.crewEmp2)).includes('undefined') ? '' : getTechName(tech.crewEmp2))}}</td>
              <td>
                <button class="btn btn-primary" (click)="chosenCrewID = tech.techID; filterTechName = tech.fullName">
                  <span *ngIf="chosenCrewID !== tech.techID">Select crew</span>
                  <span *ngIf="isLoading"><i class="fas fa-sync fa-spin"></i></span>
                  <span *ngIf="chosenCrewID === tech.techID">Selected Crew <i class="fas fa-check"></i></span>
                </button>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <hr>
    <div class="row my-1">
      <div class="col-6"></div>
      <div class="col-3 text-center">
        <button class="btn btn-outline-primary" (click)="loadSewerWater(selectedWT, 'techTicket')"
                [disabled]="chosenCrewID === undefined || chosenCrewID === ''">Assign</button>
      </div>
      <div class="col-3 text-center">
        <button class="btn btn-primary" (click)="closeModal()">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>
<!-- Assign Tech Modal END-->

<!-- Confirm Date Modal-->
<ng-template #confirmDate>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>Assign WT to Date:  ?</h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <hr>

  </div>
</ng-template>
<!-- Confirm Date Modal END-->
