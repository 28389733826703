<div class="page">
  <h1 class="title">Rework Queue</h1>
      <div class="newActionButtons">
        <div class="filterOptions">
          <div class="firstrow">
          <mat-form-field appearance="outline">
            <mat-label>Types</mat-label>
            <mat-select [formControl]="types" (selectionChange)="typeFilter()" multiple>
              <mat-option *ngFor="let type of typeList" [value]="type">{{type}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Area Manager</mat-label>
            <mat-select [formControl]="areaManagers" (selectionChange)="typeFilter()" multiple>
              <mat-option *ngFor="let am of areaManagerList" [value]="am">{{am}}</mat-option>
            </mat-select>
          </mat-form-field>
          <app-searchable-msel [selectedOptions]="phases" (onSelectChange)="typeFilter()" placeholder="Phases"
          [options]="phaseList"></app-searchable-msel>
</div>
<div class="firstrow">
  <app-searchable-msel [selectedOptions]="leadmen" (onSelectChange)="typeFilter()" placeholder="Leadman"
    [options]="leadmanList"></app-searchable-msel>
  <app-searchable-msel [selectedOptions]="techs" (onSelectChange)="typeFilter()" placeholder="Techs"
    [options]="techList"></app-searchable-msel>
  <mat-form-field appearance="outline">
    <mat-label>Filter By Address</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Filter Address..." #input>
  </mat-form-field>
</div>
</div>
<div class="submitButton">
  <button class="btn btn-primary" [disabled]="submitTickets.length == 0 || loading"
    (click)="openSubmissionModal(confirmSubmission)">
    <span *ngIf="submitTickets.length == 0">Submit</span>
    <span *ngIf="submitTickets.length == 1">Submit {{submitTickets.length}} Ticket</span>
    <span *ngIf="submitTickets.length > 1">Submit {{submitTickets.length}} Tickets</span>
  </button>
</div>
      </div>
      <div class="mat-elevation-z8 mat-table-responsive" style="overflow: auto;">
        <table mat-table [dataSource]="dataSource" [ngStyle]="{'width':'100%'}" [hidden]="isDataListLoading" matSort>
          <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef>View</th>
            <td mat-cell *matCellDef="let row">
              <button class="btn btn-primary" [disabled]="loadingTicketLines|| checkForDetails(row)"
                (click)="openModalDetails(viewDetails, row )">View</button>
            </td>
          </ng-container>
          <ng-container matColumnDef="workTicketNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>WorkTicket</th>
            <td mat-cell *matCellDef="let row">{{row.workTicketNumber}}</td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
            <td mat-cell *matCellDef="let row">{{row.address}}</td>
          </ng-container>
          <ng-container matColumnDef="phase">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phase</th>
            <td mat-cell *matCellDef="let row">{{row.phase | titlecase}}</td>
          </ng-container>
          <ng-container matColumnDef="scheduleDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Scheduled Date</th>
            <td mat-cell *matCellDef="let row">{{row.scheduleDate}}</td>
          </ng-container>
          <ng-container matColumnDef="tech">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tech</th>
            <td mat-cell *matCellDef="let row">{{row.tech}}</td>
          </ng-container>
          <ng-container matColumnDef="leadman">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Leadman</th>
            <td mat-cell *matCellDef="let row">{{row.leadman}}</td>
          </ng-container>
          <ng-container matColumnDef="statusDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status Date</th>
            <td mat-cell *matCellDef="let row">{{row.statusDate}}</td>
          </ng-container>
          <ng-container matColumnDef="daysInStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Days In Status</th>
            <td mat-cell *matCellDef="let row">{{row.daysInStatus}}</td>
          </ng-container>
          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row">
              <button type="button" *ngIf="row.status === 'REC'" class="btn btn-primary"
                [ngClass]="row.updated ? 'btn-success' : ''" (click)="(row.updated && !row.submitted) ? addToList(row, bothTickets.indexOf(row), 'QAR', row.trackingComment)
                : openModal(addModal, row, bothTickets.indexOf(row), 'QAR')">
                <span *ngIf="!loading && !row.updated">Rewalk</span>
                <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i></span>
                <span *ngIf="!loading && row.updated && !row.submitted">Rewalk Selected <i
                    class="fas fa-check"></i></span>
                <span *ngIf="!loading && row.updated && row.submitted">Updated <i class="fas fa-check"></i></span>
              </button>
              <button type="button" *ngIf="row.status === 'LW'" class="btn btn-warning"
                [ngClass]="row.updated ? 'btn-success' : ''" (click)="(row.updated && !row.submitted) ? addToList(row, bothTickets.indexOf(row), 'QAN', row.trackingComment)
                : openModal(addModal, row, bothTickets.indexOf(row), 'QAN')">
                <span *ngIf="!loading && !row.updated">QAN</span>
                <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i></span>
                <span *ngIf="!loading && row.updated && !row.submitted">QAN Selected <i class="fas fa-check"></i></span>
                <span *ngIf="!loading && row.updated && row.submitted">Updated <i class="fas fa-check"></i></span>
              </button>

              <!-- CLD/INS -->
              <button [disabled]="row.setP || row.setQ" type="button" *ngIf="row.status === 'CLD'"
                class="btn btn-sm btn-primary" [ngClass]="row.updated && row.setC ? 'btn-success' : ''" (click)="(row.updated && row.setC && !row.submitted) ? addToList(row, bothTickets.indexOf(row), 'COM', row.trackingComment)
                          : openModal(addModal, row, bothTickets.indexOf(row), 'COM')">
                <span *ngIf="!loading && !row.updated && !row.setC">COM</span>
                <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i></span>
                <span *ngIf="!loading && row.updated && !row.submitted && row.setC">COM Selected <i
                    class="fas fa-check"></i></span>
                <span *ngIf="!loading && row.updated && row.submitted && row.setC">Updated <i
                    class="fas fa-check"></i></span>
              </button>
              <button [disabled]="row.setC || row.setQ" type="button" *ngIf="row.status === 'CLD'"
                class="btn btn-sm btn-warning ml-1" [ngClass]="row.updated && row.setP ? 'btn-success' : ''" (click)="(row.updated && row.setP && !row.submitted) ? addToList(row, bothTickets.indexOf(row), 'PTL', row.trackingComment)
                          : openModal(addModal, row, bothTickets.indexOf(row), 'PTL')">
                <span *ngIf="!loading && !row.updated && !row.setP">PTL</span>
                <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i></span>
                <span *ngIf="!loading && row.updated && !row.submitted && row.setP">PTL Selected <i
                    class="fas fa-check"></i></span>
                <span *ngIf="!loading && row.updated && row.submitted && row.setP">Updated <i
                    class="fas fa-check"></i></span>
              </button>
              <button [disabled]="row.setC || row.setP" type="button" *ngIf="row.status === 'CLD'"
                class="btn btn-sm btn-danger ml-1" [ngClass]="row.updated && row.setQ ? 'btn-success' : ''" (click)="(row.updated && row.setQ && !row.submitted) ? addToList(row,bothTickets.indexOf(row),'QAR', row.trackingComment)
                          : openModal(addModal, row, bothTickets.indexOf(row), 'QAR')">
                <span *ngIf="!loading && !row.updated && !row.setQ">QAR</span>
                <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i></span>
                <span *ngIf="!loading && row.updated && !row.submitted && row.setQ">QAR Selected <i
                    class="fas fa-check"></i></span>
                <span *ngIf="!loading && row.updated && row.submitted && row.setQ">Updated <i
                    class="fas fa-check"></i></span>
              </button>
              <button type="button" *ngIf="row.status === 'INS'" class="btn btn-warning"
                [ngClass]="row.updated ? 'btn-success' : ''" (click)="(row.updated && !row.submitted) ? addToList(row, bothTickets.indexOf(row), 'CLD', row.trackingComment)
                          : openModal(addModal, row, bothTickets.indexOf(row), 'CLD')">
                <span *ngIf="!loading && !row.updated">CLD</span>
                <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i></span>
                <span *ngIf="!loading && row.updated && !row.submitted">CLD Selected <i class="fas fa-check"></i></span>
                <span *ngIf="!loading && row.updated && row.submitted">Updated <i class="fas fa-check"></i></span>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </div>
    </div>



<!-- addModal modal-->
<ng-template #addModal>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2> Confirm Changes? </h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <hr>
    <div class="row mb-1">
      <div class="col-4 pt-2 text-center">
        <label class="font-weight-bold" for="newTrackComment">Tracking Comment</label>
      </div>
      <div class="col">
        <textarea id="newTrackComment" [(ngModel)]="newComment" maxlength="500" rows="3" class="form-control"
          type="text" name="trackComment"></textarea>
      </div>
    </div>
    <hr>
    <div class="row mb-1">
      <div class="col-sm-6 text-center">
        <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
      </div>
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" (click)="addToList(modalTicket, importTemplateIndex, modalStatus, newComment)"
          [disabled]="isLoading">Add ticket
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!-- addModal modal-->

<!-- View the details of a ticket-->

<ng-template #viewDetails>
  <div class="row p-2">
    <div class="col-10 offset-1 text-center">
      <h2> Details </h2>
    </div>
    <div class="col-1">
      <button type="button" class="close" (click)="closeModal()">
        <span><i class="fas fa-window-close"></i></span>
      </button>
    </div>
  </div>

  <body>
    <p *ngIf="trackingComment != ''"><b>Tracking Comment:</b><br>
      {{trackingComment}}</p>
    <p *ngIf="trackingComment == ''"><b>No Tracking Comment:</b></p>
    <table class="table table-responsive table-striped" *ngIf="lines.length > 0">
      <!-- <thead class="thead-dark">
        <tr> -->
      <th>Phase</th>
      <th>Item Code</th>
      <th>Desc</th>
      <th>Status</th>
      <th>Status Date</th>
      <th>QA Code</th>
      <th>Note</th>
      <!-- </tr>
      </thead> -->
      <tr *ngFor="let ticketLine of lines">
        <td>{{ticketLine.HdrParentItemCode}}</td>
        <td>{{ticketLine.ItemCode}}</td>
        <td>{{ticketLine.ItemCodeDesc.length >= 30 ? ticketLine.extended_desc : ticketLine.ItemCodeDesc}}</td>
        <td>{{ticketLine.StatusCode}}</td>
        <td>{{ticketLine.StatusDate | date: "shortDate"}}</td>
        <td>{{ticketLine.UDF_ASSIGNED_CODE}}</td>
        <td>{{ticketLine.UDF_INSP_NOTES}}</td>
      </tr>
    </table>
    <p *ngIf="lines.length == 0"><b>No ticket history</b></p>
  </body>
</ng-template>

<ng-template #confirmSubmission>
  <div class="row p-2">
    <div class="col-10 offset-1 text-center">
      <h2>Submission Details</h2>
    </div>
    <div class="col-1">
      <button type="button" class="close" (click)="closeModal()">
        <span><i class="fas fa-window-close"></i></span>
      </button>
    </div>
  </div>
  <div class="submission">
    <table class="table table-responsive table-striped" id="subTable">
      <th>SalesOrderNo</th>
      <th>WT#</th>
      <th>Comment</th>
      <th>Status</th>
      <tr *ngFor="let line of submitTickets">
        <td>{{line[0]}}</td>
        <td>{{line[1]}}</td>
        <td>{{line[3]}}</td>
        <td>{{line[4]}}</td>
      </tr>
    </table>
    <div>
      <button class="btn btn-primary" (click)="submitList()" [disabled]="loading">Submit</button>
    </div>
  </div>
</ng-template>
