
<div [class]="screenSize.getClasses() + ' h360'">
  <app-data-table 
    [rawData]="house360Reports" 
    [tableColumns]="[
      {col: 'S_O_', value: 'S_O_', title: 'SO#', size: 'md'}, 
      {col: 'WTNumber', value: 'WTNumber', title: 'WT#', size: 'xs'},
      {col: 'WTStep', value: 'WTStep', title: 'WTStep#', size: 'xs'},
      {col: 'JT158_WTNumber', value: 'JT158_WTNumber', title: 'JTWT#', size: 'xs'},
      {col: 'StatusCode', value: 'StatusCode', title: 'StatusCode', size: 'sm'},
      {col: 'ShipToName', value: 'ShipToName', title: 'Address', size: 'lg'},
      {col: 'Builder', value: 'Builder', title: 'Builder', size: 'md'}, 
      {col: 'UDF_SUBDIVISION_CODE', value: 'UDF_SUBDIVISION_CODE', title: 'Subdivision', size: 'md'},
      {col: 'ItemCode', value: 'ItemCode', title: 'ItemCode', size: 'md'},
      {col: 'ItemCodeDesc', value: 'ItemCodeDesc', title: 'ItemCodeDesc', size: 'lg', includeColumnByDefault: false},
      {col: 'ExtendedDescriptionKey', value: 'ExtendedDescriptionKey', title: 'ExtendedDescription', size: 'xl', includeColumnByDefault: false},
      {col: 'CommentText', value: 'CommentText', title: 'Comment', size: 'xl', includeColumnByDefault: false},
      {col: 'UDF_SCHEDULE_COMMENT', value: 'UDF_SCHEDULE_COMMENT', title: 'ScheduleComment', size: 'lg', includeColumnByDefault: false},
      {col: 'UDF_TRACKING_COMMENT', value: 'UDF_TRACKING_COMMENT', title: 'TrackingComment', size: 'xl'},
      {col: 'ScheduledDate', value: normalizeDate, title: 'ScheduledDate', size: 'md'},
      {col: 'PromiseDate', value: normalizeDate, title: 'PromiseDate', size: 'md', includeColumnByDefault: false},
      {col: 'Phase_Completed_Date', value: normalizeDate, title: 'PhaseCompletedDate', size: 'md'},
      {col: 'UDF_CLOSING_DATE', value: normalizeDate, title: 'ClosingDate', size: 'lg', includeColumnByDefault: false},
      {col: 'TimeToComplete', value: 'TimeToComplete', title: 'TimeToComplete', size: 'md'},
      {col: 'TechCode', value: 'TechCode', title: 'TechCode', size: 'sm', includeColumnByDefault: false},
      {col: 'techName', value: 'techName', title: 'TechName', size: 'lg'},
      {col: 'UDF_HOMEOWNER_NAME', value: 'UDF_HOMEOWNER_NAME', title: 'HomeOwner', size: 'lg'},
      {col: 'UDF_HOMEOWNER_PHONE', value: 'UDF_HOMEOWNER_PHONE', title: 'HomeOwnerPhone#', size: 'md'},
      {col: 'UDF_HOMEOWNER_PHONE_2', value: 'UDF_HOMEOWNER_PHONE_2', title: 'HomeOwnerPhoneAlt#', size: 'md'},
      {col: 'TotalRevenue', value: normalizeMoney, title: 'TotalRevenue', size: 'md'},
    ]"
    [inColumns]="[ {col: 'techName', title: 'TechName'}, {col: 'UDF_SUBDIVISION_CODE', title: 'Subdivision'} ]"
    [mselColumns]="[ {col: 'StatusCode', title: 'StatusCode'}, {col: 'ItemCode', title: 'ItemCode'}, {col: 'Builder', title: 'Builder'} ]"
    [isLoading]="house360ReportsLoading"
    [includeColumnSelector]="true"
    [searchOnChange]="true"
    accordianControls="true"
    accordianScreenSize="smd"
    title="360 Reports"
  >
    <ng-container table-content>
      <div class="h360-table-tabcon-ctrls">
        <mat-form-field appearance="outline" class="h360-table-tabcon-ctrls-mobileshare">
          <mat-label>Address</mat-label>
          <input [formControl]="house360ReportsAddressContrl" placeholder="Address" (input)="setHouse360ReportsSearchable()" matInput>
        </mat-form-field>
        <mat-form-field appearance="outline" class="h360-table-tabcon-ctrls-mobileshare">
          <mat-label>SO#</mat-label>
          <input [formControl]="house360ReportsSONumContrl" placeholder="SO#" (input)="setHouse360ReportsSearchable()" matInput >
        </mat-form-field>
        <mat-form-field appearance="outline" class="h360-table-tabcon-ctrls-mobilefull" (click)="onHouse360ReportsDateClick()">
          <mat-label>Completed</mat-label>
          <mat-date-range-input [formGroup]="house360ReportsDateContrl" [rangePicker]="house360Picker" [min]="house360ReportsDateContrlMin" [max]="house360ReportsDateContrlMax" [disabled]="house360ReportsLoading">
            <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="onHouse360ReportsDateChange($event)">
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onHouse360ReportsDateChange($event)">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="house360Picker" (click)="onHouse360ReportsDateClick()"></mat-datepicker-toggle>
          <mat-date-range-picker #house360Picker [disabled]="house360ReportsLoading"></mat-date-range-picker>
        </mat-form-field>
        <button (click)="pullHouse360()" [disabled]="house360ReportsLoading || !house360ReportsSearchable" mat-raised-button color="accent" extended class="h360-table-tabcon-ctrls-search h360-table-tabcon-ctrls-mobilefull mat-elevation-z0">Search</button>
      </div>
      <h6 *ngIf="!screenSize.getClassesArray().includes('smd')">Filters</h6>
    </ng-container>
  </app-data-table>
  
  <app-data-table 
    [rawData]="house360TrimItems" 
    [tableColumns]="[
      {col: 'SONum', value: 'SONum', title: 'SO#', size: 'md'},
      {col: 'WTNumber', value: 'WTNumber', title: 'WT#', size: 'xs'},
      {col: 'WTStep', value: 'WTStep', title: 'WTStep#', size: 'xs'},
      {col: 'ShipToName', value: 'ShipToName', title: 'Address', size: 'lg'},
      {col: 'ItemCode', value: 'ItemCode', title: 'ItemCode', size: 'md'},
      {col: 'ItemCodeDesc', value: 'ItemCodeDesc', title: 'ItemCodeDesc', size: 'lg'},
      {col: 'HdrParentItemCode', value: 'HdrParentItemCode', title: 'HDRParentCode', size: 'md'},
      {col: 'Description', value: 'Description', title: 'Description', size: 'lg', includeColumnByDefault: false},
      {col: 'ExtendedDescriptionText', value: 'ExtendedDescriptionText', title: 'ExtendedDescription', size: 'xl'},
      {col: 'QuantityOrdered', value: 'QuantityOrdered', title: 'Ordered', size: 'sm'},
      {col: 'QuantityShipped', value: 'QuantityShipped', title: 'Shipped', size: 'sm'},
      {col: 'UDF_DATE_SCHEDULED', value: normalizeDate, title: 'ScheduleDate', size: 'md'},
      {col: 'UDF_LOCATION', value: 'UDF_LOCATION', title: 'Location', size: 'lg'},
      {col: 'UnitCost', value: normalizeMoney, title: 'UnitCost', size: 'md', includeColumnByDefault: false},
      {col: 'UnitPrice', value: normalizeMoney, title: 'UnitPrice', size: 'md', includeColumnByDefault: false},
    ]"
    [inColumns]="[]"
    [mselColumns]="[ {col: 'ItemCode', title: 'ItemCode'}, {col: 'HdrParentItemCode', title: 'HDRParentCode'} ]"
    [isLoading]="house360TrimItemsLoading"
    [includeColumnSelector]="true"
    [searchOnChange]="true"
    accordianControls="true"
    accordianScreenSize="smd"
    title="House 360 Trim Items"
  >
    <ng-container table-content>
      <div class="h360-table-tabcon-ctrls">
        <mat-form-field appearance="outline" class="h360-table-tabcon-ctrls-mobileshare">
          <mat-label>Address</mat-label>
          <input [formControl]="house360TrimAddressContrl" placeholder="Address" (input)="setHouse360TrimSearchable()" matInput>
        </mat-form-field>
        <mat-form-field appearance="outline" class="h360-table-tabcon-ctrls-mobileshare">
          <mat-label>SO#</mat-label>
          <input [formControl]="house360TrimSONumContrl" placeholder="SO#" (input)="setHouse360TrimSearchable()" matInput >
        </mat-form-field>
        
        <mat-form-field (click)="onHouse360TrimDateClick()" appearance="outline" class="h360-table-tabcon-ctrls-mobilefull">
          <mat-label>Scheduled</mat-label>
          <mat-date-range-input [formGroup]="house360TrimDateContrl" [rangePicker]="trimPicker" [min]="house360TrimDateContrlMin" [max]="house360TrimDateContrlMax" [disabled]="house360TrimItemsLoading">
            <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="onHouse360TrimDateChange($event)">
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onHouse360TrimDateChange($event)">
          </mat-date-range-input>
          <mat-datepicker-toggle (click)="onHouse360TrimDateClick()" matSuffix [for]="trimPicker"></mat-datepicker-toggle>
          <mat-date-range-picker #trimPicker [disabled]="house360TrimItemsLoading"></mat-date-range-picker>
        </mat-form-field>
        <button (click)="pullHouse360TrimItems()" [disabled]="house360TrimItemsLoading || !house360TrimSearchable" mat-raised-button color="accent" extended class="h360-table-tabcon-ctrls-search h360-table-tabcon-ctrls-mobilefull mat-elevation-z0">Search</button>
      </div>
      <h6 *ngIf="!screenSize.getClassesArray().includes('smd')">Filters</h6>
    </ng-container>
  </app-data-table>
</div>