<div [class]="screenSize.getClasses() + ' quoting-theme quotesview'">
  <h1 class="quotesview-title">
    Quotes
    <mat-spinner *ngIf="loading" [diameter]="30" class="quotesview-title-spinner"></mat-spinner>
  </h1>
  <div class="quotesview-controls">
      <mat-form-field appearance="outline" class="quotesview-controls-kitsearch">
        <mat-label>Quote</mat-label>
        <input
          (keyup.enter)="searchQuotes()"
          matInput
          type="text"
          [formControl]="quoteControl"
          class="quotesview-controls-kitsearch-in"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="quotesview-controls-kitsearch">
        <mat-label>Builder</mat-label>
        <input
          (keyup.enter)="searchQuotes()"
          matInput
          type="text"
          [formControl]="builderControl"
          class="quotesview-controls-kitsearch-in"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="kitsview-controls-region">
        <mat-label>Region</mat-label>
        <mat-select [formControl]="regionControl">
          <mat-option [value]="'All'">All</mat-option>
          <mat-option *ngFor="let reg of regions" [value]="reg.valueOf()">{{reg}}</mat-option>
          <mat-option [value]="'NA'">NA</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="kitsview-controls-region">
        <mat-label>Status</mat-label>
        <mat-select [formControl]="statusControl">
          <mat-option [value]="'All'">All</mat-option>
          <mat-option *ngFor="let stat of quoteStatuses" [value]="stat.valueOf()">{{stat}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button (click)="searchQuotes()" [disabled]="loading" color="accent" mat-flat-button class="quotesview-controls-search">Search</button>
  </div>

  <div class="quotesview-add">
    <button (click)="openNewQuoteSheet()" mat-fab class="quotesview-add-button">
      <i class="fas fa-plus"></i>
    </button>
  </div>

  <div class="quotesview-list">
    <mat-card class="quotesview-list-item" *ngFor="let quote of quotes; let kIndex = index">
      <div class="quotesview-list-item-content" [class.quotesview-kit-selected]="quote.selected">
        <div class="quotesview-list-item-content-header">
          <span class="quotesview-list-item-content-title">
            {{quote.Quote_Name}}<sup class="quotesview-list-item-content-status">{{quote.Quote_Status}}</sup>
            <span class="quotesview-list-item-content-builder">{{quote.Quote_Builder}}</span>
          </span>
          <!-- <span class="quotesview-list-item-content-parts">{{ quote.Quote_TotalPart > 0? quote.Quote_TotalPart + " parts" : "No parts" }}</span> -->
          <!-- <span class="quotesview-list-item-content-cost">
            <span class="quotesview-list-item-content-cost-icon">$</span>{{ quote?.Quote_TotalCost?.toFixed(2) || 0 }}
          </span> -->
        </div>
        <div class="quotesview-list-item-content-description">{{ quote.Quote_Desc }}</div>
      </div>
      <div class="quotesview-list-item-controls">
        <a [routerLink]="'/quoting/quote/' + quote.Quote_guid">
          <button  long-press (onLongPress)="menuTrigger.openMenu()" color="primary" mat-mini-fab class="quotesview-list-item-controls-view">
            <i class="fas fa-cog"></i>
            <button mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" [style]="{width: '0px', height: '0px'}"></button>
            <mat-menu #menu="matMenu">
              <button [routerLink]="'/quoting/quote/' + quote.Quote_guid" mat-menu-item>Edit Quote</button>
              <button (click)="copyQuote(quote)" mat-menu-item>Copy Quote</button>
            </mat-menu>
          </button>
        </a>
        <button (click)="deleteQuote(quote, kIndex)" color="accent" mat-mini-fab class="quotesview-list-item-controls-delete">
          <span class="quotesview-list-item-controls-delete-icon">&times;</span>
        </button>
      </div>
    </mat-card>
  </div>

  <div *ngIf="!loading && quotePageHasMore" class="quotesview-loadmore">
    <button (click)="searchQuotes(quotePage+1)" mat-button>Load more</button>
  </div>

  <div *ngIf="loading && quotePageHasMore" class="quotesview-loading">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>

  <div 
    *ngIf="
      hasSearched 
      && !loading 
      && (
        (
          quotePage > 0 
          && !quotePageHasMore
        )
      || (
          quotePage == 0 
          && quotes.length == 0
      )
    )
    " 
    class="quotesview-empty"
  >
    <span>No Quotes found</span>
  </div>

  <app-bottom-sheet [open]="newQuoteOpen" (sheetClosed)="newQuoteOpen=false" [sheetWidth]="screenSize.getClassesArray().includes('smd')? '100vw' : '75vw'" class="quotesview-create">
    <h1>
      Create Quote
      <mat-spinner *ngIf="newQuoteLoading" [diameter]="30" class="quotesview-create-spinner"></mat-spinner>
    </h1>
    <div class="quotesview-create-controls">
      <mat-form-field appearance="outline" class="quotesview-create-ff">
        <mat-label>Name</mat-label>
        <input
          (keyup.enter)="createQuote()"
          [formControl]="newQuoteNameControl"
          [disabled]="newQuoteLoading"
          matInput
          type="text"
          class="quotesview-create-ff-in"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="quotesview-create-ff">
        <mat-label>Builder</mat-label>
        <input
          [formControl]="newQuoteBuilderControl"
          [disabled]="newQuoteLoading"
          matInput
          type="text"
          class="quotesview-create-ff-in"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="quotesview-create-ff">
        <mat-label>Sq. Footage</mat-label>
        <input
          [formControl]="newQuoteSquareFootageControl"
          (input)="newQuoteSquareFootageInput($event)"
          [disabled]="newQuoteLoading"
          matInput
          type="text"
          class="quotesview-create-ff-in"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="quotesview-create-ff">
        <mat-label>Expiration</mat-label>
        <input 
          [formControl]="newQuoteExpControl"
          matInput 
          [matDatepicker]="datePicker"
          [min]="newQuoteExpMin"
          [disabled]="newQuoteLoading"
        />
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker [disabled]="newQuoteLoading"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="quotesview-create-ff">
        <mat-label>Region</mat-label>
        <mat-select 
          [formControl]="newQuoteRegionControl"
          [disabled]="newQuoteLoading"
        >
          <mat-option *ngFor="let reg of regions" [value]="reg.valueOf()">{{reg}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="quotesview-create-ff">
        <mat-label>Status</mat-label>
        <mat-select 
          [formControl]="newQuoteStatusControl"
          [disabled]="newQuoteLoading"
        >
          <mat-option *ngFor="let status of quoteStatuses" [value]="status.valueOf()">{{ status }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field appearance="outline" class="quotesview-create-ff quotesview-create-desc">
      <mat-label>Description</mat-label>
      <textarea
        [formControl]="newQuoteDescControl"
        [disabled]="newQuoteLoading"
        matInput
        type="text"
        class="quotesview-create-ff-in"
      ></textarea>
    </mat-form-field>

    <div class="quotesview-create-qs">
      <h3 class="quotesview-create-qs-title">Questions: </h3>
      <ng-container
        *ngFor="let question of quoteQuestions; let qqIndex = index"
      >
        <app-qqrow
          [disabled]="newQuoteLoading"
          [quoteQuestion]="question"
          (answerChange)="newQuoteAnswerCheckChanged($event, qqIndex)"
        >
        </app-qqrow>
      </ng-container>
    </div>

    <span *ngIf="newQuoteErrorMsg != null" class="quotesview-create-error">*{{newQuoteErrorMsg}}</span>
    <div class="quotesview-create-actions">
      <button (click)="createQuote()" [disabled]="!newQuoteValid()" color="primary" mat-flat-button class="quotesview-create-actions-submit">Create Quote</button>
    </div>
  </app-bottom-sheet>

</div>