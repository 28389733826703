import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/Components/Platform/confirm-modal/confirm-modal.component';
import { SuperConfirmModalComponent } from 'src/app/Components/Platform/super-confirm-modal/super-confirm-modal.component';

@Injectable({
    providedIn: 'root',
})
export class BaseModalService {

  constructor(
    private dialog: MatDialog
  ){}

  superConfirm(title: string, message: string, superConfirmText: string): Observable<boolean> {
    const obs: Observable<boolean> = new Observable((subscriber)=>{
      const dialogRef = this.dialog.open(SuperConfirmModalComponent, {
        data: {title, message, superConfirmText}
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if(result){
          subscriber.next(true);
          subscriber.complete();
        }else{
          subscriber.next(false);
          subscriber.complete();
        }
      });
    });
    const sobs = obs.pipe(share());
    sobs.subscribe(()=>{});
    return sobs;
  }

  confirm(title: string, message: string): Observable<boolean> {
    const obs: Observable<boolean> = new Observable((subscriber)=>{
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {title, message}
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {// Woulda returned afterClosed, but it's result is not typed
        if(result){
          subscriber.next(true);
          subscriber.complete();
        }else{
          subscriber.next(false);
          subscriber.complete();
        }
      });
    });
    const sobs = obs.pipe(share());
    sobs.subscribe(()=>{});
    return sobs;
  }

  alert(){
    // ToDo, make an alert modal in the same vein as the confirm modal
  }

}
