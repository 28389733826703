import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AlertifyService } from "../../../_services/alertify/alertify.service";
import { SageApiService } from "../../../_services/sageApi/sageApi.service";
import { AuthService } from "../../../_services/auth/auth.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { concat } from "rxjs";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-new-user",
  templateUrl: "./new-user.component.html",
  styleUrls: ["./new-user.component.css"],
})
export class NewUserComponent implements OnInit {
  username = "";
  jobTitle = "";
  techCode = "";
  super = "";
  allUsers = [];
  allRoles = [];
  allTechs = [];
  allSupers = [];
  allUsersWithNoAccount = [];
  jobTitlesForRole = [];
  jobDepartments = [];
  userinformation = {
    username: "",
    role: "",
    superName: "",
    techCode: "",
    jobTitle: "",
    jobDepartment: "",
  };
  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  ngOnInit() {
    this.clear();
    this.getAllRoles();
    this.getAllTechs();
    this.getUsersWithNoAccount();
    this.getAvailableSupers();
    this.userinformation = {
      username: "",
      role: "",
      superName: "",
      techCode: "",
      jobTitle: "",
      jobDepartment: "",
    };
  }
  clear() {
    this.allUsers = [];
    this.allRoles = [];
    this.allTechs = [];
    this.allSupers = [];
    this.allUsersWithNoAccount = [];
    this.jobTitlesForRole = [];
    this.jobDepartments = [];
    this.userinformation = {
      username: "",
      role: "",
      superName: "",
      techCode: "",
      jobTitle: "",
      jobDepartment: "",
    };
  }
  getAvailableSupers() {
    this.sageApi.pullReport("EditUser/AvailableSupers").subscribe(
      (supers: Array<any>) => {
        if (Array.isArray(supers)) {
          supers.forEach((lead) => {
            const obj = {
              nickname: lead.nickname,
            };
            this.allSupers.push(obj);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
      },
      () => {}
    );
  }
  getUsersWithNoAccount() {
    this.sageApi.pullReport("EditUser/GetUsersWithNoAccount").subscribe(
      (usernames: Array<any>) => {
        if (Array.isArray(usernames)) {
          usernames.forEach((user) => {
            this.allUsersWithNoAccount.push(user);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
      },
      () => {}
    );
  }

  getAllUsers() {
    this.sageApi.pullReport("EditUser/EditExisting").subscribe(
      (users: Array<any>) => {
        if (Array.isArray(users)) {
          users.forEach((user) => {
            const obj = {
              username: user.username,
              role: user.role,
              superName: user.superName,
              techCode: user.techCode,
              jobTitle: user.jobTitle,
              jobDepartment: user.jobDepartment,
            };
            this.allUsers.push(obj);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
      },
      () => {
        console.log(this.allUsers);
      }
    );
  }
  selectRole(event: TypeaheadMatch) {
    if (this.allRoles.includes(event.value)) {
      this.userinformation.role = event.value;
    }
  }

  confirmRole() {
      if (!this.allRoles.includes(this.userinformation.role)) {
        this.userinformation.role = "";
      }

  }
  confirmSuper(){
    if(!this.allSupers.includes(this.userinformation.superName)){
      this.userinformation.superName = "";
    }
  }
  selectSuper(event: TypeaheadMatch){
    this.super = event.value;
  }
  selectUser(event: TypeaheadMatch) {
    this.username = event.value;
  }

  getAllRoles() {
    this.sageApi.pullReport("EditUser/Roles").subscribe(
      (users: Array<any>) => {
        if (Array.isArray(users)) {
          users.forEach((user) => {
            if (!this.allRoles.includes(user.role)) {
              this.allRoles.push(user.role);
            }
            if (!this.jobDepartments.includes(user.jobDepartment)) {
              this.jobDepartments.push(user.jobDepartment);
            }
            if (!this.jobTitlesForRole.includes(user.jobTitle)) {
              this.jobTitlesForRole.push(user.jobTitle);
            }
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
      },
      () => {
        console.log(this.allRoles);
      }
    );
  }

  selectTech(event: TypeaheadMatch) {
    this.techCode = event.item.techCode;
    console.log(event);
  }
  selectTechCode(techCode) {
    console.log(techCode);
  }
  getAllTechs() {
    this.sageApi.pullReport("EditUser/Technicians").subscribe(
      (techs: Array<any>) => {
        if (Array.isArray(techs)) {
          techs.forEach((tech) => {
            const obj = {
              techCode: tech.TechnicianDeptNo + tech.TechnicianNo,
              name: tech.FirstName + " " + tech.LastName,
            };
            this.allTechs.push(obj);
          });
          const blankObj = {
            techCode: "",
            name: "",
          };
          this.allTechs.push(blankObj);
        }
      },
      (err) => {
        this.alertify.error(err.message);
      },
      () => {
        console.log(this.allTechs);
      }
    );
  }

  checkForm() {
    if (this.allRoles.includes(this.userinformation.role)) {
      if (this.allSupers.some((x) => x.nickname == this.userinformation.superName
        ) || this.userinformation.superName == ""
      ) {
        if (
          this.allTechs.some((x) => x.techCode == this.techCode)
        ) {
          return false;
        }
      }
    } else {
      return true;
    }
  }

  submitForm(): void {
    console.log(this.userinformation);
    const obj = {
      username: this.userinformation.username,
      role: this.userinformation.role,
      jobTitle: this.userinformation.jobTitle,
      jobDepartment: this.userinformation.jobDepartment,
      techCode: this.techCode,
      superName: this.userinformation.superName,
    };

    const observables = [];

    console.log(obj);
    observables.push(this.sageApi.putRequest("EditUser/AddUser", obj));

    concat(...observables).subscribe(
      (response: any) => {
        console.log(response);
        this.alertify.success("Successfully created account")
      },
      (err) => {
        this.alertify.error("Error submitting new user.");
      },
      () => {
        this.clear();
        this.ngOnInit();
      }
    );
  }
}
