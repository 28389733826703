import ContextQuoteDtoInterface from "../_services/sageApi/interfaces/pullReport/ContextQuoteDtoInterface";
import ContextQuoteKitDtoInterface from "../_services/sageApi/interfaces/pullReport/ContextQuoteKitDtoInterface";
import ContextQuoteKitPartDtoInterface from "../_services/sageApi/interfaces/pullReport/ContextQuoteKitPartDtoInterface";
import ContextQuotePartDtoInterface from "../_services/sageApi/interfaces/pullReport/ContextQuotePartInterface";
import QuoteKitDtoInterface from "../_services/sageApi/interfaces/pullReport/QuoteKitDtoInterface";
import QuotePartDtoInterface from "../_services/sageApi/interfaces/pullReport/QuotePartDtoInterface";

export type ContextQuoteOrContextQuoteKitType =
  | ContextQuoteKitDtoInterface
  | ContextQuoteDtoInterface;

export const getImmediateQuoteSubkits = (
  param: ContextQuoteOrContextQuoteKitType
) => {
  let subkits: ContextQuoteKitPartDtoInterface[] = [];
  for (let qkp of param.QuoteKitParts) {
    if (qkp?.QuoteKit != null) {
      const qk = qkp;
      subkits.push(qk);
    }
  }
  return subkits;
};

export const getImmediateQuoteKitParts = (
  param: ContextQuoteOrContextQuoteKitType
) => {
  let parts: ContextQuoteKitPartDtoInterface[] = [];
  for (let qkp of param.QuoteKitParts) {
    if (qkp?.QuotePart != null) {
      parts.push(qkp);
    }
  }
  return parts;
};

export const getFullQuoteKitParts = (
  param: ContextQuoteOrContextQuoteKitType
) => {
  let parts: ContextQuoteKitPartDtoInterface[] = [];
  for (let qkp of param.QuoteKitParts) {
    if (qkp?.QuotePart != null) {
      parts.push(qkp);
    }
    if (qkp?.QuoteKit != null) {
      const qk = qkp.QuoteKit;
      parts = parts.concat(getFullQuoteKitParts(qk));
    }
  }
  return parts;
};

export const getQuoteKitCost = (param: ContextQuoteKitDtoInterface) => {
  let total = 0;
  for (let qkp of param.QuoteKitParts) {
    if (qkp?.QuotePart != null) {
      const qp = qkp.QuotePart;
      total += qp.QuotePart.QuotePart_Cost * qkp.QuoteKitPart.QuoteKitPart_Quantity;
    }
    if (qkp?.QuoteKit != null) {
      const qk = qkp.QuoteKit;
      total += getQuoteKitCost(qk) * qkp.QuoteKitPart.QuoteKitPart_Quantity;
    }
  }
  return total;
};

export const getQuoteCost = (param: ContextQuoteDtoInterface) => {
  let total = 0;
  for (let qkp of param.QuoteKitParts) {
    if (qkp?.QuotePart != null) {
      const qp = qkp.QuotePart;
      total += qp.QuotePart.QuotePart_Cost;
    }
    if (qkp?.QuoteKit != null) {
      const qk = qkp.QuoteKit;
      total += getQuoteKitCost(qk);
    }
  }
  return total;
};

export const getQuoteKitTotalParts = (param: ContextQuoteKitPartDtoInterface) => {
  let total = 0;
  if(param.QuoteKit != null && param.QuoteKit.QuoteKitParts != null){
    for (let qkp of param.QuoteKit.QuoteKitParts) {
      if (qkp?.QuotePart != null) {
        total += qkp.QuoteKitPart.QuoteKitPart_Quantity * (param?.QuoteKitPart?.QuoteKitPart_Quantity||1);
      }else if (qkp?.QuoteKit != null) {
        const oldttl = total;
        total += getQuoteKitTotalParts(qkp) * (param?.QuoteKitPart?.QuoteKitPart_Quantity||1);
      }
    }
  }
  return total;
};

export const getQuoteTotalParts = (param: ContextQuoteDtoInterface) => {
  let total = 0;
  for (let qkp of param.QuoteKitParts) {
    if (qkp?.QuotePart != null) {
      const qp: ContextQuoteKitPartDtoInterface = qkp;
      total += qp.QuoteKitPart.QuoteKitPart_Quantity;
    }else if (qkp?.QuoteKit != null) {
      total += getQuoteKitTotalParts(qkp);
    }
  }
  return total;
};

export const getQuoteParts = (param: ContextQuoteOrContextQuoteKitType): ContextQuotePartDtoInterface[] => {
  // Loop through the quote kit parts and add the QuotePart to the list if it doesn't already exist in the list
  let parts: ContextQuotePartDtoInterface[] = [];
  for (let qkp of param.QuoteKitParts) {
    if (qkp?.QuotePart != null) {
      const qp = qkp.QuotePart;
      if (!parts.some((p) => p.QuotePart.QuotePart_guid === qp.QuotePart.QuotePart_guid)) {
        parts.push(qp);
      }
    }
    if (qkp?.QuoteKit != null) {
      const qk = qkp.QuoteKit;
      parts = parts.concat(getQuoteParts(qk));
    }
  }
  return parts;
}

export const removeGuidPortionFromQuoteErrorMessage = (str: string) => {
  return str
    .replace(
      /\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/, // The guid portion
      ""
    )
    .replace(/::/g, ""); // The :: portion
};

class QuoteUtil {
  public getImmediateQuoteSubkits = getImmediateQuoteSubkits;
  public getImmediateQuoteKitParts = getImmediateQuoteKitParts;
  public getFullQuoteKitParts = getFullQuoteKitParts;
  public getQuoteCost = getQuoteCost;
  public getQuoteKitCost = getQuoteKitCost;
  public getQuoteTotalParts = getQuoteTotalParts;
  public getQuoteKitTotalParts = getQuoteKitTotalParts;
  public getQuoteParts = getQuoteParts;
  public removeGuidPortionFromQuoteErrorMessage =
    removeGuidPortionFromQuoteErrorMessage;
}
export default QuoteUtil;
