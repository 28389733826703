import { Component, OnInit } from "@angular/core";
import { SageApiService } from "../../../_services/sageApi/sageApi.service";
import { AlertifyService } from "../../../_services/alertify/alertify.service";

@Component({
  selector: "app-truck-manager",
  templateUrl: "./truck-manager.component.html",
  styleUrls: ["./truck-manager.component.css"],
})
export class TruckManagerComponent implements OnInit {
  technicians = [];
  itemList = [];
  OnHandStock: OnHandStockInterface[] = []
  OnHandStockLoading: boolean = true;
techId= '';
  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService
  ) {}

  ngOnInit() {
    this.OnHandStockLoading = false;
    this.getAllTechniciansFromServiceTitan();
  }
  getAllTechniciansFromServiceTitan() {
    this.sageApi
      .pullReport("ServiceTitan/settings/technicians")
      .subscribe((rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            if (row.name != "TEST") {
              this.technicians.push(row);
            }
          });
        }
      });
    console.log(this.technicians);
  }
  setTech(tech) {
    this.techId = tech.id;
  }

  pullSageItemList() {
    this.OnHandStockLoading = true;
    this.sageApi.pullReport("ServiceTitanIntegration/TruckManager/" + this.techId).subscribe(
      (items: Array<OnHandStockInterface>) => {
        this.OnHandStock = items;
        this.OnHandStockLoading = false;
        console.log(this.OnHandStock)
      },
      (err) => {
        console.log(err);
        this.alertify.error(err.message);
        this.OnHandStockLoading = false;
      },
    );
  }
}
 interface OnHandStockInterface {
  Name: string,
  ItemCode: string,
  Description: string,
  TotalQuantityOnHand: number
 }
