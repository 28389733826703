<div class="container pb-5">
  <div class="card my-5 shadow-lg text-center" id="page">
    <div class="navigate">
    <button mat-raised-button color="success" routerLink="/Builder/ChangeOrder">
      View All Change Orders
    </button>
    </div>
    <h1>Change Order</h1>

    <form class="ChangeOrderForm">
      <mat-form-field appearance="outline">
        <mat-label>Builder</mat-label>
        <input matInput type="text" appearance="outline" name="builder" placeholder="Builder"
          [(ngModel)]="changeOrder.BuilderName" [typeaheadMinLength]="0" [typeahead]="customerList"
          typeaheadOptionField="name" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
          [typeaheadOptionsInScrollableView]="5" autocomplete="on" (typeaheadOnSelect)="selectCustomer($event)">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Reason</mat-label>
        <textarea matInput appearance="outline" name="reason" placeholder="Reason" [(ngModel)]="changeOrder.reason">
      </textarea>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Expected Date Change</mat-label>
        <input matInput [matDatepicker]="pickerInvoice" name="dateOfChange" [(ngModel)]="changeOrder.dateOfChange">
        <mat-datepicker-toggle matSuffix [for]="pickerInvoice"></mat-datepicker-toggle>
        <mat-datepicker #pickerInvoice></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Inspector</mat-label>
        <input matInput type="text" appearance="outline" name="inspector" placeholder="Inspector"
          [(ngModel)]="changeOrder.inspector">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Subdivision</mat-label>
        <input matInput type="text" appearance="outline" name="subdivision" placeholder="Subdivision"
          [(ngModel)]="changeOrder.subdivision" [typeaheadMinLength]="0" [typeahead]="subdivisionList"
          typeaheadOptionField="UDF_SUBDIVISION_CODE" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
          [typeaheadOptionsInScrollableView]="5" autocomplete="on" (typeaheadOnBlur)="onSubdivisionSelect($event)"
          (typeaheadOnSelect)="onSubdivisionSelect($event)">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <input matInput type="text" appearance="outline" name="city" placeholder="City" [(ngModel)]="changeOrder.city">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Warehouse</mat-label>
        <mat-select [(ngModel)]="changeOrder.Warehouse" name="warehouse" [(value)]="changeOrder.Warehouse" (selectionChange)="loadItemListPrice()">
          <mat-option value="001">001</mat-option>
          <mat-option value="005">005</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Item Code</mat-label>
        <input matInput type="text" appearance="outline" name="itemCode" placeholder="Item Code" [disabled]="itemList.length == 0"
        [(ngModel)]="changeOrder.ItemCode" (typeaheadOnSelect)="ItemCodeChange($event)" [typeahead]="itemList"
        typeaheadOptionField="name" typeaheadOptionsLimit="150" [typeaheadScrollable]="true"
        [typeaheadOptionsInScrollableView]="5" placeholder="Item" autocomplete="off">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Quantity</mat-label>
        <input matInput matTooltip='Must be a whole number' type="number" (change)="wholeNumber()" appearance="outline"
        name="quantity" placeholder="Quantity" [(ngModel)]="changeOrder.Quantity">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Taxable</mat-label>
        <mat-select (selectionChange)="calculateCost()" [(ngModel)]="changeOrder.taxable" name="taxable" [(value)]="changeOrder.taxable" (selectionChange)="loadItemListPrice()">
          <mat-option value="Y">Yes</mat-option>
          <mat-option value="N">No</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Margin</mat-label>
        <input matInput (change)="calculateCost()" type="number" appearance="outline" name="margin" placeholder="Margin"
          [(ngModel)]="changeOrder.Margin">
        </mat-form-field>
        <div [ngStyle]="{'display': 'flex'}">
          <mat-checkbox (change)="override()" name="overrideCost"[(ngModel)]="overrideCost">Override Cost</mat-checkbox>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Cost</mat-label>
        <input matInput [disabled]="changeOrder.OverrideCost == 'N'" type="number" appearance="outline" name="cost" placeholder="Cost"
          [(ngModel)]="displayCost" [value]="displayCost" (change)="changeCost()">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Action</mat-label>
        <mat-select [(ngModel)]="changeOrder.action" [(value)]="changeOrder.action" appearance="outline" name="action" placeholder="Action">
          <mat-option value="Add">Add</mat-option>
          <mat-option value="Remove">Remove</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
<div>
  <button  [disabled]="sending || checkForDetails()" mat-raised-button color="success" (click)="Submit()">
    <span *ngIf="sending"><i class="fas fa-sync fa-spin"></i></span>Submit Change Order</button>
</div>
  </div>

</div>
