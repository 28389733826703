import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import KitRegions from '../KitRegions';
import KitPhases from '../KitPartPhases';
import { timeStamp } from 'console';

@Component({
  selector: 'app-koqappbar',
  templateUrl: './KOQAppBarComponent.html',
  styleUrls: ['./KOQAppBarComponent.css', '../QuotingTheme.scss'],
})
export class KOQAppBarComponent implements OnInit, OnDestroy, OnChanges {

  @Input() title = 'Untitled';
  @Input() description = '';
  @Input() saving: boolean = false;

  @Input() region = '';
  @Input() phase = '';
  
  @Input() partTotal = 0;
  @Input() costTotal = 0;

  isSidebarOpen = false;
  
  @ViewChild('titleInput', {static: true}) public titleInput: ElementRef;
  
  titleControl = new FormControl(this.title, [Validators.required]);
  descriptionControl = new FormControl(this.description, [Validators.required, Validators.maxLength(255)]);

  regionControl = new FormControl(this.region, [Validators.required]);
  phaseControl = new FormControl(this.phase, [Validators.required]);

  heightOffset = 0;

  kitRegions = KitRegions;

  @Output() onChange = new EventEmitter<{
    title: string,
    region: string,
    phase: string,
    description: string,
  }>();
  
  @Output() onUpdateParts = new EventEmitter();
  
  @Output() onTotalHover = new EventEmitter<boolean>();
  
  constructor(public screenSize: ScreenSizeService) {}
  
  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.resetControls();
  }

  resetControls(){
    this.titleControl = new FormControl(this.title, [Validators.required]);
    this.regionControl = new FormControl(this.region, [Validators.required]);
    this.phaseControl = new FormControl(this.phase, [Validators.required]);
    this.descriptionControl = new FormControl(this.description, [Validators.maxLength(255)]);
  }

  titleBlur(event){
    if(this.titleControl.value.trim() == ''){
      this.titleControl.setValue('Untitled');
    }else{
      this.titleControl.setValue(this.titleControl.value.trim());
    }
  }

  saveChanges(){
    this.title = this.titleControl.value;
    this.region = this.regionControl.value;
    this.onChange.emit({
      title: this.titleControl.value, 
      region: this.regionControl.value,
      phase: this.phaseControl.value,
      description: this.descriptionControl.value,
    });
  }

  cancelChanges(){
    this.titleControl.setValue(this.title);
    this.regionControl.setValue(this.region);
  }

  updatePrices(){
    this.onUpdateParts.emit();
  }
  
  totalHovered(){
    this.onTotalHover.emit(true);
  }

}