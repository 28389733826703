import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import ContextQuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteDtoInterface';
import QuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { AddItemsOutputInterface } from '../KitOrPartPickerBotSheetComponent/KitOrPartPickerBotSheetComponent';
import ContextQuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteKitPartDtoInterface';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import QuoteDataEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteDataEditableInterface';
import QuoteKitPartEditableInterface, { QuoteKitPartEditableDataInterface } from '../../../_services/QuoteEditableService/interfaces/QuoteKitPartEditableInterface';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import ContextQuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuotePartInterface';
import QuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuotePartDtoInterface';
import PartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/PartDtoInterface';
import CustomQuoteKitToAddInterface from 'src/app/_services/sageApi/interfaces/pullReport/CustomQuoteKitToAddInterface';
import QuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteKitPartDtoInterface';
import ContextPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextPartDtoInterface';
import { PartBreakdownItemGroupInterface, PartBreakdownItemInterface, QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';

interface AuditPartsInterface {
  PartCode: string;
  Quantity: number;
  Cost: number;
  TotalCost: number;
  Phase: string;
  Kit: string;
  KitParents: string; // (string[]).join(", ")
  Desc: string;
  Tags: string; // (string[]).join(", ")
  Location: string;
}

@Component({
  selector: 'app-quoteslicer',
  templateUrl: './QuoteDataSlicerComponent.html',
  styleUrls: ['./QuoteDataSlicerComponent.css', '../QuotingTheme.scss']
})
export class QuoteDataSlicerComponent implements OnInit, OnChanges{
  
  @Input("partGroups") rawPartGroups: PartBreakdownItemGroupInterface[] = [];
  partGroups: PartBreakdownItemGroupInterface[] = this.rawPartGroups;
  
  allParts: AuditPartsInterface[] = [];

  constructor(
    private screenSizeService: ScreenSizeService,
    private router: Router,
    private api: SageApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private qe: QuoteEditableService
  ) { }

  ngOnInit(): void {
    this.allParts = this.getAuditParts(this.partGroups);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.partGroups = this.rawPartGroups;
  }
  
  openPdf(){
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let d = [
      [
        'Part_Code',
        'TotalQuantity',
        'TotalCost',
        'Part_Cost'
      ],
      ...this.partGroups.map(group => {
        return [
          group.name,
          group.quantity,
          group.totalCost.toFixed(4),
          group.partCost
        ]
      })
    ];

    this.qe.loadingUntilComplete(
      this.api.postBlob('json-to-pdf-table', d)).subscribe((response) => {
        var blob = new Blob([response], { type: 'application/pdf' });
        var file = window.URL.createObjectURL(blob);
        window.open(file);
      },
      e => { throw Error(e); }
    );
  }

  getAuditParts(breakdownGroups:  PartBreakdownItemGroupInterface[]){
    let flat: AuditPartsInterface[] = [];
    for(let group of breakdownGroups){
      for(let item of group.items){
        flat.push({
          PartCode: item.data.QuotePart.QuotePart.QuotePart_Code,
          Quantity: item.data.QuoteKitPart.QuoteKitPart_Quantity,
          Cost: item.data.QuotePart.QuotePart.QuotePart_Cost,
          TotalCost: item.data.QuoteKitPart.QuoteKitPart_Quantity * item.data.QuotePart.QuotePart.QuotePart_Cost,
          Phase: item.data.QuoteKitPart.QuoteKitPart_Phase,
          Kit: item.parents[item.parents.length - 1],
          KitParents: item.parents.join(", "),
          Desc: item.data.QuotePart.QuotePart.QuotePart_Desc,
          Tags: item.data.QuotePart.QuotePartTags.map(t => t.Tag.Tag_Name).join(", "),
          Location: item.location.BuildLocation_Code
        });
      }
    }
    return flat;
  }

  normalizeMoney(row: AuditPartsInterface, val: string): string{
    return `$${parseFloat(val).toFixed(2)}`;
  }

}