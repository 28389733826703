<div class="card m-3">
  <div class="card-header">
    Schedule Board
  </div>
  <div class="card-body">
    <div cdkDropListGroup>
      <div class="" *ngFor="let name of technicians; let techIndex = index">
      <div class="row">
        <div class="col-md-2"><label>{{name}}</label></div>
        <div class="col-md-10">
          
          <div class="row">
            <div class="col-md-6">              
              <label>Today</label>
                <div
                  cdkDropList
                  cdkDropListOrientation="vertical"
                  [cdkDropListData]="Schedule[techIndex].today"
                  class="example-list"
                  (cdkDropListDropped)="drop($event, Schedule[techIndex])">
                    <div class="example-box" *ngFor="let item of Schedule[techIndex].today" cdkDrag [cdkDragDisabled]="[item.disabled]">{{item.address}}</div>
                </div></div>
            <div class="col-md-6">              
              <label>Tomorrow</label>
              <div
                cdkDropList
                cdkDropListOrientation="vertical"
                [cdkDropListData]="Schedule[techIndex].tomorrow"
                class="example-list"
                (cdkDropListDropped)="drop($event, Schedule[techIndex])">
                <div class="example-box" *ngFor="let item of Schedule[techIndex].tomorrow" cdkDrag [cdkDragDisabled]="[item.disabled]">{{item.address}}</div>
              </div></div>
          </div>
        </div>
      </div>
      </div> <!-- End of Name List-->
      </div>
  </div>
</div>

<!-- <div cdkDropListGroup>
<mat-grid-list cols="{{daysToView}}" *ngFor="let name of technicians; let techIndex = index">
  <h2>{{name}}</h2>
  <mat-grid-tile *ngIf="daysToView > 0">
    <mat-grid-tile-header rowspan="1" style = " display: flex;">Today</mat-grid-tile-header>
      <div
        cdkDropList
        cdkDropListOrientation="horizontal"
        [cdkDropListData]="Schedule[techIndex].today"
        class="assignedList"
        (cdkDropListDropped)="drop($event)">
          <div class="assignedListItem" *ngFor="let item of Schedule[techIndex].today" cdkDrag>{{item}}</div>
      </div>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="daysToView > 1">
    <mat-grid-tile-header rowspan="1">Tomorrow</mat-grid-tile-header>
    <div
      cdkDropList
      cdkDropListOrientation="horizontal"
      [cdkDropListData]="Schedule[techIndex].tomorrow"
      class="example-list-horizontal"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box-horizontal" *ngFor="let item of Schedule[techIndex].tomorrow" cdkDrag>{{item}}</div>
    </div>
</mat-grid-tile>
<mat-grid-tile *ngIf="daysToView > 2">
  <mat-grid-tile-header rowspan="1">2 Days Out</mat-grid-tile-header>
  <div
    cdkDropList
    [cdkDropListData]="Schedule[techIndex].twoDaysOut"
    class="example-list"
    (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let item of Schedule[techIndex].twoDaysOut" cdkDrag>{{item}}</div>
  </div>
</mat-grid-tile>
<mat-grid-tile *ngIf="daysToView > 3">
  <mat-grid-tile-header rowspan="1">3 Days Out</mat-grid-tile-header>
  <div
    cdkDropList
    [cdkDropListData]="Schedule[techIndex].threeDaysOut"
    class="example-list"
    (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let item of Schedule[techIndex].threeDaysOut" cdkDrag>{{item}}</div>
  </div>
</mat-grid-tile>
<mat-grid-tile *ngIf="daysToView > 4">
  <mat-grid-tile-header rowspan="1">4 Days Out</mat-grid-tile-header>
  <div
    cdkDropList
    [cdkDropListData]="Schedule[techIndex].fourDaysOut"
    class="example-list"
    (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let item of Schedule[techIndex].fourDaysOut" cdkDrag>{{item}}</div>
  </div>
</mat-grid-tile>
</mat-grid-list>

</div> -->
<!--

<div cdkDropListGroup>
      <div class="body" *ngFor="let name of technicians; let techIndex = index">
        <div id="left" class="column">
          <label>{{name}}</label>
        </div>
        <div id="right" class="column">
          <div class="assignedList">
            <div class="assignedListItem" *ngIf="daysToView > 0">
              <label>Today</label>
                <div
                  cdkDropList
                  cdkDropListOrientation="vertical"
                  [cdkDropListData]="Schedule[techIndex].today"
                  class="example-list"
                  (cdkDropListDropped)="drop($event)">
                    <div class="example-box" *ngFor="let item of Schedule[techIndex].today" cdkDrag>{{item}}</div>
                </div>
              </div>
            <div class="assignedListItem" *ngIf="daysToView > 1">
              <label>Tomorrow</label>
              <div
                cdkDropList
                cdkDropListOrientation="vertical"
                [cdkDropListData]="Schedule[techIndex].tomorrow"
                class="example-list"
                (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let item of Schedule[techIndex].tomorrow" cdkDrag>{{item}}</div>
              </div>
          </div>
          <div class="assignedListItem" *ngIf="daysToView > 2">
            <label>2 Days Out</label>
            <div
              cdkDropList
              [cdkDropListData]="Schedule[techIndex].twoDaysOut"
              class="example-list"
              (cdkDropListDropped)="drop($event)">
              <div class="example-box" *ngFor="let item of Schedule[techIndex].twoDaysOut" cdkDrag>{{item}}</div>
            </div>
          </div>
          <div class="assignedListItem" *ngIf="daysToView > 3">
            <label>3 Days Out</label>
            <div
              cdkDropList
              [cdkDropListData]="Schedule[techIndex].threeDaysOut"
              class="example-list"
              (cdkDropListDropped)="drop($event)">
              <div class="example-box" *ngFor="let item of Schedule[techIndex].threeDaysOut" cdkDrag>{{item}}</div>
            </div>
          </div>
          <div class="assignedListItem" *ngIf="daysToView > 4">
            <label>4 Days Out</label>
            <div
              cdkDropList
              [cdkDropListData]="Schedule[techIndex].fourDaysOut"
              class="example-list"
              (cdkDropListDropped)="drop($event)">
              <div class="example-box" *ngFor="let item of Schedule[techIndex].fourDaysOut" cdkDrag>{{item}}</div>
            </div>
          </div>
          </div>
        </div>
      </div> 
    -->
