<div class="container-fluid">
  <div *ngIf="loadingStatus" class="card m-3">
    <div
      id="progress-bar"
      class="container well-sm"
      role="alert">
      <div class="pull-left">
        <label *ngIf="loadingStatus && !isError" for="progress-bar"><img
          *ngIf="isLoading && !isError"
          src="../../../assets/ring-alt-colored.svg"
          alt="a moving orange ring"
        />{{ loadingStatus }}</label>
        <label *ngIf="isError" for="progress-bar"><span style="color: darkred;">
        <img
          src="../../../assets/importantRED.png"
          alt="an exclamation with a red background"
          style="max-height: 20px;"
        />
        Error: {{ loadingStatus }}</span></label>
      </div>
    </div>
  </div>
  <div class="card border-1 m-3">
    <section class="content" style="margin-top: 20px;">
      <div class="container-fluid text-center">
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <div class="panel panel-default container-fluid vpo-container">
              <div class="card border-1 m-3">
                <div class="panel-body" style="padding: 10px;">
                  <div class="form-group">
                    <form
                      method="post"
                      accept-charset="UTF-8"
                      autocomplete="on"
                      name="vpo-form">
                      <div class="form-group" style="margin-bottom: 0px;">
                        <div class="custom-control custom-radio custom-control-inline radio-inline">
                          <input type="radio" id="ticketTypePunch" name="ticketType" class="custom-control-input"
                                 [(ngModel)]="ticketType" value="punch">
                          <label class="custom-control-label" for="ticketTypePunch"><b>Punch Ticket</b></label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline radio-inline">
                          <input type="radio" id="ticketTypeBackcharge" name="ticketType" class="custom-control-input"
                                 [(ngModel)]="ticketType" value="backcharge">
                          <label class="custom-control-label" for="ticketTypeBackcharge"><b>Backcharge from Builder</b></label>
                        </div>
                      </div>
                      <div class="form-group" style="margin-bottom: 0px;">
                        <label for="vpo-address mt-1"><b>Address</b></label>
                        <input
                          [(ngModel)]="address"
                          [typeahead]="loadedAddresses"
                          class="form-control requiredInputs"
                          name="vpo-address"
                          id="vpo-address"
                          typeaheadOptionField="name"
                          placeholder="Search by address or WT#"
                          autocomplete="off"
                          [disabled]="addressLoading"
                          (typeaheadOnSelect)="onAddressSelected($event)"
                        />
                      </div>
                      <div class="row mt-1">
                        <div class="col-md-12">
                          <div class="form-group" style="margin-bottom: 0px;" [ngStyle]="ticketType !== 'backcharge' ? { 'display': 'none' } : ''">
                            <label for="vpo-assigned-tech"><b>Assigned Leadman</b></label>
                            <input
                              [disabled]="isLoadingLead"
                              [(ngModel)]="searchLead"
                              [typeahead]="leadsLoad"
                              (typeaheadOnSelect)="onLeadSelected($event)"
                              class="form-control requiredInputs"
                              name="vpo-assigned-Lead"
                              id="vpo-assigned-Lead"
                              placeholder="Enter Leadman"
                              typeaheadOptionField="techTag"
                              autocomplete="off"
                            />
                          </div>
                          <div class="form-group" style="margin-bottom: 0px;" [ngStyle]="ticketType === 'backcharge' ? { 'display': 'none' } : ''">
                            <label for="vpo-assigned-tech"><b>Assigned Technician</b></label>
                            <app-searchable-sel
                              [selectedOption]="AssigenTechContrl"
                              placeholder="New Tech"
                              [disabled]="isLoading"
                              [options]="techsLoadSelect"
                              (onSelectChange)="onTechSelected($event)"
                            ></app-searchable-sel>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-1" [ngStyle]="ticketType === 'backcharge' ? { 'display': 'none' } : ''">
                        <div class="col-md-6">
                          <div class="form-group" style="margin-bottom: 0px;">
                            <label for="payAmount"><b>Pay (Labor) Amount</b></label>
                            <input type="number" [(ngModel)]="payAmount" name="payAmount"
                                   class="form-control requiredInputs" (click)="$event.target.select()"/>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group" style="margin-bottom: 0px;">
                            <label for="vpo-scheduled-date"><b>Schedule Date</b></label>
                            <input
                              (change)="onDateSelected($event.target.value)"
                              type="date"
                              class="form-control requiredInputs"
                              name="vpo-scheduled-date"
                              id="vpo-scheduled-date"
                              aria-describedby="vpo-scheduled-date"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mt-1" [ngStyle]="ticketType !== 'backcharge' ? { 'display': 'none' } : ''">
                        <div class="col-md-12">
                          <div class="form-group" style="margin-bottom: 0px;">
                            <label for="backchargeAmount"><b>Pending Backcharge Amount</b></label>
                            <input type="number" [(ngModel)]="backchargeAmount" name="backchargeAmount"
                                   class="form-control requiredInputs" (click)="$event.target.select()"/>
                          </div>
                        </div>
                      </div>

                      <div class="form-group mt-1" style="margin-bottom: 0px;">
                        <label for="vpo-work-desc"><b>Description of Work</b></label>
                        <textarea
                          [(ngModel)]="Description"
                          type="text"
                          maxlength="500"
                          class="form-control requiredInputs"
                          name="vpo-work-desc"
                          id="Description"
                          rows="3"
                          aria-placeholder="description of the work that needs to be completed"
                          placeholder="Describe the work">
                  </textarea>
                      </div>

                      <ng-container id="punchContainer" *ngIf="ticketType === 'punch'">

                        <div class="form-group mt-3">
                          <div class="btn-group">
                            <label for="radioCharges" class="mx-1"><b>Backcharge</b></label>
                            <div class="radio-inline mx-1">
                              <label>
                                <input
                                  type="radio"
                                  name="radioCharges"
                                  id="radioCharges1"
                                  value="CREW"
                                  (click)="cleanReasonCode()"
                                  [(ngModel)]="vpoWho"
                                />
                                Crew
                              </label>
                            </div>
                            <div class="radio-inline mx-1">
                              <label>
                                <input
                                  type="radio"
                                  name="radioCharges"
                                  id="radioCharges2"
                                  value="BUILDER"
                                  (click)="cleanReasonCode()"
                                  [(ngModel)]="vpoWho"
                                />
                                Builder
                              </label>
                            </div>
                            <div class="radio-inline mx-1">
                              <label>
                                <input
                                  type="radio"
                                  name="radioCharges"
                                  id="radioCharges3"
                                  value="GIBSON"
                                  [(ngModel)]="vpoWho"
                                />
                                Gibson
                              </label>
                            </div>
                          </div>
                        </div>
                        <!-- Punch Codes -->
                        <div class="form-group mt-1" *ngIf="vpoWho === 'GIBSON' ">
                          <label><b>Punch Codes</b></label>
                          <app-searchable-sel
                            [selectedOption]="AssigenPunchContrl"
                            placeholder="Punch Code"
                            [disabled]="isLoading"
                            [options]="punchLoadSelect"
                            (onSelectChange)="onPunchCodeSelected($event.value)"
                          ></app-searchable-sel>
                        </div>
                        <!-- Warranty Codes -->
<!--                        <div class="form-group mt-1" *ngIf="vpoWho === 'GIBSON' && AssigenPunchContrl.value.includes( 'Warranty')">-->
<!--                          <label><b>Warranty Codes</b></label>-->
<!--                          <app-searchable-sel-->
<!--                            [selectedOption]="AssigenWarrantyContrl"-->
<!--                            placeholder="Warranty Code"-->
<!--                            [disabled]="isLoading"-->
<!--                            [options]="punchWarLoadSelect"-->
<!--                            (onSelectChange)="onWarrantyCodeSelected($event.value)"-->
<!--                          ></app-searchable-sel>-->
<!--                        </div>-->
                        <div class="form-group">
                          <div class="form-horizontal">
                            <ng-container *ngIf="vpoWho === 'BUILDER'">
                              <label for="PONum"><b>Builder PO#</b></label>
                              <input
                                type="text" [(ngModel)]="PONum" name="PONum" id="PONum"
                                class="form-control requiredInputs" (click)="$event.target.select()"
                              />
                            </ng-container>
                            <label for="VpoNotes" class="label-control">
                              <b>Reason for Back Charge Selection</b>
                            </label>
                            <textarea
                              name="VpoNotes"
                              id="VpoNotes"
                              maxlength="500"
                              class="form-control marginB requiredInputs"
                              rows="2"
                              type="text"
                              [(ngModel)]="vpoNotes"></textarea>
                            <!--                            <div *ngIf="vpoWho === 'BUILDER'">-->
                            <div>
                              <ng-container *ngIf="vpoWho === 'BUILDER'">
                                <label for="builderContact"><b>Builder Contact Name</b></label>
                                <input
                                  type="text"
                                  class="form-control marginB requiredInputs"
                                  name="builderContact"
                                  id="builderContact"
                                  aria-describedby="builderContact"
                                  placeholder="Superintendent's Name"
                                  maxlength="50"
                                  [(ngModel)]="builderContactName"
                                />
                                <div class="my-2">
                                  <mat-checkbox [checked]="builderReplace" (change)="builderReplace = !builderReplace">
                                    <b>Replace Saved Builder Emails</b></mat-checkbox></div>
                                <label for="builderContactEmail1"><b>Builder Contact Email 1</b></label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="builderContactEmail1"
                                  id="builderContactEmail1"
                                  aria-describedby="builderContactEmail1"
                                  maxlength="80"
                                  (change)="onBuilderContactEmailSet(
                                            $event.target.value,
                                            builderEmail1.name
                                            )" [(ngModel)]="builderContactEmail1"
                                  #builderEmail1
                                />
                                <label for="builderContactEmail2"><b>Builder Contact Email 2</b></label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="builderContactEmail2"
                                  id="builderContactEmail2"
                                  aria-describedby="builderContactEmail2"
                                  maxlength="80"
                                  value="{{ builderContactEmail2 }}"
                                  (change)="onBuilderContactEmailSet(
                                                        $event.target.value,
                                                        builderEmail2.name
                                                        )" #builderEmail2
                                />
                                <label for="builderContactEmail3"><b>Builder Contact Email 3</b></label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="builderContactEmail3"
                                  id="builderContactEmail3"
                                  aria-describedby="builderContactEmail3"
                                  maxlength="80"
                                  value="{{ builderContactEmail3 }}"
                                  (change)="onBuilderContactEmailSet(
                                                        $event.target.value,
                                                        builderEmail3.name
                                                        )" #builderEmail3
                                />
                              </ng-container>
                              <label for="matPricing"><b>Gibson Labor Pricing</b></label>
                              <input
                                type="number"
                                class="form-control"
                                name="matPricing"
                                id="matPricing"
                                aria-describedby="matPricing"
                                value="{{ payAmount }}"
                                disabled
                              />
                              <label for="matPricing"><b>Estimated Material Pricing</b></label>
                              <ng-container *ngIf="ZeroPartsPrice">
                                <label for="matPricing">**Warning: 1 or more parts added to the Punch are missing a
                                  price.**</label>
                              </ng-container>
                              <input
                                type="number"
                                class="form-control"
                                name="matPricing"
                                id="matPricing"
                                aria-describedby="matPricing"
                                value="{{ TotalMatPricing() }}"
                                disabled
                              />
                              <label for="BillingAmount"><b>VPO Labor Pricing</b></label>
                              <input
                                type="number" [(ngModel)]="BillingAmount" name="BillingAmount" id="BillingAmount"
                                class="form-control requiredInputs" (click)="$event.target.select()"
                              />
                              <label for="VpoReqAmount"><b>Total VPO Request Amount</b></label>
                              <input
                                type="number"
                                class="form-control"
                                name="VpoReqAmount"
                                id="VpoReqAmount"
                                aria-describedby="VpoReqAmount"
                                value="{{VPORequestAmount()}}"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <div class="form-group" *ngIf="ticketType != 'backcharge'">
                        <label for="radioCompleted"><b>Work Completed?</b></label>
                        <div class="radio-inline">
                          <label>
                            <input
                              type="radio"
                              name="radioCompleted"
                              id="radioCompleted1"
                              [value]="true"
                              [(ngModel)]="workAlreadyComplete"
                            />
                            Yes
                          </label>
                        </div>
                        <div class="radio-inline">
                          <label>
                            <input
                              type="radio"
                              name="radioCompleted"
                              id="radioCompleted2"
                              [value]="false"
                              [(ngModel)]="workAlreadyComplete"
                            />
                            No
                          </label>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="col-xs-6">
                          <div class="row align-content-center">
                            <div class="col" *ngIf="ticketType != 'backcharge'">
                              <button
                                (click)="openModal(materialModal)"
                                type="button"
                                class="btn btn-primary m-1"
                                [disabled]="!addressSelected || isLoading || isImageLoading">
                                Add Material
                              </button>
                            </div>
                          </div>
                        </div>
                        <ng-container *ngIf="!(!addressSelected || isLoading || isImageLoading) && ticketType != 'backcharge'">
                          <div class="col-xs-5">
                            <div class="col-auto">
                              <div class="custom-file">
                                <input type="file" class="custom-file-input" id="requestAttachments"
                                       (change)="imagesChosen($event)">
                                <label class="custom-file-label text-left" for="requestAttachments">Choose file</label>
                              </div>
                              <div class="col" *ngFor="let file of fileArray">
                                <small> {{ file.name }}</small>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <div class="col-xs-5 my-1">
                          <button class="btn btn-primary" [disabled]="!addressSelected || isLoading || isImageLoading"
                                  (click)="imagesUpload()"
                                  *ngIf="fileArray.length > 0">
                            <span *ngIf="!fileLoading">Upload Images</span><span *ngIf="fileLoading"><i
                            class="fas fa-sync fa-spin"></i></span>
                          </button>
                          <div>
                            <small *ngIf="fileNames.length !== 0">{{
                              fileNames.join(" ")
                              }}</small>
                          </div>
                          {{ imageUploadComplete ? "Selected Image Uploaded" : "" }}
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="col-xs-6 align-self-center mx-1 mb-2">
                  <button
                    (click)="onResetDataClicked()"
                    type="button"
                    class="btn btn-danger pull-left"
                    [disabled]="isLoading">
                    Reset
                  </button>
                </div>
                <div class="col-xs-6 align-self-center mx-1 mb-2">
                  <button
                    #submitButton
                    type="button"
                    id="Commit"
                    class="btn btn-primary pull-right"
                    (click)="onTicketSubmit()"
                    [disabled]="isLoading"
                    [ngClass]="submitButtonDisabled ? 'disabled-button' : 'able-to-submit'">
                    <img
                      src="../../../assets/ring-alt-colored.svg"
                      *ngIf="isLoading"
                      alt="a ring moving in circles to show loading status"
                    />
                    Commit Changes
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!--          Comment History-->
          <div class="col-md-12 col-lg-6" *ngIf="ticketType == 'backcharge'">
            <div>
              <div><b>Backcharge History </b><button type="button"
                class="btn btn-primary" (click)=" showBackCharge == true ? showBackCharge = false :  showBackCharge= true">
                <span *ngIf="showBackCharge">▼</span>
                <span *ngIf="!showBackCharge">⯇</span></button></div>
                <div *ngIf="showBackCharge">
                <div class="pull-left">
                  <span  *ngIf="salesorder != '' && (this.userrole !== 'acct' && this.userrole !== 'acct_mgt')">
                    <b>{{ backchargeLoadingStatus }}</b>
                    </span>
                  <span *ngIf="salesorder != '' && (this.userrole == 'acct' || this.userrole == 'acct_mgt')">
                    <button type="button" class="btn btn-primary mt-1"
                    (click)="routeToWoms()">View In Woms</button>
                  </span>
                </div>
                <div
                  class="pull-right"
                  style="display: inline-block;">
                  <div class="vpo-legend-block"></div>
                </div>
                <table class="table table-striped table-condensed">
                  <thead>
                  <tr>
                    <th>WT#</th>
                    <th>WOMS Status</th>
                    <th>Desc</th>
                    <th>Status</th>
                    <th>Scheduled</th>
                    <th>DIP</th>
                    <th>Amount</th>
                    <th>WOMS Type</th>
                    <th>Tech</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    *ngFor="let step of backchargewts; let i= index"
                    [ngClass]="{ 'vpo-marker': step.womsType == 'BUILDER' }">
                    <td id="SO_SalesOrderDetail|JT158_WTNumber|">
                      {{ step.wtNum }}
                    </td>
                    <td>{{step.womsStatus}}</td>
                    <td id="SO_SalesOrderDetail|ItemCodeDescription|">
                      {{ step.trackingComment }}
                    </td>
                    <td id="JT_WorkTicket|Status|">
                      {{ step.status }}
                    </td>
                    <td>{{step.promiseDate | date: 'shortDate'}}</td>
                    <td>
                      {{step.DIP}}
                    </td>
                    <td>${{step.unitPrice}}</td>
                    <td>{{step.womsType}}</td>
                    <td title="{{step.techName}}-{{step.techCode}}">{{step.techName}}-{{step.techCode}}</td>

                  </tr>
                  </tbody>
                </table>
              </div>
              </div>
            <!-- <div class="panel panel-default container-fluid vpo-container">
              <div class="row vpo-container-titles-container justify-content-center">
                <label class="mx-1 mt-1"><b>Punch History</b></label>
                <button *ngIf="showCommentHistory" (click)="showCommentHistory= !showCommentHistory" type="button"
                        class="btn btn-primary">▼
                </button>
                <button *ngIf="!showCommentHistory" (click)="showCommentHistory= !showCommentHistory" type="button"
                        class="btn btn-primary">⯇
                </button>
              </div>
              <div *ngIf="showCommentHistory" class="panel-body">
                <div class="pull-left">
                  <b>{{ punchLoadingStatus }}</b>
                </div>
                <div
                  *ngIf="punchLoadingStatus !== ''"
                  class="pull-right"
                  style="display: inline-block;">
                  <div class="vpo-legend-block"></div>
                </div>
                <table class="table table-striped table-condensed">
                  <thead>
                  <tr>
                    <th>WT#</th>
                    <th>Type</th>
                    <th>Desc</th>
                    <th>Status</th>
                    <th>Scheduled</th>
                    <th>DIP</th>
                    <th>Tech</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    *ngFor="let step of wts; let i= index"
                    [ngClass]="{ 'vpo-marker': step.womsType == 'BUILDER' }">
                    <td id="SO_SalesOrderDetail|JT158_WTNumber|">
                      {{ step.wtNum }}
                    </td>
                    <td>{{step.womsType}}</td>
                    <td id="SO_SalesOrderDetail|ItemCodeDescription|">
                      {{ step.description }}
                    </td>
                    <td id="JT_WorkTicket|Status|">
                      {{ step.status }}
                    </td>
                    <td>{{step.promiseDate | date: 'shortDate'}}</td>
                    <td>
                      {{step.DIP}}
                    </td>
                    <td title="{{step.techName}}-{{step.techCode}}">{{step.techName}}-{{step.techCode}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div> -->
          </div>

          <!--* Previous Punch -->
          <div class="col-md-12 col-lg-6" *ngIf="ticketType == 'punch'">
            <div class="panel panel-default container-fluid vpo-container">
              <div class="row vpo-container-titles-container justify-content-center">
                <label class="mx-1 mt-1"><b>Punch History</b></label>
                <button *ngIf="showPunchHistory" (click)="showPunchHistory= !showPunchHistory" type="button"
                        class="btn btn-primary">▼
                </button>
                <button *ngIf="!showPunchHistory" (click)="showPunchHistory= !showPunchHistory" type="button"
                        class="btn btn-primary">⯇
                </button>
              </div>
              <div *ngIf="showPunchHistory" class="panel-body">
                <div class="pull-left">
                  <b>{{ punchLoadingStatus }}</b>
                </div>
                <div
                  *ngIf="punchLoadingStatus !== ''"
                  class="pull-right"
                  style="display: inline-block;">
                  <div class="vpo-legend-block"></div>
                </div>
                <table class="table table-striped table-condensed">
                  <thead>
                  <tr>
                    <th>WT#</th>
                    <th
                      [ngStyle]="tableLoaded
                    ? { 'padding-left': '5%' }
                    : { 'padding-left': '5%' }">
                      Desc
                    </th>
                    <th>Status</th>
                    <th>Scheduled</th>
                    <th>Tech</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    *ngFor="let step of wts; let i= index"
                    [ngClass]="{ 'vpo-marker': step.womsType == 'BUILDER' }">
                    <td id="SO_SalesOrderDetail|JT158_WTNumber|">
                      {{ step.wtNum }}
                    </td>
                    <td id="SO_SalesOrderDetail|ItemCodeDescription|">
                      {{ step.description }}
                    </td>
                    <td id="JT_WorkTicket|Status|">
                      {{ step.status }}
                    </td>
                    <td>{{step.promiseDate | date: 'shortDate'}}</td>
                    <td title="{{step.techName}}-{{step.techCode}}">{{step.techName}}-{{step.techCode}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div *ngIf="address !== ''" class="col-md-12">
            <div class="panel panel-default container-fluid vpo-container">
              <div class="row justify-content-center ml-2 my-2"
                   *ngIf="qaItemsForPunch.length < 1 && this.address !== ''"><b>No Open QA Items for This Address</b>
              </div>
              <div *ngIf="qaItemsForPunch.length > 0 && address !== ''"
                   class="row vpo-container-titles-container justify-content-center ml-2">
                <p class="mt-4"><b>Current QA Items</b></p>
                <button *ngIf="showCurrentQA" (click)="showCurrentQA= !showCurrentQA" type="button"
                        class="btn btn-primary ml-1 mt-2">▼
                </button>
                <button *ngIf="!showCurrentQA" (click)="showCurrentQA= !showCurrentQA" type="button"
                        class="btn btn-primary ml-1 mt-2">⯇
                </button>
              </div>
              <div class="table-responsive" *ngIf="showCurrentQA">
                <table *ngIf="qaItemsForPunch.length > 0 && address !== ''" class="table table-striped table-condensed">
                  <thead>
                  <tr>
                    <th>WT#</th>
                    <th>Code</th>
                    <th>Desc</th>
                    <th>Status</th>
                    <th>Status Date</th>
                    <th>Scout Comment</th>
                    <th>Tracking Comment</th>
                    <th>Tech</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let qaItem of qaItemsForPunch;">
                    <td>{{qaItem.WTNumber}}</td>
                    <td>{{qaItem.QACode}}</td>
                    <td>{{qaItem.ItemCodeDesc}}</td>
                    <td>{{qaItem.StatusCode}}</td>
                    <td>{{qaItem.StatusDate | date : 'shortDate'}}</td>
                    <td>{{qaItem.UDF_SCOUT_LM_DESCRIPTION}}</td>
                    <td>{{qaItem.UDF_TRACKING_COMMENT}}</td>
                    <td>{{qaItem.UDF_TECHNICIAN_CODE}} <br> {{qaItem.techName}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
        <!-- End of row -->
      </div>
      <footer>
        <div></div>
      </footer>
    </section>
  </div>
</div>


<!-- *Image Modal -->
<ng-template #imgModal>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Images for {{ address }}</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="{{ modalImgUrl }}" alt="" width="100%"/>
      </div>
    </div>
  </div>
</ng-template>

<!-- *Material Items Modal -->
<ng-template #materialModal>
  <div class="row justify-content-center mx-1 mt-3">
    <div class="col-10 justify-content-center"><label><h4><b>Add Material for {{ address }}</b></h4></label></div>
    <div class="col-2 justify-content-center">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="closeModal()">
        <span aria-hidden="true"><b>&times;</b></span>
      </button>
    </div>
  </div>
  <div class="row justify-content-center m-1">
    <div class="col-sm-2 justify-content-center">
      <div class="form-group">
        <label><b>Qty</b></label>
        <input
          type="number"
          class="form-control"
          [(ngModel)]="materialNeededQty"
          (click)="$event.target.select()"
        />
      </div>
    </div>
    <div class="col-sm-10">
      <div class="form-group">
        <label for="itemCodesInput"><b>Item Codes</b></label>
        <input
          list="itemCodesList"
          type="text"
          id="itemCodesInput"
          name="itemCodes"
          class="form-control"
          placesholder="Search For Items"
          (keyup)="onSearchItems()"
          [(ngModel)]="itemCodeSearchText"
        />
        <ul class="list-group" style="height: 35rem; overflow-y:auto; overflow-x: hidden;">
          <h5 style="margin-bottom: 0;" class="mt-1"><b>Address Items</b></h5>
          <hr style="margin-top: 0;">
          <ng-container *ngFor="let item of punchSpecMaterialItems">
            <div *ngIf="item.InactiveItem == 'N'">
                       <li class="list-group-item"
              *ngIf="item.itemCode.toLowerCase().includes(itemCodeSearchText.toLowerCase()) || (item.itemDesc.toLowerCase()).includes(itemCodeSearchText.toLowerCase())">
              <div class="container">
                <div class="row">
                  <div class="col-10">
                    <strong>{{ item.phase }}</strong> - {{ item.itemCode }} - {{ item.itemDesc }}
                    <br> <strong>{{ item.location }}</strong> Needed: {{ item.qtyNeeded }} Used: {{ item.qtyUsed }}
                    Price (per QTY): ${{item.UnitCost | number:'1.2-2'}}
                  </div>
                  <div class="col-2">
                    <span class="add"><button class="btn btn-sm btn-success"
                      (click)="onItemCodeSelected(item.itemCode, item.itemDesc, item.UnitCost)">+</button></span>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            </ng-container>
          <h5 style="margin-bottom: 0;" class="mt-1"><b>Searched Items</b></h5>
          <hr style="margin-top: 0;">
          <ng-container
            *ngFor="let item of searchHitsItems; let i= index">
            <li class="list-group-item">
              <b>{{ item.itemCode }}</b> - {{ item.description }}
              <span class="add">
                              <button
                                class="btn btn-sm btn-primary"
                                (click)="onItemCodeSelected(
                                item.itemCode,
                                item.description
                                )">
                                +
                              </button>
                            </span>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div
      class="row justify-content-center"
      name="selectedCodes"
      *ngFor="let item of materialNeededItems;
                    let itemIndex= index">
      <div class="col-sm-2">
        <input
          type="text"
          class="form-control"
          value="{{ item.itemQty }}"
          disabled
        />
      </div>
      <div class="col-sm-2">
        <input
          type="text"
          class="form-control"
          value="${{ item.UnitCost | number:'1.2-2'}}"
          disabled
        />
      </div>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          value="{{ item.itemCode }} - {{ item.desc }}"
          disabled
        />
      </div>
      <div class="col-sm-1">
        <button
          class="btn btn-danger"
          (click)="removeMaterialItemCode(itemIndex)">
          X
        </button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      (click)="closeModal()"
      type="button"
      class="btn btn-primary"
      [disabled]="isLoading">
      Done
    </button>
  </div>
</ng-template>

