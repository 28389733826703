import { Component, OnInit, TemplateRef } from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { WasabiApiService } from '../../../_services/wasabiApi/wasabiApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { DatePipe } from '@angular/common';
import { filter } from 'rxjs/operators';
import { parseTwoDigitYear } from 'ngx-bootstrap/chronos/units/year';
import { FormControl } from '@angular/forms';
import PhasePayInterface from '../interfaces/PhasePay';

@Component({
  selector: 'app-phase-pay',
  templateUrl: './phase-pay.component.html',
  styleUrls: ['./phase-pay.component.css'],
})
export class PhasePayComponent implements OnInit {
  loadingData: boolean;



  phaseOptions: string[] = [];
  phaseCodes: FormControl = new FormControl([]);

  statusOptions: string[] = ['', 'approved', 'hold'];
  statusCodes: FormControl = new FormControl(['', 'approved', 'hold'])
  // * Show-Hide Variables * //
  showUnapprovedTable = true;
  showApprovedTable = false;
  showHoldTable = false;
  showHistoryTable = false;
  showWater = true;
  showSewer = true;
  showRoughin = true;
  updatingData = false;
  showTicketLines = false;

  table: string;

  // * Column Sort Variables * //
  wtSortDirection = 'desc';
  addressSortDirection = 'desc';
  subdivisionSortDirection = 'desc';
  scheduleDateSortDirection = 'desc';
  statusSortDirection = 'desc';
  technicianSortDirection = 'desc';
  phasePayStatusDateSortDirection = 'desc';
  epdSortDirection = 'desc';

  // * Modal Variables * //
  editPhaseTicketModal: BsModalRef;
  phaseTicketHoldModal: BsModalRef;
  addChippingLineModal: BsModalRef;
  addIncentiveLineModal: BsModalRef;
  imageModal: BsModalRef;
  editIndex = '';
  throwDateError = false;

  // * Data Models * //
  supersList:string[] = [];
  super: FormControl = new FormControl([]);
  defaultSuper: FormControl = new FormControl([]);


  selectedSuper = '';
  superintendentsList = [];



  phaseTickets: PhasePayInterface[] = [];
  phaseTicketLines = [];
  phaseTicketHoldLines = [];
  ticketToEdit = {};

  addRoughInAddersModal: BsModalRef;
  roughInAddersSelected: RoughInAdder[] = [];
  selectedRoughInAdder: RoughInAdderItem;
  selectedRoughInAdderQty: number;
  selectedRoughInAdderPrice: number;
  selectedRoughInAdderDesc: string;
  roughInAddersList: RoughInAdderItem[] = [];


  // * Date Variables * //
  datePipe = new DatePipe('en-US');
  today = new Date();
  date = this.today.getDate();
  todayDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
  day = this.today.getDay();
  fridayDate: string;
  fridayDisplayDate;

  // * Image Variables *//
  houseImages: Array<string> = [];
  imageUploadComplete: boolean;
  isImageLoading: boolean;
  imageArr: any = [];
  fileNames: any = [];
  imageUrlList: any;
  // imageModal: BsModalRef;
  imageAddressForModal = '';
  selectedImageUrlForModal = '';

  // * All Other Variables * //
  user = this.authService.decodedToken;
  username = this.authService.decodedToken.nameid;
  allowEditOnRestrictedTickets = false;
  allowAddIncentiveLine = false;
  timestamp = `${this.username} - ${this.todayDate}`;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    public authService: AuthService,
    private modalService: BsModalService,
    private wasabiApi: WasabiApiService
  ) {}

  ngOnInit() {
    this.getRoughInAdders();
    // ! Set the date for Pay Dates //
    if (this.day === 0) {
      this.fridayDate = this.datePipe.transform(
        this.today.setDate(this.date + 5),
        'yyyy-MM-dd'
      );
      this.fridayDisplayDate = this.datePipe.transform(
        this.today.setDate(this.date + 5),
        'yyyyMMdd'
      );
    } else if (this.day === 1) {
      this.fridayDate = this.datePipe.transform(
        this.today.setDate(this.date + 4),
        'yyyy-MM-dd'
      );
      this.fridayDisplayDate = this.datePipe.transform(
        this.today.setDate(this.date + 4),
        'yyyyMMdd'
      );
    } else if (this.day === 2) {
      this.fridayDate = this.datePipe.transform(
        this.today.setDate(this.date + 10),
        'yyyy-MM-dd'
      );
      this.fridayDisplayDate = this.datePipe.transform(
        this.today.setDate(this.date + 10),
        'yyyy-MM-dd'
      );
    } else if (this.day === 3) {
      this.fridayDate = this.datePipe.transform(
        this.today.setDate(this.date + 9),
        'yyyy-MM-dd'
      );
      this.fridayDisplayDate = this.datePipe.transform(
        this.today.setDate(this.date + 9),
        'yyyyMMdd'
      );
    } else if (this.day === 4) {
      this.fridayDate = this.datePipe.transform(
        this.today.setDate(this.date + 8),
        'yyyy-MM-dd'
      );
      this.fridayDisplayDate = this.datePipe.transform(
        this.today.setDate(this.date + 8),
        'yyyyMMdd'
      );
    } else if (this.day === 5) {
      this.fridayDate = this.datePipe.transform(
        this.today.setDate(this.date + 7),
        'yyyy-MM-dd'
      );
      this.fridayDisplayDate = this.datePipe.transform(
        this.today.setDate(this.date + 7),
        'yyyyMMdd'
      );
    } else if (this.day === 6) {
      this.fridayDate = this.datePipe.transform(
        this.today.setDate(this.date + 6),
        'yyyy-MM-dd'
      );
      this.fridayDisplayDate = this.datePipe.transform(
        this.today.setDate(this.date + 6),
        'yyyyMMdd'
      );
    }

    this.selectedSuper = this.authService.decodedToken.Super;
    this.getSuperintendents();
    this.getPhaseTickets(this.selectedSuper);
    // ! Allows Admins/Area Managers to edit tickets on restricted statuses (PTL,QAR,REC,QAN) //
    // tslint:disable-next-line: max-line-length
    if (
      this.authService.decodedToken.role === 'superadmin' ||
      this.authService.decodedToken.role === 'admin' ||
      this.authService.decodedToken.role === 'acct_mgt'
    ) {
      this.allowEditOnRestrictedTickets = true;
      this.allowAddIncentiveLine = true;
    }
    this.table='unapproved'
    this.selectTableToShow('unapproved');
  }

  // *Beginning of UI Functions //
  selectTableToShow(table: string) {
    if (table === 'unapproved') {
      this.showUnapprovedTable = true;
      this.showApprovedTable = false;
      this.showHoldTable = false;
      this.showHistoryTable = false;
    } else if (table === 'approved') {
      this.showApprovedTable = true;
      this.showHoldTable = false;
      this.showUnapprovedTable = false;
      this.showHistoryTable = false;
    } else if (table === 'hold') {
      this.showHoldTable = true;
      this.showApprovedTable = false;
      this.showUnapprovedTable = false;
      this.showHistoryTable = false;
    } else if (table === 'chipping') {
      this.showHoldTable = false;
      this.showApprovedTable = false;
      this.showUnapprovedTable = false;
      this.showHistoryTable = true;
    }
  }



  onPayDateCheck(event: InputEvent, index: string) {
    const dateToCheck = new Date(
      (event.target as HTMLInputElement).value
    ).getDay();
    const payDateInputs: any =
      document.getElementsByClassName('pay-date-inputs');
    if (dateToCheck === 4) {
      this.throwDateError = false;
      payDateInputs[index].style.cssText += 'background-color:#fff;color:#444';
    } else if (dateToCheck !== 4) {
      this.throwDateError = true;
      payDateInputs[index].style.cssText += 'background-color:red;color:white;';
    }
    console.log(this.throwDateError);
  }

  onSuperSelected(index: string) {
    console.log(index)

    this.selectedSuper = this.superintendentsList[index].superName;
    this.phaseTickets = [];
    this.getPhaseTickets(this.selectedSuper);
  }

  onSuperNameSelected() {
    console.log(this.selectedSuper)
    this.phaseTickets = [];
    this.getPhaseTickets(this.selectedSuper);
  }

  onEditPhaseTicket(template: TemplateRef<any>, index) {
    this.phaseTicketLines = [];
    this.showTicketLines = false;
    const config: ModalOptions = { class: 'modal-xl', ignoreBackdropClick: true};
    this.editPhaseTicketModal = this.modalService.show(template, config);

    var item = this.phaseTickets.indexOf(index);

    this.editIndex = item.toString();

    this.phaseTicketLines = this.phaseTickets.filter(
      (ticket) => ticket.soNum === this.phaseTickets[item].soNum
    );
  }

  onEditHoldTicket(template: TemplateRef<any>, index, editTicket) {
    console.log(editTicket);
    this.phaseTicketLines = [];
    const config: ModalOptions = { class: 'modal-lg', ignoreBackdropClick: true };
    this.phaseTicketHoldModal = this.modalService.show(template, config);
    this.editIndex = index;

    this.phaseTicketLines = this.phaseTicketHoldLines.filter(
      (ticket) => ticket.soNum === editTicket.soNum
    );
  }

  onAddChippingLines(template: TemplateRef<any>, index) {
    this.houseImages = [];
    const options: ModalOptions = { class: 'modal-lg' };
    this.addChippingLineModal = this.modalService.show(template, options);
    var item = this.phaseTickets.indexOf(index);

    this.editIndex = item.toString();

    this.ticketToEdit = this.phaseTickets[item];
  }

  onAddIncentiveLine(template: TemplateRef<any>, index) {
    this.addIncentiveLineModal = this.modalService.show(template);
    var item = this.phaseTickets.indexOf(index);

    this.editIndex = item.toString();
    this.phaseTicketLines = this.phaseTickets.filter(
      (ticket) =>
        ticket.soNum === this.phaseTickets[item].soNum &&
        this.phaseTickets[item].phase.toUpperCase() === '/INCENTIVE'
    );
  }

  onAddRoughInAdders(template: TemplateRef<any>, index) {
    this.roughInAddersSelected = [];
    this.selectedRoughInAdderQty = 1;
    this.selectedRoughInAdderPrice = 0;
    this.selectedRoughInAdderDesc = '';

    const config: ModalOptions = {
      backdrop: true,
      class: 'adder-modal mt-0 pt-5 modal-xl'
    };

    this.addRoughInAddersModal = this.modalService.show(template, config);
    this.editIndex = index;
    console.log(template, index);
  }

  addRoughInAdder(item: RoughInAdderItem, qty: number, price?: number, desc?: string) {
    if (qty >= 1 && item) {

      const obj: RoughInAdder = {
        itemCode: item.itemCode,
        itemCodeDesc: desc ? desc : item.description,
        qty
      };

      if (price) {
        obj.price = price;
      }

      this.roughInAddersSelected.unshift(obj);
    }
  }

  deleteRoughInAdder(index) {
    index = Number(index);
    this.roughInAddersSelected.splice(index, 1);
  }

  checkAdderInTicket(item: RoughInAdder) {
    return this.phaseTicketLines.some(x => x.phase.toLowerCase() === item.itemCode.toLowerCase());
  }

  otherAdderDisabled() {
    if (this.selectedRoughInAdderPrice && this.selectedRoughInAdderDesc) {
      if (this.selectedRoughInAdderPrice > 0 && this.selectedRoughInAdderDesc.length > 3) {
        return false;
      }
    }
    return true;
  }

  getRoughInAdders() {
    this.sageApi.pullReport('PhasePay/Adder').subscribe((adders: RoughInAdderItemApi[]) => {
      adders.forEach((adder) => {
        const obj: RoughInAdderItem = {
          itemCode: adder.ItemCode,
          description: adder.ItemCodeDesc,
          price: adder.Price,
          prtPrice: adder.PrtPrice
        };
        this.roughInAddersList.push(obj);
      });
    });
  }

  submitRoughInAdders(phaseTicketIndex) {
    console.log(this.phaseTickets[phaseTicketIndex], this.roughInAddersSelected, this.phaseTicketLines);
    // api call
    const ticket = this.phaseTickets[phaseTicketIndex];
    this.roughInAddersSelected.forEach(adder => {
      const obj = {
        subVendor: ticket.subVendor,
        subVendorCode: ticket.subVendorCode,
        techName: ticket.techName,
        phase: adder.itemCode,
        itemCodeDesc: adder.itemCodeDesc,
        wtNumber: ticket.wtNumber,
        wtStep: '020',
        wtParent: 'N',
        lineKey: '',
        promiseDate: ticket.promiseDate,
        salesOrderNo: ticket.salesOrderNo,
        soNum: ticket.soNum,
        extendedCost: 0,
        address: ticket.address,
        status: ticket.status,
        statusDate: ticket.statusDate,
        statusTime: ticket.statusTime,
        isTechPRT: ticket.isTechPRT,
        prtCrewLead: ticket.prtCrewLead,
        prtCrewLeadSplit: ticket.prtCrewLeadSplit,
        prtCrew1: ticket.prtCrew1,
        prtCrew1Split: ticket.prtCrew1Split,
        prtCrew2: ticket.prtCrew2,
        prtCrew2Split: ticket.prtCrew2Split,
        builderAudit: ticket.builderAudit,
        builderAuditDate: ticket.builderAuditDate,
        builderAuditReason: ticket.builderAuditReason,
        builderAuditTime: ticket.builderAuditTime,
        builderAuditUser: ticket.builderAuditUser,
        dateScheduled: ticket.dateScheduled,
        superintendent: ticket.superintendent,
        reviewed: ticket.reviewed,
        subdivision: ticket.subdivision,
        techCode: ticket.techCode,
        phasePayStatus: 'active',
        phasePayComment: '',
        phasePayStatusDate: '',
        qtyOrdered: adder.qty,
        qtyShipped: 0,
        estimatedPayDate: 17530101,
        fileReviewedBy: ticket.fileReviewedBy,
        unitCost: 0,
        totalCost: 0,
        UDF_PAY_DATE: this.datePipe.transform(this.fridayDate, 'yyyy-MM-dd'),
        completePipes: ticket.completePipes,
        completePipesUpdated: false,
        partialPipes: ticket.partialPipes,
        partialPipesUpdated: false,
        damagedPipes: ticket.damagedPipes,
        damagedPipesUpdated: false,
        pipesOutExtended: ticket.pipesOutExtended,
        pipesOutExtendedUpdated: false,
        tubBox: ticket.tubBox,
        tubBoxUpdated: false,
        updated: false,
        chippingComment: ticket.chippingComment,
        chippingSubmitted: ticket.chippingSubmitted,
        incentive: { amount: 0, reason: '' },
        updatedStatus: false,
        location: 'NONE',
        lastUser: ticket.lastUser
      };

      if (!this.otherAdderDisabled()) {
        obj.itemCodeDesc = this.selectedRoughInAdderDesc;
        obj.unitCost = this.selectedRoughInAdderPrice;
      }

      this.phaseTicketLines.push(obj);
      this.selectedRoughInAdder = null;
      this.selectedRoughInAdderQty = 1;
      this.selectedRoughInAdderPrice = 0;
      this.selectedRoughInAdderDesc = '';
    });

    console.log(this.phaseTicketLines);
    this.addRoughInAddersModal.hide();
  }

  onClosePhaseTicketModal(template: string) {
    if (template === 'editPhaseTicketModal') {
      this.editPhaseTicketModal.hide();
      this.showTicketLines = false;
    } else if (template === 'phaseTicketHoldModal') {
      this.phaseTicketHoldModal.hide();
    } else if (template === 'addChippingLineModal') {
      this.addChippingLineModal.hide();
    } else if (template === 'addIncentiveLineModal') {
      this.addIncentiveLineModal.hide();
    } else if (template === 'addRoughInAddersModal') {
      this.addRoughInAddersModal.hide();
    }
  }

  onColumnSort(column: string, order: string) {
    this.phaseTickets.sort(compareValues(column, order));

    // tslint:disable-next-line:no-shadowed-variable
    function compareValues(key, order) {
      return (a, b) => {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }

        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order === 'desc' ? comparison * -1 : comparison;
      };
    }

    switch (column) {
      case 'soNum':
        if (this.wtSortDirection === 'asc' || this.wtSortDirection === '') {
          this.wtSortDirection = 'desc';
        } else {
          this.wtSortDirection = 'asc';
        }
        break;
      case 'address':
        if (
          this.addressSortDirection === 'asc' ||
          this.addressSortDirection === ''
        ) {
          this.addressSortDirection = 'desc';
        } else {
          this.addressSortDirection = 'asc';
        }
        break;
      case 'subdivision':
        if (
          this.subdivisionSortDirection === 'asc' ||
          this.subdivisionSortDirection === ''
        ) {
          this.subdivisionSortDirection = 'desc';
        } else {
          this.subdivisionSortDirection = 'asc';
        }
        break;
      case 'dateScheduled':
        if (
          this.scheduleDateSortDirection === 'asc' ||
          this.scheduleDateSortDirection === ''
        ) {
          this.scheduleDateSortDirection = 'desc';
        } else {
          this.scheduleDateSortDirection = 'asc';
        }
        break;
      case 'status':
        if (
          this.statusSortDirection === 'asc' ||
          this.statusSortDirection === ''
        ) {
          this.statusSortDirection = 'desc';
        } else {
          this.statusSortDirection = 'asc';
        }
        break;
      case 'techName':
        if (
          this.technicianSortDirection === 'asc' ||
          this.technicianSortDirection === ''
        ) {
          this.technicianSortDirection = 'desc';
        } else {
          this.technicianSortDirection = 'asc';
        }
        break;
      case 'phasePayStatusDate':
        if (
          this.phasePayStatusDateSortDirection === 'asc' ||
          this.phasePayStatusDateSortDirection === ''
        ) {
          this.phasePayStatusDateSortDirection = 'desc';
        } else {
          this.phasePayStatusDateSortDirection = 'asc';
        }
        break;
      case 'estimatedPayDate':
        if (this.epdSortDirection === 'asc' || this.epdSortDirection === '') {
          this.epdSortDirection = 'desc';
        } else {
          this.epdSortDirection = 'asc';
        }
        break;
    }
  }

  // *End of UI Functions //

  // *Beginning of Data Functions //
  getPhaseTickets(superintendent: string) {
    this.loadingData = true;
    this.phaseTickets = [];
    this.phaseTicketHoldLines = [];
    this.sageApi
      .pullReport(`PhasePaySalesOrder?superintendent=${superintendent}`)
      .subscribe((phases: Array<any>) => {
        phases.forEach((ticket) => {
          // * Formats the default pay date on parent lines for UI * //
          let modifiedPayDate;
          if (
            ticket.WTParent === 'Y' &&
            (ticket.UDF_PAY_DATE === null ||
              ticket.UDF_PAY_DATE === '1753-01-01T00:00:00' ||
              ticket.UDF_PAY_DATE === '' ||
              ticket.UDF_PAY_DATE === '1900-01-01T00:00:00')
          ) {
            modifiedPayDate = this.datePipe.transform('17530101', 'yyyy-MM-dd');
          }

          // * For UNAPPROVED lines, format the date so the UI shows correct date - without this format the UI shows incorrect date * //
          if (
            (ticket.UDF_PAY_DATE === '1753-01-01T00:00:00' ||
              ticket.UDF_PAY_DATE === '1900-01-01T00:00:00') &&
            ticket.WTParent === 'N' &&
            ticket.UDF_PHASE_PAY_STATUS !== 'approved'
          ) {
            ticket.UDF_PAY_DATE = this.datePipe.transform(
              this.fridayDate,
              'yyyy-MM-dd'
            );
          }

          // * Defaults to the next Friday pay date for lines if no pay date or default pay date is set * //
          if (
            ticket.WTParent === 'N' &&
            ticket.UDF_PHASE_PAY_STATUS !== 'approved' &&
            (ticket.UDF_PAY_DATE === null || ticket.UDF_PAY_DATE === '')
          ) {
            modifiedPayDate = this.datePipe.transform(
              this.fridayDate,
              'yyyy-MM-dd'
            );
          } else {
            modifiedPayDate = this.datePipe.transform(
              ticket.UDF_PAY_DATE,
              'yyyy-MM-dd'
            );
          }
          var approvedDate = '';
          if(ticket.UDF_PHASE_PAY_COMMENT == '' && ticket.JT158_WTNumber == '010' && ticket.UDF_PHASE_PAY_STATUS == 'approved' ||
          (ticket.UDF_PHASE_PAY_COMMENT.includes("Approved via SAGE") && !ticket.UDF_PHASE_PAY_COMMENT.includes("-"))){
            var date = ticket.UDF_PHASE_PAY_STATUS_DATE.split('-');
            if(date.length == 3){
              approvedDate = date[0]+"-"+date[2]+"-"+date[1];
            }
          }
          else{
            approvedDate = ticket.UDF_PHASE_PAY_STATUS_DATE;
          }

          const phaseObj:PhasePayInterface = {
            subVendor: ticket.ASSIGNED_SUBVENDOR,
            subVendorCode: ticket.UDF_SUB_VENDOR,
            techName: ticket.ASSIGNED_TECH,
            phase: ticket.ItemCode,
            itemCodeDesc: ticket.ItemCodeDesc,
            wtNumber: ticket.JT158_WTNumber,
            wtStep: ticket.JT158_WTStep,
            wtParent: ticket.WTParent,
            lineKey: ticket.LineKey,
            promiseDate: ticket.PromiseDate,
            salesOrderNo: ticket.SalesOrderNo,
            soNum: `${ticket.SalesOrderNo}-${ticket.JT158_WTNumber}`,
            extendedCost: ticket.JT158_WTExtendedCost,
            address: ticket.ShipToName,
            status: ticket.StatusCode,
            statusDate: ticket.StatusDate,
            statusTime: ticket.StatusTime,
            isTechPRT: ticket.TECH_PRT,
            prtCrewLead: ticket.UDF_PRT_CREW_LEAD,
            prtCrewLeadSplit: ticket.UDF_PRT_CREW_LEAD_SPLIT,
            prtCrew1: ticket.UDF_PRT_CREW_EMP1,
            prtCrew1Split: ticket.UDF_PRT_CREW_EMP1_SPLIT,
            prtCrew2: ticket.UDF_PRT_CREW_EMP2,
            prtCrew2Split: ticket.UDF_PRT_CREW_EMP2_SPLIT,
            builderAudit: ticket.UDF_BUILDER_AUDIT,
            builderAuditDate: ticket.UDF_BUILDER_AUDIT_DATE,
            builderAuditReason: ticket.UDF_BUILDER_AUDIT_REASON,
            builderAuditTime: ticket.UDF_BUILDER_AUDIT_TIME,
            builderAuditUser: ticket.UDF_BUILDER_AUDIT_USER,
            dateScheduled: ticket.UDF_DATE_SCHEDULED,
            superintendent: ticket.UDF_LEADMAN,
            reviewed: ticket.UDF_REVIEWED,
            subdivision: ticket.UDF_SUBDIVISION,
            techCode: ticket.UDF_TECHNICIAN_CODE,
            phasePayStatus:
              ticket.UDF_PHASE_PAY_STATUS === null ||
              ticket.UDF_PHASE_PAY_STATUS === 'NULL' ||
              ticket.UDF_PHASE_PAY_STATUS === ''
                ? 'active'
                : ticket.UDF_PHASE_PAY_STATUS,
            phasePayComment: ticket.UDF_PHASE_PAY_COMMENT || '',
            phasePayStatusDate: approvedDate,
            qtyOrdered: ticket.QuantityOrdered,
            qtyShipped: ticket.QuantityShipped,
            estimatedPayDate: 17530101,
            fileReviewedBy: ticket.UDF_FILE_REVIEWED_BY,
            unitCost: ticket.UnitCost,
            totalCost: ticket.TotalCost,
            UDF_PAY_DATE: ticket.UDF_PAY_DATE || modifiedPayDate,
            completePipes: ticket.UDF_SCOUT_LM_PIPES_OUT,
            completePipesUpdated: false,
            partialPipes: ticket.UDF_SCOUT_LM_PIPES_SHADING,
            partialPipesUpdated: false,
            damagedPipes: ticket.UDF_SCOUT_LM_DMG_PIPES,
            damagedPipesUpdated: false,
            pipesOutExtended: ticket.UDF_SCOUT_LM_PIPES_OUT_EXT,
            pipesOutExtendedUpdated: false,
            tubBox: ticket.UDF_SCOUT_LM_TUB_BOX,
            tubBoxUpdated: false,
            updated: false,
            chippingComment: ticket.UDF_SCOUT_LM_DESCRIPTION,
            chippingSubmitted:
              ticket.UDF_SCOUT_LM_PIPES_SHADING > 0 ||
              ticket.UDF_SCOUT_LM_PIPES_OUT > 0 ||
              ticket.UDF_SCOUT_LM_DMG_PIPES > 0 ||
              ticket.UDF_SCOUT_LM_PIPES_OUT_EXT > 0 ||
              ticket.UDF_SCOUT_LM_TUB_BOX > 0
                ? true
                : false,
            incentive: { amount: 0, reason: '' },
            updatedStatus: false,
            location: ticket.UDF_LOCATION,
            lastUser: ticket.UDF_PHASE_PAY_LAST_USER === undefined || ticket.UDF_PHASE_PAY_LAST_USER === ''
              ? 'Sage/unknown' : ticket.UDF_PHASE_PAY_LAST_USER,
            parentPhase: ticket.HdrParentItemCode
          };
          if (phaseObj.phasePayStatus === 'approved') {
            const newDate = new Date(phaseObj.phasePayStatusDate);
            const newDay = newDate.getDate() + 1;
            const getDay = newDate.getDay() + 1;
            const getMonth = newDate.getMonth() + 1;
            const getYear = newDate.getFullYear();

            if (getDay === 1) {
              phaseObj.estimatedPayDate = newDate.setDate(
                Number(`${newDay + 11}`)
              );
            } else if (getDay === 2) {
              phaseObj.estimatedPayDate = newDate.setDate(
                Number(`${newDay + 10}`)
              );
            } else if (getDay === 3) {
              phaseObj.estimatedPayDate = newDate.setDate(
                Number(`${newDay + 9}`)
              );
            } else if (getDay === 4) {
              phaseObj.estimatedPayDate = newDate.setDate(
                Number(`${newDay + 8}`)
              );
            } else if (getDay === 5) {
              phaseObj.estimatedPayDate = newDate.setDate(
                Number(`${newDay + 7}`)
              );
            }
            else if (getDay === 6) {
              phaseObj.estimatedPayDate = newDate.setDate(
                Number(`${newDay + 6}`)
              );
            }
            else if (getDay === 7) {
              phaseObj.estimatedPayDate = newDate.setDate(
                Number(`${newDay + 5}`)
              );
            }
          }
          phaseObj.phasePayStatus === 'hold'
            ? this.phaseTicketHoldLines.push(phaseObj)
            : this.phaseTickets.push(phaseObj);

        });
        const newPhases: string[] = [];
        for(const step of this.phaseTickets){
          const obj = (step.parentPhase.trim().toLowerCase());
          if(!newPhases.includes(obj)){
            newPhases.push(obj);
          }
        }
        this.phaseOptions = newPhases;
        this.phaseCodes.setValue(newPhases);

        this.phaseTickets.sort((a,b) => Date.parse(a.dateScheduled) - Date.parse(b.dateScheduled));
        this.phaseTickets.forEach(element => {
          if(element.salesOrderNo == '0028984' && element.wtNumber == '020'){
            console.log(element);
          }
        });
        console.log(this.phaseTickets, this.phaseTicketHoldLines);
      });
      this.loadingData = false;
  }

  statusFilter(ticket){
    let status = ticket.status.toLowerCase();
    return status.toLowerCase() === 'com' || status.toLowerCase() === 'qac' || status.toLowerCase() === 'ins' || status.toLowerCase() === 'ptl' ||
      status.toLowerCase() === 'qar' || status.toLowerCase() === 'rec' || status.toLowerCase() === 'qan' || status.toLowerCase() === 'wob' ||
      status.toLowerCase() === 'wog' || status.toLowerCase() === 'cld' || status.toLowerCase() === 'ins' || status.toLowerCase() === 'bin' ||
      (status.toLowerCase() === 'new' && ticket.phase.toLowerCase() == 'zvanguard');
  }

  getSuperintendents() {
    this.sageApi
      .pullReport('User?role=super')
      .subscribe((superintendents: Array<any>) => {
        if (Array.isArray(superintendents)) {
          superintendents.forEach((user) => {
          if(user.SageSuperName != ''){
            const obj = {
              FirstName: user.FirstName,
              UserKey: user.UserKey,
              UserLogon: user.UserLogon,
              jobDepartment: user.jobDepartment,
              jobTitle: user.jobTitle,
              role: user.role,
              superName: user.SageSuperName,
              techCode: user.techCode,
            };
            this.superintendentsList.push(obj);
            this.supersList.push(user.SageSuperName);
          }});
        }

        this.superintendentsList.sort((a,b) => a.superName.localeCompare(b.superName));
      });
  }

  onApproveTicket(index: string, template: string) {
    this.updatingData = true;
    const observables = [];
    let qtyShipped: number;
    const phasepaylines = [];
    let ticketSO = '';

    this.phaseTicketLines.forEach((ticket) => {
      // * If the quantity ordered is not empty, set qty shipped to match qty ordered //
      if (ticket.wtParent === 'N' && ticket.qtyOrdered !== '') {
        console.log(ticket.parentPhase);
        if (ticket.parentPhase.toLowerCase().includes('roughin')){
          ticket.qtyOrdered = 1;
          // console.log(ticket.qtyOrdered);
        }
        qtyShipped = Number(ticket.qtyOrdered);
      }

      // ! Account for null values in pay dates (mostly on header items) as API expects a string ! //
      let payDate;
      if (ticket.UDF_PAY_DATE === null || Number(ticket.qtyOrdered < 1)) {
        payDate = '17530101';
      } else {
        payDate = this.datePipe.transform(ticket.UDF_PAY_DATE, 'yyyyMMdd');
      }

      if (ticket.phasePayStatus === 'hold' && ticket.wtParent === 'N') {
        payDate = '17530101';
        qtyShipped = 0;
      }
      if (ticket.phasePayStatus === 'inactive') {
        payDate = '17530101';
        qtyShipped = 0;
      }
      ticketSO = ticket.salesOrderNo;
      // const soLines = {
      //   username: this.authService.decodedToken.nameid,
      //   password:
      //     this.authService.decodedToken[
      //       'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      //     ],
      //   salesOrderNo: ticket.salesOrderNo,
      //   PhasePayLines: [
      const tickLine = [
          ticket.lineKey, // 0
          payDate, // 1
          `${ticket.phasePayComment} ${this.timestamp}`, // 2
          ticket.qtyOrdered, // 3
          qtyShipped, // 4
          ticket.isTechPRT, // 5
          ticket.prtCrewLead, // 6
          Number(ticket.prtCrewLeadSplit), // 7
          ticket.prtCrew1, // 8
          Number(ticket.prtCrew1Split), // 9
          ticket.prtCrew2, // 10
          Number(ticket.prtCrew2Split), // 11
          ticket.phasePayStatus === 'active' &&
          template === 'editPhaseTicketModal'
            ? 'approved'
            : ticket.phasePayStatus === 'hold' &&
              template === 'editPhaseTicketModal'
            ? 'hold'
            : ticket.phasePayStatus === 'hold' &&
              template === 'phaseTicketHoldModal'
            ? 'approved'
            : ticket.phasePayStatus === 'inactive' ? ''
            : ticket.phasePayStatus, // 12
          this.todayDate, // 13
          Number(ticket.qtyOrdered) < 1 ? '' : ticket.subVendorCode, // 14
          Number(ticket.qtyOrdered) < 1 ? '' : ticket.techCode, // 15
          ticket.phase, // 16
          ticket.wtParent, // 17
          ticket.wtStep, // 18
          ticket.unitCost, // 19
          this.phaseTickets[index].wtNumber, // 20
          this.phaseTickets[index].lineKey, // 21
          this.phaseTickets[index].itemCodeDesc, // 22
        ];
      phasepaylines.push(tickLine);

      //   ],
      // };
      // DO NOT ADD BACK ***********************************************************
      // observables.push(this.sageApi.putRequest('PhasePaySalesOrder', soLines));
      // ***************************************************************************
      // console.log(soLines);
    });

    // const obj = {
    //   username: this.authService.decodedToken.nameid,
    //   password:
    //     this.authService.decodedToken[
    //       'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
    //       ],
    //   salesOrderNo: ticketSO,
    //   editWT: 'N',
    //   PhasePayLines: phasepaylines
    // };

    // console.log(obj);
    // new observable goes here *************************************************
    // observables.push(this.sageApi.putRequest('PhasePayWorkTicket', obj));
    // **************************************************************************

    // * If editing a ticket with a restricted status, WT status moves to QAN //
    // tslint:disable-next-line: max-line-length
    const updatedWTStatus =
      this.phaseTickets[index].status.toLowerCase() === 'ptl' ||
      this.phaseTickets[index].status.toLowerCase() === 'qar' ||
      this.phaseTickets[index].status.toLowerCase() === 'rec'
        ? 'QAN'
        : this.phaseTickets[index].status;

    // * If the WT status changes then update the status date & time //
    // tslint:disable-next-line: max-line-length
    const updatedWTStatusDate =
      this.phaseTickets[index].status.toLowerCase() === 'ptl' ||
      this.phaseTickets[index].status.toLowerCase() === 'qar' ||
      this.phaseTickets[index].status.toLowerCase() === 'rec'
        ? this.todayDate
        : this.phaseTickets[index].statusDate;
    // tslint:disable-next-line: max-line-length
    const updatedWTStatusTime =
      this.phaseTickets[index].status.toLowerCase() === 'ptl' ||
      this.phaseTickets[index].status.toLowerCase() === 'qar' ||
      this.phaseTickets[index].status.toLowerCase() === 'rec'
        ? this.datePipe.transform(this.today.getTime(), 'hhmm')
        : this.phaseTickets[index].statusTime;

    const wtLines = {
      username: this.authService.decodedToken.nameid,
      password:
        this.authService.decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
        ],
      salesOrderNo: this.phaseTickets[index].salesOrderNo,
      paydate:
        this.phaseTickets[index].UDF_PAY_DATE === '1753-01-01' ||
        this.phaseTickets[index].UDF_PAY_DATE === '' ||
        this.phaseTickets[index].UDF_PAY_DATE === null
          ? this.fridayDate
          : this.phaseTickets[index].UDF_PAY_DATE,
      wtNumber: this.phaseTickets[index].wtNumber,
      wtStep: '020',
      wtStatus: updatedWTStatus,
      wtStatusDate: updatedWTStatusDate,
      wtStatusTime: updatedWTStatusTime,
      editWT: 'Y',
      PhasePayLines: phasepaylines
    };
    console.log(wtLines);

    // THIS ONE
    observables.push(this.sageApi.putRequest('PhasePayWorkTicket', wtLines));

    this.alertify.warning(`Updating ${this.phaseTickets[index].address}`);
    concat(...observables).subscribe(
      (response) => {
        console.log('put', response);
      },
      (err) => {
        this.alertify.error(
          `Error updating ${this.phaseTickets[index].soNum} - ${this.phaseTickets[index].address}: ${err.error.Message}`
        );
        template === 'editPhaseTicketModal'
          ? this.onClosePhaseTicketModal('editPhaseTicketModal')
          : this.onClosePhaseTicketModal('phaseTicketHoldModal');
        this.updatingData = false;
      },
      () => {
        this.alertify.success(
          `APPROVED - ${this.phaseTickets[index].address}-${this.phaseTickets[index].phase}.`
        );
        template === 'editPhaseTicketModal'
          ? this.onClosePhaseTicketModal('editPhaseTicketModal')
          : this.onClosePhaseTicketModal('phaseTicketHoldModal');
        this.onWriteToHistory(index, 'approved');
        this.updatingData = false;
        this.getPhaseTickets(this.selectedSuper);
      }
    );
  }

  onApproveSingleHoldLine(ticket, editIndex: string, template: string) {
    this.updatingData = true;
    const observables = [];
    let qtyShipped: number;
    const phasepaylines = [];
    // * If the quantity ordered is not empty, set qty shipped to match qty ordered //
    if (ticket.wtParent === 'N' && ticket.qtyOrdered !== '') {
      console.log(ticket.parentPhase);
      if (ticket.parentPhase.toLowerCase().includes('roughin') && ticket.qtyOrdered <= 0)
      {
        ticket.qtyOrdered = 1;
        // console.log(ticket.qtyOrdered);
      }
      qtyShipped = Number(ticket.qtyOrdered);
    }

    // ! Account for null values in pay dates (mostly on header items) as API expects a string ! //
    let payDate;
    if (ticket.UDF_PAY_DATE === null || Number(ticket.qtyOrdered < 1)) {
      payDate = '17530101';
    } else {
      payDate = this.datePipe.transform(ticket.UDF_PAY_DATE, 'yyyyMMdd');
    }

    phasepaylines.push([
      ticket.lineKey,
      payDate,
      `${ticket.phasePayComment} ${this.timestamp}`,
      ticket.qtyOrdered,
      qtyShipped,
      ticket.isTechPRT,
      ticket.prtCrewLead,
      Number(ticket.prtCrewLeadSplit),
      ticket.prtCrew1,
      Number(ticket.prtCrew1Split),
      ticket.prtCrew2,
      Number(ticket.prtCrew2Split),
      'approved',
      this.todayDate,
      Number(ticket.qtyOrdered) < 1 ? '' : ticket.subVendorCode,
      Number(ticket.qtyOrdered) < 1 ? '' : ticket.techCode,
      ticket.phase,
      ticket.wtParent,
      ticket.wtStep
    ]);

    const soLines = {
      username: this.authService.decodedToken.nameid,
      password:
        this.authService.decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
        ],
      salesOrderNo: ticket.salesOrderNo,
      editWT: 'N',
      PhasePayLines: phasepaylines
    };
    console.log(soLines);
    // observables.push(this.sageApi.putRequest('PhasePaySalesOrder', soLines));

    // THIS ONE
    observables.push(this.sageApi.putRequest('PhasePayWorkTicket', soLines));

    this.alertify.warning(`Updating ${ticket.address}`);
    concat(...observables).subscribe(
      (response) => {
        console.log('put', response);
      },
      (err) => {
        this.alertify.error(
          `Error updating ${ticket.soNum} - ${ticket.address}: ${err.error.Message}`
        );
        template === 'editPhaseTicketModal'
          ? this.onClosePhaseTicketModal('editPhaseTicketModal')
          : this.onClosePhaseTicketModal('phaseTicketHoldModal');
        this.updatingData = false;
      },
      () => {
        this.alertify.success(`APPROVED - ${ticket.address}-${ticket.phase}.`);
        template === 'editPhaseTicketModal'
          ? this.onClosePhaseTicketModal('editPhaseTicketModal')
          : this.onClosePhaseTicketModal('phaseTicketHoldModal');
        this.onWriteToHistory(editIndex, 'approved');
        this.updatingData = false;
        this.getPhaseTickets(this.selectedSuper);
      }
    );
  }

  onEditApprovedTicket(index: string) {
    this.updatingData = true;
    const observables = [];
    const phasepaylines = [];
    this.phaseTicketLines.forEach((ticket) => {
      // const soLines = {
      //   username: this.authService.decodedToken.nameid,
      //   password:
      //     this.authService.decodedToken[
      //       'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      //     ],
      //   salesOrderNo: ticket.salesOrderNo,
      //   PhasePayLines: [
      const tickline = [
          ticket.lineKey,
          '17530101',
          `${this.phaseTickets[index].phasePayComment} ${this.timestamp}`,
          ticket.qtyOrdered,
          ticket.qtyShipped,
          ticket.isTechPRT,
          ticket.prtCrewLead,
          Number(ticket.prtCrewLeadSplit),
          ticket.prtCrew1,
          Number(ticket.prtCrew1Split),
          ticket.prtCrew2,
          Number(ticket.prtCrew2Split),
          'active',
          this.todayDate,
          Number(ticket.qtyOrdered) < 1 ? '' : ticket.subVendorCode,
          Number(ticket.qtyOrdered) < 1 ? '' : ticket.techCode,
          ticket.phase,
          ticket.wtParent,
          ticket.wtStep
      ];
      phasepaylines.push(tickline);
      //   ],
      // };
      // DO NOT ADD BACK *********************************************************
      // observables.push(this.sageApi.putRequest('PhasePaySalesOrder', soLines));
      // *************************************************************************
    });

    const soLines = {
      username: this.authService.decodedToken.nameid,
      password:
        this.authService.decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
        ],
      salesOrderNo: this.phaseTickets[index].salesOrderNo,
      paydate: '1753-01-01',
      wtNumber: this.phaseTickets[index].wtNumber,
      wtStep: '020',
      wtStatus: this.phaseTickets[index].status,
      wtStatusDate: this.phaseTickets[index].statusDate,
      wtStatusTime: this.phaseTickets[index].statusTime,
      editWT: 'Y',
      PhasePayLines: phasepaylines
    };

    // THIS ONE
    observables.push(this.sageApi.putRequest('PhasePayWorkTicket', soLines));

    this.alertify.warning(`Updating ${this.phaseTickets[index].address}`);
    concat(...observables).subscribe(
      (response) => {
        console.log('put', response);
      },
      (err) => {
        this.alertify.error(
          `Error updating ${this.phaseTickets[index].soNum} - ${this.phaseTickets[index].address}: ${err.error.Message}`
        );
        this.onClosePhaseTicketModal('editPhaseTicketModal');
        this.updatingData = false;
      },
      () => {
        this.alertify.success(
          `${this.phaseTickets[index].address}-${this.phaseTickets[index].phase} - Moved to UNAPPROVED Status`
        );
        this.onClosePhaseTicketModal('editPhaseTicketModal');
        this.onWriteToHistory(index, 'active');
        this.updatingData = false;
        this.getPhaseTickets(this.selectedSuper);
      }
    );
  }

  onHoldTicket(index: string) {
    this.updatingData = true;
    const observables = [];
    const phasepaylines = [];
    this.phaseTicketLines.forEach((ticket) => {
      // const holdLines = {
      //   username: this.authService.decodedToken.nameid,
      //   password:
      //     this.authService.decodedToken[
      //       'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      //     ],
      //   salesOrderNo: ticket.salesOrderNo,
      //   PhasePayLines: [
      const ticklines = [
          ticket.lineKey,
          '1753-01-01', // ! No pay date needed to hold tickets
          `${this.phaseTickets[index].phasePayComment} ${this.timestamp}`,
          ticket.qtyOrdered,
          ticket.qtyShipped,
          ticket.isTechPRT,
          ticket.prtCrewLead,
          Number(ticket.prtCrewLeadSplit),
          ticket.prtCrew1,
          Number(ticket.prtCrew1Split),
          ticket.prtCrew2,
          Number(ticket.prtCrew2Split),
          'hold',
          this.todayDate,
          Number(ticket.qtyOrdered) < 1 ? '' : ticket.subVendorCode,
          Number(ticket.qtyOrdered) < 1 ? '' : ticket.techCode,
          ticket.phase,
          ticket.wtParent,
          ticket.wtStep
      ];
      phasepaylines.push(ticklines);
      //   ],
      // };
      // DO NOT ADD BACK *******************************************
      // observables.push(
      //   this.sageApi.putRequest('PhasePaySalesOrder', holdLines)
      // );
      // ***********************************************************
    });

    const holdLines = {
      username: this.authService.decodedToken.nameid,
      password:
        this.authService.decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
          ],
      salesOrderNo: this.phaseTicketLines[0].salesOrderNo,
      editWT: 'N',
      PhasePayLines: phasepaylines
    };
    // observables.push(this.sageApi.putRequest("PhasePaySalesOrder", holdLines));

    // THIS ONE
    observables.push(this.sageApi.putRequest('PhasePayWorkTicket', holdLines));

    this.alertify.warning(`Updating ${this.phaseTickets[index].address}`);
    concat(...observables).subscribe(
      (response) => {
        console.log('put', response);
      },
      (err) => {
        this.updatingData = false;
        this.alertify.error(
          `Error updating ${this.phaseTickets[index].address}: ${err.error.Message}`
        );
        this.onClosePhaseTicketModal('editPhaseTicketModal');
      },
      () => {
        this.alertify.success(
          `Success - ${this.phaseTickets[index].address}-${this.phaseTickets[index].phase} was placed on HOLD`
        );
        this.onClosePhaseTicketModal('editPhaseTicketModal');
        this.onWriteToHistory(index, 'hold');
        this.updatingData = false;
        this.getPhaseTickets(this.selectedSuper);
      }
    );
  }

  onSubmitChippingLines(workTicket: any) {
    const observables = [];
    const chippingLinesArray = [];
    const chippingComment = `${workTicket.chippingComment} ${this.timestamp}`;

    // * Beginning of Sales Order Line Creation *//
    // ? Damaged Pipes ? //
    if (workTicket.damagedPipes > 0 && workTicket.damagedPipesUpdated) {
      const dmgArr = [
        '/TOP OUT - CHIPPING',
        `${workTicket.damagedPipes} damaged pipes`, // * ItemCode
        workTicket.damagedPipes, // * QtyOrdered
        '0', // *QtyShipped
        '20', // * UnitCost,
        'Y',
        workTicket.wtNumber,
        '020', // * WTStep
        'Y', // * ChargePart
        'N', // *WTParent
        workTicket.lineKey, // * WTParentLineKey
        workTicket.subVendor,
        workTicket.techCode,
      ];
      chippingLinesArray.push(dmgArr);
    }

    // ? Partial Pipes ? //
    if (workTicket.partialPipes > 2 && workTicket.partialPipesUpdated) {
      const partialArr = [
        '/TOP OUT - CHIPPING',
        `${
          workTicket.partialPipes - 2
        } pipes partially out of wall (after first two)`, // * ItemCode
        workTicket.partialPipes - 2, // * QtyOrdered
        '0', // *QtyShipped
        '10', // * UnitCost,
        'Y',
        workTicket.wtNumber,
        '020', // * WTStep
        'Y', // * ChargePart
        'N', // *WTParent
        workTicket.lineKey, // * WTParentLineKey
        workTicket.subVendor,
        workTicket.techCode,
      ];
      chippingLinesArray.push(partialArr);
    }

    // ? Complete Pipes ? //
    if (workTicket.completePipes > 0 && workTicket.completePipesUpdated) {
      const completeArr = [
        '/TOP OUT - CHIPPING',
        `${workTicket.completePipes} pipes 6'or less out of wall`, // * ItemCode
        workTicket.completePipes, // * QtyOrdered
        '0', // *QtyShipped
        '25', // * UnitCost,
        'Y',
        workTicket.wtNumber,
        '020', // * WTStep
        'Y', // * ChargePart
        'N', // *WTParent
        workTicket.lineKey, // * WTParentLineKey
        workTicket.subVendor,
        workTicket.techCode,
      ];
      chippingLinesArray.push(completeArr);
    }

    // ? Pipes Out Extended ? //
    if (workTicket.pipesOutExtended > 0 && workTicket.pipesOutExtendedUpdated) {
      const pipesOutExtendedArr = [
        '/TOP OUT - CHIPPING',
        `${workTicket.pipesOutExtended} pipes 6'or more out of wall`, // * ItemCode
        workTicket.pipesOutExtended, // * QtyOrdered
        '0', // *QtyShipped
        '40', // * UnitCost,
        'Y',
        workTicket.wtNumber,
        '020', // * WTStep
        'Y', // * ChargePart
        'N', // *WTParent
        workTicket.lineKey, // * WTParentLineKey
        workTicket.subVendor,
        workTicket.techCode,
      ];
      chippingLinesArray.push(pipesOutExtendedArr);
    }

    // ? Tub Box ? //
    if (workTicket.tubBox > 0 && workTicket.tubBoxUpdated) {
      const tubBoxArr = [
        '/TOP OUT - CHIPPING',
        `${workTicket.tubBox} tub box(es)`, // * ItemCode
        workTicket.tubBox, // * QtyOrdered
        '0', // *QtyShipped
        '40', // * UnitCost,
        'Y',
        workTicket.wtNumber,
        '020', // * WTStep
        'Y', // * ChargePart
        'N', // *WTParent
        workTicket.lineKey, // * WTParentLineKey
        workTicket.subVendor,
        workTicket.techCode,
      ];
      chippingLinesArray.push(tubBoxArr);
    }

    const soChippingLines = {
      username: this.username,
      password:
        this.authService.decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
        ],
      salesorderno: workTicket.salesOrderNo,
      chippingLines: chippingLinesArray,
    };
    console.log(`chipping lines: `, soChippingLines.chippingLines);
    // THIS ONE
    observables.push(this.sageApi.putRequest('UpdScoutSO', soChippingLines));


    // observables.push(
    //   this.sageApi.putRequest('PhasePayChipping', soChippingLines)
    // );

    // * End of Sales Order Line Creation *//

    // * Beginning of WT Line Creation *//

    const wtChippingLines = {
      username: this.username,
      password:
        this.authService.decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
        ],
      salesorderno: workTicket.salesOrderNo,
      wtnumber: workTicket.wtNumber,
      readyStatus: ' ',
      scoutDescription: chippingComment,
      pipesOutOfTheWall: workTicket.completePipes || 0,
      pipesShadingTheWall: workTicket.partialPipes > 2 ? workTicket.partialPipes - 2 : workTicket.partialPipes,
      pictureLinks: ' ',
      materialOnScout: ' ',
      pipesDamaged: workTicket.damagedPipes || 0,
      pipesExtendedOutOfTheWall: workTicket.pipesOutExtended || 0,
      tubBox: workTicket.tubBox || 0,
    };

    // observables.push(
    //   this.sageApi.putRequest('PhasePayChippingWT', wtChippingLines)
    // );
    console.log(wtChippingLines);

    // observables.push(this.qaPage.updateScoutWT(wtScout));

    // THIS ONE
    observables.push(this.sageApi.putRequest('UpdScoutWT', wtChippingLines));


    // * End of WT Line Creation *//

    this.alertify.warning(`Chipping submitted for ${workTicket.address}`);
    concat(...observables).subscribe(
      (response) => {},
      (error) => {
        console.error(error);
        this.alertify.error(
          `Error for ${workTicket.address}: ${error.error.Message}`
        );
        this.onClosePhaseTicketModal('addChippingLineModal');
      },
      () => {
        this.alertify.success(
          `Your chipping lines for ${workTicket.address} were submitted successfully!`
        );
        this.onClosePhaseTicketModal('addChippingLineModal');
        workTicket.chippingSubmitted = true;
      }
    );
  }

  onSubmitIncentive(ticket) {
    const observables = [];
    let modifiedPayDate;
    if (this.day === 0) {
      modifiedPayDate = this.datePipe.transform(
        this.today.setDate(this.date + 5),
        'yyyy-MM-dd'
      );
    } else if (this.day === 1) {
      modifiedPayDate = this.datePipe.transform(
        this.today.setDate(this.date + 4),
        'yyyy-MM-dd'
      );
    } else if (this.day === 2) {
      modifiedPayDate = this.datePipe.transform(
        this.today.setDate(this.date + 10),
        'yyyy-MM-dd'
      );
    } else if (this.day === 3) {
      modifiedPayDate = this.datePipe.transform(
        this.today.setDate(this.date + 9),
        'yyyy-MM-dd'
      );
    } else if (this.day === 4) {
      modifiedPayDate = this.datePipe.transform(
        this.today.setDate(this.date + 8),
        'yyyy-MM-dd'
      );
    } else if (this.day === 5) {
      modifiedPayDate = this.datePipe.transform(
        this.today.setDate(this.date + 7),
        'yyyy-MM-dd'
      );
    } else if (this.day === 6) {
      modifiedPayDate = this.datePipe.transform(
        this.today.setDate(this.date + 6),
        'yyyy-MM-dd'
      );
    }

    const phasepaylines = [];

    phasepaylines.push([
      '/INCENTIVE', // * ItemCode
      'Incentive', // * ItemCodeDesc
      ticket.incentive.amount, // * UnitCost
      ticket.wtNumber, // * WTNumber
      ticket.lineKey, // * ParentLineKey
      ticket.subVendorCode, // * Subvendor
      ticket.techCode, // * Technician
      ticket.incentive.reason, // * CommentText
      modifiedPayDate, // * PayDate
      ticket.promiseDate, // * Promise Date
    ]);

    const incentiveObj = {
      username: this.username,
      password:
        this.authService.decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
        ],
      salesOrderNo: ticket.salesOrderNo,
      editWT: 'N',
      PhasePayLines: phasepaylines,
    };
    console.log(incentiveObj);
    // observables.push(this.sageApi.putRequest('PhasePaySalesOrder', incentiveObj));

    // THIS ONE
    observables.push(this.sageApi.putRequest('PhasePayWorkTicket', incentiveObj));

    this.alertify.warning(`Submitting incentive for ${ticket.address}...`);
    concat(...observables).subscribe(
      (response) => {},
      (error) => {
        console.error(error);
        this.alertify.error(
          `Error for ${ticket.address}: ${error.error.Message}`
        );
        this.onClosePhaseTicketModal('addIncentiveLineModal');
      },
      () => {
        this.alertify.success(
          `Your incentive for ${ticket.address} was submitted!`
        );
        this.onClosePhaseTicketModal('addIncentiveLineModal');
      }
    );
  }

  onWriteToHistory(index: string, currentStatus: string) {
    this.updatingData = true;
    const observables = [];
    const historyList = [];
    this.phaseTicketLines.forEach((ticket) => {
      let updatedCurrentStatus = '';
      if (
        this.showUnapprovedTable === true &&
        ticket.phasePayStatus === 'active' &&
        currentStatus !== 'hold'
      ) {
        updatedCurrentStatus = 'approved';
      } else if (
        this.showUnapprovedTable === true &&
        ticket.phasePayStatus === 'hold' &&
        currentStatus !== 'hold'
      ) {
        updatedCurrentStatus = 'hold';
      } else if (
        this.showUnapprovedTable === true &&
        currentStatus === 'hold'
      ) {
        updatedCurrentStatus = 'hold';
      } else if (
        this.showApprovedTable === true &&
        currentStatus === 'active'
      ) {
        updatedCurrentStatus = 'active';
      } else if (this.showApprovedTable === true && currentStatus === 'hold') {
        updatedCurrentStatus = 'hold';
      } else if (this.showHoldTable === true) {
        updatedCurrentStatus = 'approved';
      }
      // const Obj = [
      //   ticket.salesOrderNo,
      //   ticket.wtNumber,
      //   ticket.wtStep,
      //   ticket.wtParent,
      //   ticket.address,
      //   ticket.lineKey,
      //   ticket.phase,
      //   ticket.itemCodeDesc,
      //   new Date(ticket.promiseDate) || new Date('1753-01-01'),
      //   ticket.extendedCost,
      //   ticket.reviewed,
      //   ticket.phasePayStatus === 'hold' || ticket.qtyOrdered < 1
      //       ? new Date('1753-01-01')
      //       : new Date(ticket.UDF_PAY_DATE),
      //   ticket.builderAudit,
      //   ticket.builderAuditReason,
      //   new Date(ticket.builderAuditDate) || new Date('1753-01-01'),
      //   ticket.builderAuditTime,
      //   ticket.builderAuditUser,
      //   ticket.subVendorCode,
      //   Number(ticket.qtyOrdered) < 1 ? '' : ticket.subVendorCode,
      //   Number(ticket.qtyOrdered) < 1 ? '' : ticket.techCode,
      //   ticket.techName,
      //   ticket.isTechPRT,
      //   ticket.status,
      //   new Date(ticket.statusDate) || new Date('1753-01-01'),
      //   ticket.statusTime,
      //   ticket.subdivision,
      //   ticket.superintendent,
      //   new Date(ticket.dateScheduled) || new Date('1753-01-01'),
      //   ticket.qtyOrdered,
      //   updatedCurrentStatus === 'approved' && ticket.wtParent === 'N'
      //       ? ticket.qtyOrdered
      //       : ticket.qtyShipped,
      //   ticket.prtCrewLead,
      //   ticket.prtCrewLeadSplit,
      //   ticket.prtCrew1,
      //   ticket.prtCrew1Split,
      //   ticket.prtCrew2,
      //   ticket.prtCrew2Split,
      //   `${ticket.phasePayComment} ${this.timestamp}`,
      //   this.showUnapprovedTable === true
      //       ? 'active'
      //       : this.showApprovedTable === true
      //       ? 'approved'
      //       : this.showHoldTable === true
      //         ? 'hold'
      //         : '',
      //   updatedCurrentStatus,
      //   this.datePipe.transform(
      //     this.today.getTime(),
      //     'HHmm'
      //   ),
      //   this.todayDate,
      //   this.authService.decodedToken.nameid,
      //   // tslint:disable-next-line: max-line-length
      //   `${ticket.salesOrderNo}${ticket.wtNumber}${
      //     ticket.lineKey
      //   }${this.datePipe.transform(
      //     this.today,
      //     'yyyyMMdd'
      //   )}${this.datePipe.transform(this.today.getTime(), 'hhmmss')}`,
      //   ticket.unitCost,
      //   ticket.totalCost,
      // ];
      // historyList.push(Obj);
      const historyObj = {
        salesOrderNo: ticket.salesOrderNo,
        wtNumber: ticket.wtNumber,
        wtStep: ticket.wtStep,
        wtParent: ticket.wtParent,
        address: ticket.address,
        lineKey: ticket.lineKey,
        itemCode: ticket.phase,
        itemCodeDesc: ticket.itemCodeDesc,
        promiseDate: new Date(ticket.promiseDate) || new Date('1753-01-01'),
        extendedCost: ticket.extendedCost,
        reviewed: ticket.reviewed,
        payDate:
          ticket.phasePayStatus === 'hold' || ticket.qtyOrdered < 1
            ? new Date('1753-01-01')
            : new Date(ticket.UDF_PAY_DATE),
        builderAudit: ticket.builderAudit,
        builderAuditReason: ticket.builderAuditReason,
        builderAuditDate:
          new Date(ticket.builderAuditDate) || new Date('1753-01-01'),
        builderAuditTime: ticket.builderAuditTime,
        builderAuditUser: ticket.builderAuditUser,
        subvendorCode: ticket.subVendorCode,
        subvendorName:
          Number(ticket.qtyOrdered) < 1 ? '' : ticket.subVendorCode,
        technicianCode: Number(ticket.qtyOrdered) < 1 ? '' : ticket.techCode,
        technicianName: ticket.techName,
        prt: ticket.isTechPRT,
        wtStatus: ticket.status,
        wtStatusDate: new Date(ticket.statusDate) || new Date('1753-01-01'),
        wtStatusTime: ticket.statusTime,
        subdivision: ticket.subdivision,
        leadman: ticket.superintendent,
        dateScheduled: new Date(ticket.dateScheduled) || new Date('1753-01-01'),
        quantityOrdered: ticket.qtyOrdered,
        quantityShipped:
          updatedCurrentStatus === 'approved' && ticket.wtParent === 'N'
            ? ticket.qtyOrdered
            : ticket.qtyShipped,
        prtCrewLead: ticket.prtCrewLead,
        prtCrewLeadSplit: ticket.prtCrewLeadSplit,
        prtCrew1: ticket.prtCrew1,
        prtCrew1Split: ticket.prtCrew1Split,
        prtCrew2: ticket.prtCrew2,
        prtCrew2Split: ticket.prtCrew2Split,
        phasePayComment: `${ticket.phasePayComment} ${this.timestamp}`,
        phasePayPreviousStatus:
          this.showUnapprovedTable === true
            ? 'active'
            : this.showApprovedTable === true
            ? 'approved'
            : this.showHoldTable === true
            ? 'hold'
            : '',
        phasePayCurrentStatus: updatedCurrentStatus,
        phasePayStatusChangeTime: this.datePipe.transform(
          this.today.getTime(),
          'HHmm'
        ),
        phasePayStatusDate: this.todayDate,
        phasePayUser: this.authService.decodedToken.nameid,
        // tslint:disable-next-line: max-line-length
        historyKey: `${ticket.salesOrderNo}${ticket.wtNumber}${
          ticket.lineKey
        }${this.datePipe.transform(
          this.today,
          'yyyyMMdd'
        )}${this.datePipe.transform(this.today.getTime(), 'hhmmss')}`,
        unitCost: ticket.unitCost,
        totalCost: ticket.totalCost,
      };
      // THIS ONE
      observables.push(this.sageApi.putRequest('PhasePayHistory', historyObj));
      console.log(historyObj);
    });
    console.log(historyList);
    // observables.push(this.sageApi.putRequest('PhasePayHistory', historyList));

    concat(...observables).subscribe(
      (response) => {
        console.log('Server Response for History: ', response);
      },
      (err) => {
        this.updatingData = false;
      },
      () => {
        console.log('History Was Successfully Updated!');
      }
    );
  }

  // * Beginning of  Image Functions * //
  async onLoadAddressImages(address: string) {
    const response = await this.wasabiApi.getImages(address);
    try {
      this.houseImages = response;
    } catch (error) {
      this.alertify.error(
        'There was an error loading the images for this house - please try again'
      );
      console.warn(`Error loading images: ${error}`);
    }
  }

  onFileChanged(event, address: string, salesorder) {
    this.imageUploadComplete = false;
    this.isImageLoading = true;
    this.imageArr = [];
    this.fileNames = [];
    const files = event.target.files;

    for (const index in files) {
      if (files[index] instanceof File) {
        this.fileNames.push(files[index].name);
        const obj = {
          name: salesorder.soNum+"-000"+files[index].name,
          data: files[index],
        };
        this.imageArr.push(obj);
      }
    }
    if (files.length > 0) {
       this.onFileLoadComplete(address);
    }
  }

  onFileLoadComplete(address: string, ) {
    this.wasabiApi.uploadImage(address,this.imageArr).subscribe(
      (res) => {
        this.imageUploadComplete = true;
      },
      (err) => {
        console.error(err);
        this.fileNames = [];
        this.isImageLoading = false;
      },
      () => {
        this.fileNames = [];
        this.isImageLoading = false;
      }
    );
  }

  getIncludedSteps(): PhasePayInterface[] {
    const isteps: PhasePayInterface[] = [];
    for (const step of this.phaseTickets){
      if (this.isStepIncluded(step)){
        isteps.push(step);
      }
    }
    return isteps;
  }

  isStepIncluded(step: PhasePayInterface){
    //const filterIncluded = this.isIncludedInStatus(step);
     const phaseIncluded = this.isIncludedInPhases(step);
    if (
      //filterIncluded &&
      phaseIncluded
    ){
      return true;
    }
    return false;
  }

  // isIncludedInStatus(isIncludedStep: PhasePayInterface){
  //   if (this.statusCodes.value.sort().join(',') == this.statusOptions.sort().join(',')){
  //     return true;
  //   }
  //   if (this.statusCodes.value.includes(isIncludedStep.phasePayStatus)){
  //     return true;
  //   }
  //   return false;
  // }

  isIncludedInPhases(isIncludedStep: PhasePayInterface){
    const stepSubdiv = (isIncludedStep.parentPhase == undefined ? '' : isIncludedStep.parentPhase).trim().toLowerCase();
    if (this.phaseCodes.value.sort().join(',') == this.phaseOptions.sort().join(',')){
      return true;
    }
    if (this.phaseCodes.value.includes(stepSubdiv)){
      return true;
    }
    return false;
  }
  // * End of  Image Functions * //

  // * End of Data Functions //
}

interface RoughInAdder {
  itemCode: string;
  itemCodeDesc: string;
  qty: number;
  price?: number;
}

interface RoughInAdderItem {
  itemCode: string;
  description: string;
  price: number;
  prtPrice: number;
}

interface RoughInAdderItemApi {
  Id: number;
  ItemCode: string;
  ItemCodeDesc: string;
  SetupPrice: number;
  Price: number;
  PrtPrice: number;
}
