import { ValidatorFn, AbstractControl } from "@angular/forms";

class DateValidators {

    /* 
        Example: 
        new FormGroup({
            start: new FormControl((new Date('2023-01-01')), [Validators.required]),
            end: new FormControl((new Date('2023-12-31')), [Validators.required]),
            value: new FormControl((new Date()), [Validators.required, DateValidators.dateBetweenValidator('value', 'start', 'end', {'minmax': true})]),
        });
    */
    static dateBetweenValidator(valueField: string, minField: string, maxField: string, validatorField: { [key: string]: boolean }): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const val = (new Date(valueField)).getTime()
            const min = (new Date(minField)).getTime();
            const max = (new Date(maxField)).getTime();
            if (
                (val !== null && min !== null && max !== null)
                && (!Number.isNaN(val) && !Number.isNaN(min) && !Number.isNaN(max))
                && val > min 
                && val < max
            ) {
                return validatorField;
            }
            return null;
        };
    }

    static dateAfterValidator(valueField: string, minField: string, validatorField: { [key: string]: boolean }): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const val = (new Date(valueField)).getTime()
            const min = (new Date(minField)).getTime();
            if (
                (val !== null && min !== null)
                && (!Number.isNaN(val) && !Number.isNaN(min))
                && val > min
            ) {
                return validatorField;
            }
            return null;
        };
    }

    static dateBeforeValidator(valueField: string, maxField: string, validatorField: { [key: string]: boolean }): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const val = (new Date(valueField)).getTime()
            const max = (new Date(maxField)).getTime();
            if (
                (val !== null && max !== null)
                && (!Number.isNaN(val) && !Number.isNaN(max))
                && val < max
            ) {
                return validatorField;
            }
            return null;
        };
    }
}
export default DateValidators;