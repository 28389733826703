import { AfterViewInit, Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AddedPayLinesInterface {
  itemCode: string;
  price: number;
  initialPayDate: string;
  payPeriods: number;
}

export interface PreviousPayLinesInterface {
  itemCode: string;
  description: string;
  womsComment: string;
  price: string;
  payDate: string;
  techName: string;
}
export interface ManagerTicketEditedInterface {
  newType: string;
  addedPayLines: AddedPayLinesInterface[];
  addedBackchargeLines: AddedPayLinesInterface[];
  previousPayLines: PreviousPayLinesInterface[];
}
export interface ManagerTicketInterface {
  salesorderno: string;
  wtNumber: string;
  address: string;
  type: string;
  typeComment: string;
  assignedTech: string;
  status: string;
  areaManager: string;
  itemCode: string;
  descriptionOfWork: string;
  timeToCompleteWork: number;
  builderName: string;
  builderEmail: string;
  builderCompany: string;
  subdivsion: string;
  itemPrice: number;
  parentPrice: number;
  womsStatusDesc: string;
  womsStatusType: string;
  itemlineKey: string;
  poNumber: string;
  superintendent: string;
  materialDescription: string;
  materialNeeded: string;
  topoutCrew: string;
  trimCrew: string;
  roughinCrew: string;
  vanguardCrew: string;
  dropinCrew: string;
  gasCrew: string;
  vpoNotes: string;
  promiseDate: string;
  materialNeededDate: string;
  techinicianCode: string;
  prt: string;
  prtTripChargeRate: number;
  commentText: string;
  womsServiceCode: string;
  womsStatusDate: string;
  subVendor: string;
  prtCrew1: string;
  prtCrewSplit1: number;
  prtCrew2: string;
  prtCrewSplit2: number;
  prtCrewLead: string;
  prtCrewSplitLead: number;
  punchImageKey: string;
  helper: string;
  wtReassignedBy: string;
  qaExpectedCompletionDate: string;
  editedFields: ManagerTicketEditedInterface;
}
export interface ManagerInterface {
  name: string;
  ticketGroups: {
    name: string
    selected: boolean
    tickets: ManagerTicketInterface[]
  }[];
}

export interface WomsHistory1 {
  SalesOrderNo: string;
  WTNumber: string;
  ItemCode: string;
  Type: string;
  TypeComment: string;
  Address: string;
  Status: string;
  DescriptionOfWork: string;
  Technician: string;
  WorkTime: number;
  BuilderName: string;
  BuilderPhone: string;
  BuilderCompany: string;
  Subdivision: string;
  LinePrice: number;
  ParentPrice: number;
  StatusDesc: string;
  StatusType: string;
  LineKey: string;
  PoNumber: string;
  BuilderEmail: string;
  LM_MANAGER: string;
  UDF_LEADMAN: string;
  UDF_MAT_DESCRIPTION: string;
  UDF_MAT_NEEDED_CHECK: string;
  TOPOUT_CREW: string;
  TRIM_CREW: string;
  ROUGHIN_CREW: string;
  VG_CREW: string;
  DROPIN_CREW: string;
  GAS_CREW: string;
  VPO_NOTES: string;
  PromiseDate: string;
  UDF_MAT_DATE_NEEDED: string;
  TechCode: string;
  PRT: string;
  CommentText: string;
  UDF_WOMS_SERVICE_CODE: string;
  UDF_WOMS_STATUS_DATE: string;
  SubVendor: string;
  PRT_CREW_EMP1: string;
  PRT_CREW_EMP1_SPLIT: number;
  PRT_CREW_EMP2: string;
  PRT_CREW_EMP2_SPLIT: number;
  PRT_CREW_LEAD: string;
  PRT_CREW_LEAD_SPLIT: number;
  UDF_PUNCH_IMAGE_KEY: string;
  Helper: string;
  StatusCode: string;
  UDF_WT_REASSIGNED_BY: string;
  UDF_QA_EXP_COMPLETION_DATE: string;
}

@Component({
  selector: 'app-woms-management-queue',
  templateUrl: './woms-management-queue.component.html',
  styleUrls: ['./woms-management-queue.component.css']
})
export class WomsManagementQueueComponent implements OnInit, AfterViewInit {
  managers: ManagerInterface[] = [];
  managersLoading: boolean;

  statusList = [];

  filteredValues = {
    wtNumber: '',
    address: '',
    type: '',
    assignedTech: '',
    status: ''
  };

  selectedGroup = this.managers?.[0]?.ticketGroups?.[0];
  tableDisplayedColumns: string[] = ['wtNumber', 'address', 'type', 'assigned tech', 'status', 'action'];
  dataSource: MatTableDataSource<ManagerTicketInterface> | null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.loadManagersList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  managerSelected(managersIndex: number) {
    console.log(managersIndex);
  }

  ticketGroupSelected(mangersIndex: number, ticketGroupIndex: number) {
    this.selectedGroup = this.managers[mangersIndex].ticketGroups[ticketGroupIndex];
    this.dataSource = new MatTableDataSource(this.selectedGroup?.tickets);
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    console.log(mangersIndex, ticketGroupIndex);
  }

  ticketSelected(selectedTicket: any) {
    console.log(selectedTicket);
    const dialogRef = this.dialog.open(WomsManagementQueueDialogComponent, {
      data: { ticket : selectedTicket, slideName: 'updateCharge'},
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filteredValues.wtNumber = filterValue.trim().toLowerCase();
    this.filteredValues.address = filterValue.trim().toLowerCase();
    this.filteredValues.type = filterValue.trim().toLowerCase();
    this.filteredValues.assignedTech = filterValue.trim().toLowerCase();

    this.dataSource.filter = JSON.stringify(this.filteredValues);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    console.log(this.dataSource.filter);
  }

  applyColumnFilter(selection: any) {
    console.log(selection);
    this.filteredValues.status = selection.join(', ').trim().toLowerCase();
    this.dataSource.filter = JSON.stringify(this.filteredValues);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    console.log(this.dataSource);
  }

  customFilterPredicate() {
    const myFilterPredicate = (data, filter: string): boolean => {
      const searchString = JSON.parse(filter);
      return  (data.wtNumber.toString().trim().toLowerCase().indexOf(searchString.wtNumber.toLowerCase()) !== -1 ||
              data.address.toString().trim().toLowerCase().indexOf(searchString.address.toLowerCase()) !== -1 ||
              data.type.toString().trim().toLowerCase().indexOf(searchString.type.toLowerCase()) !== -1 ||
              data.assignedTech.toString().trim().toLowerCase().indexOf(searchString.assignedTech.toLowerCase()) !== -1) &&
              (searchString.status.trim().toLowerCase().indexOf(data.status.toString().trim().toLowerCase()) !== -1 ||
              searchString.status.trim().toLowerCase() === '' ||
              searchString.status.trim().toLowerCase().indexOf('all statuses') !== -1);
    };

    return myFilterPredicate;
  }

  loadManagersList() {
    this.managers = [];
    this.managersLoading = true;
    this.sageApi.pullReport('womsHistory/1').subscribe((rows: Array<WomsHistory1>) => {
      if (Array.isArray(rows)) {
        rows.forEach(row => {
          const manager: ManagerInterface = {
            name: row.LM_MANAGER,
            ticketGroups: []
          };

          if (this.managers.some(x => x.name.toLocaleLowerCase() === row.LM_MANAGER.toLocaleLowerCase())) {
            // Dont add manager
          } else {
            this.managers.push(manager);
          }

          const managerIndex = this.managers.findIndex(x => x.name.toLocaleLowerCase() === row.LM_MANAGER.toLocaleLowerCase());

          const ticketGroup: {
            name: string,
            selected: boolean,
            tickets: []
          } = {
            name: row.ItemCode.slice(1),
            selected: false,
            tickets: []
          };

          if (this.managers[managerIndex].ticketGroups.some(x => x.name.toLocaleLowerCase() === row.ItemCode.slice(1).toLocaleLowerCase())) {
            // Dont add ticket grooup
          } else {
            this.managers[managerIndex].ticketGroups.push(ticketGroup);
          }
          const ticketGroupIndex = this.managers[managerIndex].ticketGroups.findIndex(x =>
                                      x.name.toLocaleLowerCase() === row.ItemCode.slice(1).toLocaleLowerCase());

          const ticket: ManagerTicketInterface = {
            salesorderno: row.SalesOrderNo,
            wtNumber: row.WTNumber,
            address: row.Address,
            type: row.Type,
            typeComment: row.TypeComment,
            assignedTech: row.Technician,
            status: row.StatusCode,
            areaManager: row.LM_MANAGER,
            itemCode: row.ItemCode,
            descriptionOfWork: row.DescriptionOfWork,
            timeToCompleteWork: row.WorkTime,
            builderName: row.BuilderName,
            builderEmail: row.BuilderEmail,
            builderCompany: row.BuilderCompany,
            subdivsion: row.Subdivision,
            itemPrice: row.LinePrice,
            parentPrice: row.ParentPrice,
            womsStatusDesc: row.StatusDesc,
            womsStatusType: row.StatusType,
            itemlineKey: row.LineKey,
            poNumber: row.PoNumber,
            superintendent: row.UDF_LEADMAN,
            materialDescription: row.UDF_MAT_DESCRIPTION,
            materialNeeded: row.UDF_MAT_NEEDED_CHECK,
            topoutCrew: row.TOPOUT_CREW,
            trimCrew: row.TRIM_CREW,
            roughinCrew: row.ROUGHIN_CREW,
            vanguardCrew: row.VG_CREW,
            dropinCrew: row.DROPIN_CREW,
            gasCrew: row.GAS_CREW,
            vpoNotes: row.VPO_NOTES,
            promiseDate: row.PromiseDate,
            materialNeededDate: row.UDF_MAT_DATE_NEEDED,
            techinicianCode: row.TechCode,
            prt: row.PRT,
            prtTripChargeRate: row.PRT === 'Y' ? 25 : 35,
            commentText: row.CommentText,
            womsServiceCode: row.UDF_WOMS_SERVICE_CODE,
            womsStatusDate: row.UDF_WOMS_STATUS_DATE,
            subVendor: row.SubVendor,
            prtCrew1: row.PRT_CREW_EMP1,
            prtCrewSplit1: row.PRT_CREW_EMP1_SPLIT,
            prtCrew2: row.PRT_CREW_EMP2,
            prtCrewSplit2: row.PRT_CREW_EMP2_SPLIT,
            prtCrewLead: row.PRT_CREW_LEAD,
            prtCrewSplitLead: row.PRT_CREW_LEAD_SPLIT,
            punchImageKey: row.UDF_PUNCH_IMAGE_KEY,
            helper: row.Helper,
            wtReassignedBy: row.UDF_WT_REASSIGNED_BY,
            qaExpectedCompletionDate: row.UDF_QA_EXP_COMPLETION_DATE,
            editedFields: {
              newType: '',
              addedPayLines: [],
              addedBackchargeLines: [],
              previousPayLines: []
            }
          };

          this.managers[managerIndex].ticketGroups[ticketGroupIndex].tickets.push(ticket);

          if (!this.statusList.some(x => x === row.StatusCode)) {
            this.statusList.push(row.StatusCode);
          }
        });
      }
    }, err => {
      this.alertify.error(err.message);
      console.log(err);
      this.managersLoading = false;
    }, () => {
      console.log(this.managers);

      this.selectedGroup = this.managers?.[0].ticketGroups?.[0];
      this.dataSource = new MatTableDataSource(this.selectedGroup?.tickets);
      this.dataSource.filterPredicate = this.customFilterPredicate();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.managersLoading = false;
    });
  }
}

@Component({
  selector: 'app-woms-management-queue-dialog',
  templateUrl: './woms-management-queue-dialog.html',
})
export class WomsManagementQueueDialogComponent {
  paymentAmount = 0;
  previousPayLinesLoading = false;

  constructor(
    private sageApi: SageApiService,
    public dialogRef: MatDialogRef<WomsManagementQueueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { ticket: ManagerTicketInterface, slideName: string }) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  slideSelection(nextSlideName: string) {
    this.data.slideName = nextSlideName;
  }

  updateChargeType(type: string) {
    this.data.ticket.editedFields.newType = type;
  }

  addPayment(paymentItemCode: string) {
    const paymentPrice = paymentItemCode === 'Trip Charge' ? this.data.ticket.prtTripChargeRate : this.paymentAmount;
    const payment: AddedPayLinesInterface = {
      itemCode: paymentItemCode,
      price: paymentPrice,
      initialPayDate: '',
      payPeriods: 1
    };

    this.data.ticket.editedFields.addedPayLines.push(payment);
  }

  removePayment(paymentIndex: number) {
    this.data.ticket.editedFields.addedPayLines.splice(paymentIndex, 1);
  }

  getPreviousPayments() {
    this.data.ticket.editedFields.previousPayLines = [];
    this.previousPayLinesLoading = true;

    this.sageApi.pullReport('PayLines/' + this.data.ticket.salesorderno).subscribe(rows => {
      if (Array.isArray(rows)) {
        rows.forEach(row => {
          const previousPayment: PreviousPayLinesInterface = {
            itemCode: row.ItemCode,
            description: row.ItemCodeDesc,
            womsComment: row.UDF_WOMS_TYPE_COMMENT,
            payDate: row.UDF_PAY_DATE,
            price: row.UnitCost,
            techName: row.TechName
          };
          if (this.data.ticket.assignedTech.toLocaleLowerCase() === row.TechName.toLocaleLowerCase()) {
            this.data.ticket.editedFields.previousPayLines.unshift(previousPayment);
          } else {
            this.data.ticket.editedFields.previousPayLines.push(previousPayment);
          }
        });
      }
    }, err => {
      console.log(err);
      this.previousPayLinesLoading = false;
    }, () => {
      this.previousPayLinesLoading = false;
      console.log('got payments', this.data.ticket.editedFields.previousPayLines);
    });
  }

}
