import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import ContextKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitDtoInterface';
import PartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/PartDtoInterface';
import KitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitPartDtoInterface';
import ContextKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitPartDtoInterface';
import { FormArray, FormControl, Validators } from '@angular/forms';
import KitPartPhases from '../KitPartPhases';
import ContextQuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteKitPartDtoInterface';
import QuoteUtil from 'src/app/utils/QuoteUtil';
import QuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteKitPartDtoInterface';
import QuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuotePartDtoInterface';
import QuoteKitPartQuotePartSavedInterface from '../../../_services/QuoteEditableService/interfaces/QuoteKitPartQuotePartSavedInterface';
import { Subject } from 'rxjs';
import QuoteKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteKitDtoInterface';
import QuoteKitPartEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteKitPartEditableInterface';
import GetColorFromString from 'src/app/utils/GetColorFromString';
import { QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';
import ContextQuoteKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteKitDtoInterface';
import { BaseModalService } from 'src/app/_services/BaseModalService/BaseModalService';
import ContextQuotePartTagDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuotePartTagDtoInterface';

export type QuoteKitPartRowOnSaveType = 
  QuoteKitPartDtoInterface
  | QuotePartDtoInterface
  | ContextQuoteKitDtoInterface
  | QuoteKitDtoInterface;

@Component({
  selector: 'app-quotekprow',
  templateUrl: './QuoteKitPartRowComponent.html',
  styleUrls: ['./QuoteKitPartRowComponent.css', '../QuotingTheme.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteKitPartRowComponent implements OnInit, OnChanges {

  @Input() qkp: QuoteKitPartEditableInterface;
  
  // @Input() shouldUpdate: Subject<void>;

  cost = 0;
  costControlVisible: boolean = false;

  totalParts = 0;

  kitPartPhases = KitPartPhases;

  isHovered = false;
  isCostHovered = false;

  isLocationPickerOpen = false;
  tagPickerOpen = false;
  
  @Output() onSave: EventEmitter<QuoteKitPartRowOnSaveType> = new EventEmitter<QuoteKitPartRowOnSaveType>();
  @Output() onOpenAddSheet: EventEmitter<QuoteKitPartEditableInterface> = new EventEmitter<QuoteKitPartEditableInterface>();
  @Output() onCustomKitSheet: EventEmitter<QuoteKitPartEditableInterface> = new EventEmitter<QuoteKitPartEditableInterface>();
  @Output() onCustomPartSheet: EventEmitter<QuoteKitPartEditableInterface> = new EventEmitter<QuoteKitPartEditableInterface>();
  
  constructor(
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    public api: SageApiService,
    public qe: QuoteEditableService,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private modals: BaseModalService,
  ){}

  ngOnInit() {
    this.qe.updated.subscribe(()=>{
      this.generateCost();
      this.generateTotalParts();
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetControls();
    this.generateCost();
    this.generateTotalParts();
    this.cdr.detectChanges();
  }

  resetControls(){
    this.qkp.quantityControl.setValue(this.qkp.data.QuoteKitPart.QuoteKitPart_Quantity);
    this.qkp.buildLocationControl.setValue(this.qkp.data?.BuildLocation?.BuildLocation_Code||'');
    
    if(this.qkp?.data?.QuotePart != null){
      this.qkp.costControl.setValue(this.qkp.data.QuotePart.QuotePart.QuotePart_Cost);
      this.qkp.phaseControl.setValue(this.qkp.data.QuoteKitPart.QuoteKitPart_Phase);
      this.qkp.tagsControl = new FormArray(this.qkp.data.QuotePart.QuotePartTags.map(tag=>new FormControl(tag.Tag.Tag_Name)));
    }else if(this.qkp?.data?.QuoteKit != null){
      this.qkp.nameControl.setValue(this.qkp.data.QuoteKit.QuoteKit.QuoteKit_Name);
      this.qkp.descControl.setValue(this.qkp.data.QuoteKit.QuoteKit.QuoteKit_Desc);
    }
    this.cdr.detectChanges();
  }

  generateCost() {
    if(this.qkp?.data?.QuotePart != null){
      this.cost = this.qkp.data.QuotePart.QuotePart.QuotePart_Cost * this.qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
    }else if(this.qkp?.data?.QuoteKit != null){
      this.cost = this.qe.getRawCost({quoteKitPart: this.qkp}) * this.qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
    }
  }

  generateTotalParts() {
    if(this.qkp.data.QuoteKit){
      // this.totalParts = this.getTotalParts(this?.qkp?.children) * this.qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
      this.totalParts = this.qe.getTotalParts({quoteKitPart: this.qkp});
    }else{
      this.totalParts = this.qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
    }
  }

  getTotalParts(children: QuoteKitPartEditableInterface[]){
    // Loop through data.children, and their children, adding all the quantities of the QuoteParts
    let totalParts = 0;
    for(let qkp of children){
      if(qkp.data.QuotePart){
        totalParts += qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
      }
      if(qkp.data.QuoteKit){
        totalParts += this.getTotalParts(qkp.children) * qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
      }
    }
    return totalParts;
  }
  
  getTotalCost(children: QuoteKitPartEditableInterface[]){
    // Loop through data.children, and their children, adding all the costs * the quantities of the QuoteParts
    let totalCost = 0;
    for(let qkp of children){
      if(qkp.data.QuotePart){
        totalCost += qkp.data.QuotePart.QuotePart.QuotePart_Cost * qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
      }
      if(qkp.data.QuoteKit){
        totalCost += this.getTotalCost(qkp.children) * qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
      }
    }
    return totalCost;
  }

  getSubkits(){
    return this.qkp.children.filter((child)=>child.data.QuoteKit != null);
  }

  getParts(){

    const tagValueProposition = {
      Fixture: 100,
      BTW: 90,
      Labor: 80,
    };

    return this.qkp.children
      .filter((child)=>child.data.QuotePart != null)
      .sort((a, b)=>{
        let aValue = 0;
        const atags = a.data.QuotePart.QuotePartTags.map(tag=>tag.Tag.Tag_Name);
        for(let tag of atags){
          const nval = tagValueProposition[tag]||0;
          if(nval > aValue){
            aValue = nval;
          }
        }

        let bValue = 0;
        const btags = b.data.QuotePart.QuotePartTags.map(tag=>tag.Tag.Tag_Name);
        for(let tag of btags){
          const nval = tagValueProposition[tag]||0;
          if(nval > bValue){
            bValue = nval;
          }
        }

        if(aValue==bValue){
          return a.data.QuoteKitPart.QuoteKitPart_Phase.localeCompare(b.data.QuoteKitPart.QuoteKitPart_Phase);
        }
        return bValue - aValue;
      });
  }

  selectedChanged(newSelected: boolean) {
    this.qkp.selected.setValue(newSelected);
  }

  quantityControlInput(e: InputEvent){
    // Test if e.value is a number
    if(!/^[0-9]*$/.test((e.target as HTMLInputElement).value)){
      const newValue = (e.target as HTMLInputElement).value.replace(/\D/g, '').slice(0, 4);
      // First, get the cursor position in the input
      const cursorPosStart = (e.target as HTMLInputElement).selectionStart-1;
      const cursorPosEnd = (e.target as HTMLInputElement).selectionEnd-1;

      (e.target as HTMLInputElement).value = newValue;
      this.qkp.quantityControl.setValue(newValue);
      // Now, set the cursor position back to cursorPos
      (e.target as HTMLInputElement).setSelectionRange(cursorPosStart, cursorPosEnd);
      setTimeout(()=>{
        // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
        (e.target as HTMLInputElement).setSelectionRange(cursorPosStart, cursorPosEnd);
      }, 0);
    }else{
      this.qkp.quantityControl.setValue(this.qkp.quantityControl.value.slice(0, 4));
    }
  }

  quoteKitNameInput(e: InputEvent){
    this.qkp.nameControl.setValue((e.target as HTMLInputElement).value);
  }

  costControlInput(e: InputEvent){
    const wholeValueMax = 8;
    const decimalValueMax = 4;

    const target = e.target as HTMLInputElement;

    // test is e.value has more than one period. If it does, remove all but the first one
    if(target.value.split('.').length > 2){
      const splitByPeriod = target.value.split('.');
      const firstTwo = splitByPeriod.slice(0, 2).join('.');
      const newValue = [firstTwo, ...splitByPeriod.slice(2)].join('');

      target.value = newValue;
      this.qkp.costControl.setValue(newValue);

      const cursorPosStart = newValue.split('.')[0].length+1;
      const cursorPosEnd = newValue.split('.')[0].length+1;
      target.setSelectionRange(cursorPosStart, cursorPosEnd);
      setTimeout(()=>{
        // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
        target.setSelectionRange(cursorPosStart, cursorPosEnd);
      }, 0);
    }

    // Test if e.value is a number
    if(!/^\d*\.?\d*$/.test(target.value)){
      let arrVal = target.value.replace(/\D/g, '').split('.');
      arrVal[0] = arrVal[0].slice(0, wholeValueMax);
      if(arrVal.length >= 2){
        arrVal[1] = arrVal[1].slice(0, decimalValueMax);
      }
      const newValue = arrVal.join('.');
      // First, get the cursor position in the input
      const cursorPosStart = target.selectionStart-1;
      const cursorPosEnd = target.selectionEnd-1;

      target.value = newValue;
      this.qkp.costControl.setValue(newValue);
      // Now, set the cursor position back to cursorPos
      target.setSelectionRange(cursorPosStart, cursorPosEnd);
      setTimeout(()=>{
        // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
        target.setSelectionRange(cursorPosStart, cursorPosEnd);
      }, 0);
    }else{
      let arrVal = this.qkp.costControl.value.split('.');
      arrVal[0] = arrVal[0].slice(0, wholeValueMax);
      if(arrVal.length >= 2){
        arrVal[1] = arrVal[1].slice(0, decimalValueMax);
      }
      const newValue = arrVal.join('.');
      this.qkp.costControl.setValue(newValue);
    }
  }
  
  costControlBlur(e){
    // If the costControl is empty, set it back to QuotePart_QuotePart_Cost
    if(`${this.qkp.costControl.value}`.trim() == ''){
      this.generateCost();
      this.generateTotalParts();
      this.qkp.costControl.setValue(this.qkp.data.QuotePart.QuotePart.QuotePart_Cost);
    }

    // Set the costControl value to the parseFloat of the value
    this.qkp.costControl.setValue(parseFloat(this.qkp.costControl.value));

    this.costControlVisible = false;
  }

  saveChanges() {
    // Make an if statement to check if the quantityControl or the phaseControl was changed, if it was, make a patch request for that
    if(
      this.qkp.data.QuoteKitPart?.QuoteKitPart_guid != null
      && (
        this.qkp.quantityControl.value != this.qkp.data?.QuoteKitPart?.QuoteKitPart_Quantity 
        || this.qkp.phaseControl.value != this.qkp.data?.QuoteKitPart?.QuoteKitPart_Phase
        || this.qkp.buildLocationControl.value != this.qkp.data?.BuildLocation?.BuildLocation_Code
      )
    ){
      const newBl = this.qe.getBuildLocation({buildLocationCode: this.qkp.buildLocationControl.value});
      this.qe.patchQuoteKitPart({
        quoteKitPart: this.qkp
      }).subscribe((data: QuoteKitPartDtoInterface)=>{
        this.qkp.data.QuoteKitPart = data;
        // I am not sending back the BuildLocation, so I need to set it back to the one that was sent
        this.qkp.data.BuildLocation = newBl;
        this.resetControls();
        this.generateCost();
        this.generateTotalParts();
        this.onSave.emit(data);
        this.cdr.detectChanges();
        this.qe.updated.next();
      }, (error)=>{
        console.log(error);
        this.cdr.detectChanges();
        this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
      });
    }

    // If the tagsControl was changed, make a put request for that
    if(this.tagsControlsChanged()){
      this.qe.putQuotePartTags({
        quoteKitPart: this.qkp
      }).subscribe((data: ContextQuotePartTagDtoInterface[])=>{
        this.resetControls();
        this.cdr.detectChanges();
      }, (error)=>{
        console.log(error);
        this.cdr.detectChanges();
        this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
      })
    }

    // if the cost control was changed, make a patch request for that too
    if(this.qkp.costControl.value != this.qkp.data?.QuotePart?.QuotePart?.QuotePart_Cost && this.qkp.data?.QuotePart != null){

      this.qe.changeQuotePart({
        quotePart: this.qkp
      }).subscribe((data: QuotePartDtoInterface)=>{
        this.qkp.data.QuotePart.QuotePart = data;
        this.resetControls();
        this.generateCost();
        this.generateTotalParts();
        this.onSave.emit(data);
        this.cdr.detectChanges();
      }, (error)=>{
        console.log(error);
        this.cdr.detectChanges();
        this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
      });
    }

    // if the nameControl was changed, make a patch request for that too
    if(this.qkp.data?.QuoteKit != null && this.qkp.data?.QuoteKit?.Kit?.Kit_guid != null &&  this.qkp.nameControl.value != this.qkp.data?.QuoteKit.QuoteKit.QuoteKit_Name){
      this.qe.patchQuoteKit({
        quoteKit: this.qkp.data.QuoteKit.QuoteKit
      }).subscribe((data: QuoteKitDtoInterface)=>{
        this.qkp.data.QuoteKit.QuoteKit = data;
        this.generateCost();
        this.generateTotalParts();
        this.onSave.emit(data);
        this.cdr.detectChanges();
        this.qe.updated.next();
      }, (error)=>{
        console.log(error);
        this.cdr.detectChanges();
        this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
      });
    }
    
    if(
      this?.qkp.data?.QuoteKit?.QuoteKit?.QuoteKit_guid 
      && (
        this.qkp.nameControl != null 
        && this?.qkp.data?.QuoteKit?.QuoteKit?.QuoteKit_Name != this.qkp.nameControl.value
      )
    ){
      this.qe.patchQuoteKit({
        quoteKit: this.qkp.data.QuoteKit.QuoteKit
      }).subscribe((data: QuoteKitDtoInterface)=>{
        this.qkp.data.QuoteKit.QuoteKit = data;
        this.generateCost();
        this.generateTotalParts();
        this.onSave.emit(data);
        this.cdr.detectChanges();
        this.qe.updated.next();
      }, (error)=>{
        console.log(error);
        this.cdr.detectChanges();
        this.snackBar.open('Failed to save changes', 'Close', {duration: Infinity});
      });
      
    }
    this.generateCost();
    this.generateTotalParts();
  }

  cancelChanges(){
    this.resetControls();
  }

  mOver(e: MouseEvent){
    // Needed so we dont tell the parent to add the hover class to it
    e.preventDefault();
    e.stopPropagation();
    this.isHovered = true;
  }

  mOut(e: MouseEvent){
    // Needed so we dont tell the parent to add the hover class to it
    e.preventDefault();
    e.stopPropagation();
    this.isHovered = false;
  }

  getTagColor(tagName?: string){
    if(tagName){
      return GetColorFromString(tagName);
    }
    return '#fff';
  }

  getBuildLocations(){
    return this.qe.getBuildLocations().map(bl=>bl.BuildLocation_Code);
  }

  buildLocationControlChange(e){
    this.qkp?.buildLocationControl.setValue(e.value); 
    this.isLocationPickerOpen=false; 
    this.cdr.detectChanges();
  }

  tagPickerControlChange(e){
    this.qkp.tagsControl.push(new FormControl(e.value));
    this.tagPickerOpen=false;
    this.cdr.detectChanges();
  }

  getAvailableTags(){
    const currTags = this.qkp.tagsControl.controls.map(t=>t.value);
    return this.qe.getTags().filter(tag=>currTags.findIndex(ctag=>ctag==tag.Tag_Name) == -1).map(t=>t.Tag_Name);
  }

  tagsControlsChanged(){
    return this.qkp.tagsControl.controls.map(t=>t.value).join(',') != this.qkp.data.QuotePart.QuotePartTags.map(t=>t.Tag.Tag_Name).join(',');
  }

  removeTag(tag: string){
    this.modals.confirm(`Remove ${tag}?`, `Really Remove ${tag} from ${this.qkp.data.QuotePart.QuotePart.QuotePart_Code}?`).subscribe((result: boolean) => {
      if(result){
        const index = this.qkp.tagsControl.controls.findIndex(t=>t.value==tag);
        this.qkp.tagsControl.removeAt(index);
        this.cdr.detectChanges();
      }
    });
  }

}