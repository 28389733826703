import { Component, OnInit, TemplateRef } from "@angular/core";
import { AlertifyService } from "../../../_services/alertify/alertify.service";
import { SageApiService } from "../../../_services/sageApi/sageApi.service";
import { AuthService } from "../../../_services/auth/auth.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { concat } from "rxjs";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { DatePipe } from "@angular/common";
import { formatDate } from "@angular/common";

@Component({
  selector: "app-history",
  templateUrl: "./pull-ticket-history.component.html",
  styleUrls: ["./pull-ticket-history.component.css"],
})
export class PullTicketHistoryComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig>;
  history = [];
  itemsToTransfer = [];
  DateScheduled: Date;
  FromDate: Date;
  ToDate: Date;
  pdfTodayDate: string;
  pdfTime: string;
  print: boolean;
  showMaterialsScheduled = false;
  materialsOnSchedule = [];
  MatLoading = false;
  loading = false;
  empty = false;
  dateFormatted = "";
  initialDate: string;
  initial: true;
  date = "";
  jobNumber = "";
  itemsByJobNumber = false;
  numberProjected = "";
  baseUrl: string;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    const today = new Date();
    this.historyOfPullSheets();
    this.pdfTodayDate = this.datePipe.transform(today, "MM/dd/yyyy");
    this.pdfTime = this.formatAMPM(today);
    this.DateScheduled = today;
    this.baseUrl = window.location.origin;
    //this.dateFormatted = this.datePipe.transform(today, "MM-dd-yyyy");
  }

  GetMaterial(FromDate: Date, ToDate: Date) {
    this.MatLoading = true;
    const endpoint = "PullSheet/ScheduledDate";
    const observables = [];
    const body = {
      FromDate: FromDate,
      ToDate: ToDate,
    };
    observables.push(this.sageApi.putRequest(endpoint, body));
    concat(...observables).subscribe(
      (arrays: Array<any>) => {
        if (Array.isArray(arrays)) {
          arrays.forEach((array) => {
            this.materialsOnSchedule.push(array);
          });
        }
      },
      (err) => {
        console.log(err);
        this.alertify.error("Error " + err.error);
      },
      () => {
        this.MatLoading = false;
      }
    );
    this.showMaterialsScheduled = true;
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  }
  historyOfPullSheets() {
    this.sageApi
      .pullReport("PullSheet/History")
      .subscribe((arrays: Array<any>) => {
        if (Array.isArray(arrays)) {
          arrays.forEach((array) => {
            this.history.push(array);
          });
        }
      });
  }

  printThePullSheets(FromDate: any, ToDate: any) {
    this.itemsToTransfer = [];
    this.loading = true;
    console.log(this.loading);
    this.print = false;
    var FromDateFormatted = formatDate(FromDate, "MM-dd-yyyy", "en-US");
    var ToDateFormatted = formatDate(ToDate, "MM-dd-yyyy", "en-US");

    this.dateFormatted =
      formatDate(FromDate, "MM/dd/yyyy", "en-US") +
      " - " +
      formatDate(ToDate, "MM/dd/yyyy", "en-US");
    this.sageApi
      .pullReport(
        "PullSheet/ScheduledDate/" + FromDateFormatted + "/" + ToDateFormatted
      )
      .subscribe(
        (arrays: Array<any>) => {
          if (Array.isArray(arrays)) {
            arrays.forEach((array) => {
              this.itemsToTransfer.push(array);
            });
          }
          this.loading = false;
          this.print = true;
        },
        (err) => {
          this.alertify.error(err.message);
          this.loading = false;
        }
      );
    this.itemsToTransfer.forEach((x) => {
      if (x.length !== 0) {
        this.empty = true;
      }
    });
  }
  printPage() {
    const today = new Date();
    this.pdfTodayDate = this.datePipe.transform(today, "MM/dd/yyyy");
    this.pdfTime = this.formatAMPM(today);
    window.print();
  }

  findJobByJobNumber(jobNumber: string) {
    this.itemsToTransfer = [];
    this.itemsByJobNumber = false;
    this.loading = true;
    this.print = false;
    this.empty = false;
    this.sageApi
      .pullReport("PullSheet/" + jobNumber)
      .subscribe((arrays: Array<any>) => {
        if (Array.isArray(arrays)) {
          if (arrays.length > 0) {
            this.itemsToTransfer.push(arrays);
          } else {
            this.itemsByJobNumber = true;
            this.empty = true;
          }
        }
      });
    this.loading = false;
    this.print = true;
    this.numberProjected = jobNumber;
  }
}
