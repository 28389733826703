import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AlertifyService } from "../../../_services/alertify/alertify.service";
import { SageApiService } from "../../../_services/sageApi/sageApi.service";
import { AuthService } from "../../../_services/auth/auth.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { concat } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";

export interface DialogData {
  superintendentList: SuperintendentList[];
}
@Component({
  selector: "app-subdivision-setup",
  templateUrl: "./subdivision-setup.component.html",
  styleUrls: ["./subdivision-setup.component.css"],
})
export class SubdivisionSetupComponent implements OnInit, AfterViewInit {
  isSubdivisionLoading = false;
  subdivisionList: SubdivisionList[] = [];
  superintendentList: SuperintendentList[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tableDisplayedColumns: string[] = [
    "name",
    "superintendentMain",
    "superintendentGround",
    "city",
    "gas",
    // "cityCrew",
    "cityInspection",
    "tnrccInspection",
    "action",
  ];
  dataSource: MatTableDataSource<SubdivisionList> | null;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadSubdivisionList();

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  saveSubdivision(element: SubdivisionList) {
    element.updating = true;

    console.log(element);

    const endpoint = "Subdivision/Update";
    const observables = [];
    const body: any = {
      Username: this.authService.decodedToken?.nameid.toLocaleLowerCase(),
      Subdivision: element.name,
    };

    if (element.origCity.toLowerCase() !== element.city.toLowerCase()) {
      body.City = element.city;
    }
    if (
      element.origSuperintendentMain.toLowerCase() !==
      element.superintendentMain.toLowerCase()
    ) {
      body.SuperintendentMain = element.superintendentMain;
    }
    if (
      element.origSuperintendentGround.toLowerCase() !==
      element.superintendentGround.toLowerCase()
    ) {
      body.SuperintendentGround = element.superintendentGround;
    }
    if (element.origGas !== element.gas) {
      body.Gas = element.gas;
    }
    if (element.origCityCrew !== element.cityCrew) {
      body.CityCrew = element.cityCrew;
    }
    if (element.origCityInspection !== element.cityInspection) {
      body.CityInspection = element.cityInspection;
    }
    if (element.origTnrccInspection !== element.tnrccInspection) {
      body.TnrccInspection = element.tnrccInspection;
    }

    observables.push(this.sageApi.putRequest(endpoint, body));

    concat(...observables).subscribe(
      (response: any) => {
        console.log("response", response);
      },
      (err) => {
        console.log(err)
        element.updating = false;
        this.alertify.error(err.message);
      },
      () => {
        element.origSuperintendentMain = element.superintendentMain;
        element.origSuperintendentGround = element.superintendentGround;
        element.origCity = element.city;
        element.origGas = element.gas;
        element.origCityCrew = element.cityCrew;
        element.origCityInspection = element.cityInspection;
        element.origTnrccInspection = element.tnrccInspection;
        element.updating = false;
        element.updated = true;
        this.alertify.success("Successfully updated " + element.name);
      }
    );
  }

  loadSuperintendentList() {
    const endpoint = "Leadman";

    this.sageApi.pullReport(endpoint).subscribe(
      (supers: SuperintendentList[]) => {
        this.superintendentList = supers;
      },
      (err) => {
        this.alertify.error(err.message);
      }
    );
  }

  loadSubdivisionList() {
    const endpoint = "Subdivision";
    this.isSubdivisionLoading = true;

    this.loadSuperintendentList();

    this.sageApi.pullReport(endpoint).subscribe(
      (subdivisions: ApiSubdivisionList[]) => {
        console.log(subdivisions);

        if (Array.isArray(subdivisions)) {
          subdivisions.forEach((sub) => {
            const gas = sub.UDF_GAS ?? "N";
            const city = sub.UDF_CITY_INSPECTIONS ?? "N";
            const tnrcc = sub.UDF_TNRCC_INSPECTIONS ?? "N";
            const cityCrew = sub.UDF_CITY_CREW ?? "N";

            const subdivision: SubdivisionList = {
              name: sub.UDF_SUBDIVISION_CODE,
              superintendentMain: sub.UDF_LEADMAN_CODE.toLocaleLowerCase(),
              origSuperintendentMain: sub.UDF_LEADMAN_CODE.toLocaleLowerCase(),
              superintendentGround:
                sub.UDF_RI_LEADMAN_CODE == undefined
                  ? sub.UDF_RI_LEADMAN_CODE
                  : sub.UDF_RI_LEADMAN_CODE.toLocaleLowerCase(),
              origSuperintendentGround:
                sub.UDF_RI_LEADMAN_CODE == undefined
                  ? sub.UDF_RI_LEADMAN_CODE
                  : sub.UDF_RI_LEADMAN_CODE.toLocaleLowerCase(),
              city: sub.UDF_CITY,
              origCity: sub.UDF_CITY,
              gas: gas.toLocaleLowerCase() === "y" ? true : false,
              origGas: gas.toLocaleLowerCase() === "y" ? true : false,
              cityCrew: cityCrew.toLocaleLowerCase() === "y" ? true : false,
              origCityCrew: cityCrew.toLocaleLowerCase() === "y" ? true : false,
              cityInspection: city.toLocaleLowerCase() === "y" ? true : false,
              origCityInspection:
                city.toLocaleLowerCase() === "y" ? true : false,
              tnrccInspection: tnrcc.toLocaleLowerCase() === "y" ? true : false,
              origTnrccInspection:
                tnrcc.toLocaleLowerCase() === "y" ? true : false,
              updating: false,
              updated: false,
            };

            this.subdivisionList.push(subdivision);
          });
        }
      },
      (err) => {
        console.log(err);
        this.isSubdivisionLoading = false;
        this.alertify.error(err.message);
      },
      () => {
        this.isSubdivisionLoading = false;
        this.dataSource = new MatTableDataSource(this.subdivisionList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddSubdivisionDialog, {
      height: "50%",
      data: {
        superintendentList: this.superintendentList,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == undefined) {
      } else if (result == "Success") {
        this.alertify.success("Created Subdivision!");
        this.refreshData();
      } else {
        this.alertify.warning("Error creating subdivision.");
        this.refreshData();
      }
    });
  }

  refreshData() {
    this.superintendentList = [];
    this.subdivisionList = [];
    this.loadSubdivisionList();
  }
}

interface SubdivisionList {
  name: string;
  superintendentMain: string;
  origSuperintendentMain: string;
  superintendentGround: string;
  origSuperintendentGround: string;
  city: string;
  origCity: string;
  gas: boolean;
  origGas: boolean;
  cityCrew: boolean;
  origCityCrew: boolean;
  cityInspection: boolean;
  origCityInspection: boolean;
  tnrccInspection: boolean;
  origTnrccInspection: boolean;
  updating: boolean;
  updated: boolean;
}

interface ApiSubdivisionList {
  UDF_SUBDIVISION_CODE: string;
  UDF_LEADMAN_CODE: string;
  UDF_CITY: string;
  UDF_GAS: string;
  UDF_CITY_INSPECTIONS: string;
  UDF_TNRCC_INSPECTIONS: string;
  UDF_RI_LEADMAN_CODE: string;
  UDF_CITY_CREW: string;
}

interface SuperintendentList {
  Leadman: string;
  UDF_LEADMAN: string;
  UDF_UDF_INITIALS: string;
}

@Component({
  selector: "dialog-overview-example-dialog",
  templateUrl: "AddSubdivision.html",
  styleUrls: ["./subdivision-setup.component.css"],
})
export class AddSubdivisionDialog {
  Super = "";
  city = "";
  subdivison = "";
  gas: boolean = false;
  // cityCrew: boolean = false;
  cityInspection: boolean = false;
  tnrcc: boolean = false;
  superList: SuperintendentList[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddSubdivisionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar,
    private sageApi: SageApiService,

  ) {
    this.test();
  }

  test() {
    this.superList = this.data.superintendentList;
    console.log(this.superList);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  checkData() {
    const Data = {
      Username: "",
      SuperintendentMain: this.Super,
      City: this.city,
      SuperintendentGround: this.Super,
      Subdivision: this.subdivison,
      Gas: this.gas,
      //After searching with Julia -- the cityCrew is not needed.
      // CityCrew: this.cityCrew,
      CityInspection: this.cityInspection,
      TnrccInspection: this.tnrcc,
    };
    console.log(Data);
    const endpoint = "Subdivision/AddNew";
    const observables = [];
    observables.push(this.sageApi.putRequest(endpoint, Data));

    concat(...observables).subscribe(
      (response: any) => {
        console.log("response", response);
        this.dialogRef.close(response);
      },
      (err) => {
        console.log(err);
        this.dialogRef.close(err);
      },
      () => {}
    );
  }
}
