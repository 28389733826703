
<div class="bg-dark text-light rounded px-2">
    <h1 mat-dialog-title>{{ data.ticket.address }} <small>{{ data.ticket.salesorderno + '-' + data.ticket.wtNumber }}</small></h1>
</div>

<mat-divider></mat-divider>

<mat-dialog-content>
    <div class="row pb-2">
        <div class="col-sm-12">
            <strong>Area Manager:</strong> {{ data.ticket.areaManager | titlecase }}
        </div>
        <div class="col-sm-12">
            <strong>Superintendent:</strong> {{ data.ticket.superintendent | titlecase }}
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <strong>Work Description</strong>
        </div>
        <div class="col-12">
            <p>{{ data.ticket.descriptionOfWork }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <strong>Explanation</strong>
        </div>
        <div class="col-12">
            <p>{{ data.ticket.typeComment }}</p>
        </div>
    </div>

    <mat-divider></mat-divider>

    <h3>{{ data.ticket.type | titlecase }} {{ data.slideName == 'review' ? '- Reveiw Details' : '' }}</h3>
    <div id="updateCharge" *ngIf="data.slideName == 'updateCharge'">
        <div class="row">
            <div class="col-12 text-center">
                <h4>Do you want to update the charge type?</h4>
            </div>
        </div>
    
        <div class="row">
            <div class="col-12 text-center mt-3 mb-3">
                <button mat-raised-button color="primary" class="mr-2" (click)="slideSelection('editCharge')">Yes</button>
                <button mat-raised-button color="accent" class="mr-2" (click)="slideSelection('payTech')">No</button>
            </div>
        </div>
    </div>

    <div id="editCharge" *ngIf="data.slideName == 'editCharge'">
        <div class="row">
            <div class="col-12 text-center mt-3 mb-3">
                <mat-form-field appearance="fill">
                    <mat-label>Select</mat-label>
                    <mat-select (selectionChange)="updateChargeType($event.value)">
                    <mat-option value="crew">Crew</mat-option>
                    <mat-option value="builder">Builder</mat-option>
                    <mat-option value="gibson">Gibson</mat-option>
                    <mat-option value="cancel">Cancel</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center mt-3 mb-3">
                <button mat-raised-button color="primary" class="mr-2" (click)="slideSelection('updateCharge')" (click)="data.ticket.editedFields.newType = ''">Back</button>
                <button mat-raised-button color="accent" class="mr-2" (click)="slideSelection('payTech')" *ngIf="data.ticket.editedFields.newType == 'crew'">Next</button>
                <button mat-raised-button color="accent" class="mr-2" (click)="slideSelection('payTech')" *ngIf="data.ticket.editedFields.newType == 'gibson'">Next</button>
                <button mat-raised-button color="accent" class="mr-2" (click)="slideSelection('payTech')" *ngIf="data.ticket.editedFields.newType == 'builder'">Next</button>
                <button mat-raised-button color="accent" class="mr-2" (click)="slideSelection('review')" *ngIf="data.ticket.editedFields.newType == 'cancel'">Finish</button>
            </div>
        </div>
    </div>

    <div id="payTech" *ngIf="data.slideName == 'payTech'">
        <div class="row">
            <div class="col-2">
                <button mat-raised-button (click)="slideSelection('updateCharge')">Back</button>
            </div>
            <div class="col-8 text-center">
                <h4>Do you want to pay <b>{{ data.ticket.assignedTech | titlecase }}</b>?</h4>
            </div>

        </div>

        <div class="row">
            <div class="col-12 text-center mt-3 mb-3">
                <button mat-raised-button color="primary" class="mr-2" (click)="slideSelection('addPayments')" (click)="getPreviousPayments()">Yes</button>
                <button mat-raised-button color="accent" (click)="slideSelection('backchargeCrew')" *ngIf="data.ticket.editedFields.newType == '' ? data.ticket.type.toLocaleLowerCase() == 'crew' : data.ticket.editedFields.newType == 'crew'">No</button>
                <button mat-raised-button color="accent" (click)="slideSelection('review')" *ngIf="data.ticket.editedFields.newType == '' ? data.ticket.type.toLocaleLowerCase() == 'gibson' : data.ticket.editedFields.newType == 'gibson'">No</button>
                <button mat-raised-button color="accent" (click)="slideSelection('updateBuilder')" *ngIf="data.ticket.editedFields.newType == '' ? data.ticket.type.toLocaleLowerCase() == 'builder' : data.ticket.editedFields.newType == 'builder'">No</button>
            </div>
        </div>
    </div>

    <div id="payTech" *ngIf="data.slideName == 'addPayments'">
        <div class="row">
            <div class="col-lg-4 order-3 order-lg-1">
                <ng-container *ngIf="!previousPayLinesLoading; else paymentsLoading">
                    <mat-list dense *ngIf="data.ticket.editedFields.previousPayLines.length > 0; else noPreviousPayments">
                        <div mat-subheader>
                            <span [matBadge]="data.ticket.editedFields.previousPayLines.length" matBadgeOverlap="false">Previous Payments</span>
                        </div>
                        <div class="overflow-auto" style="height: 200px;">
                            <mat-list-item *ngFor="let line of data.ticket.editedFields.previousPayLines; let paymentIndex = index;">
                                <div mat-line>
                                    <mat-icon mat-list-icon [ngClass]="{'text-success': line.techName.toLocaleLowerCase() === data.ticket.assignedTech.toLocaleLowerCase()}"> payments </mat-icon>
                                    {{ line.price | currency}} {{ line.techName }}
                                </div>
                                <div mat-line>
                                    {{ line.description }} on {{ line.payDate | date }}
                                </div>
                                <div mat-line *ngIf="line.womsComment !== ''">
                                    <mat-icon mat-list-icon> comment </mat-icon>
                                    {{ line.womsComment }}
                                </div>
                            </mat-list-item>
                        </div>
                    </mat-list>
                    <ng-template #noPreviousPayments>No Previous Payments</ng-template>
                </ng-container>
                <ng-template #paymentsLoading>Payments Are Loading...</ng-template>
            </div>
            <div class="col-lg-4 order-1 order-lg-2 text-center" id="paymentQuestions">
                <div class="col-12 text-center">
                    <h4>Add Pay Items for {{ data.ticket.assignedTech | titlecase }}</h4>
                </div>
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Pay Trip Charge For Amount</mat-label>
                        <input matInput type="number" [(ngModel)]="data.ticket.prtTripChargeRate">
                        <span matPrefix>$&nbsp;</span>
                        <span mat matSuffix>
                            <button mat-mini-fab color="primary" class="ml-3" (click)="addPayment('Trip Charge')">
                                <mat-icon>add</mat-icon>
                            </button>
                        </span>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Pay For Work Amount</mat-label>
                        <input matInput type="number" [(ngModel)]="paymentAmount">
                        <span matPrefix>$&nbsp;</span>
                        <span matSuffix>
                            <button mat-mini-fab color="primary" class="ml-3" (click)="addPayment('Payment')" [disabled]="paymentAmount <= 0">
                                <mat-icon>add</mat-icon>
                            </button>
                        </span>
                    </mat-form-field>
                </div>

                <div>
                    <button mat-raised-button color="primary" class="mr-2" (click)="slideSelection('payTech')">Back</button>
                    <button mat-raised-button color="accent" class="mr-2" (click)="slideSelection('backchargeCrew')" *ngIf="data.ticket.editedFields.newType == '' ? data.ticket.type.toLocaleLowerCase() == 'crew' : data.ticket.editedFields.newType == 'crew'">Next</button>
                    <button mat-raised-button color="accent" class="mr-2" (click)="slideSelection('review')" *ngIf="data.ticket.editedFields.newType == '' ? data.ticket.type.toLocaleLowerCase() == 'gibson' : data.ticket.editedFields.newType == 'gibson'">Next</button>
                    <button mat-raised-button color="accent" class="mr-2" (click)="slideSelection('updateBuilder')" *ngIf="data.ticket.editedFields.newType == '' ? data.ticket.type.toLocaleLowerCase() == 'builder' : data.ticket.editedFields.newType == 'builder'">Next</button>
                </div>
            </div>
            <div class="col-lg-4 order-2 order-lg-3" id="addedPayments">
                <mat-list dense *ngIf="data.ticket.editedFields.addedPayLines.length > 0">
                    <div mat-subheader>
                        <span [matBadge]="data.ticket.editedFields.addedPayLines.length" matBadgeOverlap="false">Pay Details</span>
                    </div>
                    <mat-list-item *ngFor="let line of data.ticket.editedFields.addedPayLines; let paymentIndex = index;">
                        <div mat-line>
                            <mat-icon mat-list-icon class="text-success"> payments </mat-icon>
                            {{ line.itemCode }} ${{ line.price }}
                            <mat-icon mat-list-icon class="text-warning" (click)="removePayment(paymentIndex)"> delete </mat-icon>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>

    <div id="backchargeCrew" *ngIf="data.slideName == 'backchargeCrew'">
        <div class="row">
            <div class="col-12 text-center">
                <h4>Do you want to backcharge a crew?</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center mt-3 mb-3">
                <button mat-raised-button color="primary" (click)="slideSelection('')" class="mr-2">Yes</button>
                <button mat-raised-button color="accent" (click)="slideSelection('updateBuilder')" *ngIf="data.ticket.type.toLocaleLowerCase() == 'builder'">No</button>
                <button mat-raised-button color="accent" (click)="slideSelection('review')" *ngIf="data.ticket.type.toLocaleLowerCase() == 'gibson'">No</button>
                <button mat-raised-button color="accent" (click)="slideSelection('review')" *ngIf="data.ticket.type.toLocaleLowerCase() == 'crew'">No</button>

            </div>
        </div>
    </div>

    <div id="updateBuilder" *ngIf="data.slideName == 'updateBuilder'">
        <div class="row">
            <div class="col-12 text-center">
                <h4>Update Builder Information</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center mt-3 mb-3">
                <button mat-raised-button color="primary" (click)="slideSelection('')" class="mr-2">Back</button>
                <button mat-raised-button color="accent" (click)="slideSelection('review')">Sumbit</button>
            </div>
        </div>
    </div>

    <div id="reveiw" *ngIf="data.slideName == 'review'">
        <div class="row">
            <div class="col-12">
                <h4></h4>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <mat-list>
                    <div mat-subheader>Details</div>
                    <mat-list-item>
                      <div mat-line> New Type: {{ data.ticket.editedFields.newType }}</div>
                      <div mat-line> {{ 'second line' }} </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <div mat-subheader>Pay Details</div>
                    <mat-list-item *ngFor="let line of data.ticket.editedFields.addedPayLines">
                      <mat-icon mat-list-icon class="text-success"> payments </mat-icon>
                      <div mat-line>{{ line.itemCode }} {{ line.price | currency }}</div>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>



</mat-dialog-content>

<mat-divider *ngIf="data.slideName == 'review'"></mat-divider>

<mat-dialog-actions align="end" *ngIf="data.slideName == 'review'">
    <button mat-button class="bg-secondary">Back</button>
    <button mat-button class="bg-warning text-light" mat-dialog-close >Cancel</button>
    <button mat-button class="bg-primary text-light" [mat-dialog-close]="true" cdkFocusInitial>Confirm</button>
</mat-dialog-actions>
