import ContextKitDtoInterface from "../_services/sageApi/interfaces/pullReport/ContextKitDtoInterface";
import ContextKitPartDtoInterface from "../_services/sageApi/interfaces/pullReport/ContextKitPartDtoInterface";

export const getImmediateSubkits = (kit: ContextKitDtoInterface) => {
  return kit.KitParts.filter((part) => {
    return part.Part == undefined;
  });
};

export const getImmediateKitParts = (kit: ContextKitDtoInterface) => {
  return kit.KitParts.filter((part) => {
    return part.Part != undefined;
  });
};

export const getFullKitParts = (kit: ContextKitDtoInterface) => {
    let parts: ContextKitPartDtoInterface[] = [];
    kit.KitParts.forEach((part) => {
      if (part?.Part != null) {
        parts.push(part);
      } else if (part?.Kit != null) {
        parts = parts.concat(getFullKitParts(part.Kit));
      }
    });
    return parts;
  };

export const getKitCost = (kit: ContextKitDtoInterface) => {
  let total = 0;
  kit.KitParts.forEach((part) => {
    if (part?.Part != null) {
      total += part.Part.Part.Part_Cost * part.KitPart.KitPart_Quantity;
    } else if (part?.Kit != null) {
      total += getKitCost(part.Kit) * part.KitPart.KitPart_Quantity;
    }
  });
  return total;
};

export const getKitTotalParts = (kit: ContextKitDtoInterface) => {
    let total = 0;
    kit.KitParts.forEach((part) => {
        if (part?.Part != null) {
            total += part.KitPart.KitPart_Quantity;
        } else if (part?.Kit != null) {
            const subkitTotal = getKitTotalParts(part.Kit);
            total += subkitTotal * part.KitPart.KitPart_Quantity;
        }
    });
    return total;
}

export const removeGuidPortionFromKitErrorMessage = (str: string) => {
    return str.replace(/\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/, '').replace(/::/g, '');
}

class KitUtil {
  public getImmediateSubkits = getImmediateSubkits;
  public getImmediateKitParts = getImmediateKitParts;
  public getFullKitParts = getFullKitParts;
  public getKitCost = getKitCost;
  public getKitTotalParts = getKitTotalParts;
  public removeGuidPortionFromKitErrorMessage = removeGuidPortionFromKitErrorMessage;
}
export default KitUtil;