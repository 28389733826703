import { Component, OnInit, TemplateRef } from "@angular/core";
import { concat } from "rxjs";
import { AlertifyService } from "../../../_services/alertify/alertify.service";
import { AuthService } from "../../../_services/auth/auth.service";
import { SageApiService } from "../../../_services/sageApi/sageApi.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-edit-worktickets",
  templateUrl: "./edit-worktickets.component.html",
  styleUrls: ["./edit-worktickets.component.css"],
})
export class EditWorkticketsComponent implements OnInit {
  isOverShipped = false;
  isDropup = true;
  modalTemplate: BsModalRef;
  itemCollection = [];
  itemList = [];
  kitNames = [];
  wtNumber = "";
  dataWTNum = "";
  submissionkey = "";
  loading = false;
  sortedBy: string;
  sortedBy2: string;
  StatusList: {
    stat: string;
    desc: string;
  }[] = [
    {
      stat: "NEW",
      desc: "New Ticket",
    },
    {
      stat: "LW",
      desc: "Left Warehouse",
    },
    {
      stat: "PIW",
      desc: "Pulled-In Warehouse",
    },
  ];

  processedList: {
    stat: string;
  }[] = [
    {
      stat: "New",
    },
    {
      stat: "Check",
    },
    {
      stat: "Error",
    },
    {
      stat: "Exempt",
    },
    {
      stat: "Hold",
    },
  ];

  filerProc = "New";
  filerSO = "";
  filerItem = "";

  pdfTodayDate: string;
  pdfTime: string;

  selectedWTEditName = "WT Editor";
  selectionWTEdit = true;
  selectionWTSubmitDelete = false;

  matSubList = [];

  constructor(
    private alertify: AlertifyService,
    public authService: AuthService,
    private sageApi: SageApiService,
    private modalService: BsModalService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    const today = new Date();
    this.filerSO = "";
    this.filerItem = "";
    this.pdfTodayDate = this.datePipe.transform(today, "MM/dd/yyyy");
    this.pdfTime = this.formatAMPM(today);
    //this.pullSageItemList();
    this.getKitNames();
  }

  moveTemplateTab(tabName: string) {
    switch (tabName.toLowerCase()) {
      case "editor":
        this.selectedWTEditName = "WT Editor";
        this.selectionWTEdit = true;
        this.selectionWTSubmitDelete = false;
        break;
      case "subdelete":
        this.selectedWTEditName = "Submission Deletion Table";
        this.selectionWTEdit = false;
        this.selectionWTSubmitDelete = true;
        this.pullSubList();
        break;
    }
  }

  setFilter(index, itemgroup) {
    // @ts-ignore
    itemgroup.value[0]?.newStatus = this.StatusList[index].stat;
    console.log("New Stat:", itemgroup.value[0]?.newStatus);
  }

  filerSettings(tick: any) {
    return (
      !tick.newStatus &&
      tick.SageProcessed === this.filerProc &&
      tick.SalesOrderNo.includes(this.filerSO) &&
      tick.ItemCode.toLocaleLowerCase().includes(
        this.filerItem.toLocaleLowerCase()
      )
    );
  }

  onWTKeyUp(event) {
    if (event.keyCode === 13) {
      // this.pullMaterial();
    } else if (event.keyCode === 8 || event.keyCode === 46) {
      return;
    } else {
      const filteredTicket = this.wtNumber
        ? this.wtNumber.replace(/-/g, "")
        : this.wtNumber;
      if (filteredTicket.length > 6 && filteredTicket.length <= 11) {
        this.wtNumber =
          filteredTicket.substr(0, 7) + "-" + filteredTicket.substr(7);
        this.wtNumber = this.wtNumber.substr(0, 11);
      } else {
        this.wtNumber = filteredTicket;
      }
    }
  }

  sortTickets(wtNumber: string, keyToSortBy: any) {
    const arr = this.itemCollection[wtNumber];
    this.sortedBy = keyToSortBy.toString();

    arr.sort((a, b) => {
      let ascDirection = true; // sort numbers in ascending
      let ItemOne = a[keyToSortBy];
      let ItemTwo = b[keyToSortBy];

      if (typeof a[keyToSortBy] === "string") {
        ItemOne = a[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ItemTwo = b[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ascDirection = true; // sort strings ascendingly
      }

      if (ItemOne < ItemTwo) {
        return ascDirection ? -1 : 1;
      }
      if (ItemOne > ItemTwo) {
        return ascDirection ? 1 : -1;
      }
      // items must be equal
      return 0;
    });
  }

  sortTickets2(keyToSortBy: any) {
    const arr = this.matSubList;
    this.sortedBy2 = keyToSortBy.toString();

    arr.sort((a, b) => {
      let ascDirection = true; // sort numbers in ascending
      let ItemOne = a[keyToSortBy];
      let ItemTwo = b[keyToSortBy];

      if (typeof a[keyToSortBy] === "string") {
        ItemOne = a[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ItemTwo = b[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ascDirection = true; // sort strings ascendingly
      }

      if (ItemOne < ItemTwo) {
        return ascDirection ? -1 : 1;
      }
      if (ItemOne > ItemTwo) {
        return ascDirection ? 1 : -1;
      }
      // items must be equal
      return 0;
    });
  }

  DeleteSub() {
    const SubmissionKeyList = [];

    this.matSubList.forEach((item) => {
      if (item.deleteselected) {
        SubmissionKeyList.push(item.SubmissionKey);
      }
    });

    const observables = [];
    const endpoint = "UpdMatUsage/deleteSubScript";
    const object = {
      username: this.authService.decodedToken.nameid,
      password:
        this.authService.decodedToken[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication"
        ],
      SubmissionKeyList,
    };
    console.log(object);

    observables.push(this.sageApi.putRequest(endpoint, object));

    concat(...observables).subscribe(
      (response: any) => {
        console.log("response", response);
      },
      (err) => {
        console.log(err);
        this.loading = false;
        if (err.error !== null) {
          this.alertify.error(err.error.text);
        }
      },
      () => {
        this.matSubList.forEach((item) => {
          if (item.deleteselected === true) {
            item.newStatus = "Deleted";
            item.deleteselected = false;
          }
        });
        this.loading = false;
        this.closeModal();
        this.alertify.success("Submission Removed");
      }
    );
  }

  lineEdited(wtNumber: string, index: number) {
    const checkQtyOrdered =
      this.itemCollection[wtNumber][index].QuantityOrdered !==
      this.itemCollection[wtNumber][index].newQtyOrdered;
    const checkQtyShipped =
      this.itemCollection[wtNumber][index].QuantityShipped !==
      this.itemCollection[wtNumber][index].newQtyShipped;
    const checkQtyBackordered =
      this.itemCollection[wtNumber][index].QuantityBackordered !==
      this.itemCollection[wtNumber][index].newQuantityBackordered;
    const checkCommentText =
      this.itemCollection[wtNumber][index].CommentText !==
      this.itemCollection[wtNumber][index].newCommentText;

    if (
      checkQtyOrdered ||
      checkQtyShipped ||
      checkCommentText ||
      checkQtyBackordered
    ) {
      this.itemCollection[wtNumber][index].edited = true;
    }
    this.checkForOverShip(wtNumber);
  }

  showUpdatedTicket(wtNumber: string) {
    this.itemCollection[wtNumber][0].updated = false;
  }

  addLineItem(wtNumber: string) {
    if (
      this.itemCollection[wtNumber][this.itemCollection[wtNumber].length - 1]
        .ItemCode === ""
    ) {
      return;
    }

    const item = {
      CommentText: "",
      ExtendedDescriptionKey: "",
      ItemCode: "",
      ItemCodeDesc: "",
      JT158_WTNumber: this.itemCollection[wtNumber][0].JT158_WTNumber,
      JT158_WTParentLineKey:
        this.itemCollection[wtNumber][0].JT158_WTParentLineKey,
      QuantityOrdered: 0,
      QuantityShipped: 0,
      QuantityBackordered: 0,
      QuantityOnHand: 0,
      SalesOrderNo: this.itemCollection[wtNumber][0].SalesOrderNo,
      ShipToName: this.itemCollection[wtNumber][0].ShipToName,
      UDF_LEADMAN: "",
      UDF_LOCATION: "",
      newQtyOrdered: 0,
      newQtyShipped: 0,
      newQtyBackordered: 0,
      newCommentText: "",
      updated: false,
      edited: false,
      added: true,
    };
    this.itemCollection[wtNumber].push(item);
  }

  removeLineItem(wtNumber: string) {
    this.itemCollection[wtNumber].pop();
  }

  printPage() {
    const today = new Date();
    this.pdfTodayDate = this.datePipe.transform(today, "MM/dd/yyyy");
    // this.pdfTime = today.getHours() + ':' + today.getMinutes();
    this.pdfTime = this.formatAMPM(today);
    window.print();
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  }

  pullSageItemList(SalesOrder: string) {
    this.sageApi.pullReport("ItemMaterialByWarehouse/" + SalesOrder).subscribe(
      (items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach((item) => {
            const obj = {
              QuantityOnHand: item.Total_Quantity,
              ItemCode: item.ItemCode,
            };

            this.itemList.push(obj);
          });
          console.log("itemList", this.itemList);
        }
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }

  setItem(item, itemgroup) {
    var itemFound = this.itemList.find((x) => x.ItemCode === item.ItemCode);
    if (itemFound == "" || itemFound == undefined) {
      if (item.ItemCode != "") {
        this.alertify.warning(item.ItemCode + " is not a valid part.");
      }
      this.itemCollection[itemgroup].forEach((element) => {
        if (element.ItemCode == item.ItemCode) {
          element.ItemCode = "";
        }
      });
    } else {
      var loc = this.itemCollection[itemgroup].findIndex(
        (x) => x.ItemCode == itemFound.ItemCode
      );
      this.itemCollection[itemgroup].forEach((element) => {
        if (element.ItemCode == itemFound.ItemCode) {
          element.TotalQuantityOnHand = itemFound.QuantityOnHand;
        }
      });
    }
  }

  pullMaterial() {
    if (this.wtNumber.length !== 11) {
      return;
    }

    if (this.itemCollection.hasOwnProperty(this.wtNumber)) {
      this.itemCollection[this.wtNumber] = [];
    }

    this.loading = true;
    const soNum = this.wtNumber.split("-")[0];
    const wtNum = this.wtNumber.split("-")[1];

    this.sageApi
      .pullReport(`UpdMatUsage?soNum=${soNum}&wtNum=${wtNum}`)
      .subscribe(
        (items: Array<any>) => {
          if (Array.isArray(items)) {
            items.forEach((item) => {
              item.newQtyOrdered = item.QuantityOrdered;
              item.newCommentText = item.CommentText;
              item.loading = false;
              item.updated = false;
              item.edited = false;
              item.added = false;
              item.newStatus = item.StatusCode;
              //Chris advised that backordering items would cause an issue.
              // if(item.newQtyOrdered > item.TotalQuantityOnHand){
              //   //If the item qty ordered is greater than the quantity on hand then we need to backorder the amount above it.
              //   var amountToBackOrder = item.TotalQuantityOnHand - item.newQtyOrdered;
              //   if(amountToBackOrder < 0){
              //     amountToBackOrder = Math.abs(amountToBackOrder);
              //   }
              //   item.newQtyShipped = item.TotalQuantityOnHand;
              //   item.newQtyBackordered = amountToBackOrder;
              //   item.edited = true;

              // }
              // else{
              item.newQtyShipped = item.QuantityShipped;
              item.newQtyBackordered = item.QuantityBackordered;

              if (!this.itemCollection.hasOwnProperty(this.wtNumber)) {
                this.itemCollection[this.wtNumber] = [item];
              } else {
                this.itemCollection[this.wtNumber].push(item);
              }
            });
            this.checkForOverShip(this.wtNumber);
            console.log(this.itemCollection);
          }
        },
        (err) => {
          this.loading = false;
          this.alertify.error(
            "Error loading " + this.wtNumber + ": " + err.error.Message
          );
          console.log(err);
        },
        () => {
          this.loading = false;
          this.wtNumber = "";
        }
      );

    this.pullSageItemList(soNum);
  }

  pullSubList() {
    this.loading = true;
    this.matSubList = [];
    this.sageApi.pullReport(`UpdMatUsage/SubmissionList`).subscribe(
      (items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach((item) => {
            item.updated = false;
            item.newStatus = item.StatusCode;
            item.deleteselected = false;

            // tslint:disable-next-line:triple-equals
            if (this.matSubList == []) {
              this.matSubList = [item];
            } else {
              this.matSubList.push(item);
            }
          });

          this.matSubList.sort((a, b) => {
            return b.DateCreated - a.DateCreated;
          });
          console.log(this.matSubList);
        }
      },
      (err) => {
        this.loading = false;
        this.alertify.error(
          "Error loading Material Submission list: " + err.error.Message
        );
        console.log(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  cleanDeletes(stat: string) {
    this.filerProc = stat;
    this.matSubList.forEach((item) => {
      item.deleteselected = false;
    });
  }

  SelectAll() {
    this.matSubList.forEach((item) => {
      if (
        item.SageProcessed === this.filerProc &&
        item.newStatus !== "Deleted"
      ) {
        item.deleteselected = true;
      }
    });
  }

  OverShipWarning(item: any) {
    return (
      item.TotalQuantityOnHand - (item.newQtyOrdered + item.newQtyShipped) > 0
    );
  }

  OverShip(item: any) {
    if (item.QuantityShipped != item.newQtyShipped) {
      return item.TotalQuantityOnHand < item.newQtyShipped;
    }
  }

  BackAndShip(item: any) {
    return (
      item.newQtyOrdered < item.newQtyOrdered + item.newQtyBackordered &&
      item.newQtyOrdered !== 0
    );
  }

  backorderCheck() {
    const foundbackorder = false;
  }

  updateMaterial(wtNumber: string) {
    this.closeModal();
    if (
      this.itemCollection[wtNumber][this.itemCollection[wtNumber].length - 1]
        .ItemCode === ""
    ) {
      this.alertify.error("Error : Please Enter an ItemCode");
      return;
    }

    this.itemCollection[wtNumber][0].updated = true;
    this.itemCollection[wtNumber][0].loading = true;

    const soNum = wtNumber.split("-")[0];
    const wtNum = wtNumber.split("-")[1];

    const observables = [];
    const editedLinesArr = [];
    const addedLinesArr = [];
    const endpoint = "UpdMatUsageSubmit/App";
    let foundError = false;
    let faillist =
      "ERROR: Quantity mismatech for the following parts - (The offending lines will be marked in Blue): ";

    this.itemCollection[wtNumber].forEach((item) => {
      if (item.added && item.edited) {
        console.log(
          item.ItemCode,
          item.newQtyOrdered < item.newQtyShipped + item.newQtyBackordered &&
            item.newQtyOrdered !== 0
        );
        console.log(
          item.ItemCode,
          item.newQtyOrdered,
          item.newQtyShipped,
          item.newQtyBackordered
        );
        if (
          item.newQtyOrdered < item.newQtyShipped + item.newQtyBackordered &&
          item.quantityOrdered !== 0
        ) {
          foundError = true;
          faillist = faillist + item.ItemCode + ", ";
        }
        const arr = [
          item.ItemCode, // 0
          item.newQtyOrdered, // 1
          item.newQtyShipped, // 2
          wtNum, // 3
          item.JT158_WTParentLineKey, // 4
          item.newCommentText, // 5
          item.newQtyBackordered, // 6
        ];
        console.log(arr);
        addedLinesArr.push(arr);
      } else if (item.edited) {
        console.log(
          item.ItemCode,
          item.newQtyOrdered < item.newQtyShipped + item.newQtyBackordered &&
            item.newQtyOrdered !== 0
        );
        console.log(
          item.ItemCode,
          item.newQtyOrdered,
          item.newQtyShipped,
          item.newQtyBackordered
        );
        if (
          item.newQtyOrdered < item.newQtyShipped + item.newQtyBackordered &&
          item.newQtyOrdered !== 0
        ) {
          foundError = true;
          faillist = faillist + item.ItemCode + ", ";
        }
        const arr = [
          item.LineKey, // 0
          item.newQtyOrdered, // 1
          item.newQtyShipped, // 2
          item.newCommentText, // 3
          item.newQtyBackordered, // 4
          item.JT158_WTParentLineKey, // 5
          item.ItemCode, // 6
        ];
        editedLinesArr.push(arr);
      }
    });
    console.log("itemCollection", this.itemCollection[wtNumber]);

    const object = {
      username: this.authService.decodedToken.nameid,
      password:
        this.authService.decodedToken[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication"
        ],
      salesOrderNo: soNum,
      SystemOperation: "WT Edit",
      Status:
        this.itemCollection[wtNumber][0].newStatus ===
        this.itemCollection[wtNumber][0].Status
          ? "none"
          : this.itemCollection[wtNumber][0].newStatus,
      wtNum,
      editedLines: editedLinesArr,
      addedLines: addedLinesArr,
    };
    console.log(object);
    if (foundError) {
      this.itemCollection[wtNumber][0].updated = false;
      this.itemCollection[wtNumber][0].loading = false;
      console.log(faillist);
      this.alertify.error(faillist);
      return;
    }

    observables.push(this.sageApi.putRequest(endpoint, object));

    concat(...observables).subscribe(
      (response: any) => {
        console.log("response", response);
        // if (response.Response.ReasonPhrase !== '') {
        // this.alertify.message('Successful with the following message ' + response.Response.ReasonPhrase, 0);
        // } else {
        this.alertify.success("Success");
        // }
      },
      (err) => {
        console.log(err);
        // element.updating = false;
        this.itemCollection[wtNumber][0].loading = false;
        if (err.error !== null) {
          this.alertify.error(err.error.text);
        }
        // this.alertify.error('Error on ' + wtNumber + ': ' + err.error.Message);
      },
      () => {
        this.resetTicket(wtNumber);
        this.alertify.success("Material Updated on " + wtNumber);
      }
    );
  }

  resetTicket(wtNumber: string) {
    delete this.itemCollection[wtNumber];
  }

  resetAll() {
    this.itemCollection = [];
  }

  openModal(template: TemplateRef<any>, wtNumber: string) {
    this.dataWTNum = wtNumber;
    this.modalTemplate = this.modalService.show(template);
  }
  openModal2(template: TemplateRef<any>) {
    // this.submissionkey = subkey;
    this.modalTemplate = this.modalService.show(template);
  }
  closeModal() {
    this.modalTemplate.hide();
  }

  shipAllItems() {
    this.itemCollection[this.dataWTNum].forEach((item) => {
      // if (item.newQtyOrdered !== item.newQtyShipped) {
      if (
        item.newQtyOrdered !== item.newQtyShipped &&
        item.JT158_WTParentLineKey !== undefined &&
        item.JT158_WTParentLineKey !== "" &&
        !item.JT158_WTParentLineKey.includes(item.LineKey)
      ) {
        if (
          !item.ItemCode.startsWith("/") &&
          !this.kitNames.includes(item.ItemCode) &&
          !item.ItemCode.startsWith("KIT")
        ) {
          item.newQtyShipped = item.newQtyOrdered;
          item.edited = true;
        }
      }
      //Chris advised that automatically backordering items would cause an issue.
      // if (item.newQtyOrdered > item.TotalQuantityOnHand) {
      //   var amountToBackOrder = item.TotalQuantityOnHand - item.newQtyOrdered;
      //   item.newQtyShipped = item.TotalQuantityOnHand;
      //   if (amountToBackOrder < 0) {
      //     amountToBackOrder = Math.abs(amountToBackOrder);
      //   }
      //   item.newQtyBackordered = amountToBackOrder;
      //   item.edited = true;
      // }
    });
    this.closeModal();
    this.checkForOverShip(this.dataWTNum);
  }

  unshipAllItems() {
    this.itemCollection[this.dataWTNum].forEach((item) => {
      if (
        item.newQtyShipped !== 0 &&
        item.JT158_WTParentLineKey !== undefined &&
        item.JT158_WTParentLineKey !== "" &&
        !item.JT158_WTParentLineKey.includes(item.LineKey)
      ) {
        item.newQtyShipped = 0;
        item.edited = true;
      }
    });
    this.closeModal();
    this.checkForOverShip(this.dataWTNum);
  }

  checkForOverShip(wtNumber: string) {
    var overShip = false;

    this.itemCollection[wtNumber].forEach((item) => {
      if (item.added == true) {
        var itemFound = this.itemList.find((x) => x.ItemCode === item.ItemCode);
        if (itemFound == "" || itemFound == undefined) {
          if (item.ItemCode != "") {
            this.alertify.warning(item.ItemCode + " is not a valid part.");
          }
          this.itemCollection[wtNumber].forEach((element) => {
            if (element.ItemCode == item.ItemCode) {
              element.ItemCode = "";
              element.TotalQuantityOnHand = 0;
            }
          });
        }
      }
      if (item.JT158_WTParent == "N" || item.added == true) {
        if (item.edited == true) {
          if (item.QuantityShipped != item.newQtyShipped) {
            if (item.newQtyShipped > item.TotalQuantityOnHand) {
              this.alertify.error(
                "Unable to ship " +
                  item.ItemCode +
                  " for " +
                  wtNumber +
                  ". \nRequested Ship Amount: " +
                  item.newQtyShipped +
                  "\nAmount in Stock: " +
                  item.TotalQuantityOnHand
              );
              overShip = true;
            }
          }
        }
      }
    });

    this.isOverShipped = overShip;
  }
  getKitNames() {
    this.sageApi.pullReport("KitName/").subscribe(
      (kits: Array<any>) => {
        if (Array.isArray(kits)) {
          kits.forEach((kit) => {
            this.kitNames.push(kit.BillNo);
          });
        }
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }
}
