<div class="container">
  <div class="card border-1 m-3 shadow-lg" id="web-view">
    <ng-container *ngIf="(authService.decodedToken.role === 'admin' || authService.decodedToken.role === 'superadmin'
    || authService.decodedToken.role === 'mat_mgt')">
      <div class="row px-3 pt-3 pb-4 d-flex justify-content-center">
        <ng-container>
          <div class="col-5">
            <div class="list-group list-group-horizontal">
              <button [disabled]="selectionWTEdit"
                class="list-group-item list-group-item-action px-0 flex-fill text-center"
                (click)="moveTemplateTab('editor')">WT Editor
              </button>
            </div>
          </div>
          <div class="col-5">
            <div class="list-group list-group-horizontal">
              <button [disabled]="selectionWTSubmitDelete"
                class="list-group-item list-group-item-action px-0 flex-fill text-center"
                (click)="moveTemplateTab('subdelete')">Submission Deletion Table
              </button>
            </div>
          </div>
        </ng-container>
        <!-- * BEGINNING of Moving Tab -->
        <div class="moving-selection-tab position-absolute col-6 p-0" style="z-index: 4;"
          [ngClass]="{'offset1': selectionWTEdit, 'offset5' : selectionWTSubmitDelete }">
          <ul class="list-group list-group-horizontal list-unstyled">
            <li class="text-center py-3 flex-fill bg-primary text-light h5 mb-0"> {{ selectedWTEditName }} </li>
          </ul>
        </div>
        <!-- * END of Moving Tab -->
      </div>
    </ng-container>

    <ng-container *ngIf="selectionWTEdit">
      <div class="card-header mt-3">
        <form class="form-inline">
          <div class="form-group row mx-sm-3 mb-2">
            <label for="wtnumber" class="col-sm-4 col-form-label">WT Number</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" id="wtnumber" name="wtnumber" [(ngModel)]="wtNumber"
                placeholder="WT Number" maxlength="11" (keyup)="onWTKeyUp($event)">
            </div>
          </div>
          <button type="submit" class="btn btn-primary mb-2" (click)="pullMaterial()"><span *ngIf="!loading">Load WT
              Items</span><span *ngIf="loading"><i class="fas fa-sync fa-spin"></i></span></button>
          <button type="button" class="btn btn-outline-primary ml-2 mb-2" (click)="resetAll()">Reset All
            Tickets</button>
        </form>
      </div>
      <div class="card-body border-0">
        <div *ngFor="let itemgroup of itemCollection | keyvalue; let i = index" class="workTicket">
          <div id="headerbox">
            <div>
              <h4>
                {{ itemgroup.value[0]?.ShipToName }} ----- Current Status: {{itemgroup.value[0]?.StatusCode}}
                <h6>{{ itemgroup.key }}</h6>
              </h4>
            </div>
            <div>
              <span>New Status: </span>
              <div class="btn-group" dropdown [dropup]="isDropup">
                <button type="button" dropdownToggle class="btn btn-outline-dark">{{itemgroup.value[0]?.newStatus}}<span
                    class="caret"><i class="fas fa-caret-down"></i></span></button>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                  <li role="menuitem" *ngFor="let filt of StatusList; index as index">
                    <a (click)="setFilter(index, itemgroup)" class="dropdown-item" style="cursor: pointer;">
                      <span>{{filt.stat}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <table class="table table-sm table-striped"
            [ngStyle]="{ 'display': itemgroup.value[0]?.updated ? 'none' : '' }">
            <thead class="thead-dark">
              <tr>
                <th scope="col" style="display: none;">#</th>
                <th scope="col" (click)="sortTickets(itemgroup.key, 'ItemCode')">Item <span
                    *ngIf="sortedBy === 'ItemCode'"><i class="fas fa-wrench"></i></span></th>
                <th scope="col">Desc</th>
                <th scope="col" (click)="sortTickets(itemgroup.key, 'UDF_LOCATION')">Location <span
                    *ngIf="sortedBy === 'UDF_LOCATION'"><i class="fas fa-wrench"></i></span></th>
                <th scope="col" class="col-3">Comment</th>
                <th scope="col">Ordered/ Required</th>
                <th scope="col">Shipped</th>
                <th scope="col">Backorder</th>
                <th scope="col">On Hand</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of itemgroup.value; let index = index">
                <!--              <tr [ngStyle]="{'display': item.ItemCode.substr(0, 1) === 'Z' ? 'none' : ''}" >-->
                <tr
                  [ngStyle]="{'display': item.LineKey === item.JT158_WTParentLineKey ? 'none' : '', 'background-color': BackAndShip(item) ? 'blue' : OverShip(item) ? 'red' : OverShipWarning(item) ? '' : 'yellow', 'color': BackAndShip(item) ? 'white' : OverShip(item) ? 'white' : OverShipWarning(item) ? '' : 'black' }">
                  <th scope="row" style="display: none;">{{ index + 1 }}</th>
                  <td *ngIf="!item.added">{{ item.ItemCode }}</td>

                  <td *ngIf="item.added">

                    <input class="form-control form-control-sm" style="width: 100px;" [(ngModel)]="item.ItemCode"
                      type="text" [typeahead]="itemList" typeaheadOptionField="ItemCode" placeholder="Item Code"
                      autocomplete="off" (typeaheadOnSelect)="setItem(item, itemgroup.key)" />
                  </td>
                  <td>{{item.ItemCodeDesc}}</td>
                  <td>{{item.UDF_LOCATION}}</td>
                  <td><input class="form-control form-control-sm" type="text" maxlength="30"
                      [(ngModel)]="item.newCommentText" (click)="$event.target.select()"
                      (change)="lineEdited(itemgroup.key, index)" /></td>
                  <td><input class="form-control form-control-sm text-center" style="width: 75px;" type="number"
                      [(ngModel)]="item.newQtyOrdered" (click)="$event.target.select()" min="0"
                      (change)="lineEdited(itemgroup.key, index)" /></td>
                  <td><input class="form-control form-control-sm text-center" style="width: 75px;" type="number"
                      [(ngModel)]="item.newQtyShipped" (click)="$event.target.select()" min="0"
                      (change)="lineEdited(itemgroup.key, index)" /></td>
                  <td><input class="form-control form-control-sm text-center" style="width: 75px;" type="number"
                      [(ngModel)]="item.newQtyBackordered" (click)="$event.target.select()" min="0"
                      (change)="lineEdited(itemgroup.key, index)" /></td>
                  <td><input class="form-control form-control-sm text-center" disabled style="width: 75px;"
                      type="number" [(ngModel)]="item.TotalQuantityOnHand" /></td>
                  <td *ngIf="item.added"><button type="button" class="btn btn-outline-primary mb-2"
                      (click)="removeLineItem(itemgroup.key)">X</button></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <!-- * item List  -->
          <!-- <datalist id="itemsList">
            <option *ngFor="let item of itemList" value="{{ item.ItemCode }}"></option>
          </datalist> -->
          <!-- * item List End  -->
          <div class="row">
            <div class="col-12">
              <button type="button" class="btn btn-secondary mb-2" (click)="resetTicket(itemgroup.key)">Reset
                Ticket</button>
              <span class="mb-2 ml-4 mr-2"><b>Current Status:</b> {{itemgroup.value[0]?.StatusCode}}</span>
              <span class="mb-2 mx-2">
                <span>New Status: </span>
                <div class="btn-group" dropdown [dropup]="isDropup">
                  <button type="button" dropdownToggle
                    class="btn btn-outline-dark">{{itemgroup.value[0]?.newStatus}}<span class="caret"><i
                        class="fas fa-caret-down"></i></span></button>
                  <ul *dropdownMenu class="dropdown-menu" role="menu">
                    <li role="menuitem" *ngFor="let filt of StatusList; index as index">
                      <a (click)="setFilter(index, itemgroup)" class="dropdown-item" style="cursor: pointer;">
                        <span>{{filt.stat}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </span>
              <button type="button" class="btn btn-primary mb-2 float-right" [disabled]="isOverShipped"
                (click)="openModal(updateItems,itemgroup.key)"><span *ngIf="!itemgroup.value[0]?.loading">Update
                  Items</span><span *ngIf="itemgroup.value[0]?.loading"><i
                    class="fas fa-sync fa-spin"></i></span></button>
              <button type="button" class="btn btn-primary mb-2 mr-2 float-right"
                (click)="openModal(unshipAll, itemgroup.key)">Unship All</button>
              <button type="button" class="btn btn-primary mb-2 mr-2 float-right"
                (click)="openModal(shipAll, itemgroup.key)">Ship All</button>
              <button type="button" class="btn btn-secondary mb-2 mr-2 float-right"
                (click)="showUpdatedTicket(itemgroup.key)">Show Items</button>
              <button type="button" class="btn btn-outline-info mb-2 mr-2 float-right"
                (click)="addLineItem(itemgroup.key)">Add</button>
              <button type="button" class="btn btn-secondary mb-2 mr-2 float-right" (click)="printPage()">Print</button>
            </div>
          </div>
        </div>

      </div>
    </ng-container>
    <ng-container *ngIf="selectionWTSubmitDelete">
      <div class="card-header mt-3">
        <div class="row">
          <div class="col-2">
            <input type="text" class="form-control" [(ngModel)]="filerSO" placeholder="SO number" maxlength="11">
          </div>
          <div class="col-2">
            <input type="text" class="form-control" [(ngModel)]="filerItem" placeholder="ItemCode">
          </div>
          <div class="col-3">
            <span>Sage Processed: </span>
            <div class="btn-group" dropdown>
              <button type="button" dropdownToggle class="btn btn-outline-dark">{{filerProc}}<span class="caret"><i
                    class="fas fa-caret-down"></i></span></button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li role="menuitem" *ngFor="let filt of processedList; index as index">
                  <a (click)="cleanDeletes(filt.stat)" class="dropdown-item" style="cursor: pointer;">
                    <span>{{filt.stat}}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-4 text-right">
            <button class="btn btn-outline-primary mr-2" (click)="SelectAll()">Select All</button>
            <button class="btn btn-primary" (click)="openModal2(DeleteSubmission)">Delete Selected</button>
          </div>
          <!--          <div class="col-2">-->
          <!--            <button class="btn btn-primary" (click)="openModal2(DeleteSubmission)">Delete Selected</button>-->
          <!--          </div>-->
        </div>
      </div>
      <div class="card-body border-0">
        <table class="table table-sm table-striped">
          <thead class="thead-dark">
            <tr>
              <th scope="col" (click)="sortTickets2('SalesOrderNo')">SO # <span *ngIf="sortedBy2 === 'SalesOrderNo'"><i
                    class="fas fa-wrench"></i></span></th>
              <th scope="col">WT #</th>
              <th scope="col" (click)="sortTickets2('ItemCode')">Item <span *ngIf="sortedBy2 === 'ItemCode'"><i
                    class="fas fa-wrench"></i></span></th>
              <th scope="col">Ordered</th>
              <th scope="col">Shipped</th>
              <th scope="col">Backordered</th>
              <th scope="col">On Hand</th>
              <th scope="col" (click)="sortTickets2('SystemOperation')">System Operation <span
                  *ngIf="sortedBy2 === 'SystemOperation'"><i class="fas fa-wrench"></i></span></th>
              <th scope="col" (click)="sortTickets2('TaskAction')">Task Action <span
                  *ngIf="sortedBy2 === 'TaskAction'"><i class="fas fa-wrench"></i></span></th>
              <th scope="col">Sage Processed</th>
              <th scope="col" (click)="sortTickets2('DateCreated')">Date Created <span
                  *ngIf="sortedBy2 === 'DateCreated'"><i class="fas fa-wrench"></i></span></th>
              <th scope="col" (click)="sortTickets2('TimeCreated')">Time Created <span
                  *ngIf="sortedBy2 === 'TimeCreated'"><i class="fas fa-wrench"></i></span></th>
              <th scope="col" (click)="sortTickets2('CreatedBy')">Created By <span *ngIf="sortedBy2 === 'CreatedBy'"><i
                    class="fas fa-wrench"></i></span></th>
              <th scope="col">Select</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of matSubList; let index = index">
              <!--              <tr [ngStyle]="{'display': item.ItemCode.substr(0, 1) === 'Z' ? 'none' : ''}" >-->
              <ng-container *ngIf="filerSettings(item)">
                <tr>
                  <td>{{item.SalesOrderNo}}</td>
                  <td>{{item.WTNumber}}</td>
                  <td>{{item.ItemCode }}</td>
                  <td>{{item.QuantityOrdered}}</td>
                  <td>{{item.QuantityShipped}}</td>
                  <td>{{item.QuantityBackordered}}</td>
                  <td>{{item.QuantityOnHand}}</td>
                  <td>{{item.SystemOperation}}</td>
                  <td>{{item.TaskAction}}</td>
                  <td>{{item.SageProcessed}}</td>
                  <td>{{item.DateCreated | date:'MM/dd/yyyy'}}</td>
                  <td>{{item.TimeCreated}}</td>
                  <td>{{item.CreatedBy}}</td>
                  <td (click)="item.deleteselected = !item.deleteselected">
                    <div class="custom-control custom-checkbox text-center">
                      <input type="checkbox" class="custom-control-input" [(ngModel)]="item.deleteselected"
                        style="pointer-events:none">
                      <label class="custom-control-label"></label>
                    </div>
                  </td>
                  <!--                <td><button class="btn btn-sm btn-primary" (click)="openModal2(DeleteSubmission,item.SubmissionKey)">Delete</button></td>-->
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>

  <!-- * print list-->
  <div id="print-view-page" class="m-0 p-0">
    <div class="container-fluid">
      <div class="row justify-content-center" [id]="'pdfData' + i"
        *ngFor="let itemgroup of itemCollection | keyvalue; let i = index">
        <div class="row justify-content-center" [ngStyle]="{'min-width': '100%', 'max-width': '100%'}">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td><img [ngStyle]="{'height': '100px'}" src="assets/gibsonLogo.PNG" alt="image"></td>
                <td>
                  <div class="row justify-content-center mt-1 p-0">
                    <qr-code value="{{itemgroup.key}}"></qr-code>
                  </div>
                  <div class="row justify-content-center mt-1 p-0">
                    <p><b>WT: {{itemgroup.key}}</b></p>
                  </div>
                </td>
                <td>
                  <div class="row justify-content-center m-0 p-0">
                    <h3><b>Packing List</b></h3>
                  </div>
                  <ng-container *ngIf="itemCollection !== undefined">
                    <div class="row justify-content-center mt-1 p-0">
                      <h5><b>{{itemgroup.value[0]?.ShipToName}}</b></h5>
                    </div>
                    <div class="row justify-content-center mt-1 p-0">
                      <p>
                        <b>Leadman:</b> {{itemgroup.value[0]?.UDF_LEADMAN}}&nbsp;&nbsp;&nbsp;&nbsp;<b>Status:</b>
                        {{itemgroup.value[0]?.StatusCode}}
                      </p>
                    </div>
                    <div class="row justify-content-center mt-1 p-0">
                      <p><b>Print Date:</b>{{pdfTodayDate}} <b>- Print time:</b> {{pdfTime}}</p>
                    </div>
                  </ng-container>
                </td>
                <td>
                  <!--              <p><b>{{tick.pageNum}} of {{shipListSplit.length}}</b></p>-->
                </td>
              </tr>
            </thead>
            <tr class="table-primary">
              <th>Item</th>
              <th>Barcode</th>
              <th>Description</th>
              <th>Backordered</th>
            </tr>
            <tr *ngFor="let item of itemgroup.value">
              <ng-container *ngIf="item.newQtyBackordered != 0 && item.LineKey != item.JT158_WTParentLineKey">
                <th style="width: 20%;">{{item.ItemCode}}</th>
                <!--            <td style="width: 20%;"><qr-code value="{{item.name}}"></qr-code></td>-->
                <td style="width: 20%;">
                  <ngx-barcode6 [bc-value]="item.ItemCode" [bc-display-value]="false" [bc-format]="'CODE128'"
                    [bc-width]="1" [bc-height]="40"></ngx-barcode6>
                </td>
                <td style="width: 50%;">{{item === undefined ? '' : item.ItemCDesc }}</td>
                <td style="width: 10%;"><b>{{item.newQtyBackordered}}</b> {{item.UnitOfMeasure}}</td>
              </ng-container>
            </tr>
          </table>
        </div>
        <!-- * print list END -->
      </div>
    </div>
  </div>
</div>


<!-- ship all modal -->
<ng-template #shipAll>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2> Ship All Items? </h2>
        <h5> All shipped values will be set equal to the number ordered</h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <div class="row mb-1" id="">
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" (click)="shipAllItems()">Yes</button>
      </div>
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" (click)="closeModal()">No</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- unship all modal -->
<ng-template #unshipAll>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2> Unship All Items? </h2>
        <h5> All shipped values will be set to Zero</h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <div class="row mb-1" id="">
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" (click)="unshipAllItems()">Yes</button>
      </div>
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" (click)="closeModal()">No</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- update items modal -->
<ng-template #updateItems>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2> Update Workticket? </h2>
        <h5></h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" (click)="updateMaterial(dataWTNum)">Yes</button>
      </div>
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" (click)="closeModal()">No</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- update items modal -->
<ng-template #DeleteSubmission>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2> Delete Submission? </h2>
        <h5></h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" (click)="DeleteSub()">Yes</button>
      </div>
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" (click)="closeModal()">No</button>
      </div>
    </div>
  </div>
</ng-template>
