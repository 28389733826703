import { Component, OnInit, TemplateRef } from "@angular/core";
import { AlertifyService } from "../../../_services/alertify/alertify.service";
import { SageApiService } from "../../../_services/sageApi/sageApi.service";
import { AuthService } from "../../../_services/auth/auth.service";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead/typeahead-match.class";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { concat } from "rxjs";
import { WasabiApiService } from "../../../_services/wasabiApi/wasabiApi.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-house-editor",
  templateUrl: "./house-editor.component.html",
  styleUrls: ["./house-editor.component.css"],
})
export class HouseEditorComponent implements OnInit {
  modalTemplate: BsModalRef;

  addressCollection = [];

  importTemplateLoading: boolean;
  importTemplatePhaseIndex = 0;
  importTemplateInfo = {
    templateName: "",
    template: "",
    step: "",
  };

  itemEditItemIndex = 0;
  itemEditStepIndex = 0;
  itemEditPhaseIndex = 0;

  sliderOne = true;
  sliderTwo = false;
  sliderThree = false;
  sliderFour = false;
  sliderFive = false;
  currentSlideName = "Info";
  imgFileTag = "";
  imgUrl: any = "";
  WShowImg = false;
  ShowImg = false;
  ShowStatus = false;

  newPhase = {
    name: "",
    itemCode: "",
    wtNumber: "",
    templateName: "",
    template: "",
    poNumber: "",
    pendingItems: "N",
    updateItems: "N",
    cabinetDetails: "N",
    reviewed: "N",
    auditReason: "Audit - Pending",
    auditOldReason: "",
    auditUpdate: "Y",
    comment: "",
    price: "",
    vpo: "N",
  };
  phaseLoading: boolean;

  loading: boolean;

  houseLoading: boolean;
  reviewedByDate: "";
  houseInfo = {
    salesOrderNo: "",
    address: "",
    builder: "",
    fileReviewedBy: "",
    fileReviewedByDate: "",
    series: "",
    type: "",
    planNumber: "",
    squareFootage: 0,
    subdivision: "",
    energyType: "",
    lot: "",
    block: "",
    garageLocation: "",
    updated: false,
    loading: false,
    warehouse: "",
    phases: [],
  };

  addressName = "";
  addressLoaded: boolean;
  addressList = [
    {
      name: "1234 Test House - 0011787",
      address: "1234 Test House",
      salesOrderNo: "0011787",
    },
  ];

  fileList = [];
  WasabiList = [];
  fileArray = [];
  plansArray = [];
  fileLoading = false;

  garageLocationList = [
    {
      name: "",
      code: "",
      description: "",
      selected: false,
    },
  ];

  energyList = [
    {
      name: "",
      code: "",
      description: "",
      selected: false,
    },
  ];

  planName = "";
  planList = [
    {
      name: "",
      code: "",
      description: "",
      selected: false,
    },
  ];

  warehouseName = "";
  WhC = [
    {
      warehouse: "001",
      city: "San Antonio",
      name: "001 - San Antonio",
      selected: false,
    },
    {
      warehouse: "005",
      city: "Dallas",
      name: "005 - Dallas",
      selected: false,
    },
  ];

  typeName = "";
  typeList = [
    {
      name: "1UP/1DOWN - 1 up two down",
      code: "1UP/1DOWN",
      description: "1 up two down",
      selected: false,
    },
  ];

  seriesList = [
    {
      name: "gehan",
      code: "",
      description: "",
      selected: false,
    },
  ];

  subdivisionList = [
    {
      name: "gehan",
      code: "",
      description: "",
      selected: false,
    },
  ];

  locationList = [
    {
      name: "NONE",
      code: "NONE",
      description: "",
      show: true,
      selected: false,
    },
  ];

  auditReasonList = [
    {
      name: "",
      code: "",
      description: "",
    },
  ];

  phaseList = [
    {
      name: "roughin",
      itemCode: "ZROUGHIN",
    },
    {
      name: "roughin extra",
      itemCode: "ZROUGHINEX",
    },
    {
      name: "sewer",
      itemCode: "ZSEWER",
    },
    {
      name: "water",
      itemCode: "ZWATER",
    },
    {
      name: "vanguard",
      itemCode: "ZVANGUARD",
    },
    {
      name: "topout",
      itemCode: "ZTOPOUT",
    },
    {
      name: "topout extra",
      itemCode: "ZTOPOUTEX",
    },
    {
      name: "gas",
      itemCode: "ZGAS",
    },
    {
      name: "trim",
      itemCode: "ZTRIM",
    },
    {
      name: "trim extra",
      itemCode: "ZTRIMEX",
    },
    {
      name: "dropin",
      itemCode: "ZDROPIN",
    },
    {
      name: "rock standard",
      itemCode: "ZROCKSTD",
    },
    {
      name: "back water valve",
      itemCode: "ZBWV",
    },
    {
      name: "traffic box",
      itemCode: "ZTRAFBOX",
    },
  ];

  HouseStatus = [
    {
      phase: "",
      scheduleDate: "",
      status: "",
      statusDate: "",
      CrewID: "",
      WTNum: "",
    },
  ];

  templateList = [
    {
      name: "TOHH2.0",
      code: "",
      description: "2.0 Bath - Top Out Highland",
    },
  ];

  itemList = [
    {
      name: "S711182000 - vikrell tub",
      itemCode: "S711182000",
      description: "vikrell tub",
    },
  ];

  houseLocationList = [
    {
      code: "",
      show: false,
    },
  ];

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private wasabiApi: WasabiApiService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.loadAddressList();
    this.loadSeriesList();
    this.loadTypeList();
    this.loadPlanList();
    this.loadEnergyList();
    this.loadGarageLocationList();
    this.loadSubdivisionList();
    this.loadItemLocationList();
    this.loadTemplateList();
    this.loadItemList();
    this.loadAuditReasonList();
    // pull location list before salesorder data is able to be chosen or phases added
  }

  openModal(
    template: TemplateRef<any>,
    phaseIndex: number = 0,
    stepIndex: number = 0,
    itemIndex: number = 0
  ) {
    console.log(this.houseInfo, this.houseLocationList);
    this.importTemplatePhaseIndex = phaseIndex;
    this.importTemplateInfo = {
      templateName: "",
      template: "",
      step: "",
    };

    this.itemEditItemIndex = itemIndex;
    this.itemEditStepIndex = stepIndex;
    this.itemEditPhaseIndex = phaseIndex;

    this.modalTemplate = this.modalService.show(template);
  }

  closeModal() {
    this.modalTemplate.hide();
    this.resetUpload();
  }

  slideNext() {
    switch (this.currentSlideName) {
      case "Info":
        this.currentSlideName = "Phases";
        this.sliderOne = false;
        this.sliderTwo = true;
        break;

      case "Phases":
        this.currentSlideName = "Parts";
        this.sliderTwo = false;
        this.sliderThree = true;
        break;

      case "Parts":
        this.currentSlideName = "Labor";
        this.sliderThree = false;
        this.sliderFour = true;
        break;

      case "Labor":
        this.currentSlideName = "Review";
        this.sliderFour = false;
        this.sliderFive = true;
        break;

      default:
        this.currentSlideName = "Info";
        this.sliderOne = true;
        break;
    }
  }

  slidePrevious() {
    switch (this.currentSlideName) {
      case "Phases":
        this.currentSlideName = "Info";
        this.sliderOne = true;
        this.sliderTwo = false;
        break;

      case "Parts":
        this.currentSlideName = "Phases";
        this.sliderTwo = true;
        this.sliderThree = false;
        break;

      case "Labor":
        this.currentSlideName = "Parts";
        this.sliderThree = true;
        this.sliderFour = false;
        break;

      case "Review":
        this.currentSlideName = "Labor";
        this.sliderFour = true;
        this.sliderFive = false;
        break;

      default:
        this.currentSlideName = "Info";
        this.sliderOne = true;
        break;
    }
  }

  slideSpecific(slideName: string) {
    switch (slideName) {
      case "Info":
        this.currentSlideName = "Info";
        this.sliderOne = true;
        this.sliderTwo = false;
        this.sliderThree = false;
        this.sliderFour = false;
        this.sliderFive = false;
        break;

      case "Phases":
        this.currentSlideName = "Phases";
        this.sliderTwo = true;
        this.sliderOne = false;
        this.sliderThree = false;
        this.sliderFour = false;
        this.sliderFive = false;
        break;

      case "Parts":
        this.currentSlideName = "Parts";
        this.sliderThree = true;
        this.sliderOne = false;
        this.sliderTwo = false;
        this.sliderFour = false;
        this.sliderFive = false;
        break;

      case "Labor":
        this.currentSlideName = "Labor";
        this.sliderFour = true;
        this.sliderOne = false;
        this.sliderTwo = false;
        this.sliderThree = false;
        this.sliderFive = false;
        break;

      case "Review":
        this.currentSlideName = "Review";
        this.sliderFive = true;
        this.sliderOne = false;
        this.sliderTwo = false;
        this.sliderThree = false;
        this.sliderFour = false;
        break;

      default:
        this.currentSlideName = "Info";
        this.sliderOne = true;
        this.sliderTwo = false;
        this.sliderThree = false;
        this.sliderFour = false;
        this.sliderFive = false;
        break;
    }
  }

  loadAddressList() {
    this.addressList = [];
    this.sageApi.pullReport("HouseEditor/Addresses").subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const address = {
              name: row.Address + " - " + row.SalesOrderNo,
              address: row.Address,
              salesOrderNo: row.SalesOrderNo,
            };

            this.addressList.push(address);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        console.log(this.addressList);
      }
    );
  }

  loadSeriesList() {
    this.seriesList = [];
    this.sageApi.pullReport("HouseEditor/Series").subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const series = {
              name: row.Series + " - " + row.Description,
              code: row.Series,
              description: row.Description,
              selected: false,
            };

            this.seriesList.push(series);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        console.log(this.seriesList);
      }
    );
  }

  loadTypeList() {
    this.typeList = [];
    this.sageApi.pullReport("HouseEditor/Type").subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const type = {
              name: row.Type + " - " + row.Description,
              code: row.Type,
              description: row.Description,
              selected: false,
            };

            this.typeList.push(type);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        console.log(this.typeList);
      }
    );
  }

  loadPlanList() {
    this.planList = [];
    this.sageApi.pullReport("HouseEditor/Plan").subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const plan = {
              name: row.PlanNumber + " - " + row.Description,
              code: row.PlanNumber,
              description: row.Description,
              selected: false,
            };

            this.planList.push(plan);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        console.log(this.planList);
      }
    );
  }

  loadEnergyList() {
    this.energyList = [];
    this.sageApi.pullReport("HouseEditor/EnergyType").subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const energy = {
              name: row.EnergyType,
              code: row.EnergyType,
              description: "",
              selected: false,
            };

            this.energyList.push(energy);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        console.log(this.energyList);
      }
    );
  }

  loadGarageLocationList() {
    this.garageLocationList = [];
    this.sageApi.pullReport("HouseEditor/GarageLocation").subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const garage = {
              name: row.GarageLocation,
              code: row.GarageLocation,
              description: "",
              selected: false,
            };

            this.garageLocationList.push(garage);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        console.log(this.garageLocationList);
      }
    );
  }

  loadSubdivisionList() {
    this.subdivisionList = [];
    this.sageApi.pullReport("HouseEditor/Subdivisions").subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const subdivision = {
              name: row.Subdivision,
              code: row.Subdivision,
              description: "",
              selected: false,
            };

            this.subdivisionList.push(subdivision);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        console.log(this.subdivisionList);
      }
    );
  }

  loadItemLocationList() {
    this.locationList = [
      {
        name: "NONE",
        code: "NONE",
        description: "",
        show: false,
        selected: false,
      },
    ];
    this.sageApi.pullReport("HouseEditor/ItemLocation").subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const location = {
              name: row.Location + " - " + row.Description,
              code: row.Location,
              description: row.Description,
              show: true,
              selected: false,
            };

            this.locationList.unshift(location);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        console.log(this.locationList);
      }
    );
  }

  loadAuditReasonList() {
    this.auditReasonList = [];
    this.sageApi.pullReport("HouseEditor/AuditReasons").subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const reason = {
              name: row.AuditReason,
              code: row.AuditReason,
              description: row.AuditReason,
            };

            this.auditReasonList.push(reason);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        console.log(this.auditReasonList);
      }
    );
  }

  loadTemplateList() {
    this.templateList = [];
    this.sageApi.pullReport("HouseEditor/Template").subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const template = {
              name: row.Template + " - " + row.Description,
              code: row.Template,
              description: row.Description,
            };

            this.templateList.push(template);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        console.log(this.templateList);
      }
    );
  }

  loadItemList() {
    this.itemList = [];
    this.sageApi.pullReport("HouseEditor/Item").subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach((row) => {
            const item = {
              name: row.ItemCode + " - " + row.ItemCodeDesc,
              itemCode: row.ItemCode,
              description: row.ItemCodeDesc,
              kit: row.Kit,
            };

            this.itemList.push(item);
          });
        }
      },
      (err) => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        console.log(this.itemList);
      }
    );
  }

  addressSelected(event: TypeaheadMatch) {
    this.loadSalesOrder(event.item.salesOrderNo);
  }

  loadSalesOrder(salesOrderNo: string) {
    this.addressLoaded = false;
    this.houseLoading = true;
    this.houseInfo = {
      salesOrderNo: "",
      address: "",
      builder: "",
      fileReviewedBy: "",
      fileReviewedByDate: "",
      series: "",
      type: "",
      planNumber: "",
      squareFootage: 0,
      subdivision: "",
      energyType: "",
      lot: "",
      block: "",
      garageLocation: "",
      updated: false,
      loading: false,
      warehouse: "",
      phases: [],
    };
    this.houseLocationList = [];

    this.sageApi.pullReport("HouseEditor?salesorder=" + salesOrderNo).subscribe(
      (rows: Array<any>) => {
        console.log(rows);
        if (Array.isArray(rows)) {
          rows.forEach((row, rowIndex) => {
            if (rowIndex === 0) {
              this.houseInfo.salesOrderNo = salesOrderNo;
              this.houseInfo.address = row.Address;
              this.houseInfo.builder = row.Customer;
              this.houseInfo.fileReviewedBy = row.FileReviewedBy;
              this.houseInfo.fileReviewedByDate = row.FileReviewedByDate;
              this.reviewedByDate = row.FileReviewedByDate.substring(0, 10);
              this.houseInfo.series = row.Series;
              this.houseInfo.type = row.Type;
              this.typeName = row.Type;
              this.houseInfo.planNumber = row.PlanNumber;
              this.planName = row.PlanNumber;
              this.houseInfo.squareFootage = row.SquareFootage ?? 0;
              this.houseInfo.subdivision = row.Subdivision;
              this.houseInfo.energyType = row.EnergyType;
              this.houseInfo.lot = row.Lot;
              this.houseInfo.block = row.Block;
              this.houseInfo.warehouse = row.WarehouseCode;
              const findware = this.WhC.find((item) =>
                item.warehouse.includes(row.WarehouseCode)
              );
              this.warehouseName =
                findware !== undefined ? findware.name : row.WarehouseCode;
              this.houseInfo.garageLocation = row.GarageLocation;
              this.houseInfo.updated = false;
              this.houseInfo.phases = [];
            }

            if (
              this.houseInfo.phases.some(
                (phase) => phase.wtNumber === row.WTNumber
              )
            ) {
              if (row.Parent === "Y") {
                const phaseIndex = this.houseInfo.phases.findIndex(
                  (phase) => phase.wtNumber === row.WTNumber
                );
                this.houseInfo.phases[phaseIndex].lineKey = row.LineKey;
                this.houseInfo.phases[phaseIndex].poNumber = row.PONumber;
                this.houseInfo.phases[phaseIndex].pendingItems =
                  row.PendingItems;
                this.houseInfo.phases[phaseIndex].updateItems = row.UpdateItems;
                this.houseInfo.phases[phaseIndex].cabinetDetails =
                  row.NeedCabinetDetails;
                this.houseInfo.phases[phaseIndex].reviewed = row.Reviewed;
                this.houseInfo.phases[phaseIndex].auditReason = row.AuditReason;
                this.houseInfo.phases[phaseIndex].auditOldReason =
                  row.AuditReason;
                this.houseInfo.phases[phaseIndex].auditUpdate = "N";
                this.houseInfo.phases[phaseIndex].comment = row.CommentText;
                this.houseInfo.phases[phaseIndex].price = row.Price;
                this.houseInfo.phases[phaseIndex].new = false;
                this.houseInfo.phases[phaseIndex].updated = false;
                this.houseInfo.phases[phaseIndex].vpo = row.UDF_VPO;
              } else {
                const phaseIndex = this.houseInfo.phases.findIndex(
                  (phase) => phase.wtNumber === row.WTNumber
                );
                const childItem = {
                  itemCode: row.ItemCode,
                  lineKey: row.LineKey,
                  description: row.Description,
                  qty: row.QuantityOrdered,
                  location: row.Location,
                  comment: row.CommentText,
                  updated: false,
                  PartOfTemp: row.PartOfTemp,
                  inactive: row.InactiveItem
                };

                if (
                  this.houseInfo.phases[phaseIndex].steps.some(
                    (step) => step.wtStep === row.WTStep
                  )
                ) {
                  const stepIndex = this.houseInfo.phases[
                    phaseIndex
                  ].steps.findIndex((step) => step.wtStep === row.WTStep);
                  this.houseInfo.phases[phaseIndex].steps[
                    stepIndex
                  ].existingItems.push(childItem);
                } else {
                  const newStep = {
                    wtStep: row.WTStep,
                    new: false,
                    loading: false,
                    newItem: {},
                    addedItems: [],
                    existingItems: [childItem],
                  };

                  this.locationList.forEach((location) => {
                    newStep.newItem[location.code] = {
                      itemSelected: "",
                      itemCode: "",
                      description: "",
                      notes: "",
                      qty: 0,
                      kit: "",
                      explode: "",
                    };
                  });

                  this.houseInfo.phases[phaseIndex].steps.push(newStep);
                }
              }
            } else {
              let newPhase;
              if (row.Parent === "Y") {
                newPhase = {
                  parent: row.ParentItemCode,
                  lineKey: row.LineKey,
                  wtNumber: row.WTNumber,
                  template: row.Template,
                  importedTemplate: "",
                  poNumber: row.PONumber,
                  pendingItems: row.PendingItems,
                  updateItems: row.UpdateItems,
                  cabinetDetails: row.NeedCabinetDetails,
                  reviewed: row.Reviewed,
                  auditReason: row.AuditReason,
                  auditOldReason: row.AuditReason,
                  auditUpdate: "N",
                  comment: row.CommentText,
                  price: row.Price,
                  new: false,
                  updated: false,
                  vpo: row.UDF_VPO,
                  steps: [],
                };
              } else {
                const childItem = {
                  itemCode: row.ItemCode,
                  lineKey: row.LineKey,
                  description: row.Description,
                  qty: row.QuantityOrdered,
                  location: row.Location,
                  comment: row.CommentText,
                  updated: false,
                };

                const newStep = {
                  wtStep: row.WTStep,
                  new: false,
                  loading: false,
                  newItem: {},
                  addedItems: [],
                  existingItems: [childItem],
                };

                this.locationList.forEach((location) => {
                  newStep.newItem[location.code] = {
                    itemSelected: "",
                    itemCode: "",
                    description: "",
                    notes: "",
                    qty: 0,
                    kit: "",
                    explode: "",
                  };
                });

                newPhase = {
                  parent: row.ParentItemCode,
                  lineKey: "",
                  wtNumber: row.WTNumber,
                  template: row.Template,
                  importedTemplate: "",
                  poNumber: "",
                  pendingItems: "",
                  updateItems: "",
                  cabinetDetails: "",
                  reviewed: "",
                  auditReason: "Audit - Pending",
                  auditOldReason: "",
                  auditUpdate: "Y",
                  comment: "",
                  price: null,
                  new: false,
                  updated: false,
                  vpo: row.UDF_VPO,
                  steps: [newStep],
                };
              }
              this.houseInfo.phases.push(newPhase);
            }

            if (
              !this.houseLocationList.some(
                (location) => location.code === row.Location
              )
            ) {
              const location = {
                code: row.Location,
                show: row.Location === "MASTBATH" ? true : false,
              };
              this.houseLocationList.push(location);
            }
          });
        }
      },
      (err) => {
        console.log(err);
        this.alertify.error(err.message);
        this.houseLoading = false;
      },
      () => {
        console.log(this.houseInfo, this.houseLocationList);
        this.houseLoading = false;
        this.addressLoaded = true;
        this.loadHouseStatuses(salesOrderNo);
      }
    );
  }

  loadHouseStatuses(salesOrderNo: string) {
    this.HouseStatus = [];
    this.sageApi
      .pullReport("HouseEditor/Status?salesorder=" + salesOrderNo)
      .subscribe(
        (rows: Array<any>) => {
          console.log("Statuses", rows);
          rows.forEach((phase) => {
            const obj = {
              phase: phase.HdrParentItemCode,
              scheduleDate: phase.PhaseDate,
              status: phase.StatusCode,
              statusDate: phase.StatusDate,
              CrewID: phase.UDF_TECHNICIAN_CODE,
              WTNum: phase.WTNumber,
            };
            this.HouseStatus.push(obj);
          });
        },
        (err) => {
          console.log(err);
          this.alertify.error(err.message);
        },
        () => {
          console.log("HouseStatus", this.HouseStatus);
        }
      );
  }

  addHouseLocation(name: string, locationIndex: number) {
    if (!this.houseLocationList.some((location) => location.code === name)) {
      const location = {
        code: name,
        show: name === "MASTBATH" ? true : false,
      };
      this.houseLocationList.push(location);
    }
  }

  seriesSelected(code: string, seriesIndex: number) {
    this.houseInfo.series = code;
  }

  // typeSelected(name: string, typeIndex: number) {
  //   this.houseInfo.type = name;
  // }
  typeSelected(event: TypeaheadMatch) {
    this.houseInfo.type = event.item.code;
  }

  // planSelected(name: string, planIndex: number) {
  //   this.houseInfo.planNumber = name;
  // }
  planSelected(event: TypeaheadMatch) {
    this.houseInfo.planNumber = event.item.code;
  }

  warehouseSelected(event: TypeaheadMatch) {
    if (event.item.warehouse !== undefined) {
      this.houseInfo.warehouse = event.item.warehouse;
    } else {
      if (isNaN(+this.warehouseName)) {
        this.houseInfo.warehouse = "";
      } else {
        this.houseInfo.warehouse = this.warehouseName;
      }
    }
    // console.log(event.item.warehouse);
    // console.log(this.houseInfo.warehouse);
  }

  energySelected(name: string, energyIndex: number) {
    this.houseInfo.energyType = name;
  }

  garageSelected(name: string, garageIndex: number) {
    this.houseInfo.garageLocation = name;
  }

  phasePendingItemsSelected(option: string) {
    this.houseInfo.phases[this.importTemplatePhaseIndex].updated = true;
    this.houseInfo.phases[this.importTemplatePhaseIndex].pendingItems = option;
  }

  phaseUpdateItemsSelected(option: string) {
    this.houseInfo.phases[this.importTemplatePhaseIndex].updated = true;
    this.houseInfo.phases[this.importTemplatePhaseIndex].updateItems = option;
  }

  phaseCabinetDetailsSelected(option: string) {
    this.houseInfo.phases[this.importTemplatePhaseIndex].updated = true;
    this.houseInfo.phases[this.importTemplatePhaseIndex].cabinetDetails =
      option;
  }

  phaseReviewedSelected(option: string) {
    this.houseInfo.phases[this.importTemplatePhaseIndex].updated = true;
    this.houseInfo.phases[this.importTemplatePhaseIndex].reviewed = option;
  }

  phaseAuditReasonSelected(option: string) {
    this.houseInfo.phases[this.importTemplatePhaseIndex].updated = true;
    this.houseInfo.phases[this.importTemplatePhaseIndex].auditReason = option;
    const splitOp = option.split(" - ");
    if (
      this.houseInfo.phases[this.importTemplatePhaseIndex].auditOldReason !== ""
    ) {
      const splitOld =
        this.houseInfo.phases[
          this.importTemplatePhaseIndex
        ].auditOldReason.split(" - ");
      // console.log('splitOp', splitOp);
      // console.log('splitOld', splitOld);
      this.houseInfo.phases[this.importTemplatePhaseIndex].auditUpdate =
        splitOp[1].substring(0, 1) !== splitOld[1].substring(0, 1) ? "Y" : "N";
      // console.log(this.houseInfo.phases[this.importTemplatePhaseIndex].auditUpdate);
    } else {
      this.houseInfo.phases[this.importTemplatePhaseIndex].auditUpdate = "Y";
    }
  }

  phaseVPOSelected(event) {
    this.houseInfo.phases[this.importTemplatePhaseIndex].updated = true;
    this.houseInfo.phases[this.importTemplatePhaseIndex].vpo =
      event.target.checked === true ? "Y" : "N";
  }

  newPhaseVPOSelected(event) {
    this.newPhase.vpo = event.target.checked === true ? "Y" : "N";
  }

  importTemplateReady() {
    // if (this.importTemplateInfo.template === '' || this.importTemplateInfo.step === '') {
    if (this.importTemplateInfo.template === "") {
      return false;
    } else {
      return true;
    }
  }

  importTemplateSelected(event: TypeaheadMatch) {
    // console.log('saved: update true');
    this.houseInfo.phases[this.importTemplatePhaseIndex].updated = true;
    this.importTemplateInfo.template = event.item.code;
  }

  addTemplateItems() {
    this.importTemplateLoading = true;
    const steps = ["000", "010", "020"];
    // this.sageApi.pullReport('HouseEditor/Template?template=' + this.importTemplateInfo.template + '&step=' + this.importTemplateInfo.step)
    this.sageApi
      .pullReport(
        "HouseEditor/Template?template=" + this.importTemplateInfo.template
      )
      .subscribe(
        (items: Array<any>) => {
          if (Array.isArray(items)) {
            items.forEach((item) => {
              const obj = {
                itemCode: item.ItemCode,
                description: item.Description,
                qty: item.QuantityNeeded,
                location: item.Location,
                comment: item.CommentText, inactive: item.InactiveItem
              };
              if(obj.inactive == "Y"){
                this.alertify.error(this.importTemplateInfo.template + " contains inactive item "+obj.itemCode);
              }
              if (
                this.houseInfo.phases[this.importTemplatePhaseIndex].steps.some(
                  (step) => step.wtStep === item.WTStep
                )
              ) {
                const stepIndex = this.houseInfo.phases[
                  this.importTemplatePhaseIndex
                ].steps.findIndex((step) => step.wtStep === item.WTStep);
                this.houseInfo.phases[this.importTemplatePhaseIndex].steps[
                  stepIndex
                ].addedItems.push(obj);
              } else {
                const newStep = {
                  wtStep: item.WTStep,
                  new: true,
                  loading: false,
                  newItem: {},
                  addedItems: [obj],
                  existingItems: [],
                };

                this.locationList.forEach((location) => {
                  newStep.newItem[location.code] = {
                    itemSelected: "",
                    itemCode: "",
                    description: "",
                    notes: "",
                    qty: 0,
                    kit: "",
                    explode: "",
                  };
                });
                // tslint:disable-next-line:max-line-length
                // console.log(this.houseInfo.phases[this.importTemplatePhaseIndex].poNumber + ' ' +this.houseInfo.phases[this.importTemplatePhaseIndex].price);
                this.houseInfo.phases[this.importTemplatePhaseIndex].steps.push(
                  newStep
                );
              }

              if (
                !this.houseLocationList.some(
                  (location) => location.code === item.Location
                )
              ) {
                const location = {
                  code: item.Location,
                  show: item.Location === "MASTBATH" ? true : false,
                };
                this.houseLocationList.push(location);
              }
            });
          }
        },
        (err) => {
          console.log(err);
          this.alertify.error(err.message);
          this.importTemplateLoading = false;
        },
        () => {
          this.houseInfo.phases[
            this.importTemplatePhaseIndex
          ].importedTemplate = this.importTemplateInfo.template;
          this.modalTemplate.hide();
          this.importTemplateLoading = false;
          this.importTemplateInfo = {
            templateName: "",
            template: "",
            step: "",
          };
          console.log(this.houseInfo);
        }
      );

    steps.forEach((step) => {
      if (
        !this.houseInfo.phases[this.importTemplatePhaseIndex].steps.some(
          (stepInfo) => stepInfo.wtStep === step
        )
      ) {
        const newStep = {
          wtStep: step,
          new: true,
          loading: false,
          newItem: {},
          addedItems: [],
          existingItems: [],
        };

        this.locationList.forEach((location) => {
          newStep.newItem[location.code] = {
            itemSelected: "",
            itemCode: "",
            description: "",
            notes: "",
            qty: 0,
            kit: "",
            explode: "",
          };
        });

        this.houseInfo.phases[this.importTemplatePhaseIndex].steps.push(
          newStep
        );
      }
    });
  }

  addPhase() {
    const newPhaseVpoCheckBox: any = document.getElementById(
      "newPhase-vpo-checkbox"
    );
    this.phaseLoading = true;

    const phase = {
      parent: this.newPhase.itemCode,
      lineKey: "",
      wtNumber: ("000" + this.newPhase.wtNumber).slice(-3),
      template: this.newPhase.template,
      importedTemplate: this.newPhase.template,
      poNumber: this.newPhase.poNumber,
      pendingItems: this.newPhase.pendingItems,
      updateItems: this.newPhase.updateItems,
      cabinetDetails: this.newPhase.cabinetDetails,
      reviewed: this.newPhase.reviewed,
      auditReason: this.newPhase.auditReason,
      auditUpdate: this.newPhase.auditUpdate,
      comment: this.newPhase.comment,
      price: this.newPhase.price,
      new: true,
      updated: true,
      vpo: this.newPhase.vpo,
      steps: [],
    };

    this.sageApi
      .pullReport("HouseEditor/Template?template=" + this.newPhase.template)
      .subscribe(
        (items: Array<any>) => {
          if (Array.isArray(items)) {
            items.forEach((item) => {
              const obj = {
                itemCode: item.ItemCode,
                description: item.Description,
                qty: item.QuantityNeeded,
                location: item.Location,
                comment: item.CommentText,  inactive: item.InactiveItem
              };
              if(obj.inactive == "Y"){
                this.alertify.error(this.newPhase.template + " contains inactive item "+obj.itemCode);
              }
              if (phase.steps.some((step) => step.wtStep === item.WTStep)) {
                const stepIndex = phase.steps.findIndex(
                  (step) => step.wtStep === item.WTStep
                );
                phase.steps[stepIndex].addedItems.push(obj);
              } else {
                const newStep = {
                  wtStep: item.WTStep,
                  new: true,
                  loading: false,
                  newItem: {},
                  addedItems: [obj],
                  existingItems: [],
                };

                this.locationList.forEach((location) => {
                  newStep.newItem[location.code] = {
                    itemSelected: "",
                    itemCode: "",
                    description: "",
                    notes: "",
                    qty: 0,
                    kit: "",
                    explode: "",
                  };
                });

                phase.steps.push(newStep);
              }

              if (
                !this.houseLocationList.some(
                  (location) => location.code === item.Location
                )
              ) {
                const location = {
                  code: item.Location,
                  show: item.Location === "MASTBATH" ? true : false,
                };
                this.houseLocationList.push(location);
              }
            });
          }
        },
        (err) => {
          console.log(err);
          this.alertify.error(err.message);
          this.phaseLoading = false;
        },
        () => {
          this.houseInfo.phases.push(phase);
          this.phaseLoading = false;
          newPhaseVpoCheckBox.checked = false;
          this.newPhase = {
            name: "",
            itemCode: "",
            wtNumber: "",
            templateName: "",
            template: "",
            poNumber: "",
            pendingItems: "N",
            updateItems: "N",
            cabinetDetails: "N",
            reviewed: "N",
            auditReason: "Audit - Pending",
            auditOldReason: "",
            auditUpdate: "Y",
            comment: "",
            price: "",
            vpo: "N",
          };
          console.log(this.houseInfo);
        }
      );
  }

  removePhase(phaseIndex) {
    console.log("remove index", phaseIndex);
    console.log("remove phase", this.houseInfo.phases[phaseIndex]);
    this.houseInfo.phases.splice(phaseIndex, 1);
    console.log("Remaining Phases", this.houseInfo.phases);
  }

  phaseItemSelected(event: TypeaheadMatch) {
    console.log(event);
    this.newPhase.itemCode = event.item.itemCode;
  }

  phaseTemplateSelected(event: TypeaheadMatch) {
    this.newPhase.template = event.item.code;
  }

  phaseReady() {
    if (
      this.newPhase.itemCode === "" ||
      this.newPhase.wtNumber === "" ||
      this.newPhase.template === ""
    ) {
      return false;
    } else {
      return true;
    }
  }

  showItemsToggle(locationIndex: number) {
    return (this.houseLocationList[locationIndex].show =
      !this.houseLocationList[locationIndex].show);
  }

  addItem(phaseIndex: number, stepIndex: number, locationIndex: number) {
    this.houseInfo.phases[phaseIndex].steps[stepIndex].loading = true;
    const locationName = this.houseLocationList[locationIndex].code;
    if (
      this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[locationName]
        .explode === "Y"
    ) {
      console.log("explode");
      this.sageApi
        .pullReport(
          "HouseEditor/Kit?kit=" +
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].itemCode
        )
        .subscribe(
          (rows: Array<any>) => {
            if (Array.isArray(rows)) {
              rows.forEach((row) => {
                const item = {
                  itemCode: row.ItemCode,
                  description: row.ItemCodeDesc,
                  // tslint:disable-next-line:radix
                  qty:
                    parseInt(
                      this.houseInfo.phases[phaseIndex].steps[stepIndex]
                        .newItem[locationName].qty
                    ) * row.Quantity,
                  location: locationName,
                  comment: row.CommentText,
                  vpo: "N",
                };
                console.log(
                  this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
                    locationName
                  ].qty,
                  row.Quantity
                );
                this.houseInfo.phases[phaseIndex].steps[
                  stepIndex
                ].addedItems.push(item);
              });
            }
          },
          (err) => {
            this.alertify.error(err.message);
            console.log(err);
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].itemCode = "";
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].description = "";
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].qty = 0;
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].itemSelected = "";
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].notes = "";
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].kit = "";
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].explode = "";
            this.houseInfo.phases[phaseIndex].steps[stepIndex].loading = false;
            this.houseInfo.phases[phaseIndex].steps[stepIndex].vpo = "N";
          },
          () => {
            console.log(this.itemList);
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].itemCode = "";
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].description = "";
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].qty = 0;
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].itemSelected = "";
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].notes = "";
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].kit = "";
            this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
              locationName
            ].explode = "";
            this.houseInfo.phases[phaseIndex].steps[stepIndex].vpo = "N";
            this.houseInfo.phases[phaseIndex].steps[stepIndex].loading = false;
          }
        );
    } else {
      const item = {
        itemCode:
          this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
            locationName
          ].itemCode,
        description:
          this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
            locationName
          ].description,
        // tslint:disable-next-line:radix
        qty: parseInt(
          this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
            locationName
          ].qty
        ),
        location: locationName,
        comment:
          this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
            locationName
          ].notes,
        vpo: this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
          locationName
        ].vpo,
        upgrade: "true"
      };
      this.houseInfo.phases[phaseIndex].steps[stepIndex].addedItems.push(item);

      this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
        locationName
      ].itemCode = "";
      this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
        locationName
      ].description = "";
      this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
        locationName
      ].qty = 0;
      this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
        locationName
      ].itemSelected = "";
      this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
        locationName
      ].notes = "";
      this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
        locationName
      ].kit = "";
      this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
        locationName
      ].explode = "";
      this.houseInfo.phases[phaseIndex].steps[stepIndex].loading = false;
    }
  }

  stepItemSelected(
    event: TypeaheadMatch,
    phaseIndex: number,
    stepIndex: number,
    locationIndex: number
  ) {
    console.log(event);
    const locationName = this.houseLocationList[locationIndex].code;
    this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
      locationName
    ].itemCode = event.item.itemCode;
    this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
      locationName
    ].description = event.item.description;
    this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
      locationName
    ].kit = event.item.kit;
    this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
      locationName
    ].explode = event.item.kit === "Y" ? "Y" : "N";
    console.log(this.houseInfo.phases[phaseIndex].steps[stepIndex]);
  }

  // ! ot sure this is needed now - commented out to test hard coding 'N' at labor/material level //
  // stepItemVPOSelected( phaseIndex: number, stepIndex: number, locationIndex: number, event: any) {
  //   const locationName = this.houseLocationList[locationIndex].code;
  //   console.log( phaseIndex, stepIndex, locationIndex)
  //   this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[locationName].vpo = event.target.checked === true ? 'Y' : 'N'
  //   console.log(this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[locationName].vpo);
  //   }

  stepItemKitToggle(
    phaseIndex: number,
    stepIndex: number,
    locationIndex: number
  ) {
    const locationName = this.houseLocationList[locationIndex].code;
    this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[
      locationName
    ].explode =
      this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[locationName]
        .explode === "Y"
        ? "N"
        : "Y";
    console.log(
      this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[locationName]
        .explode
    );
  }

  stepItemReady(phaseIndex: number, stepIndex: number, locationIndex: number) {
    const locationName = this.houseLocationList[locationIndex].code;
    if (
      this.houseInfo.phases[phaseIndex].steps[stepIndex].newItem[locationName]
        .itemCode === ""
    ) {
      return false;
    } else {
      return true;
    }
  }

  stepItemRemove(phaseIndex: number, stepIndex: number, itemIndex: number) {
    this.houseInfo.phases[phaseIndex].steps[stepIndex].addedItems.splice(
      itemIndex,
      1
    );
  }

  stepItemLocationSelected(
    phaseIndex: number,
    stepIndex: number,
    itemIndex: number,
    locationSelected: string
  ) {
    this.houseInfo.phases[phaseIndex].steps[stepIndex].addedItems[
      itemIndex
    ].location = locationSelected;
  }

  stepAddBlankItem(phaseIndex: number, locationIndex, stepName: string) {
    const stepIndex = this.houseInfo.phases[phaseIndex].steps.findIndex(
      (step) => step.wtStep === stepName
    );
    if (stepIndex < 0) {
      return;
    }
    const item = {
      itemCode: "",
      description: "",
      // tslint:disable-next-line:radix
      qty: "",
      location: this.houseLocationList[locationIndex].code,
      comment: "",
    };

    this.houseInfo.phases[phaseIndex].steps[stepIndex].addedItems.push(item);
    console.log(this.houseInfo);
  }

  stepLocationItemCount(phaseIndex: number, locationIndex, stepName: string) {
    const stepIndex = this.houseInfo.phases[phaseIndex].steps.findIndex(
      (step) => step.wtStep === stepName
    );
    if (stepIndex < 0) {
      return stepIndex;
    }
    const existingItemCount = this.houseInfo.phases[phaseIndex].steps[
      stepIndex
    ].existingItems.reduce(
      (sum, item) =>
        item.location.toLowerCase() ===
        this.houseLocationList[locationIndex].code.toLowerCase()
          ? ++sum
          : sum,
      0
    );
    const addedItemCount = this.houseInfo.phases[phaseIndex].steps[
      stepIndex
    ].addedItems.reduce(
      (sum, item) =>
        item.location.toLowerCase() ===
        this.houseLocationList[locationIndex].code.toLowerCase()
          ? ++sum
          : sum,
      0
    );
    return existingItemCount + addedItemCount;
  }

  stepAllLocationItemCount(phaseIndex: number, locationIndex) {
    let allItemCount = 0;
    const location = this.houseLocationList[locationIndex].code.toLowerCase();

    this.houseInfo.phases[phaseIndex].steps.forEach((step) => {
      const existingItemCount = step.existingItems.reduce(
        (sum, item) => (item.location.toLowerCase() === location ? ++sum : sum),
        0
      );
      const addedItemCount = step.addedItems.reduce(
        (sum, item) => (item.location.toLowerCase() === location ? ++sum : sum),
        0
      );

      allItemCount = allItemCount + existingItemCount + addedItemCount;
    });

    return allItemCount;
  }

  // stepItemLocationSelected(event: TypeaheadMatch) {
  //   this.newItem.location = event.item.name;
  //   console.log(this.newItem);
  // }

  runHouseFix() {
    const salesOrderNo = this.houseInfo.salesOrderNo;
    const body = {
      SOnum: this.houseInfo.salesOrderNo,
    };
    this.houseLoading = true;
    // this.sageApi.pullReport('ScriptFixHouse?SOnum=' + salesOrderNo).subscribe((rows: any) => {
    //   console.log(rows);
    // }, err => {
    //   console.log(err);
    //   this.alertify.error(err.message);
    //   this.houseLoading = false;
    // }, () => {
    //   console.log(this.houseInfo, this.houseLocationList);
    //   this.houseLoading = false;
    //   this.addressLoaded = true;
    // });

    const observables = [];
    observables.push(this.sageApi.putRequest("ScriptFixHouse", body));
    concat(...observables).subscribe(
      (response) => {},
      (err) => {
        console.log(err);
        this.alertify.error("Error " + err.error.Message);
        this.houseLoading = false;
      },
      () => {
        this.alertify.success("house fix applied");
        this.houseLoading = false;
      }
    );
  }

  updateHouse() {
    // tslint:disable-next-line:max-line-length
    // ["ZTOPOUT","1","N","210","000","Y","","Test Comment", "Y", "Y", "Y", "Y", "N", "", "poNumber", "price", "location", "template", "Audit - Pending"]

    this.addressCollection.unshift(this.houseInfo);
    const addressCollectionIndex = this.addressCollection.findIndex(
      (address) => address.salesOrderNo === this.houseInfo.salesOrderNo
    );
    this.addressCollection[addressCollectionIndex].squareFootage = Number(
      this.addressCollection[addressCollectionIndex].squareFootage.replace(
        /[^0-9\.]+/g,
        ""
      )
    );
    this.resetHouseData();
    console.log(this.addressCollection[addressCollectionIndex]);
    const observables = [];
    this.addressCollection[addressCollectionIndex].loading = true;
    const body = {
      username: this.authService.decodedToken?.nameid.toLocaleLowerCase(),
      password:
        this.authService.decodedToken[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication"
        ],
      salesorderno: this.addressCollection[addressCollectionIndex].salesOrderNo,
      address: this.addressCollection[addressCollectionIndex].address,
      configuration: this.addressCollection[addressCollectionIndex].type,
      garageLocation:
        this.addressCollection[addressCollectionIndex].garageLocation,
      series: this.addressCollection[addressCollectionIndex].series,
      planNumber: this.addressCollection[addressCollectionIndex].planNumber,
      squareFootage:
        this.addressCollection[addressCollectionIndex].squareFootage,
      energyType: this.addressCollection[addressCollectionIndex].energyType,
      warehouse: this.addressCollection[addressCollectionIndex].warehouse,
      updateFileReviewed:
        this.addressCollection[addressCollectionIndex].fileReviewedBy === ""
          ? "Y"
          : "N",
      resubmit: "N",
      lines: [],
    };

    this.addressCollection[addressCollectionIndex].phases.forEach((phase) => {
      let phasePrice = "0";
      if(phase.price === null){
        phase.price = 0;
      }
      if (phase.price !== undefined) {
        phasePrice = phase.price.toString();
      } else {
        this.alertify.error(
          "Error Phase.price has registered as undefined for some reason. Please show IT this error"
        );
        this.addressCollection[addressCollectionIndex].loading = false;
        return;
      }

      // console.log('New: ' + phase.new);
      // console.log('Updated: ' + phase.updated);
      if (phase.new) {
        const newPhase = [
          phase.parent,
          "1",
          "N",
          phase.wtNumber,
          "000",
          "Y",
          "",
          phase.comment,
          phase.cabinetDetails,
          phase.pendingItems,
          phase.updateItems,
          phase.reviewed,
          "Y",
          phase.lineKey,
          // lines [][14]
          phase.poNumber,
          // lines [][15]
          phasePrice === "" ? "0" : parseFloat(phasePrice).toString(),
          "",
          phase.template,
          phase.auditReason,
          phase.vpo,
          phase.auditUpdate,
        ];
        body.lines.push(newPhase);
      } else if (phase.updated) {
        const updatedPhase = [
          phase.parent,
          "1",
          "N",
          phase.wtNumber,
          "000",
          "Y",
          phase.lineKey,
          phase.comment,
          phase.cabinetDetails,
          phase.pendingItems,
          phase.updateItems,
          phase.reviewed,
          "N",
          phase.lineKey,
          phase.poNumber,
          phasePrice === "" ? "0" : parseFloat(phasePrice).toString(),
          "",
          phase.importedTemplate === ""
            ? phase.template
            : phase.importedTemplate,
          phase.auditReason,
          phase.vpo,
          phase.auditUpdate,
        ];
        body.lines.push(updatedPhase);
      }

      phase.steps.forEach((step) => {
        step.addedItems.forEach((item) => {
          if (item.qty === undefined || item.qty === null) {
            console.log(item);
            this.alertify.error(
              "ERROR: Item.qty has registered as undefined. An Add Item Broke the submit. Please get IT for this error."
            );
            this.addressCollection[addressCollectionIndex].loading = true;
            return;
          }
          const newItem = [
            item.itemCode,
            item.qty.toString(),
            "Y",
            phase.wtNumber,
            step.wtStep,
            "N",
            phase.new ? "" : phase.lineKey,
            item.comment,
            "N",
            "N",
            "N",
            "N",
            "Y",
            "",
            "",
            "",
            item.location,
            "",
            "",
            "N",
            "N",
          ];

          body.lines.push(newItem);
        });

        step.existingItems.forEach((item) => {
          if (item.updated) {
            if (item.qty === undefined || item.qty === null) {
              console.log(item);
              this.alertify.error(
                "ERROR: Item.qty has registered as undefined. An Saved Item that has been updated Broke the submit. Please get IT for this error."
              );
              this.addressCollection[addressCollectionIndex].loading = true;
              return;
            }
            const existingItem = [
              item.itemCode,
              item.qty.toString(),
              "Y",
              phase.wtNumber,
              step.wtStep,
              "N",
              phase.lineKey,
              item.comment,
              "N",
              "N",
              "N",
              "N",
              "N",
              item.lineKey,
              "",
              "",
              item.location,
              "",
              "",
              "N",
              "N",
            ];

            body.lines.push(existingItem);
          }
        });
      });
    });
    console.log(body);

    observables.push(this.sageApi.putRequest("HouseEditor", body));

    concat(...observables).subscribe(
      (response) => {},
      (err) => {
        console.log(err.error.Message);
        if (err.error.Message.includes("Possible Fails")) {
          this.alertify.warning(
            "Some items passed, but there may be errors. Please check build folder."
          );
        } else {
          if (err.error.Message.includes("System.NullReferenceException")) {
            this.alertify.error("Error creating phase, please try again.");
          } else {
            this.alertify.error("Error " + err.error.Message);
          }
        }
        this.addressCollection[addressCollectionIndex].loading = false;
      },
      () => {
        this.alertify.success(
          this.addressCollection[addressCollectionIndex].address +
            " Built Successfully"
        );
        this.addressCollection[addressCollectionIndex].loading = false;
        this.addressCollection.splice(addressCollectionIndex, 1);
      }
    );
  }

  resubmitHouse(addressCollectionIndex: number) {
    console.log(this.addressCollection[addressCollectionIndex]);
    const observables = [];
    this.addressCollection[addressCollectionIndex].loading = true;
    const body = {
      username: this.authService.decodedToken?.nameid.toLocaleLowerCase(),
      password:
        this.authService.decodedToken[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication"
        ],
      salesorderno: this.addressCollection[addressCollectionIndex].salesOrderNo,
      address: this.addressCollection[addressCollectionIndex].address,
      configuration: this.addressCollection[addressCollectionIndex].type,
      garageLocation:
        this.addressCollection[addressCollectionIndex].garageLocation,
      series: this.addressCollection[addressCollectionIndex].series,
      planNumber: this.addressCollection[addressCollectionIndex].planNumber,
      squareFootage:
        this.addressCollection[addressCollectionIndex].squareFootage,
      energyType: this.addressCollection[addressCollectionIndex].energyType,
      warehouse: this.addressCollection[addressCollectionIndex].warehouse,
      updateFileReviewed:
        this.addressCollection[addressCollectionIndex].fileReviewedBy === ""
          ? "Y"
          : "N",
      resubmit: "Y",
      lines: [],
    };

    this.addressCollection[addressCollectionIndex].phases.forEach((phase) => {
      let phasePrice = "0";
      if(phase.price === null){
        phase.price = 0;
      }
      if (phase.price !== undefined) {
        phasePrice = phase.price.toString();
      } else {
        console.log(phase);
        this.alertify.error(
          "Error Phase.price has registered as undefined for some reason. Please show IT this error"
        );
        this.addressCollection[addressCollectionIndex].loading = false;
        return;
      }
      if (phase.new) {
        const newPhase = [
          phase.parent, // 0---------------------------------------------  0
          "1", // 1------------------------------------------------------  1
          "N", // 2------------------------------------------------------  2
          phase.wtNumber, // 3-------------------------------------------  3
          "000", // 4---------------------------------------------------  4
          "Y", // 5---------------------------------------------------  5
          "", // 6---------------------------------------------------  6
          phase.comment, // 7--------------------------------------------  7
          phase.cabinetDetails, // 8-------------------------------------  8
          phase.pendingItems, // 9---------------------------------------  9
          phase.updateItems, // 10--------------------------------------- 10
          phase.reviewed, // 11------------------------------------------ 11
          "Y", // 12----------------------------------------------------- 12
          phase.lineKey, // 13------------------------------------------- 13
          phase.poNumber, // 14------------------------------------------ 14
          phasePrice === "" ? "0" : parseFloat(phasePrice).toString(), // 15
          "", // 16------------------------------------------------------ 16
          phase.template, // 17------------------------------------------ 17
          phase.auditReason, // 18--------------------------------------- 18
          phase.vpo, // 19----------------------------------------------- 19
          phase.auditUpdate,
        ];
        body.lines.push(newPhase);
      } else if (phase.updated) {
        const updatedPhase = [
          phase.parent, // 0---------------------------------------------  0
          "1", // 1------------------------------------------------------  1
          "N", // 2------------------------------------------------------  2
          phase.wtNumber, // 3-------------------------------------------  3
          "000", // 4---------------------------------------------------  4
          "Y", // 5---------------------------------------------------  5
          phase.lineKey, // 6 -------------------------------------------  6
          phase.comment, // 7--------------------------------------------  7
          phase.cabinetDetails, // 8-------------------------------------  8
          phase.pendingItems, // 9---------------------------------------  9
          phase.updateItems, // 10--------------------------------------- 10
          phase.reviewed, // 11------------------------------------------ 11
          "N", // 12----------------------------------------------------- 12
          phase.lineKey, // 13------------------------------------------- 13
          phase.poNumber, // 14------------------------------------------ 14
          phasePrice === "" ? "0" : parseFloat(phasePrice).toString(), // 15
          "", // 16------------------------------------------------------ 16
          phase.importedTemplate === ""
            ? phase.template
            : phase.importedTemplate, // 17------------- 17
          phase.auditReason, // 18--------------------------------------- 18
          phase.vpo, // 19----------------------------------------------- 19
          phase.auditUpdate,
        ];
        body.lines.push(updatedPhase);
      }

      phase.steps.forEach((step) => {
        step.addedItems.forEach((item) => {
          if (item.qty === undefined || item.qty === null) {
            console.log(item);
            this.alertify.error(
              "ERROR: Item.qty has registered as undefined. An Add Item Broke the submit. Please get IT for this error."
            );
            this.addressCollection[addressCollectionIndex].loading = true;
            return;
          }
          const newItem = [
            item.itemCode, // 0--------------- 0
            item.qty.toString(), // 1--------- 1
            "Y", // 2------------------------- 2
            phase.wtNumber, // 3-------------- 3
            step.wtStep, // 4----------------- 4
            "N", // 5------------------------- 5
            phase.new ? "" : phase.lineKey, // 6
            item.comment, // 7---------------- 7
            "N", // 8------------------------- 8
            "N", // 9------------------------- 9
            "N", // 10------------------------10
            "N", // 11------------------------11
            "Y", // 12------------------------12
            "", // 13-------------------------13
            "", // 14-------------------------14
            "", // 15-------------------------15
            item.location, // 16--------------16
            "", // 17-------------------------17
            "", // 18-------------------------18
            "N", // 19-------------------------19
            "N",
          ];

          body.lines.push(newItem);
        });

        step.existingItems.forEach((item) => {
          if (item.updated) {
            if (item.qty === undefined || item.qty === null) {
              console.log(item);
              this.alertify.error(
                "ERROR: Item.qty has registered as undefined. An Saved Item that has been updated Broke the submit. Please get IT for this error."
              );
              this.addressCollection[addressCollectionIndex].loading = true;
              return;
            }
            const existingItem = [
              item.itemCode,
              item.qty.toString(),
              "Y",
              phase.wtNumber,
              step.wtStep,
              "N",
              phase.lineKey,
              item.comment,
              "N",
              "N",
              "N",
              "N",
              "N",
              item.lineKey,
              "",
              "",
              item.location,
              "",
              "",
              "N",
              "N",
            ];

            body.lines.push(existingItem);
          }
        });
      });
    });
    console.log(body);

    observables.push(this.sageApi.putRequest("HouseEditor", body));

    concat(...observables).subscribe(
      (response) => {},
      (err) => {
        console.log(err.error.Message);
        if (err.error.Message.includes("Possible Fails")) {
          this.alertify.error(
            "Some items passed, but there may be errors. Please check build folder."
          );
        } else {
          if (err.error.Message.includes("System.NullReferenceException")) {
            this.alertify.error("Error " + err.error.Message);
            this.alertify.error("Error creating the phase, please resend.");
          } else if (err.error.Message.includes("System.IO.IOException")) {
            this.alertify.error("Error " + err.error.Message);
            this.alertify.error(
              "This Sales Order is open in another location, unable to save until it has been closed."
            );
          } else {
            this.alertify.error("Error " + err.error.Message);
          }
        }
        this.addressCollection[addressCollectionIndex].loading = false;
      },
      () => {
        this.alertify.success(
          this.addressCollection[addressCollectionIndex].address +
            " Built Successfully"
        );
        this.addressCollection[addressCollectionIndex].loading = false;
        this.addressCollection.splice(addressCollectionIndex, 1);
      }
    );
  }

  fileDownload(fileName: string) {
    const route = `HouseEditor/file/download?address=${this.houseInfo.address}&fileName=${fileName}`;

    this.sageApi.getFile(route).subscribe(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        if (fileName) {
          downloadLink.setAttribute("download", fileName);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
      },
      (err) => {
        console.log(err);
        this.alertify.error(err.message);
      }
    );
  }

  fileNames() {
    const route = `HouseEditor/file?address=${this.houseInfo.address}`;
    this.fileList = [];
    this.sageApi.pullReport(route).subscribe(
      (response: any) => {
        const splitFiles = response.split(", \\\\gp-nas2\\SO_WT_Files");
        splitFiles.forEach((file) => {
          this.fileList.push({
            name: file.split(this.houseInfo.address + "\\")[1],
          });
        });
      },
      (err) => {
        console.log(err);
        this.alertify.error(err.message);
      },
      () => {
        console.log(this.fileList);
      }
    );
  }

  loadImagesForViewing() {
    // this.imageSearched = true;
    console.log(this.houseInfo.address);
    this.onGetTicketImages(this.houseInfo.address).then(() => {
      // this.imagesForViewing = this.WasabiList;
      // this.WasabiList = [];
      console.log("Wasabi List", this.WasabiList);
    });
    // this.imagesForViewing = this.sageApi.imageUrlListReports;
    // this.imagesForViewing = this.wasabiApi.getImages(this.imgAddress);
    // this.displayScoutImages = true;
  }

  async onGetTicketImages(address: string) {
    const response = await this.wasabiApi.getImages(address);
    try {
      this.WasabiList = response;
    } catch (error) {
      this.alertify.error(
        "There was an error loading the images for this house - please try again"
      );
      console.warn(`Error loading images: ${error}`);
    }
  }

  fileChosen(event) {
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        const obj = {
          name: files[index].name,
          data: files[index],
        };
        // this.fileArray = [obj];
        this.fileArray = this.fileArray.concat(obj);
      }
    }
  }

  plansChosen(event) {
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        const obj = {
          name: files[index].name,
          data: files[index],
          size: files[index].size
        };
        this.plansArray = this.plansArray.concat(obj);
      }
    }
    console.log("plans array", this.plansArray);
  }

  loadImagesS3() {
    this.fileLoading = true;
    const folder = this.houseInfo.address + "/plans";
    const image = this.wasabiApi
      .getImages(this.houseInfo.address + "/plans")
      .then(
        (img) => {
          console.log("heres the img " + img);
        },
        () => {
          console.log("we got the images");
          console.log(this.sageApi.imageUrlListReports.length);
        }
      );
  }

  imageName(imgArrIndex: number) {
    // console.log(this.imagesForViewing[imgArrIndex].split('%2F').length);
    let name =
      this.WasabiList[imgArrIndex].split("%2F")[
        this.WasabiList[imgArrIndex].split("%2F").length - 1
      ];
    if (name !== null) {
      name = name.replace(/%20/g, " ");
    }
    const namesplit = name.split("/");
    return namesplit[namesplit.length - 1];
  }

  plansUpload() {
    this.fileLoading = true;
    const sentFiles = [];
    console.log("plan array", this.plansArray);
    let size = 0;

    this.plansArray.forEach((file) => {
      sentFiles.push(file.data);
     size += file.size;
    });
    console.log(size)
    if(size > 30000000){
      this.alertify.warning("May be too large")
    }

    this.wasabiApi
      .uploadFile(this.houseInfo.address, "plans/", sentFiles)
      .subscribe(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
          if (err.message.includes("request entity is too large")) {
            this.alertify.error(
              "Error: File entry size too large for submission. Decrease number of files or manually input file..."
            );
          } else {
            this.alertify.error("Error: " + err.message);
          }
          this.fileLoading = false;
        },
        () => {
          this.resetUpload();
          this.alertify.success("File entered into Wasabi");
          // this.plansArray.forEach(fi => {
          //   this.fileArray.push(fi);
          // });
          // this.fileArray = this.plansArray.concat(this.fileArray);
          console.log("filesArray", this.fileArray);
          this.fileUpload();
          // this.loadImagesForViewing();
        }
      );
  }

  fileUpload() {
    this.fileLoading = true;
    // this.loadImagesS3();
    const route = `HouseEditor/file/upload?address=${this.houseInfo.address}`;
    const sentFiles = [];

    this.fileArray.forEach((file) => {
      sentFiles.push(file.data);
    });

    console.log(sentFiles);

    this.sageApi.uploadFile(route, sentFiles).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        this.alertify.error("Error: " + err.error);
        console.log(err);
        this.fileLoading = false;
      },
      () => {
        this.resetUpload();
        this.alertify.success("File Loaded");
        this.loadImagesForViewing();
      }
    );
  }

  modalViewerSource() {
    if (this.imgFileTag === "pdf") {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        "https://drive.google.com/viewerng/viewer?embedded=true&url=" +
          this.imgUrl
      );
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.imgUrl);
    }
  }

  onBlowUpImage(imgUrl: any) {
    this.imgFileTag = imgUrl[0].slice(-3);
    this.imgUrl = "";
    this.imgUrl = imgUrl;
    // modalshow
    // this.imageModal.show();
  }

  resetUpload() {
    this.fileArray = [];
    this.plansArray = [];
    this.fileLoading = false;
    this.ShowImg = false;
    this.WShowImg = false;
    this.ShowStatus = false;
    // this.addressName = '';
  }

  resetHouseData() {
    this.sliderOne = true;
    this.sliderFive = false;
    this.ShowImg = false;
    this.WShowImg = false;
    this.currentSlideName = "Info";
    this.addressName = "";
    this.addressLoaded = false;
    this.houseInfo = {
      salesOrderNo: "",
      address: "",
      builder: "",
      fileReviewedBy: "",
      fileReviewedByDate: "",
      series: "",
      type: "",
      planNumber: "",
      squareFootage: 0,
      subdivision: "",
      energyType: "",
      lot: "",
      block: "",
      garageLocation: "",
      updated: false,
      loading: false,
      warehouse: "",
      phases: [],
    };
  }
}
