<div class="bg-dark text-light rounded px-2">
    <h1 mat-dialog-title>Editing Header Info</h1>
</div>

<mat-dialog-content>
    <div class="container">
        <div class="row">
            <div class="col-8 text-center">
                <mat-form-field appearance="fill">
                    <mat-label>Invoice Total Due</mat-label>
                    <input matInput disabled [value]="(data.invoice.totalAmountDue) | currency">
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Sage Total Due</mat-label>
                    <input matInput disabled [value]="(data.sageSumTotalCost + data.taxAmount) | currency">
                </mat-form-field>
            </div>
            <div class="col-4 text-center">
                <mat-form-field appearance="fill" style="width: 100%">
                    <mat-label>Approved Sage Total Due</mat-label>
                    <input matInput disabled [value]="(data.sageSumTotalCost + data.taxAmount) | currency">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-8 text-center">
                <mat-form-field appearance="fill">
                    <mat-label>Invoice Tax</mat-label>
                    <input matInput disabled [value]="data.invoice.taxAmountDue | currency">
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Sage Tax</mat-label>
                    <input matInput disabled [value]="data.sageTax | currency">
                </mat-form-field>
            </div>
            <div class="col-4 text-center">
                <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Approved Tax Amount</mat-label>
                    <input type="number" matInput [(ngModel)]="data.taxAmount">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-8 text-center">
                <mat-form-field appearance="fill">
                    <mat-label>Invoice Date</mat-label>
                    <input matInput disabled [value]="data.invoice.invoiceDate | date">
                </mat-form-field>
            </div>
            <div class="col-4 text-center">
                <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Approved Invoice Date</mat-label>
                    <input matInput [matDatepicker]="pickerInvoice" [(ngModel)]="data.invoiceDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerInvoice"></mat-datepicker-toggle>
                    <mat-datepicker #pickerInvoice></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12 pb-1">
                <h5>Add Applied Invoices</h5>
                <mat-divider></mat-divider>
            </div>

            <div class="col-6 text-center">
                <form [formGroup]="creditForm" (ngSubmit)="addCredit($event)">
                    <div>
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Invoice Type</mat-label>
                            <mat-select formControlName="InvoiceType">
                              <mat-option value="credit">Credit</mat-option>
                              <mat-option value="rebill">Rebill</mat-option>
                              <mat-option value="invoice">Invoice</mat-option>
                            </mat-select>
                            <mat-error *ngIf="creditForm.get('InvoiceType').invalid">Invoice Type Required</mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Enter Invoice No.</mat-label>
                            <input matInput placeholder="S123456789.001" formControlName="InvoiceNo" required>
                            <mat-error *ngIf="creditForm.get('InvoiceNo').invalid">Invoice No. Required</mat-error>
                            <span mat matSuffix>
                                <button type="submit" mat-mini-fab color="primary" class="ml-3" [disabled]="!creditForm.valid">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </span>
                        </mat-form-field>
                    </div>

                    <!-- <button mat-raised-button color="primary" type="submit" [disabled]="!creditForm.valid">
                        Add
                    </button> -->
                </form>
            </div>
            <div class="col-6 text-center overflow-auto" style="height: 150px;">
                <mat-list dense *ngIf="data.invoice.credits.length > 0">
                    <div mat-subheader>
                        <span [matBadge]="data.invoice.credits.length" matBadgeOverlap="false" matBadgeSize="small">Applied  Invoice</span>
                    </div>
                    <mat-list-item *ngFor="let credit of data.invoice.credits; let creditIndex = index;">
                        <div mat-line>
                            <mat-icon mat-list-icon> receipt </mat-icon>
                            {{ credit.invoiceNo }} - {{ credit.invoiceType | titlecase }}
                            <button mat-icon-button color="danger" (click)="removeCredit(creditIndex)">
                                <mat-icon> delete </mat-icon>
                            </button>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-8 text-center">
                <mat-form-field appearance="fill">
                    <mat-label>Receipt Date</mat-label>
                    <input matInput disabled [value]="data.invoice.receiptDate | date">
                </mat-form-field>
            </div>
            <div class="col-4 text-center">
                <mat-form-field appearance="fill" style="width: 100%">
                    <mat-label>Approved Receipt Date</mat-label>
                    <input matInput [matDatepicker]="pickerReceipt" [(ngModel)]="data.receiptDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerReceipt"></mat-datepicker-toggle>
                    <mat-datepicker #pickerReceipt></mat-datepicker>
                </mat-form-field>
            </div>
        </div> -->
    </div>
</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="success" (click)="confirmUpdate()">Save</button>
</mat-dialog-actions>
