<mat-card class="qexclu">
  <app-grow-input
    [control]="exclusion.nameControl"
    (inputEmitter)="nameInput($event)"
    (blurEmitter)="nameBlur()"
    [useNativeInput]="true"
    minWidth="100%"
    maxWidth="100%"
    class="qexclu-name"
  ></app-grow-input>
  <mat-form-field appearance="outline" class="qexclu-desc">
    <mat-label>Description</mat-label>
    <textarea
      [formControl]="exclusion.descControl"
      matInput
      class="qexclu-desc-in"
    ></textarea>
    <div class="qexclu-desc-char">
        <span class="qexclu-desc-char-len" [style]="{color: exclusion.descControl.value.length <= 1024?'var(--gpc-primary)' : 'var(--gpc-warn)'}">{{exclusion.descControl.value.length}}{{' '}}</span>
        /1024
    </div>
  </mat-form-field>
  <div class="qexclu-actions">
    <div class="qwxclu-actions-front">
      <button (click)="deleteExclusion()" color="warn" mat-flat-button mat-button>Delete</button>
    </div>
    <div class="qwxclu-actions-back">
      <button
        [disabled]="isSaveDisabled()"
        (click)="resetControls()"
        color="warn"
        mat-flat-button
        mat-button
      >
        Cancel
      </button>
      <button
        [disabled]="isSaveDisabled()"
        (click)="saveChanges()"
        color="primary"
        mat-flat-button
        mat-button
      >
        Save
      </button>
    </div>
  </div>
</mat-card>
