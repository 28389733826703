/*
  Shamelessly adapted from:
  https://medium.com/@amcdnl/angular2-long-press-directive-6e257e991a32
*/

import {
  Directive,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
  ElementRef,
  Host,
} from "@angular/core";

@Directive({ selector: "[long-press]" })
export class LongPressDirective {
  @Input() longPressThreshhold: number = 1000;

  @Output() onLongPress: EventEmitter<any> = new EventEmitter();
  @Output() onLongPressMiss: EventEmitter<any> = new EventEmitter();

  private hasMetThreshhold: boolean;
  private timeout: any;

  constructor(private el: ElementRef) {
    el.nativeElement.style.touchAction = "none";
    el.nativeElement.style.userSelect = "none";
    el.nativeElement.style.msUserSelect = "none";
    el.nativeElement.style.webkitUserSelect = "none";
    el.nativeElement.style.webkitTouchCallout = "none";
  }

  @HostBinding('contextmenu') disableContextMenu(event) {
    event.preventDefault();
  }

  @HostListener("touchstart") onTouchStart(e) {
    this.start(e);
  }

  @HostListener("mousedown") onMouseDown(e) {
    this.start(e);
  }

  start(e){
    this.hasMetThreshhold = false;
    this.timeout = setTimeout(() => {
      this.hasMetThreshhold = true;
      this.onLongPress.emit(e);
    }, this.longPressThreshhold);
  }

  @HostListener("touchend") onTouchEnd() {
    this.end();
  }

  @HostListener("mouseup") onMouseUp() {
    this.end();
  }

  end(){
    if(!this.hasMetThreshhold){
      this.onLongPressMiss.emit();
    }
    this.hasMetThreshhold = false;
    clearTimeout(this.timeout);
  }

  @HostListener("touchleave") onTouchLeave() {
    this.leave();
  }

  @HostListener("mouseleave") onMouseLeave() {
    this.leave();
  }

  leave(){
    this.hasMetThreshhold = false;
    clearTimeout(this.timeout);
  }
}
