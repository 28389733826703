<div class="container pb-5">
  <div class="card my-5 shadow-lg">
    <div class="card-body">
      <div class="row" id="searches">
        <div class="col-sm-5">
          <div class="card m-3 border-0">
            <form>
              <div class="form-group m-0">
                <label for="address"> Address </label>
                <input class="form-control" name="addressName" [(ngModel)]="addressName" (typeaheadOnSelect)="filterAddress($event)" [typeahead]="addressList" typeaheadOptionField="name" autocomplete="off">
              </div>
            </form>
          </div>
        </div>
        <div class="col-sm-5">
          <div class="card m-3 border-0">
            <form>
              <div class="form-group m-0">
                <label for="subdivision"> Subdivision </label>
                <input class="form-control" name="subdivisionName" [(ngModel)]="subdivisionName"  (typeaheadOnSelect)="filterSubdivision($event)" [typeahead]="subdivisionList" typeaheadOptionField="name" autocomplete="off">
              </div>
            </form>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="card my-3 border-0 mx-0">
            <form>
              <div class="form-group m-0">
                <label for="customer"> Customer </label>
                <button type="button" class="btn btn-primary form-control" (click)="openModal(addCustomer)"><i class="fas fa-user-plus"></i> Add</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="row" id="filters" *ngIf="subdivisionName !== ''">
        <div class="col-sm-3">
          <div class="card mx-3 mb-3 border-0">
            <form>
              <div class="form-group m-0">
                <label for="">Customer</label>
                <input name="customerName" class="form-control" [(ngModel)]="builderName" (typeaheadOnSelect)="filterBuilder($event)" [typeahead]="builderList" [typeaheadMinLength]="0" typeaheadOptionField="name" autocomplete="off">
              </div>
            </form>
          </div>
        </div>
      </div>

      <div id="addresses">
        <ul class="list-group">
          <ng-container *ngFor="let address of filteredList; let addressIndex = index; let isFirst = first;">
            <div class="list-group list-group-horizontal" *ngIf="isFirst">
              <a class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-light bg-primary" (click)="sortTickets('name')"> Address </a>
              <a class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-light bg-primary" (click)="sortTickets('customerName')"> Customer </a>
              <a class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary" (click)="sortTickets('subdivision')"> Subdivision </a>
              <a class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary" (click)="sortTickets('primaryEmail')"> Primary Email </a>
              <a class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary" (click)="sortTickets('secondaryEmail')"> Secondary Email </a>
              <a class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary" (click)="sortTickets('tertiaryEmail')"> Tertiary Email </a>
            </div>

            <ng-container *ngIf="builderSelected && isFirst">
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item list-group-item-primary py-2 col-2 col-md-2 col-lg-2 text-center"> Update All Addresses </li>
                <li class="list-group-item list-group-item-primary py-2 col-2 col-md-2 col-lg-2"> {{ builderName | titlecase }} </li>
                <li class="list-group-item list-group-item-primary py-2 col-2 col-md-2 col-lg-2 text-center"> {{ address.subdivision | titlecase }} </li>
                <li class="list-group-item list-group-item-primary border-left-0 px-1 py-2 col-2 col-md-2 col-lg-2 text-center">
                  <input class="form-control" [(ngModel)]="updateAllPrimaryEmail" (typeaheadOnSelect)="updateEmailAll($event, 'primary')" [typeahead]="customerList" typeaheadOptionField="name" autocomplete="off">
                </li>
                <li class="list-group-item list-group-item-primary px-1 py-2 col-2 col-md-2 col-lg-2 text-center">
                  <input class="form-control" [(ngModel)]="updateAllSecondaryEmail" (typeaheadOnSelect)="updateEmailAll($event, 'secondary')" [typeahead]="customerList" typeaheadOptionField="name" autocomplete="off">
                </li>
                <li class="list-group-item list-group-item-primary px-1 py-2 col-2 col-md-2 col-lg-2 text-center">
                  <input class="form-control" [(ngModel)]="updateAllTertiaryEmail" (typeaheadOnSelect)="updateEmailAll($event, 'tertiary')" [typeahead]="customerList" typeaheadOptionField="name" autocomplete="off">
                </li>
              </ul>
            </ng-container>

            <ng-container *ngIf="address.customerName === builderName || builderName === ''">
            <ul class="list-group list-group-horizontal">
              <li class="list-group-item py-2 col-2 col-md-2 col-lg-2 text-center" [ngClass]="{'list-group-item-dark': addressIndex % 2 !== 0}"> {{ address.name | titlecase }} <small> ({{ address.salesOrderNo }}) </small> </li>
              <li class="list-group-item py-2 col-2 col-md-2 col-lg-2" [ngClass]="{'list-group-item-dark': addressIndex % 2 !== 0}"> {{ address.customerName | titlecase }} </li>
              <li class="list-group-item py-2 col-2 col-md-2 col-lg-2 text-center" [ngClass]="{'list-group-item-dark': addressIndex % 2 !== 0}"> {{ address.subdivision | titlecase }} </li>
              <li class="list-group-item px-1 py-2 col-2 col-md-2 col-lg-2 text-center" [ngClass]="{'list-group-item-dark': addressIndex % 2 !== 0}">
                <small [ngStyle]="checkEmails(address.primaryEmail) ? '' : {display: 'none'}">{{ address.primaryEmail }}</small><br>
                <small [ngStyle]="checkEmails(address.primaryEmail) ? '' : {display: 'none'}">{{ address.primaryPhoneNumber }}</small>
                <input class="form-control" [(ngModel)]="address.primaryEmailChange" (typeaheadOnSelect)="updateEmail($event, addressIndex, 'primary')" [typeahead]="customerList" typeaheadOptionField="name" autocomplete="off">
              </li>
              <li class="list-group-item px-1 py-2 col-2 col-md-2 col-lg-2 text-center" [ngClass]="{'list-group-item-dark': addressIndex % 2 !== 0}">
                <small [ngStyle]="checkEmails(address.secondaryEmail) ? '' : {display: 'none'}">{{ address.secondaryEmail }}</small><br>
                <small [ngStyle]="checkEmails(address.secondaryEmail) ? '' : {display: 'none'}">{{ address.secondaryPhoneNumber }}</small>
                <input class="form-control" [(ngModel)]="address.secondaryEmailChange" (typeaheadOnSelect)="updateEmail($event, addressIndex, 'secondary')" [typeahead]="customerList" typeaheadOptionField="name" autocomplete="off">
              </li>
              <li class="list-group-item px-1 py-2 col-2 col-md-2 col-lg-2 text-center" [ngClass]="{'list-group-item-dark': addressIndex % 2 !== 0}">
                <small [ngStyle]="checkEmails(address.tertiaryEmail) ? '' : {display: 'none'}">{{ address.tertiaryEmail }}</small><br>
                <small [ngStyle]="checkEmails(address.tertiaryEmail) ? '' : {display: 'none'}">{{ address.tertiaryPhoneNumber }}</small>
                <input class="form-control" [(ngModel)]="address.tertiaryEmailChange" (typeaheadOnSelect)="updateEmail($event, addressIndex, 'tertiary')" [typeahead]="customerList" typeaheadOptionField="name" autocomplete="off">
              </li>
            </ul>
            </ng-container>
          </ng-container>
        </ul>
      </div>

    </div>
  </div>
</div>

<!-- Add New Customer -->
<ng-template #addCustomer>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>Add New Customer</h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <form #customerFrom="ngForm" (ngSubmit)="addNewCustomer(customerFrom)">
      <div class="form-row">
        <div class="col">
          <label for="fistname">First Name</label>
          <input type="text" #firstName="ngModel" class="form-control" placeholder="First name" id="firstname" name="firstname" [(ngModel)]="newCustomer.firstName" required autocomplete="off">
          <div *ngIf="firstName.errors && (firstName.invalid || firstName.touched)">
            <small class="text-danger" *ngIf="firstName.errors.required">First name is required</small>
          </div>
        </div>
        <div class="col">
          <label for="lastname">Last Name</label>
          <input type="text" #lastName="ngModel" class="form-control" placeholder="Last name" id="lastname" name="lastname" [(ngModel)]="newCustomer.lastName" required autocomplete="off">
          <div *ngIf="lastName.errors && (lastName.invalid || lastName.touched)">
            <small class="text-danger" *ngIf="lastName.errors.required"> Last name is required</small>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <label for="email">Email Address</label>
          <input type="email" #email="ngModel" class="form-control" [class.is-invalid]="email.invalid && email.touched" placeholder="Email Address" id="email" name="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [(ngModel)]="newCustomer.email" required autocomplete="off">
          <div *ngIf="email.errors && (email.invalid || email.touched)">
            <small class="text-danger" *ngIf="email.errors.required">Primary email is required</small>
            <small class="text-danger" *ngIf="email.errors.pattern">Please provide a valid email address</small>
          </div>
        </div>
        <div class="col">
          <label for="phonenumber">Phone Number</label>
          <input type="text" #phoneNumber="ngModel" class="form-control" [class.is-invalid]="email.invalid && email.touched" placeholder="Phone Number" id="phonenumber" name="phonenumber" minlength="10" maxlength="10" pattern="^((\\+91-?)|0)?[0-9]{10}$" [(ngModel)]="newCustomer.phoneNumber" required autocomplete="off">
          <div *ngIf="phoneNumber.errors && (phoneNumber.invalid || phoneNumber.touched)">
            <small class="text-danger" *ngIf="phoneNumber.errors.required">Email address is required</small>
            <small class="text-danger" *ngIf="phoneNumber.errors.pattern">Please provide a valid phone number</small>
            <small class="text-danger" *ngIf="phoneNumber.errors.minlength">Phone number must be 10 digits</small>
            <small class="text-danger" *ngIf="phoneNumber.errors.maxlength">Phone number must be 10 digits</small>
          </div>
        </div>
      </div>
      <div class="form-row pt-2">
        <div class="col">
          <button type="submit" class="btn btn-primary float-right">Create</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
