import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import QuoteBillingTermEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteBillingTermEditableInterface';
import QuoteBillingTermDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteBillingTermDtoInterface';
import { QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';

@Component({
  selector: 'app-qbillingterm',
  templateUrl: './QuoteBillingTermComponent.html',
  styleUrls: ['./QuoteBillingTermComponent.css', '../QuotingTheme.scss']
})
export class QuoteBillingTermComponent implements OnInit{

  @Input() billingTerm: QuoteBillingTermEditableInterface;

  constructor(
    public api: SageApiService,
    public screenSize: ScreenSizeService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    private qe: QuoteEditableService
  ){}

  ngOnInit(): void {
    
  }

  resetControls(){
    this.billingTerm.nameControl.setValue(this.billingTerm.data.QuoteBillingTerm_Name);
    this.billingTerm.descControl.setValue(this.billingTerm.data.QuoteBillingTerm_Desc);
  }

  nameInput(e: InputEvent){
    const value = (e.target as HTMLInputElement).value;
    if(value.length > 100){
      const newVal = value.slice(0, 100);
      this.billingTerm.nameControl.setValue(newVal);
      (e.target as HTMLInputElement).value = newVal;
    }
  }

  isSaveDisabled(){
    const different = 
      this.billingTerm.nameControl.value !== this.billingTerm.data.QuoteBillingTerm_Name 
      || this.billingTerm.descControl.value !== this.billingTerm.data.QuoteBillingTerm_Desc;

    const valid = 
      this.billingTerm.nameControl.valid 
      && this.billingTerm.descControl.valid;
    
    return !different || !valid;
  }

  nameBlur(){
    if(this.billingTerm.nameControl.value === ''){
      this.billingTerm.nameControl.setValue('Untitled');
    }
  }

  saveChanges(){
    this.qe.changeQuoteBillingTerm({quoteBillingTerm: this.billingTerm});
  }

  deleteExclusion(){
    this.qe.destroyQuoteBillingTerm({quoteBillingTerm: this.billingTerm})
  }

}