<div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 col-xl-4 my-3">
        <mat-form-field class="">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. 0012345" #input autocomplete="off">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-4 my-3" *ngFor="let invoice of filteredInvoices; let invoiceIndex = index;">
            <mat-card style="height: 100%;">
                <mat-card-header>
                    <mat-icon mat-card-avatar [ngClass]="{'text-primary': invoice.lineErrors > 0, 'text-success': invoice.lineErrors <= 0}" [matBadge]="invoice.lineErrors" [matBadgeHidden]="invoice.lineErrors <= 0">receipt</mat-icon>
                    <mat-card-title> {{ invoice.purchaseOrderNo }} </mat-card-title>
                    <mat-card-subtitle> {{ invoice.invoiceNo }} {{ invoice.invoiceDate | date }} </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <p class="lead text-center">
                    Total Due: <strong>{{ invoice.totalAmountDue | currency }}</strong>
                  </p>
                  <p class="lead text-center" *ngIf="invoice.sageTaxDue != invoice.taxAmountDue">
                    Tax Due Discrepancy <br>
                    Sage: {{ invoice.sageTaxDue }} Invoice: {{ invoice.taxAmountDue }}
                  </p>
                </mat-card-content>
                <mat-card-actions class="text-right mb-0">
                  <button mat-raised-button color="danger" (click)="openIndividualInvoice(invoice.id)">Open</button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>
