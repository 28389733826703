<mat-spinner *ngIf="loading" class="loadingSpinner"></mat-spinner>
<div class="startingOut">
  <div class="routerButtons">

    <button color="accent" mat-flat-button routerLink="/quoting/questions">Go To Questions</button>
    <button color="accent" mat-flat-button routerLink="/quoting/quotes">Go To Quotes</button>
    <button color="accent" mat-flat-button routerLink="/quoting/kits">Go To Kits</button>
  </div>


  <div class="chart-container">
    <canvas id="BuilderChart" width="400" height="400"></canvas>
    <canvas id="newAttempt" width="400" height="400"></canvas>
    <canvas id="newDoughnut" width="400" height="400"></canvas>
  </div>

  <div *ngIf="!loading">
    <h1>Current Quotes</h1>
    <table class="table table-hover table-striped">
      <tr>
        <th>Name</th>
        <th>Desc</th>
        <th>Builder</th>
        <th>Total Price</th>
        <th>Status</th>
        <th>Region</th>
      </tr>
      <tr *ngFor="let quote of allQuotes">
        <td>
          <a routerLink="/quoting/quote/{{quote.guid}}" title="Go to Quote" target="_blank">{{quote.QuoteName}}</a>
        </td>
        <td>{{quote.Description}}</td>
        <td>{{quote.Builder}}</td>
        <td>${{quote.TotalPrice.toFixed(2)}}</td>
        <td>{{quote.Status}}</td>
        <td>{{quote.Region }}</td>
      </tr>
    </table>
  </div>
</div>
