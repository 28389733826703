
import {Component, HostBinding, OnInit} from '@angular/core';
import { AuthService } from '../app/_services/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AlertifyService } from '../app/_services/alertify/alertify.service';
import { Router } from '@angular/router';
import {version} from '../environments/version';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  navOpened = false;
  model: any = {};
  title = 'Gibson Portal';
  jwtHelper = new JwtHelperService();
  loginLoading = false;

  constructor(
    public authService: AuthService,
    private alertify: AlertifyService,
    private router: Router
  ) { }

  ngOnInit() {
    const token = localStorage.getItem('token');
    this.authService.holidayTheme = localStorage.getItem('holidayTheme') === 'true';
    this.authService.decodedToken = this.jwtHelper.decodeToken(token);
  }

  toggleHolidayTheme() {
    this.authService.holidayTheme = !this.authService.holidayTheme;
    const mode = this.authService.holidayTheme ? 'true' : 'false';
    localStorage.setItem('holidayTheme', mode);
  }

  navToggle() {
    this.navOpened = !this.navOpened;
  }

  navClose() {
    if (this.navOpened) {
      this.navOpened = false;
    }
  }

  navShow(group: string) {
    let allowedRoles: any = [];
    let unallowedRoles: any = [];
    switch (group) {
      case 'superadmin':
        allowedRoles = ['superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'admin':
        allowedRoles = ['admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'mgt':
        allowedRoles = ['mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'mat_mgt':
        allowedRoles = ['mat_mgt', 'res_mgt', 'mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'material':
        allowedRoles = ['material', 'mat_mgt', 'res_mgt', 'mgt', 'admin', 'superadmin', 'dfw_sch', 'matDFW'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'service':
          allowedRoles = [ 'res_mgt', 'mgt', 'admin', 'superadmin', 'dfw_sch', 'service'];
          return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'inventory':
        allowedRoles = ['mat_mgt', 'res_mgt', 'acct', 'acct_mgt', 'mgt', 'admin', 'superadmin', 'dfw_sch'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'Warehouse':
        allowedRoles = [ 'material', 'mat_mgt', 'res_mgt', 'acct_mgt', 'mgt', 'admin', 'superadmin', 'dfw_sch', 'saw', 'matDFW'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'accounting' :
        allowedRoles = ['acct', 'acct_mgt', 'mgt', 'admin', 'superadmin', 'dfw_sch'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'am':
        allowedRoles = ['am', 'res_mgt', 'mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'super':
        allowedRoles = ['super', 'superdfw', 'am', 'res_mgt', 'mgt', 'admin', 'superadmin',];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'tech':
        allowedRoles = ['tech', 'am', 'mgt', 'admin', 'superadmin', 'material', 'mat_mgt', 'super', 'superdfw', 'matDFW'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'techWar':
        allowedRoles = ['techWar', 'mgt', 'admin', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'schedule':
        allowedRoles = ['schedule', 'res_mgt', 'mgt', 'admin', 'superadmin', 'dfw_sch'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'serviceMaterial':
        allowedRoles = ['mat_mgt', 'serviceAppr', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'builder':
        allowedRoles = ['builder', 'res_mgt', 'mgt', 'admin', 'superadmin', 'dfw_sch'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'scheduleadmin':
        allowedRoles = ['admin', 'superadmin', 'sch_mgt'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'warrantyCoordinator':
        allowedRoles = ['admin', 'superadmin', 'warrantyCoord'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'purchasing':
        allowedRoles = ['purchasing', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'external':
        allowedRoles = ['ExternalBuilder', 'superadmin'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      case 'internal':
        unallowedRoles = ['ExternalBuilder'];
        return !unallowedRoles.includes(this.authService.decodedToken?.role);

      case 'quoting':
        //'purchasing', 'purchasing', 'builder', 'purchasing', 'acct_mgt', 'res_mgt', 'mgt', 'admin',
        allowedRoles = [ 'superadmin', 'admin', 'builder'];
        return allowedRoles.includes(this.authService.decodedToken?.role);

      default:
        return false;
    }
  }

  login() {
    this.loginLoading = true;
    this.authService.login(this.model).subscribe(next => {
      this.alertify.success('Logged in successfully');
    }, error => {
      console.log(error);
      this.loginLoading = false;
      this.alertify.error('Could not login: ' + error.error);
    }, () => {
      this.loginLoading = false;
      this.router.navigate(['/']);
    });
  }

  loggedIn() {
    if (!this.authService.loggedIn() && this.authService.decodedToken) {
      setTimeout(() => {
        if (this.authService.decodedToken !== '') {
          this.logout();
        }
      });
      return false;
    } else {
      return this.authService.loggedIn();
    }
  }

  logout() {
    localStorage.removeItem('token');
    this.authService.decodedToken = '';
    this.alertify.message('logged out');
    this.router.navigate(['']);
  }
}
